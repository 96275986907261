import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class UpdateApplicationModalService{
    constructor(private http: HttpClient){ }

    getSubStatuses(): Observable<any>{
        return this.http.get(environment.platformBackendURL + '/resources/subStatuses');
    }

    getStages(currentStage): Observable<any>{
        return this.http.get(environment.platformBackendURL + '/resources/applicationStages?current_stage=' + currentStage);
    }

    updateApplication(body, uuid): Observable<any>{
        return this.http.put(environment.platformBackendURL + '/candidates/applications/' + uuid, body);
    }

    getRejectReasons(): Observable<any>{
        return this.http.get(environment.platformBackendURL + '/resources?type=reject_reasons');
    }

    getResources(type): Observable<any>{
      return this.http.get(environment.platformBackendURL + '/resources?type=' + type);
    }
}
