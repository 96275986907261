import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class UserDataService{
    data: any;

    constructor(private http: HttpClient){
    }

    getUserData(): Observable<any>{
        const url: string = environment.platformBackendURL + '/me';
        return this.http.get(url);
    }
}
