import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class GetNavisNavbarService{

    constructor(private httpClient: HttpClient){

     }

    logout(): Observable<any>{
        return this.httpClient.get(environment.getNavisBackendURL + '/logout');
    }

}
