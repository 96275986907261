<div class="card scroll mt-5">
  <div class="card-block">
    <div class="card-body">
      <div class="form">
        <div class="form-section">
          Basic details
          <div class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2"
            (click)="editForm('basic')">
            edit
          </div>
        </div>
        <div class="row card-block" *ngIf="edit.basic">
          <div class="col-md-12 col-12">
            <div class="card-body-title-basic">
              <label>
                Profile picture
              </label>
            </div>
            <div class="media mb-3">
              <img class="align-self-center mr-3 height-40 width-40 rounded-circle"
                [src]="consultant.details.picture_file_url || globals.avatar">
              <div class="media-body">
                <input type="text" class="form-control" placeholder="Insert picture url"
                  [(ngModel)]="consultant.details.picture_file_url" [ngModelOptions]="{standalone: true}" onFocusOut
                  (focusout)="changed ? editConsultant('picture_file_url' ,consultant.details.picture_file_url) : ''"
                  (ngModelChange)="onChange($event)">
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>* First name</label>
              </div>
              <div class="card-body-content">
                <input type="text" class="form-control" placeholder="Insert first name"
                  [(ngModel)]="consultant.details.first_name" [ngModelOptions]="{standalone: true}" onFocusOut
                  (focusout)="changed ? editConsultant('first_name' ,consultant.details.first_name) : ''"
                  (ngModelChange)="onChange($event)">
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>* Last name</label>
              </div>
              <div class="card-body-content">
                <input type="text" class="form-control" placeholder="Insert last name"
                  [(ngModel)]="consultant.details.last_name" [ngModelOptions]="{standalone: true}" onFocusOut
                  (focusout)="changed ? editConsultant('last_name',consultant.details.last_name) : ''"
                  (ngModelChange)="onChange($event)">
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>* Email</label>
              </div>
              <div class="card-body-content">
                <input type="email" class="form-control" placeholder="Insert email"
                  [(ngModel)]="consultant.details.email" [ngModelOptions]="{standalone: true}" onFocusOut
                  (focusout)="changed ? editConsultant('email',consultant.details.email) : ''"
                  (ngModelChange)="onChange($event)">
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>Title</label>
              </div>
              <div class="card-body-content">
                <input type="text" class="form-control" placeholder="Insert title"
                  [(ngModel)]="consultant.details.title" [ngModelOptions]="{standalone: true}" onFocusOut
                  (focusout)="changed ? editConsultant('title',consultant.details.title) : ''"
                  (ngModelChange)="onChange($event)">
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>Phone</label>
              </div>
              <div class="card-body-content">
                <input type="text" class="form-control" placeholder="Insert phone"
                  [(ngModel)]="consultant.details.phone" [ngModelOptions]="{standalone: true}" onFocusOut
                  (focusout)="changed ? editConsultant('phone',consultant.details.phone , uuid) : ''"
                  (ngModelChange)="onChange($event)">
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>
                  Company
                </label>
              </div>
              <div class="card-body-content">
                <ng-select bindLabel="name" dropdownPosition="bottom" placeholder="ConsultantCompany"
                  [items]="companies" (open)="loadCompany()" [(ngModel)]="consultant.details.company"
                  [ngModelOptions]="{standalone: true}"
                  (change)="editConsultant('company' ,consultant.details.company)">
                  <ng-template ng-option-tmp let-item="item" let-index="i">
                    <div class="media mb-1">
                      <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                        [src]="item.photo || (item.images ? (item.images['32x32'] ? item.images['32x32'] : globals.companyAvatar) : '')"
                        (error)="item.images = globals.companyAvatar" />
                      <div class="media-body">
                        <div class="media-body-title mt-1 mb-0">{{item.name}}<span *ngIf="item.website">,
                            {{item.website}}</span>
                        </div>
                        <p *ngIf="item.location">
                          <i class="tt-icon-sm icon-ttrelocation"></i>
                          <span>
                            {{item.location}}
                          </span>
                        </p>
                      </div>
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!edit.basic">
          <div class="row card-block">
            <div class="col-12">
              <div class="form-group">
                <div class="media">
                  <img class="align-self-start mr-3 height-50 width-50 rounded-circle"
                    [src]="consultant.details.picture_file_url || globals.avatar">
                  <div class="media-body align-self-center">
                    <p class="text-bold text-lg mb-0 line-height-custom">
                      <strong> {{consultant.details.first_name}} {{consultant.details.last_name}}</strong>
                    </p>
                    <p class="mb-0"> {{consultant.details.title}} </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>
                    Email
                  </label>
                </div>
                <div class="card-body-content">
                  {{consultant.details.email}}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>
                    Phone
                  </label>
                </div>
                <div class="card-body-content">
                  {{consultant.details.phone || 'No information available'}}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>
                    Company
                  </label>
                </div>
                <div class="card-body-content">
                  {{consultant.details.company ? consultant.details.company.name : 'No information available'}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form">
        <div class="form-section">
          Jobs
          <div class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2"
            (click)="editForm('jobs')">
            edit
          </div>
        </div>
        <div class="row card-block" *ngIf="edit.jobs">
          <div class="col-12">
            <div class="form-group">
              <div class="card-body-content">
                <ng-select bindLabel="title" dropdownPosition="bottom" placeholder="Select jobs"
                  [items]="resources.jobs" (open)="getResources('jobs')" [(ngModel)]="consultant.jobs"
                  [ngModelOptions]="{standalone: true}" multiple="true"
                  (change)="editConsultant('jobs',consultant.jobs)">
                  <ng-template ng-option-tmp let-item="item" let-index="i">
                    {{item.title}} {{item.company ? ' - ' + item.company.name : 'Missing company'}}
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
        </div>
        <div class="card-block" *ngIf="!edit.jobs">
          <div class="row" *ngIf="consultant.jobs.length; else noJobs">
            <div class="col-12" *ngFor="let job of consultant.jobs">
              <div class="row">
                <div class="col-md-6 col-12">
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label>
                        Title
                      </label>
                    </div>
                    <div class="card-body-content">
                      {{job.title ? job.title : 'No information available'}}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label>
                        Company
                      </label>
                    </div>
                    <div class="card-body-content">
                      {{job.company ? job.company.name : 'No information available'}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ng-template #noJobs>
            <div class="card-body-content">
              No information available
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>