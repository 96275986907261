import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from 'environments/environment';

@Injectable()
export class ChooseMergeCandidateModalService{

    constructor(private http: HttpClient){ }

    getCandidates(candidate_name): Observable<any>{
        return this.http.get(environment.platformBackendURL + '/candidates?name=' + candidate_name);
    }
}
