<div class="d-flex align-items-center">
    <div class="mr-3">
        <img [src]="setAvatar()" class="height-35 width-35 rounded-circle margin-table-pic">
    </div>
    <div class="line-height-20-px">
        <div class="d-flex">
            <a *ngIf="candidateData.linkedin" class="mr-2" [href]="candidateData.linkedin" target="_blank">
                <img [src]="globals.linkedin">
            </a>
            <div *ngIf="candidateData.name" class="cursor-pointer big-search-primary-color" style="text-decoration: underline;" (click)="nameClick($event)">
                {{candidateData.name}}
            </div>
        </div>
    </div>
</div>

<ng-template #popContent>
    <div class="card scroll aggird-popover">
        <ul class="list-group list-group-flush">
          
        </ul>
      </div>
</ng-template>

