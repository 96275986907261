<div *ngIf="loadComponent">
    <nav ngbNav id="tab-shadow" class="nav-tabs" #acc="ngbNav">
      <ng-container [ngbNavItem]="'ngb-tab-0'">
        <a ngbNavLink>Details</a>
        <ng-template ngbNavContent>
          <div class="card card-top scroll scroll-tabs">
            <ngb-accordion id="filter-accordion" [(activeIds)]="activeIds" #acc="ngbAccordion" class="ng-accordion-append"
              (panelChange)="preventDefault ? beforeChange($event) : ''">
              <ngb-panel id="details" class="mt-2">
                <ng-template ngbPanelHeader>
                  <div class="form" (click)="acc.toggle('details')">
                    <div class="form-section">
                      Details
                      <div
                        class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2"
                        (click)="editForm('details')">
                        edit
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent *ngIf="!edit.details">
                    <div class="card-block">
                        <div class="card-body">
                            <div class="form-group">
                                <div class="card-body-title-basic">
                                    <label>
                                        Name
                                    </label>
                                </div>
                                <div class="card-body-content">{{consultantCompany.name ? consultantCompany.name : 'no information available'}}</div>
                            </div>
                            <div class="form-group">
                                <div class="card-body-title-basic">
                                    <label>
                                        Logo URL
                                    </label>
                                </div>
                                <div class="card-body-content">{{consultantCompany.photo ? consultantCompany.photo : 'no information available'}}</div>
                            </div>
                            <div class="form-group">
                                <div class="card-body-title-basic">
                                    <label>
                                        Linkedin URL
                                    </label>
                                </div>
                                <div class="card-body-content">{{consultantCompany.linkedin_url ? consultantCompany.linkedin_url : 'no information available'}}</div>
                            </div>
                            <div class="form-group">
                                <div class="card-body-title-basic">
                                    <label>
                                        Website URL
                                    </label>
                                </div>
                                <div class="card-body-content">{{consultantCompany.website_url ? consultantCompany.website_url : 'no information available'}}</div>
                            </div>
                            <div class="form-group">
                                <div class="card-body-title-basic">
                                    <label>
                                        Contact person image
                                    </label>
                                </div>
                                <div class="card-body-content">{{consultantCompany.contact && consultantCompany.contact.photo ? consultantCompany.contact.photo : 'no information available'}}</div>
                            </div>
                            <div class="form-group">
                                <div class="card-body-title-basic">
                                    <label>
                                        Name of contact person
                                    </label>
                                </div>
                                <div class="card-body-content">{{consultantCompany.contact && consultantCompany.contact.name ? consultantCompany.contact.name : 'no information available'}}</div>
                            </div>
                            <div class="form-group">
                                <div class="card-body-title-basic">
                                    <label>
                                        Email of contact person
                                    </label>
                                </div>
                                <div class="card-body-content">{{consultantCompany.contact && consultantCompany.contact.email ? consultantCompany.contact.email : 'no information available'}}</div>
                            </div>
                            <div class="form-group">
                                <div class="card-body-title-basic">
                                    <label>
                                        Phone of contact person
                                    </label>
                                </div>
                                <div class="card-body-content">{{consultantCompany.contact && consultantCompany.contact.phone ? consultantCompany.contact.phone : 'no information available'}}</div>
                            </div>
                            <div class="form-group">
                                <div class="card-body-title-basic">
                                    <label>Consultants</label>
                                </div>
                                <div class="card-body-content" *ngIf="consultantCompany.consultants.length">
                                    <span *ngFor="let consultant of consultantCompany.consultants">
                                        {{consultant.name ? consultant.name : ''}}
                                    </span>
                                </div>
                                <div class="card-body-content" *ngIf="!consultantCompany.consultants.length">
                                    no information available
                                </div>
                            </div>
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                  <label>Xero contact</label>
                              </div>
                              <div class="card-body-content" *ngIf="consultantCompany.xero_contact">
                                  <span>
                                      {{consultantCompany.xero_contact.name}}
                                  </span>
                                  <span *ngIf="consultantCompany.xero_contact.email">
                                    ({{consultantCompany.xero_contact.email}})
                                  </span>
                              </div>
                              <div class="card-body-content" *ngIf="!consultantCompany.xero_contact">
                                  no information available
                              </div>
                          </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbPanelContent *ngIf="edit.details">
                    <div class="card-block">
                        <div class="card-body">
                            <div class="form-group">
                                <div class="card-body-title-basic">
                                    <label>
                                        * Name
                                        <span>
                                            (Required field)
                                        </span>
                                    </label>
                                </div>
                                <div class="card-body-content">
                                    <ng-select [items]="companies | async" #companiesSelect [hideSelected]="true"
                                        bindLabel="name" bindValue="name" [loading]="companyLoading"
                                        [typeahead]="companyinput$" [(ngModel)]="consultantCompany.name"
                                        [clearable]="true" placeholder="Select company"
                                        (change)="setCompanyData($event)" (clear)="initCompanyData()">
                                        <ng-template ng-option-tmp let-item="item" let-index="i">
                                            <div class="media mb-1" *ngIf="item.dealroom_data; else noDealroom">
                                                <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                                                    [src]="item.dealroom_data ? (item.dealroom_data.images['32x32'] ? item.dealroom_data.images['32x32'] : globals.companyAvatar) : ''"
                                                    (error)="item.dealroom_data.images = globals.companyAvatar" />
                                                <div class="media-body">
                                                    <div class="media-body-title mt-1 mb-0">{{item.name}}<span
                                                            *ngIf="item.dealroom_data.website_url">,
                                                            {{item.website_url}}</span></div>
                                                    <p
                                                        *ngIf="item.dealroom_data.hq_locations && item.dealroom_data.hq_locations.length">
                                                        <i class="tt-icon-sm icon-ttrelocation"></i>
                                                        <span
                                                            *ngFor="let location of item.dealroom_data.hq_locations;let last = last">
                                                            {{location.address}} {{!last ? ' (HQ), ' : ' (HQ)'}}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <ng-template #noDealroom>
                                                <div class="media mb-1">
                                                    <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                                                        [src]="item.photo || (item.images ? (item.images['32x32'] ? item.images['32x32'] : globals.companyAvatar) : '')"
                                                        (error)="item.images = globals.companyAvatar" />
                                                    <div class="media-body">
                                                        <div class="media-body-title mt-1 mb-0">{{item.name}}<span
                                                                *ngIf="item.website_url">, {{item.website_url}}</span>
                                                        </div>
                                                        <p *ngIf="item.hq_locations && item.hq_locations.length">
                                                            <i class="tt-icon-sm icon-ttrelocation"></i>
                                                            <span
                                                                *ngFor="let location of item.hq_locations;let last = last">
                                                                {{location.address}} {{!last ? ' (HQ), ' : ' (HQ)'}}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>

                                        </ng-template>
                                        <ng-template *ngIf="companiesSelect.searchTerm" ng-footer-tmp
                                            let-search="searchTerm">
                                            <div class="mb-1 pull-right">
                                                <button class="btn bg-secondary-color btn-position pull-right mb-0"
                                                    (click)="addCompany(search)">Add company {{search}}</button>
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="card-body-title-basic">
                                    <label>
                                        Logo URL
                                    </label>
                                </div>
                                <div class="media mb-3">
                                    <img class="align-self-center mr-3 height-40 width-40 rounded-circle"
                                        [src]="consultantCompany.photo || globals.companyAvatar">
                                    <div class="media-body">
                                        <input type="text" class="form-control" placeholder="Insert logo url"
                                            [(ngModel)]="consultantCompany.photo" [ngModelOptions]="{standalone: true}"
                                            (focusout)="changed ? editConsultantCompany('photo' ,consultantCompany.photo) : ''" (ngModelChange)="onChange($event)">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="card-body-title-basic">
                                    <label>
                                        Linkedin URL (Please enter the URL in the following format: https://www.linkedin.com/company/companyname/)
                                    </label>
                                </div>
                                <div class="card-body-content">
                                    <input type="text" class="form-control" placeholder="Insert linkedin url"
                                        [(ngModel)]="consultantCompany.linkedin_url"
                                        [ngModelOptions]="{standalone: true}"
                                        (focusout)="changed ? editConsultantCompany('linkedin_url' ,consultantCompany.linkedin_url) : ''" (ngModelChange)="onChange($event)">
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="card-body-title-basic">
                                    <label>
                                        Website URL
                                    </label>
                                </div>
                                <div class="card-body-content">
                                    <input type="text" class="form-control" placeholder="Insert website url"
                                        [(ngModel)]="consultantCompany.website_url"
                                        [ngModelOptions]="{standalone: true}"
                                        (focusout)="changed ? editConsultantCompany('website_url' ,consultantCompany.website_url) : ''" (ngModelChange)="onChange($event)">
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="card-body-title-basic">
                                    <label>
                                        Contact person image
                                    </label>
                                </div>
                                <div class="media mb-3">
                                    <img class="align-self-center mr-3 height-40 width-40 rounded-circle"
                                        [src]="consultantCompany.contact.photo || globals.companyAvatar">
                                    <div class="media-body">
                                        <input type="text" class="form-control" placeholder="Insert image url"
                                            [(ngModel)]="consultantCompany.contact.photo"
                                            [ngModelOptions]="{standalone: true}"
                                            (focusout)="changed ? editConsultantCompany('contact.photo' ,consultantCompany.contact.photo) : ''" (ngModelChange)="onChange($event)">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="card-body-title-basic">
                                    <label>
                                        Name of contact person
                                    </label>
                                </div>
                                <div class="card-body-content">
                                    <input type="text" class="form-control" placeholder="Insert full name"
                                        [(ngModel)]="consultantCompany.contact.name"
                                        [ngModelOptions]="{standalone: true}"
                                        (focusout)="changed ? editConsultantCompany('contact.name' ,consultantCompany.contact.name) : ''" (ngModelChange)="onChange($event)">
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="card-body-title-basic">
                                    <label>
                                        Email of contact person
                                    </label>
                                </div>
                                <div class="card-body-content">
                                    <input type="text" class="form-control" placeholder="Insert email"
                                        [(ngModel)]="consultantCompany.contact.email"
                                        [ngModelOptions]="{standalone: true}"
                                        (focusout)="changed ? editConsultantCompany('contact.email' ,consultantCompany.contact.email) : ''" (ngModelChange)="onChange($event)">
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="card-body-title-basic">
                                    <label>
                                        Phone of contact person
                                    </label>
                                </div>
                                <div class="card-body-content">
                                    <input type="text" class="form-control" placeholder="Insert phone number"
                                        [(ngModel)]="consultantCompany.contact.phone"
                                        [ngModelOptions]="{standalone: true}"
                                        (focusout)="changed ? editConsultantCompany('contact.phone' ,consultantCompany.contact.phone) : ''" (ngModelChange)="onChange($event)">
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="card-body-title-basic">
                                    <label>Consultants</label>
                                </div>
                                <div class="card-body-content">
                                    <ng-select [items]="consultants" [clearable]="true" placeholder="Select consultants"
                                        bindLabel="name" (open)="getConsultants()" [multiple]="true"
                                        [(ngModel)]="consultantCompany.consultants"
                                        (change)="editConsultantCompany('consultants' ,consultantCompany.consultants)">
                                        <ng-template ng-option-tmp let-item="item" let-index="i">
                                            <div class="media mb-1">
                                                <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                                                    [src]="item.picture_file_url"
                                                    (error)="item.picture_file_url = globals.avatar" />
                                                <div class="media-body">
                                                    <div class="media-body-title mt-1 mb-0"><b>{{item.name}}</b></div>
                                                    <span>{{item.email}}</span>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                  <label>Xero contact</label>
                              </div>
                              <div class="card-body-content">
                                  <ng-select [items]="xeroContacts" [clearable]="true" placeholder="Select xero contact"
                                      bindLabel="name" (open)="getXeroContacts()" [multiple]="false"
                                      [(ngModel)]="consultantCompany.xero_contact" (change)="editConsultantCompany('xero_contact', consultantCompany.xero_contact)">
                                      <ng-template ng-option-tmp let-item="item" let-index="i">
                                          <div class="media mb-1">
                                              <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                                                  [src]="item.picture_file_url"
                                                  (error)="item.picture_file_url = globals.avatar" />
                                              <div class="media-body">
                                                  <div class="media-body-title mt-1 mb-0"><b>{{item.name}}</b></div>
                                                  <span>{{item.email}}</span>
                                              </div>
                                          </div>
                                      </ng-template>
                                  </ng-select>
                              </div>
                          </div>
                        </div>
                    </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </ng-template>
      </ng-container>
      <ng-container [ngbNavItem]="'ngb-tab-1'">
        <a ngbNavLink>
          <div>Blacklist</div>
        </a>
        <ng-template ngbNavContent>
          <div class="card card-top scroll scroll-tabs">
            <ngb-accordion id="filter-accordion" [(activeIds)]="activeIds" #acc="ngbAccordion" class="ng-accordion-append"
              (panelChange)="preventDefault ? beforeChange($event) : ''">
              <ngb-panel id="blacklisted_companies" class="mt-2">
                <ng-template ngbPanelHeader>
                  <div class="form" (click)="acc.toggle('blacklisted_companies')">
                    <div class="form-section">
                      Blacklist
                      <div
                        class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2"
                        (click)="editForm('blacklist')">
                        edit
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="row card-block" *ngIf="edit.blacklist">
                    <div class="card card-top scroll">
                        <div class="card-block">
                            <div class="card-body">
                                <div class="form-group">
                                    <div class="card-body-title-basic">
                                        <label>Blacklisted Companies</label>
                                    </div>
                                    <div class="card-body-content">
                                        <div class="row">
                                            <div class="col-12">
                                                <ng-select [items]="companies | async" #blacklistSelect
                                                    [hideSelected]="true" dropdownPosition="bottom" [addTag]="addTagCompany"
                                                    bindLabel="name" [loading]="companyLoading" [multiple]="true"
                                                    [typeahead]="companyinput$"
                                                    [(ngModel)]="consultantCompany.blacklistedCompanies"
                                                    (remove)="loadCompany()" [clearable]="true" placeholder="Select company"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (change)="editConsultantCompany('blacklisted_companies' ,consultantCompany.blacklistedCompanies)">
                                                    <ng-template ng-option-tmp let-item="item" let-index="i">
                                                        <div class="media mb-1" [ngStyle]="{position: relative}">
                                                            <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                                                                [src]="item.images['32x32'] ? item.images['32x32'] : globals.companyAvatar"
                                                                (error)="item.images = globals.companyAvatar" />
                                                            <div class="media-body">
                                                                <div class="media-body-title mt-1 mb-0">{{item.name}},
                                                                    {{item.website_url}}</div>
                                                                <p *ngIf="item.hq_locations && item.hq_locations.length"><i
                                                                        class="tt-icon-sm icon-ttrelocation"></i>
                                                                    <span
                                                                        *ngFor="let location of item.hq_locations;let last = last">
                                                                        {{location.address}} {{!last ? ' (HQ), ' : ' (HQ)'}}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                    <ng-template ng-tag-tmp let-search="searchTerm">
                                                        <button
                                                            class="btn bg-secondary-color btn-position pull-right mb-0">Add
                                                            company
                                                            {{search}}</button>
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                            <div class="col-12 d-flex align-items-center justify-content-center m-3 ">
                                                <span class="text-color-700">Bulk import companies from .csv</span>
                                                <button class="btn btn-sm bg-secondary-color btn-position mb-0 ml-2"
                                                    (click)="blacklistedFileInput.click()">
                                                    choose .csv
                                                    <span *ngIf="csv_loading"
                                                        class="badge-icon-sm icon icon-loading icon-sm ml-1"></span>
                                                </button>
                                                <input type="file" ng2FileSelect [uploader]="blacklistCsvUploader"
                                                    #blacklistedFileInput (click)="onFileClick($event)"
                                                    (change)="onBlacklistedCompanyInsertCSV()" class="d-none">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="card-body-title-basic">
                                        <label>Blacklisted candidates</label>
                                    </div>
                                    <div class="card-body-content">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="row card-block">
                                                    <div class="col-12">
                                                      <div class="form-group">
                                                        <div class="card-body-content">
                                                          <div class="form-group">
                                                            <input class="form-control" type="text" [(ngModel)]="blacklistedCandidate"
                                                              [ngModelOptions]="{standalone: true}" placeholder="+ blacklisted candidates"
                                                              (keyup.enter)="addBlacklistedCandidate()"/>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div class="row card-block" *ngIf="consultantCompany.blacklistedCandidates.length">
                                                    <div class="col-12">
                                                      <div class="form-group">
                                                        <div class="card-body-content">
                                                          <ul class="media-list media-bordered pl-0">
                                                            <li class="media "*ngFor="let blacklistedCandidate of consultantCompany.blacklistedCandidates; let i = index">
                                                              <div class="col-11">
                                                                <a [href]="blacklistedCandidate.linkedin_url" target="_blank">{{blacklistedCandidate.linkedin_url}}</a>
                                                              </div>
                                                              <div class="col-1">
                                                                <i class="tt-icon-sm icon-ttdelete pull-right m-2" (click)="deleteBlacklistedCandidate(i)"></i>
                                                              </div>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div class="row card-block" *ngIf="!consultantCompany.blacklistedCandidates.length">
                                                    <div class="col-12">
                                                      No candidates available
                                                    </div>
                                                  </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                  </div>
                  <div *ngIf="!edit.blacklist">
                    <div class="card card-top scroll">
                        <div class="card-block">
                            <div class="card-body">
                                <div class="form-group">
                                    <div class="card-body-title-basic">
                                        <label>Blacklisted Companies</label>
                                    </div>
                                    <div class="card-body-content">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="row card-block">
                                                  <div class="row card-block" *ngIf="consultantCompany.blacklistedCompanies.length">
                                                    <div class="col-12">
                                                      <div class="form-group">
                                                        <div class="card-body-content">
                                                          <ul class="media-list media-bordered pl-0">
                                                            <li class="media "*ngFor="let blacklistedCompany of consultantCompany.blacklistedCompanies; let i = index">
                                                              <div class="col-12">
                                                               {{blacklistedCompany.name}}
                                                              </div>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div class="row card-block" *ngIf="!consultantCompany.blacklistedCompanies.length">
                                                    <div class="col-12">
                                                      No companies available
                                                    </div>
                                                  </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="card-body-title-basic">
                                        <label>Blacklisted Candidates</label>
                                    </div>
                                    <div class="card-body-content">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="row card-block">
                                                  <div class="row card-block" *ngIf="consultantCompany.blacklistedCandidates.length">
                                                    <div class="col-12">
                                                      <div class="form-group">
                                                        <div class="card-body-content">
                                                          <ul class="media-list media-bordered pl-0">
                                                            <li class="media "*ngFor="let blacklistedCandidate of consultantCompany.blacklistedCandidates; let i = index">
                                                              <div class="col-12">
                                                                <a [href]="blacklistedCandidate.linkedin_url" target="_blank">{{blacklistedCandidate.linkedin_url}}</a>
                                                              </div>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div class="row card-block" *ngIf="!consultantCompany.blacklistedCandidates.length">
                                                    <div class="col-12">
                                                      No candidates available
                                                    </div>
                                                  </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </ng-template>
      </ng-container>
    </nav>
    <div [ngbNavOutlet]="acc"></div>
    <div class="mt-2">
      <label><strong>Important:</strong> Data is saved automatically when you navigate out of a field or
        select an
        item from the dropdown list</label>
    </div>
  </div>
