<div class="full-width scroll scroll-filter mt-5">
  <div class="card-block">
    <ngb-accordion id="filter-accordion" #acc="ngbAccordion" [(activeIds)]="activeId"
      (panelChange)="saveAccordionsState($event)">
      <ngb-panel *ngFor="let filter of filters; let i = index" [id]="filter.key">
        <ng-template ngbPanelHeader>
          <div class="form" (click)="acc.toggle(filter.key)">
            <div class="form-section">
              {{filter.key | removeUndersoreCapitalizeFirst}}
              <i class="ft-filter tt-icon-sm"></i>
              <span *ngIf="counts[filter.key]" style="right: -20px"
                class="notification badge-inverse badge-pill badge-big-search-primary-inverse">{{counts[filter.key]}}</span>
            </div>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <div *ngIf="'tags' !== filter.key && 'hq_locations' !== filter.key">
            <div class="row card-block">
              <div *ngFor="let item of filter.items; let i=index" class="col-6">
                <div class="form-group">
                  <div class="custom-control custom-checkbox mb-2 mr-sm-2 mb-sm-0">
                    <input type="checkbox" [value]="item.isChecked" [checked]="item.isChecked"
                      class="custom-control-input" id="item{{item.name}}"
                      (change)="onCheckbox($event, item, filter.key)">
                    <label class="custom-control-label cursor-pointer" for="item{{item.name}}">
                      <span>
                        {{item.name}}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="'tags' === filter.key || 'hq_locations' === filter.key">
            <div class="row card-block">
              <div class="col-12">
                <ng-select appendTo="body" bindLabel="name" dropdownPosition="bottom"
                  [(ngModel)]="dropdownModel[filter.key]" [closeOnSelect]="false" [typeahead]="searchInput$"
                  [loading]="filtersLoading" [ngModelOptions]="{standalone: true}" [items]="filtersArray$ | async"
                  [multiple]="true" (add)="onItemSelect($event, filter.key)"
                  (remove)="onItemDeSelect($event,filter.key)" (rendered)="openSelect(select)"
                  [placeholder]="'tags' === filter.key ? 'Search tags' : 'Search locations'" (clear)="onFilterClear(filter.key)">
                </ng-select>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>

  </div>
  <div class="position-bottom full-width">
    <button type="button" class="btn pull-right bg-secondary-color" (click)="onApply()">
      Apply
    </button>
  </div>
</div>
