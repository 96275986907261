import { takeWhile, distinctUntilChanged, debounceTime, tap, switchMap, catchError } from 'rxjs/operators';
import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { Observable, Subject, of, concat } from 'rxjs';
import { CompaniesFilterService } from './companies-filter.service';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Globals } from 'app/globals';
import { HttpClient } from '@angular/common/http';
import { CommunicationService } from 'app/shared/service/communication.service';

@Component({
  selector: 'app-companies-filter',
  templateUrl: './companies-filter.component.html',
  styleUrls: ['./companies-filter.component.scss']
})
export class CompaniesFilterComponent implements OnInit{
  @Output() dismissDrawer: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() data: any = {};

  componentAlive = true;

  filters: any;

  //data: any;
  dropdownSettings = {};

  findFiltersByKey: any;

  filtersArray$: Observable<any[]>;

  public searchInput$ = new Subject<string>();

  filtersLoading = false;

  dropdownModel = {
    tags: [],
    hq_locations: []
  };

  counts = {
    client_focus: 0,
    revenues: 0,
    performance: 0,
    fundings: 0,
    industries: 0,
    delivery_methods: 0,
    growth_stages: 0,
    company_status: 0,
    employees: 0,
    tags: 0,
    hq_locations: 0
  };

  showComponent = false;

  activeId: string[] = [];

  @ViewChild('acc', { static: true }) acc: NgbAccordion;

  constructor(private companiesService: CompaniesFilterService, public globals: Globals, private http: HttpClient, private communicationService: CommunicationService){

    this.communicationService.changeCompaniesFilters$.pipe(takeWhile(() => this.componentAlive))
      .subscribe((data) => {
        if (data){
          this.updateFilter(data);
        }
      });
  }

  ngOnInit(){
    const categories = "performance,fundings,client_focus,revenues,industries,delivery_methods,growth_stages,company_status,employees";
    const grouped = [];
    this.companiesService.getDealroomFiltersByCategories(categories)
      .subscribe((filters) => {
        this.filters = filters;
        let firstIteration = true;
        this.filters.forEach(element => {
          element.showContent = firstIteration;
          firstIteration = false;
          if (element.items){
            // find all selected items by key
            this.findFiltersByKey = grouped[element.key];
            if (this.findFiltersByKey){
              if (element.key === 'tags' || element.key === 'hq_locations'){
                this.findFiltersByKey.forEach(elm => {
                  this.dropdownModel[element.key].push({ name: elm, category: element.key });
                  this.counts[element.key]++;
                });
              } else {
                // for each filter (checkbox) check if should be selected or not
                // based of logic if it is found in corresponding key array in selected filters
                element.items.forEach(elm => {
                  this.findFiltersByKey.forEach(filter => {
                    if (!elm.isChecked){
                      elm.isChecked = filter === elm.name;
                      if (elm.isChecked){
                        this.counts[element.key]++;
                      }
                    }
                  });
                });
              }

            } else {
              element.items.forEach(elm => {
                elm.isChecked = false;
              });
            }
          }
        });
        this.showComponent = true;
      });
    // create new array with key category and add corresponding items to each array
    if (this.data.filters.length > 0){
      this.data.filters.forEach(function (data){
        grouped[data.category] = grouped[data.category] || [];
        grouped[data.category].push(data.name);
      });
    }
  }

  updateFilter(data){
    if (data.clearAll){
      this.filters.forEach(filter => {
        this.counts[filter.key] = 0;
        if (filter.key === "tags" || filter.key === "hq_locations"){
          filter.items = [];
          this.dropdownModel[filter.key] = [...[]];
        } else {
          filter.items.forEach(elm => {
            elm.isChecked = false;
          });
        }
      });
    } else if (data.onSearchSelect){
      let firstIteration = true;
      this.filters.forEach(element => {
        element.showContent = firstIteration;
        firstIteration = false;
        if (element.items){
          // find all selected items by key
          this.findFiltersByKey = data.filters[element.key];
          if (element.key === 'tags' || element.key === 'hq_locations'){
            this.dropdownModel[element.key] = [];
            this.counts[element.key] = 0;
            if (this.findFiltersByKey){
              this.findFiltersByKey.forEach(elm => {
                this.dropdownModel[element.key].push({ name: elm, category: element.key });
                this.counts[element.key]++;
              });
            }
            this.dropdownModel[element.key] = [...this.dropdownModel[element.key]];
          } else {
            // for each filter (checkbox) check if should be selected or not
            // based of logic if it is found in corresponding key array in selected filters
            this.counts[element.key] = 0;
            element.items.forEach(elm => {
              if (this.findFiltersByKey){
                const itemIndex = this.findFiltersByKey.findIndex(item => item === elm.name);
                if (itemIndex !== -1){
                  this.counts[element.key]++;
                  elm.isChecked = true;
                } else {
                  elm.isChecked = false;
                }
              } else {
                element.items.forEach(elm => {
                  elm.isChecked = false;
                });
              }
            });
          }
        }
      });
    } else {
      const category = this.filters.find(item => item.key === data.category);
      if (category){
        const filter = category.items.find(item => item.name === data.name);
        if (data.category === "tags" || data.category === 'hq_locations'){
          if (data.selected){
            this.counts[data.category]++;
            this.dropdownModel[data.category] = [...this.dropdownModel[data.category], { name: data.name, category: data.category }];
          } else {
            this.counts[data.category]--;
            const index = this.dropdownModel[data.category].findIndex(comp => comp.name === data.name);
            this.dropdownModel[data.category].splice(index, 1);
            this.dropdownModel[data.category] = [...this.dropdownModel[data.category]];
          }
        } else {
          if (filter){
            filter.isChecked = data.selected;
            if (filter.isChecked){
              this.counts[data.category]++;
            } else {
              this.counts[data.category]--;
            }
          }
        }
      }
    }
  }

  saveAccordionsState(event){
    if (event.nextState){
      this.activeId.push(event.panelId);
    } else {
      this.activeId = this.activeId.filter(item => item !== event.panelId);
    }
    if (event.panelId === 'tags' || event.panelId === 'hq_locations'){
      this.loadFilters(event.panelId);
    }
    this.filters.forEach(filter => {
      filter.showContent = filter.key === event.panelId;
    });
  }

  onCheckbox($event, item, key){
    $event.target.checked ? item.isChecked = true : item.isChecked = false;
    item.isChecked = $event.target.checked;
    if (item.isChecked){
      this.counts[key]++;
    } else {
      this.counts[key]--;
    }
  }

  openSelect(select: NgSelectComponent){
    select.open();
  }

  onItemSelect(item: any, key){
    item.isChecked = true;
    this.counts[key]++;
  }

  onItemDeSelect(item: any, key){
    const itm = item.value;
    this.counts[key]--;
    itm.isChecked = false;
  }

  onApply(){
    const tags = this.filters.find(item => item.key === 'tags');
    const hq_locations = this.filters.find(item => item.key === 'hq_locations');
    tags.items = this.dropdownModel.tags.length > 0 ? this.dropdownModel.tags : [];
    hq_locations.items = this.dropdownModel.hq_locations.length > 0 ? this.dropdownModel.hq_locations : [];

    //this.activeModal.close(this.filters);
    this.communicationService.emitCompaniesFilters(this.filters);
    this.dismissDrawer.emit(true);

  }

  private loadFilters(key){
    this.filtersArray$ = concat(
      of([]), // default items
      this.searchInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.filtersLoading = true),
        switchMap(term =>
          this.companiesService.getDealroomFilters(term, key).pipe(
            catchError(() => of([])), // empty list on error
            tap(() =>
              this.filtersLoading = false
            )
          ))
      )
    );
  }

  onFilterClear(key){
    this.counts[key] = 0;
  }

}
