<div class="full-width">
  <div class="card scroll mt-5">
    <div class="card-block">
      <div class="card-body">
        <div class="form">
          <div class="form-section">
            {{globals.labels.placeholders.shared_page.add_revenue.revenue_title}}
          </div>
          <div *ngIf="invoiceValidationIssues.length" class="form-group">
            <i class="icon-ttcritical error-color tt-icon-lg"></i>
            <strong>
              You have errors, fix and resubmit or skip validation:
            </strong>
            <div *ngFor="let invoiceIssue of invoiceValidationIssues">
              <div *ngIf="!invoiceIssue.passed" id={invoiceIssue.check}
                class="card card-block m-1 p-2 invoice-validation-failure">
                {{invoiceIssue.description}}
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="card-body-title-basic">
              <label> {{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                {{globals.labels.placeholders.shared_page.add_revenue.revenue_type}}
                <span *ngIf="!validForm && !revenueData.type" [ngClass]="{'primary-color': !revenueData.type}">
                  {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                </span>
              </label>
            </div>
            <div class="card-body-content">
              <ng-select bindLabel="description" dropdownPosition="bottom" [items]="revenueTypes"
                [(ngModel)]="revenueData.type" [ngModelOptions]="{standalone: true}" (open)="getResources()"
                [placeholder]="globals.labels.placeholders.inputs.shared_page.add_revenue.select_revenue_type"
                (clear)="clearTypeFileds()" (ngModelChange)="clearTypeFileds()">
              </ng-select>
            </div>
          </div>
          <div *ngIf="revenueData.type">
            <div *ngIf="revenueData.type.value=='placement'">
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label> {{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                    Candidate
                    <span *ngIf="!validForm && !revenueData.parent_id.candidate"
                      [ngClass]="{'primary-color': !revenueData.parent_id.candidate}">
                      {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                    </span>
                  </label>
                </div>
                <div class="card-body-content">
                  <ng-select 
                    [items]="candidates$ | async" 
                    bindLabel="name" 
                    placeholder="Select candidate"
                    dropdownPosition="bottom"
                    [minTermLength]="minLengthTerm" 
                    [loading]="candidateLoading"
                    typeToSearchText="Please enter {{minLengthTerm}} or more characters" 
                    [typeahead]="candidateInput$"
                    [(ngModel)]="revenueData.parent_id.candidate"
                    (ngModelChange)="updateInvoiceRefAndDesc()"
                    [ngModelOptions]="{standalone: true}">
                    <ng-template ng-option-tmp let-item="item" let-index="i">
                      <div class="media mb-1">
                        <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                          [src]="item.photo ? item.photo : globals.avatar" (error)="item.photo = globals.avatar" />
                        <div class="media-body">
                          <div class="media-body-title mt-1 mb-0">{{item.name}}</div>
                          <p *ngIf="item.current_job_title">
                            <i class="tt-icon-sm icon-ttcareer"></i>
                            {{item.current_job_title || item.current_employer ? item.current_job_title + '@ ' +
                            item.current_employer : 'No job title available'}}
                          </p>
                        </div>
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
            </div>
            <div>
              <div class="form-group">
                <div class="card-body-title-basic"><label> Job
                  </label>
                </div>
                <div class="card-body-content">
                  <ng-select 
                    [items]="jobs$ | async" 
                    bindLabel="job_title" 
                    placeholder="Select job"
                    dropdownPosition="bottom"
                    [minTermLength]="minLengthTerm" 
                    [loading]="jobLoading"
                    typeToSearchText="Please enter {{minLengthTerm}} or more characters" 
                    [typeahead]="jobInput$"
                    [(ngModel)]="revenueData.parent_id.job"
                    (ngModelChange)="updateInvoiceRefAndDesc()"
                    [ngModelOptions]="{standalone: true}">
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-12 col-lg-6">
                  <div class="card-body-title-basic">
                    <label> {{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                      {{globals.labels.placeholders.shared_page.add_revenue.amount}}
                      <span *ngIf="!validForm && !revenueData.amount"
                        [ngClass]="{'primary-color': !revenueData.amount}">
                        {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                      </span>
                    </label>
                  </div>
                  <div class="card-body-content">
                    <input class="form-control" type="number" min="0" step="any" [(ngModel)]="revenueData.amount"
                      [ngModelOptions]="{standalone: true}"
                      [placeholder]="globals.labels.placeholders.inputs.shared_page.add_revenue.insert_amount" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card-body-title-basic">
                    <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                      {{globals.labels.placeholders.shared_page.add_revenue.currency}}
                      <span *ngIf="!validForm && !revenueData.currency"
                        [ngClass]="{'primary-color': !revenueData.currency}">
                        {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                      </span>
                    </label>
                  </div>
                  <div class="card-body-content">
                    <ng-select bindLabel="description" dropdownPosition="bottom" (open)="getResources()"
                      [placeholder]="globals.labels.placeholders.inputs.shared_page.add_revenue.select_currency"
                      [items]="currencies" [(ngModel)]="revenueData.currency" [ngModelOptions]="{standalone: true}">
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label> {{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                  POD Lead
                  <span *ngIf="!validForm && !revenueData.pod_lead"
                    [ngClass]="{'primary-color': !revenueData.pod_lead}">
                    {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                  </span>
                </label>
              </div>
              <div class="card-body-content">
                <ng-select bindLabel="name" dropdownPosition="bottom" (open)="getPodLeads()"
                  placeholder="Select pod lead" [items]="podLeads" [(ngModel)]="revenueData.pod_lead"
                  [ngModelOptions]="{standalone: true}">
                  <ng-template ng-option-tmp let-item="item" let-index="i">
                    <div class="media mb-1">
                      <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                        [src]="item.photo ? item.photo : globals.avatar" (error)="item.photo = globals.avatar" />
                      <div class="media-body">
                        <div class="media-body-title mt-1 mb-0">{{item.name}}</div>
                        <p *ngIf="item.pod_name">
                          <i class="tt-icon-sm icon-ttcareer"></i>
                          Pod: {{item.pod_name}}
                        </p>
                      </div>
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic"><label>POD Members
                </label>
              </div>
              <div class="card-body-content">
                <ng-select bindLabel="name" dropdownPosition="bottom" (open)="getPodUsers()"
                  placeholder="Select pod members" [items]="podUsers" [(ngModel)]="revenueData.pod_users"
                  [multiple]="true" [ngModelOptions]="{standalone: true}">
                  <ng-template ng-option-tmp let-item="item" let-index="i">
                    <div class="media mb-1">
                      <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                        [src]="item.photo ? item.photo : globals.avatar" (error)="item.photo = globals.avatar" />
                      <div class="media-body">
                        <div class="media-body-title mt-1 mb-0">{{item.name}}</div>
                        <p *ngIf="item.pod_name">
                          <i class="tt-icon-sm icon-ttcareer"></i>
                          Pod: {{item.pod_name}}
                        </p>
                      </div>
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}} Billing contact
                  <span *ngIf="!validForm && !invoiceData.contact_id"
                    [ngClass]="{'primary-color': !invoiceData.contact_id}">
                    {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                  </span>
                </label>
              </div>
              <div class="card-body-content">
                <ng-select bindLabel="name" bindValue="id" dropdownPosition="bottom" (open)="getBillingContacts()"
                  placeholder="Select billing contact" [items]="billingContacts" [(ngModel)]="invoiceData.contact_id"
                  [multiple]="false" [ngModelOptions]="{standalone: true}">
                  <ng-template ng-option-tmp let-item="item" let-index="i">
                    <div class="media mb-1">
                      <div class="media-body">
                        <div class="media-body-title mt-1 mb-0">{{item.name}}</div>
                        <div *ngIf="item.addresses[0].AddressLine1">
                          <i class="tt-icon-sm icon-ttrelocation"></i>
                          {{item.addresses[0].AddressLine1}} ({{item.addresses[0].City}},{{item.addresses[0].Country}})
                        </div>
                        <div *ngIf="item.email">
                          <i class="email-icon icon-ttmail mr-1"></i>
                          {{item.email}}
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}} Invoice reference
                  <span *ngIf="!validForm && !invoiceData.reference"
                    [ngClass]="{'primary-color': !invoiceData.reference}">
                    {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                  </span>
                </label>
                <i class="tt-icon-sm cursor-pointer ml-1" (click)="edit.editingReference = !edit.editingReference"
                  [ngClass]="edit.editingReference ? 'icon-note' : 'icon-check'"></i>
              </div>
              <div class="card-body-content">
                <input class="form-control" placeholder="Reference" [disabled]="edit.editingReference" type="text"
                  [(ngModel)]="invoiceData.reference" [ngModelOptions]="{standalone: true}" />
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}} Invoice description
                  <span *ngIf="!validForm && !invoiceData.description"
                    [ngClass]="{'primary-color': !invoiceData.description}">
                    {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                  </span>
                </label>
                <i class="tt-icon-sm cursor-pointer ml-1" (click)="edit.editingDescription = !edit.editingDescription"
                  [ngClass]="edit.editingDescription ? 'icon-note' : 'icon-check'"></i>
              </div>
              <div class="card-body-content">
                <input class="form-control" [disabled]="edit.editingDescription" type="text"
                  [(ngModel)]="invoiceData.description" placeholder="Description"
                  [ngModelOptions]="{standalone: true}" />
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}} Invoice due date
                  <span *ngIf="!validForm && !invoiceData.date"
                    [ngClass]="{'primary-color': !invoiceData.date}">
                    {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                  </span>
                </label>
                <i class="tt-icon-sm cursor-pointer ml-1" (click)="edit.editingDueDate = !edit.editingDueDate"
                  [ngClass]="edit.editingDueDate ? 'icon-note' : 'icon-check'"></i>
              </div>
              <div class="card-body-content">
                <input class="form-control" [disabled]="edit.editingDueDate" type="date"
                  [(ngModel)]="invoiceData.date" [ngModelOptions]="{standalone: true}" />
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}} VAT (Add 21% VAT for Netherlands, 0% VAT otherwise)
                  <span *ngIf="!validForm && !invoiceData.vat_type"
                    [ngClass]="{'primary-color': !invoiceData.vat_type}">
                    {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                  </span>
                </label>
              </div>
              <div class="card-body-content">
                <ng-select bindLabel="description" bindValue="value" dropdownPosition="bottom" [items]="vatTypes"
                  [(ngModel)]="invoiceData.vat_type" [ngModelOptions]="{standalone: true}"
                  placeholder="Select VAT type">
                </ng-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="position-bottom full-width mb-3 ml-0">
    <button type="button" class="btn pull-right bg-secondary-color mr-4" (click)="addRevenue(false)">Create revenue &
      send invoice</button>
    <button type="button" class="btn pull-left bg-primary-color" *ngIf="invoiceValidationIssues.length"
      (click)="addRevenue(true)"> Skip validation & send invoice</button>
  </div>
</div>