<div *ngIf="jobDataIsDownloaded">
  <div class="media m-4">
    <div class="media-body">
      <div class="media-body-title form-group display-inline-flex">
        <span class="align-self-center">{{job.job_title}}</span>
        <span class="badge-tags-inverse ml-3 align-self-center white"
          [ngClass]="job.state == 'Open' ? 'bg-success' : 'bg-error-color'">
          {{job.state}}
        </span>
      </div>
      <div class="media-body-content">
        <div class="row">
          <div class="col-4">
            <div class="form-group">
              <div *ngIf="job.location.length">
                <span *ngFor="let location of job.location;let last = last;">
                  <div>
                    <i class="fa fa-map-marker pr-1 pl-1"></i>
                    {{location.description}}
                  </div>
                </span>
              </div>
              <div *ngIf="!job.location.length">
                <i class="fa fa-map-marker pr-1 pl-1"></i>No location available
              </div>
            </div>
            <div class="form-group">
              <i class="fa fa-envelope mr-1">
              </i>{{job.contact && job.contact.email ? job.contact.email : 'No contact information'}}
            </div>
            <div class="form-group">
              <strong>Company:
              </strong>{{job.company && job.company.name ? job.company.name : 'No company information'}}
            </div>
            <div class="form-group">
              <div
                *ngIf="job.jobCompensation && (job.jobCompensation.annual_salary || job.jobCompensation.salary_from);then showSalary else showMsgSalary">
              </div>
              <ng-template #showSalary>
                <div
                  *ngIf="job.jobCompensation.annual_salary && job.jobCompensation.currency &&  job.jobCompensation.currency.value == 'EUR'">
                  <strong>Salary: </strong> {{job.jobCompensation.annual_salary}} {{job.jobCompensation.currency.value}}
                  &euro;
                </div>
                <div
                  *ngIf=" job.jobCompensation.annual_salary && job.jobCompensation.currency &&  job.jobCompensation.currency.value == 'GBP'">
                  <strong>Salary: </strong>{{job.jobCompensation.annual_salary}} {{job.jobCompensation.currency.value}}
                  &#163;
                </div>
                <div
                  *ngIf="job.jobCompensation.annual_salary && job.jobCompensation.currency &&  job.jobCompensation.currency.value == 'USD'">
                  <strong>Salary: </strong>{{job.jobCompensation.annual_salary}} {{job.jobCompensation.currency.value}}
                  &#36;
                </div>
                <div
                  *ngIf="!job.jobCompensation.annual_salary && job.jobCompensation.salary_from && job.jobCompensation.salary_to && job.jobCompensation.currency && job.jobCompensation.currency.value == 'EUR'">
                  <strong>Salary: </strong> {{job.jobCompensation.salary_from}}-{{job.jobCompensation.salary_to}}
                  {{job.jobCompensation.currency.value}}
                  &euro;
                </div>
                <div
                  *ngIf="!job.jobCompensation.annual_salary && job.jobCompensation.salary_from && job.jobCompensation.salary_to && job.jobCompensation.currency && job.jobCompensation.currency.value == 'GBP'">
                  <strong>Salary: </strong>{{job.jobCompensation.salary_from}}-{{job.jobCompensation.salary_to}}
                  {{job.jobCompensation.currency.value}}
                  &#163;
                </div>
                <div
                  *ngIf="!job.jobCompensation.annual_salary && job.jobCompensation.salary_from && job.jobCompensation.salary_to && job.jobCompensation.currency && job.jobCompensation.currency.value == 'USD'">
                  <strong>Salary: </strong>{{job.jobCompensation.salary_from}}-{{job.jobCompensation.salary_to}}
                  {{job.jobCompensation.currency.value}}
                  &#36;
                </div>
              </ng-template>
              <ng-template #showMsgSalary>
                <strong>Salary: </strong>No salary information
              </ng-template>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <strong>Stage: </strong>
              <ng-template #t let-fill="fill" let-last="last" let-index="index">
                <span class="bullet cursor-pointer" [class.full]="fill === 100">
                  <span
                    [ngClass]="job.current_stage[index].status ? 'success' : job.current_stage[index].status == false ? 'error-color' : 'skip-color'"
                    [style.width.%]="fill" [ngbTooltip]="tooltipClose" placement="bottom"
                    [innerHTML]="globals.labels ? globals.labels.placeholders.full_layout_page.omni_component.job.job_history.stage_rating : '&#x025CF;'"
                    [title]="jobTitles[index]">
                  </span>
                </span>
              </ng-template>
              <ngb-rating [(rate)]="stageRate" [starTemplate]="t" [readonly]="true" [max]="job.current_stage.length">
              </ngb-rating>
            </div>
            <div class="form-group">
              <strong>Status:
                <span [ngClass]="job.status && job.status.description == 'Active' ? 'success' : 'error-color'">
                  {{job.status ? job.status.description : 'Status is missing'}} </span></strong>
            </div>
            <div class="form-group">
              <strong>Type:</strong> {{job.type ? job.type.description : 'Job type is missing'}}
            </div>
            <div class="form-group">
              <strong>Priority:</strong> {{job.priority ? job.priority.description : 'No information'}}
            </div>
          </div>
          <div class="col-5">
            <div class="form-group">
              <strong> Campaigns metrics:</strong>
              <div
                *ngIf="job.metrics && job.metrics.response_rate && job.metrics.response_rate != '0.00';then showMetrics else showMetricsMsg">
              </div>
              <ng-template #showMetrics>
                <div class="card m-0 cursor-pointer" (click)="openMetricsModal()">
                  <div class="card-content">
                    <div class="px-3 py-3">
                      <div class="media">
                        <div class="media-body text-left">
                          <div class="card-metrics-title"> {{job.metrics.response_rate}}% Response rate
                            (View more details)
                          </div>
                        </div>
                        <div class="media-right align-self-center">
                          <i class="font-large-1 float-right success icon-pie-chart"></i>
                        </div>
                      </div>
                      <div class="mt-2 mb-0">
                        <ngb-progressbar type="success" [value]="job.metrics.response_rate" class="progress-bar-sm">
                        </ngb-progressbar>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template #showMetricsMsg>
                No information available
              </ng-template>
            </div>
            <div class="form-group">
              <strong>Created </strong> <span *ngIf="job.created_by.owner && job.created_by.owner.name"><strong> by
                </strong>{{job.created_by.owner.name}}</span><span *ngIf="job.created_by.created_at"><strong> @
                </strong>{{job.created_by.created_at | dateDiffTimestampNav:job.created_by.created_at}}</span>
            </div>
            <div class="form-group" *ngIf="job.state != 'Open' && job.closed_by">
              <strong>Closed </strong><span *ngIf="job.closed_by.owner && job.closed_by.owner.name"><strong> by
                </strong>{{job.closed_by.owner.name}}</span><span *ngIf="job.closed_by.closed_at"><strong> @
                </strong>{{job.closed_by.closed_at | dateDiffTimestampNav:job.closed_by.closed_at}}</span>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-2">
                  <strong>Team: </strong>
                </div>
                <div *ngIf="job.users.length;then showTeam else showTeamMsg"></div>
                <ng-template #showTeam>
                  <div class="col-6 position-left">
                    <div *ngFor="let user of job.users; let i = index" class="position-absolute podPicture">
                      <img class="d-flex height-30 width-30 rounded-circle cursor-pointer"
                        [src]="user.photo ? user.photo : globals.avatar" (error)="user.photo = globals.avatar"
                        data-toggle="tooltip" data-placement="top"
                        [title]="user.roles.length ? (takeUserRoles(user.roles) +': '+ user.name) : user.name" />
                    </div>
                  </div>

                </ng-template>
                <ng-template #showTeamMsg>
                  No team information
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <nav ngbNav id="tab-shadow" class="nav-tabs" #acc="ngbNav">
    <ng-container [ngbNavItem]="'ngb-tab-0'">
      <a ngbNavLink>General</a>
      <ng-template ngbNavContent>
        <div class="card card-top scroll scroll-tabs">
          <ngb-accordion id="filter-accordion" [(activeIds)]="activeId" #acc="ngbAccordion" class="ng-accordion-append"
            (panelChange)="preventDefault ? beforeChange($event) : ''">
            <ngb-panel id="details" class="mt-2">
              <ng-template ngbPanelHeader>
                <div class="form" (click)="acc.toggle('details')">
                  <div class="form-section">
                    Details
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="card-block">
                  <div class="row">
                    <div *ngIf="job.job_title" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Job title
                        </label>
                        <div class="card-body-content">{{job.job_title}}</div>
                      </div>
                    </div>
                    <div *ngIf="!job.job_title" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Job title
                        </label>
                        <div class="card-body-content">No information available.</div>
                      </div>
                    </div>
                    <div *ngIf="job.company && job.company.name" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Company
                        </label>
                        <div class="card-body-content">
                          {{job.company.name}}
                        </div>
                      </div>
                    </div>
                    <div *ngIf="job.company && !job.company.name" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Company
                        </label>
                        <div class="card-body-content">
                          No information available.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div *ngIf="job.contact && job.contact.name" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Contact
                        </label>
                        <div class="card-body-content">
                          {{job.contact.name}}
                        </div>
                      </div>
                    </div>
                    <div *ngIf="job.contact && !job.contact.name" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Contact
                        </label>
                        <div class="card-body-content">
                          No information available.
                        </div>
                      </div>
                    </div>
                    <div *ngIf="job.type && job.company" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Job type
                        </label>
                        <div class="card-body-content">
                          {{job.type.description}}
                        </div>
                      </div>
                    </div>
                    <div *ngIf="job.type && !job.company" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Job type
                        </label>
                        <div class="card-body-content">
                          No information available.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div *ngIf="job.status" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Status
                        </label>
                        <div class="card-body-content">
                          {{job.status.description}}
                        </div>
                      </div>
                    </div>
                    <div *ngIf="!job.status" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Status
                        </label>
                        <div class="card-body-content">
                          No information available.
                        </div>
                      </div>
                    </div>
                    <div class="col-6" *ngIf="job.priority">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Priority
                        </label>
                        <div class="card-body-content">
                          {{job.priority.description}}
                        </div>
                      </div>
                    </div>
                    <div class="col-6" *ngIf="!job.priority">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Priority
                        </label>
                        <div class="card-body-content">
                          No information available.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Location
                        </label>
                        <div class="row" *ngIf="job.location.length">
                          <div class="col-12">
                            <span *ngFor="let location of job.location;let last = last">
                              {{location.description}}{{last?'':'; '}}
                            </span>
                          </div>
                        </div>
                        <div class="row" *ngIf="!job.location.length">
                          <div class="col-12">
                            No location available
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="job.fe.length" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Functional Expertise
                        </label>
                        <div class="card-body-content">
                          <span *ngFor="let fe of job.fe">
                            {{fe.description}}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="!job.fe.length" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Functional Expertise
                        </label>
                        <div class="card-body-content">
                          No information available.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div *ngIf="job.sfe.length" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Sub Functional Expertise
                        </label>
                        <div class="card-body-content">
                          <span *ngFor="let sfe of job.sfe">
                            {{sfe.description}}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="!job.sfe.length" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Sub Functional Expertise
                        </label>
                        <div class="card-body-content">
                          No information available.
                        </div>
                      </div>
                    </div>
                    <div *ngIf="job.industry.length" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Industry
                        </label>
                        <div class="card-body-content">
                          <span *ngFor="let industry of job.industry">
                            {{industry.description}}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="!job.industry.length" class="col-6">
                      <label class="card-body-title-basic">
                        Industry
                      </label>
                      <div class="form-group">
                        No information available.
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div *ngIf="job.seniority" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Seniority
                        </label>
                        <div class="card-body-content">
                          {{job.seniority.description}}
                        </div>
                      </div>
                    </div>
                    <div *ngIf="!job.seniority" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Seniority
                        </label>
                        <div class="card-body-content">
                          No information available.
                        </div>
                      </div>
                    </div>
                    <div *ngIf="job.projected_length" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Project length (months)
                        </label>
                        <div class="card-body-content">
                          {{job.projected_length}}
                        </div>
                      </div>
                    </div>
                    <div *ngIf="!job.projected_length" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Project length (months)
                        </label>
                        No information available.
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div *ngIf="job.head_count" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Headcount
                        </label>
                        <div class="card-body-content">
                          {{job.head_count}}
                        </div>
                      </div>
                    </div>
                    <div *ngIf="!job.head_count" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Headcount
                        </label>
                        <div class="card-body-content">
                          No information available.
                        </div>
                      </div>
                    </div>
                    <div *ngIf="job.description" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Description
                        </label>
                        <div class="card-body-content" [innerHTML]="job.description"></div>
                      </div>
                    </div>
                    <div *ngIf="!job.description" class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Description
                        </label>
                        <div class="card-body-content">No information available.</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div *ngIf="job.search_source" class="form-group" class="col-6">
                      <label class="card-body-title-basic">
                        Search source
                      </label>
                      <div class="card-body-content">
                        {{job.search_source.description}}
                      </div>
                    </div>
                    <div *ngIf="!job.search_source" class="form-group" class="col-6">
                      <label class="card-body-title-basic">
                        Search source
                      </label>
                      <div class="card-body-content">No information available.</div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
            <ngb-panel id="team" class="mt-2">
              <ng-template ngbPanelHeader>
                <div class="form" (click)="acc.toggle('team')">
                  <div class="form-section">
                    Team
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                  <div class="card-block">
                    <div class="row">
                      <div class="col-6" *ngIf="job.users.length > 0">
                        <label class="card-body-title-basic">
                          <strong>Users</strong>
                        </label>
                        <div class="form-group card-body-content" *ngFor="let user of job.users">
                          {{user.name}}
                        </div>
                      </div>
                      <div *ngIf="job.pod" class="col-6">
                        <strong> Pod </strong>
                        <p>{{job.pod.name}}</p>
                      </div>
                      <div *ngIf="!job.pod && job.users.length == 0" class="col-6">
                        No information available.
                      </div>
                    </div>
                  </div>
              </ng-template>
            </ngb-panel>
            <ngb-panel id="compensation" class="mt-2">
              <ng-template ngbPanelHeader>
                <div class="form" (click)="acc.toggle('compensation')">
                  <div class="form-section">
                    Compensation
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div *ngIf="job.jobCompensation" class="card-block">
                  <div *ngIf="job.jobCompensation.currency">
                    <div class="form-group">
                      <label class="card-body-title-basic">
                        Currency
                      </label>
                      <div class="card-body-content">
                        {{job.jobCompensation.currency.description}}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6" *ngIf="job.jobCompensation && job.jobCompensation.salary_from">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          Salary from
                        </div>
                        {{job.jobCompensation.salary_from}}
                      </div>
                    </div>
                    <div class="col-6" *ngIf="job.jobCompensation && job.jobCompensation.salary_to">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Salary to
                        </label>
                        <div class="card-body-content">
                          {{job.jobCompensation.salary_to}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="job.jobCompensation && job.jobCompensation.annual_salary">
                    <div class="form-group">
                      <label class="card-body-title-basic">
                        Annual salary
                      </label>
                      <div class="card-body-content">
                        {{job.jobCompensation.annual_salary}}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6" *ngIf="job.jobCompensation && job.jobCompensation.bonus">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Bonus
                        </label>
                        <div class="card-body-content">
                          {{job.jobCompensation.bonus}}
                        </div>
                      </div>
                    </div>
                    <div class="col-6" *ngIf="job.jobCompensation && job.jobCompensation.bonus_type">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Bonus type
                        </label>
                        <div class="card-body-content">
                          {{job.jobCompensation.bonus_type.description}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="job.jobCompensation && job.jobCompensation.equity">
                    <div class="form-group">
                      <label class="card-body-title-basic">
                        Equity
                      </label>
                      <div class="card-body-content">
                        {{job.jobCompensation.equity}}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6" *ngIf="job.jobCompensation && job.jobCompensation.fee">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Fee
                        </label>
                        <div class="card-body-content">
                          {{job.jobCompensation.fee}}
                        </div>
                      </div>
                    </div>
                    <div class="col-6" *ngIf="job.jobCompensation && job.jobCompensation.fee_type">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Fee type
                        </label>
                        <div class="card-body-content">
                          {{job.jobCompensation.fee_type.description}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="!job.jobCompensation.salary_from && !job.jobCompensation.bonus && !job.jobCompensation.bonus_type && !job.jobCompensation.currency
                    && !job.jobCompensation.equity && !job.jobCompensation.fee && !job.jobCompensation.fee_type && !job.jobCompensation.salary_from
                    && !job.jobCompensation.salary_to && !job.jobCompensation.updated_at" class="col-6">
                  <div class="form-group">
                    <div class="card-body-content">No information available.</div>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
            <ngb-panel id="scorecards" class="mt-2">
              <ng-template ngbPanelHeader>
                <div class="form" (click)="acc.toggle('scorecards')">
                  <div class="form-section">
                    Scorecards
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="card-block mt-2">
                  <div *ngIf="job.scorecards.length">
                    <div class="form-group" *ngFor="let scorecard of job.scorecards; let i = index">
                      <p>{{scorecard.skill}}</p>
                    </div>
                  </div>
                  <div *ngIf="!job.scorecards.length">
                    No information available.
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </ng-template>
    </ng-container>
    <ng-container [ngbNavItem]="'ngb-tab-2'">
      <a ngbNavLink>
        <div (click)="getActivity()">Activity</div>
      </a>
      <ng-template ngbNavContent>
        <div class="card-body">
          <div class="form">
            <div class="form-section">
              Job activity
            </div>
          </div>
          <div *ngIf="activities.length>0; then showActivity else hideActivity;"></div>
          <ng-template #hideActivity>
            <div class="media pl-3 pt-2">
              No information available.
            </div>
          </ng-template>
          <div class="card-block">
            <ng-template #showActivity>
              <div class="scroll scroll-tabs">
                <div class="timeline-centered">
                  <article class="timeline-entry" *ngFor="let activity of activities">
                    <div class="timeline-entry-inner" *ngIf="activity">
                      <div class="timeline-icon">
                        <i class="fa fa-circle primary-color"></i>
                      </div>
                      <div class="timeline-label">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="timeline-body mr-4" [innerHTML]="activity.title" *ngIf="activity.title">
                            </div>
                            <span class="timeline-time-ago">
                              {{activity.created_date | dateDiffNav:activity.created_date }}</span>
                            <div *ngIf="activity.body" class="timeline-body mr-4">
                              <p class="timeline-body-activity p-2" [innerHTML]="activity.body">
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                  <article class="timeline-entry begin">
                    <div class="timeline-entry-inner">
                      <div class="timeline-icon"
                        style="-webkit-transform: rotate(-90deg); -moz-transform: rotate(-90deg);">
                        <i class="fa fa-circle" style="color:#e5e5e5 !important;"></i>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-container [ngbNavItem]="'ngb-tab-3'">
      <a ngbNavLink>
        <div>Hunting ground</div>
      </a>
      <ng-template ngbNavContent>
        <div class="card-block">
          <div class="card-body">
            <div class="form">
              <div class="form-section">
                Hunting ground
              </div>
            </div>
            <div *ngIf="huntingGrounds.length" class="scroll scroll-tabs">
              <div *ngFor="let huntingGround of huntingGrounds; let i = index">
                <div class="card">
                  <div class="card-block">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-7">
                          <div><strong>{{huntingGround.name}}</strong></div>
                        </div>
                        <div class="col-5 text-right">
                          <div class="form-group">
                            <a><i class="icon-docs" (click)="copyToClipboard(huntingGround)"
                                title="Copy to clipboard"></i></a>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Created by:
                        </label>
                        <div class="card-body-content">
                          {{huntingGround.created_by}}
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Created:
                        </label>
                        <div class="card-body-content">
                          {{huntingGround.created_at | dateDiffTimestampFullNav}}
                        </div>
                      </div>
                      <ngb-accordion [(activeIds)]="huntingGroundsActiveId" id="huntingGroundAccordion"
                        #acc="ngbAccordion" *ngFor="let hGround of huntingGround.tags">
                        <ngb-panel [id]="hGround.key + '-' + i">
                          <ng-template ngbPanelHeader>
                            <div class="card-block" (click)="acc.toggle(hGround.key+ '-' + i)">
                              <div class="form">
                                <div class="form-section">
                                  {{hGround.key | removeUndersoreCapitalizeFirst}}<i
                                    class="fa fa-filter tt-icon-lg"></i>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                          <ng-template ngbPanelContent>
                            <div class="row ml-1">
                              <div class="col-12">
                                <div class="text-center hunting-ground-tags width-100 form-group"
                                  *ngFor="let val of hGround.values">
                                  {{val | removeUndersoreCapitalizeFirst}}
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </ngb-panel>
                      </ngb-accordion>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="!huntingGrounds.length">
              No information available.
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-container [ngbNavItem]="'ngb-tab-4'">
      <a ngbNavLink>Files</a>
      <ng-template ngbNavContent>
        <div class="card card-top scroll scroll-tabs">
          <ngb-accordion [(activeIds)]="filesActiveId" id="filter-accordion" #accFiles="ngbAccordion" class = "ng-accordion-append"
            (panelChange)="preventDefault ? beforeChange($event) : ''">
            <ngb-panel id="files">
              <ng-template ngbPanelHeader>
                <div class="form mt-3" (click)="accFiles.toggle('files')">
                  <div class="form-section">
                    Files
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div *ngIf="job.files.length; then showFiles else showEmptyFiles"></div>
                <ng-template #showFiles>
                  <div class="form-group" *ngFor="let file of job.files;let i = index">
                      <div class="form-group">
                        <div class="card-block">
                          <div class="row">
                            <div class="col-1">
                              <a [href]="file.url" target="_blank">
                                <div *ngIf="file.original_name">
                                  <div
                                    [ngSwitch]="file.original_name.substr(file.original_name.lastIndexOf('.')+1).toLowerCase()">
                                    <div *ngSwitchCase="'pdf'">
                                      <i class="fa fa-file-pdf-o tt-icon-xl primary-color m-2" aria-hidden="true"></i>
                                    </div>
                                    <div *ngSwitchCase="'xlsx'">
                                      <i class="fa fa-file-excel-o tt-icon-xl text-success m-2" aria-hidden="true"></i>
                                    </div>
                                    <div *ngSwitchCase="'doc'">
                                      <i class="fa fa-file-word-o tt-icon-xl big-search-primary-color m-2"
                                        aria-hidden="true"></i>
                                    </div>
                                    <div *ngSwitchCase="'docx'">
                                      <i class="fa fa-file-word-o tt-icon-xl big-search-primary-color m-2"
                                        aria-hidden="true"></i>
                                    </div>
                                    <div *ngSwitchCase="'pptx'">
                                      <i class="fa fa-file-powerpoint-o tt-icon-xl warning-color m-2"
                                        aria-hidden="true"></i>
                                    </div>
                                    <div *ngSwitchCase="'png'">
                                      <i class="fa fa-file-image-o tt-icon-xl warning-color m-2" aria-hidden="true"></i>
                                    </div>
                                    <div *ngSwitchCase="'jpg'">
                                      <i class="fa fa-file-image-o tt-icon-xl warning-color m-2" aria-hidden="true"></i>
                                    </div>
                                    <div *ngSwitchCase="'jpeg'">
                                      <i class="fa fa-file-image-o tt-icon-xl warning-color m-2" aria-hidden="true"></i>
                                    </div>
                                    <div *ngSwitchDefault>
                                      <i class="fa fa-file-o tt-icon-xl m-2" aria-hidden="true"></i>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                            <div class="col-10">
                              <div class="form-group">
                                <div class="card-body-title-basic" *ngIf="file.type.description">
                                  {{file.type.description}}
                                  <span *ngIf="file.is_primary && 'cv/resume' == file.type.value"
                                    class="tag secondary-color ml-5">
                                    <em>Primary CV/Resume</em>
                                  </span>
                                </div>
                              </div>
                              <div class="form-group" *ngIf="file.original_name">
                                <div class="card-body-content">
                                  <a [href]="file.url" target="_blank" [ngClass]="{'secondary-color':file.url}">
                                    <u>{{file.original_name}}</u>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </ng-template>
                <ng-template #showEmptyFiles>
                  <div>
                    No information available.
                  </div>
                </ng-template>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </ng-template>
    </ng-container>
  </nav>
  <div [ngbNavOutlet]="acc" *ngIf="editJobFlag"></div>
  </div>
