import { Observable, EMPTY, throwError } from 'rxjs';

import { switchMap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from 'environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor{
  isFirstLogout = false;

  constructor(public auth: AuthService, private http: HttpClient){ }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
    if (request.url.includes(environment.dealroomURL)){
      request = this.addDealRoomHeaders(request);
      return next.handle(request);
    }
    if (request.url.includes(environment.djangoURL)){
      request = this.addDjangoHeader(request);
      return next.handle(request);
    }

    if (request.url.includes(environment.graphAPIURL)){
      request = this.addGraphAPIHeaders(request);
      return next.handle(request);
    }

    request = this.addAuthHeader(request);

    return next.handle(request).pipe(
      catchError(error => {
        if (request.url.includes(environment.platformBackendURL)){
        if (error.status === 401){
          if (request.url.endsWith('/logout')){
            return EMPTY;
          }

          if (request.url.endsWith('/users/token')){
            if (!this.isFirstLogout){
              this.auth.logout();
              this.isFirstLogout = true;
            }
            return EMPTY;
          }

          return this.getNewToken().pipe(switchMap((response) => {
            localStorage.setItem('access-token', response.access_token);
            request = this.addAuthHeader(request);
            return next.handle(request);
          }),
            catchError(() => {
                this.auth.logout();
                this.isFirstLogout = true;
                return EMPTY;
            }));
        }
        return throwError(() => error);
      }
        return throwError(() => error);
      }));
  }

  getNewToken(): Observable<any>{
    const url: string = environment.platformBackendURL + '/users/token';
    return this.http.get(url, { withCredentials: true });
  }

  addAuthHeader(request){
    return request.clone({
      setHeaders: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${this.auth.getToken()}`
      }
    });
  }

  addDjangoHeader(request){
    return request.clone({
      setHeaders: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${environment.djangoKey}`
      }
    });
  }

  addGraphAPIHeaders(request){
    return request.clone({
      setHeaders: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${environment.graphAPIKey}`
      }
    });
  }

  addDealRoomHeaders(request){
    return request.clone({
      setHeaders: {
        'X-Dealroom-App-Id': '110719080',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json'
      }
    });
  }
}
