import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, Directive, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { ViewCandidateService } from './view-candidate.service';
import { Globals } from 'app/globals';
import { NgbModal, NgbNav, NgbPanelChangeEvent, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { CommunicationService } from 'app/shared/service/communication.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { AddToAnotherJobComponent } from 'app/shared/getnavis/getnavis-platform-modals/add-to-another-job/add-to-another-job.component';

@Directive({
  selector: '[appRendered]'
})
export class RenderedDirective{

  @Output() rendered: EventEmitter<any> = new EventEmitter();

  OnInit(){
    this.rendered.emit();
  }
}

@Component({
  selector: 'app-view-candidate',
  templateUrl: './view-candidate.component.html',
  styleUrls: ['./view-candidate.component.scss']
})
export class ViewCandidateComponent implements OnInit, OnChanges{
  @Input() data: any;

  candidate_uuid: string;

  job_uuid: string;

  @Output() dismissDrawer: EventEmitter<boolean> = new EventEmitter<boolean>();

  subscription: any;

  candidate: any = {};

  comments: any = [];

  activities: any = [];

  loadingActivity = false;

  addActivity = false;//this needs to be removed, but need to find a proper solution

  innerWidth: number;

  users: any = [];

  masterCandidates = false;

  activeIds = ['basic', 'compensation', 'consent', 'experience', 'education', 'language', 'miscellaneous', 'closedJobs', 'openJobs', 'files', 'filters'];

  preventDefault = false;

  dateFormat = 'DD/MM/YYYY';

  accordion: any = {
    basic: true,
    compensation: true,
    consent: true,
    experience: true,
    education: true,
    language: true,
    miscellaneous: true,
    closedJobs: true,
    openJobs: true,
    files: true,
    add_activity: true,
    filters: true
  };

  add: any = {
    activityFilter: {
      filter_type: null,
      selected_filter: null,
      filters: {}
    }
  };

  resources: any = {
    jobs: {
      type: null,
      data: []
    },
    internal: [],
    campaigns: [],
    client: [],
    activity: [],
    filters: []
  };

  @ViewChild('acc') acc: NgbNav;

  @ViewChild('popOver') public popContentFilters: NgbPopover;

  objectKeys = Object.keys;

  oldJobsClients: string;

  oldJobsContacts: string;

  showFilterTag: any = {
    from: false,
    to: false
  };

  constructor(private profileService: ViewCandidateService, public globals: Globals, private communicationService: CommunicationService, public toastr: ToastrService,
    private cdr: ChangeDetectorRef, private modalService: NgbModal){
    this.innerWidth = window.innerWidth;
  }

  ngOnInit(){
    this.candidate_uuid = this.data.candidate_uuid;
    this.job_uuid = this.data.job_uuid;
    this.getCandidateData();
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes.data.previousValue && changes.data.currentValue.origin == 'navis1' && (changes.data.currentValue.candidate_uuid != changes.data.previousValue.candidate_uuid)){
      this.candidate_uuid = changes.data.currentValue.candidate_uuid;
      this.job_uuid = changes.data.currentValue.job_uuid;
      this.initializeProfile();
      this.getCandidateData();
    }
  }

  getCandidateData(){
    this.profileService.getCandidateProfile(this.candidate_uuid)
      .subscribe((data) => {
        this.candidate = data;
        this.masterCandidates = true;
      }, (err) => {
        this.displayErrorMessage(err);
      });
  }

  fetchActiveTab(event){
    if (event.nextId === 'ngb-tab-3'){
      if (!this.comments.length){
        this.profileService.getComments(this.candidate_uuid, this.job_uuid ? this.job_uuid : null)
          .subscribe((data) => {
            this.comments = data;
          }, (err) => {
            this.displayErrorMessage(err);
          });
      }
    } else if (event.nextId === 'ngb-tab-4'){
      this.profileService.getActivityFilterResources()
        .subscribe(
          (data) => {
            this.resources.activity = data;
          },
          (err) => {
            this.displayErrorMessage(err);
          },
          () => {
            this.add.activityFilter.filter_type = this.resources.activity[0].value;
            this.getActivity(true, this.add.activityFilter.filter_type);
          }
        );
    }
  }

  getActivity(filter, type){
    this.loadingActivity = true;
    if (this.subscription){
      this.subscription.unsubscribe();
    }
    this.activities = [];
    if (filter){
      this.add.activityFilter.filters = {};
      this.add.activityFilter.selected_filter = null;
      this.oldJobsClients = '';
      this.oldJobsContacts = '';
      this.resources.clients = [];
      this.resources.contacts = [];
      this.resources.jobs.data = [];
    }
    const candidate_emails = [this.candidate.primary_email];
    this.subscription = this.profileService.getActivity(this.candidate.uuid, this.add.activityFilter.filters, type, candidate_emails)
      .subscribe((data) => {
        this.activities = data;
        this.loadingActivity = false;
      },
        (err) => {
          this.activities = [];
          this.loadingActivity = false;
          this.displayErrorMessage(err);
        });
  }

  prepareFilterBody(key, value, type){
    if (!Object.keys(this.add.activityFilter.filters).includes(key)){
      key == 'users' ? this.add.activityFilter.filters[key] = {} : this.add.activityFilter.filters[key] = value;
    }
    if (key == 'users' && (!this.add.activityFilter.filters.users.internal && !this.add.activityFilter.filters.users.client)){
      this.add.activityFilter.filters.users.clientBoolean = true;
      this.add.activityFilter.filters.users.internalBoolean = true;
      this.add.activityFilter.filters.users.internal = [];
      this.add.activityFilter.filters.users.client = [];
    }
    if (key == 'from' || key == 'to'){
      this.changeTags(key);
    }
    this.getActivity(false, type);
  }

  changeTags(key){
    this.add.activityFilter.filters[key].length ? this.showFilterTag[key] = false : this.showFilterTag[key] = true;
  }

  changeUsersState(state, type){
    if (state){
      this.add.activityFilter.filters.users[type] = [];
    } else {
      this.add.activityFilter.filters.users[type] = [];
      delete this.add.activityFilter.filters.users[type];
    }

    this.getActivity(false, this.add.activityFilter.filter_type);
  }

  getFilterColor(type, filter){
    const resourceIndex = this.resources.activity.findIndex(resource => resource.value === type);
    if (resourceIndex !== -1){
      const childrenIndex = this.resources.activity[resourceIndex].children.findIndex(children => children.value === filter);
      if (childrenIndex !== -1){
        return this.resources.activity[resourceIndex].children[childrenIndex].color_code;
      }
    }
    return null;
  }

  isObject(object){
    return (object && typeof object === 'object');
  }

  isArray(array){
    return (Array.isArray(array));
  }

  checkObjectValues(obj){
    let hasValue = false;
    for (const key in obj){
      if (obj[key] !== null && obj[key] != "")
        hasValue = true;
    }
    return hasValue;
  }

  checkArrayObjectValues(array){
    let hasValue = false;
    array.forEach(element => {
      if (element.skill){
        hasValue = true;
      }
    });
    return hasValue;
  }

  deleteFilter(filter, deleteKey, i?){
    if (deleteKey){
      this.add.activityFilter.filters[filter] = [];
      delete this.add.activityFilter.filters[filter];
    } else {
      this.add.activityFilter.filters[filter].splice(i, 1);
    }
    this.add.activityFilter.selected_filter = null;
    this.getActivity(false, this.add.activityFilter.filter_type);
  }

  showFilters(type, filter){
    const resourceIndex = this.resources.activity.findIndex(resource => resource.value === type);
    if (resourceIndex !== -1){
      const childrenIndex = this.resources.activity[resourceIndex].children.findIndex(children => children.value === filter);
      if (childrenIndex !== -1){
        this.add.activityFilter.selected_filter = this.resources.activity[resourceIndex].children[childrenIndex];
      }
    }
  }

  getFilteredFilters(filterValue){
    this.resources.filters = [];
    if (this.add.activityFilter.filter_type){
      this.resources.activity.forEach(element => {
        if (element.value == filterValue){
          this.resources.filters = element.children;
          this.cdr.detectChanges();
        }
      });
    }
  }

  getCampaigns(){
    if (!this.resources.campaigns.length){
      this.profileService.getCampaignsResources()
        .subscribe((data) => {
          this.resources.campaigns = data;
        }
          ,
          (err) => {
            this.displayErrorMessage(err);
          });
    }
  }

  closePopContentFilters(){
    this.popContentFilters.close();
  }

  getUsers(group){

    if (!this.resources[group].length){

      this.profileService.getUsers("?group=" + group)
        .subscribe((data) => {
          this.resources[group] = data;
        });
    }
  }

  public beforeChange($event: NgbPanelChangeEvent){
    $event.preventDefault();
    this.preventDefault = false;
  }

  getJobs(type, candidate_uuid){
    if ((this.resources.jobs.type != type) || !this.resources.jobs.data.length){
      this.profileService.getCandidateJobsResources(type, candidate_uuid).subscribe(
        (data) => {
          this.resources.jobs.data = data;
        });
    }
    this.resources.jobs.type = type;
  }

  getContrastYIQ(hexcolor){
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 128) ? 'black' : 'white';
  }

  initializeProfile(){
    this.candidate = {};
    this.comments = [];
    this.activities = [];
    this.users = [];
    this.masterCandidates = false;

  }

  openLinkedIn(linkedinURL){
    window.open(linkedinURL, '_blank');
  }

  displayErrorMessage(err){
    if (err.error){
      for (const errProp in err.error){
        if (err.error.hasOwnProperty(errProp)){
          if (err.error[errProp][0]){
            this.toastr.error(err.error[errProp]);
          }
        }
      }
    } else {
      this.toastr.error('Something went wrong');
    }
  }

  addToJobModal(){
    const modalRef = this.modalService.open(AddToAnotherJobComponent, { size: 'md' as 'lg', centered: true, backdrop: 'static' });
    modalRef.componentInstance.candidates = [this.candidate];
    modalRef.componentInstance.openedFrom = 'view-candidate';
    modalRef.result.then(() => {
    }, () => {
    });
  }

  transform(date: any): any{
    return moment.unix(date).format(this.dateFormat);
  }
}
