import { Pipe, PipeTransform } from '@angular/core';
/*
 * Replace all underscores with empty string from given string
 * Takes a string as a value.
 * Usage:
 *  value | removeUndersore
 * Example:
 *  // value.name = first_name
 *  {{ value.name | removeUndersore  }}
 *  fromats to: First Name
*/
@Pipe({
    name: 'removeUndersore'
})
export class RemoveUnderscorePipe implements PipeTransform{

  transform(value: any, args?: any): any{
      const replacment = args ? args : ' ';

      return value.replace(/_/g, replacment);
  }
}
