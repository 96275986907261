import { Component, OnInit, Input, OnChanges, Output, EventEmitter, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { ViewJobService } from './view-job.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardService } from 'ngx-clipboard';
import { CampaignsMetricsModalComponent } from 'app/shared/platform-modals/campaigns-metrics-modal/campaigns-metrics-modal.component';
import { Globals } from 'app/globals';

@Component({
  selector: 'app-view-job',
  templateUrl: './view-job.component.html',
  styleUrls: ['./view-job.component.scss']
})
export class ViewJobComponent implements OnInit, OnChanges{
  @Input() data: any;

  @Output() dismissDrawer: EventEmitter<boolean> = new EventEmitter<boolean>();

  job: any = this.initJobObject();

  jobDataIsDownloaded = false;

  jobUUID = '';

  activeId: string[] = ['details', 'team', 'compensation', 'scorecards'];

  filesActiveId: string[] = ['files'];

  huntingGroundsActiveId: string[] = [];

  preventDefault = false;

  stageRate = 2.5;

  activities: any = [];

  huntingGrounds: any = [];

  editJobFlag = true;

  jobTitles: any = [];

  constructor(private viewJobService: ViewJobService, public globals: Globals, public toastr: ToastrService, private modalService: NgbModal,
    private clipboardService: ClipboardService, private cdr: ChangeDetectorRef){
  }

  ngOnInit(){
    this.jobUUID = this.data.job_uuid;
    this.takeDataForJob(this.jobUUID);
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes.data.previousValue && changes.data.currentValue.origin == 'navis1' && (changes.data.currentValue.job_uuid != changes.data.previousValue.job_uuid)){
      this.jobUUID = changes.data.currentValue.job_uuid;
      this.huntingGrounds = [];
      this.activities = [];
      this.takeDataForJob(this.jobUUID);
      this.getActivity();
      this.getHuntingGroundData();
    }
  }

  takeDataForJob(jobUUID){
    this.viewJobService.getJobData(jobUUID)
      .subscribe((data) => {
        this.job = data;
        this.getJobCampaignsMetrics(jobUUID);
        this.prepopulateStageRate(this.job.current_stage);
        this.editJobFlag = true;
        this.jobDataIsDownloaded = true;
      }, (err) => {
        this.displayErrorMessage(err);
      });
  }

  getActivity(){
    if (this.activities.length == 0){
      this.viewJobService.getActivity(this.jobUUID)
        .subscribe((data) => {
          this.activities = data;
        }, (err) => {
        this.displayErrorMessage(err);
        });
    }
  }

  getJobCampaignsMetrics(uuid){
    this.viewJobService.getJobMetrics(uuid)
      .subscribe((data) => {
        this.job.metrics = data;

      },
      (err) => {
       this.displayErrorMessage(err);
      });
  }

  getHuntingGroundData(){
    this.viewJobService.getHuntingGroundData(this.jobUUID)
      .subscribe((data) => {
        this.huntingGrounds = data;
        if (this.huntingGrounds.length <= 1){
          this.huntingGrounds.forEach((element, i) => {
            element.tags.forEach(tag => {
              this.huntingGroundsActiveId.push(tag.key + '-' + i);
            });

          });
        }
        this.cdr.detectChanges();
      });
  }

  prepopulateStageRate(currentStage){
    this.jobTitles = [];
    let tempRate = 0;
    currentStage.reverse();
    currentStage.forEach(element => {
      if (element.status == true){
        tempRate++;
      }
      this.jobTitles.push(element.description);
    });
    this.stageRate = tempRate;
  }

  openMetricsModal(){
    const modalRef = this.modalService.open(CampaignsMetricsModalComponent, { size: 'md' as 'lg', centered: true });
    modalRef.componentInstance.metricsData = this.job.metrics;
    modalRef.componentInstance.uuid = null;
    modalRef.result.then(() => {
    }, () => {
    });
  }

  public beforeChange($event: NgbPanelChangeEvent){
    $event.preventDefault();
    this.preventDefault = false;
  }

  initJobObject(){
    return {
      job_title: null,
      company: null,
      contact: null,
      description: null,
      type: null,
      status: null,
      priority: null,
      location: null,
      search_source: null,
      affinity_opportunity_id: null,
      projected_length: null,
      head_count: null,
      fe: null,
      sfe: null,
      industry: null,
      seniority: null,
      jobCompensation: {
        currency: null,
        salary_from: null,
        salary_to: null,
        annual_salary: null,
        bonus: null,
        bonus_type: null,
        equity: null,
        fee: null,
        fee_type: null
      },
      users: [],
      files: [],
      scorecards: [],
      pod: null
    };
  }

  copyToClipboard(huntingGround){
    this.clipboardService.copyFromContent(huntingGround.companies_boolean);
    this.toastr.success('Copied to clipboard');
  }

  prevent($event){
    $event.stopPropagation();
  }

  takeUserRoles(roles){
    let tempRoles = [];
    if (roles.length > 1){
      roles.forEach(element => {
        tempRoles.push(element.name);
      });
    } else {
      tempRoles = roles[0].name;
    }
    return tempRoles;
  }

  displayErrorMessage(err){
    if (err.error){
      for (const errProp in err.error){
        if (err.error.hasOwnProperty(errProp)){
          if (err.error[errProp][0]){
            this.toastr.error(err.error[errProp]);
          }
        }
      }
    } else {
      this.toastr.error('Something went wrong');
    }
  }
}
