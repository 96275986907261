<div class="card card-top">
  <div class="card-block card-body scroll">
    <div class="row">
      <div class="col-md-12 col-12">
        <div class="card-body-title-basic">
          <label>
            Profile picture
          </label>
        </div>
        <div class="media mb-3">
          <img class="align-self-center mr-3 height-40 width-40 rounded-circle"
            [src]="consultant.picture_file_url || globals.avatar">
          <div class="media-body">
            <input type="text" class="form-control" placeholder="Insert picture url"
              [(ngModel)]="consultant.picture_file_url" [ngModelOptions]="{standalone: true}">
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="form-group">
          <div class="card-body-title-basic">
            <label>
              * First name
              <span>
                (Required field)
              </span>
            </label>
          </div>
          <div class="card-body-content">
            <input type="text" class="form-control" placeholder="Insert first name" [(ngModel)]="consultant.first_name"
              [ngModelOptions]="{standalone: true}">
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="form-group">
          <div class="card-body-title-basic">
            <label>
              * Last name
              <span>
                (Required field)
              </span>
            </label>
          </div>
          <div class="card-body-content">
            <input type="text" class="form-control" placeholder="Insert last name" [(ngModel)]="consultant.last_name"
              [ngModelOptions]="{standalone: true}">
          </div>
        </div>
      </div>
      <div class="col-md-12 col-12">
        <div class="form-group">
          <div class="card-body-title-basic">
            <label>
              * Email
              <span>
                (Required field)
              </span>
            </label>
          </div>
          <div class="card-body-content">
            <input type="email" class="form-control" name="email" placeholder="Insert email"
              [(ngModel)]="consultant.email" [ngModelOptions]="{standalone: true}">
          </div>
        </div>
      </div>
      <div class="col-md-12 col-12">
        <div class="form-group">
          <div class="card-body-title-basic">
            <label>
              Title
            </label>
          </div>
          <div class="card-body-content">
            <input type="text" class="form-control" placeholder="Insert title" [(ngModel)]="consultant.title"
              [ngModelOptions]="{standalone: true}">
          </div>
        </div>
      </div>
      <div class="col-md-12 col-12">
        <div class="form-group">
          <div class="card-body-title-basic">
            <label>
              Phone
            </label>
          </div>
          <div class="card-body-content">
            <input type="text" class="form-control" placeholder="Insert phone" [(ngModel)]="consultant.phone"
              [ngModelOptions]="{standalone: true}">
          </div>
        </div>
      </div>
      <div class="col-md-12 col-12">
        <div class="form-group">
          <div class="card-body-title-basic">
            <label>
              Company
            </label>
          </div>
          <div class="card-body-content">
            <ng-select bindLabel="name" dropdownPosition="bottom" placeholder="ConsultantCompany" [items]="companies"
              (open)="loadCompany()" [(ngModel)]="consultant.company" [ngModelOptions]="{standalone: true}">
              <ng-template ng-option-tmp let-item="item" let-index="i">
                <div class="media mb-1">
                  <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                    [src]="item.photo || (item.images ? (item.images['32x32'] ? item.images['32x32'] : globals.companyAvatar) : '')"
                    (error)="item.images = globals.companyAvatar" />
                  <div class="media-body">
                    <div class="media-body-title mt-1 mb-0">{{item.name}}<span *ngIf="item.website">,
                        {{item.website}}</span>
                    </div>
                    <p *ngIf="item.location">
                      <i class="tt-icon-sm icon-ttrelocation"></i>
                      <span>
                        {{item.location}}
                      </span>
                    </p>
                  </div>
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-12">
        <div class="form-group">
          <div class="card-body-title-basic">
            <label>
              Jobs
            </label>
          </div>
          <div class="card-body-content">
            <ng-select bindLabel="title" dropdownPosition="bottom" placeholder="Select jobs" [items]="resources.jobs"
              (open)="getResources('jobs')" [(ngModel)]="consultant.jobs" [ngModelOptions]="{standalone: true}"
              multiple="true">
              <ng-template ng-option-tmp let-item="item" let-index="i">
                {{item.title}} {{item.company ? ' - ' + item.company.name : 'Missing company'}}
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="position-bottom full-width">
  <button type="button" class="btn pull-left bg-primary-color" (click)="initComponent()">Clear data
  </button>
  <button type="button" class="btn pull-right bg-secondary-color mr-4" (click)="createConsultant()">Add Consultant
  </button>
</div>