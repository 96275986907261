<div *ngIf="!show.component" class="loading-view">
    <img [src]="globals.loading" class="loading-element" />
</div>
<div *ngIf="show.component">
    <div class="media">
        <div class="media-body">
            <div class="media-body-content">
                <div class="row">
                    <div class="col-4">
                        <div class="form-group">
                            <h5><strong>{{campaign.name}}</strong></h5>
                        </div>
                        <div class="form-group" *ngIf="campaign.status.description">
                            <strong>Status:</strong>
                            {{campaign.status.description}}
                        </div>
                        <div class="form-group" *ngIf="campaign.created_date">
                            <strong>Created date:</strong>
                            {{campaign.created_date}}
                        </div>
                        <div class="form-group" *ngIf="campaign.job.job_title">
                            <strong>Job:</strong>
                            {{campaign.job.job_title}}
                        </div>
                        <div class="form-group" *ngIf="metrics.response_rate">
                            <strong>Response rate:</strong>
                            {{metrics.response_rate}}
                        </div>
                    </div>
                    <div class="col-8 pl-0" *ngIf="show.metrics">
                        <div class="row">
                            <div class="col-lg-7 col-12 pl-0">
                                <strong>Total sent metrics</strong>
                                <div class="row">
                                    <div class="col-12 col-lg-5 align-self-center p-0">
                                        <div id="donut-dashboard-chart" class="height-155 donut">
                                            <x-chartist class="" [data]="chart.data" [type]="chart.type"
                                                [options]="chart.options" [responsiveOptions]="chart.responsiveOptions"
                                                [events]="chart.events">
                                            </x-chartist>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-6 align-self-center p-0">
                                        <div class="row" *ngFor="let info of metrics.sent.data.series">
                                            <div class="col-12 form-group">
                                                <div class="card-body-title-basic">{{info.value}}% {{info.name}}</div>
                                                <div class="progress height-5">
                                                    <div class="progress-bar" [className]="'bg-' + info.className"
                                                        role="progressbar" [style.width.%]="info.value"
                                                        [attr.aria-valuenow]="info.value" aria-valuemin="0"
                                                        aria-valuemax="100">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-12 p-0">
                                <strong>Steps metrics</strong>
                                <div class="form-group pt-3">
                                    <ng-select bindLabel="name" dropdownPosition="bottom" placeholder="Select step"
                                        [items]="metrics.steps" [(ngModel)]="filters.metrics"
                                        [ngModelOptions]="{standalone: true}">
                                    </ng-select>
                                </div>

                                <div class="form-group">
                                    <div class="card-body-title-basic">{{filters.metrics.opened}}% Opened</div>
                                    <div class="progress height-5">
                                        <div class="progress-bar" class="bg-pending-color" role="progressbar"
                                            [style.width.%]="filters.metrics.opened"
                                            [attr.aria-valuenow]="filters.metrics.opened" aria-valuemin="0"
                                            aria-valuemax="100">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="card-body-title-basic">{{filters.metrics.replied}}% Replied</div>
                                    <div class="progress height-5">
                                        <div class="progress-bar" class="bg-success" role="progressbar"
                                            [style.width.%]="filters.metrics.replied"
                                            [attr.aria-valuenow]="filters.metrics.replied" aria-valuemin="0"
                                            aria-valuemax="100">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="chosenTempalte"
                (ngModelChange)="templateChanged(chosenTempalte)">
                <label ngbButtonLabel class="btn bg-secondary-color-outline pull-left mr-2">
                    <input ngbButton type="radio" value="details"> Details
                </label>
                <label ngbButtonLabel class="btn bg-secondary-color-outline pull-left mr-2">
                    <input ngbButton type="radio" value="candidates"> Candidates
                    <span
                        class="notification badge-inverse badge-pill badge-big-search-primary-inverse m-0">{{metrics.total_candidates}}</span>
                </label>
            </div>
            <button type="button" class="btn bg-primary-color pull-right" *ngIf="uuid && show.campaignUpdate"
                (click)="addUpdateCampaign(uuid)">Update Campaign
            </button>
        </div>
    </div>
    <div class="row mt-3 scroll">
        <div class="col-12">
            <div [ngSwitch]="chosenTempalte">
                <ng-template [ngSwitchCase]="'details'">
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="form-group">
                                <div class="card-body-title-basic">
                                    *
                                    Name
                                    <span *ngIf="!validForm && !campaign.name"
                                        [ngClass]="{'primary-color': !campaign.name}">
                                        (Please fill in this field)
                                    </span>
                                </div>
                                <div class="card-body-content">
                                    <input type="text" class="form-control" placeholder="Insert campaign name"
                                        [(ngModel)]="campaign.name" [ngModelOptions]="{standalone: true}"
                                        (ngModelChange)="show.campaignUpdate = true">
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="form-group">
                                <div class="card-body-title-basic">
                                    *
                                    Job
                                    <span *ngIf="!validForm && !campaign.job"
                                        [ngClass]="{'primary-color': !campaign.job}">
                                        (Please fill in this field)
                                    </span>
                                </div>
                                <div class="card-body-content">
                                    <ng-select bindLabel="job_title" dropdownPosition="bottom" (open)="getJobs()"
                                        (ngModelChange)="show.campaignUpdate = true" placeholder="Select job"
                                        [items]="resources.jobs" [(ngModel)]="campaign.job"
                                        [ngModelOptions]="{standalone: true}" (ngModelChange)="clearFields()" [clearable]="false">
                                        <ng-template ng-option-tmp let-item="item" let-index="index"
                                            let-search="searchTerm">
                                            <span title="{{item.job_title}} - {{item.hiring_company.name}}">
                                                {{item.job_title}} -
                                                {{item.hiring_company.name}}
                                            </span>
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <section id="timeline" class="timeline-center timeline-wrapper mt-3">
                                <ul class="timeline">
                                    <div class="timeline-line mb-5"></div>
                                    <div class="timeline-item block">
                                        <div class="timeline-badge">
                                            <span class="bg-big-search-primary-color bg-lighten-1" data-toggle="tooltip"
                                                data-placement="left" title="Campaign start"><i
                                                    class="fa fa-check-square-o"></i></span>
                                        </div>
                                    </div>
                                    <div class="block">
                                        <div class="timeline-card card border-grey border-lighten-2 mt-5 mb-0 p-3"
                                            *ngFor="let step of campaign.steps; let i = index">
                                            <div class="row">
                                                <div class="col-12">
                                                    <i class="tt-icon-lg fa fa-trash-o pull-right cursor-pointer"
                                                        (click)="deleteStep(i)" data-toggle="tooltip"
                                                        data-placement="bottom" title="delete"></i>
                                                </div>
                                                <div class="col-12 col-lg-6">
                                                    <div class="form-group">
                                                        <div class="card-body-title-basic">
                                                            *
                                                            Step name
                                                            <span *ngIf="!validForm && !step.name"
                                                                [ngClass]="{'primary-color': !step.name}">
                                                                (Please fill in this field)
                                                            </span>
                                                        </div>
                                                        <div class="card-body-content">
                                                            <input type="text" class="form-control"
                                                                placeholder="Insert step name" [(ngModel)]="step.name"
                                                                [ngModelOptions]="{standalone: true}"
                                                                (ngModelChange)="show.campaignUpdate = true">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-6">
                                                    <div class="form-group">
                                                        <div class="card-body-title-basic">
                                                            *
                                                            Template
                                                            <span *ngIf="!validForm && !step.template"
                                                                [ngClass]="{'primary-color': !step.template}">
                                                                (Please fill in this field)
                                                            </span>
                                                        </div>
                                                        <div class="card-body-content">
                                                            <ng-select bindLabel="name" dropdownPosition="bottom"
                                                                (open)="getTemplates()"
                                                                (ngModelChange)="show.campaignUpdate = true"
                                                                placeholder="Select template"
                                                                [items]="resources.templates"
                                                                [(ngModel)]="step.template"
                                                                [ngModelOptions]="{standalone: true}">
                                                                <ng-template ng-option-tmp let-item="item"
                                                                    let-index="index" let-search="searchTerm">
                                                                    <span title="{{item.name}}">{{item.name}}</span>
                                                                </ng-template>
                                                            </ng-select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-lg-6">
                                                    <div class="form-group">
                                                        <div class="card-body-title-basic">
                                                            *
                                                            From
                                                            <span *ngIf="!validForm && !step.users.from"
                                                                [ngClass]="{'primary-color': !step.users.from}">
                                                                (Please fill in this field)
                                                            </span>
                                                        </div>
                                                        <div class="card-body-content">
                                                            <ng-select bindLabel="name" dropdownPosition="bottom"
                                                                (open)="getContacts()"
                                                                placeholder="Select contact (from)"
                                                                [items]="resources.contacts"
                                                                (ngModelChange)="show.campaignUpdate = true"
                                                                [(ngModel)]="step.users.from"
                                                                [ngModelOptions]="{standalone: true}">
                                                                <ng-template ng-option-tmp let-item="item"
                                                                    let-index="i">
                                                                    <div class="media mb-1">
                                                                        <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                                                                            [src]="item.photo ? item.photo : 
                                                                          (item.gender ?
                                                                            (item.gender === 'female' || item.gender.value ==='female' ? 
                                                                                globals.femaleAvatar : globals.avatar) :
                                                                          globals.avatar)" (error)="item.gender && item.gender === 'female' ? 
                                                                            item.photo = globals.femaleAvatar : 
                                                                            item.photo = globals.avatar">
                                                                        <div class="media-body">
                                                                            <div class="media-body-title mt-1 mb-0">
                                                                                {{item.name}}</div>
                                                                            <p><i
                                                                                    class="tt-icon-sm icon-ttmail email-icon mr-1"></i>{{item.email
                                                                                ? item.email : 'No email
                                                                                available'}}<br>
                                                                                <i
                                                                                    class="tt-icon-sm icon-ttcareer"></i>{{item.job_title
                                                                                ? item.job_title : 'No job title
                                                                                available'}}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </ng-template>
                                                            </ng-select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-lg-6">
                                                    <div class="form-group">
                                                        <div class="card-body-title-basic">
                                                            CC
                                                        </div>
                                                        <div class="card-body-content">
                                                            <ng-select bindLabel="name" dropdownPosition="bottom"
                                                                (open)="getContacts()"
                                                                (ngModelChange)="show.campaignUpdate = true"
                                                                placeholder="Select contact (cc)"
                                                                [items]="resources.contacts" [multiple]="true"
                                                                [(ngModel)]="step.users.cc"
                                                                [ngModelOptions]="{standalone: true}">
                                                                <ng-template ng-option-tmp let-item="item"
                                                                    let-index="i">
                                                                    <div class="media mb-1">
                                                                        <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                                                                            [src]="item.photo ? item.photo : globals.avatar"
                                                                            (error)="item.photo = globals.avatar" />
                                                                        <div class="media-body">
                                                                            <div class="media-body-title mt-1 mb-0">
                                                                                {{item.name}}</div>
                                                                            <p><i
                                                                                    class="tt-icon-sm icon-ttmail email-icon mr-1"></i>{{item.email
                                                                                ? item.email : 'No email
                                                                                available'}}<br>
                                                                                <i
                                                                                    class="tt-icon-sm icon-ttcareer"></i>{{item.job_title
                                                                                ? item.job_title : 'No job title
                                                                                available'}}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </ng-template>
                                                            </ng-select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-6">
                                                    <div class="form-group">
                                                        <div class="card-body-title-basic">
                                                            BCC
                                                        </div>
                                                        <div class="card-body-content">
                                                            <ng-select bindLabel="name" dropdownPosition="bottom"
                                                                (open)="getContacts()"
                                                                (ngModelChange)="show.campaignUpdate = true"
                                                                placeholder="Select contact (bcc)"
                                                                [items]="resources.contacts" [multiple]="true"
                                                                [(ngModel)]="step.users.bcc"
                                                                [ngModelOptions]="{standalone: true}">
                                                                <ng-template ng-option-tmp let-item="item"
                                                                    let-index="i">
                                                                    <div class="media mb-1">
                                                                        <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                                                                            [src]="item.photo ? item.photo : globals.avatar"
                                                                            (error)="item.photo = globals.avatar" />
                                                                        <div class="media-body">
                                                                            <div class="media-body-title mt-1 mb-0">
                                                                                {{item.name}}</div>
                                                                            <p><i
                                                                                    class="tt-icon-sm icon-ttmail email-icon mr-1"></i>{{item.email
                                                                                ? item.email : 'No email
                                                                                available'}}<br>
                                                                                <i
                                                                                    class="tt-icon-sm icon-ttcareer"></i>{{item.job_title
                                                                                ? item.job_title : 'No job title
                                                                                available'}}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </ng-template>
                                                            </ng-select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-lg-6">
                                                    <div class="form-group">
                                                        <div class="card-body-title-basic">
                                                            *
                                                            Next substatus
                                                            <span *ngIf="!validForm && !step.next_status"
                                                                [ngClass]="{'primary-color': !step.next_status}">
                                                                (Please fill in this field)
                                                            </span>
                                                        </div>
                                                        <div class="card-body-content">
                                                            <ng-select bindLabel="description" dropdownPosition="bottom"
                                                                (open)="getStatusResources()"
                                                                placeholder="Select next status"
                                                                [items]="resources.statuses"
                                                                (ngModelChange)="show.campaignUpdate = true"
                                                                [(ngModel)]="step.next_status"
                                                                [ngModelOptions]="{standalone: true}">
                                                            </ng-select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-lg-6">
                                                    <div class="form-group">
                                                        <div class="card-body-title-basic">
                                                            *
                                                            Trigger type
                                                            <span *ngIf="!validForm && !step.trigger_type"
                                                                [ngClass]="{'primary-color': !step.trigger_type}">
                                                                (Please fill in this field)
                                                            </span>
                                                        </div>
                                                        <div class="card-body-content">
                                                            <ng-select bindLabel="description" dropdownPosition="bottom"
                                                                (ngModelChange)="show.campaignUpdate = true"
                                                                *ngIf="0==i"
                                                                (open)="getTriggers(triggers.triggerType, i)"
                                                                placeholder="Select trigger type"
                                                                (ngModelChange)="step.trigger_type = null;step.time = {};step.trigger_time=null"
                                                                [items]="resources.triggerType"
                                                                [(ngModel)]="step.trigger_type"
                                                                (clear)="step.trigger_type = null;step.time = {};step.trigger_time=null"
                                                                [ngModelOptions]="{standalone: true}">
                                                            </ng-select>
                                                            <ng-select bindLabel="description" dropdownPosition="bottom"
                                                                (ngModelChange)="show.campaignUpdate = true"
                                                                *ngIf="0!=i"
                                                                (open)="getTriggers(triggers.triggerSubType, i)"
                                                                placeholder="Select trigger type"
                                                                (ngModelChange)="step.trigger_type = null;step.time = {};step.trigger_time=null"
                                                                [items]="resources.triggerSubType"
                                                                [(ngModel)]="step.trigger_type"
                                                                (clear)="step.trigger_type = null;step.time = {};step.trigger_time=null"
                                                                [ngModelOptions]="{standalone: true}">
                                                            </ng-select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-6"
                                                    *ngIf="step.trigger_type && 'daily_at' == step.trigger_type.value">
                                                    <div class="form-group">
                                                        <div class="card-body-content timepicker-position">
                                                            <ngb-timepicker [(ngModel)]="step.time"
                                                                (ngModelChange)="transformTime(step)">
                                                            </ngb-timepicker>
                                                            <span *ngIf="!validForm && !step.trigger_time"
                                                                [ngClass]="{'primary-color': !step.trigger_time}">
                                                                <strong>(Please fill in this field)</strong>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-6"
                                                    *ngIf="step.trigger_type && 'after_days' == step.trigger_type.value">
                                                    <div class="card-body-title-basic">
                                                        *
                                                        After days
                                                        <span *ngIf="!validForm && !step.trigger_time"
                                                            [ngClass]="{'primary-color': !step.trigger_time}">
                                                            (Please fill in this field)
                                                        </span>
                                                        <span
                                                            *ngIf="!validForm && (step.trigger_time && step.trigger_time<1)"
                                                            [ngClass]="{'primary-color': step.trigger_time && step.trigger_time<1}">
                                                            (Days cannot be lower than 1)
                                                        </span>
                                                    </div>
                                                    <div class="form-group">
                                                        <div class="card-body-content">
                                                            <input type="number" class="form-control"
                                                                placeholder="Insert after days"
                                                                (ngModelChange)="show.campaignUpdate = true" min=1
                                                                [(ngModel)]="step.trigger_time"
                                                                [ngModelOptions]="{standalone: true}">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="timeline-group">
                                        <a class="btn btn-raised bg-big-search-primary-color mb-0 white"
                                            (click)="addNewStep()">Add next
                                            step</a>
                                    </div>
                                </ul>
                            </section>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngSwitchCase]="'candidates'">
                    <div class="row">
                        <div class="col-12 col-lg-4">
                            <input type="text" class="form-control" placeholder="Search candidates"
                                [(ngModel)]="filters.candidates" [ngModelOptions]="{standalone: true}"
                                (ngModelChange)="searchCandidates()">
                        </div>
                        <div class="col-12 col-lg-4">
                            <ng-select bindLabel="name" bindValue="name" dropdownPosition="bottom" (open)="getSteps()"
                                placeholder="Select step" [items]="resources.steps" [(ngModel)]="filters.step"
                                [ngModelOptions]="{standalone: true}" (ngModelChange)="getCandidates()">
                            </ng-select>
                        </div>
                        <div class="col-12 col-lg-4">
                            <ng-select bindLabel="description" bindValue="value" dropdownPosition="bottom"
                                placeholder="Select events" [items]="resources.events" [(ngModel)]="filters.event"
                                [ngModelOptions]="{standalone: true}" (ngModelChange)="getCandidates()">
                            </ng-select>
                        </div>
                    </div>
                    <div *ngIf="show.candidates;then showCandidates else candidatesMsg"></div>
                    <ng-template #showCandidates>
                        <div class="scroll">
                            <ngb-accordion id="campaign-accordion" *ngFor="let candidate of candidates;let i = index"
                                #accordionCampaigns="ngbAccordion" [closeOthers]="false"
                                [(activeIds)]="activeIdsCampaigns"
                                (panelChange)="preventDefaultCampaigns ? beforeChange($event) : ''">
                                <ngb-panel id="campaigns-{{candidate.uuid}}">
                                    <ng-template ngbPanelHeader>
                                        <div class="row p-4"
                                            (click)="accordionCampaigns.toggle('campaigns-'+candidate.uuid)">
                                            <div class="col-12 col-lg-8 media">
                                                <img class="media-object align-self-center d-flex height-40 width-40 rounded-circle"
                                                    [src]="candidate.photo ? candidate.photo : ('female' == candidate.gender ? globals.femaleAvatar : globals.avatar)"
                                                    (error)="'female' == candidate.gender ? candidate.photo = globals.femaleAvatar : candidate.photo = globals.avatar" />
                                                <div class="media-body mt-3">
                                                    <div class="media-body-title d-flex align-items-center">{{candidate.name}}
                                                        <a class="fa fa-linkedin-square secondary-color tt-icon-lg ml-1"
                                                            href="{{candidate.linkedin}}"
                                                            (click)="openLinkedIn(candidate.linkedin)"
                                                            aria-hidden="true"></a>
                                                            <app-hubspot [data]="candidate.hubspot" [clickable]="false" [origin]="'drawer'"></app-hubspot>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="col-12 col-lg-4 media-object align-self-center">
                                                <div class="media-body">
                                                    {{candidate.substatus}}
                                                </div>
                                            </div> -->
                                            <div class="col-12 col-lg-4 media-object align-self-center">
                                                <div class="media-body">
                                                    {{candidate.primary_email}}
                                                    <a *ngIf="candidate.next_step && ('bounce' == candidate.current_event.value || 'dropped' == candidate.current_event.value ||
                            'not_sent' == candidate.current_event.value || 'blocked' == candidate.current_event.value)"
                                                        (click)="editEmail(candidate.uuid, candidate.primary_email, i)">
                                                        <i class="tt-icon-lg icon-pencil pl-3"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        <div class="m-3">
                                            <div class="row">
                                                <div class="col-12 col-lg-4">
                                                    <div class="form-group">
                                                        <strong>Current step:</strong> {{candidate.current_step.name}}
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-8">
                                                    <div class="form-group">
                                                        <div ngbDropdown placement="bottom-left" CIT-actions
                                                            id="CIT-actions">
                                                            <a id="dropdownBasic1" ngbDropdownToggle>
                                                                <span class="badge m-0"
                                                                    [ngClass]="setBadgeClass(candidate.current_event.value)">
                                                                    <i class="fa fa-circle"></i>
                                                                </span>
                                                                <label>
                                                                    {{candidate.current_event.description}}
                                                                    {{candidate.event_date ? '(' + candidate.event_date
                                                                    + ')': ''}}
                                                                </label>
                                                                <i class="icon-pencil" data-toggle="tooltip"
                                                                    data-placement="bottom"
                                                                    *ngIf="candidate.current_event && 'replied' != candidate.current_event.value "
                                                                    title="Next steps"></i>
                                                            </a>
                                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                                                class="dropdownBasic1"
                                                                *ngIf="candidate.currentStepActions.length">
                                                                <div
                                                                    *ngFor='let action of candidate.currentStepActions'>
                                                                    <a class="dropdown-item"
                                                                        (click)="changeCandidateEvent(action, candidate, i)">
                                                                        {{action.description}}
                                                                    </a>
                                                                </div>
                                                                <a class="dropdown-item"
                                                                    *ngIf="'pending' == candidate.current_event.value && candidate.event_date"
                                                                    (click)="changeCandidateTriggerType(candidate.current_step.uuid, candidate.uuid, i)">
                                                                    Change trigger time
                                                                </a>
                                                                <a class="dropdown-item"
                                                                    (click)="editNextStepEmail(candidate.uuid)"
                                                                    *ngIf="candidate.current_event && 'pending' == candidate.current_event.value">
                                                                    Preview and edit email
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="candidate.next_step && candidate.next_step_event">
                                                <div class="col-12 col-lg-4">
                                                    <strong>Next step:</strong> {{candidate.next_step.name}}
                                                </div>
                                                <div class="col-12 col-lg-8">
                                                    <div ngbDropdown placement="bottom-left" CIT-actions
                                                        id="CIT-actions">
                                                        <a id="dropdownBasic1" ngbDropdownToggle>
                                                            <span class="badge m-0"
                                                                [ngClass]="setBadgeClass(candidate.next_step_event.value)">
                                                                <i class="fa fa-circle"></i>
                                                            </span>
                                                            <label>
                                                                {{candidate.next_step_event.description}}
                                                                {{candidate.scheduled_time ? '(' +
                                                                candidate.scheduled_time + ')': ''}}
                                                            </label>
                                                            <i class="icon-pencil" data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                *ngIf="candidate.next_step_event && 'pending' == candidate.next_step_event.value "
                                                                title="Next steps"></i>
                                                        </a>
                                                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                                            class="dropdownBasic1"
                                                            *ngIf="candidate.nextStepActions.length">
                                                            <div *ngFor='let action of candidate.nextStepActions'>
                                                                <a class="dropdown-item"
                                                                    (click)="changeCandidateEvent(action, candidate, i)">
                                                                    {{action.description}}
                                                                </a>
                                                            </div>
                                                            <div>
                                                                <a class="dropdown-item"
                                                                    *ngIf="candidate.next_step_event && 'pending' == candidate.next_step_event.value && candidate.scheduled_time"
                                                                    (click)="changeCandidateTriggerType(candidate.next_step.uuid, candidate.uuid, i)">
                                                                    Change trigger time
                                                                </a>
                                                                <a class="dropdown-item"
                                                                    (click)="editNextStepEmail(candidate.uuid)"
                                                                    *ngIf="candidate.next_step_event && 'pending' == candidate.next_step_event.value && 'pending' != candidate.current_event.value">
                                                                    Preview and edit email
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ngb-panel>
                            </ngb-accordion>
                        </div>
                    </ng-template>
                    <ng-template #candidatesMsg>
                        <div class="card">
                            <label class="m-3">No candidates available</label>
                        </div>
                    </ng-template>

                </ng-template>
            </div>
        </div>
    </div>
</div>