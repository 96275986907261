import { Component, OnInit } from '@angular/core';
import { Globals } from './globals';
import { VersionCheckService } from './version-check.service';
import { Router } from '@angular/router';

import { environment } from 'environments/environment';
import { Angulartics2Mixpanel } from 'angulartics2';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit{
  constructor(angulartics2Mixpanel: Angulartics2Mixpanel, public globals: Globals, private versionCheckService: VersionCheckService, public router: Router){
    angulartics2Mixpanel.startTracking();
  }

  ngOnInit(){
    if (environment.production){
      this.versionCheckService.initVersionCheck('./version.json');
    }
  }
}
