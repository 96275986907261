import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Globals } from 'app/globals';
import { JobApplicationModalService } from './job-application-modal.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { isEqual, cloneDeep } from 'lodash';

@Component({
  selector: 'app-job-application-modal',
  templateUrl: './job-application-modal.component.html',
  styleUrls: ['./job-application-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class JobApplicationModalComponent implements OnInit{

  candidates: any = [];

  application: any;

  originalData: any;

  job_uuid: string = null;

  changed = false;

  nps_send:boolean;

  resources: any = {
    statuses: [],
    substatuses: [],
    consultants: [],
    client_interview_type: [],
    currencies: [],
    bonus_type: []
  };

  loadingStatuses = false;

  loadingSubstatuses = false;

  constructor(public globals: Globals,
    private jobApplicationModalService: JobApplicationModalService,
    public toastr: ToastrService,
    public activeModal: NgbActiveModal){
  }

  ngOnInit(){
    if (this.candidates.length){
      this.application = cloneDeep(this.candidates[0]);
    }
    this.originalData = cloneDeep(this.application);
    this.getSubstatuses();
    this.setPipelineDataParams();
  }

  getStatuses(){
    if (!this.resources.statuses.length){
      this.loadingStatuses = true;
      this.jobApplicationModalService.getStatuses(this.job_uuid).subscribe(
        (data: any) => {
          this.resources.statuses = data;
          this.loadingStatuses = false;
        },
        (err) => {
          this.displayErrorMessage(err);
          this.loadingStatuses = false;
        });
    }
  }

  getSubstatuses(){
    this.loadingSubstatuses = true;
    this.jobApplicationModalService.getSubstatuses(this.transformCurrentStatus(), this.getPreviousStatus(), this.job_uuid).subscribe(
      (data: any) => {
        this.resources.substatuses = data;
        if (this.application.current_status.value === 'contacting'){
          this.transformConsultantSubstatuses();
        }
        this.loadingSubstatuses = false;
      },
      (err) => {
        this.displayErrorMessage(err);
        this.loadingSubstatuses = false;
      });
  }

  transformCurrentStatus(): any{
    if (this.application.current_status.value === 'rejected'){
      const contactStatuses = ['to_contact', 'contacting', 'recruiter_interview', 'assessed'];
      if (contactStatuses.includes(this.originalData.current_status.value)){
        return 'contact_archived';
      }
      const interviewStatuses = ['shortlist', 'client_interview', 'offer', 'hire'];
      if (interviewStatuses.includes(this.originalData.current_status.value)){
        return 'interview_archived';
      }
    }
    return this.application.current_status.value;
  }

  /**
   * We need to send previous status to the backend in case we archive candidates
   * @returns Returns previous status if archiving, else returns null.
   */
  getPreviousStatus(){
    if (this.application.current_status.value === 'rejected'){
      return this.originalData.current_status.value;
    }
    const rejectedStatuses = ['contact_archived', 'interview_archived'];
    if (rejectedStatuses.includes(this.application.current_status.value)){
      return this.originalData.latest_status_audit;
    }
    return null;
  }

  /**
   * Brings the elements of the nested 'Contacted by consultant' substatus array to the outside and sets 'group' value to each of them for grouping, potentially improving the ng-select behavior
   */
  transformConsultantSubstatuses(): void{
    const consultantIndex = this.resources.substatuses.findIndex(substatus => substatus.value === 'contacted_by_consultant');
    if (consultantIndex !== -1){
      this.resources.substatuses[consultantIndex].children?.forEach(substatus => {
        substatus.children = this.resources.substatuses[consultantIndex].description;
        this.resources.substatuses.push(substatus);
      }
      );
      this.resources.substatuses.splice(consultantIndex, 1);
    }
  }

  checkRejectionNpsEligibility(): boolean{
    return ['recruiter_interview', 'assessed', 'shortlist', 'client_interview', 'offer', 'hire'].includes(this.originalData?.current_status?.value);
  }

  getConsultants(){
    this.jobApplicationModalService.getUsers("consultants", this.job_uuid)
      .subscribe((data) => {
        this.resources.consultants = data;
      },
        (err) => {
          this.displayErrorMessage(err);
        });
  }

  getResourcesByType(type){
    this.jobApplicationModalService.getResourcesByType(type)
      .subscribe((data) => {
        this.resources[type] = data;
      },
        (err) => {
          this.displayErrorMessage(err);
        });
  }

  /**
   * Runs when the substatus is changed
   * @param event Holds the new substatus object
   */
  onSubstatusChange(){
    this.setPipelineDataParams();
    this.trackChanges();
  }

  /**
   * This method is called when status is changed. Clears the current substatus and requests new substatus resources. If 'offer or hire status is selected, it will preset the pipeline data.
   * @param event This parameter hold the selected status option
   */
  onStatusChange(){
    this.application.substatus = null;
    this.setPipelineDataParams();
    this.getSubstatuses();
  }

  /**
   * This method is used to reset the application to the original data
   */
  resetStatus(){
    this.application.current_status = this.originalData.current_status;
    this.application.substatus = this.originalData.substatus;
    this.application.pipeline_data = cloneDeep(this.originalData.pipeline_data);
    this.getSubstatuses();
    this.changed = false;
  }

  /**
   * This method is used to populate pipeline data based on the status and substatus chosen
   */
  setPipelineDataParams(){
    const status = this.application.current_status.value;

    if (this.application.pipeline_data && status in this.application.pipeline_data && this.application.pipeline_data[status]){
      return;
    }
    //we need to hardcode this because status is different then pipeline data key. recruiter_interview VS internal_interview
    if (status === 'recruiter_interview' && this.application.pipeline_data && 'internal_interview' in this.application.pipeline_data && this.application.pipeline_data.internal_interview){
      return;
    }

    if (this.originalData.pipeline_data && status in this.originalData.pipeline_data && this.originalData.pipeline_data['status']){
      this.application.pipeline_data = this.originalData.pipeline_data;
      return;
    }

    if (status === 'recruiter_interview' && this.application.substatus?.value == 'scheduled_interview'){
      this.application.pipeline_data = {
        internal_interview: {
          details: {
            date: null,
            time: null,
            interviewer: null
          }
        }
      };
      return;
    }

    if (status === 'client_interview' && this.application.substatus?.value == 'scheduled_x_interview'){
      this.application.pipeline_data = {
        client_interview: {
          details: {
            date: null,
            time: null,
            interviewer: null,
            type: null
          }
        }
      };
      return;
    }

    if (status === 'offer'){
      this.application.pipeline_data = {
        offer: {
          details: {
            base_salary: null,
            currency: null,
            bonus: null,
            bonus_type: null,
            equity: null,
            date: null,
            comment: null
          }
        }
      };
      return;
    }

    if (status === 'hire'){
      this.application.pipeline_data = {
        hire: {
          details: {
            base_salary: this.application.pipeline_data?.offer?.details.base_salary || null,
            currency: this.application.pipeline_data?.offer?.details.currency || null,
            bonus: this.application.pipeline_data?.offer?.details.bonus || null,
            bonus_type: this.application.pipeline_data?.offer?.details.bonus_type || null,
            equity: this.application.pipeline_data?.offer?.details.equity || null,
            date: this.application.pipeline_data?.offer?.details.date || null,
            comment: this.application.pipeline_data?.offer?.details.comment || null,
            fee: null
          }
        }
      };
      return;
    }
    delete this.application.pipeline_data;
  }

  updateApplication(){
    const body: any = this.generateApplicationBody();
    if (!body){
      this.toastr.warning("No changes were made");
      return;
    }
    this.jobApplicationModalService.updateApplication(body).subscribe(
      (response) => {
        this.toastr.success(response.message.success[0].message);
        this.activeModal.close({
          'application': this.application,
          'originalData': this.originalData
        });
      },
      (err) => {
        this.displayErrorMessage(err);
      });
  }

  /**
   *
   * This method is used to generate the body for the update application request
   * @returns - Returns the body to be sent to the server
   */
  generateApplicationBody(){
    if (this.candidates.length > 1){
      const body = [];
      this.candidates.forEach(element => {
        const application = {
          application_uuid: element.application_uuid,
          status: {
            value: this.transformCurrentStatus()
          },
          substatus: this.application.substatus
        };
        body.push(application);
      });
      return body;
    }
    const body: any = {};
    this.addBodyParameters(body);

    // in case no changes are made to the application we return false
    if (!Object.keys(body).length){
      return false;
    }
    body.application_uuid = this.application.application_uuid;
    return [body];
  }

  /**
   * This method will mutate the body object in generateApplicationBody() by comparing and adding all the changes on application with the originalData.
   * @param body
   */
  addBodyParameters(body){
    if (!isEqual(this.application.current_status.value, this.originalData.current_status.value)){
      body.status = {
        value: this.transformCurrentStatus()
      };
    }

    if (!isEqual(this.application.substatus, this.originalData.substatus)){
      body.substatus = this.application.substatus;
    }

    if (!isEqual(this.application.pipeline_data, this.originalData.pipeline_data)){
      body.pipeline_data = this.application.pipeline_data;
    }
    if (this.nps_send){
      body.nps_send = true;
    }
  }

  /**
   * This method is used to display error messages
   * @param error
   */
  displayErrorMessage(error){
    error = error?.error?.errors;//flatten error message
    if (error){
      for (const errProp in error){
        for (const message in error[errProp]){
          this.toastr.error(error[errProp][message]);
        }
      }
    } else {
      this.toastr.error('Something went wrong');
    }
  }

  /**
   * This method tracks changes in the modal, which makes the 'Reset Application' button visible
   */
  trackChanges(){
    this.changed = true;
  }

  /**
   * This method clears the time when the date is cleared, and when date is selected for the first time it will set up default time as 0:0 (This is a workaround for the 'hour' issue with the <ngb-timepicker> element)
   * @param interviewType A pipeline_data child object consisting on the type of interview (internal_interview or client_interview).
   */
  dateChange(interviewType){
    this.changed = true;
    if (!interviewType.details.date){
      interviewType.details.time = null;
      return;
    }
    if (!interviewType.details.time){
      interviewType.details.time = {
        hour: 0,
        minute: 0
      };
    }
  }

  closePopup(){
    this.changed = false;
    this.activeModal.dismiss();
  }

  generateEmptySubstatus(): any{
    return {
      additional_comment: null,
      value: null,
      description: null
    };
  }

  isCandidateRejected(){
    const rejectedStatuses = ['rejected', 'contact_archived', 'interview_archived'];
    return rejectedStatuses.includes(this.application.current_status.value);
  }
}