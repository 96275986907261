import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GetnavisCandidateEmailModalService{

  constructor(private http: HttpClient){ }

  updateCandidate(uuid, body): Observable<any>{
      return this.http.put(environment.getNavisBackendURL + '/candidates/' + uuid, body);
  }

  postCandidatesToCampaign(data: any): Observable<any>{
        const body = {
            candidates: data.candidates
        };
        return this.http.post(environment.getNavisBackendURL + '/campaigns/' + data.campaign + '/candidates', body);
    }
  }