import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from 'app/globals';
import { ToastrService } from 'ngx-toastr';
import { GetNavisBlacklistCandidateModalService } from './blacklist-candidate-modal.service';

@Component({
  selector: 'app-blacklist-candidate-modal',
  templateUrl: './blacklist-candidate-modal.component.html',
  styleUrls: ['./blacklist-candidate-modal.component.scss']
})
export class GetNavisBlacklistCandidateModalComponent implements OnInit{
  candidate_uuid: string;

  job_uuid: string;

  data: any = {};

  resources = {
    jobs: []
  };

  constructor(private blacklistCandidateModalService: GetNavisBlacklistCandidateModalService, public globals: Globals, public activeModal: NgbActiveModal, private toastr: ToastrService){

   }

  ngOnInit(){
    this.data.job = this.job_uuid;
    this.getJobs();
  }

  getJobs(){
    if (!this.resources.jobs.length){
      this.blacklistCandidateModalService.getJobs().subscribe(
        (data) => {
          if (data){
            this.resources.jobs = data;
          }
        },
        (err) => {
          this.toastr.error(err);
        });
    }
  }

  blacklistCandidate(){
    const body = {
      candidate_uuid : this.candidate_uuid,
      job_uuid: this.data.job,
      reason: this.data.reason
    };
    this.blacklistCandidateModalService.blacklistCandidate(body).subscribe(
      (response) => {
        if (response){
          this.toastr.success("Succesfoly blacklisted");
          this.activeModal.close(true);
        }
      },
      (err) => {
        if (err.error){
          for (const errProp in err.error){
            if (err.error.hasOwnProperty(errProp) && err.error[errProp][0]){
              this.toastr.error(err.error[errProp]);
            }
          }
        } else {
          this.toastr.error('Something went wrong');
        }
      });

  }

}
