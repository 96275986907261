<div class="modal-content">
  <div class="modal-bg">
    <div class="modal-header">
      <div class="modal-header-title">
        {{globals.labels.placeholders.full_layout_page.omni_component.currently_unemployed.title}}
      </div>
      <button type="button" class="close pull-right" aria-label="Close"  (click)="activeModal.dismiss()">
        <span aria-hidden="true" [innerHTML]="globals.labels.placeholders.global.buttons.close_x" data-toggle="tooltip"
              data-placement="bottom" [title]="globals.labels.tooltips.global.close"></span>
      </button>
    </div>
    <div class="modal-body">
      <div class="modal-body-title">
        {{globals.labels.placeholders.full_layout_page.omni_component.currently_unemployed.description}}
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="col-md-3 btn btn-block bg-primary-color"  (click)="activeModal.close()">{{globals.labels.placeholders.global.buttons.yes}}
      </button>
      <button type="button" class="col-md-3 btn btn-block btn-raised bg-secondary-color"  (click)="activeModal.dismiss()">{{globals.labels.placeholders.global.buttons.no}}</button>
    </div>
  </div>
</div>
