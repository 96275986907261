import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { CloseJobService } from './close-job.service';
import { ToastrService } from 'ngx-toastr';
import { Globals } from 'app/globals';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-close-job',
  templateUrl: './close-job.component.html',
  styleUrls: ['./close-job.component.scss']
})
export class CloseJobComponent implements OnInit, OnDestroy{
  @Input() uuid: string;

  @Output() dismissDrawer: EventEmitter<boolean> = new EventEmitter<boolean>();

  closingData: any = {};

  status: any = [];

  reason: any = [];

  validForm = true;

  job_uuid = '';

  subscription: any;

  currencies: any = [];

  constructor(private closeJobService: CloseJobService, private toastr: ToastrService, public globals: Globals, private router: Router){ }

  ngOnInit(){
    let oldUUID = this.router.parseUrl(this.router.url).root.children.primary.segments[1].path;
    this.initClosingJob();
    this.job_uuid = this.uuid;
    this.subscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      if (this.router.parseUrl(this.router.url).root.children.primary.segments[1]){
        let newUUID = '';
        if (oldUUID != this.router.parseUrl(this.router.url).root.children.primary.segments[1].path){
          newUUID = this.router.parseUrl(this.router.url).root.children.primary.segments[1].path;
          oldUUID = newUUID;
        }
        if (newUUID){
          this.job_uuid = newUUID;
          this.initClosingJob();
        }
      }
    });
  }

  initClosingJob(){
    this.closingData = {
      additional_info: null,
      //cancellation_fee: 0,
      //fee_currency_id: null,
      status_id: null,
      reason_id: null
    };
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  getResources(){
    if (!this.currencies.length || !this.status.length){
      this.closeJobService.getResources()
        .subscribe((data) => {
          this.status = data.filter((el) => el.type == 'closing_role_type');
          this.currencies = data.filter((el) => el.type == 'currencies');
        },
          (err) => {
            this.toastr.error(err.message);
          });
    }
  }

  getReason(status_id){
    const reasons = this.status.find(record => record.id == status_id);
    this.reason = reasons.children;
  }

  checkRequiredFields(){
    if (this.closingData.reason_id && this.closingData.status_id){
      this.validForm = true;
    } else this.validForm = false;
  }

  closeJob(){
    this.checkRequiredFields();
    if (this.validForm){
      this.closingData.status_id = this.closingData.status_id.id;
      this.closingData.reason_id = this.closingData.reason_id.id;
      this.closeJobService.closeJob(this.job_uuid, this.closingData)
        .subscribe(() => {
          this.initClosingJob();
          this.toastr.success('Successfully closed job');
          this.dismissDrawer.emit(true);
        },
          (err) => {
            this.toastr.error(err.message);
          }
        );
    }
  }
}
