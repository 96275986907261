import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";

@Injectable()
export class RemoveCandidateModalService{
  constructor(private httpClient: HttpClient){}

  removeCandidates(body): Observable<any>{
    const headers = new Headers({
      'Content-Type': 'application/json'
    });
    const options:any = {
      headers: headers,
      body: body
    };
    return this.httpClient.delete(environment.getNavisBackendURL + '/merger/candidates/applications', options);
  }

}