  <div class="modal-content" *ngIf="show.component">
    <div class="modal-bg">
      <div class="modal-header modal-header-bg white">
        <div class="modal-header-title modal-header-body-bg full-width row align-self-center">
          <p class="col-8 align-self-center mb-0 pl-4">
            Add Hiring Company
          </p>
        </div>
        <button type="button" class="close pull-right white" aria-label="Close" (click)="activeModal.dismiss()">
          <span aria-hidden="true" data-toggle="tooltip" data-placement="bottom" class="ft-x font-medium-3"></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="card card-top">
        <div class="card-block card-body scroll hiring-company-height">
          <div class="row">
            <div class="col-md-12 col-12">
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>
                    * Name
                    <span >
                      (Required field)
                    </span>
                  </label>
                </div>
                <div class="card-body-content">
                  <ng-select [items]="companies | async" #companiesSelect [hideSelected]="true"
                      bindLabel="name" bindValue="name" [loading]="companyLoading" [typeahead]="companyinput$" 
                      [(ngModel)]="hiringCompany.name" [clearable]="true" placeholder="Select company"
                      (change)="setCompanyData($event)" (clear)="initData()">
                      <ng-template ng-option-tmp let-item="item" let-index="i">
                        <div class="media mb-1" *ngIf="item.dealroom_data; else noDealroom">
                          <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                            [src]="item.dealroom_data ? (item.dealroom_data.images['32x32'] ? item.dealroom_data.images['32x32'] : globals.companyAvatar) : ''"
                            (error)="item.dealroom_data.images = globals.companyAvatar" />
                          <div class="media-body">
                            <div class="media-body-title mt-1 mb-0">{{item.name}}<span *ngIf="item.dealroom_data.website_url">, {{item.website_url}}</span></div>
                            <p *ngIf="item.dealroom_data.hq_locations && item.dealroom_data.hq_locations.length">
                              <i class="tt-icon-sm icon-ttrelocation"></i>
                              <span *ngFor="let location of item.dealroom_data.hq_locations;let last = last">
                                {{location.address}} {{!last ? ' (HQ), ' : ' (HQ)'}}
                              </span>
                            </p>
                          </div>
                        </div>
                        <ng-template #noDealroom>
                          <div class="media mb-1">
                            <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                              [src]="item.photo || (item.images ? (item.images['32x32'] ? item.images['32x32'] : globals.companyAvatar) : '')"
                              (error)="item.images = globals.companyAvatar" />
                            <div class="media-body">
                              <div class="media-body-title mt-1 mb-0">{{item.name}}<span *ngIf="item.website_url">, {{item.website_url}}</span></div>
                              <p *ngIf="item.hq_locations && item.hq_locations.length">
                                <i class="tt-icon-sm icon-ttrelocation"></i>
                                <span *ngFor="let location of item.hq_locations;let last = last">
                                  {{location.address}} {{!last ? ' (HQ), ' : ' (HQ)'}}
                                </span>
                              </p>
                            </div>
                          </div>
                        </ng-template>
                        
                      </ng-template>
                      <ng-template *ngIf="companiesSelect.searchTerm" ng-footer-tmp let-search="searchTerm">
                        <div class="mb-1 pull-right">
                          <button class="btn bg-secondary-color btn-position pull-right mb-0" (click)="addCompany(search)" >Add company {{search}}</button>
                        </div>
                      </ng-template>
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-12">
              <div class="card-body-title-basic">
                <label>
                  Logo URL
                </label>
              </div>
              <div class="media mb-3">
                <img class="align-self-center mr-3 height-40 width-40 rounded-circle" [src]="hiringCompany.photo || globals.companyAvatar">
                <div class="media-body">
                  <input type="text" class="form-control" placeholder="Insert logo url"
                      [(ngModel)]="hiringCompany.photo" [ngModelOptions]="{standalone: true}">
                </div>
              </div>
            </div>
            <div class="col-md-12 col-12">
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>
                    Linkedin URL
                  </label>
                </div>
                <div class="card-body-content">
                  <input type="text" class="form-control" placeholder="Insert linkedin url"
                  [(ngModel)]="hiringCompany.linkedin_url" [ngModelOptions]="{standalone: true}">
                </div>
              </div>
            </div>
            <div class="col-md-12 col-12">
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>
                    Dealroom URL
                  </label>
                </div>
                <div class="card-body-content">
                  <input type="text" class="form-control" placeholder="Insert dealroom url"
                  [(ngModel)]="hiringCompany.dealroom_url" [ngModelOptions]="{standalone: true}">
                </div>
              </div>
            </div>
            <div class="col-md-12 col-12">
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>
                    Website URL
                  </label>
                </div>
                <div class="card-body-content">
                  <input type="text" class="form-control" placeholder="Insert website url"
                  [(ngModel)]="hiringCompany.website_url" [ngModelOptions]="{standalone: true}">
                </div>
              </div>
            </div>
            <div class="col-md-12 col-12">
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>
                    Company culture description
                  </label>
                </div>
                <div class="card-body-content">
                  <textarea rows="2" class="form-control" type="text" placeholder="Add Comment" 
                    [(ngModel)]="hiringCompany.company_culture_description" [ngModelOptions]="{standalone: true}">
                  </textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn bg-primary-color" (click)="initData()">Clear data</button> -->
        <button type="button" class="btn bg-secondary-color pull-left" (click)="createHiringCompany()">Add Hiring Company</button>
        <button type="button" class="btn bg-primary-color"(click)="activeModal.dismiss()">Close</button>
      </div>
    </div>
  </div>