import { takeWhile } from 'rxjs/operators';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CommunicationService } from 'app/shared/service/communication.service';
import { Globals } from 'app/globals';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-contact-drawer',
  templateUrl: './contact-drawer.component.html',
  styleUrls: ['./contact-drawer.component.scss']
})
export class ContactDrawerComponent implements OnInit{
  componentAlive = true;

  uuid = '';

  data: any = {};

  width: number;

  constructor(private communicationService: CommunicationService, public globals: Globals, private router: Router, private activatedRoute: ActivatedRoute, private cdr: ChangeDetectorRef){
    this.communicationService.onChooseContactDrawer$.pipe(takeWhile(() => this.componentAlive))
      .subscribe((data) => {
        if (data){
          this.data = data;
          if (!this.cdr['destroyed']){
            this.cdr.detectChanges();
          }
          const element = document.getElementById('contact-drawer');
          if (element){
            element.style.visibility = 'visible';
            element.classList.add('open');
          }
        }
      });
  }

  ngOnInit(){
    $.getScript('assets/js/notification-sidebar.js');
    const element = document.getElementById('contact-drawer');
    if (element){
      element.style.visibility = 'hidden';
    }
    this.width = document.body.clientWidth;
  }

  dismiss(){
    this.router.navigate([],
      {
        relativeTo: this.activatedRoute,
        queryParams: {}
      });
    const element = document.getElementById('contact-drawer');
    element.style.visibility = 'hidden';
  }
}
