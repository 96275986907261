import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class NavbarService{

    constructor(
        private httpClient: HttpClient
    ){ }

    getNotifications(): Observable<any>{
        return this.httpClient.get(environment.platformBackendURL + '/events/latest');
    }

    markAsRead(uuid): Observable<any>{
        return this.httpClient.get(environment.platformBackendURL + '/events/' + uuid + '/markAsRead');
    }

    readAllNotification(): Observable<any>{
        return this.httpClient.get(environment.platformBackendURL + '/events/markAllAsRead');
    }

    getDataForGlobalSearch(searchTerm, type, page?): Observable<any>{
        return this.httpClient.get(environment.djangoURL + '/globalSearch/' + type + '?name=' + searchTerm + '&page=' + page);
    }

    getCountEverything(searchTerm){
        return this.httpClient.get(environment.djangoURL + '/globalSearch/count?name=' + searchTerm);
    }
}
