<div class="modal-content">
    <div class="modal-bg">
        <div class="modal-header">
            <div class="modal-header-title" *ngIf="create_activity_type == 'inmail_reply'; else showGlobalNoteTitle">
                Add InMail reply
            </div>
            <ng-template #showGlobalNoteTitle>
                <div class="modal-header-title">
                    Add global note
                </div>
              </ng-template>
            <button type="button" class="close pull-right" aria-label="Close">
                <span aria-hidden="true" data-toggle="tooltip" (click)="closePopup()" data-placement="bottom">x</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row" *ngIf="create_activity_type == 'inmail_reply'">
                <div class="col-6">
                    <div class="form-group">
                        <div class="modal-body-title">
                            <label>* Job</label>
                        </div>
                        <div class="modal-body-content">
                            <ng-select bindLabel="title" bindValue="uuid" dropdownPosition="bottom" [clearable]="false"
                                placeholder="Select job" [items]="resources.jobs" [(ngModel)]="activity.position_uuid"
                                [ngModelOptions]="{standalone: true}">
                                <ng-template ng-label-tmp let-item="item" let-index="i">
                                    <span>{{item.title}} {{item.company ? ' - ' + item.company.name : ''}}</span>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-index="i">
                                    <span>{{item.title}} {{item.company ? ' - ' + item.company.name : ''}}</span>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <div class="modal-body-title">
                            <label>Description</label>
                        </div>
                        <div class="modal-body-content scroll">
                            <quill-editor id="quill-editor-activity" [(ngModel)]="activity.value"
                                placeholder="Please enter description">
                            </quill-editor>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn pull-right bg-secondary-color" (click)="createCandidateInsight()">Create</button>
        </div>
    </div>
</div>