<div class="row mobile-scroll scroll" *ngIf="candidateDataIsDownloaded">
  <div class="col-lg-3 col-12 candidate-sidebar  border-sidebar-right">
    <div class="row">
      <div class="col-12">
        <div class="card" [ngClass]="{'scroll scroll-details':innerWidth > 1200}">
          <div class="card-block">
            <div class="card-content">
              <div class="card-body">
                <div class="row">
                  <div class="col-3">
                    <div class="hvrbox" *ngIf="edit.basic">
                      <a (click)="fileInput.click()" data-toggle="tooltip" data-placement="top" title="Click to choose new image">
                        <img class="media-object d-flex height-70 width-70 rounded-circle" [src]="candidate.picture_file_url ? candidate.picture_file_url :
                            (candidate.gender ?
                              (candidate.gender === 'female' || candidate.gender.value ==='female' ? globals.femaleAvatar : globals.avatar) :
                            globals.avatar)" (error)="candidate.gender && candidate.gender === 'female' ?
                            candidate.picture_file_url = globals.femaleAvatar :
                            candidate.picture_file_url = globals.avatar">
                        <div class="hvrbox-layer_top">
                          <div class="hvrbox-text">Choose image</div>
                        </div>
                      </a>
                      <span class="span-uploader">
                        <input type="file" #fileInput ng2FileSelect [uploader]="uploader" (change)="OnContentChange($event)" />
                      </span>
                    </div>
                    <img class="media-object d-flex height-70 width-70 rounded-circle" *ngIf="!edit.basic" [src]="candidate.picture_file_url ? candidate.picture_file_url :
                          (candidate.gender ?
                            (candidate.gender === 'female' || candidate.gender.value ==='female' ? globals.femaleAvatar : globals.avatar) :
                          globals.avatar)" (error)="candidate.gender && candidate.gender === 'female' ?
                            candidate.picture_file_url = globals.femaleAvatar :
                            candidate.picture_file_url = globals.avatar">
                  </div>
                </div>
                <div class="media pb-0">
                  <div class="media-body align-self-center">
                    <p class="text-bold text-lg mb-0 line-height-custom d-flex align-self-center">
                      <strong> {{candidate.first_name}} {{candidate.middle_name}}
                        {{candidate.last_name}}</strong>
                        <app-hubspot [data]="candidate.hubspot" [clickable]="false"></app-hubspot>
                    </p>
                    <p class="mb-0"> {{candidate.title}}
                      <span *ngIf="candidate.company">
                        @ <app-dealroom-widget [companyName]="candidate.company.name"></app-dealroom-widget>
                      </span>
                    </p>
                    <p class="text-color-700 mb-0" *ngIf="candidate.location">
                      {{candidate.location.description}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="candidate.tags">
              <div class="row">
                <div class="col-12 mb-2">
                  <ng-template #tipContent>
                    <div *ngIf="tooltipText" class="card card-preview">
                      <div class="card-body">
                        <div class="card-body-content" [innerHTML]="tooltipText"></div>
                      </div>
                    </div>
                  </ng-template>
                  <span *ngFor="let tag of candidate.tags;let first=first" [ngStyle]="{'background-color':tag.background_color, 'color':tag.text_color}"
                    class="badge-tags-inverse nowrap align-self-center  mt-2 mr-1 display-inline-flex tool-tip-bgc" [ngbTooltip]="tipContent" container="body" placement="bottom"
                    (mouseover)="tooltipText=tag.hover_data" (mouseout)="tooltipText=''">
                    {{tag.description}}
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mr-2">
                <li class="nav-item" ngbDropdown placement="bottom-right">
                  <button id="dropdownBasic3" class="btn bg-secondary-color col-12" type="button" ngbDropdownToggle aria-expanded="true">
                    Actions
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic3" class="card-shadow notification-dropdown">
                    <a (click)="addToJobModal()" class="dropdown-item py-1" href="javascript:;">
                      <i class="ft-edit-3 mr-2"></i>
                      <span>Add to Job</span>
                    </a>
                    <a (click)="blacklistCandidateModal()" class="dropdown-item py-1" href="javascript:;">
                      <i class="tt-icon-sm fa fa-trash mr-2"></i>
                      <span>Blacklist candidate</span>
                    </a>
                    <a (click)="addCandidateActivity('inmail_reply')" class="dropdown-item py-1" href="javascript:;">
                      <i class="ft-edit-3 mr-2"></i>
                      <span>Add InMail reply</span>
                    </a>
                    <a (click)="addCandidateActivity('global_note')" class="dropdown-item py-1" href="javascript:;">
                      <i class="ft-edit-3 mr-2"></i>
                      <span>Add global note</span>
                    </a>
                  </div>
                </li>
              </div>
            </div>
            <div class="row candidate-info">
              <div class="col-12 pr-0" *ngIf="checkIfExistInContactDetails(candidate.contact_details, 'linkedin')">
                <p class="text-color-700 mb-0 mt-3">
                  <i class="tt-icon-md fa fa-linkedin-square mr-2"></i>
                  <strong>Linkedin</strong>
                </p>
                <p class="mb-0" *ngFor="let linkedin of candidate.contact_details | filterContactArray:'linkedin'"><a href="{{linkedin.value}}" target="_blank">{{linkedin.value}}</a></p>
              </div>
              <div class="col-12 pr-0" *ngIf="checkIfExistInContactDetails(candidate.contact_details, 'email')">
                <p class="text-color-700 mb-0 mt-3">
                  <i class="tt-icon-md fa fa-envelope mr-2"></i>
                  <strong>Email</strong>
                </p>
                <p class="mb-0" *ngFor="let email of candidate.contact_details | filterContactArray:'email'">{{email.value}}</p>
              </div>

              <div class="col-12 pr-0" *ngIf="checkIfExistInContactDetails(candidate.contact_details, 'phone')">
                <p class="text-color-700 mb-0 mt-3">
                  <i class="tt-icon-md fa fa-phone mr-2"></i>
                  <strong>Phone</strong>
                </p>
                <p class="mb-0" *ngFor="let phone of candidate.contact_details | filterContactArray:'phone'">{{phone.value}}</p>
              </div>

              <div class="col-12 pr-0" *ngIf="candidate.created_by || candidate.created_at">
                <p class="text-color-700 mb-0 mt-3"> <strong>Created</strong> </p>
                <p> {{candidate.created_by?.first_name}} {{candidate.created_by?.last_name}}
                  {{candidate.created_at}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg col-12 candidate-details mt-4" [ngClass]="innerWidth<=990 ? 'ml-2 pr-3' : ''">
    <nav ngbNav #acc="ngbNav" class="full-width nav-tabs" id="tab-shadow" (navChange)="fetchActiveTab($event)">
      <ng-container [ngbNavItem]="'ngb-tab-0'">
        <a ngbNavLink>Details</a>
        <ng-template ngbNavContent>
          <div class="card card-top" [ngClass]="{'scroll scroll-tabs':innerWidth > 1200}">
            <ngb-accordion id="filter-accordion" [(activeIds)]="activeIds" class="ng-accordion-append" #acc="ngbAccordion" (panelChange)="preventDefault ? beforeChange($event) : ''">
              <ngb-panel id="basic">
                <ng-template ngbPanelHeader>
                  <div class="form" (click)="acc.toggle('basic')">
                    <div class="form-section">
                      Basic details
                      <div class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2" (click)="editFields('basic')">
                        edit
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div *ngIf="edit.basic">
                    <div class="row card-block">
                      <div class="col-md-4 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              First name *
                            </label>
                          </div>
                          <div class="card-body-content">
                            <input type="text" class="form-control" placeholder="Insert first name" [(ngModel)]="candidate.first_name" [ngModelOptions]="{standalone: true}" appOnFocusOut
                              (focusout)="changed ? editCandidate('first_name', candidate.first_name) : ''" (ngModelChange)="edit.basic ? onChange($event) : ''">
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Middle name
                            </label>
                          </div>
                          <div class="card-body-content">
                            <input type="text" class="form-control" placeholder="Insert middle name" [(ngModel)]="candidate.middle_name" [ngModelOptions]="{standalone: true}" appOnFocusOut
                              (focusout)="changed ? editCandidate('middle_name', candidate.middle_name) : ''" (ngModelChange)="edit.basic ? onChange($event) : ''">
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Last name *
                            </label>
                          </div>
                          <div class="card-body-content">
                            <input type="text" class="form-control" placeholder="Insert last name" [(ngModel)]="candidate.last_name" [ngModelOptions]="{standalone: true}" appOnFocusOut
                              (focusout)="changed ? editCandidate('last_name', candidate.last_name) : ''" (ngModelChange)="onChange($event)">
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Gender
                            </label>
                          </div>
                          <div class="card-body-content">
                            <ng-select bindLabel="description" dropdownPosition="bottom" placeholder="Select gender" [items]="resources.gender" (open)="getGender()" [(ngModel)]="candidate.gender"
                              [ngModelOptions]="{standalone: true}" (change)="editCandidate('gender', candidate.gender)">
                            </ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Location
                            </label>
                          </div>
                          <div class="card-body-content">
                            <ng-select [items]="locations$ | async" bindLabel="description" placeholder="Select location" dropdownPosition="bottom" [minTermLength]="minLengthTerm"
                              [loading]="locationLoading" typeToSearchText="Please enter {{minLengthTerm}} or more characters" [typeahead]="locationInput$" [(ngModel)]="candidate.location"
                              (change)="editCandidate('location', candidate.location)" [ngModelOptions]="{standalone: true}">
                            </ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Primary experience
                            </label>
                          </div>
                          <div class="card-body-content">
                            <ng-select class="ng-append" (open)="setPrimaryExperiences()" [items]="primaryExperiences" dropdownPosition="bottom" bindLabel="title"
                              placeholder="Select primary experience" [(ngModel)]="primaryExperience" (change)="setPrimaryExperience($event)">
                            </ng-select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="!edit.basic">
                    <div class="row card-block">
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Gender
                            </label>
                          </div>
                          <div class="card-body-content">
                            {{candidate.gender ? (candidate.gender.description || candidate.gender) : 'No information
                            available'}}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Primary Experience
                            </label>
                          </div>
                          <div class="card-body-content">
                            {{primaryExperience ? primaryExperience.title : 'No information available'}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="contact">
                <ng-template ngbPanelHeader>
                  <div class="form" (click)="acc.toggle('contact')">
                    <div class="form-section">
                      Contact details
                      <div class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2 ml-2" (click)="editFields('contact')">
                        edit
                      </div>
                      <div class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2 ml-2" (click)="addForm('contactForm')">
                        add
                      </div>
                      <div class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2"  *ngIf="enableImport" (click)="onImportClick()">
                        import
                        <div class="icon-import-contacts ml-1"></div>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div *ngIf="add.contactForm">
                    <form class="form-group" #form>
                      <div class="card card-preview">
                        <div class="row">
                          <div class="col-lg-6 col-sm-12">
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  Source *
                                </label>
                              </div>
                              <div class="card-body-content">
                                <ng-select bindLabel="description" dropdownPosition="bottom" placeholder="Select source" [items]="resources.contact_source" (open)="getResource('contact_source')"
                                  [(ngModel)]="add.contact.source" [ngModelOptions]="{standalone: true}">
                                </ng-select>
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  Type *
                                </label>
                              </div>
                              <div class="card-body-content">
                                <ng-select bindLabel="description" dropdownPosition="bottom" placeholder="Select type" [items]="resources.contact_type" (open)="getResource('contact_type')"
                                  [(ngModel)]="add.contact.type" [ngModelOptions]="{standalone: true}">
                                </ng-select>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-sm-12">
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  Share with *
                                </label>
                              </div>
                              <div class="card-body-content">
                                <ng-select bindLabel="description" dropdownPosition="bottom" placeholder="Select share with" [items]="resources.contact_shared_with"
                                  (open)="getResource('contact_shared_with')" [(ngModel)]="add.contact.shared_with" [ngModelOptions]="{standalone: true}">
                                </ng-select>
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  Contact details *
                                </label>
                              </div>
                              <div class="card-body-content">
                                <input [type]="add.contact.type?.value == 'phone' ? 'number' : (add.contact.type?.value == 'email' ? 'email' : 'text')" class="form-control"
                                  placeholder="Insert contact" [(ngModel)]="add.contact.value" [ngModelOptions]="{standalone: true}" #value id="value">
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="pull-right">
                              <button type="button" class="btn pull-right bg-secondary-color" (click)="addContactDetails(add.contact, 'contactForm')">
                                Add new contact detail
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div *ngIf="candidate.contact_details.length; then showContactDetails else showEmptyContactDetails"></div>
                  <ng-template #showContactDetails>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <div *ngIf="!edit.contact">
                            <div class="row card-block">
                              <div class="col-lg-6 col-sm-12" *ngFor="let contact of candidate.contact_details;let i=index">
                                <div class="form-group">
                                  <div class="card-body-title-basic">
                                    {{contact.type?.description | capitalizeFirst}}
                                  </div>
                                  <div class="card-body-content">
                                    {{contact.value}}
                                  </div>
                                  <div class="card-body-content sm-font regular-font-size">
                                    added on {{contact.created_at | date:'dd MMM yyyy' }} from {{contact.source.description}} - shared with {{contact.shared_with.description}}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="edit.contact">
                            <div class="card card-preview">
                              <div class="row" *ngFor="let contact of candidate.contact_details;let i=index">
                                <div class="col-12">
                                  <div class="form-group">
                                    <div class="row">
                                      <div class="col-1 ml-auto">
                                        <a>
                                          <i class="tt-icon-lg icon-ttdelete secondary-color pull-right" data-toggle="tooltip" data-placement="bottom" (click)="deleteContactDetails(contact, i)"
                                            title="Delete"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-6 col-sm-12">
                                  <div class="form-group">
                                    <div class="card-body-title-basic">
                                      <label>
                                        Source *
                                      </label>
                                    </div>
                                    <div class="card-body-content">
                                      <ng-select bindLabel="description" dropdownPosition="bottom" placeholder="Select source" [items]="resources.contact_source" (open)="getResource('contact_source')"
                                        [(ngModel)]="contact.source" [ngModelOptions]="{standalone: true}" disabled>
                                      </ng-select>
                                    </div>
                                  </div>
                                  <div class="form-group">
                                    <div class="card-body-title-basic">
                                      <label>
                                        Type *
                                      </label>
                                    </div>
                                    <div class="card-body-content">
                                      <ng-select bindLabel="description" dropdownPosition="bottom" placeholder="Select type" [items]="resources.contact_type" (open)="getResource('contact_type')"
                                        [(ngModel)]="contact.type" [ngModelOptions]="{standalone: true}" (change)="changed ? editContactDetails() : ''" (ngModelChange)="onChange($event)">
                                      </ng-select>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-6 col-sm-12">
                                  <div class="form-group">
                                    <div class="card-body-title-basic">
                                      <label>
                                        Share with *
                                      </label>
                                    </div>
                                    <div class="card-body-content">
                                      <ng-select bindLabel="description" dropdownPosition="bottom" placeholder="Select share with" [items]="resources.contact_shared_with"
                                        (open)="getResource('contact_shared_with')" [(ngModel)]="contact.shared_with" [ngModelOptions]="{standalone: true}"
                                        (change)="changed ? editContactDetails() : ''" (ngModelChange)="onChange($event)">
                                      </ng-select>
                                    </div>
                                  </div>
                                  <div class="form-group">
                                    <div class="card-body-title-basic">
                                      <label>
                                        Contact details *
                                      </label>
                                    </div>
                                    <div class="card-body-content">
                                      <input [type]="contact.type?.value == 'phone' ? 'number' : (contact.type?.value == 'email' ? 'email' : 'text')" class="form-control" placeholder="Insert contact"
                                        [(ngModel)]="contact.value" [ngModelOptions]="{standalone: true}" appOnFocusOut (focusout)="changed ? editContactDetails() : ''"
                                        (ngModelChange)="onChange($event)">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template #showEmptyContactDetails>
                    <div class="row card-block">
                      <div class="col-12">
                        No information available
                      </div>
                    </div>
                  </ng-template>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="miscellaneous">
                <ng-template ngbPanelHeader>
                  <div class="form" (click)="acc.toggle('miscellaneous')">
                    <div class="form-section">
                      Miscellaneous
                      <div class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2" (click)="editFields('miscellaneous')">
                        edit
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div *ngIf="candidate.miscellaneous.skills.length || candidate.miscellaneous.publications.length || candidate.miscellaneous.patents.length
                    || candidate.miscellaneous.certifications.length || candidate.miscellaneous.honors_awards.length || edit.miscellaneous
                    ;then ShowMiscellaneous else ShowEmptyMiscellaneous"></div>
                </ng-template>
                <ng-template #ShowMiscellaneous>
                  <div class="row card-block">
                    <div class="col-12">
                      <div class="form-group">
                        <div class="card-body-title-basic" *ngIf="(candidate.miscellaneous.skills.length && !edit.miscellaneous) || edit.miscellaneous">
                          <label>
                            Skills
                          </label>
                        </div>
                        <div class="card-body-content" *ngIf="candidate.miscellaneous.skills.length && !edit.miscellaneous">
                          <span *ngFor="let i of candidate.miscellaneous.skills;let last = last">
                            {{i}}{{last? '':', '}}
                          </span>
                        </div>
                        <div class="card-body-content" *ngIf="edit.miscellaneous">
                          <div class="row">
                            <div class="col-12 form-group">
                              <input class="form-control" type="text" [(ngModel)]="candidate.miscellaneous.skill" [ngModelOptions]="{standalone: true}" placeholder="+ skill" (keyup.enter)="candidate.miscellaneous.skill ? (candidate.miscellaneous.skill.trim() ?
                                (candidate.miscellaneous.skills.push(candidate.miscellaneous.skill) && editCandidate('miscellaneous.skills',candidate.miscellaneous.skills)) : '') : '';
                                candidate.miscellaneous.skill = '';" />
                            </div>
                            <div class="col-12 form-group">
                              <tag-input id="expertise-input" [(ngModel)]="candidate.miscellaneous.skills" [ngModelOptions]="{standalone: true}"
                                (ngModelChange)="editCandidate('miscellaneous.skills',candidate.miscellaneous.skills)">
                              </tag-input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="card-body-title-basic" *ngIf="(candidate.miscellaneous.publications.length && !edit.miscellaneous) || edit.miscellaneous">
                          <label>
                            Publications
                          </label>
                        </div>
                        <div class="card-body-content" *ngIf="candidate.miscellaneous.publications.length && !edit.miscellaneous">
                          <span *ngFor="let i of candidate.miscellaneous.publications;let last = last">
                            {{i}}{{last? '':', '}}
                          </span>
                        </div>
                        <div class="card-body-content" *ngIf="edit.miscellaneous">
                          <div class="row">
                            <div class="col-12 form-group">
                              <input class="form-control" type="text" [(ngModel)]="candidate.miscellaneous.publication" [ngModelOptions]="{standalone: true}" placeholder="+ publication" (keyup.enter)="candidate.miscellaneous.publication ?  (candidate.miscellaneous.publication.trim() ?
                                  (candidate.miscellaneous.publications.push(candidate.miscellaneous.publication) && editCandidate('miscellaneous.publications',candidate.miscellaneous.publications)) : '') : '';
                                  candidate.miscellaneous.publication = ''" />
                            </div>
                            <div class="col-12 form-group">
                              <tag-input id="expertise-input" [(ngModel)]="candidate.miscellaneous.publications" [ngModelOptions]="{standalone: true}"
                                (ngModelChange)="editCandidate('miscellaneous.publications',candidate.miscellaneous.publications)">
                              </tag-input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="card-body-title-basic" *ngIf="(candidate.miscellaneous.patents.length && !edit.miscellaneous) || edit.miscellaneous">
                          <label>
                            Patents
                          </label>
                        </div>
                        <div class="card-body-content" *ngIf="candidate.miscellaneous.patents.length && !edit.miscellaneous">
                          <span *ngFor="let i of candidate.miscellaneous.patents;let last = last">
                            {{i}}{{last? '':', '}}
                          </span>
                        </div>
                        <div class="card-body-content" *ngIf="edit.miscellaneous">
                          <div class="row">
                            <div class="col-12 form-group">
                              <input class="form-control" type="text" [(ngModel)]="candidate.miscellaneous.patent" [ngModelOptions]="{standalone: true}" placeholder="+ patent" (keyup.enter)="candidate.miscellaneous.patent ? (candidate.miscellaneous.patent.trim() ?
                                  (candidate.miscellaneous.patents.push(candidate.miscellaneous.patent) && editCandidate('miscellaneous.patents',candidate.miscellaneous.patents)) : '') : '';
                                  candidate.miscellaneous.patent = ''" />
                            </div>
                            <div class="col-12 form-group">
                              <tag-input id="expertise-input" [(ngModel)]="candidate.miscellaneous.patents" [ngModelOptions]="{standalone: true}"
                                (ngModelChange)="editCandidate('miscellaneous.patents',candidate.miscellaneous.patents)">
                              </tag-input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="card-body-title-basic" *ngIf="(candidate.miscellaneous.certifications.length && !edit.miscellaneous) || edit.miscellaneous">
                          <label>
                            Certifications
                          </label>
                        </div>
                        <div class="card-body-content" *ngIf="candidate.miscellaneous.certifications.length && !edit.miscellaneous">
                          <span *ngFor="let i of candidate.miscellaneous.certifications;let last = last">
                            {{i}}{{last? '':', '}}
                          </span>
                        </div>
                        <div class="card-body-content" *ngIf="edit.miscellaneous">
                          <div class="row">
                            <div class="col-12 form-group">
                              <input class="form-control" type="text" [(ngModel)]="candidate.miscellaneous.certification" [ngModelOptions]="{standalone: true}" placeholder="+ certification"
                                (keyup.enter)="candidate.miscellaneous.certification ? (candidate.miscellaneous.certification.trim() ?
                                  (candidate.miscellaneous.certifications.push(candidate.miscellaneous.certification) && editCandidate('miscellaneous.certifications',candidate.miscellaneous.certifications)) : '') : '';
                                  candidate.miscellaneous.certification = ''" />
                            </div>
                            <div class="col-12 form-group">
                              <tag-input id="expertise-input" [(ngModel)]="candidate.miscellaneous.certifications" [ngModelOptions]="{standalone: true}"
                                (ngModelChange)="editCandidate('miscellaneous.certifications',candidate.miscellaneous.certifications)">
                              </tag-input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="card-body-title-basic" *ngIf="(candidate.miscellaneous.honors_awards.length && !edit.miscellaneous) || edit.miscellaneous">
                          <label>
                            Honors Awards
                          </label>
                        </div>
                        <div class="card-body-content" *ngIf="candidate.miscellaneous.honors_awards.length && !edit.miscellaneous">
                          <span *ngFor="let i of candidate.miscellaneous.honors_awards;let last = last">
                            {{i}}{{last? '':', '}}
                          </span>
                        </div>
                        <div class="card-body-content" *ngIf="edit.miscellaneous">
                          <div class="row">
                            <div class="col-12 form-group">
                              <input class="form-control" type="text" [(ngModel)]="candidate.miscellaneous.honors_award" [ngModelOptions]="{standalone: true}" placeholder="+ honors award"
                                (keyup.enter)="candidate.miscellaneous.honors_award ? (candidate.miscellaneous.honors_award.trim() ?
                                  (candidate.miscellaneous.honors_awards.push(candidate.miscellaneous.honors_award) && editCandidate('miscellaneous.honors_awards',candidate.miscellaneous.honors_awards)) : '') : '';
                                  candidate.miscellaneous.honors_award = ''" />
                            </div>
                            <div class="col-12 form-group">
                              <tag-input id="expertise-input" [(ngModel)]="candidate.miscellaneous.honors_awards" [ngModelOptions]="{standalone: true}"
                                (ngModelChange)="editCandidate('miscellaneous.honors_awards',candidate.miscellaneous.honors_awards)">
                              </tag-input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template #ShowEmptyMiscellaneous>
                  <div class="row card-block">
                    <div class="col-12">
                      No information available
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="consent">
                <ng-template ngbPanelHeader>
                  <div class="form" (click)="acc.toggle('consent')">
                    <div class="form-section">
                      Consent
                      <div class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2 ml-1" (click)="editFields('consent')">
                        edit
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div *ngIf="edit.consent">
                    <div class="row card-block">
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Obtained at
                            </label>
                          </div>
                          <div class="card-body-content">
                            <input type="date" [(ngModel)]="candidate.consent.obtained_at" [ngModelOptions]="{standalone: true}" class="form-control" appOnFocusOut
                              (focusout)="changed ? editCandidate('consent.obtained_at',candidate.consent.obtained_at, candidate.uuid): ''" (ngModelChange)="edit.consent ? onChange($event) : ''">
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Expires at
                            </label>
                          </div>
                          <div class="card-body-content">
                            <input type="date" [(ngModel)]="candidate.consent.expires_at" [ngModelOptions]="{standalone: true}" class="form-control" appOnFocusOut
                              (focusout)="changed ? editCandidate('consent.expires_at',candidate.consent.expires_at, candidate.uuid): ''" (ngModelChange)="edit.consent ? onChange($event) : ''">
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Obtained through type
                            </label>
                          </div>
                          <div class="card-body-content">
                            <ng-select bindLabel="description" dropdownPosition="bottom" placeholder="Select obtained through type" (open)="getObtainedType()" [items]="resources.obtained_type"
                              [(ngModel)]="candidate.consent.obtained_through_type"
                              (change)="changed ? editCandidate('consent.obtained_through_type',candidate.consent.obtained_through_type , candidate.uuid):''"
                              (ngModelChange)="edit.consent ? onChange($event) : ''"></ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Type
                            </label>
                          </div>
                          <div class="card-body-content">
                            <ng-select bindLabel="description" dropdownPosition="bottom" placeholder="Select type" (open)="getConsentType()" [items]="resources.consent_type"
                              [(ngModel)]="candidate.consent.type" (change)="changed ? editCandidate('consent.type',candidate.consent.type , candidate.uuid):''"
                              (ngModelChange)="edit.consent ? onChange($event) : ''"></ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Note
                            </label>
                          </div>
                          <div class="card-body-content">
                            <quill-editor placeholder="Insert note" class="form-control" id="quill-editor-consent" [(ngModel)]="candidate.consent.note" [ngModelOptions]="{standalone: true}"
                              appOnFocusOut (focusout)="changed ? editCandidate('consent.note',candidate.consent.note, candidate.uuid): ''"
                              (ngModelChange)="edit.consent ? onChange($event) : ''"></quill-editor>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="!edit.consent">
                    <div class="row card-block">
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Obtained at
                            </label>
                          </div>
                          <div class="card-body-content">
                            {{candidate.consent && candidate.consent.obtained_at ? candidate.consent.obtained_at : 'No
                            information available'}}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Expires at
                            </label>
                          </div>
                          <div class="card-body-content">
                            {{candidate.consent && candidate.consent.expires_at ? candidate.consent.expires_at : 'No
                            information available'}}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Obtained through type
                            </label>
                          </div>
                          <div class="card-body-content">
                            {{candidate.consent && candidate.consent.obtained_through_type ?
                            candidate.consent.obtained_through_type.description: 'No information available' }}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Type
                            </label>
                          </div>
                          <div class="card-body-content">
                            {{candidate.consent && candidate.consent.type ? candidate.consent.type.description : 'No
                            information available'}}
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Note
                            </label>
                          </div>
                          <div class="card-body-content" [innerHTML]="candidate.consent && candidate.consent.note ? candidate.consent.note : 'No information available' ">
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </ng-template>
      </ng-container>
      <ng-container [ngbNavItem]="'ngb-tab-1'">
        <a ngbNavLink>Experience</a>
        <ng-template ngbNavContent>
          <div class="card card-top" [ngClass]="{'scroll scroll-tabs':innerWidth > 1200}">
            <ngb-accordion id="filter-accordion" [(activeIds)]="activeIds" class="ng-accordion-append" #acc="ngbAccordion" (panelChange)="beforeChange($event)">
              <ngb-panel id="experience">
                <ng-template ngbPanelHeader>
                  <div class="form" (click)="acc.toggle('experience')">
                    <div class="form-section">
                      Experience
                      <div class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2 ml-2" (click)="editFields('experience');">
                        edit
                      </div>
                      <div class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2" (click)="addForm('experienceForm')">
                        add
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div *ngIf="add.experienceForm">
                    <div class="form-group">
                      <div class="card card-preview">
                        <div class="row">
                          <div class="col-lg-6 col-sm-12">
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  Title *
                                </label>
                              </div>
                              <div class="card-body-content">
                                <input type="text" class="form-control" placeholder="Insert job title" [(ngModel)]="add.experiences.title" [ngModelOptions]="{standalone: true}">
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-sm-12">
                            <div class="form-group">
                                <div class="card-body-title-basic">
                                    <label>
                                        Company *
                                    </label>
                                </div>
                                <div class="card-body-content">
                                    <ng-select [items]="companies | async" #companiesSelect [hideSelected]="true"
                                        bindLabel="name" bindValue="name" [loading]="companyLoading"
                                        [typeahead]="companyinput$" [(ngModel)]="add.experiences.company.name"
                                        [clearable]="true" placeholder="Select company"
                                        (change)="setCompanyData($event)" (clear)="initCompanyData()">
                                        <ng-template ng-option-tmp let-item="item" let-index="i">
                                            <div class="media mb-1" *ngIf="item.dealroom_data; else noDealroom">
                                                <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                                                    [src]="item.dealroom_data ? (item.dealroom_data.images['32x32'] ? item.dealroom_data.images['32x32'] : globals.companyAvatar) : ''"
                                                    (error)="item.dealroom_data.images = globals.companyAvatar" />
                                                <div class="media-body">
                                                    <div class="media-body-title mt-1 mb-0">{{item.name}}<span
                                                            *ngIf="item.dealroom_data.website_url">,
                                                            {{item.website_url}}</span></div>
                                                    <p
                                                        *ngIf="item.dealroom_data.hq_locations && item.dealroom_data.hq_locations.length">
                                                        <i class="tt-icon-sm icon-ttrelocation"></i>
                                                        <span
                                                            *ngFor="let location of item.dealroom_data.hq_locations;let last = last">
                                                            {{location.address}} {{!last ? ' (HQ), ' : ' (HQ)'}}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <ng-template #noDealroom>
                                                <div class="media mb-1">
                                                    <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                                                        [src]="item.photo || (item.images ? (item.images['32x32'] ? item.images['32x32'] : globals.companyAvatar) : '')"
                                                        (error)="item.images = globals.companyAvatar" />
                                                    <div class="media-body">
                                                        <div class="media-body-title mt-1 mb-0">{{item.name}}<span
                                                                *ngIf="item.website_url">, {{item.website_url}}</span>
                                                        </div>
                                                        <p *ngIf="item.hq_locations && item.hq_locations.length">
                                                            <i class="tt-icon-sm icon-ttrelocation"></i>
                                                            <span
                                                                *ngFor="let location of item.hq_locations;let last = last">
                                                                {{location.address}} {{!last ? ' (HQ), ' : ' (HQ)'}}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>

                                        </ng-template>
                                        <ng-template *ngIf="companiesSelect.searchTerm" ng-footer-tmp
                                            let-search="searchTerm">
                                            <div class="mb-1 pull-right">
                                                <button class="btn bg-secondary-color btn-position pull-right mb-0"
                                                    (click)="addCompany(search); companiesSelect.close()">Add company {{search}}</button>
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-sm-12">
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  Start date *
                                </label>
                              </div>
                              <div class="card-body-content">
                                <input type="date" [(ngModel)]="add.experiences.from" [ngModelOptions]="{standalone: true}" class="form-control">
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-sm-12">
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  End date
                                </label>
                              </div>
                              <div class="card-body-content">
                                <input type="date" [(ngModel)]="add.experiences.to" [ngModelOptions]="{standalone: true}" class="form-control">
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  Description
                                </label>
                              </div>
                              <div class="card-body-content">
                                <div class="row">
                                  <div class="col-12 form-group">
                                    <textarea rows="5" class="form-control" type="text" [(ngModel)]="add.experiences.description" [ngModelOptions]="{standalone: true}"
                                      placeholder="Add Description"></textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="pull-right">
                              <button type="button" class="btn pull-right bg-secondary-color" (click)="addNew(add.experiences,'experiences')">
                                Add new experience
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="candidate.experiences.length; then showExperience else showEmptyExperience"></div>
                  <ng-template #showExperience>
                    <div class="row">
                      <div class="col-12">
                        <div *ngIf="candidate.experiences; then showCurrentExp else showCurrentExpMsg"></div>
                        <ng-template #showCurrentExp>
                          <div class="form-group" *ngFor="let experience of candidate.experiences;let i=index">
                            <div *ngIf="!edit.experience">
                              <div class="row card-block">
                                <div class="col-12">
                                  <div class="card-body-title-basic d-flex align-items-center" *ngIf="experience.title">
                                    {{experience.title}}
                                    <span *ngIf="experience.company" class="d-flex align-items-center ml-1">
                                      @ <app-dealroom-widget [companyName]="experience.company.name" class="ml-1">
                                      </app-dealroom-widget>
                                      <app-hubspot [data]="experience.company.hubspot" [clickable]="false"></app-hubspot>
                                    </span>
                                  </div>
                                  <div class="card-body-content" *ngIf="(experience.from || experience.to)">
                                    {{experience.from ? experience.from + ' – ' : ''}}
                                    {{experience.to ? experience.to : 'present'}}
                                  </div>
                                  <div class="form-group" *ngIf="experience.description">
                                    <div class="card-body-title-basic">
                                      <label>
                                        Description
                                      </label>
                                    </div>
                                    <div class="card-body-content">
                                      {{experience.description}}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="edit.experience">
                              <div class="card card-preview">
                                <div class="row">
                                  <div class="col-12">
                                    <div class="form-group">
                                      <div class="row">
                                        <div class="col-1 ml-auto">
                                          <a>
                                            <i class="tt-icon-lg icon-ttdelete secondary-color pull-right" data-toggle="tooltip" data-placement="bottom" (click)="deleteCard(i, 'experiences')"
                                              title="Delete"></i>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-lg-6 col-sm-12">
                                    <div class="form-group">
                                      <div class="card-body-title-basic">
                                        <label>
                                          Title *
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <input type="text" class="form-control" placeholder="Insert job title" [(ngModel)]="experience.title" [ngModelOptions]="{standalone: true}" appOnFocusOut
                                          (focusout)="changed ? editCandidate('experiences', candidate.experiences, i) : ''" (ngModelChange)="onChange($event)">
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-lg-6 col-sm-12">
                                    <div class="form-group">
                                      <div class="card-body-title-basic">
                                          <label>
                                              Company *
                                          </label>
                                      </div>
                                      <div class="card-body-content">
                                          <ng-select [items]="companies | async" #companiesSelect [hideSelected]="true"
                                              bindLabel="name" [loading]="companyLoading"
                                              [typeahead]="companyinput$" [(ngModel)]="experience.company"
                                              [clearable]="false" placeholder="Select company"
                                              (change)="editCandidate('experiences', candidate.experiences, i)">
                                              <ng-template ng-option-tmp let-item="item" let-index="i">
                                                  <div class="media mb-1" *ngIf="item.dealroom_data; else noDealroom">
                                                      <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                                                          [src]="item.dealroom_data ? (item.dealroom_data.images['32x32'] ? item.dealroom_data.images['32x32'] : globals.companyAvatar) : ''"
                                                          (error)="item.dealroom_data.images = globals.companyAvatar" />
                                                      <div class="media-body">
                                                          <div class="media-body-title mt-1 mb-0">{{item.name}}<span
                                                                  *ngIf="item.dealroom_data.website_url">,
                                                                  {{item.website_url}}</span></div>
                                                          <p
                                                              *ngIf="item.dealroom_data.hq_locations && item.dealroom_data.hq_locations.length">
                                                              <i class="tt-icon-sm icon-ttrelocation"></i>
                                                              <span
                                                                  *ngFor="let location of item.dealroom_data.hq_locations;let last = last">
                                                                  {{location.address}} {{!last ? ' (HQ), ' : ' (HQ)'}}
                                                              </span>
                                                          </p>
                                                      </div>
                                                  </div>
                                                  <ng-template #noDealroom>
                                                      <div class="media mb-1">
                                                          <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                                                              [src]="item.photo || (item.images ? (item.images['32x32'] ? item.images['32x32'] : globals.companyAvatar) : '')"
                                                              (error)="item.images = globals.companyAvatar" />
                                                          <div class="media-body">
                                                              <div class="media-body-title mt-1 mb-0">{{item.name}}<span
                                                                      *ngIf="item.website_url">, {{item.website_url}}</span>
                                                              </div>
                                                              <p *ngIf="item.hq_locations && item.hq_locations.length">
                                                                  <i class="tt-icon-sm icon-ttrelocation"></i>
                                                                  <span
                                                                      *ngFor="let location of item.hq_locations;let last = last">
                                                                      {{location.address}} {{!last ? ' (HQ), ' : ' (HQ)'}}
                                                                  </span>
                                                              </p>
                                                          </div>
                                                      </div>
                                                  </ng-template>

                                              </ng-template>
                                              <ng-template *ngIf="companiesSelect.searchTerm" ng-footer-tmp
                                                  let-search="searchTerm">
                                                  <div class="mb-1 pull-right">
                                                      <button class="btn bg-secondary-color btn-position pull-right mb-0"
                                                          (click)="addCompany(search, i); companiesSelect.close()">Add company {{search}}</button>
                                                  </div>
                                              </ng-template>
                                          </ng-select>
                                      </div>
                                  </div>
                                  </div>
                                  <div class="col-lg-6 col-sm-12">
                                    <div class="form-group">
                                      <div class="card-body-title-basic">
                                        <label>
                                          Start date *
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <input type="date" class="form-control" [(ngModel)]="experience.from" [ngModelOptions]="{standalone: true}" appOnFocusOut
                                          (focusout)="changed ? editCandidate('experiences', candidate.experiences, i) : ''" (ngModelChange)="onChange($event)">
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-lg-6 col-sm-12">
                                    <div class="form-group">
                                      <div class="card-body-title-basic">
                                        <label>
                                          End date
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <input type="date" class="form-control" [(ngModel)]="experience.to" [ngModelOptions]="{standalone: true}" appOnFocusOut
                                          (focusout)="changed ? editCandidate('experiences', candidate.experiences, i) : ''" (ngModelChange)="onChange($event)">
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-12">
                                    <div class="form-group">
                                      <div class="card-body-title-basic">
                                        <label>
                                          Description
                                        </label>
                                      </div>
                                      <div class="card-body-content">
                                        <div class="row">
                                          <div class="col-12 form-group">
                                            <textarea rows="5" class="form-control" type="text" [(ngModel)]="experience.description" [ngModelOptions]="{standalone: true}" placeholder="Add Description"
                                              appOnFocusOut (focusout)="changed ? editCandidate('experiences', candidate.experiences, i): ''" (ngModelChange)="onChange($event)">
                                          </textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template #showCurrentExpMsg>
                          <div class="form-group card-block" *ngIf="!edit.experience">
                            <div class="card-body-content">
                              Currently unemployed
                            </div>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template #showEmptyExperience>
                    <div class="row card-block">
                      <div class="col-12">
                        No information available
                      </div>
                    </div>
                  </ng-template>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </ng-template>
      </ng-container>
      <ng-container [ngbNavItem]="'ngb-tab-2'">
        <a ngbNavLink>Education</a>
        <ng-template ngbNavContent>
          <div class="card card-top" [ngClass]="{'scroll scroll-tabs':innerWidth > 1200}">
            <ngb-accordion id="filter-accordion" [(activeIds)]="activeIds" class="ng-accordion-append" #acc="ngbAccordion" (panelChange)="beforeChange($event)">
              <ngb-panel id="education">
                <ng-template ngbPanelHeader>
                  <div class="form" (click)="acc.toggle('education')">
                    <div class="form-section">
                      Education
                      <div class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2 ml-2" (click)="editFields('education');">
                        edit
                      </div>
                      <div class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2" (click)="addForm('educationForm')">
                        add
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div *ngIf="add.educationForm">
                    <div class="form-group">
                      <div class="card card-preview">
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  University *
                                </label>
                              </div>
                              <div class="card-body-content">
                                <input type="text" class="form-control" placeholder="Insert university" [(ngModel)]="add.education.school.name" [ngModelOptions]="{standalone: true}">
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  Degree
                                </label>
                              </div>
                              <div class="card-body-content">
                                <input type="text" class="form-control" placeholder="Insert degree" [(ngModel)]="add.education.name" [ngModelOptions]="{standalone: true}">
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  Start date
                                </label>
                              </div>
                              <div class="card-body-content">
                                <input type="date" [(ngModel)]="add.education.from" [ngModelOptions]="{standalone: true}" class="form-control">
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  End date
                                </label>
                              </div>
                              <div class="card-body-content">
                                <input type="date" [(ngModel)]="add.education.to" [ngModelOptions]="{standalone: true}" class="form-control">
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="pull-right">
                              <button type="button" class="btn pull-right bg-secondary-color" (click)="addNew(add.education,'education_milestones','education')">
                                Add new education
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="candidate.education_milestones.length; then showEducations else showEmptyEducations">
                  </div>
                  <ng-template #showEducations>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group" *ngFor="let education of candidate.education_milestones; let i = index">
                          <div *ngIf="!edit.education">
                            <div class="row card-block">
                              <div class="col-12">
                                <div class="card-body-title-basic">
                                  {{education.name}} {{education.school ? '@ '+ education.school.name : ''}}
                                </div>
                                <div class="card-body-content">
                                  <div *ngIf="education.from || education.to">
                                    {{education.from + ' – ' }}
                                    {{education.to ? education.to : 'present'}}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="edit.education">
                            <div class="card card-preview mb-5">
                              <div class="row">
                                <div class="col-12">
                                  <div class="form-group">
                                    <div class="row">
                                      <div class="col-1 ml-auto">
                                        <a>
                                          <i class="tt-icon-lg icon-ttdelete secondary-color pull-right" data-toggle="tooltip" data-placement="bottom"
                                            (click)="deleteCard(i, 'education_milestones', 'education')" title="Delete"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="form-group">
                                    <div class="card-body-title-basic">
                                      University *
                                    </div>
                                    <div class="card-body-content">
                                      <input type="text" class="form-control" placeholder="Insert university" [(ngModel)]="education.school.name" [ngModelOptions]="{standalone: true}" appOnFocusOut
                                        (focusout)="changed ? editCandidate('education',candidate.education_milestones) : ''" (ngModelChange)="onChange($event)">
                                    </div>
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="form-group">
                                    <div class="card-body-title-basic">
                                      Degree
                                    </div>
                                    <div class="card-body-content">
                                      <input type="text" class="form-control" placeholder="Insert degree" [(ngModel)]="education.name" [ngModelOptions]="{standalone: true}" appOnFocusOut
                                        (focusout)="changed ? editCandidate('education',candidate.education_milestones) : ''" (ngModelChange)="onChange($event)">
                                    </div>
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="form-group">
                                    <div class="card-body-title-basic">
                                      <label>
                                        Start date
                                      </label>
                                    </div>
                                    <div class="card-body-content">
                                      <input type="date" class="form-control" [(ngModel)]="education.from" [ngModelOptions]="{standalone: true}" appOnFocusOut
                                        (focusout)="changed ? editCandidate('education',candidate.education_milestones) : ''" (ngModelChange)="onChange($event)">
                                    </div>
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="form-group">
                                    <div class="card-body-title-basic">
                                      <label>
                                        End date
                                      </label>
                                    </div>
                                    <div class="card-body-content">
                                      <input type="date" class="form-control" [(ngModel)]="education.to" [ngModelOptions]="{standalone: true}" appOnFocusOut
                                        (focusout)="changed ? editCandidate('education',candidate.education_milestones) : ''" (ngModelChange)="onChange($event)">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template #showEmptyEducations>
                    <div class="row card-block">
                      <div class="col-12">
                        No information available
                      </div>
                    </div>
                  </ng-template>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </ng-template>
      </ng-container>
      <ng-container [ngbNavItem]="'ngb-tab-3'">
        <a ngbNavLink>Languages</a>
        <ng-template ngbNavContent>
          <div class="card card-top" [ngClass]="{'scroll scroll-tabs':innerWidth > 1200}">
            <ngb-accordion id="filter-accordion" [(activeIds)]="activeIds" class="ng-accordion-append" #acc="ngbAccordion" (panelChange)="beforeChange($event)">
              <ngb-panel id="language">
                <ng-template ngbPanelHeader>
                  <div class="form" (click)="acc.toggle('language')">
                    <div class="form-section">
                      Languages
                      <div class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2 ml-2" (click)="editFields('language');">
                        edit
                      </div>
                      <div class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2" (click)="addForm('languageForm')">
                        add
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div *ngIf="add.languageForm">
                    <div class="form-group">
                      <div class="card card-preview">
                        <div class="row">
                          <div class="col-md-6 col-12">
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  Language *
                                </label>
                              </div>
                              <div class="card-body-content">
                                <ng-select class="ng-append" appendTo=".accordion" [items]="resources.languages" dropdownPosition="bottom" bindLabel="description" [hideSelected]="true"
                                  [(ngModel)]="add.languages.language" placeholder="Select language">
                                </ng-select>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 col-12">
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  Level *
                                </label>
                              </div>
                              <div class="card-body-content">
                                <ng-select class="ng-append" appendTo=".accordion" bindLabel="description" dropdownPosition="bottom" placeholder="Select level" [items]="resources.levels"
                                  [(ngModel)]="add.languages.level" [ngModelOptions]="{standalone: true}">
                                </ng-select>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="pull-right">
                              <button type="button" class="btn pull-right bg-secondary-color" (click)="addNew(add.languages,'languages')">
                                Add new language
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="candidate.languages.length; then showLanuages else showEmptyLaguages"></div>
                  <ng-template #showLanuages>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group" *ngFor="let language of candidate.languages;let i = index">
                          <div *ngIf="!edit.language">
                            <div class="row card-block">
                              <div class="col-12">
                                <strong> {{language.language.description}} </strong>
                                {{language.level && 'NO_VALUE' != language.level.value ? '- ' +
                                language.level.description
                                : ''}}
                              </div>
                            </div>
                          </div>
                          <div *ngIf="edit.language">
                            <div class="card card-preview">
                              <div class="row">
                                <div class="col-12">
                                  <a class="pull-right">
                                    <i class="tt-icon-lg icon-ttdelete secondary-color pull-right" data-toggle="tooltip" (click)="deleteCard(i, 'languages')" data-placement="bottom"
                                      title="Delete"></i>
                                  </a>
                                </div>
                                <div class="col-md-6 col-12">
                                  <div class="form-group">
                                    <div class="card-body-title-basic">
                                      <label>
                                        Language *
                                      </label>
                                    </div>
                                    <div class="card-body-content">
                                      <ng-select class="ng-append" appendTo=".accordion" [items]="resources.languages " bindLabel="description" [hideSelected]="true" [(ngModel)]="language.language"
                                        placeholder="Select language" (change)="editCandidate('languages',candidate.languages)">
                                      </ng-select>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6 col-12">
                                  <div class="form-group">
                                    <div class="card-body-title-basic">
                                      <label>
                                        Level *
                                      </label>
                                    </div>
                                    <div class="card-body-content">
                                      <ng-select class="ng-append" appendTo=".accordion" bindLabel="description" dropdownPosition="bottom" placeholder="Select level" [items]="resources.levels"
                                        [(ngModel)]="language.level" [ngModelOptions]="{standalone: true}" (change)="editCandidate('languages',candidate.languages)">
                                      </ng-select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template #showEmptyLaguages>
                    <div class="row card-block">
                      <div class="col-12">
                        No information available
                      </div>
                    </div>
                  </ng-template>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </ng-template>
      </ng-container>
      <ng-container [ngbNavItem]="'ngb-tab-4'">
        <a ngbNavLink>Search</a>
        <ng-template ngbNavContent>
          <div class="card card-top" [ngClass]="{'scroll scroll-tabs':innerWidth > 1200}">
            <ngb-accordion id="filter-accordion" [(activeIds)]="activeIds" class="ng-accordion-append" #acc="ngbAccordion" (panelChange)="preventDefault ? beforeChange($event) : ''">
              <ngb-panel id="navisSearches">
                <ng-template ngbPanelHeader>
                  <div class="form" (click)="acc.toggle('navisSearches')">
                    <div class="form-section">
                      Navis searches
                    </div>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div *ngIf="candidate.jobs.length; then showSearch else showEmptySearch"></div>
                  <ng-template #showSearch>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group" *ngFor="let job of candidate.jobs;let i = index">
                          <div class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right" (click)="editFields('jobs', i);">
                            edit
                          </div>
                          <div class="card card-preview">
                            <div class="row">
                              <div class="col-11">
                                <div class="card-body-title-basic">
                                  <div class="form-group display-inline-flex">
                                    <span>{{job.title + (job.company?.name ? ' @ ' + job.company.name: '')}}</span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-12 col-lg-6">
                                <div class="form-group">
                                  <div class="card-body-title-basic">
                                    <label>
                                      Job Type
                                    </label>
                                  </div>
                                  <div class="card-body-content">
                                    {{job.job_type?.description || "No information available"}}
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                  <div class="card-body-title-basic">
                                    <label>
                                      Ranking / Tier
                                    </label>
                                  </div>
                                  <div class="card-body-content" *ngIf="!edit.jobs[i]">
                                    {{job.ranking ? job.ranking.description : "No information available"}}
                                  </div>
                                  <div class="card-body-content" *ngIf="edit.jobs[i]">
                                    <ng-select class="ng-append" appendTo=".accordion" [items]="resources.tiers" dropdownPosition="bottom"
                                      (change)="editApplication('candidate_ranking', job.ranking, job.application_uuid)" bindLabel="description" [hideSelected]="true" [(ngModel)]="job.ranking"
                                      placeholder="Select Tier">
                                    </ng-select>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                  <div class="card-body-title-basic">
                                    <label>
                                      Status
                                    </label>
                                  </div>
                                  <div class="card-body-content">
                                    {{job.status || "No information available"}}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-12 col-lg-6">
                                <div class="form-group">
                                  <div class="card-body-title-basic">
                                    <label>
                                      Archetype
                                    </label>
                                  </div>
                                  <div class="card-body-content" *ngIf="!edit.jobs[i]">
                                    {{job.archetype ? job.archetype.name : "No information available"}}
                                  </div>
                                  <div class="card-body-content" *ngIf="edit.jobs[i]">
                                    <ng-select [items]="resources.archetypes" placeholder="Select archetype" (open)="getArchetype(job)" dropdownPosition="bottom" bindLabel="name"
                                      [(ngModel)]="job.archetype" (change)="editArchetype('persona', job)">
                                    </ng-select>
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-12 col-lg-6">
                                <div class="form-group">
                                  <div class="card-body-title-basic">
                                    <label>
                                      Hunting Grounds
                                    </label>
                                  </div>
                                  <div class="card-body-content">
                                    {{job.hunting_ground.length ? extractHuntingGroundNames(job.hunting_ground) : "No information available"}}
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-12 col-lg-6">
                                <div class="form-group">
                                  <div class="card-body-title-basic">
                                    <label>
                                      Consultant note
                                    </label>
                                  </div>
                                  <div class="card-body-content">
                                    {{job.consultant_note.note || "No information available"}}
                                  </div>
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="form-group">
                                  <div class="card-body-title-basic">
                                    <label>
                                      Research note
                                    </label>
                                  </div>
                                  <div class="card-body-content" *ngIf="!edit.jobs[i]">
                                    <div *ngIf="job.research_note;then showResearchNote else showEmptyResearchNote">
                                    </div>
                                    <ng-template #showResearchNote>
                                      <div class="form-group">
                                        <div class="ql-editor p-0" *ngIf="job.research_note" [innerHTML]="job.research_note">
                                          {{job.research_note}}
                                        </div>
                                      </div>
                                    </ng-template>
                                    <ng-template #showEmptyResearchNote>
                                      <div class="form-group">
                                        <p>No information available</p>
                                      </div>
                                    </ng-template>
                                  </div>
                                  <div class="card-body-content" *ngIf="edit.jobs[i]">
                                    <quill-editor id="description" name="description" [(ngModel)]="job.research_note" placeholder="Enter research note" appOnFocusOut
                                      (focusout)="changed ? editArchetype('comment', job) : ''" (ngModelChange)="onChange($event)">
                                    </quill-editor>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="navis1Searches">
                <ng-template ngbPanelHeader>
                  <div class="form" (click)="acc.toggle('navis1Searches')">
                    <div class="form-section">
                      Navis1 searches
                    </div>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="card card-top pl-0" [ngClass]="{'scroll scroll-tabs':innerWidth > 1200}">
                    <ngb-accordion id="filter-accordion" [(activeIds)]="activeIds" #accordionJobs="ngbAccordion" class="ng-accordion-append" (panelChange)="preventDefault ? beforeChange($event) : ''"
                      *ngIf="candidateNavis1JobHistory.visible || candidateNavis1JobHistory.hidden; else emptyNavis1History">
                      <ngb-panel id="openJobs">
                        <ng-template ngbPanelHeader>
                          <div class="form" (click)="accordionJobs.toggle('openJobs');acc.openJobs = !acc.openJobs">
                            <div class="form-section">
                              Open jobs
                              <i class="pull-right mt-1 pt-2" [ngClass]="{'ft-chevron-right': !acc.openJobs,'ft-chevron-down': acc.openJobs}"></i>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template ngbPanelContent>
                          <div *ngIf="candidateNavis1JobHistory.visible?.length;then showOpenJobs else showEmptyOpenJobs">
                          </div>
                          <ng-template #showOpenJobs>
                            <div class="row card-block">
                              <div class="col-12">
                                <div *ngFor="let openJob of candidateNavis1JobHistory.visible">
                                  <div class="card-body-title-basic">
                                    <div class="form-group display-inline-flex">
                                      <u>{{openJob.job}}</u>
                                      <span class="badge-tags-inverse ml-3 bg-success-color cursor-pointer" *ngIf="openJob.is_vetted">
                                        Vetted
                                      </span>
                                      <span *ngIf="openJob.candidate_rating" [ngStyle]="{'background-color':openJob.candidate_rating.background_color, 'color':openJob.candidate_rating.text_color}"
                                        class="badge-tags-inverse nowrap align-self-center ml-2 display-inline-flex" title="{{openJob.candidate_rating.hover_data}}">
                                        {{openJob.candidate_rating.description}}
                                      </span>
                                    </div>
                                  </div>
                                  <div class="card-body-content">
                                    <div class="row" *ngIf="openJob.stage.value || (openJob.substatus && openJob.substatus.description)">
                                      <div class="col-6" *ngIf="openJob.stage.value">
                                        <div class="form-group">
                                          Stage
                                          <ng-template #tooltipOpen>
                                            <div class="card card-preview">
                                              <div class="card-body">
                                                <div class="card-body-title-basic">
                                                  Stage: {{openJob.stage.description}}
                                                </div>
                                                <div class="card-body-content">
                                                  <div *ngIf="openJob.shortlisted_date">
                                                    Shortlisted date:
                                                    {{openJob.shortlisted_date |
                                                    dateDiffTimestampFullNav:openJob.shortlisted_date}}
                                                  </div>
                                                  <div *ngIf="openJob.sent_date">
                                                    Sent date: {{openJob.sent_date |
                                                    dateDiffTimestampFullNav:openJob.sent_date}}
                                                  </div>
                                                  <div *ngIf="openJob.first_interview_date">
                                                    First interview date:
                                                    {{openJob.first_interview_date |
                                                    dateDiffTimestampFullNav:openJob.first_interview_date}}
                                                  </div>
                                                  <div *ngIf="openJob.second_interview_date">
                                                    Second interview date:
                                                    {{openJob.second_interview_date |
                                                    dateDiffTimestampFullNav:openJob.second_interview_date}}
                                                  </div>
                                                  <div *ngIf="openJob.offer_date">
                                                    Offer date:
                                                    {{openJob.offer_date | dateDiffTimestampFullNav:openJob.offer_date}}
                                                  </div>
                                                  <div *ngIf="openJob.placed_date">
                                                    Placed date:
                                                    {{openJob.placed_date |
                                                    dateDiffTimestampFullNav:openJob.placed_date}}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </ng-template>
                                          <ng-template #t let-fill="fill" let-last="last" let-index="index">
                                            <span class="bullet" [class.full]="fill === 100">
                                              <span class="bullet"
                                                [ngClass]="fill === 100 && (openJob.substatus && openJob.substatus.success) ? 'success' : index == openJob.stage.current_stage-1 ? 'error' : 'success'"
                                                [style.width.%]="fill" [ngbTooltip]="tooltipOpen" placement="bottom-left" [innerHTML]="'&#x025CF;'"></span>
                                              <span [innerHTML]="'&#x025CF;'"></span>
                                            </span>
                                          </ng-template>
                                          <ngb-rating [(rate)]="openJob.stage.current_stage" [starTemplate]="t" [readonly]="true" [max]="openJob.stage.max_stage">
                                          </ngb-rating>
                                        </div>
                                      </div>
                                      <div class="col-6" *ngIf="openJob.substatus && openJob.substatus.description">
                                        <div class="form-group">
                                          <span class="badge m-0" [ngStyle]="{'color': openJob.substatus.color_code}">
                                            <i class="fa fa-circle"></i>
                                          </span>
                                          <label>{{openJob.substatus.description}}</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                          <ng-template #showEmptyOpenJobs>
                            <div class="row card-block">
                              <div class="col-12">
                                This candidate is not shortlisted on any ongoing jobs
                              </div>
                            </div>
                          </ng-template>
                        </ng-template>
                      </ngb-panel>
                      <ngb-panel id="closedJobs">
                        <ng-template ngbPanelHeader>
                          <div class="form" (click)="accordionJobs.toggle('closedJobs');acc.closedJobs = !acc.closedJobs">
                            <div class="form-section">
                              Closed jobs
                              <i class="pull-right mt-1 pt-2" [ngClass]="{'ft-chevron-right': !acc.closedJobs,'ft-chevron-down': acc.closedJobs}"></i>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template ngbPanelContent>
                          <div *ngIf="candidateNavis1JobHistory.hidden?.length;then showClosedJobs else showEmptyClosedJobs">
                          </div>
                          <ng-template #showClosedJobs>
                            <div class="row card-block">
                              <div class="col-12">
                                <div *ngFor="let closedJob of candidateNavis1JobHistory.hidden">
                                  <div class="card-body-title-basic">
                                    <div class="form-group display-inline-flex">
                                      <u>{{closedJob.job}}</u>
                                      <span *ngIf="closedJob.candidate_rating"
                                        [ngStyle]="{'background-color':closedJob.candidate_rating.background_color, 'color':closedJob.candidate_rating.text_color}"
                                        class="badge-tags-inverse nowrap align-self-center ml-2 display-inline-flex" title="{{closedJob.candidate_rating.hover_data}}">
                                        {{closedJob.candidate_rating.description}}
                                      </span>
                                      <span class="badge-tags-inverse ml-3 bg-success-color cursor-pointer" *ngIf="closedJob.is_vetted">
                                        Vetted
                                      </span>
                                    </div>
                                  </div>
                                  <div class="card-body-content">
                                    <div class="row" *ngIf="closedJob.stage.value || (closedJob.substatus && closedJob.substatus.description)">
                                      <div class="col-6" *ngIf="closedJob.stage.value">
                                        <div class="form-group">
                                          Stage
                                          <ng-template #tooltipClose>
                                            <div class="card card-preview">
                                              <div class="card-body">
                                                <div class="card-body-title-basic">
                                                  Stage: {{closedJob.stage.description}}
                                                </div>
                                                <div class="card-body-content">
                                                  <div *ngIf="closedJob.shortlisted_date">
                                                    Shortlisted date:
                                                    {{closedJob.shortlisted_date |
                                                    dateDiffTimestampFullNav:closedJob.shortlisted_date}}
                                                  </div>
                                                  <div *ngIf="closedJob.sent_date">
                                                    Sent date:
                                                    {{closedJob.sent_date |
                                                    dateDiffTimestampFullNav:closedJob.sent_date}}
                                                  </div>
                                                  <div *ngIf="closedJob.first_interview_date">
                                                    First interview date:
                                                    {{closedJob.first_interview_date |
                                                    dateDiffTimestampFullNav:closedJob.first_interview_date}}
                                                  </div>
                                                  <div *ngIf="closedJob.second_interview_date">
                                                    Second interview date:
                                                    {{closedJob.second_interview_date |
                                                    dateDiffTimestampFullNav:closedJob.second_interview_date}}
                                                  </div>
                                                  <div *ngIf="closedJob.offer_date">
                                                    Offer date:
                                                    {{closedJob.offer_date |
                                                    dateDiffTimestampFullNav:closedJob.offer_date}}
                                                  </div>
                                                  <div *ngIf="closedJob.placed_date">
                                                    Placed date:
                                                    {{closedJob.placed_date |
                                                    dateDiffTimestampFullNav:closedJob.placed_date}}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </ng-template>
                                          <ng-template #t let-fill="fill" let-last="last" let-index="index">
                                            <span class="bullet" [class.full]="fill === 100">
                                              <span class="bullet"
                                                [ngClass]="fill === 100 && (closedJob.substatus && closedJob.substatus.success) ? 'success' : index == closedJob.stage.current_stage-1 ? 'error' : 'success'"
                                                [style.width.%]="fill" [ngbTooltip]="tooltipClose" placement="bottom-left" [innerHTML]="'&#x025CF;'"></span>
                                              <span [innerHTML]="'&#x025CF;'"></span>
                                            </span>
                                          </ng-template>
                                          <ngb-rating [(rate)]="closedJob.stage.current_stage" [starTemplate]="t" [readonly]="true" [max]="closedJob.stage.max_stage">
                                          </ngb-rating>
                                        </div>
                                      </div>
                                      <div class="col-6" *ngIf="closedJob.substatus && closedJob.substatus.description">
                                        <div class="form-group">
                                          <span class="badge m-0" [ngStyle]="{'color': closedJob.substatus.color_code}">
                                            <i class="fa fa-circle"></i>
                                          </span>
                                          <label>{{closedJob.substatus.description}}</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                          <ng-template #showEmptyClosedJobs>
                            <div class="row card-block">
                              <div class="col-12">
                                No information available
                              </div>
                            </div>
                          </ng-template>
                        </ng-template>
                      </ngb-panel>
                    </ngb-accordion>
                    <ng-template #emptyNavis1History>
                      <div class="row card-block">
                        <div class="col-12">
                          This candidate is not part of Navis1 searches
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </ng-template>
      </ng-container>
      <ng-container [ngbNavItem]="'ngb-tab-5'">
        <a ngbNavLink>Activity</a>
        <ng-template ngbNavContent>
          <div class="card card-top" [ngClass]="{'scroll scroll-tabs':innerWidth > 1200}">
            <ngb-accordion id="filter-accordion" [(activeIds)]="activeIds" #acc="ngbAccordion" class="ng-accordion-append" (panelChange)="preventDefault ? beforeChange($event) : ''">
              <ngb-panel id="filters">
                <ng-template ngbPanelHeader>
                  <div class="form" (click)="acc.toggle('filters')">
                    <div class="form-section">
                      Filters
                      <!-- <i class="pull-right mt-1 pt-2"
                        [ngClass]="{'ft-chevron-right': !accordion.filters,'ft-chevron-down': accordion.filters}"></i> -->
                    </div>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="form-group">
                    <div class="card card-preview">
                      <div class="row">
                        <div class="col-md-5 col-12">
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>
                                Filter type
                              </label>
                            </div>
                            <div class="card-body-content row">
                              <div class="col-10">
                                <ng-select [items]="resources.activity_filters" class="ng-append" bindLabel="description" bindValue="value" [(ngModel)]="activeFilter" [clearable]="false"
                                  (change)="getCandidateActivities()" dropdownPosition="bottom" placeholder="Select filter type">
                                </ng-select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
            <div class="card card-top" [ngClass]="{'scroll scroll-tabs':innerWidth > 1200}">
              <div *ngIf="loadingActivity" class="loading-view">
                <img [src]="globals.loading" class="loading-element" />
              </div>
              <div *ngIf="candidateActivities.length>0; then showActivity else hideActivity;"></div>
              <ng-template #hideActivity>
                <div class="row card-block">
                  <div class="col-12">
                    No information available
                  </div>
                </div>
              </ng-template>
              <ng-template #showActivity>
                <div [ngClass]="{'scroll scroll-comments':innerWidth > 1200}">
                  <div class="timeline-centered full-width">
                    <article class="timeline-entry" *ngFor="let activity of candidateActivities">
                      <div class="timeline-entry-inner" *ngIf="activity">
                        <div class="timeline-icon">
                          <i class="fa fa-circle big-search-primary-color"></i>
                        </div>
                        <div class="timeline-label">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="timeline-body mr-4" [innerHTML]="activity.title" *ngIf="activity.title"></div>
                              <span class="timeline-time-ago mb-2">
                                {{ transform (activity.date || activity.created_at ) }}
                              </span>
                              <div *ngFor="let tag of activity.tags" class="mt-2 display-inline-flex">
                                <span class="badge-tag ml-3" [ngStyle]="{'background-color': tag.color_code, 'color': getContrastYIQ(tag.color_code.substr(1))}">
                                  {{tag.description}}
                                </span>
                              </div>
                              <div *ngIf="activity.body" class="timeline-body mt-3 mr-4">
                                <div *ngIf="'emails' == activeFilter;then emailBody else activityBody">
                                </div>
                                <ng-template #emailBody>
                                  <div class="timeline-body-activity p-2" [innerHTML]="activity.body"></div>
                                </ng-template>
                                <ng-template #activityBody>
                                  <div class="timeline-body-activity p-2" *ngIf="!isObject(activity.body);else structured_note" [innerHTML]="activity.body"></div>
                                  <ng-template #structured_note>
                                    <div class="timeline-body-activity p-2">
                                      <div *ngFor="let key of objectKeys(activity.body)">
                                        <span *ngIf="isArray(activity.body[key])">
                                          <div *ngIf="activity.body[key].length>0">
                                            <div *ngIf="checkArrayObjectValues(activity.body[key])">
                                              <strong> {{key | removeUndersoreCapitalizeFirst}}: </strong>
                                            </div>
                                            <div *ngFor="let child of activity.body[key]">
                                              <div *ngFor="let childKey of objectKeys(child)" class="pl-4">
                                                <div *ngIf="'skill' === childKey && child[childKey] ">
                                                  {{child[childKey]}}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </span>
                                        <span *ngIf="!isArray(activity.body[key])">
                                          <span *ngIf="isObject(activity.body[key])">
                                            <div *ngIf="checkObjectValues(activity.body[key])">
                                              <strong> {{key | removeUndersoreCapitalizeFirst}}: </strong>
                                            </div>
                                            <div *ngFor="let childKey of objectKeys(activity.body[key])" class="pl-4">
                                              <span *ngIf="isObject(activity.body[key][childKey])">
                                                <div *ngIf="checkObjectValues(activity.body[key][childKey])">
                                                  <strong> {{childKey | removeUndersoreCapitalizeFirst}}: </strong>
                                                </div>
                                                <div *ngFor="let nestedChildKey of objectKeys(activity.body[key][childKey])" class="pl-4">
                                                  <div *ngIf="activity.body[key][childKey][nestedChildKey]">
                                                    <strong> {{nestedChildKey | removeUndersoreCapitalizeFirst}}:
                                                    </strong>
                                                    {{activity.body[key][childKey][nestedChildKey] |
                                                    removeUndersoreCapitalizeFirst}}
                                                  </div>
                                                </div>
                                              </span>
                                              <span *ngIf="!isObject(activity.body[key][childKey])">
                                                <strong> {{childKey | removeUndersoreCapitalizeFirst}}: </strong>
                                                {{activity.body[key][childKey] | removeUndersoreCapitalizeFirst}}
                                              </span>
                                            </div>
                                          </span>
                                          <span *ngIf="!isObject(activity.body[key])">
                                            <div *ngIf="activity.body[key] !== null" class="display-inline-flex">
                                              <strong class="pr-1"> {{key | removeUndersoreCapitalizeFirst}}:</strong>
                                              <span [innerHTML]="activity.body[key]"></span>
                                            </div>
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </ng-template>
                                </ng-template>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>
                    <article class="timeline-entry begin">
                      <div class="timeline-entry-inner">
                        <div class="timeline-icon timeline-icon-bottom">
                          <i class="fa fa-circle"></i>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </nav>
    <div [ngbNavOutlet]="acc"></div>
  </div>
</div>
