import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbModal, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from 'app/globals';
import { environment } from 'environments/environment';
import { FileUploader } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import { Subject, of, concat, Observable } from 'rxjs';
import { AddCandidateService } from './add-candidate.service';
import { distinctUntilChanged, debounceTime, switchMap, tap, catchError, filter } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { CurrentlyUnemployedModalComponent } from './currently-unemployed-modal/currently-unemployed-modal.component';

@Component({
  selector: 'app-add-candidate',
  templateUrl: './add-candidate.component.html',
  styleUrls: ['./add-candidate.component.scss']
})
export class AddCandidateComponent implements OnInit{
  readonly minLengthTerm = 3;

  loading = false;

  uploader: FileUploader;

  uploaderFile: FileUploader;

  hasBaseDropZoneOver;

  loadComponent = false;

  updateCandidate = false;

  inProcess: boolean;

  oldExp: any = [];

  activeIds = {
    details: [],
    languages: [],
    experience: [],
    education: [],
    jobs: []
  };

  preventDefault = {
    details: false,
    languages: false,
    education: false,
    experience: false,
    jobs: false
  };

  blacklistedCandidate = null;

  accordion: any = {
    basic: true,
    //compensation: true,
    closedJobs: true,
    openJobs: true,
    jobs: true,
    language: {},
    experience: {},
    education: {}
  };

  candidate: any = {
    //compensation: {},
    languages: [],
    experiences: [],
    educations: []
  };

  resources: any = {
    gender_type: [],
    source_type: [],
    currencies: [],
    bonus_type: [],
    languageLevels: [],
    role_seniority: [],
    industries: [],
    functionalExpertise: [],
    investmentStage: [],
    languages: [],
    investmentSector: [],
    country: [],
    nationalities: [],
    subFunctionalExpertise: [],
    filteredSfe: [],
    subStatuses: [],
    jobs: [],
    stages: [],
    candidate_application_rating: []
  };

  job: any = {};

  locations$: Observable<any>;

  locationLoading = false;

  locationInput$ = new Subject<string>();

  languageLoading = false;

  languageinput = new Subject<string>();

  nationalityLoading = false;

  nationalityinput = new Subject<string>();

  sectorLoading = false;

  sectorinput = new Subject<string>();

  countryLoading = false;

  countryinput = new Subject<string>();

  validation: any = {
    details: true,
    language: true,
    experience: true,
    education: true,
    job: true,
    email: true,
    linkedin: true
  };

  activateLonglistValidation = false;

  validEmailRegex = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;

  uploadingData = false;

  constructor(public globals: Globals, private toastr: ToastrService, private addCandidateService: AddCandidateService, private cdRef: ChangeDetectorRef, private modalService: NgbModal){
    const URL = environment.platformBackendURL + '/assets';
    this.uploader = new FileUploader({
      url: URL,
      isHTML5: true,
      method: 'POST',
      itemAlias: 'file',
      authTokenHeader: 'authorization',
      authToken: 'Bearer ' + localStorage.getItem('access-token')
    });

    this.uploaderFile = new FileUploader({
      url: environment.djangoURL + '/transformLinkedInCandidateData/',
      method: 'POST',
      itemAlias: 'linkedin_pdf',
      authTokenHeader: 'authorization',
      authToken: 'Bearer ' + environment.djangoKey
    });

    this.uploader.onCompleteItem = (item: any, response: any, status: any) => {
      const obj = JSON.parse(response);
      if (status === 201){
        this.candidate.image_url = obj.file_url;
        this.loading = false;
      } else {
        this.toastr.error(obj.message);
      }
    };

    this.uploaderFile.onCompleteItem = (item: any, response: any, status: any) => {
      this.loading = false;
      if (status === 200){
        this.candidate = JSON.parse(response);
        this.activateAccordions();
        this.getResources('functionalExpertise');
        this.checkIfCandidateIsBlacklisted();

        this.oldExp = JSON.parse(JSON.stringify(this.candidate.experiences));
        this.updateCandidate = true;

      } else if (Math.floor(status / 100) === 4 || Math.floor(status / 100) === 5){
        this.toastr.error('There is an error with parsing the PDF file. Please contact Product team ;)');
      }
      this.hasBaseDropZoneOver = false;
      this.job = {};
    };
  }

  checkIfCandidateIsBlacklisted(){
    this.blacklistedCandidate = this.candidate.blacklisted.find(obj => obj.value === 'candidate');
  }

  ngOnInit(){
    this.activeIds.details = ['basic'];
    this.activeIds.jobs = ['jobs'];
    this.addNewLanguage();
    this.addNewExperience();
    this.addNewEducation();
    this.loadComponent = true;
    this.loadAsyncResources('languages', 'languageinput', 'languageLoading');
    this.loadAsyncResources('country', 'countryinput', 'countryLoading');
    this.loadAsyncResources('investmentSector', 'sectorinput', 'sectorLoading');
    this.loadAsyncResources('nationalities', 'nationalityinput', 'nationalityLoading');
    this.loadLocations();
    this.uploaderFile.onAfterAddingFile = (file: any) => { file.withCredentials = false; };
  }

  trackByFn(item: any){
    return item.imdbID;
  }

  OnContentChange(event, uploader){
    if (event.target.value){
      this.loading = true;
      if (uploader == 'uploaderFile'){
        this.uploaderFile.uploadAll();
      } else {
        this.uploader.uploadAll();
      }
    } else {
      this.loading = false;
    }
  }

  afterFileisDrop(){
    this.loading = true;
  }

  fileOverBase(e: any){
    this.hasBaseDropZoneOver = e;
    this.uploaderFile.uploadAll();
  }

  public beforeChange($event: NgbPanelChangeEvent, i?){

    $event.preventDefault();
    this.preventDefault.details = false;
    this.preventDefault.education[i] = false;
    this.preventDefault.experience[i] = false;
    this.preventDefault.languages[i] = false;
    this.preventDefault.jobs = false;

  }

  getResources(value, name?){
    if (!this.resources[value].length){
      this.addCandidateService.getResources(value, name).subscribe((data) => {
        if (data.length){
          this.resources[value] = data;
        }
        if (value == 'functionalExpertise'){
          this.getResources('subFunctionalExpertise');
        }
      });
    }
  }

  getResourcesByType(value){
    if (!this.resources[value].length){
      this.addCandidateService.getResourcesByType(value).subscribe((data) => {
        if (data.length){
          this.resources[value] = data;
        }
      });
    }
  }

  loadLocations(){
    this.locations$ = concat(
      of([]), // default items
      this.locationInput$.pipe(
        filter(res => res !== null && res.length >= this.minLengthTerm),
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => this.locationLoading = true),
        switchMap(term => this.addCandidateService.getLocations(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.locationLoading = false)
          ))
      )
    );
  }

  loadAsyncResources(resourceType, resourceInput, resourceBoolean){
    this.resources[resourceType] = concat(
      of([]),
      this[resourceInput].pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this[resourceBoolean] = true),
        switchMap(name =>
          this.addCandidateService.getResources(resourceType, name).pipe(
            catchError(() => of([])),
            tap(() => {
              this[resourceBoolean] = false;
            })
          ))
      )
    );
  }

  getFilteredSfe(fe){
    this.resources.filteredSfe = [];
    if (fe.length){
      fe.forEach((element) => {
        this.resources.subFunctionalExpertise.forEach(obj => {
          if (obj.fe_value === element.value || obj.fe_value === null){
            this.resources.filteredSfe.push(obj);
          }
        });
      });
    }
    this.resources.filteredSfe = this.resources.filteredSfe.filter(
      (item, i, arr) => arr.findIndex(distinctItem => distinctItem.value && distinctItem.value === item.value) === i
    );
  }

  onUnselectFunctional(experience){
    if (experience.fe.length){
      this.getFilteredSfe(experience.fe);
      experience.fe.forEach(fe => {
        experience.sfe = experience.sfe.filter((element) => fe.value == element.fe_value);
      });
    } else {
      experience.sfe = [];
    }
  }

  getJobsResources(){
    if (!this.resources.jobs.length){
      this.addCandidateService.getJobsResources().subscribe((data) => {
        if (data.length){
          this.resources.jobs = data;
        }
      });
    }
  }

  getApplicationStagesResources(){
    if (!this.resources.stages.length){
      this.addCandidateService.getApplicationStagesResources().subscribe((data) => {
        if (data.length){
          this.resources.stages = data;
        }
      });
    }
  }

  preventNegativeNumbers(e: any){
    if (!((e.keyCode > 95 && e.keyCode < 106)
      || (e.keyCode > 47 && e.keyCode < 58)
      || e.keyCode == 8)){
      return false;
    }
    return true;
  }

  isPrimary(item, i){
    if (item.target.checked){
      this.candidate.experiences.forEach((item, index) => {
        if (index !== i){
          item.is_primary = false;
        }
      });
    }
  }

  addExpertise(experience){
    if (experience.expertise === null){
      experience.expertise = [];
    }
    experience.expertise.push({ 'value': this.generateValueFromDescription(experience.exp), 'description': experience.exp });
    experience.exp = null;
  }

  addSectorTag(tag: string){
    return {
      'value': this.generateValueFromDescription(tag),
      'description': tag
    };
  }

  generateToBeFoundEmail(){
    this.candidate.email = uuid().replace(/-/g, '')
.substring(0, 13) + '@tobefound.com';
    this.validation.email = true;
  }

  languageObject(){
    return {
      level: null,
      language: null
    };
  }

  experienceObject(){
    return {
      company: null,
      expertise: [],
      fe: [],
      from: null,
      industry: null,
      not_applicable: false,
      is_blacklisted: false,
      is_primary: false,
      is_investment: false,
      is_linkedin: false,
      location: null,
      sfe: [],
      summary: null,
      seniority: null,
      title: null,
      to: null,
      investment_country_focus: null,
      investment_sector_focus: [],
      investment_stage_focus: []

    };
  }

  educationObject(){
    return {
      degree: null,
      from: null,
      to: null,
      is_linkedin: false,
      university: null
    };
  }

  addNewLanguage(){
    this.candidate.languages.push(this.languageObject());
    const activeAccordion = 'language' + (this.candidate.languages.length - 1);
    this.activeIds.languages.push(activeAccordion);
  }

  addNewEducation(){
    this.candidate.educations.push(this.educationObject());
    const activeAccordion = 'education' + (this.candidate.educations.length - 1);
    this.activeIds.education.push(activeAccordion);
  }

  addNewExperience(){
    this.candidate.experiences.push(this.experienceObject());
    const activeAccordion = 'experience' + (this.candidate.experiences.length - 1);
    this.activeIds.experience.push(activeAccordion);
  }

  activateAccordions(){
    for (let index = 0; index < this.candidate.experiences.length; index++){
      this.activeIds.experience.push('experience' + index);

    }
    for (let index = 0; index < this.candidate.educations.length; index++){
      this.activeIds.education.push('education' + index);

    }
    for (let index = 0; index < this.candidate.languages.length; index++){
      this.activeIds.languages.push('language' + index);

    }
  }

  deleteLanguage(i, $event){
    $event.preventDefault();
    this.candidate.languages.splice(i, 1);
  }

  deleteExperience(i, $event){
    $event.preventDefault();
    this.candidate.experiences.splice(i, 1);
  }

  deleteEducation(i, $event){
    $event.preventDefault();
    this.candidate.educations.splice(i, 1);
  }

  validateDetails(){
    let valid = true;
    this.cdRef.detectChanges();

    if (this.candidate.first_name && this.candidate.last_name && (this.candidate.gender && this.candidate.gender.value)){
      this.validation.details = true;
    } else {
      this.validation.details = false;
      valid = false;
    }

    if (!this.validation.email || !this.candidate.email){
      this.validation.email = false;
      valid = false;
    }
    if (!this.validation.linkedin){
      valid = false;
    }

    return valid;
  }

  checkCandidateField(field_name){
    let body = null;

    if (field_name == 'email' && this.candidate.email){
      body = field_name + '=' + this.candidate.email;
    } else if (field_name == 'linkedin_url' && this.candidate.linkedin_url){
      body = field_name + '=' + this.candidate.linkedin_url;
    }

    if (body){
      this.addCandidateService.checkCandidateData(body)
        .subscribe((response) => {
          if (response.length){
            let found = false;
            if (this.candidate.candidate_uuid){
              found = response.some(el => el.uuid === this.candidate.candidate_uuid);
            }
            if (field_name === 'email'){
              if (!found){
                this.toastr.error('Candidate with this email already exists');
                this.validation.email = false;
              } else {
                this.validation.email = true;
              }
            } else if (field_name === 'linkedin_url'){
              if (!found){
                this.toastr.error('Candidate with this linkedin url already exists');
                this.validation.linkedin = false;
              } else {
                this.validation.linkedin = true;
              }
            }
          } else {
            if (field_name === 'email'){
              this.validation.email = true;
            } else {
              this.validation.linkedin = true;
            }
          }

        }, (err) => {
          if (err.error){
            for (const errProp in err.error){
              if (err.error.hasOwnProperty(errProp) && err.error[errProp][0]){
                this.toastr.error(err.error[errProp]);
              }
            }
          } else {
            this.toastr.error('Something went wrong');
          }
        });
    } else {
      if (field_name === 'email'){
        this.validation.email = false;
      } else {
        this.validation.linkedin = true;
      }
    }
  }

  validateExperience(){
    for (let i = 0; i < this.candidate.experiences.length; i++){
      if (!this.candidate.experiences[i].not_applicable){
        if (!this.candidate.experiences[i].company && !this.candidate.experiences[i].from && !this.candidate.experiences[i].to && !this.candidate.experiences[i].location && !this.candidate.experiences[i].summary &&
          !this.candidate.experiences[i].seniority && !this.candidate.experiences[i].title && !this.candidate.experiences[i].investment_country_focus && !this.candidate.experiences[i].expertise.length
          && !this.candidate.experiences[i].fe.length
          && !this.candidate.experiences[i].investment_sector_focus.length && !this.candidate.experiences[i].investment_stage_focus.length){
          this.candidate.experiences.splice(i, 1);
        } else if (this.candidate.experiences[i].title && this.candidate.experiences[i].company && this.candidate.experiences[i].from &&
          (this.activateLonglistValidation ? (this.candidate.experiences[i].fe.length && this.candidate.experiences[i].seniority) : true)){
          this.validation.experience = true;
        } else {
          this.validation.experience = false;
          return false;
        }
      }
    }
    return true;

  }

  validateEducation(){
    for (let i = 0; i < this.candidate.educations.length; i++){
      if (!this.candidate.educations[i].degree && !this.candidate.educations[i].from && !this.candidate.educations[i].to && !this.candidate.educations[i].university && !this.candidate.educations[i].major){
        this.candidate.educations.splice(i, 1);
      } else if (this.candidate.educations[i].degree && this.candidate.educations[i].university){
        this.validation.education = true;
      } else {
        this.validation.education = false;
        return false;
      }
    }
    return true;
  }

  validateJob(){
    if (this.job.stage){
      if (this.job.stage.value == 'longlist'){
        this.inProcess = this.checkIfCandidateIsInProcess();
        this.activateLonglistValidation = true;
        this.validateExperience();
        this.validation.job = this.job.substatus ? true : false;
        return this.job.substatus ? true : false;
      } else {
        this.activateLonglistValidation = false;
        this.validation.job = true;
        return true;
      }
    }
    this.validation.job = false;
    return false;
  }

  checkIfCandidateIsInProcess(): boolean{
    if (!this.candidate.job_history){
      return false;
    }

    for (const element of this.candidate.job_history.open){
      if (element.stage && element.stage.value !== 'market_mapping'){
        return true;
      }
    }

    return false;
  }

  createUpdateCandidate(){
    if (this.validateDetails() && (this.job.job_uuid ? this.validateJob() : true) && this.validateEducation() && this.validateExperience()){
      this.activateLonglistValidation = false;
      if (!this.candidate.experiences.filter(item => item.is_primary).length){
        const modalRef = this.modalService.open(CurrentlyUnemployedModalComponent, { centered: true, size: 'sm', backdrop: 'static' });
        modalRef.result.then(() => {
          this.uploadDataToServer();
        }, () => {
        });
      } else this.uploadDataToServer();
    } else {
      this.toastr.error('Please fill all required fields');
    }
  }

  uploadDataToServer(){
    this.uploadingData = true;
    this.addCandidateService.createCandidate(this.candidate)
      .subscribe((response) => {
        this.toastr[response.tag](response.value);
        if (response.tag == 'success'){
          if (this.job.job_uuid){
            this.job.candidate_uuid = response.candidate_uuid;
            setTimeout(() => {
              this.createCandidateApplication();
            }, 2000);

          } else {
            this.uploadingData = false;
            this.initializeComponent();
          }
        } else {
          this.uploadingData = false;
          this.initializeComponent();
        }
      }, (err) => {
        if (err.error){
          for (const errProp in err.error){
            if (err.error.hasOwnProperty(errProp) && err.error[errProp][0]){
              this.toastr.error(err.error[errProp]);
            }
          }
        } else {
          this.toastr.error('Something went wrong');
        }
        this.uploadingData = false;
      });
  }

  createCandidateApplication(){
    if (this.job.candidate_uuid && this.job.job_uuid){
      this.addCandidateService.createCandidateApplication(this.job)
        .subscribe((response) => {
          if (response.success){
            response.success.forEach(element => {
              if (element.hasOwnProperty('message')){
                this.toastr.success(element.message);
              }
            });
          }
          this.uploadingData = false;
          this.initializeComponent();
        }, (err) => {
          if (err.error){
            for (const errProp in err.error){
              if (err.error.hasOwnProperty(errProp)){
                if (err.error[errProp][0]){
                  this.toastr.error(err.error[errProp]);
                }
              }
            }
          } else {
            this.toastr.error('Something went wrong');
          }
          this.uploadingData = false;
        });
    }
  }

  initializeComponent(){
    this.candidate = {
      // compensation: {},
      languages: [],
      experiences: [],
      educations: []
    };
    this.job = {};
    this.activeIds = {
      details: [],
      languages: [],
      experience: [],
      education: [],
      jobs: []
    };
    this.activeIds.details = ['basic', 'compensation', 'closedJobs', 'openJobs', 'jobs'];
    this.addNewLanguage();
    this.addNewExperience();
    this.addNewEducation();
    this.oldExp = [];
    this.updateCandidate = false;
    this.validation = {
      details: true,
      language: true,
      experience: true,
      education: true,
      job: true,
      email: true,
      linkedin: true
    };
  }

  generateValueFromDescription(value){
    return value.replace(/\s+/g, '_').toLowerCase();
  }

  ratingCSS(job){
    return job.stage && job.stage.value == 'longlist' ? "form-group col-6" : "form-group col-12";
  }
}
