import { takeWhile } from 'rxjs/operators';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Globals } from 'app/globals';
import { ToastrService } from 'ngx-toastr';
import { ViewCampaignService } from './view-campaign.service';
import * as Chartist from 'chartist';
import { ChartType, ChartEvent } from "ng-chartist";
import { NgbPanelChangeEvent, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CandidateTriggerTypeModalComponent } from './modals/candidate-trigger-type-modal/candidate-trigger-type-modal.component';
import { CandidateEmailModalComponent } from './modals/candidate-email-modal/candidate-email-modal.component';
import { CommunicationService } from 'app/shared/service/communication.service';
import { CandidateNextStepEmailModalComponent } from './modals/candidate-next-step-email-modal/candidate-next-step-email-modal.component';

export interface Chart
{
  type: ChartType;
  data: Chartist.IChartistData;
  options?: any;
  responsiveOptions?: any;
  events?: ChartEvent;
}

@Component({
  selector: 'app-view-campaign',
  templateUrl: './view-campaign.component.html',
  styleUrls: ['./view-campaign.component.scss']
})
export class ViewCampaignComponent implements OnInit, OnChanges{
  @Input() uuid: string;

  show: any = {};

  validForm = true;

  resources: any = {
    jobs: [],
    templates: [],
    contacts: [],
    triggerType: [],
    triggerSubType: [],
    substatuses: [],
    steps: [],
    events: []
  };

  metrics: any = {};

  campaign: any = {
    name: null,
    job: null,
    steps: []
  };

  triggers: any = {
    triggerType: 'campaign_trigger_type',
    triggerSubType: 'campaign_trigger_sub_type'
  };

  metricsData: any = {};

  chart: Chart;

  chosenTempalte = 'candidates';

  allCandidates: any = [];

  candidates: any = [];

  activeIdsCampaigns: string[] = [];

  preventDefaultCampaigns = false;

  filters: any = {
    candidates: null,
    step: null,
    event: null,
    metrics: null
  };

  componentAlive = true;

  constructor(private viewCampaignService: ViewCampaignService, public globals: Globals, private toastr: ToastrService, private modalService: NgbModal, private communicationService: CommunicationService){
    this.communicationService.addedCandidateCampaign$.pipe(takeWhile(() => this.componentAlive))
      .subscribe((data) => {
        if (data){
          this.getCandidates();
          this.getCampaign(this.uuid);
        }
      });
  }

  ngOnInit(){

  }

  ngOnChanges(){
    this.getEvents();
    this.getCampaign(this.uuid);
    this.initElements();
  }

  initElements(){
    this.show = {
      component: false,
      campaignUpdate: false,
      metrics: false,
      candidates: false
    };
    this.resources.steps = [];
    this.candidates = [];
    this.allCandidates = [];
    this.filters = {
      candidates: null,
      step: null,
      event: null
    };
    this.chosenTempalte = 'candidates';
  }

  getCampaign(uuid){
    this.viewCampaignService.getCampaign(uuid).subscribe(
      (response) => {
        if (response){
          this.campaign = response.campaign;
          this.metrics = response.metrics;
          if (this.metrics.sent.total){
            this.show.metrics = true;
            if (this.metrics.steps.length){
              this.filters.metrics = this.metrics.steps[0];
            }
            const total = this.metrics.sent.total;
            this.chart = {
              type: 'Pie',
              data: this.metrics.sent.data,
              options: {
                donut: true,
                startAngle: 0,
                labelInterpolationFnc: function (){
                  return total;
                }
              },
              events: {
                draw(data: any): void{
                  if (data.type === 'label'){
                    if (data.index === 0){
                      data.element.attr({
                        dx: data.element.root().width() / 2,
                        dy: data.element.root().height() / 2
                      });
                    } else {
                      data.element.remove();
                    }
                  }

                }
              }
            };
          }
          this.campaign.steps.forEach(element => {
            if (element.trigger_type.value == 'daily_at'){
              element.time = {
                hour:  parseInt(element.trigger_time.substr(0, element.trigger_time.indexOf(':'))),
                minute: parseInt(element.trigger_time.substr(element.trigger_time.indexOf(':') + 1, element.trigger_time.length)),
                second: 0
              };
            }
          });
          this.show.component = true;
        }
      },
      (err) => {
        if (err.error){
          for (const errProp in err.error){
            if (err.error.hasOwnProperty(errProp)){
              if (err.error[errProp].length){
                this.toastr.error(err.error[errProp]);
              }
            }
          }
        } else {
          this.toastr.error('Something went wrong');
        }
      }
    );
  }

  templateChanged(template){
    switch (template){
      case 'candidates': {
        if (!this.candidates.length)
          this.getCandidates();
        break;
      }
      default: break;
    }
  }

  getCandidates(){
    if (this.filters.step){
      this.filters.metrics = this.metrics.steps.find(step => step.name == this.filters.step);
    }
    this.viewCampaignService.getCandidates(this.uuid, this.filters.step, this.filters.event).subscribe(
      (response) => {
        if (response.length){
          this.candidates = response;
          this.show.candidates = true;
          this.candidates.forEach((element: any) => {
            this.activeIdsCampaigns.push('campaigns-' + element.uuid);
            element.currentStepActions = this.resources.events.find(item => item.value == element.current_event.value).children;
            if (element.next_step_event && element.next_step_event.value){
              element.nextStepActions = this.resources.events.find(item => item.value == element.next_step_event.value).children;
            }
          });
          if (this.filters.candidates){
            this.searchCandidates();
          }
          this.allCandidates = this.candidates;
        } else {
          this.show.candidates = false;
        }
      },
      (err) => {
        if (err.error){
          for (const errProp in err.error){
            if (err.error.hasOwnProperty(errProp)){
              if (err.error[errProp].length){
                this.toastr.error(err.error[errProp]);
              }
            }
          }
        } else {
          this.toastr.error('Something went wrong');
        }
      }
    );
  }

  searchCandidates(){
    this.candidates = this.allCandidates.filter(item => item.name.toLowerCase().includes(this.filters.candidates.toLowerCase()));
  }

  getSteps(){
    if (!this.resources.steps.length){
      this.viewCampaignService.getStepsResources(this.uuid).subscribe(
        (response) => {
          if (response){
            this.resources.steps = response;
          }
        },
        (err) => {
          if (err.error){
            for (const errProp in err.error){
              if (err.error.hasOwnProperty(errProp)){
                if (err.error[errProp].length){
                  this.toastr.error(err.error[errProp]);
                }
              }
            }
          } else {
            this.toastr.error('Something went wrong');
          }
        }
      );
    }
  }

  getEvents(){
    if (!this.resources.events.length){
      this.viewCampaignService.getEventsResources().subscribe(
        (response) => {
          if (response){
            this.resources.events = response;
          }
        },
        (err) => {
          if (err.error){
            for (const errProp in err.error){
              if (err.error.hasOwnProperty(errProp)){
                if (err.error[errProp].length){
                  this.toastr.error(err.error[errProp]);
                }
              }
            }
          } else {
            this.toastr.error('Something went wrong');
          }
        },
        () => {
          this.getCandidates();
        }
      );
    } else {
      this.getCandidates();
    }
  }

  setBadgeClass(event){
    return this.resources.events.find(item => item.value == event).color_code;
  }

  changeCandidateEvent(action, candidate, i){
    this.viewCampaignService.putCandidateStatus(action, this.uuid, candidate.uuid).subscribe(
      (response) => {
        if (response){
          this.candidates[i] = response;
          this.candidates[i].currentStepActions = this.resources.events.find(item => item.value == this.candidates[i].current_event.value).children;
          if (this.candidates[i].next_step_event && this.candidates[i].next_step_event.value){
            this.candidates[i].nextStepActions = this.resources.events.find(item => item.value == this.candidates[i].next_step_event.value).children;
          }

          this.toastr.success("Successfully changed event");
        }
      },
      (err) => {
        if (err.error){
          for (const errProp in err.error){
            if (err.error.hasOwnProperty(errProp)){
              if (err.error[errProp].length){
                this.toastr.error(err.error[errProp]);
              }
            }
          }
        } else {
          this.toastr.error('Something went wrong');
        }
      }
    );

  }

  changeCandidateTriggerType(step, candidate, i){
    const data = {
      campaign: this.uuid,
      candidate: candidate,
      step: step
    };
    const modalRef = this.modalService.open(CandidateTriggerTypeModalComponent, { size: 'md' as 'lg', centered: true, backdrop: 'static' });
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data){
        this.candidates[i] = data;
        this.candidates[i].currentStepActions = this.resources.events.find(item => item.value == this.candidates[i].current_event.value).children;
        if (this.candidates[i].next_step_event && this.candidates[i].next_step_event.value){
          this.candidates[i].nextStepActions = this.resources.events.find(item => item.value == this.candidates[i].next_step_event.value).children;
        }
        this.toastr.success("Successfully changed trigger type");
      }
    }, () => {

    });
  }

  editEmail(candidate, email, i){
    this.preventDefaultCampaigns = true;
    const data = {
      primary_email: email,
      uuid: candidate,
      campaign: this.uuid,
      job: this.campaign.job.uuid
    };
    const modalRef = this.modalService.open(CandidateEmailModalComponent, { size: 'sm', centered: true, backdrop: 'static' });
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data){
        this.candidates[i].primary_email = data;
        this.preventDefaultCampaigns = false;
      }
    }, () => {
      this.preventDefaultCampaigns = false;
    });
  }

  public beforeChange($event: NgbPanelChangeEvent){
    $event.preventDefault();
    this.preventDefaultCampaigns = false;
  }

  openLinkedIn(linkedinURL){
    window.open(linkedinURL, '_blank');
  }

  initStep(){
    const step = {
      name: null,
      template: null,
      time: {},
      trigger_type: null,
      trigger_time: null,
      next_substatus: null,
      users: {
        from: null,
        cc: [],
        bcc: []
      }
    };
    return step;
  }

  addNewStep(){
    this.campaign.steps.push(this.initStep());
  }

  deleteStep(i){
    this.campaign.steps.splice(i, 1);
    this.toastr.success('Successfully deleted step');
    if (!this.campaign.steps.length){
      this.addNewStep();
    }
    if (i == 0){
      if (this.campaign.steps[0].trigger_type && this.campaign.steps[0].trigger_type.value == 'after_days'){
        this.campaign.steps[0].trigger_type = null; this.campaign.steps[0].time = null;
      }
      this.clearTemplateField();
    }
    this.show.campaignUpdate = true;
  }

  clearTemplateField(){
    if (this.campaign.job && this.campaign.job.type && (this.campaign.job.type.value == 'non-executive_search' || this.campaign.job.type.value == 'executive_search')){
      this.campaign.steps[0].template = null;
    }
  }

  getJobs(){
    if (!this.resources.jobs.length){
      this.viewCampaignService.getJobsResources().subscribe(
        (response) => {
          if (response){
            this.resources.jobs = response;
          }
        },
        (err) => {
          if (err.error){
            for (const errProp in err.error){
              if (err.error.hasOwnProperty(errProp)){
                if (err.error[errProp].length){
                  this.toastr.error(err.error[errProp]);
                }
              }
            }
          } else {
            this.toastr.error('Something went wrong');
          }
        }
      );
    }
  }

  getTemplates(){
    if (!this.resources.templates.length){
      this.viewCampaignService.getTemplatesResources().subscribe(
        (response) => {
          if (response){
            this.resources.templates = response;
          }
        },
        (err) => {
          if (err.error){
            for (const errProp in err.error){
              if (err.error.hasOwnProperty(errProp)){
                if (err.error[errProp].length){
                  this.toastr.error(err.error[errProp]);
                }
              }
            }
          } else {
            this.toastr.error('Something went wrong');
          }
        }
      );
    }
  }

  getContacts(){
    if (!this.resources.contacts.length){
      this.viewCampaignService.getContactsResources().subscribe(
        (response) => {
          if (response){
            this.resources.contacts = response;
          }
        },
        (err) => {
          if (err.error){
            for (const errProp in err.error){
              if (err.error.hasOwnProperty(errProp)){
                if (err.error[errProp].length){
                  this.toastr.error(err.error[errProp]);
                }
              }
            }
          } else {
            this.toastr.error('Something went wrong');
          }
        }
      );
    }
  }

  getKeyByValue(object, value){
    return Object.keys(object).find(key => object[key] === value);
  }

  getTriggers(type){
    if (!this.resources[this.getKeyByValue(this.triggers, type)].length){
      this.viewCampaignService.getTriggersResources(type).subscribe(
        (response) => {
          if (response){
            this.resources[this.getKeyByValue(this.triggers, type)] = response;
          }
        },
        (err) => {
          if (err.error){
            for (const errProp in err.error){
              if (err.error.hasOwnProperty(errProp)){
                if (err.error[errProp].length){
                  this.toastr.error(err.error[errProp]);
                }
              }
            }
          } else {
            this.toastr.error('Something went wrong');
          }
        }
      );
    }
  }

  transformTime(campaign){
    if (campaign.time){
      campaign.trigger_time = (campaign.time.hour < 10 ? '0' + campaign.time.hour : campaign.time.hour) + ':' + (campaign.time.minute < 10 ? '0' + campaign.time.minute : campaign.time.minute);
      this.show.campaignUpdate = true;
    }
  }

  getSubStatuses(){
    if (!this.resources.substatuses.length){
      this.viewCampaignService.getSubStatusesResources().subscribe(
        (response) => {
          if (response){
            this.resources.substatuses = response;
          }
        },
        (err) => {
          if (err.error){
            for (const errProp in err.error){
              if (err.error.hasOwnProperty(errProp)){
                if (err.error[errProp].length){
                  this.toastr.error(err.error[errProp]);
                }
              }
            }
          } else {
            this.toastr.error('Something went wrong');
          }
        }
      );
    }
  }

  checkCampaignRequiredFields(){
    this.validForm = true;
    if (this.campaign.name && this.campaign.job){
      for (let index = 0; index < this.campaign.steps.length; index++){
        if (!this.checkStepsRequiredFields(this.campaign.steps[index], index)){
          this.validForm = false;
          return false;
        }
      }
    } else {
      this.validForm = false;
      return false;
    }
    return true;
  }

  checkStepsRequiredFields(step, index){
    if (step.name && step.users.from && step.next_substatus && step.trigger_type && ((step.trigger_type.value == 'after_days' ? step.trigger_time >= 1 : step.trigger_time) || step.trigger_type.value == 'immediately') && this.checkTemplateRequiredFields(step, index)){
      return true;
    } else {
      return false;
    }
  }

  checkTemplateRequiredFields(step, index){
    if (step.template){
      return true;
    } else {
      if (index == 0 && this.campaign.job && this.campaign.job.type && (this.campaign.job.type.value == 'non-executive_search' || this.campaign.job.type.value == 'executive_search')){
        return true;
      } else {
        return false;
      }
    }
  }

  addUpdateCampaign(uuid){
    if (this.checkCampaignRequiredFields()){
      this.viewCampaignService.addUpdateCampaign(this.campaign, uuid).subscribe(
        (response) => {
          if (response){
            const newData = {
              data: response,
              job_uuid: null
            };
            this.communicationService.emitEditedCampaign(newData);
            this.toastr.success('Successfully ' + (uuid ? 'updated ' : 'created ') + 'campaign');
            this.show.campaignUpdate = false;
          }
        },
        (err) => {
          if (err.error){
            for (const errProp in err.error){
              if (err.error.hasOwnProperty(errProp)){
                if (err.error[errProp].length){
                  this.toastr.error(err.error[errProp]);
                }
              }
            }
          } else {
            this.toastr.error('Something went wrong');
          }
        }
      );
    }
  }

  editNextStepEmail(candidate){
    this.viewCampaignService.getNextStepEmail(this.campaign.uuid, candidate).subscribe(
      (response) => {
        if (response){
          const modalRef = this.modalService.open(CandidateNextStepEmailModalComponent, { size: 'md' as 'lg', centered: true, backdrop: 'static' });
          modalRef.componentInstance.data = response;
          modalRef.result.then((data) => {
            if (data){
              this.changeNextEmail(data);
            }
          }, () => { });
        }
      },
      (err) => {
        if (err.error){
          for (const errProp in err.error){
            if (err.error.hasOwnProperty(errProp) && err.error[errProp].length){
              this.toastr.error(err.error[errProp]);
            }
          }
        } else {
          this.toastr.error('Something went wrong');
        }
      }
    );
  }

  changeNextEmail(data){
    this.viewCampaignService.putNextEmail(data).subscribe(
      () => {
        this.toastr.success('Successfully updated next email');
      },
      (err) => {
        if (err.error){
          for (const errProp in err.error){
            if (err.error.hasOwnProperty(errProp)){
              if (err.error[errProp].length){
                this.toastr.error(err.error[errProp]);
              }
            }
          }
        } else {
          this.toastr.error('Something went wrong');
        }
      }
    );
  }

}
