import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from 'environments/environment';

@Injectable()
export class AddActivityService{

  constructor(private http: HttpClient){ }

  getActivityTypes(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources?type=activities_type,interview_next_steps_type,role_seniority,pitch_client_quality,terms_and_pricing_type,intake_revenue,currencies');
  }

  getJobs(job_name): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/jobs?name=' + job_name + '&type=open');
  }

  getCompanyJobs(job_name, company_id): Observable<any>{
    if (company_id){
    return this.http.get(environment.platformBackendURL + '/jobs?name=' + job_name + '&company_id=' + company_id + '&type=open');
    } else {
      return this.http.get(environment.platformBackendURL + '/jobs?name=' + job_name + '&type=open');
    }
  }

  getCandidates(candidate_name, job_id): Observable<any>{
    if (job_id){
      return this.http.get(environment.platformBackendURL + '/candidates?name=' + candidate_name + '&jobs=' + job_id.toString() + '&relations=compensation');
    } else {
      return this.http.get(environment.platformBackendURL + '/candidates?name=' + candidate_name + '&relations=compensation');
    }
  }

  getCompanies(company_name): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/companies?name=' + company_name);
  }

  getContacts(contact_name, company_id): Observable<any>{
    if (company_id){
      return this.http.get(environment.platformBackendURL + '/contacts?name=' + contact_name + '&company_id=' + company_id);
    } else {
      return this.http.get(environment.platformBackendURL + '/contacts?name=' + contact_name);
    }
  }

  getScorecards(job_id): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/jobs/' + job_id + '/scorecards');
  }

  getFunctional(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources/functionalExpertise');
  }

  getSubFunctional(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources/subFunctionalExpertise');
  }

  getIndustries(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources/industries');
  }

  getLocations(parameter: string): Observable<any>{
    const body = {
      "input_text": parameter
    };
    return this.http.post(environment.djangoURL + '/geocoder/autocomplete/', body);
  }

  addCompany(company): Observable<any>{
    const body = {
      "company_name": company
    };
    return this.http.post(environment.platformBackendURL + '/companies', body);
  }

  addContact(company, user, email): Observable<any>{
    const body = {
      "company": {
        uuid: company
      },
      "name": user,
      "email": email
    };
    return this.http.post(environment.platformBackendURL + '/contacts', body);
  }

  postActivity(type, data, selectedCompanies, selectedContacts, selectedJobs, selectedCandidates): Observable<any>{
    const body = {
      type: type,
      data: data,
      companies: selectedCompanies,
      contacts: selectedContacts,
      jobs: selectedJobs,
      candidates: selectedCandidates
    };
    return this.http.post(environment.platformBackendURL + '/activities', body);
  }

  getResources(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources?type=currencies,bonus_type,fee_type');
  }

}
