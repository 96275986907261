import { OnInit, OnDestroy, Component, ViewChild, ElementRef } from '@angular/core';
import { NavigationEnd, Router } from "@angular/router";
import { Globals } from "app/globals";
import { ToastrService } from "ngx-toastr";
import { filter, takeWhile } from "rxjs";
import { GetNavisCommunicationService } from "../getnavis-service/get-navis-communication.service";
import { GetNavisSidebarService } from "./sidebar.service";
import { Angulartics2 } from 'angulartics2';
declare let $: any;

@Component({
  selector: 'app-getnavis-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class GetNavisSidebarComponent implements OnInit, OnDestroy{
  componentAlive = true;

  selectedJob: any = null;

  jobs: any = [];

  jobDropdownList: any = [];

  showJobsSubMenu = false;

  closedJobs = false;

  @ViewChild('sidebarToggle') toggleButtonEl: ElementRef;

  toggleButton = false;

  constructor(private dataService: GetNavisSidebarService,
    public globals: Globals,
    private route: Router,
    private communicationService: GetNavisCommunicationService,
    private toastr: ToastrService, private angulartics2: Angulartics2){

    this.communicationService.toggleSidebar$.pipe(
      takeWhile(() => this.componentAlive))
      .subscribe((data) => {
        this.toggleButton = data;
      });

  }

  ngOnDestroy(): void{
  }

  ngOnInit(){
    $.getScript('./assets/js/app-sidebar.js');
    if (this.route.parseUrl(this.route.url).root.children.primary.segments[1].toString() == 'job'){
      this.getJobs();
    }
    let routerSegment = this.route.parseUrl(this.route.url).root.children.primary.segments[1]?.path;
    this.setSidebarAppearance(routerSegment);
    this.route.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      routerSegment = this.route.parseUrl(this.route.url).root.children.primary.segments[1]?.path;
      this.setSidebarAppearance(routerSegment);
    });
  }

  setSidebarAppearance(routerSegment){
    if (routerSegment == 'candidates' && sessionStorage.getItem('filter-active-candidates') == 'true'){
      this.toggleButton = true;
      return true;
    }
    if (routerSegment == 'jobs' && sessionStorage.getItem('filter-active-jobs') == 'true'){
      this.toggleButton = true;
      return true;
    }
    this.toggleButton = false;
    return false;
  }

  getJobs(){
    if (!this.jobs.length){
      this.dataService.getJobs(this.closedJobs).subscribe(
        (data) => {
          if (data){
            this.jobs = data;
            this.checkIfJobIsPresentInUrl();
          }
        },
        (err) => {
          this.toastr.error(err);
        });
    }
  }

  checkIfJobIsPresentInUrl(){
    if (this.route.parseUrl(this.route.url).root.children.primary.segments[1].toString() == 'job'){
      const job_uuid = this.route.parseUrl(this.route.url).root.children.primary.segments[2].toString();
      this.selectedJob = this.jobs.find(item => item.uuid == job_uuid);
      this.emitJobSelected(this.selectedJob);
    }
  }

  emitJobSelected(job){
    if (job){
      this.showJobsSubMenu = true;
      this.communicationService.emitGetnavisJobSelected(job);
    }
  }

  onChange(item){
    if (item){
      const urlSegments = this.route.parseUrl(this.route.url).root.children.primary.segments;
      let url = '/getnavis/job/' + item.uuid;
      if (urlSegments[3]){
        url = url + '/' + urlSegments[3].toString();
      }
      this.route.navigate([url]);
      this.emitJobSelected(item);
    } else {
      this.selectedJob = null;
      this.showJobsSubMenu = false;
      this.route.navigate(['/getnavis/overview']);
    }
  }

  navigateTo(value){
    const mixpanelProperties = {
      navigate: value
    };
    this.insertMixPanelTracking('backoffice_vertical_menu_item_open_click_app', mixpanelProperties);
    if (value == '/getnavis/overview'){
      this.selectedJob = null;
      this.showJobsSubMenu = false;
    }
    this.route.navigate([value]);
  }

  navigateToJobComponents(value){
    this.route.navigate(['/getnavis/job/' + this.selectedJob.uuid + '/' + value]);
    this.emitJobSelected(this.selectedJob);
  }

  changeCloseJobs(){
    this.jobs = [];
    this.getJobs();
  }

  customSearch(term: string, item){
    term = term.toLowerCase();
    if ((item.title && item.title.toLowerCase().indexOf(term) > -1) ||
      (item.company.name && item.company.name.toLowerCase().indexOf(term) > -1)){
      return true;
    }
    return false;
  }

  toggleSidebar(){
    this.toggleButton = !this.toggleButton;
    this.communicationService.emitSidebarAppearance(this.toggleButton);
  }

  insertMixPanelTracking(action_title: string, properties: any){
    this.angulartics2.eventTrack.next({
        action: action_title,
        properties: properties
    });
  }
}
