import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateDiffTimestampFullNav'
})
export class DateDiffTimestampFullNavPipe implements PipeTransform{

  transform(date: any): any{
    return moment.unix(date).format('DD-MM-YYYY hh:mm');
  }

}
