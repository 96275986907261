<div class="full-width scroll scroll-filter mt-5">
  <div class="card-block">
    <ngb-accordion activeIds="details" id="filter-accordion" #acc="ngbAccordion">
      <ngb-panel id="details">
        <ng-template ngbPanelHeader>
          <div class="form" (click)="acc.toggle('details')">
            <div class="form-section">
              Details
              <i class="ft-filter tt-icon-sm"></i>
            </div>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="card-block">
            <div class="form-group mb-3">
              <div class="card-body-title-basic">
                <label>Title </label>
              </div>
              <div class="card-body-content">
                <div class="row">
                  <div class="col-md-12 col-lg-4">
                    <input placeholder="Enter title" class="form-control" multiple="true" [(ngModel)]="filters.title"
                      [ngModelOptions]="{standalone: true}" type="text"
                      (keyup.enter)="populateTitle(filters.title);filters.title = ''" />
                  </div>
                  <div class="col-md-12 col-lg-8">
                    <tag-input id="expertise-input" [(ngModel)]="tagInputTitle" [ngModelOptions]="{standalone: true}">
                    </tag-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>Company </label>
              </div>
              <div class="card-body-content">
                <ng-select [(ngModel)]="filters.company" bindValue="uuid" multiple="true" [items]="companys | async"
                  bindLabel="name" [typeahead]="companyinput" [hideSelected]="true" [loading]="companyLoading"
                  placeholder="Select company name" #select [hideSelected]="true"></ng-select>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>Contact </label>
              </div>
              <div class="card-body-content">
                <ng-select [(ngModel)]="filters.contact" multiple="true" bindValue="uuid" [items]="contacts"
                  (open)="loadContacts()" bindLabel="name" placeholder="Select contact name"></ng-select>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>Pod name </label>
              </div>
              <div class="card-body-content">
                <ng-select (open)="getPods()" multiple="true" bindLabel="name" bindValue="uuid" [items]="pods"
                  [(ngModel)]="filters.pod_name" placeholder="Select pod"></ng-select>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>Type </label>
              </div>
              <div class="card-body-content">
                <ng-select [items]="types" [(ngModel)]="filters.type" multiple="true" bindValue="value"
                  bindLabel="description" placeholder="Select type " (open)="getType()"></ng-select>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <div class="card-body-title-basic">
                    <label>Status </label>
                  </div>
                  <div class="card-body-content">
                    <ng-select (open)="getStatus()" multiple="true" bindValue="value" placeholder="Select status"
                      bindLabel="description" [items]="statuses" [(ngModel)]="filters.status"></ng-select>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <div class="card-body-title-basic">
                    <label>Priority </label>
                  </div>
                  <div class="card-body-content">
                    <ng-select (open)="getPriority()" multiple="true" bindValue="value" [(ngModel)]="filters.priority"
                      bindLabel="description" placeholder="Select priority" [items]="prioritys"></ng-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <div class="card-body-title-basic">
                    <label>Seniority </label>
                  </div>
                  <div class="card-body-content">
                    <ng-select (open)="getSeniority()" multiple="true" bindValue="value" [items]="seniorityes"
                      [(ngModel)]="filters.seniority" placeholder="Select seniority" bindLabel="description">
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <div class="card-body-title-basic">
                    <label>Industry </label>
                  </div>
                  <div class="card-body-content">
                    <ng-select (open)="getIndustry()" multiple="true" bindValue="value" [items]="industrys"
                      [(ngModel)]="filters.industry" placeholder="Select industry" bindLabel="description">
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>Head count </label>
              </div>
              <div class="card-body-content">
                <input class="form-control" type="number" pattern="[0-9]*" min="0"
                  (keydown)=preventNegativeNumbers($event) [(ngModel)]="filters.head_count" appOnFocusOut
                  placeholder="Insert head count" />
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>Location </label>
              </div>
              <div class="card-body-content">
                <ng-select [items]="location | async" multiple="true" bindValue="name" bindLabel="name"
                  [hideSelected]="true" [loading]="locationLoading" [typeahead]="locationinput"
                  [(ngModel)]="filters.location" placeholder="Select location">
                </ng-select>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <div class="card-body-title-basic">
                    <label>Functional Expertise </label>
                  </div>
                  <div class="card-body-content">
                    <ng-select (open)="getFee()" multiple="true" bindValue="value" [items]="fee"
                      [(ngModel)]="filters.fe" placeholder="Select functional expertise" bindLabel="description">
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <div class="card-body-title-basic">
                    <label>Sub Functional Expertise</label>
                  </div>
                  <div class="card-body-content">
                    <ng-select (open)="getSfe()" multiple="true" bindValue="value" [items]="sfe"
                      [(ngModel)]="filters.sfe" placeholder="Select sub functional expertise" bindLabel="description">
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body-title-basic">
              <label>Scorecards </label>
            </div>
            <div class="form-group card-body-content">
              <div class="row">
                <div class="col-md-12 col-lg-4">
                  <input class="form-control" multiple="true" bindValue="value" [(ngModel)]="filters.scorecards"
                    [ngModelOptions]="{standalone: true}" type="text" placeholder="Enter scordcards"
                    (keyup.enter)="populateScordCards(filters.scorecards);filters.scorecards = ''" />
                </div>
                <div class="col-md-12 col-lg-8">
                  <tag-input id="expertise-input" [(ngModel)]="tagInputScordCards"
                    [ngModelOptions]="{standalone: true}">
                  </tag-input>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>Description</label>
              </div>
              <div class="card-body-content">
                <textarea placeholder="Insert description " rows="3" class="form-control" type="text" [(ngModel)]="filters.description"
                appOnFocusOut> </textarea>
              </div>
            </div>
          </div>

        </ng-template>
      </ngb-panel>
      <ngb-panel id="compensation">
        <ng-template ngbPanelHeader>
          <div class="form"  (click)="acc.toggle('compensation')">
            <div class="form-section">
              Compensation
              <i class="ft-filter tt-icon-sm"></i>
            </div>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="card-block">
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>Currency </label>
              </div>
              <div class="card-body-content">
                <ng-select bindLabel="description" bindValue="value" (open)="getCurrency()" [items]="currencyes"
                  [(ngModel)]="filters.compensation.currency" placeholder="Select currency">
                </ng-select>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <div class="card-body-title-basic">
                    <label>Salary from </label>
                  </div>
                  <div class="card-body-content">
                    <input class="form-control" type="number" pattern="[0-9]*" min="0"
                      (keydown)=preventNegativeNumbers($event) [(ngModel)]="filters.compensation.salary_from"
                      [ngModelOptions]="{standalone: true}" placeholder="Enter salary from" />
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <div class="card-body-title-basic">
                    <label>Salary to </label>
                  </div>
                  <div class="card-body-content">
                    <input class="form-control" type="number" pattern="[0-9]*" min="0"
                      (keydown)=preventNegativeNumbers($event) [(ngModel)]="filters.compensation.salary_to"
                      [ngModelOptions]="{standalone: true}" placeholder="Enter salary to" />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>Annual salary </label>
              </div>
              <div class="card-body-content">
                <input (keydown)=preventNegativeNumbers($event) class="form-control" type="number" pattern="[0-9]*"
                  min="0" [(ngModel)]="filters.compensation.annual_salary" [ngModelOptions]="{standalone: true}"
                  placeholder="Enter annual to" />
              </div>
            </div>
            <div class="row">
              <div class="col-8">
                <div class="form-group">
                  <div class="card-body-title-basic">
                    <label>Bonus </label>
                  </div>
                  <div class="card-body-content">
                    <input (keydown)=preventNegativeNumbers($event) class="form-control" type="number" pattern="[0-9]*"
                      min="0" [(ngModel)]="filters.compensation.bonus" [ngModelOptions]="{standalone: true}"
                      placeholder="Enter bonus" />
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <div class="card-body-title-basic">
                    <label>Bonus type </label>
                  </div>
                  <div class="card-body-content">
                    <ng-select bindLabel="description" (open)="getbonusTypes()" bindValue="value" [items]="bonusTypes"
                      [(ngModel)]="filters.compensation.bonus_type" placeholder="Select bonus type"
                      bindLabel="description">
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>Equity </label>
              </div>
              <div class="card-body-content">
                <textarea appAutosize [(ngModel)]="filters.compensation.equity" [ngModelOptions]="{standalone: true}"
                  class="form-control" type="text" placeholder="Enter equity"></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-8">
                <div class="form-group">
                  <div class="card-body-title-basic">
                    <label>Fee </label>
                  </div>
                  <div class="card-body-content">
                    <input (keydown)=preventNegativeNumbers($event) class="form-control" type="number" pattern="[0-9]*"
                      min="0" [(ngModel)]="filters.compensation.fee" [ngModelOptions]="{standalone: true}"
                      placeholder="Enter fee" />
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <div class="card-body-title-basic">
                    <label>Fee type </label>
                  </div>
                  <div class="card-body-content">
                    <ng-select bindLabel="description" bindValue="value" (open)="getFeeType()" [items]="feeType"
                      [(ngModel)]="filters.compensation.fee_type" placeholder="Select bonus type"
                      bindLabel="description">
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </ng-template>
      </ngb-panel>
      <ngb-panel id="closedInfo">
        <ng-template ngbPanelHeader>
          <div class="form"  (click)="acc.toggle('closedInfo')">
            <div class="form-section">
              Closed info
              <i class="ft-filter tt-icon-sm"></i>
            </div>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="card-block">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <div class="card-body-title-basic">
                    <label>Closed info status</label>
                  </div>
                  <div class="card-body-content">
                    <ng-select (clear)="clear()" bindValue="value" bindLabel="description"
                      (open)="getClosedInfoStatus()" [items]="closedInfoStatus" [(ngModel)]="filters.closed_info.status"
                      placeholder="Select closed info status" bindLabel="description">
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <div class="card-body-title-basic">
                    <label>Closed info reason</label>
                  </div>
                  <div class="card-body-content">
                    <ng-select bindValue="value" bindLabel="name" (open)="getClosedInfoReason()"
                      [items]="closedInfoReason" [(ngModel)]="filters.closed_info.reason"
                      placeholder="Select closed info reason" bindLabel="description">
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
</div>
<div class="position-bottom full-width">
  <button (click)="searchJobs()" type="button" class="btn pull-right bg-secondary-color">
    Search jobs
  </button>
</div>
