import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class JobApplicationModalService{

    constructor(private httpClient: HttpClient){ }

    getJobs(closedJobs): Observable<any>{
        const url: string = closedJobs ? environment.getNavisBackendURL + '/resources/jobs' : environment.getNavisBackendURL + '/resources/jobs?type=open';
        return this.httpClient.get(url);
    }

    getStatuses(job_uuid){
        const url: string = environment.getNavisBackendURL + '/resources/application_statuses?job_uuid=' + job_uuid + '&type=shortlist';
        return this.httpClient.get(url);
    }

    getSubstatuses(next_status, current_status, jobUuid){
        const url: string = environment.getNavisBackendURL + '/resources/substatuses?next_status=' + next_status + '&job_uuid=' + jobUuid + (current_status ? '&current_status=' + current_status : '');
        return this.httpClient.get(url);
    }

    getResourcesByType(type){
        const url: string = environment.getNavisBackendURL + '/resources?type=' + type;
        return this.httpClient.get(url);
    }

    getArchetypes(job_uuid: string){
        const url: string = environment.getNavisBackendURL + '/resources/job_archetypes?job_uuid=' + job_uuid;
        return this.httpClient.get<any>(url);
    }

    getUsers(value, jobUUID): Observable<any>{
        return this.httpClient.get(environment.getNavisBackendURL + '/merger/users/' + value + '?job_uuid=' + jobUUID);
    }

    updateApplication(body): Observable<any>{
        return this.httpClient.put(environment.getNavisBackendURL + '/merger/candidates/applications', body);
    }

}