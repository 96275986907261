import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from 'app/globals';

@Component({
  selector: 'app-delete-contact-modal',
  templateUrl: './delete-contact-modal.component.html',
  styleUrls: ['./delete-contact-modal.component.scss']
})
export class DeleteContactModalComponent implements OnInit{

  constructor(public activeModal: NgbActiveModal, public globals: Globals){ }

  ngOnInit(){
  }

}
