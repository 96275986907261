import { Directive, Input, EventEmitter, ElementRef, Inject, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: '[appFocus]'
})
export class FocusDirective implements OnInit{
  @Input('appFocus') focusEvent: EventEmitter<boolean>;

  constructor(@Inject(ElementRef) private element: ElementRef, private renderer: Renderer2){
  }

  ngOnInit(){
    this.focusEvent.subscribe(() => {
      this.renderer.selectRootElement(this.element.nativeElement).focus();
    });
  }

}
