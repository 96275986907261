<div class="modal-content">
    <div class="modal-bg">
      <div class="modal-header">
        <div class="modal-header-title">
          Clear filters
        </div>
        <button type="button" aria-label="Close" class="close pull-right" (click)="activeModal.dismiss()">
            <span aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Close">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <div class="modal-body-title">
            *
            Email
            <span *ngIf="!data.primary_email" [ngClass]="{'primary-color': !data.primary_email}">
              (Please fill in this field)
            </span>
          </div>
          <div class="modal-body-content">
            <input type="email" #email="ngModel"
            class="form-control" id="email" name="email" placeholder="Insert email" email
            [(ngModel)]="data.primary_email" [ngModelOptions]="{standalone: true}" appOnFocusOut
            (focusout)="!data.primary_email ? toastr.warning('Email is mandatory') : ''"
            (ngModelChange)="changedEmail = true;">
          </div>
        </div>
        <div class="form-group">
          <div class="card-body-content">
            <strong>Please note, If you change the candidate email, the candidate would be added as "new candidate to the campaign".</strong>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn pull-right bg-secondary-color" *ngIf="data.primary_email && changedEmail" (click)="updateEmail(data.primary_email)">
          Change email</button>
        <button type="button" class="btn pull-right bg-primary-color"
          (click)="activeModal.dismiss()">Close</button>
      </div>
    </div>