import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Globals } from 'app/globals';
import { AddJobService } from './add-job.service';
import { ToastrService } from 'ngx-toastr';
import { distinctUntilChanged, debounceTime, switchMap, tap, catchError, filter } from 'rxjs/operators';
import { Subject, of, concat, Observable } from 'rxjs';
import { FileUploader } from 'ng2-file-upload';
import { CommunicationService } from 'app/shared/service/communication.service';
import { Router } from '@angular/router';

import { environment } from 'environments/environment';
import * as moment from 'moment';

@Component({
    selector: 'app-add-job',
    templateUrl: './add-job.component.html',
    styleUrls: ['./add-job.component.scss']
})
export class AddJobComponent implements OnInit{
    readonly minLengthTerm = 3;

    @Input() uuid: string;

    @Output() dismissDrawer: EventEmitter<boolean> = new EventEmitter<boolean>();

    badgeFlagCompensation = false;

    badgeFlagDetails = false;

    subscription: any;

    jobUUID = '';

    loadComponent = false;

    public uploader: FileUploader;

    public hasBaseDropZoneOver;

    loading = false;

    job: any = {};

    resources: any = [];

    opportunities: any = [];

    searchSources: any = [];

    jobType: any = [];

    fileType: any = [];

    seniority: any = [];

    fe: any = [];

    sfe: any = [];

    filteredSfe: any = [];

    industry: any = [];

    filteredContact: any = [];

    users: any = [];

    email: string = null;

    tmpUser: any = {
        user: null,
        role: null
    };

    tmpRoles: any = [];

    tmpFiles: any = {};

    currency: any = [];

    bonus: any = [];

    fee: any = [];

    status: any = [];

    priority: any = [];

    role: any = [];

    pods: any = [];

    arrayMessage: any;

    arrayType: any;

    errorAlert = false;

    companies$: Observable<any>;

    companyLoading = false;

    companyInput$ = new Subject<string>();

    locations$: Observable<any>;

    locationLoading = false;

    locationInput$ = new Subject<string>();

    public addTagCompany: (name) => void;

    public addTagContact: (name) => void;

    validForm = true;

    validFormContact = true;

    displayUsers: any = [];

    changed = false;

    disableButton = true;

    tagInputLocations: any = [];

    constructor(private router: Router, public globals: Globals, private jobService: AddJobService, private toastr: ToastrService, private communicationService: CommunicationService){
        this.addTagCompany = (name) => this.addCompany(name);
        this.addTagContact = (name) => this.addContact(name);
        const URL = environment.platformBackendURL + '/assets';
        this.uploader = new FileUploader({
            url: URL,
            isHTML5: true,
            method: 'POST',
            itemAlias: 'file',
            authTokenHeader: 'authorization',
            authToken: 'Bearer ' + localStorage.getItem('access-token')
        });
        this.uploader.onCompleteItem = (item: any, response: any, status: any) => {
            this.loading = false;
            const obj = JSON.parse(response);
            if (status === 201){
                let name = "";
                this.tmpFiles.uuid = obj.uuid;
                this.tmpFiles.url = obj.file_url;
                if (this.job.company && this.job.job_title){
                  name = this.generateFileName(this.job.company.name, this.job.job_title, this.tmpFiles.type.description, obj.original_name);
                } else {
                  this.toastr.error('You must enter company and job title');
                  return;
                }
                this.tmpFiles.file = obj;
                // this.tmpFiles.file.name = name;
                this.tmpFiles.original_name = name;
                this.job.files.push(this.tmpFiles);
                this.tmpFiles = {};
            } else {
                this.toastr.error('Something went wrong');
            }

            this.hasBaseDropZoneOver = false;
        };
        this.uploader.onAfterAddingFile = (file) => {
          file.withCredentials = false;
          if (this.job.company && this.job.job_title){
            file.file.name = this.generateFileName(this.job.company.name, this.job.job_title, this.tmpFiles.type.description, file.file.name);
          }
        };
        this.uploader.uploadAll();
    }

    // Angular2 File Upload
    public fileOverBase(e: any){
        this.hasBaseDropZoneOver = e;
        this.uploader.uploadAll();
    }

    preventNegativeNumbers(e: any){
        if (!((e.keyCode > 95 && e.keyCode < 106)
            || (e.keyCode > 47 && e.keyCode < 58)
            || e.keyCode == 8)){
            return false;
        }
        return true;
    }

    OnContentChange(event){
        if (event.target.value){
            this.loading = true;
            this.uploader.uploadAll();
        } else {
            this.loading = false;
        }
    }

    afterFileisDrop(){
        this.loading = true;
    }

    initArray(){
        this.job = {
            job_title: null,
            company: null,
            contact: null,
            description: null,
            type: null,
            status: null,
            priority: null,
            location: [],
            projected_length: 2,
            head_count: 1,
            fe: null,
            sfe: null,
            industry: null,
            seniority: null,
            search_source: null,
            affinity_opportunity_id: null,
            jobCompensation: {
                currency: null,
                salary_from: null,
                salary_to: null,
                annual_salary: null,
                bonus: null,
                bonus_type: null,
                equity: null,
                fee: null,
                fee_type: null
            },
            users: [],
            files: [],
            scorecards: [],
            pod: null
        };
    }

    create(){
        this.getResources();

        this.loadComponent = true;

        this.initArray();
        this.job.scorecards.push({
            "skill": ''
        });
        this.loadLocations();
        this.loadCompanies();
    }

    ngOnInit(){
        this.create();
    }

    loadCompanies(){
        this.companies$ = concat(
          of([]), // default items
          this.companyInput$.pipe(
            filter(res => res !== null && res.length >= this.minLengthTerm),
            distinctUntilChanged(),
            debounceTime(300),
            tap(() => this.companyLoading = true),
            switchMap(term => this.jobService.getCompany(term).pipe(
                catchError(() => of([])), // empty list on error
                tap(() => this.companyLoading = false)
              ))
          )
        );
    }

    loadLocations(){
        this.locations$ = concat(
          of([]), // default items
          this.locationInput$.pipe(
            filter(res => res !== null && res.length >= this.minLengthTerm),
            distinctUntilChanged(),
            debounceTime(300),
            tap(() => this.locationLoading = true),
            switchMap(term => this.jobService.getLocations(term).pipe(
                catchError(() => of([])), // empty list on error
                tap(() => this.locationLoading = false)
              ))
          )
        );
      }

    addCompany(company){
        return new Promise((resolve) => {
            this.jobService.addCompany(company)
                .subscribe((response) => {
                    resolve({ uuid: response.uuid, name: company, valid: true });
                    this.companyLoading = false;
                    this.toastr.success('Successfully added company');
                },
                    (err) => {
                        this.toastr.error(err.message);
                    });
        });
    }

    addContact(name){
        this.checkRequiredField();
        if (this.validFormContact){
            return new Promise((resolve) => {
                this.jobService.addContact(this.job.company.uuid, name, this.email)
                    .subscribe((response) => {
                        resolve({ uuid: response.uuid, name: name, valid: true });
                        this.toastr.success('Successfully added contact');
                        this.email = null;
                    },
                        (err) => {
                            if (err.error){
                                for (const errProp in err.error){
                                    if (err.error.hasOwnProperty(errProp)){
                                        if (err.error[errProp][0]){
                                            this.toastr.error(err.error[errProp]);
                                        }
                                    }
                                }
                            } else {
                                this.toastr.error('Something went wrong');
                            }
                        });
            });
        }
        return null;
    }

    filterContacts(company){
        this.jobService.getContacts(company)
            .subscribe((data) => {
                data ? this.filteredContact = data : this.filteredContact = [];
            },
                (err) => {
                    this.toastr.error(err.message);
                });
    }

    checkRequiredField(){
        if (!this.email)
            this.validFormContact = false;
    }

    getResources(){
        this.badgeFlagDetails = false;
        this.badgeFlagCompensation = false;
        this.loadComponent = true;

        this.jobService.getAffinityOpportunities().subscribe((data) => {
            this.opportunities = data.opportunities;
        });

        this.jobService.getResources()
            .subscribe((data) => {
                this.resources = data;
                if (this.resources.length){
                    this.getStatus();
                    this.getPriority();
                    this.getCurrency();
                    this.getBonus();
                    this.getFee();
                    this.getSeniority();
                    this.getJobType();
                    this.getFileType();
                    this.getFe();
                    this.getSfe();
                    this.getIndustry();
                    this.getPod();
                    this.getRole();
                    this.getInternalUsers();
                    this.getSearchSources();
                    if (this.job.fe){
                        this.filterSfe(this.job.fe);
                    }
                    this.loadComponent = true;
                }
            },
                (err) => {
                    this.toastr.error(err.message);
                }
            );
    }

    populateResources(type){
        const array = [];
        this.resources.forEach(element => {
            if (element.type == type){
                array.push(element);
            }
        });
        return array;
    }

    getStatus(){
        if (!this.status.length)
            this.status = this.populateResources('job_statuses');
    }

    getSearchSources(){
        if (!this.searchSources.length)
            this.searchSources = this.populateResources('search_sources');
    }

    getPriority(){
        if (!this.priority.length)
            this.priority = this.populateResources('job_priorities');
    }

    getCurrency(){
        if (!this.currency.length)
            this.currency = this.populateResources('currencies');
    }

    getBonus(){
        if (!this.bonus.length)
            this.bonus = this.populateResources('bonus_type');
    }

    getFee(){
        if (!this.fee.length)
            this.fee = this.populateResources('fee_type');
    }

    getSeniority(){
        if (!this.seniority.length)
            this.seniority = this.populateResources('role_seniority');
    }

    getJobType(){
        if (!this.jobType.length)
            this.jobType = this.populateResources('job_type');
    }

    getFileType(){
        if (!this.fileType.length)
            this.fileType = this.populateResources('job_files_type');
    }

    getFe(){
        if (!this.fe.length){
            this.jobService.getFunctionalExpertise()
                .subscribe((data) => {
                    this.fe = data;
                },
                    (err) => {
                        this.toastr.error(err.message);
                    });
        }
    }

    getSfe(){
        if (!this.sfe.length){
            this.jobService.getSubFunctionalExpertise()
                .subscribe((data) => {
                    this.sfe = data;
                    if (this.job.fe){
                        this.filterSfe(this.job.fe);
                    }
                },
                    (err) => {
                        this.toastr.error(err.message);
                    });
        }

    }

    filterSfe(value){
        const tempFilteredSfe = [];
        this.sfe.forEach(element => {
            if (element.fe_value == value.value){
                tempFilteredSfe.push(element);
            }
        });
        this.filteredSfe = tempFilteredSfe;
    }

    getIndustry(){
        if (!this.industry.length){
            this.jobService.getIndustries()
                .subscribe((data) => {
                    this.industry = data;
                },
                    (err) => {
                        this.toastr.error(err.message);
                    });
        }
    }

    getInternalUsers(){
        if (!this.users.length){

            this.jobService.getInternalUsers()
                .subscribe((data) => {
                    this.users = data;
                },
                    (err) => {
                        this.toastr.error(err.message);
                    });
        }
    }

    getRole(){
        if (!this.role.length){
            this.jobService.getRole()
                .subscribe((data) => {
                    this.role = data;
                },
                    (err) => {
                        this.toastr.error(err.message);
                    });
        }

    }

    getPod(){
        if (!this.pods.length){
            this.jobService.getPods()
                .subscribe((data) => {
                    this.pods = data;
                },
                    (err) => {
                        this.toastr.error(err.message);
                    });
        }
    }

    addUser(){
        if (this.tmpUser.user){
            if (this.tmpUser.role){
                this.tmpUser.user.roles = this.tmpUser.role;
                this.job.users.push(JSON.parse(JSON.stringify(this.tmpUser.user)));
                this.tmpUser = {
                    role: null,
                    user: null
                };
            } else {
                this.toastr.warning('Please select role');
            }
        } else {
            this.toastr.warning('Please select user');
        }

    }

    deleteUser(i){
        this.toastr.success('Successfully deleted user');
        this.job.users.splice(i, 1);
    }

    addNewScorecard(){
        this.job.scorecards.push({
            "skill": ''
        });
    }

    deleteScorecard(key, value, i){
        this.job.scorecards.splice(i, 1);
    }

    deleteFile(i){
        this.toastr.success('Successfully deleted file');
        this.job.files.splice(i, 1);
    }

    prevent($event){
        $event.stopPropagation();
    }

    checkRequiredFields(){
        let checkSalaryFromToAnnual = true;

        if (this.job.jobCompensation && this.job.jobCompensation.fee_type && this.job.jobCompensation.fee_type.value == "percentage"){
            if ((!this.job.jobCompensation.annual_salary && (!this.job.jobCompensation.salary_from || !this.job.jobCompensation.salary_to))){
                checkSalaryFromToAnnual = false;
                this.badgeFlagCompensation = true;
            } else { this.badgeFlagCompensation = false; }
        }

        if (this.job.head_count > 20){
            this.toastr.warning("Maximum value of head count is 20");
        }
        if (this.job.projected_length > 20){
            this.toastr.warning("Maximum value of projected length is 20");
        }

        if (!this.job.jobCompensation.fee && (this.job.type && (this.job.type.value == "executive_search" || this.job.type.value == "non-executive_search"))){
            checkSalaryFromToAnnual = false;
            this.badgeFlagCompensation = true;
        } else { this.badgeFlagCompensation = false; }

        if (!this.job.job_title || !this.job.company || !this.job.contact || !this.job.type || !this.job.projected_length || !this.job.head_count){
            this.validForm = false;
            this.disableButton = true;
            this.badgeFlagDetails = true;
        } else if ((this.job.type.value == "executive_search" || this.job.type.value == "non-executive_search") && !checkSalaryFromToAnnual){
            this.validForm = false;
            this.disableButton = true;
            this.badgeFlagCompensation = true;
            this.badgeFlagDetails = false;

        } else {
            this.badgeFlagDetails = false;
            this.badgeFlagCompensation = false;
            this.validForm = true;
        }

    }

    createJob(){
        this.checkRequiredFields();
        this.disableButton = true;
        if (this.validForm){
            if (this.job.scorecards.length == 1){
                if (!this.job.scorecards[0].skill){
                    this.job.scorecards = [];
                }
            }
            this.jobService.createJob(this.job)
                .subscribe((data) => {
                    this.toastr.success('Successfully created job');
                    this.communicationService.emitNewJob(data.uuid, this.job.job_title, this.job.fe);
                    this.email = null;
                    this.initArray();
                    this.job.scorecards.push({
                        "skill": ''
                    });
                    this.dismissDrawer.emit(true);
                    this.disableButton = true;
                },
                    (err) => {
                        this.loading = false;
                        this.arrayMessage = '';
                        this.arrayType = 'danger';
                        if (err.error){
                            for (const errProp in err.error){
                                if (err.error.hasOwnProperty(errProp)){
                                    if (err.error[errProp][0]){
                                        this.errorAlert = true;
                                        this.arrayMessage += err.error[errProp];
                                    }
                                }
                            }
                        } else {
                            this.arrayMessage = 'Something went wrong';
                        }
                        this.toastr.error(this.arrayMessage);
                        this.disableButton = true;
                    });
        }

    }

    onChange(){
        this.changed = true;
    }

    generateFileName(client: String, role: String, documentType: String, presentanionTitle){
      let name: string;
      const currentDay = moment(new Date()).format("YYMMDD");
    name = currentDay;

      if (!documentType.includes('Pitch')){
        name += "_" + client + "_" + role + "_" + documentType + presentanionTitle.substr(presentanionTitle.lastIndexOf('.'));
      } else {
        name += "_TBS_" + client + "_" + presentanionTitle;
      }
      name = name.replace(/ /g, '');
      return encodeURIComponent(name);
    }

    clearInput(){
      this.email = null;
    }
}
