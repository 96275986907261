<div class="modal-content">
    <div class="modal-bg">
      <div class="modal-header">
        <div class="modal-header-title">
          {{modalHeader}}
        </div>
        <button type="button" class="close pull-right" aria-label="Close" (click)="activeModal.dismiss()">
          <span aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="close">x</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="mb-0">{{modalDescription}}</p>
      </div>
      <div class="modal-footer">
        <button type="button"  class="col-md-3 btn btn-block bg-secondary-color"
          (click)="activeModal.close(false)">{{cancelButton}}</button>
        <button type="button" class="col-md-3 btn btn-block bg-primary-color"
          (click)="activeModal.close(true)">{{confirmButton}}</button>
      </div>
    </div>
  </div>