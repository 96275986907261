import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from 'app/globals';
import { ToastrService } from 'ngx-toastr';
import { GetnavisCandidateTriggerTypeModalService } from './getnavis-candidate-trigger-type-modal.service';

@Component({
  selector: 'app-getnavis-candidate-trigger-type-modal',
  templateUrl: './getnavis-candidate-trigger-type-modal.component.html',
  styleUrls: ['./getnavis-candidate-trigger-type-modal.component.scss']
})
export class GetnavisCandidateTriggerTypeModalComponent implements OnInit{

  data: any = {};

  triggers: any = {
    triggerType: 'campaign_trigger_type',
    triggerSubType: 'campaign_trigger_sub_type'
  };

  validForm = true;

  resources: any = {
    triggerType: [],
    triggerSubType: []
  };

  step: any = {};

  show: any = {
    stepUpdate: false
  };

  constructor(public activeModal: NgbActiveModal, public globals: Globals, private candidateTriggerTypeModalService: GetnavisCandidateTriggerTypeModalService, public toastr: ToastrService){ }

  ngOnInit(){
  }

  getKeyByValue(object, value){
    return Object.keys(object).find(key => object[key] === value);
  }

  getTriggers(type){
    if (!this.resources[this.getKeyByValue(this.triggers, type)].length){
      this.candidateTriggerTypeModalService.getTriggersResources(type).subscribe(
        (response) => {
          if (response){
            this.resources[this.getKeyByValue(this.triggers, type)] = response;
            if (this.resources[this.getKeyByValue(this.triggers, type)].length){
              this.checkStepTrigger();
            }
          }
        },
        (err) => {
          if (err.error){
            for (const errProp in err.error){
              if (err.error.hasOwnProperty(errProp)){
                if (err.error[errProp].length){
                  this.toastr.error(err.error[errProp]);
                }
              }
            }
          } else {
            this.toastr.error('Something went wrong');
          }
        }
      );
    } else {
      this.checkStepTrigger();
    }
  }

  checkStepTrigger(){
    if (this.resources.triggerType.length){
      const itemIndex = this.resources.triggerType.findIndex(item => item && item.value === 'after_days');
      if (itemIndex === -1 && this.resources.triggerSubType[0]){
        this.resources.triggerType = [...this.resources.triggerType, this.resources.triggerSubType[0]];
      }
    }
  }

  transformTime(campaign){
    if (campaign.time){
      campaign.trigger_time = (campaign.time.hour < 10 ? '0' + campaign.time.hour : campaign.time.hour) + ':' + (campaign.time.minute < 10 ? '0' + campaign.time.minute : campaign.time.minute);
      this.show.campaignUpdate = true;
    }
  }

  checkStepsRequiredFields(step){
    if (step.trigger_type && ((step.trigger_type.value == 'after_days' ? step.trigger_time >= 1 : step.trigger_time) || step.trigger_type.value == 'immediately')){
      this.validForm = true;
      return true;
    } else {
      this.validForm = false;
      return false;
    }
  }

  changeTriggerType(){
    if (this.checkStepsRequiredFields(this.step)){
      this.step.uuid = this.data.step;
      this.candidateTriggerTypeModalService.putCandidateTriggerType(this.data.campaign, this.data.candidate, this.step).subscribe(
        (response) => {
          if (response){
            this.activeModal.close(response);
          }
        },
        (err) => {
          if (err.error){
            for (const errProp in err.error){
              if (err.error.hasOwnProperty(errProp)){
                if (err.error[errProp].length){
                  this.toastr.error(err.error[errProp]);
                }
              }
            }
          } else {
            this.toastr.error('Something went wrong');
          }
        });
    }
  }

}
