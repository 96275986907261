import { takeWhile, distinctUntilChanged, debounceTime, switchMap, tap, catchError, filter } from 'rxjs/operators';
import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { Globals } from 'app/globals';
import { ToastrService } from 'ngx-toastr';
import { Subject, of, concat, Observable } from 'rxjs';
import { CandidatesFilterService } from './candidates-filter.service';
import { CommunicationService } from 'app/shared/service/communication.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-candidates-filter',
  templateUrl: './candidates-filter.component.html',
  styleUrls: ['./candidates-filter.component.scss']
})
export class CandidatesFilterComponent implements OnInit{
  readonly minLengthTerm = 3;

  @Output() dismissDrawer: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() data: any = {};

  componentAlive = true;

  filters: any = {};

  jobHistory: any = [];

  similarJobsFeSfe: any = [];

  categories: any = [];

  experience: any = {};

  education: any = {};

  language: any = {};

  expirienceLocation = [];

  dropdownResources: any = {
    fe: [],
    sfe: [],
    filteredSfe: [],
    seniority: [],
    state: [],
    degree: [],
    languagesLevel: [],
    similarJobs: [],
    openJobs: []
  };

  gender: any = [{ description: 'Female', value: 'FEMALE' }, { description: 'Male', value: 'MALE' }];

  keywords = '';

  locations$: Observable<any>;

  locationLoading = false;

  locationInput$ = new Subject<string>();

  languages$: Observable<any>;

  languageLoading = false;

  languageInput$ = new Subject<string>();

  hqLocationLoading = false;

  hqlocationinput = new Subject<any>();

  hqLocation: any = [];

  tagsLoading = false;

  tagsinput = new Subject<string>();

  tags: any = [];

  loading = false;

  showFilters = true;

  recommended_description = "The candidates are recommended and sorted based on a matching score in descending order, which means the best candidates will be at the top. The algorithm uses the following information from your search: <b>FE</b>, <b>SFE</b>, <b>Industry</b>, <b>Seniority</b>, <b>Compensation</b> and <b>Location</b>. To ensure the best results, populate as much of this data as possible. However if you update any data on a search, it might take up to one hour for the algorithm to recalculate the recommendation based on this new data.";

  activeIds: string[] = [];

  activeId: string[] = ['recommended_candidates'];

  @ViewChild('acc') acc: NgbAccordion;

  @ViewChild('subAcc') subAcc: NgbAccordion;

  constructor(public globals: Globals, private candidatesFilterService: CandidatesFilterService, private toastr: ToastrService, private communicationService: CommunicationService,
    private router: Router, private activatedRoute: ActivatedRoute){
    this.communicationService.clearCandidateFilters$.pipe(takeWhile(() => this.componentAlive))
      .subscribe((data) => {
        if (data){
          this.initComponent();
        }
      });
    router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe(event => {
      if (!event.url.startsWith('/candidates')){
        this.initComponent();
        this.dismissDrawer.emit(true);
      }
    });
  }

  initComponent(){
    this.activeIds = [];
    this.activeId = ['recommended_candidates'];
    this.initFilters();
  }

  ngOnInit(){
    this.filters = this.data;
    this.initExperience();
    this.getDealRoomCategories();
    this.loadTags();
    this.loadLocations();
    this.loadHqLocation();
    this.loadLanguages();
  }

  initFilters(){
    this.filters = {
      company_filters: {},
      candidates_filters: {
        experiences: {
          "current": [],
          "past": [],
          "all": []
        },
        educations: [],
        years_of_experience: {},
        international_exposure: null,
        is_vetted: null,
        in_other_process: null,
        languages: [],
        keywords: [],
        string_query: null,
        candidate_location: [],
        recycled: {
          value: null,
          days: null

        }
      },
      similar_jobs: [],
      recommended_job: null,
      offset: 0,
      accordion: {
        gender: false,
        vetted: false,
        in_process: false,
        location: false,
        experience: false,
        yearsExperience: false,
        language: false,
        education: false,
        keyword: false,
        company: true,
        candidate: false,
        jobs: false,
        open_to_roles: false
      }
    };
  }

  initExperience(){
    this.experience = {
      fe: null,
      sfe: [],
      seniority: null,
      location: [],
      state: null
    };
  }

  getJobs(){
    if (!this.dropdownResources.openJobs.length){
      this.candidatesFilterService.getOpenJobs()
        .subscribe((data) => {
          this.dropdownResources.openJobs = data;
        });
    }
  }

  getDealRoomCategories(){
    this.candidatesFilterService.getDealRoomCategories()
      .subscribe((data) => {
        this.categories = data;
      });
  }

  getFiltersperCategory(value, i){
    if (!this.categories[i].data.length){
      this.candidatesFilterService.getFiltersperCategory(value)
        .subscribe((data) => {
          this.categories[i].data = data[value];
        });
    }
  }

  getFe(){
    if (!this.dropdownResources.fe.length){
      this.candidatesFilterService.getFunctionalExpertise()
        .subscribe((data) => {
          this.dropdownResources.fe = data;
        });
      this.getSfe();
    }
  }

  getSfe(){
    if (!this.dropdownResources.sfe.length){
      this.candidatesFilterService.getSubFunctionalExpertise()
        .subscribe((data) => {
          this.dropdownResources.sfe = data;
        });
    }
  }

  filterSfe(id){
    this.dropdownResources.filteredSfe = this.dropdownResources.sfe.filter(sfe => sfe.fe_id == id);
  }

  getSeniority(){
    if (!this.dropdownResources.seniority.length){
      this.candidatesFilterService.getSeniority()
        .subscribe((data) => {
          this.dropdownResources.seniority = data;
        });
    }
  }

  loadLocations(){
    this.locations$ = concat(
      of([]), // default items
      this.locationInput$.pipe(
        filter(res => res !== null && res.length >= this.minLengthTerm),
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => this.locationLoading = true),
        switchMap(term => this.candidatesFilterService.getLocations(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.locationLoading = false)
          ))
      )
    );
  }

  loadLanguages(){
    this.languages$ = concat(
      of([]), // default items
      this.languageInput$.pipe(
        filter(res => res !== null && res.length >= this.minLengthTerm),
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => this.languageLoading = true),
        switchMap(term => this.candidatesFilterService.getLanguages(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.languageLoading = false)
          ))
      )
    );
  }

  loadHqLocation(){
    this.hqlocationinput.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => this.hqLocationLoading = true),
      switchMap(location => this.candidatesFilterService.getHqLocation(location).pipe(
          catchError(() => of([])),
          tap(() => {
            this.hqLocationLoading = false;
          })
        ))
    ).subscribe(res => {
      this.hqLocation = res.hq_locations;
    });
  }

  loadTags(){
    this.tagsinput.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => this.tagsLoading = true),
      switchMap(tags => this.candidatesFilterService.getTags(tags).pipe(
          catchError(() => of([])),
          tap(() => {
            this.tagsLoading = false;
          })
        ))
    ).subscribe(res => {
      this.tags = res.tags;
    });
  }

  clearGender(){
    delete this.filters.candidates_filters.delete;
  }

  getState(){
    if (!this.dropdownResources.state.length){
      this.candidatesFilterService.getResources('experience_state_type')
        .subscribe((data) => {
          this.dropdownResources.state = data;
        });
    }
  }

  getDegree(){
    if (!this.dropdownResources.degree.length){
      this.candidatesFilterService.getResources('degree_type')
        .subscribe((data) => {
          this.dropdownResources.degree = data;
        });
    }
  }

  getLanguagesLevel(){
    if (!this.dropdownResources.languagesLevel.length){
      this.candidatesFilterService.getLanguagesLevel()
        .subscribe((data) => {
          this.dropdownResources.languagesLevel = data;
        });
    }
  }

  populateKeywords(value){
    this.filters.candidates_filters.keywords.push(value);
  }

  populateExpirienceLocation(location){
    this.experience.location = location;
  }

  addExperience(experience){
    if (experience.state){
      if (experience.fe || experience.sfe.length || experience.seniority || experience.location.length){
        switch (experience.state.value){
          case 'current': {
            this.filters.candidates_filters.experiences.current.push(experience);
            break;
          }
          case 'past': {
            this.filters.candidates_filters.experiences.past.push(experience);
            break;
          }
          default: {
            this.filters.candidates_filters.experiences.all.push(experience);
            break;
          }
        }

        this.similarJobsFeSfe.push({
          fe: experience.fe,
          sfe: experience.sfe
        });
        this.getSimilarJobs(true);
        this.initExperience();
        this.expirienceLocation = [];
        this.experience.location = [];
      } else {
        this.toastr.warning("You must select additional info for the experience");
      }
    } else {
      this.toastr.warning("You must select state for the experience");
    }
  }

  deleteExperience(value, i){
    let item;
    switch (value){
      case 'current': {
        item = this.filters.candidates_filters.experiences.current[i];
        this.filters.candidates_filters.experiences.current.splice(i, 1);
        break;
      }
      case 'past': {
        item = this.filters.candidates_filters.experiences.past[i];
        this.filters.candidates_filters.experiences.past.splice(i, 1);
        break;
      }
      case 'all': {
        item = this.filters.candidates_filters.experiences.all[i];
        this.filters.candidates_filters.experiences.all.splice(i, 1);
        break;
      }
      default: {}
    }

    for (let index = 0; index < this.similarJobsFeSfe.length; index++){
      if (item.fe == this.similarJobsFeSfe[index].fe && item.sfe == this.similarJobsFeSfe[index].sfe){
        this.similarJobsFeSfe.splice(index, 1);
      }
    }
    this.getSimilarJobs(true);
  }

  addEducation(){
    this.filters.candidates_filters.educations = [];
    this.filters.candidates_filters.educations.push(this.education);
    this.education = {};
  }

  addLanguage(language, level){
    if (language && level){
      this.filters.candidates_filters.languages.push(this.language);
      this.language = {};
    } else {
      this.toastr.warning('You must select both language and language level');
    }
  }

  deleteLanguage(i){
    this.filters.candidates_filters.languages.splice(i, 1);
  }

  deleteJob(i){
    this.filters.similar_jobs = [...this.filters.similar_jobs.slice(0, i), ...this.filters.similar_jobs.slice(i + 1)];
  }

  removeDuplicates(array){
    return array.filter((arr, index) => index === array.findIndex(obj => JSON.stringify(obj) === JSON.stringify(arr)));
  }

  getSimilarJobs(populate){
    //next version - push into this.similarJobsFeSfe when selecting existing search
    let body = [];
    body = this.removeDuplicates(this.similarJobsFeSfe);
    if (populate){
      this.candidatesFilterService.getSimilarJobs(body)
        .subscribe((data) => {
          this.dropdownResources.similarJobs = data;
        });
    } else {
      if (!this.dropdownResources.similarJobs.length){
        this.candidatesFilterService.getSimilarJobs(body)
          .subscribe((data) => {
            this.dropdownResources.similarJobs = data;
          });
      }
    }
  }

  getSimilarJobsForExistingSearch(){
    this.filters.candidates_filters.experiences.all.forEach(element => {
      this.similarJobsFeSfe.push({
        fe: element.fe,
        sfe: element.sfe
      });
    });
    this.filters.candidates_filters.experiences.current.forEach(element => {
      this.similarJobsFeSfe.push({
        fe: element.fe,
        sfe: element.sfe
      });
    });
    this.filters.candidates_filters.experiences.past.forEach(element => {
      this.similarJobsFeSfe.push({
        fe: element.fe,
        sfe: element.sfe
      });
    });
  }

  saveAccordionsState(event){
    if (event.nextState){
      this.activeId.push(event.panelId);
    } else {
      this.activeId = this.activeId.filter(item => item !== event.panelId);
    }
  }

  saveSubAccordionsState(event){
    if (event.nextState){
      this.activeIds.push(event.panelId);
    } else {
      this.activeIds = this.activeIds.filter(item => item !== event.panelId);
    }
  }

  checkExp(){
    if (this.filters.candidates_filters.experiences.current.length ||
      this.filters.candidates_filters.experiences.all.length ||
      this.filters.candidates_filters.experiences.past.length){
      return true;
    } else {
      return false;
    }
  }

  preventNegativeNumbers(e: any){
    if (!((e.keyCode > 95 && e.keyCode < 106)
      || (e.keyCode > 47 && e.keyCode < 58)
      || e.keyCode == 110
      || e.keyCode == 190
      || e.keyCode == 8)){
      return false;
    }
    return true;
  }

  searchCandidates(){
    this.communicationService.emitCandidateFilters(this.filters);
    this.dismissDrawer.emit(true);
  }

}
