import { takeWhile } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { CommunicationService } from 'app/shared/service/communication.service';
import { Globals } from 'app/globals';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-filter-drawer',
  templateUrl: './filter-drawer.component.html',
  styleUrls: ['./filter-drawer.component.scss']
})
export class FilterDrawerComponent implements OnInit{
  componentAlive = true;

  uuid = '';

  data: any = [];

  width: number;

  constructor(private communicationService: CommunicationService, private router: Router, private activatedRoute: ActivatedRoute, public globals: Globals){
    this.communicationService.onChooseFilterDrawer$.pipe(takeWhile(() => this.componentAlive))
      .subscribe((data) => {
        if (data){
          this.data = data;
          const element = document.getElementById('filter-drawer');
          element.style.visibility = 'visible';
          element.classList.add('open');
        }
      });
  }

  ngOnInit(){
    $.getScript('assets/js/notification-sidebar.js');
    const element = document.getElementById('filter-drawer');
    element.style.visibility = 'hidden';
    this.width = document.body.clientWidth;
  }

  dismiss(){
    const element = document.getElementById('filter-drawer');
    element.style.visibility = 'hidden';
  }
}
