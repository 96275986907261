import { Component, OnInit } from '@angular/core';
import { Globals } from 'app/globals';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { UpdateApplicationModalService } from './update-application-modal.service';
import { Angulartics2 } from 'angulartics2';

@Component({
  selector: 'app-update-application-modal',
  templateUrl: './update-application-modal.component.html',
  styleUrls: ['./update-application-modal.component.scss']
})
export class UpdateApplicationModalComponent implements OnInit{
  applicationData: any;

  jobsInProcess: any;

  origin: string;

  inProcess = false;

  subStatuses = [];

  comment: any = null;

  reject_reason = {
    reason: null,
    description: null
  };

  rejectReasons = [];

  recycleCandidate = false;

  stages = [];

  originalData = null;

  index = null;

  valid = true;

  showRejectReason = false;

  longlistValid = false;

  candidate_rating: any;

  resources: any = {
    candidate_application_rating: []
  };

  constructor(public globals: Globals, private httpService: UpdateApplicationModalService,
    public activeModal: NgbActiveModal, public toastr: ToastrService, public angulartics2: Angulartics2){
    }

  ngOnInit(){
    this.inProcess = this.jobsInProcess;
    this.originalData = { ...this.applicationData };
    this.recycleCandidate = this.originalData.recycled_at ? this.originalData.recycled_at : false;
    this.getSubstatuses();
    if (this.originalData.substatus && (this.originalData.substatus.description.includes("Rejected") ||
        this.originalData.substatus.description.includes("Not Interested"))){
      this.showRejectReason = true;
    }
    if ("candidate_rating" in this.originalData){
      this.candidate_rating = this.originalData.candidate_rating;
    }
  }

  getSubstatuses(){
    if (!this.subStatuses.length){
      this.httpService.getSubStatuses().subscribe(
        (subStatuses) => {
          this.subStatuses = subStatuses;
          this.changeStage();
        },
        (err) => {
          if (err.error){
            for (const errProp in err.error){
              if (err.error.hasOwnProperty(errProp)){
                if (err.error[errProp][0]){
                  this.toastr.error(err.error[errProp]);
                }
              }
            }
          } else {
            this.toastr.error('Something went wrong');
          }
        });
    }
  }

  getStages(){
    if (!this.stages.length){
      this.httpService.getStages(this.applicationData.stage.value).subscribe(
        (data) => {
          this.stages = data;
        },
        (err) => {
          if (err.error){
            for (const errProp in err.error){
              if (err.error.hasOwnProperty(errProp)){
                if (err.error[errProp][0]){
                  this.toastr.error(err.error[errProp]);
                }
              }
            }
          } else {
            this.toastr.error('Something went wrong');
          }
        });
    }
  }

  changeStage(){
    if (this.applicationData.stage && this.applicationData.stage.value == 'longlist'){
      this.longlistValid = true;
      if (!this.applicationData.substatus){
        this.valid = false;
      }
    } else {
      this.valid = true;
    }
  }

  changeSubstatus(){
    this.valid = true;
    if (this.applicationData.stage && this.applicationData.stage.value == 'longlist'){
      if (!this.applicationData.substatus){
        this.valid = false;
      }
    }
    if (this.applicationData.substatus && (this.applicationData.substatus.description.includes("Rejected") ||
        this.applicationData.substatus.description.includes("Not Interested"))){
      this.showRejectReason = true;
    } else {
      this.reject_reason = {
        reason: null,
        description: null
      };
      this.recycleCandidate = false;
      this.showRejectReason = false;
    }
  }

  updateApplication(){
    if (this.valid){
      const body: any = {};
      if (this.originalData.stage && this.applicationData.stage){
        if (this.originalData.stage.value != this.applicationData.stage.value){
          body.stage = this.applicationData.stage;
        }
      }

      if (this.originalData.substatus && this.applicationData.substatus){
        if ((this.originalData.substatus.value != this.applicationData.substatus.value)){
          body.substatus = this.applicationData.substatus;
        }
      } else if (this.originalData.substatus != this.applicationData.substatus){
        body.substatus = this.applicationData.substatus;
      }

      if (this.reject_reason.reason){
        body.reject_reason = this.reject_reason;
      }
      if (this.comment){
        body.comment = this.comment;
      }
      if (this.showRejectReason){
        body.recycling_candidate = this.recycleCandidate;
      }
      if (this.candidate_rating){
        body.candidate_rating = this.candidate_rating;
      }
      if (Object.keys(body).length){
        this.httpService.updateApplication(body, this.applicationData.uuid).subscribe(
          (response) => {
            this.angulartics2.eventTrack.next({
              action: 'Candidate was added to recycle pool',
              properties: {
                data: this.applicationData,
                origin: this.origin,
                category: 'recycle candidate'
              }
            });

            let responseData = null;
            if (response.success){
              response.success.forEach(element => {
                if (element.hasOwnProperty('message')){
                  this.toastr.success(element.message);
                }
                if (element.hasOwnProperty('body')){
                  responseData = element.body;
                }
              });
              this.activeModal.close(responseData);
            }
          },
          (err) => {
            if (err.error){
              for (const errProp in err.error){
                if (err.error.hasOwnProperty(errProp)){
                  if (err.error[errProp][0]){
                    this.toastr.error(err.error[errProp]);
                  }
                }
              }
            } else {
              this.toastr.error('Something went wrong');
            }
            this.activeModal.dismiss();
          });
      } else {
        this.toastr.success("No changes were made");
        this.activeModal.dismiss();
      }
    } else {
      this.toastr.error('Please insert all required fields');
    }
  }

  changeRejectReason(){
    if (this.reject_reason.reason && this.reject_reason.reason.value !== "other"){
      this.reject_reason.description = null;
    }
  }

  getRejectReasons(){
    if (!this.rejectReasons.length){
      this.httpService.getRejectReasons().subscribe(
        (rejectReasons) => {
          this.rejectReasons = rejectReasons;
        },
        (err) => {
          if (err.error){
            for (const errProp in err.error){
              if (err.error.hasOwnProperty(errProp)){
                if (err.error[errProp][0]){
                  this.toastr.error(err.error[errProp]);
                }
              }
            }
          } else {
            this.toastr.error('Something went wrong');
          }
        });
    }
  }

  getResourcesByType(value){
    if (!this.resources[value].length){
      this.httpService.getResources(value).subscribe((data) => {
        if (data.length){
          this.resources[value] = data;
        }
      });
    }
  }

}
