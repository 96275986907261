import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'filterContactArray'
})
export class FilterContactArrayPipe implements PipeTransform{
    transform(items: any[], filter: string): any{
        if (!items || !filter){
            return items;
        }
        return items.filter(item => item.type.value == filter);

    }
}