<!-- Sidebar Header starts -->
<div class="sidebar-header">
  <div class="logo clearfix" [ngClass]="{'logo-collapsed': toggleButton}">
    <a (click)="navigateTo('/getnavis/overview')" class="logo-text float-left header">
      <div class="logo-img">
        <img src="assets/icons/logo/getnavis_logo.svg" alt="logo" class="logo-full" [ngClass]="{'visible': !toggleButton, 'invisible': toggleButton}">
        <img src="assets/icons/logo/getnavis_logo_N.svg" alt="logo" class="logo-min" [ngClass]="{'visible': toggleButton, 'invisible': !toggleButton}">
      </div>
    </a>
    <a class="nav-toggle d-none d-sm-none d-md-none d-lg-block header" id="sidebarToggle" #sidebarToggle href="javascript:;" data-toggle="tooltip" data-placement="bottom" title="collapse menu"
      (click)="toggleSidebar()">
      <i class="toggle-icon ft-toggle-right" data-toggle="collapsed" *ngIf="toggleButton"></i>
      <i class="toggle-icon ft-toggle-left" data-toggle="expanded" *ngIf="!toggleButton"></i>
    </a>
    <a class="nav-close d-block d-md-block d-lg-none d-xl-none header" id="sidebarClose" href="javascript:;">
      <i class="ft-x"></i>
    </a>
  </div>
</div>
<!-- Sidebar Header Ends -->
<!-- Sidebar Content starts -->
<div class="sidebar-content" [ngClass]="{'sidebar-collapsed-mt': toggleButton}">
  <div class="dropdown-jobs" id="select-job" *ngIf="!toggleButton">
    <ng-select id="ng-select" bindLabel="title" dropdownPosition="bottom" placeholder="Select job" [items]="jobs" [searchFn]="customSearch" (open)="getJobs()" [(ngModel)]="selectedJob"
      [ngModelOptions]="{standalone: true}" (change)="onChange(selectedJob)">
      <ng-template ng-option-tmp let-item="item" let-index="i">
        <div class="media mb-1">
          <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle" [src]="item.company.photo ? item.company.photo : globals.companyAvatar"
            (error)="item.images = globals.companyAvatar" />
          <div class="media-body">
            <div class="media-body-title mt-1 mb-0">{{item.title}}</div>
            <div class="media-body-title mt-1 mb-0">{{item.company.name}}</div>
          </div>
        </div>
      </ng-template>
      <ng-template ng-tag-tmp let-search="searchTerm">
        <button class="btn bg-secondary-color btn-position pull-right mb-0">Add company
          {{search}}</button>
      </ng-template>
    </ng-select>
  </div>
  <ul class="navigation pl-1" *ngIf="showJobsSubMenu">
    <li>
      <a routerLink="/getnavis/job/{{selectedJob.uuid}}/longlist" (click)="navigateToJobComponents('longlist')">
        <i class="icon-ttcandidates"></i>
        <span class="menu-title">Market Mapping</span>
      </a>
    </li>
    <li>
      <a routerLink="/getnavis/job/{{selectedJob.uuid}}/target-companies" (click)="navigateToJobComponents('target-companies')">
        <i class="icon-ttrpo"></i>
        <span class="menu-title">Target Companies</span>
      </a>
    </li>
    <li>
      <a routerLink="/getnavis/job/{{selectedJob.uuid}}/shortlist" (click)="navigateToJobComponents('shortlist')">
        <i class="icon-ttunion-3"></i>
        <span class="menu-title">Engage</span>
      </a>
    </li>
  </ul>
  <ul class="navigation position-bottom full-width">
    <li>
      <a routerLink="/getnavis/hiring-companies" (click)="navigateTo('/getnavis/hiring-companies')">
        <i class="icon-ttcompany"></i>
        <span class="menu-title">Hiring companies</span>
      </a>
    </li>
    <li>
      <a routerLink="/getnavis/consultant-companies" (click)="navigateTo('/getnavis/consultant-companies')">
        <i class="icon-ttcompany"></i>
        <span class="menu-title">Consultant companies</span>
      </a>
    </li>
    <li>
      <a routerLink="/getnavis/consultants" (click)="navigateTo('/getnavis/consultants')">
        <i class="icon-ttcontacts"></i>
        <span class="menu-title">Consultants</span>
      </a>
    </li>
    <li>
      <a routerLink="/getnavis/jobs" (click)="navigateTo('/getnavis/jobs')">
        <i class="icon-ttcareer"></i>
        <span class="menu-title">Filter jobs</span>
      </a>
    </li>
    <li>
      <a routerLink="/getnavis/candidates" (click)="navigateTo('/getnavis/candidates')">
        <i class="icon-ttcandidates"></i>
        <span class="menu-title">Filter candidates</span>
      </a>
    </li>
    <li>
      <a routerLink="/getnavis/campaigns" (click)="navigateTo('/getnavis/campaigns')">
        <i class="icon-ttmail"></i>
        <span class="menu-title">Campaigns</span>
      </a>
    </li>
  </ul>
</div>