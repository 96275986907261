<div *ngIf="loading" class="loading-view">
  <img [src]="globals.loading" class="loading-element" />
</div>
<div class="media m-4">
  <img class="media-object d-flex height-70 width-70 rounded-circle"
    [src]="contact.photo ? contact.photo : globals.avatar" (error)="contact.photo = globals.avatar">
  <div class="media-body ml-3">
    <div class="media-body-title form-group display-inline-flex mb-2">
      <span class="align-self-center">{{contact.name}}</span>
      <a *ngIf="contact.linkedin" class="fa fa-linkedin-square secondary-color tt-icon-lg ml-4 align-self-center"
        href="{{contact.linkedin}}" target="_blank" aria-hidden="true"></a>
      <a *ngIf="contact.facebook" class="fa fa-facebook-square secondary-color tt-icon-lg ml-2 align-self-center"
        href="{{contact.facebook}}" target="_blank" aria-hidden="true"></a>
      <a *ngIf="contact.twitter" class="fa fa-twitter-square secondary-color tt-icon-lg ml-2 align-self-center"
        href="{{contact.twitter}}" target="_blank" aria-hidden="true"></a>
      <li class="nav-item" ngbDropdown placement="bottom-right" id="contact_actions"
        *ngIf="globals.checkIfExists('permission', 'delete_contacts')">
        <a class="nav-link position-relative dropdown-color" id="dropdownBasic3" ngbDropdownToggle>
          Actions
        </a>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic3" class="card-shadow">
          <a class="dropdown-item py-1 dropdown-color" (click)="deleteContact()"
            *ngIf="globals.checkIfExists('permission', 'delete_contacts')">
            <i class="tt-icon-sm fa fa-trash mr-2"></i>
            <span>Delete contact</span>
          </a>
        </div>
      </li>
    </div>
    <div class="media-body-content">
      <div class="form-group" *ngIf="contact.job_title || (contact.company && contact.company.uuid)">
        {{contact.job_title}}
        {{contact.company && contact.company.uuid ? '@ ' + contact.company.name : ''}}
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group" *ngIf="contact.email">
            <i class="fa fa-envelope"></i>
            {{contact.email}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<nav ngbNav #nav="ngbNav" class="full-width nav-tabs" id="tab-shadow">
  <ng-container [ngbNavItem]="'ngb-tab-0'">
    <a ngbNavLink>Details</a>
    <ng-template ngbNavContent>
      <div class="card card-top scroll scroll-tabs">
        <ngb-accordion id="filter-accordion" [(activeIds)]="activeIds" #accordionDetails="ngbAccordion"
          (panelChange)="preventDefault ? beforeChange($event) : ''">
          <ngb-panel id="basic">
            <ng-template ngbPanelHeader>
              <div class="form" (click)="accordionDetails.toggle('basic');accordion.basic = !accordion.basic">
                <div class="form-section">
                  Basic details
                  <a *ngIf="globals.checkIfExists('permission', 'edit_candidates')" (click)="editFields('basic')">
                    <i class="tt-icon-sm icon-note" data-toggle="tooltip" data-placement="bottom"
                      [title]="globals.labels.tooltips.global.edit"></i>
                  </a>
                  <i class="pull-right mt-1 pt-2"
                    [ngClass]="{'ft-chevron-right': !accordion.basic,'ft-chevron-down': accordion.basic}"></i>
                </div>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div *ngIf="edit.basic">
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <div class="media">
                        <div class="hvrbox height-70 width-70">
                          <a (click)="fileInput.click()" data-toggle="tooltip" data-placement="top"
                            [title]="globals.labels.tooltips.global.choose_image">
                            <img class="media-object d-flex height-70 width-70 rounded-circle"
                              [src]="contact.photo ? contact.photo : globals.avatar"
                              (error)="contact.photo = globals.avatar">
                            <div class="hvrbox-layer_top">
                              <div class="hvrbox-text">
                                {{globals.labels.placeholders.full_layout_page.solve_component.image}}</div>
                            </div>
                          </a>
                          <span
                            style="visibility: hidden; position: absolute; overflow: hidden; width: 0; height:0;border:none; margin:0; padding:0">
                            <input type="file" #fileInput ng2FileSelect [uploader]="uploader"
                              (change)="OnContentChange($event)" />
                          </span>
                        </div>
                        <div class="media-body align-self-center">
                          <div class="media-body-title">
                            <span class="align-self-center">or</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="form-group">
                      <div class="card-body-title-basic">
                        <label>
                          Photo URL
                        </label>
                      </div>
                      <div class="card-body-content">
                        <input type="text" class="form-control" placeholder="Insert photo url" [(ngModel)]="contact.photo"
                          [ngModelOptions]="{standalone: true}" appOnFocusOut
                          (focusout)="changed ? editContact('photo',contact.photo , contact.uuid) : ''"
                          (ngModelChange)="onChange($event)">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <div class="card-body-title-basic">
                        <label>
                          * Name
                        </label>
                      </div>
                      <div class="card-body-content">
                        <input type="text" class="form-control" placeholder="Insert name" [(ngModel)]="contact.name"
                          [ngModelOptions]="{standalone: true}" appOnFocusOut
                          (focusout)="changed && contact.name ? editContact('name',contact.name , contact.uuid): contact.name ? '' : toastr.warning('Name is mandatory')"
                          (ngModelChange)="onChange($event)">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <div class="card-body-title-basic">
                        <label>
                          * Email
                        </label>
                      </div>
                      <div class="card-body-content">
                        <input type="email" class="form-control" name="email" placeholder="Insert email"
                          [(ngModel)]="contact.email" [ngModelOptions]="{standalone: true}" appOnFocusOut
                          (focusout)="changed && contact.email ? editContact('email',contact.email , contact.uuid) : (!contact.email ? toastr.warning('Name is mandatory') :toastr.warning('Invalid email'))"
                          (ngModelChange)="onChange($event)">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <div class="card-body-title-basic">
                        <label>
                          Title
                        </label>
                      </div>
                      <div class="card-body-content">
                        <input type="text" class="form-control" placeholder="Insert title" [(ngModel)]="contact.job_title"
                          [ngModelOptions]="{standalone: true}" appOnFocusOut
                          (focusout)="changed ? editContact('job_title',contact.job_title , contact.uuid) : ''"
                          (ngModelChange)="onChange($event)">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <div class="card-body-title-basic">
                        <label>
                          * Company
                        </label>
                      </div>
                      <div class="card-body-content">
                        <ng-select [items]="company | async" #select [hideSelected]="true" [addTag]="addTagCompany"
                          bindLabel="name" [loading]="companyLoading" [typeahead]="companyinput"
                          [(ngModel)]="contact.company" [clearable]="false"
                          [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.company"
                          (change)="changed && contact.company ? editContact('company', contact.company , contact.uuid) : contact.company ? '' : toastr.warning('Company is mandatory')"
                          (ngModelChange)="onChange($event)">
                          <ng-template ng-option-tmp let-item="item" let-index="i">
                            <div class="media mb-1">
                              <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                                [src]="item.photo ? item.photo : globals.companyAvatar"
                                (error)="item.photo = globals.companyAvatar" />
                              <div class="media-body">
                                <div class="media-body-title mt-1 mb-0">{{item.name}}</div>
                                <p><i *ngIf="item.location"
                                    class="tt-icon-sm icon-ttrelocation"></i>{{item.location ? item.location : globals.labels.placeholders.shared_page.add_job.empty_location}}
                                </p>
                              </div>
                            </div>
                          </ng-template>
                          <ng-template ng-tag-tmp let-search="searchTerm">
                            <button
                              class="btn bg-secondary-color btn-position pull-right mb-0">{{globals.labels.placeholders.shared_page.add_job.add_company}}
                              {{search}}</button>
                          </ng-template>
                        </ng-select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 col-12">
                    <div class="form-group">
                      <div class="card-body-title-basic">
                        <label>
                          Linkedin
                        </label>
                      </div>
                      <div class="card-body-content">
                        <input type="text" class="form-control" placeholder="Insert Linkedin" [(ngModel)]="contact.linkedin"
                          [ngModelOptions]="{standalone: true}" appOnFocusOut
                          (focusout)="changed ? editContact('linkedin',contact.linkedin , contact.uuid) : ''"
                          (ngModelChange)="onChange($event)">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-12">
                    <div class="form-group">
                      <div class="card-body-title-basic">
                        <label>
                          Twitter
                        </label>
                      </div>
                      <div class="card-body-content">
                        <input type="text" class="form-control" placeholder="Insert twitter" [(ngModel)]="contact.twitter"
                          [ngModelOptions]="{standalone: true}" appOnFocusOut
                          (focusout)="changed ? editContact('twitter',contact.twitter , contact.uuid) : ''"
                          (ngModelChange)="onChange($event)">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-12">
                    <div class="form-group">
                      <div class="card-body-title-basic">
                        <label>
                          Facebook
                        </label>
                      </div>
                      <div class="card-body-content">
                        <input type="text" class="form-control" placeholder="Insert facebook" [(ngModel)]="contact.facebook"
                          [ngModelOptions]="{standalone: true}" appOnFocusOut
                          (focusout)="changed ? editContact('facebook',contact.facebook , contact.uuid) : ''"
                          (ngModelChange)="onChange($event)">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 col-12">
                    <div class="form-group">
                      <div class="card-body-title-basic">
                        <label>
                          Skype
                        </label>
                      </div>
                      <div class="card-body-content">
                        <input type="text" class="form-control" placeholder="Insert skype" [(ngModel)]="contact.skype"
                          [ngModelOptions]="{standalone: true}" appOnFocusOut
                          (focusout)="changed ? editContact('skype',contact.skype , contact.uuid) : ''"
                          (ngModelChange)="onChange($event)">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-12">
                    <div class="form-group">
                      <div class="card-body-title-basic">
                        <label>
                          Phone
                        </label>
                      </div>
                      <div class="card-body-content">
                        <input type="text" class="form-control" placeholder="Insert phone" [(ngModel)]="contact.phone"
                          [ngModelOptions]="{standalone: true}" appOnFocusOut
                          (focusout)="changed ? editContact('phone', contact.phone, contact.uuid) : ''"
                          (ngModelChange)="onChange($event)">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-12">
                    <div class="form-group">
                      <div class="card-body-title-basic">
                        <label>
                          Mobile
                        </label>
                      </div>
                      <div class="card-body-content">
                        <input type="text" class="form-control" placeholder="Insert mobile" [(ngModel)]="contact.mobile"
                          [ngModelOptions]="{standalone: true}" appOnFocusOut
                          (focusout)="changed ? editContact('mobile',contact.mobile , contact.uuid) : ''"
                          (ngModelChange)="onChange($event)">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <div class="card-body-title-basic">
                        <label>
                          Note
                        </label>
                      </div>
                      <div class="card-body-content">
                        <textarea class="form-control" placeholder="Insert note" [(ngModel)]="contact.note"
                          [ngModelOptions]="{standalone: true}" appOnFocusOut
                          (focusout)="changed ? editContact('note',contact.note , contact.uuid) : ''"
                          (ngModelChange)="onChange($event)"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="!edit.basic">
                <div class="row card-block">
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <div class="card-body-title-basic">
                        <label>
                          Name
                        </label>
                      </div>
                      <div class="card-body-content">
                        {{contact.name ? contact.name : 'No information available'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <div class="card-body-title-basic">
                        <label>
                          Email
                        </label>
                      </div>
                      <div class="card-body-content">
                        {{contact.email ? contact.email : 'No information available'}}
                      </div>
                      </div>

                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <div class="card-body-title-basic">
                        <label>
                          Title
                        </label>
                      </div>
                      <div class="card-body-content">
                        {{contact.job_title ? contact.job_title : 'No information available'}}
                      </div>
                      </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <div class="card-body-title-basic">
                        <label>
                          Company
                        </label>
                      </div>
                      <div class="card-body-content">
                        {{contact.company_data ? contact.company_data.name : 'No information available'}}
                      </div>
                      </div>

                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <div class="card-body-title-basic">
                        <label>
                          Twitter
                        </label>
                      </div>
                      <div class="card-body-content">
                        {{contact.twitter ? contact.twitter : 'No information available'}}
                      </div>
                      </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <div class="card-body-title-basic">
                        <label>
                          Facebook
                        </label>
                      </div>
                      <div class="card-body-content">
                        {{contact.facebook ? contact.facebook : 'No information available'}}
                      </div>
                      </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <div class="card-body-title-basic">
                        <label>
                          Skype
                        </label>
                      </div>
                      <div class="card-body-content">
                        {{contact.skype ? contact.skype : 'No information available'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <div class="card-body-title-basic">
                        <label>
                          Phone
                        </label>
                      </div>
                      <div class="card-body-content">
                        {{contact.phone ? contact.phone : 'No information available'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <div class="card-body-title-basic">
                        <label>
                          Mobile
                        </label>
                      </div>
                      <div class="card-body-content">
                        {{contact.mobile ? contact.mobile : 'No information available'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <div class="card-body-title-basic">
                        <label>
                          Note
                        </label>
                      </div>
                      <div class="card-body-content">
                        {{contact.note ? contact.note : 'No information available'}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="jobs">
            <ng-template ngbPanelHeader>
              <div class="form" (click)="accordionDetails.toggle('jobs');accordion.jobs = !accordion.jobs">
                <div class="form-section">
                  Jobs
                  <i class="pull-right mt-1 pt-2"
                  [ngClass]="{'ft-chevron-right': !accordion.jobs,'ft-chevron-down': accordion.jobs}"></i>
                </div>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="card-block">
                <div class="row">
                  <div class="card-body-title-basic">
                    <label>
                      Open jobs
                    </label>
                  </div>
                </div>
                <div *ngFor="let job of openJobs">
                  <div class="row">
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>
                            Title
                          </label>
                        </div>
                        <div class="card-body-content">
                          {{job.job_title ? job.job_title : 'No information available'}}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>
                            Company
                          </label>
                        </div>
                        <div class="card-body-content">
                          {{job.company ? job.company : 'No information available'}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="card-body-title-basic">
                    <label>
                      Close jobs
                    </label>
                  </div>
                </div>
                <div *ngFor="let job of closeJobs">
                  <div class="row">
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>
                            Title
                          </label>
                        </div>
                        <div class="card-body-content">
                          {{job.job_title ? job.job_title : 'No information available'}}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>
                            Company
                          </label>
                        </div>
                        <div class="card-body-content">
                          {{job.company ? job.company : 'No information available'}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </ng-template>
  </ng-container>
  <ng-container [ngbNavItem]="'ngb-tab-1'">
    <a ngbNavLink>Activity</a>
    <ng-template ngbNavContent>
      <div class="card card-top scroll">
        <div *ngIf="loadingActivity" class="loading-view">
          <img [src]="globals.loading" class="loading-element" />
        </div>
        <div class="card-block" *ngIf="!loadingActivity">
          <div class="card-body">
            <div *ngIf="activities.length; then showActivity else hideActivity;"></div>
            <ng-template #hideActivity>
              <div class="media pl-3 pt-4">
                <div class="media-body">
                  <div class="media-body-title">
                    There is no activity for this contact
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template #showActivity>
              <div class="timeline-centered full-width">
                <article class="timeline-entry" *ngFor="let activity of activities">
                  <div class="timeline-entry-inner" *ngIf="activity">
                    <div class="timeline-icon">
                      <i class="fa fa-circle big-search-primary-color"></i>
                    </div>
                    <div class="timeline-label">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="timeline-body mr-4" *ngIf="activity.title" [innerHTML]="activity.title"></div>
                          <span
                            class="timeline-time-ago">{{activity.created_date | dateDiffNav:activity.created_date }}</span>
                          <div class="timeline-body mr-4" *ngIf="activity.body">
                            <p class="timeline-body-activity p-2" [innerHTML]="activity.body"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
                <article class="timeline-entry begin">
                  <div class="timeline-entry-inner">
                    <div class="timeline-icon"
                      style="-webkit-transform: rotate(-90deg); -moz-transform: rotate(-90deg);">
                      <i class="fa fa-circle" style="color:#e5e5e5 !important;"></i>
                    </div>
                  </div>
                </article>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</nav>
<div [ngbNavOutlet]="nav"></div>
