import { Injectable } from '@angular/core';
import { Globals } from 'app/globals';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class CalibrationListDataService{

    constructor(private httpClient: HttpClient, private global: Globals){ }

    getCalibrationList(jobId): Observable<any>{
        return this.httpClient.get(environment.platformBackendURL + '/calibration/' + jobId);
    }

    saveCandidateNote(uuid, noteData): Observable<any>{
        return this.httpClient.put(environment.platformBackendURL + '/calibration/candidates/' + uuid, noteData);
    }

    saveCandidateFeedback(uuid, feedbackData): Observable<any>{
        return this.httpClient.post(environment.platformBackendURL + '/calibration/candidates/' + uuid + '/feedback', feedbackData);
    }

    saveCalibrationListOrder(id, data): Observable<any>{
        return this.httpClient.put(environment.platformBackendURL + '/calibration/candidates/' + id + '/order', data);
    }

    editCalibration(uuid, title): Observable<any>{
        const body = {
            "title": title
        };
        return this.httpClient.put(environment.platformBackendURL + '/calibration/' + uuid, body);
    }

    deleteCalibration(uuid): Observable<any>{
        return this.httpClient.delete(environment.platformBackendURL + '/calibration/' + uuid);
    }

    deleteCandidate(uuid): Observable<any>{
        return this.httpClient.delete(environment.platformBackendURL + '/calibration/candidates/' + uuid);
    }
}
