import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from 'app/globals';
import { ToastrService } from 'ngx-toastr';
import { BlacklistCandidateModalService } from './blacklist-candidate-modal.service';

@Component({
  selector: 'app-blacklist-candidate-modal',
  templateUrl: './blacklist-candidate-modal.component.html',
  styleUrls: ['./blacklist-candidate-modal.component.scss']
})
export class BlacklistCandidateModalComponent implements OnInit{
  data: any = {};

  constructor(private blacklistCandidateModalService: BlacklistCandidateModalService, public globals: Globals, public activeModal: NgbActiveModal, private toastr: ToastrService){ }

  ngOnInit(){
  }

  blacklistCandidate(){
    const body = {
      candidate_uuid : this.data.uuid,
      reason: this.data.reason
    };
    this.blacklistCandidateModalService.blacklistCandidate(body).subscribe(
      (response) => {
        if (response){
          this.toastr.success(response.message);
          this.activeModal.close(true);
        }
      },
      (err) => {
        if (err.error){
          for (const errProp in err.error){
            if (err.error.hasOwnProperty(errProp) && err.error[errProp][0]){
              this.toastr.error(err.error[errProp]);
            }
          }
        } else {
          this.toastr.error('Something went wrong');
        }
      });

  }

}
