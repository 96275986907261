import { Component, Input, OnInit } from '@angular/core';
import { Angulartics2 } from 'angulartics2';

@Component({
  selector: 'app-hubspot',
  templateUrl: './hubspot.component.html',
  styleUrls: ['./hubspot.component.scss']
})
export class HubspotComponent implements OnInit{
  @Input() data: any = {};

  @Input() clickable = false;

  @Input() origin = null;

  constructor(private angulartics2: Angulartics2){ }

  ngOnInit(): void{
  }

  navigateUrl(){
    this.angulartics2.eventTrack.next({
      action: 'hubspot_navigate_click_app',
      properties: {
        navigateURL: this.data.link
      }
    });
    window.open(this.data.link, '_blank');
  }

}