import { Component, EventEmitter, OnInit, ViewChild, Output, ViewEncapsulation, Input } from '@angular/core';
import { concat, of, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime, switchMap, tap, catchError } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Globals } from 'app/globals';
import { ToastrService } from 'ngx-toastr';
import { GetnavisViewConsultantCompanyService } from './getnavis-view-consultant-company.service';
import { FileUploader } from 'ng2-file-upload';
import { environment } from 'environments/environment';
import { ActivatedRoute } from '@angular/router';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-getnavis-view-consultant-company',
  templateUrl: './getnavis-view-consultant-company.component.html',
  styleUrls: ['./getnavis-view-consultant-company.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GetnavisViewConsultantCompanyComponent implements OnInit{

  @Input() uuid: string;

  @Output() dismissDrawer: EventEmitter<boolean> = new EventEmitter<boolean>();

  loadComponent = false;

  consultantCompany: any = null;

  companies: Observable<any>;

  companyLoading = false;

  companyinput$ = new Subject<string>();

  tempCompanyName = null;

  consultants: any[] = [];

  activeIds: string[];

  blacklistedCandidate: any;

  public blacklistCsvUploader: FileUploader;

  csv_loading = false;

  public hasBaseDropZoneOver;

  xeroContacts: any[] = [];

  edit = {
    details: false,
    blacklist: false
  };

  public addTagCompany: (name) => void;

  @ViewChild('blacklistSelect') blacklistNgSelect: NgSelectComponent;

  @ViewChild('companiesSelect') companiesNgSelect: NgSelectComponent;

  preventDefault = false;

  changed = false;

  constructor(public globals: Globals, private getNavisViewConsultantCompanyService: GetnavisViewConsultantCompanyService, private toastr: ToastrService,
    private activatedRoute: ActivatedRoute){
    this.addTagCompany = (name) => this.addCompanyBlacklist(name);
    activatedRoute.queryParams.subscribe(params => {
      if (params.consultant_company_uuid){
        this.uuid = params.consultant_company_uuid;
        this.getConsultantCompany(this.uuid);
      }
    });
  }

  ngOnInit(){
    this.activeIds = ['details', 'blacklisted_companies'];
    this.loadCompany();
    this.createBlacklistedCompaniesFileUploader();
  }

  getConsultantCompany(uuid){
    this.getNavisViewConsultantCompanyService.getConsultantCompany(uuid).subscribe((data) => {
      if (data){
        this.consultantCompany = data;
        if (!this.consultantCompany.contact){
          this.consultantCompany.contact = {};
        }
        this.loadComponent = true;
      }
    },
    (err) => {
      this.displayErrorMessage(err);
    });
  }

  checkCompanyContact(){
    if (!this.consultantCompany.contact.name && !this.consultantCompany.contact.email && !this.consultantCompany.contact.phone && !this.consultantCompany.contact.photo){
      this.consultantCompany.contact = null;
    }
  }

  loadCompany(){
    this.companies = concat(
      of([]),
      this.companyinput$.pipe(
          debounceTime(200),
          distinctUntilChanged(),
          tap(() => this.companyLoading = true),
          switchMap(company =>
            this.getNavisViewConsultantCompanyService.getDealroomCompanies(company).pipe(
                  catchError(() => of([])),
                  tap(() => {
                      this.companyLoading = false;
                  })
              )
          )
      )
    );
  }

  setCompanyData(data){
    if (data){
       this.consultantCompany.photo = data.photo || data.images['100x100'];
       this.consultantCompany.linkedin_url = data.linkedin_url;
       this.consultantCompany.website_url = data.website_url;
       this.consultantCompany.dealroom_id = data.id;
       this.consultantCompany.dealroom_data = data;
       this.updateConsultantCompany(this.consultantCompany);
       return;
    }
    this.editConsultantCompany("name", this.consultantCompany.name);
  }

  //only consultants without consultant company
  getConsultants(){
    this.getNavisViewConsultantCompanyService.getUsers('consultants').subscribe((data) => {
      if (data.length){
        this.consultants = data;
      }
    },
    (err) => {
      this.displayErrorMessage(err);
    });
  }

  displayErrorMessage(error){
    if (error.error){
      for (const errProp in error.error){
        if (error.error.hasOwnProperty(errProp)){
          if (error.error[errProp][0]){
            this.toastr.error(error.error[errProp]);
          }
        }
      }
    } else {
      this.toastr.error('Something went wrong');
    }
  }

  addBlacklistedCandidate(){
    if (this.checkLinkedInFormat()){
      if (this.blacklistedCandidate && this.blacklistedCandidate.trim()){
        if (this.consultantCompany.blacklistedCandidates.length){
          if (this.consultantCompany.blacklistedCandidates.filter(x => x.linkedin_url === this.blacklistedCandidate.trim()).length == 0){
            const linkedin_url = { linkedin_url: this.blacklistedCandidate };
            this.consultantCompany.blacklistedCandidates.push(linkedin_url);
            this.blacklistedCandidate = '';
          } else {
            this.blacklistedCandidate = '';
            this.toastr.error('This candidate already exist');
           }
        } else {
          const linkedin_url = { linkedin_url: this.blacklistedCandidate };
          this.consultantCompany.blacklistedCandidates.push(linkedin_url);
          this.blacklistedCandidate = '';
        }
     }
     this.editConsultantCompany('blacklisted_candidates', this.consultantCompany.blacklistedCandidates);
    } else {
      this.toastr.error('Invalid linked in format');
      this.blacklistedCandidate = '';
    }

  }

  checkLinkedInFormat(){
    if (this.blacklistedCandidate && this.blacklistedCandidate.includes('https://www.linkedin.com/in/'))
      return true;
    return false;
  }

  deleteBlacklistedCandidate(index){
    this.consultantCompany.blacklistedCandidates.splice(index, 1);
    this.editConsultantCompany('blacklisted_candidates', this.consultantCompany.blacklistedCandidates);
  }

  onBlacklistedCompanyInsertCSV(){
    this.blacklistCsvUploader.uploadAll();
    this.csv_loading = false;
  }

  onFileClick(event){
    event.target.value = '';
  }

  createBlacklistedCompaniesFileUploader(){
    this.blacklistCsvUploader = new FileUploader({
      url: environment.getNavisBackendURL + '/companies/linking/csv',
      itemAlias: 'csv',
      authToken: 'Bearer ' + localStorage.getItem('access-token')
    });

    this.blacklistCsvUploader.onCompleteItem = (item: any, response: any, status: any) => {
      if (status === 200){
        const obj = JSON.parse(response);
        if (obj instanceof Object && 'companies' in obj){
          this.consultantCompany.blacklistedCompanies = this.mergeAndDeduplicateBlacklistedCompanies(obj.companies);
          this.toastr.success('Blacklisted companies succesfully added');
          return;
        }
        this.toastr.error('Response is malformed or empty. Please check the csv file content');
        return;
      }
      this.toastr.error('Something went wrong');
      this.hasBaseDropZoneOver = false;
    };
  }

  mergeAndDeduplicateBlacklistedCompanies(companies){
    const tempCompaines = [...this.consultantCompany.blacklistedCompanies, ...companies];
    return tempCompaines.filter((value, index, self) =>
      index === self.findIndex((item) => (
        item.name === value.name
      ))
    );
  }

  blacklistCompany(event){
    this.consultantCompany.blacklistedCompanies = [];
    if (this.consultantCompany && this.consultantCompany.blacklistedCompanies.find(company => company.name === this.consultantCompany.name)){
      this.consultantCompany.blacklistedCompanies.splice(
        this.consultantCompany.blacklistedCompanies.findIndex(i => i.name === this.consultantCompany.name), 1
      );
    }
    if (event){
      this.consultantCompany.blacklistedCompanies.push(event);
    }
  }

  addCompanyBlacklist(name){
    this.companyLoading = false;
    const tempCompany = {
      name: name,
      images: {
        '32x32': this.globals.companyAvatar
        }
      };
    this.consultantCompany.blacklistedCompanies = [...this.consultantCompany.blacklistedCompanies, tempCompany];
    this.editConsultantCompany('blacklisted_companies', this.consultantCompany.blacklistedCompanies);
    this.blacklistNgSelect.close();
  }

  editForm(section: string){
    this.preventDefault = true;
    this.edit[section] = !this.edit[section];
  }

  public beforeChange($event: NgbPanelChangeEvent){
    $event.preventDefault();
    this.preventDefault = false;
  }

  editConsultantCompany(key, value){
    if (value === ""){
      value = null;
    }
    const body = this.recreateConsultantCompanyObject(key, value);
    this.updateConsultantCompany(body);
  }

  recreateConsultantCompanyObject(key, value){
    if (key.indexOf('.') > -1){
      const keyValue = key.substr(0, key.indexOf('.'));
      const obj = {};
      obj[keyValue] = {
        [key.split('.').pop()]: value
      };
      return obj;
    } else {
      return {
        [key]: value
      };
    }
  }

  updateConsultantCompany(body){
    this.getNavisViewConsultantCompanyService.updateConsultantCompany(this.uuid, body)
      .subscribe((response) => {
        this.toastr.success(response['message']);
      },
        (err) => {
          this.displayErrorMessage(err);
        });
        () => {
          this.changed = false;
        };
  }

  onChange(){
    this.changed = true;
  }

  addCompany(name){
    this.companyLoading = false;
    this.consultantCompany.name = name;
    this.companiesNgSelect.close();
    this.editConsultantCompany("name", this.consultantCompany.name);
  }

  getXeroContacts(){
    this.getNavisViewConsultantCompanyService.getXeroContacts().subscribe((data) => {
      if (data.contacts?.length){
        this.xeroContacts = data.contacts;
      }
    },
    (err) => {
      this.displayErrorMessage(err);
    });
  }

}
