<div class="modal-content">
  <div class="modal-bg">
    <div class="modal-header">
      <div class="modal-header-title">
        Edit next step
      </div>
      <button type="button" class="close pull-right" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true" [innerHTML]="globals.labels.placeholders.global.buttons.close_x" data-toggle="tooltip"
          data-placement="bottom" [title]="globals.labels.tooltips.global.close"></span>
      </button>
    </div>
    <div class="modal-body" *ngIf="show.component">
      <div class="row">
        <div class="col-12 col-lg-4">
          <div class="form-group">
            <div class="modal-body-title">
              {{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
              From
              <span *ngIf="!validForm && !data.users.from" [ngClass]="{'primary-color': !data.users.from}">
                {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
              </span>
            </div>
            <div class="modal-body-content">
              <ng-select bindLabel="name" dropdownPosition="bottom" (open)="getContacts()"
                placeholder="Select contact (from)" [items]="resources.contacts"
                (ngModelChange)="this.show.emailUpdate = true" [(ngModel)]="data.users.from"
                [ngModelOptions]="{standalone: true}">
                <ng-template ng-option-tmp let-item="item" let-index="i">
                  <div class="media mb-1">
                    <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                      [src]="item.photo ? item.photo : globals.avatar" (error)="item.photo = globals.avatar" />
                    <div class="media-body">
                      <div class="media-body-title mt-1 mb-0">{{item.name}}</div>
                      <p><i
                          class="tt-icon-sm icon-ttmail email-icon mr-1"></i>{{item.email ? item.email : globals.labels.placeholders.shared_page.add_job.empty_email}}<br>
                        <i
                          class="tt-icon-sm icon-ttcareer"></i>{{item.job_title ? item.job_title : globals.labels.placeholders.shared_page.add_job.empty_title}}
                      </p>
                    </div>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="form-group">
            <div class="modal-body-title">
              CC
            </div>
            <div class="modal-body-content">
              <ng-select bindLabel="name" dropdownPosition="bottom" (open)="getContacts()"
                (ngModelChange)="this.show.emailUpdate = true" placeholder="Select contact (cc)"
                [items]="resources.contacts" [multiple]="true" [(ngModel)]="data.users.cc"
                [ngModelOptions]="{standalone: true}">
                <ng-template ng-option-tmp let-item="item" let-index="i">
                  <div class="media mb-1">
                    <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                      [src]="item.photo ? item.photo : globals.avatar" (error)="item.photo = globals.avatar" />
                    <div class="media-body">
                      <div class="media-body-title mt-1 mb-0">{{item.name}}</div>
                      <p><i
                          class="tt-icon-sm icon-ttmail email-icon mr-1"></i>{{item.email ? item.email : globals.labels.placeholders.shared_page.add_job.empty_email}}<br>
                        <i
                          class="tt-icon-sm icon-ttcareer"></i>{{item.job_title ? item.job_title : globals.labels.placeholders.shared_page.add_job.empty_title}}
                      </p>
                    </div>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="form-group">
            <div class="modal-body-title">
              BCC
            </div>
            <div class="modal-body-content">
              <ng-select bindLabel="name" dropdownPosition="bottom" (open)="getContacts()"
                (ngModelChange)="this.show.emailUpdate = true" placeholder="Select contact (bcc)"
                [items]="resources.contacts" [multiple]="true" [(ngModel)]="data.users.bcc"
                [ngModelOptions]="{standalone: true}">
                <ng-template ng-option-tmp let-item="item" let-index="i">
                  <div class="media mb-1">
                    <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                      [src]="item.photo ? item.photo : globals.avatar" (error)="item.photo = globals.avatar" />
                    <div class="media-body">
                      <div class="media-body-title mt-1 mb-0">{{item.name}}</div>
                      <p><i
                          class="tt-icon-sm icon-ttmail email-icon mr-1"></i>{{item.email ? item.email : globals.labels.placeholders.shared_page.add_job.empty_email}}<br>
                        <i
                          class="tt-icon-sm icon-ttcareer"></i>{{item.job_title ? item.job_title : globals.labels.placeholders.shared_page.add_job.empty_title}}
                      </p>
                    </div>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <div class="modal-body-title">
              {{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
              Subject
              <span *ngIf="!validForm && !data.subject" [ngClass]="{'primary-color': !data.subject}">
                  {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                </span>
            </div>
            <div class="modal-body-content"> 
              <input type="text" class="form-control" placeholder="Insert subject" [(ngModel)]="data.subject" (ngModelChange)="this.show.emailUpdate = true"
                [ngModelOptions]="{standalone: true}">
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <div class="modal-body-title">
              {{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
              Body
              <span *ngIf="!validForm && !data.body" [ngClass]="{'primary-color': !data.body}">
                  {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                </span>
            </div>
            <div class="modal-body-content">
              <quill-editor #editor [(ngModel)]="data.body" id="quill-editor-campaigns" placeholder="Insert body" (ngModelChange)="bodyModelChange()">
              </quill-editor>
              <div class="signature-campaigns p-3" [innerHTML]="data.users.from ? data.users.from.signature: ''">
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <div class="modal-body-title">
              Files
            </div>
            <div class="modal-body-content">
              <div class="form-group">
                <div class="card-body-content">
                  <div class="list-group-item list-group-item-action list-group-item-light ng-star-inserted"
                    *ngFor="let file of data.assets; let j = index">
                    <a [href]="file.file_url" target="_blank">
                      {{file.original_name}}
                    </a>
                    <a (click)="removeFile(data, j)" class="pull-right">
                      <i class="tt-icon-lg fa fa-trash-o" data-toggle="tooltip" data-placement="bottom"
                        [title]="globals.labels.tooltips.global.delete"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="card-body-content">
                  <div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)"
                    [uploader]="uploader" (onFileDrop)="afterFileisDrop()"
                    class="p-3 mb-3 mt-3 ml-0 mr-0 text-center grey my-drop-zone">
                    <span>Drag and drop the file here<br>or<br></span>
                    <button class="btn bg-secondary-color col-md-3 col-lg-3 col-xl-3 btn-position"
                      (click)="fileInput.click()">
                      Upload file
                      <span *ngIf="show.loading" class="ft-loader tt-icon-sm spinner white"></span>
                    </button>
                  </div>
                  <input type="file" ng2FileSelect [uploader]="uploader" #fileInput (change)="OnContentChange($event)"
                    style="visibility: hidden; position: absolute; overflow: hidden; width: 0px; height:0px;border:none;margin:0; padding:0">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="col-md-3 btn btn-block bg-secondary-color" *ngIf="show.emailUpdate"
        (click)="editNextStepEmail()">
        Update</button>
      <button type="button" class="col-md-3 btn btn-block bg-primary-color"
        (click)="activeModal.dismiss()">{{globals.labels.placeholders.global.buttons.close}}</button>
    </div>
  </div>
</div>