<div class="modal-content">
  <div class="modal-bg">
    <div class="modal-header">
      <div class="modal-header-title">
        Add to job (data will be applied for all selected candidates)
      </div>
      <button type="button" aria-label="Close" class="close pull-right" (click)="closePopup()">
        <span aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Close">×</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <div class="modal-body-title">
          <label>Job</label>
        </div>
        <div class="modal-body-content">
          <ng-select bindLabel="title" dropdownPosition="bottom" placeholder="Select job" [items]="resources.jobs"
            [(ngModel)]="addTojob.job_uuid" [ngModelOptions]="{standalone: true}" (open)="getJobs()"
            (change)="changedJob()">
            <ng-template ng-option-tmp let-item="item" let-index="i">
              {{item.title}} {{item.company?.name ? '- ' + item.company.name : ''}}
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="form-group" *ngIf="addTojob.job_uuid">
        <div class="modal-body-title">
          <label>Status</label>
        </div>
        <div class="modal-body-content">
          <ng-select bindLabel="description" dropdownPosition="bottom" placeholder="Select status"
            [items]="resources.statuses" [(ngModel)]="addTojob.status" [ngModelOptions]="{standalone: true}"
            (open)="getStatuses()">
          </ng-select>
        </div>
      </div>
      <div class="form-group" *ngIf="addTojob.job_uuid">
        <div class="modal-body-title">
          <label>Archetype</label>
        </div>
        <div class="modal-body-content">
          <ng-select bindLabel="name" bindValue="uuid" dropdownPosition="bottom" placeholder="Select archetype"
            [items]="resources.archetypes" [(ngModel)]="addTojob.persona_uuid" [ngModelOptions]="{standalone: true}"
            (open)="getArchetype()">
          </ng-select>
        </div>
      </div>
      <div class="form-group" *ngIf="addTojob.job_uuid && enableResearchNoteCheckbox">
        <div class="custom-control custom-checkbox mt-2">
          <input type="checkbox" id="include_research_note" class="custom-control-input" [(ngModel)]="includeResearchNote">
          <label class="custom-control-label cursor-pointer" for="include_research_note">
              Include research notes
          </label>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn pull-right bg-secondary-color" (click)="addToJob()">Add to job</button>
      <button type="button" class="btn pull-right bg-primary-color" (click)="activeModal.dismiss()">Close</button>
    </div>
  </div>
