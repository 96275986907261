import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class CandidateNextStepEmailModalService{

  constructor(private http: HttpClient){ }

  getTemplatesResources(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/campaigns/templates');
  }

  getContactsResources(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources/internalUsers');
  }

}
