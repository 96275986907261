<div class="modal-content">
  <div class="modal-bg">
    <div class="modal-header">
      <div class="modal-header-title">
        Candidate Application {{candidates.length > 1 ? ' (Changes will be applied for all candidates)' : ''}}
      </div>
      <button type="button" class="close pull-right" aria-label="Close">
        <span aria-hidden="true" data-toggle="tooltip" (click)="closePopup()" data-placement="bottom">x</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <div class="modal-body-title">
              <label>Status</label>
            </div>
            <div class="modal-body-content">
              <ng-select bindLabel="description" [clearable]="false" dropdownPosition="bottom"
                placeholder="Select status" [items]="resources.statuses" [loading]="loadingStatuses"
                [(ngModel)]="application.current_status" (change)="onStatusChange()" (open)="getStatuses()">
              </ng-select>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group" *ngIf="resources.substatuses.length || application.substatus">
            <div class="modal-body-title">
              <label>Substatus</label>
            </div>
            <ng-container [ngSwitch]="true">
              <div *ngSwitchCase="application.current_status.value=='contacting'">
                <ng-select [items]="resources.substatuses" bindLabel="description"
                  dropdownPosition="bottom" placeholder="Select substatus" [(ngModel)]="application.substatus"
                  groupBy="children" (ngModelChange)="trackChanges()" [ngModelOptions]="{standalone: true}"
                  (open)="getSubstatuses()" (change)="onSubstatusChange()">
                  <ng-template ng-label-tmp let-item="item">
                    <span *ngIf="item.type === 'contacted_substatuses_by_consultant'">Contacted by consultant -
                      {{item.value === 'other' ? item.value : item.description}}</span>
                    <span *ngIf="item.type !== 'contacted_substatuses_by_consultant'">{{item.description}}</span>
                  </ng-template>
                </ng-select>
              </div>
              <div *ngSwitchCase="isCandidateRejected()">
                <ng-select [items]="resources.substatuses" bindLabel="description" dropdownPosition="bottom"
                placeholder="Select substatus" [(ngModel)]="application.substatus" groupBy="children" (ngModelChange)="trackChanges()"
                [ngModelOptions]="{standalone: true}" (open)="getSubstatuses()" (change)="onSubstatusChange($event)">
                <ng-template ng-optgroup-tmp let-item="item">
                  {{item.description}}
                </ng-template>
                </ng-select>
              </div>
              <div *ngSwitchDefault>
                <div class="modal-body-content">
                  <ng-select [items]="resources.substatuses" bindLabel="description" dropdownPosition="bottom"
                    placeholder="Select substatus" [(ngModel)]="application.substatus"
                    [ngModelOptions]="{standalone: true}" (change)="onSubstatusChange()">
                  </ng-select>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div [ngSwitch]="application.current_status?.value">
        <ng-container *ngSwitchCase="'recruiter_interview'">
          <ng-container *ngIf="application.substatus?.value == 'scheduled_interview'"
            [ngTemplateOutlet]="recruiterScheduledInterview"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'client_interview'">
          <ng-container *ngIf="application.substatus?.value == 'scheduled_x_interview'"
            [ngTemplateOutlet]="clientScheduledInterview"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'offer'" [ngTemplateOutlet]="offerTemplate">
        </ng-container>
        <ng-container *ngSwitchCase="'hire'" [ngTemplateOutlet]="hireTemplate">
        </ng-container>
        <ng-container *ngSwitchDefault>
          <ng-container *ngIf="isCandidateRejected() || application.substatus?.value == 'other'"
            [ngTemplateOutlet]="additionalCommentTemplate"></ng-container>
          <ng-container *ngIf="isCandidateRejected() && checkRejectionNpsEligibility()" [ngTemplateOutlet]="npsSurvey"></ng-container>
        </ng-container>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn pull-right bg-secondary-color" (click)="updateApplication()">Update
        Application</button>
      <button type="button" class="btn pull-right bg-primary-color" (click)="resetStatus()" *ngIf="changed">Reset
        application</button>
    </div>
  </div>
</div>
<ng-template #recruiterScheduledInterview>
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="form-group">
        <div class="modal-body-title">
          <label>Interviewer</label>
        </div>
        <div class="modal-body-content">
          <ng-select #usersSelect [items]="resources.consultants" [clearable]="true" placeholder="Select interviewer"
            bindLabel="name" (open)="getConsultants()"
            [(ngModel)]="application.pipeline_data.internal_interview.details.interviewer"
            (ngModelChange)="trackChanges()" [ngModelOptions]="{standalone: true}">
            <ng-template ng-option-tmp let-item="item" let-index="i">
              <div class="media mb-1">
                <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle" [src]="item.picture_file_url"
                  (error)="item.picture_file_url = globals.avatar" />
                <div class="media-body">
                  <div class="media-body-title mt-1 mb-0"><b>{{item.name}}</b></div>
                  <span>{{item.email}}</span>
                </div>
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-8 col-md-6">
      <div class="form-group">
        <div class="modal-body-title">
          <label>Date and time</label>
        </div>
        <div class="modal-body-content">
          <input type="date" class="form-control" data-date-format="YYYY MMMM DD"
            [(ngModel)]="application.pipeline_data.internal_interview.details.date"
            (ngModelChange)="dateChange(application.pipeline_data.internal_interview)"
            [ngModelOptions]="{standalone: true}">
          <div class="mt-2">
            <label><strong>*</strong>The default timezone is CEST</label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4 col-md-6" *ngIf="application.pipeline_data.internal_interview.details.date">
      <div class="form-group">
        <div class="modal-body-content timepicker-position">
          <ngb-timepicker [(ngModel)]="application.pipeline_data.internal_interview.details.time"
            (ngModelChange)="trackChanges()" [ngModelOptions]="{standalone: true}">
          </ngb-timepicker>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #clientScheduledInterview>
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="form-group">
        <div class="modal-body-title">
          <label>Interviewer</label>
        </div>
        <div class="modal-body-content">
          <input type="text" class="form-control" placeholder="Insert interviewer"
            [(ngModel)]="application.pipeline_data.client_interview.details.interviewer"
            (ngModelChange)="trackChanges()" [ngModelOptions]="{standalone: true}">
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="form-group">
        <div class="modal-body-title">
          <label>Type</label>
        </div>
        <div class="modal-body-content">
          <ng-select [items]="resources.client_interview_type" [clearable]="true" placeholder="Select interview type"
            bindLabel="description" (open)="getResourcesByType('client_interview_type')"
            [(ngModel)]="application.pipeline_data.client_interview.details.type" (ngModelChange)="trackChanges()"
            [ngModelOptions]="{standalone: true}">
          </ng-select>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-8 col-md-6">
      <div class="form-group">
        <div class="modal-body-title">
          <label>Date and time</label>
        </div>
        <div class="modal-body-content">
          <input type="date" class="form-control" data-date-format="YYYY MMMM DD"
            [(ngModel)]="application.pipeline_data.client_interview.details.date"
            (ngModelChange)="dateChange(application.pipeline_data.client_interview)"
            [ngModelOptions]="{standalone: true}">
          <div class="mt-2">
            <label><strong>*</strong>The default timezone is CEST</label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4 col-md-6" *ngIf="application.pipeline_data.client_interview.details.date">
      <div class="form-group">
        <div class="modal-body-content timepicker-position">
          <ngb-timepicker [(ngModel)]="application.pipeline_data.client_interview.details.time"
            (ngModelChange)="trackChanges()" [ngModelOptions]="{standalone: true}">
          </ngb-timepicker>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #offerTemplate>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <div class="modal-body-title">
          <label>Base</label>
        </div>
        <div class="modal-body-content">
          <input type="number" class="form-control" min="0" placeholder="Insert base"
            [(ngModel)]="application.pipeline_data.offer.details.base_salary" (ngModelChange)="trackChanges()"
            [ngModelOptions]="{standalone: true}">
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <div class="modal-body-title">
          <label>Currency</label>
        </div>
        <div class="modal-body-content">
          <ng-select (open)="getResourcesByType('currencies')" bindLabel="description" [items]="resources.currencies"
            dropdownPosition="bottom" placeholder="Select currency"
            [(ngModel)]="application.pipeline_data.offer.details.currency" (ngModelChange)="trackChanges()"
            [ngModelOptions]="{standalone: true}">
          </ng-select>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <div class="modal-body-title">
          <label>Bonus</label>
        </div>
        <div class="modal-body-content">
          <input type="number" class="form-control" min="0" placeholder="Insert base"
            [(ngModel)]="application.pipeline_data.offer.details.bonus" (ngModelChange)="trackChanges()"
            [ngModelOptions]="{standalone: true}">
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <div class="modal-body-title">
          <label>Type</label>
        </div>
        <div class="modal-body-content">
          <ng-select (open)="getResourcesByType('bonus_type')" bindLabel="description" [items]="resources.bonus_type"
            dropdownPosition="bottom" placeholder="Select type"
            [(ngModel)]="application.pipeline_data.offer.details.bonus_type" (ngModelChange)="trackChanges()"
            [ngModelOptions]="{standalone: true}">
          </ng-select>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <div class="modal-body-title">
          <label>Equity</label>
        </div>
        <div class="modal-body-content">
          <input type="text" class="form-control" placeholder="Insert equity"
            [(ngModel)]="application.pipeline_data.offer.details.equity" (ngModelChange)="trackChanges()"
            [ngModelOptions]="{standalone: true}">
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <div class="modal-body-title">
          <label>Date</label>
        </div>
        <div class="modal-body-content">
          <input type="date" class="form-control" ngbDatepicker
            [(ngModel)]="application.pipeline_data.offer.details.date" (ngModelChange)="trackChanges()"
            [ngModelOptions]="{standalone: true}">
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <div class="modal-body-title">
          <label>Additional comment</label>
        </div>
        <div class="modal-body-content">
          <quill-editor id="description" name="description" placeholder="Input additional comment"
            [(ngModel)]="application.pipeline_data.offer.details.comment" (ngModelChange)="trackChanges()"
            [ngModelOptions]="{standalone: true}">
          </quill-editor>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #hireTemplate>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <div class="modal-body-title">
          <label>Base</label>
        </div>
        <div class="modal-body-content">
          <input type="number" class="form-control" min="0" placeholder="Insert base"
            [(ngModel)]="application.pipeline_data.hire.details.base_salary" (ngModelChange)="trackChanges()"
            [ngModelOptions]="{standalone: true}">
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <div class="modal-body-title">
          <label>Currency</label>
        </div>
        <div class="modal-body-content">
          <ng-select (open)="getResourcesByType('currencies')" bindLabel="description" [items]="resources.currencies"
            dropdownPosition="bottom" placeholder="Select currency"
            [(ngModel)]="application.pipeline_data.hire.details.currency" (ngModelChange)="trackChanges()"
            [ngModelOptions]="{standalone: true}">
          </ng-select>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <div class="modal-body-title">
          <label>Bonus</label>
        </div>
        <div class="modal-body-content">
          <input type="number" class="form-control" min="0" placeholder="Insert base"
            [(ngModel)]="application.pipeline_data.hire.details.bonus" (ngModelChange)="trackChanges()"
            [ngModelOptions]="{standalone: true}">
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <div class="modal-body-title">
          <label>Type</label>
        </div>
        <div class="modal-body-content">
          <ng-select (open)="getResourcesByType('bonus_type')" bindLabel="description" [items]="resources.bonus_type"
            dropdownPosition="bottom" placeholder="Select type"
            [(ngModel)]="application.pipeline_data.hire.details.bonus_type" (ngModelChange)="trackChanges()"
            [ngModelOptions]="{standalone: true}">
          </ng-select>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <div class="modal-body-title">
          <label>Equity</label>
        </div>
        <div class="modal-body-content">
          <input type="text" class="form-control" placeholder="Insert equity"
            [(ngModel)]="application.pipeline_data.hire.details.equity" (ngModelChange)="trackChanges()"
            [ngModelOptions]="{standalone: true}">
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <div class="modal-body-title">
          <label>Date</label>
        </div>
        <div class="modal-body-content">
          <input type="date" class="form-control" ngbDatepicker
            [(ngModel)]="application.pipeline_data.hire.details.date" (ngModelChange)="trackChanges()"
            [ngModelOptions]="{standalone: true}">
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <div class="modal-body-title">
          <label>Fee</label>
        </div>
        <div class="modal-body-content">
          <input type="text" class="form-control" placeholder="Insert fee"
            [(ngModel)]="application.pipeline_data.hire.details.fee" (ngModelChange)="trackChanges()"
            [ngModelOptions]="{standalone: true}">
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <div class="modal-body-title">
          <label>Additional comment</label>
        </div>
        <div class="modal-body-content">
          <quill-editor id="description" name="description" placeholder="Input additional comment"
            [(ngModel)]="application.pipeline_data.hire.details.comment" (ngModelChange)="trackChanges()"
            [ngModelOptions]="{standalone: true}">
          </quill-editor>
        </div>
      </div>
    </div>
    <div class="col-12">
      <ng-container [ngTemplateOutlet]="npsSurvey"></ng-container>
    </div>
  </div>
</ng-template>
<ng-template #additionalCommentTemplate>
  <div class="row">
    <div class="col-12">
      <div class="form-group" *ngIf="application.substatus">
        <div class="modal-body-title">
          Additional Comment
        </div>
        <div class="modal-body-content">
          <textarea [(ngModel)]="application.substatus.additional_comment" placeholder="Insert comment (optional)"
            class="form-control" rows="3" (ngModelChange)="trackChanges()"></textarea>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #npsSurvey>
  <div class="form-group">
    <div class="custom-control custom-checkbox m-0" *ngIf="application.nps === 'not sent'; else hasNps">
      <input type="checkbox" [ngModelOptions]="{standalone: true}"
        [(ngModel)]="nps_send" class="custom-control-input" id="nps_check">
      <label class="custom-control-label" for="nps_check">Send NPS survey
      </label>
    </div>
    <ng-template #hasNps>
      <p>NPS survey has already been sent</p>
    </ng-template>
  </div>
</ng-template>