import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from 'app/globals';
import { ToastrService } from 'ngx-toastr';
import { ViewCandidateService } from '../view-candidate.service';

@Component({
  selector: 'app-add-to-job-modal',
  templateUrl: './add-to-job-modal.component.html',
  styleUrls: ['./add-to-job-modal.component.scss']
})
export class AddToJobModalComponent implements OnInit{
  addTojob: any = {
    job: null,
    candidate_uuid: null,
    substatus: null,
    stage: [],
    comment: null
  };

  validForm = true;

  resources: any = {
    jobs : [],
    subStatuses : [],
    candidate_application_rating: []

  };

  stage: any = [];

  constructor(public globals: Globals, public activeModal: NgbActiveModal, public toastr: ToastrService, private profileService: ViewCandidateService){

   }

  ngOnInit(){
  }

  checkRequiredFields(){

 if (!this.addTojob.job || !this.addTojob.stage){
      this.validForm = false;
      if (!this.addTojob.job && !this.addTojob.substatus){
        this.toastr.error("Both Open job and Sub status fields are required");
      } else if (!this.addTojob.substatus && this.addTojob.stage.value == 'longlist'){
        this.toastr.error("Sub status field is required");
      } else if (this.addTojob.job && !this.addTojob.stage){
        this.toastr.error("Stage field is required");
      }
    } else {
      this.activeModal.close(this.addTojob);
    }

  }

  getJobs(type, candidate_uuid){
    if (!this.resources.jobs.length){
      this.profileService.getCandidateJobsResources(type, candidate_uuid).subscribe(
        (data) => {
          this.resources.jobs = data;
        });
    }
  }

  openStageDropdown(){
    if (!this.stage.length){

      this.profileService.getStageStatus()
        .subscribe((stage) => {
          if (stage){
            this.stage = stage;

          }
        });
    }
  }

  getInitialSubStatuses(){
    if (!this.resources.subStatuses.length){
      this.profileService.getInitialSubStatuses().subscribe(
        (data) => {
          this.resources.subStatuses = data;
        });
    }
  }

  getResourcesByType(value){
    if (!this.resources[value].length){
      this.profileService.getResources(value).subscribe((data) => {
        if (data.length){
          this.resources[value] = data;
        }
      });
    }
  }

}
