import { Component, OnInit } from '@angular/core';
import { Observable, Subject, of, concat } from 'rxjs';
import { distinctUntilChanged, debounceTime, switchMap, tap, catchError, filter } from 'rxjs/operators';
import { ChooseMergeCandidateModalService } from './choose-merge-candidate-modal.service';
import { Globals } from 'app/globals';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-choose-merge-candidate-modal',
  templateUrl: './choose-merge-candidate-modal.component.html',
  styleUrls: ['./choose-merge-candidate-modal.component.scss']
})
export class ChooseMergeCandidateModalComponent implements OnInit{
  data: any = {};

  candidates: Observable<any>;

  candidateInput = new Subject<string>();

  candidateLoading = false;

  constructor(private chooseMergeCandidateModalService: ChooseMergeCandidateModalService, public globals: Globals, public activeModal: NgbActiveModal){ }

  ngOnInit(){
    this.loadCandidates();
  }

  loadCandidates(){
    const minLength = 3;
    this.candidates = concat(
      of([]),
      this.candidateInput.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(input => (input && input.length > minLength ? this.candidateLoading = true : this.candidateLoading = false)),
        filter(input => input && input.length > minLength)).pipe(switchMap(
          candidate => this.chooseMergeCandidateModalService.getCandidates(candidate).pipe(
            catchError(() => of([])),
            tap((candidates) => {
              const index = candidates.findIndex(item => item.uuid == this.data.primary_candidate_uuid);
              if (index != -1){
                candidates.splice(index, 1);
              }
              this.candidateLoading = false;
            }))))
    );
  }
}
