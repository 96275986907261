<div class="modal-content" >
  <div class="modal-bg">
    <div class="modal-header">
      <div class="modal-header-title">
        Remove from job <span *ngIf="candidates.length > 1">(action will be applied for all selected)</span>
      </div>
      <button type="button" class="close pull-right" aria-label="Close">
        <span aria-hidden="true" data-toggle="tooltip" (click)="activeModal.dismiss()" data-placement="bottom">x</span>
      </button>
    </div>
    <div class="modal-body">
      <p *ngIf="campaignCandidate">One or more selected candidate is active in a campaign. Removing the candidates will remove them from the campaign. Are you sure you want to continue?</p>
      <p class="mb-0">This will not delete the candidate. You can always add the candidate back to the job or another job.</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn pull-right bg-secondary-color" (click)="removeCandidates()">Remove from job</button>
      <button type="button" class="btn pull-right bg-primary-color" (click)="activeModal.dismiss()">Close</button>
    </div>
  </div>
</div>