import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class LonglistCandidateModalService{

    constructor(private http: HttpClient){ }

    getFeResources(): Observable<any>{
        return this.http.get(environment.platformBackendURL + '/resources/functionalExpertise');

    }

    getCandidatesDataForValidation(body): Observable<any>{

        return this.http.post(environment.platformBackendURL + '/candidates/applicationValidation', body);
    }

    getSfeResources(): Observable<any>{
        return this.http.get(environment.platformBackendURL + '/resources/subFunctionalExpertise');
    }

    getIndustryResources(): Observable<any>{
        return this.http.get(environment.platformBackendURL + '/resources/industries');
    }

    getResources(type): Observable<any>{
        return this.http.get(environment.platformBackendURL + '/resources?type=' + type);
    }

    updateExperience(uuid, body): Observable<any>{
        return this.http.put(environment.platformBackendURL + '/candidates/workExperience/' + uuid, body);
    }

    updateCandidate(uuid, body): Observable<any>{
        return this.http.put(environment.platformBackendURL + '/candidates/' + uuid, body);
    }

    createLonglistApplication(body, application_uuid): Observable<any>{
        if (!application_uuid){
            return this.http.post(environment.platformBackendURL + '/candidates/applications', body);
        } else {
            return this.http.put(environment.platformBackendURL + '/candidates/applications/' + application_uuid, body);
        }
    }

    getStageFocusResources(): Observable<any>{
        return this.http.get(environment.platformBackendURL + '/resources/investmentStage');
    }

    getSectorFocusResources(sectorFocus): Observable<any>{
        return this.http.get(environment.platformBackendURL + '/resources/investmentSector?name=' + sectorFocus);
    }

    getCountryFocusResources(country): Observable<any>{
        return this.http.get(environment.platformBackendURL + '/resources/country?name=' + country);
    }
}
