import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Globals } from "app/globals";
import { ToastrService } from "ngx-toastr";
import { GetNavisNavbarService } from "./navbar.service";
import { mergeMap, map, filter, takeWhile } from 'rxjs/operators';
import { GetNavisCommunicationService } from "../getnavis-service/get-navis-communication.service";

@Component({
  selector: 'app-getnavis-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class GetNavisNavbarComponent implements OnInit{
  loadingFlag = true;

  width: number;

  searchTerm: any = [];

  ngTemplateForSeacrhFlag = false;

  title = "";

  isJobRoute = true;

  componentAlive = true;

  jobRouteTitle = '';

  currentJobTitle: string;

  companyName: string;

  jobRouteTitleAdditionalInfo = 0;

  constructor(public globals: Globals,
    public httpService: GetNavisNavbarService,
    private toastr: ToastrService,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private communicationService: GetNavisCommunicationService){
    this.communicationService.onJobSelected$.pipe(
      takeWhile(() => this.componentAlive))
      .subscribe((job) => {
        this.currentJobTitle = job.title;
        this.isJobRoute = true;
        this.title = job.title;
        this.companyName = job.company.name;
        this.close();
      });

    this.route.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => activatedRoute),
      map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data))
      .subscribe((event) => {
        this.close();
        const routeSegments = this.route.parseUrl(this.route.url).root.children.primary.segments[1]?.toString();
        if (routeSegments === 'job' && this.route.parseUrl(this.route.url).root.children.primary.segments[3]){
          this.isJobRoute = true;
          this.title = this.currentJobTitle;
          this.jobRouteTitle = event.title;
        } else {
          this.isJobRoute = false;
          this.title = event.title;
          this.companyName = event.companyName;
        }
        const aside = document.getElementsByTagName('aside');
        if (document.getElementsByTagName('aside')){
          for (let i = 0; i < aside.length; i++){
            if (aside[i].getAttribute('id') == 'filter-drawer'){
              aside[i].style.visibility = "hidden";
            }
          }
        }
      });

    this.communicationService.totalResults$.pipe(
      takeWhile(() => this.componentAlive))
      .subscribe((data) => {
        if (data.component == this.route.parseUrl(this.route.url).root.children.primary.segments[1]?.toString()){
          this.jobRouteTitleAdditionalInfo = data.total_candidates;
        } else {
          this.jobRouteTitleAdditionalInfo = 0;
        }
      });
  }

  ngOnInit(): void{
    this.width = document.body.clientWidth;
  }

  logout(): void{
    this.httpService.logout()
      .subscribe(
        () => {
          localStorage.removeItem('access-token');
          this.route.navigate(['login']);
        },
        (err) => {
          this.toastr.error(err.message);
        }
      );
  }

  navigateTo(value){
    this.route.navigate([value]);
  }

  createJob(){
    this.addQueryParamsRoute('getnavis-job-drawer', 'add-job');
  }

  createConsultant(){
    this.addQueryParamsRoute('getnavis-consultant-drawer', 'add-consultant');
  }

  createConsultantCompany(){
    this.addQueryParamsRoute('getnavis-consultant-company-drawer', 'add-consultant-company');
  }

  createHiringCompany(){
    this.addQueryParamsRoute('getnavis-hiring-company-drawer', 'add-hiring-company');
  }

  addQueryParamsRoute(drawer_parent, type){
    this.route.navigate([],
      {
        relativeTo: this.activatedRoute,
        queryParams: { 'drawer_parent': drawer_parent, 'type': type }
      });
  }

  //global search functons
  onSelect(){
  }

  onTagEdited(){
  }

  close(){
  }

  prepopulateDataForSearch(){
  }

  navbarEditable(){
  }

  openJobDrawer(){
    const job_uuid = this.route.parseUrl(this.route.url).root.children.primary.segments[2].toString();
    const queryParams = {
      'drawer_parent': 'getnavis-job-drawer',
      'type': 'view-job',
      'job_uuid': job_uuid,
      'origin': 'navis'
    };
    this.route.navigate([],
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams
      });
  }

  checkIfFiltersApplied(){
    return this.route.parseUrl(this.route.url).root.children.primary.segments[1]?.toString() == 'candidates' && sessionStorage.getItem('filter-active-candidates') == 'true';
  }
}
