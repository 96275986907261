<div *ngIf="loading" class="loading-view">
  <img [src]="globals.loading" class="loading-element" />
</div>
<div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)"
  [uploader]="uploaderFile" (onFileDrop)="afterFileisDrop()" class="my-drop-zone full-height">
  <div class="card-block" *ngIf="loadComponent">
      <div *ngIf="blacklistedCandidate" class="col-12 mb-3">
          <i class="icon-ttcritical error-color logo-style"></i>
          <span>This candidate is <strong> {{blacklistedCandidate.hover_data}} </strong></span>
      </div>
    <div class="right">
      <button type="button" class="btn pull-right bg-primary-color mr-5" (click)="initializeComponent()">Clear data
      </button>
    </div>
    <nav ngbNav #nav="ngbNav" class="full-width nav-tabs" id="tab-shadow">
      <ng-container [ngbNavItem]="'ngb-tab-0'">
        <a ngbNavLink>Details
          <span class="badge m-0 badge-primary" *ngIf="!validation.details">
            <i class="fa fa-circle"></i>
          </span>
        </a>
        <ng-template ngbNavContent>
          <div class="card card-top scroll scroll-no-add">
            <ngb-accordion id="filter-accordion" [(activeIds)]="activeIds.details"  #accordionDetails="ngbAccordion" class="ng-accordion-append"
              (panelChange)="preventDefault.details ? beforeChange($event) : ''">
              <ngb-panel id="basic">
                <ng-template ngbPanelHeader>
                  <div class="form" (click)="accordionDetails.toggle('basic');accordion.basic = !accordion.basic">
                    <div class="form-section">
                      Basic details
                      <i class="pull-right mt-1 pt-2"
                        [ngClass]="{'ft-chevron-right': !accordion.basic,'ft-chevron-down': accordion.basic}"></i>
                    </div>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="row card-block">
                    <div class="col-4">
                      <div class="form-group">
                        <div class="media">
                          <div class="hvrbox height-70 width-70">
                            <a (click)="fileInput.click()" data-toggle="tooltip" data-placement="top"
                              [title]="globals.labels.tooltips.global.choose_image">
                              <img class="media-object d-flex height-70 width-70 rounded-circle"
                                [src]="candidate.image_url ? candidate.image_url : (candidate.gender && candidate.gender.value == 'female' ? globals.femaleAvatar : globals.avatar)"
                                (error)="candidate.gender && candidate.gender.value == 'female' ? candidate.image_url = globals.femaleAvatar : candidate.image_url = globals.avatar">
                              <div class="hvrbox-layer_top">
                                <div class="hvrbox-text">
                                  {{globals.labels.placeholders.full_layout_page.solve_component.image}}</div>
                              </div>
                            </a>
                            <span
                              style="visibility: hidden; position: absolute; overflow: hidden; width: 0; height:0;border:none; margin:0; padding:0">
                              <input type="file" #fileInput ng2FileSelect [uploader]="uploader"
                                (change)="OnContentChange($event, 'uploader')" />
                            </span>
                          </div>
                          <div class="media-body align-self-center">
                            <div class="media-body-title">
                              <span class="align-self-center">or</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-8">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>
                            Photo URL
                          </label>
                        </div>
                        <div class="card-body-content">
                          <input type="text" class="form-control" placeholder="Insert photo url"
                            [(ngModel)]="candidate.image_url" [ngModelOptions]="{standalone: true}">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>
                            * First name
                            <span *ngIf="!validation.details && !candidate.first_name"
                              [ngClass]="{'primary-color': !candidate.first_name}">
                              (Required field)
                            </span>
                          </label>
                        </div>
                        <div class="card-body-content">
                          <input type="text" class="form-control" placeholder="Insert first name"
                            [(ngModel)]="candidate.first_name" [ngModelOptions]="{standalone: true}" appOnFocusOut
                            (focusout)="!validation.details ? validateDetails(): ''">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>
                            Middle name
                          </label>
                        </div>
                        <div class="card-body-content">
                          <input type="text" class="form-control" placeholder="Insert middle name"
                            [(ngModel)]="candidate.middle_name" [ngModelOptions]="{standalone: true}">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>
                            * Last name
                            <span *ngIf="!validation.details && !candidate.last_name"
                              [ngClass]="{'primary-color': !candidate.last_name}">
                              (Required field)
                            </span>
                          </label>
                        </div>
                        <div class="card-body-content">
                          <input type="text" class="form-control" placeholder="Insert last name"
                            [(ngModel)]="candidate.last_name" [ngModelOptions]="{standalone: true}" appOnFocusOut
                            (focusout)="!validation.details ? validateDetails(): ''">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>
                            * Email
                            <span *ngIf="!validation.email"
                              [ngClass]="{'primary-color': !validation.email}">
                              (Invalid data)
                            </span>
                          </label>
                          <span class="pull-right secondary-color cursor-pointer"
                            (click)="generateToBeFoundEmail()">Generate 'to be found' email</span>
                        </div>
                        <div class="card-body-content">
                          <input type="email" class="form-control" name="email" placeholder="Insert email"
                            [(ngModel)]="candidate.email" [ngModelOptions]="{standalone: true}" appOnFocusOut
                            (focusout)="checkCandidateField('email')">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>
                            * Gender
                            <span *ngIf="!validation.details && !candidate.gender"
                              [ngClass]="{'primary-color': !candidate.gender}">
                              (Required field)
                            </span>
                          </label>
                        </div>
                        <div class="card-body-content">
                          <ng-select bindLabel="description" dropdownPosition="bottom"
                            [placeholder]="globals.labels.placeholders.inputs.full_layout_page.omni_component.contact.gender"
                            [items]="resources.gender_type" (open)="getResourcesByType('gender_type')"
                            [(ngModel)]="candidate.gender" [ngModelOptions]="{standalone: true}"
                            (change)="!validation.details ? validateDetails(): ''"></ng-select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>
                            Linkedin
                            <span *ngIf="!validation.linkedin"
                              [ngClass]="{'primary-color': !validation.linkedin}">
                              (Invalid data)
                            </span>
                          </label>
                        </div>
                        <div class="card-body-content">
                          <input type="text" class="form-control" placeholder="Insert Linkedin"
                            [(ngModel)]="candidate.linkedin_url" [ngModelOptions]="{standalone: true}"
                            (focusout)="checkCandidateField('linkedin_url')">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>
                            Xing
                          </label>
                        </div>
                        <div class="card-body-content">
                          <input type="text" class="form-control" placeholder="Insert xing" [(ngModel)]="candidate.xing"
                            [ngModelOptions]="{standalone: true}">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>
                            Source
                          </label>
                        </div>
                        <div class="card-body-content">
                          <ng-select bindLabel="description" dropdownPosition="bottom"
                            [(ngModel)]="candidate.source_type" placeholder="Select source" [items]="resources.source_type"
                            (open)="getResourcesByType('source_type')">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>
                            Current location
                          </label>
                        </div>
                        <div class="card-body-content">
                          <ng-select
                            [items]="locations$ | async"
                            bindLabel="description"
                            placeholder="Select location"
                            dropdownPosition="bottom"
                            [minTermLength]="minLengthTerm"
                            [loading]="locationLoading"
                            typeToSearchText="Please enter {{minLengthTerm}} or more characters"
                            [typeahead]="locationInput$"
                            [(ngModel)]="candidate.location">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>
                            Nationality
                          </label>
                        </div>
                        <div class="card-body-content">
                          <ng-select appendTo=".ng-accordion-append" bindLabel="demonym" dropdownPosition="bottom"
                            [items]="resources.nationalities | async" [loading]="nationalityLoading"
                            [typeahead]="nationalityinput" placeholder="Select nationality"
                            [(ngModel)]="candidate.nationality"></ng-select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>
                            Ranking
                          </label>
                        </div>
                        <div class="card-body-content">
                          <input type="text" class="form-control" placeholder="Insert ranking"
                            [(ngModel)]="candidate.ranking" [ngModelOptions]="{standalone: true}">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>
                            Skype
                          </label>
                        </div>
                        <div class="card-body-content">
                          <input type="text" class="form-control" placeholder="Insert skype"
                            [(ngModel)]="candidate.skype" [ngModelOptions]="{standalone: true}">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>
                            Phone
                          </label>
                        </div>
                        <div class="card-body-content">
                          <input type="text" class="form-control" placeholder="Insert phone"
                            [(ngModel)]="candidate.phone" [ngModelOptions]="{standalone: true}">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>
                            Mobile
                          </label>
                        </div>
                        <div class="card-body-content">
                          <input type="text" class="form-control" placeholder="Insert mobile"
                            [(ngModel)]="candidate.mobile" [ngModelOptions]="{standalone: true}">
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <!-- <ngb-panel id="compensation">
                <ng-template ngbPanelHeader>
                  <div class="form" (click)="accordion.compensation = !accordion.compensation">
                    <div class="form-section">
                      Compensation
                      <i class="pull-right mt-1 pt-2"
                        [ngClass]="{'ft-chevron-right': !accordion.compensation,'ft-chevron-down': accordion.compensation}"></i>
                    </div>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="row card-block">
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>
                            Base
                          </label>
                        </div>
                        <div class="card-body-content">
                          <input type="text" pattern="[0-9]*" (keydown)=preventNegativeNumbers($event)
                            class="form-control" placeholder="Insert base salary"
                            [(ngModel)]="candidate.compensation.base_salary" [ngModelOptions]="{standalone: true}">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>
                            Currency
                          </label>
                        </div>
                        <div class="card-body-content">
                          <ng-select bindLabel="description" dropdownPosition="bottom"
                            [placeholder]="globals.labels.placeholders.inputs.full_layout_page.cit_component.next_steps.final_steps.currency"
                            [items]="resources.currencies" (open)="getResourcesByType('currencies')"
                            [(ngModel)]="candidate.compensation.currency"></ng-select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>
                            Bonus
                          </label>
                        </div>
                        <div class="card-body-content">
                          <input type="text" pattern="[0-9]*" (keydown)=preventNegativeNumbers($event)
                            class="form-control" placeholder="Insert bonus"
                            [(ngModel)]="candidate.compensation.bonus_salary" [ngModelOptions]="{standalone: true}">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>
                            Type
                          </label>
                        </div>
                        <div class="card-body-content">
                          <ng-select bindLabel="description" dropdownPosition="bottom"
                            [placeholder]="globals.labels.placeholders.inputs.full_layout_page.cit_component.next_steps.final_steps.currency"
                            [items]="resources.bonus_type" (open)="getResourcesByType('bonus_type')"
                            [(ngModel)]="candidate.compensation.bonus_type"></ng-select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>
                            Equity
                          </label>
                        </div>
                        <div class="card-body-content">
                          <textarea autosize [(ngModel)]="candidate.compensation.equity"
                            [ngModelOptions]="{standalone: true}" class="form-control" type="text"
                            [placeholder]="globals.labels.placeholders.inputs.full_layout_page.cit_component.next_steps.final_steps.equity"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel> -->
            </ngb-accordion>
          </div>
        </ng-template>
      </ng-container>
      <ng-container [ngbNavItem]="'ngb-tab-1'">
        <a ngbNavLink>Languages
          <span class="badge m-0 badge-primary" *ngIf="!validation.language">
            <i class="fa fa-circle"></i>
          </span>
        </a>
        <ng-template ngbNavContent>
          <div class="row card-block">
            <div class="col-12">
              <div class="form-group pull-right">
                <button type="button" class="mt-3 mr-3 btn pull-right bg-secondary-color" (click)="addNewLanguage()">
                  Add new language
                </button>
              </div>
            </div>
          </div>
          <div class="card card-top scroll scroll-add-tabs">
            <div *ngFor="let language of candidate.languages;let i = index">
              <ngb-accordion id="filter-accordion" [(activeIds)]="activeIds.languages" #accordionLanguages="ngbAccordion" class="ng-accordion-append"
                (panelChange)="preventDefault.languages[i] ? beforeChange($event,i) : ''">
                <ngb-panel id="language{{i}}">
                  <ng-template ngbPanelHeader>
                    <div class="form" (click)="accordionLanguages.toggle('language'+i);accordion.language[i] = !accordion.language[i]">
                      <div class="form-section">
                        Language #{{i+1}}
                        <i class="pull-right mt-1 pt-2"
                          [ngClass]="{'ft-chevron-right': accordion.language[i],'ft-chevron-down': !accordion.language[i]}"></i>
                        <a (click)="deleteLanguage(i, $event)">
                          <i class="icon icon-trash pull-right m-2 p-1" data-toggle="tooltip" data-placement="bottom"
                            [title]="globals.labels.tooltips.global.delete"></i>
                        </a>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="card-body">
                      <div class="row card-block">
                        <div class="col-md-6 col-12">
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>
                                Language
                              </label>
                            </div>
                            <div class="card-body-content">
                              <ng-select appendTo=".ng-accordion-append" [items]="resources.languages | async" bindLabel="description" bindValue="description"
                                [hideSelected]="true" [loading]="languageLoading" [typeahead]="languageinput"
                                [(ngModel)]="language.language" placeholder="Select language">
                              </ng-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-12">
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>
                                Level
                              </label>
                            </div>
                            <div class="card-body-content">
                              <ng-select appendTo=".ng-accordion-append" bindLabel="description" dropdownPosition="bottom"
                                (open)="getResources('languageLevels')" placeholder="Select level"
                                [items]="resources.languageLevels" [(ngModel)]="language.level"
                                [ngModelOptions]="{standalone: true}">
                              </ng-select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
          </div>
        </ng-template>
      </ng-container>
      <ng-container [ngbNavItem]="'ngb-tab-2'">
        <a ngbNavLink>Experience
          <span class="badge m-0 badge-primary" *ngIf="!validation.experience">
            <i class="fa fa-circle"></i>
          </span>
        </a>
        <ng-template ngbNavContent>
          <div class="row card-block">
            <div class="col-12">
              <div class="form-group pull-right">
                <button type="button" class="btn mt-3 mr-3 pull-right bg-secondary-color" (click)="addNewExperience()">
                  Add new experience
                </button>
              </div>
            </div>
          </div>
          <div class="card card-top scroll scroll-add-tabs">
            <div *ngFor="let experience of candidate.experiences;let i = index">
              <ngb-accordion id="filter-accordion" [(activeIds)]="activeIds.experience"  #accordionExperience="ngbAccordion" class="ng-accordion-append"
                (panelChange)="preventDefault.experience[i] ? beforeChange($event,i) : ''">
                <ngb-panel id="experience{{i}}">
                  <ng-template ngbPanelHeader>
                    <div class="form" (click)="accordionExperience.toggle('experience'+i);accordion.experience[i] = !accordion.experience[i]">
                      <div class="form-section">
                        Experience #{{i+1}}
                        <i class="pull-right mt-1 pt-2"
                          [ngClass]="{'ft-chevron-right': accordion.experience[i],'ft-chevron-down': !accordion.experience[i]}"></i>
                        <a (click)="deleteExperience(i, $event)">
                          <i class="icon icon-trash pull-right m-2 p-1" data-toggle="tooltip" data-placement="bottom"
                            [title]="globals.labels.tooltips.global.delete"></i>
                        </a>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="card-body">
                      <div class="row card-block">
                        <div class="col-md-6 col-12">
                          <div class="form-group">
                            <div class="card-body-content">
                              <div class="custom-control custom-checkbox m-0">
                                <input type="checkbox" [ngModelOptions]="{standalone: true}"
                                  [(ngModel)]="experience.is_investment" class="custom-control-input" id="investment{{i}}">
                                <label class="custom-control-label" for="investment{{i}}">Is investment
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>
                                * Title
                                <span *ngIf="!validation.experience && !experience.title"
                                  [ngClass]="{'primary-color': !experience.title}">
                                  (Required field)
                                </span>
                              </label>
                            </div>
                            <div class="card-body-content">
                              <input type="text" class="form-control" placeholder="Insert job title"
                                [(ngModel)]="experience.title" [ngModelOptions]="{standalone: true}" appOnFocusOut
                                (focusout)="!validation.experience ? validateExperience(): ''">
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>
                                * Company
                                <span *ngIf="!validation.experience && !experience.company"
                                  [ngClass]="{'primary-color': !experience.company}">
                                  (Required field)
                                </span>
                                <span *ngIf="experience.is_blacklisted"
                                  class="badge-tags-inverse nowrap mt-2 mr-1 display-inline-flex bg-error-color white">
                                  Blacklisted
                                </span>
                              </label>
                            </div>
                            <div class="card-body-content">
                              <input type="text" class="form-control" placeholder="Insert company name"
                                [(ngModel)]="experience.company" [ngModelOptions]="{standalone: true}" appOnFocusOut
                                (focusout)="!validation.experience ? validateExperience(): ''">
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>
                                * Start date
                                <span *ngIf="!validation.experience && !experience.from"
                                  [ngClass]="{'primary-color': !experience.from}">
                                  (Required field)
                                </span>
                              </label>
                            </div>
                            <div class="card-body-content">
                              <input type="date" [(ngModel)]="experience.from" [ngModelOptions]="{standalone: true}"
                                class="form-control" appOnFocusOut
                                (focusout)="!validation.experience ? validateExperience(): ''">
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>
                                End date
                              </label>
                            </div>
                            <div class="card-body-content">
                              <input type="date" [(ngModel)]="experience.to" [ngModelOptions]="{standalone: true}"
                                class="form-control">
                            </div>
                          </div>
                          <div class="form-group" *ngIf="!experience.to && experience.from">
                            <div class="card-body-content">
                              <div class="custom-control custom-checkbox m-0">
                                <input type="checkbox" [ngModelOptions]="{standalone: true}"
                                  [(ngModel)]="experience.is_primary" (change)="isPrimary($event,i)"
                                  class="custom-control-input" id="is_primary+{{i}}">
                                <label class="custom-control-label"
                                  for="is_primary+{{i}}">{{globals.labels.placeholders.full_layout_page.omni_component.experience_education.primary_job}}
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="form-group mb-2">
                            <div class="card-body-title-basic">
                              <label>
                                Description
                              </label>
                            </div>
                            <div class="card-body-content">
                              <div class="row">
                                <div class="col-12 form-group">
                                  <textarea rows="3" class="form-control" type="text" [(ngModel)]="experience.summary"
                                    [ngModelOptions]="{standalone: true}" placeholder="Add Description"></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-12">
                          <div class="form-group">
                            <div class="card-body-content">
                              <div class="custom-control custom-checkbox m-0">
                                <input type="checkbox" [ngModelOptions]="{standalone: true}"
                                  [(ngModel)]="experience.not_applicable" class="custom-control-input"
                                  id="not_applicable{{i}}">
                                <label class="custom-control-label" for="not_applicable{{i}}">Not Applicable
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>
                                Location
                              </label>
                            </div>
                            <div class="card-body-content">
                              <ng-select
                                [items]="locations$ | async"
                                bindLabel="description"
                                placeholder="Select location"
                                dropdownPosition="bottom"
                                [minTermLength]="minLengthTerm"
                                [loading]="locationLoading"
                                typeToSearchText="Please enter {{minLengthTerm}} or more characters"
                                [typeahead]="locationInput$"
                                [(ngModel)]="experience.location">
                              </ng-select>
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>
                                Seniority
                                <span
                                  *ngIf="activateLonglistValidation && !validation.experience && !experience.seniority && !experience.not_applicable"
                                  [ngClass]="{'primary-color': !experience.seniority}">
                                  (Required field)
                                </span>
                              </label>
                            </div>
                            <div class="card-body-content">
                              <ng-select
                                [placeholder]="globals.labels.placeholders.inputs.full_layout_page.omni_component.experience_education.seniority"
                                [items]="resources.role_seniority" bindLabel="description" dropdownPosition="bottom"
                                [(ngModel)]="experience.seniority" [ngModelOptions]="{standalone: true}"
                                (open)="getResourcesByType('role_seniority')"
                                (change)="!validation.experience && activateLonglistValidation ? validateExperience(): ''">
                              </ng-select>
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>
                                Functional expertise
                                <span
                                  *ngIf="activateLonglistValidation && !validation.experience && !experience.fe.length && !experience.not_applicable"
                                  [ngClass]="{'primary-color': !experience.fe.length}">
                                  (Required field)
                                </span>
                              </label>
                            </div>
                            <div class="card-body-content">
                              <ng-select appendTo=".ng-accordion-append" [items]="resources.functionalExpertise"
                                (open)="getResources('functionalExpertise')" [multiple]="true"
                                [(ngModel)]="experience.fe" [ngModelOptions]="{standalone: true}"
                                [placeholder]="globals.labels.placeholders.inputs.full_layout_page.omni_component.experience_education.functional"
                                bindLabel="description" (remove)="onUnselectFunctional(experience)"
                                (clear)="experience.sfe = []"
                                (change)="!validation.experience && activateLonglistValidation ? validateExperience(): ''">
                              </ng-select>
                            </div>
                          </div>
                          <div class="form-group d-none">
                            <div class="card-body-title-basic">
                              <label>
                                Sub Functional expertise
                                <span
                                  *ngIf="activateLonglistValidation && !validation.experience && !experience.sfe.length && !experience.not_applicable"
                                  [ngClass]="{'primary-color': !experience.sfe.length}">
                                  (Required field)
                                </span>
                              </label>
                            </div>
                            <div class="card-body-content">
                              <ng-select appendTo=".ng-accordion-append" [items]="resources.filteredSfe" (open)="getFilteredSfe(experience.fe)"
                                [disabled]="!experience.fe.length"
                                [placeholder]="globals.labels.placeholders.inputs.full_layout_page.omni_component.experience_education.sub_functional"
                                bindLabel="description" [multiple]="true" [(ngModel)]="experience.sfe"
                                [ngModelOptions]="{standalone: true}"
                                (change)="!validation.experience && activateLonglistValidation ? validateExperience(): ''">
                              </ng-select>
                            </div>
                          </div>
                          <div class="form-group mb-0">
                            <div class="card-body-title-basic">
                              <label>
                                Expertise
                              </label>
                            </div>
                            <div class="card-body-content">
                              <div class="row">
                                <div class="col-12 form-group">
                                  <input class="form-control" type="text" [(ngModel)]="experience.exp"
                                    [ngModelOptions]="{standalone: true}"
                                    [placeholder]="globals.labels.placeholders.inputs.full_layout_page.omni_component.experience_education.expertise"
                                    (keyup.enter)="addExpertise(experience)" />
                                </div>
                                <div class="col-12 form-group" *ngIf="experience.expertise">
                                  <tag-input id="expertise-input" [(ngModel)]="experience.expertise"
                                    [ngModelOptions]="{standalone: true}" [identifyBy]="'description'"
                                    [displayBy]="'description'">
                                  </tag-input>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12" *ngIf="experience.is_investment">
                          <div class="form">
                            <div class="form-section">
                              <label>VC fields</label>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-4 col-12">
                              <div class="form-group">
                                <div class="card-body-title-basic">
                                  <label>
                                    Country focus
                                  </label>
                                </div>
                                <div class="card-body-content">
                                  <ng-select bindLabel="name" dropdownPosition="bottom"
                                    [items]="resources.country | async" [loading]="countryLoading"
                                    [typeahead]="countryinput" placeholder="Select country" [multiple]="true"
                                    [(ngModel)]="experience.investment_country_focus"></ng-select>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4 col-12">
                              <div class="form-group">
                                <div class="card-body-title-basic">
                                  <label>
                                    Stage focus
                                  </label>
                                </div>
                                <div class="card-body-content">
                                  <ng-select bindLabel="description" dropdownPosition="bottom"
                                    [items]="resources.investmentStage" (open)="getResources('investmentStage')"
                                    placeholder="Select stage" [multiple]="true"
                                    [(ngModel)]="experience.investment_stage_focus"></ng-select>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4 col-12">
                              <div class="form-group">
                                <div class="card-body-title-basic">
                                  <label>
                                    Sector focus
                                  </label>
                                </div>
                                <div class="card-body-content">
                                  <ng-select bindLabel="description" dropdownPosition="bottom"
                                    [items]="resources.investmentSector | async" [loading]="sectorLoading"
                                    [addTag]="addSectorTag.bind(this)" [typeahead]="sectorinput" placeholder="Select sector"
                                    [multiple]="true" [(ngModel)]="experience.investment_sector_focus"></ng-select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
          </div>
        </ng-template>
      </ng-container>
      <ng-container [ngbNavItem]="'ngb-tab-3'">
        <a ngbNavLink>Education
          <span class="badge m-0 badge-primary" *ngIf="!validation.education">
            <i class="fa fa-circle"></i>
          </span>
        </a>
        <ng-template ngbNavContent>
          <div class="row card-block">
            <div class="col-12">
              <div class="form-group pull-right">
                <button type="button" class="btn mt-3 mr-3 pull-right bg-secondary-color" (click)="addNewEducation()">
                  Add new edication
                </button>
              </div>
            </div>
          </div>
          <div class="card card-top scroll scroll-add-tabs">
            <div *ngFor="let education of candidate.educations;let i = index">
              <ngb-accordion id="filter-accordion" [(activeIds)]="activeIds.education" #accordionEducation="ngbAccordion" class="ng-accordion-append"
                (panelChange)="preventDefault.education[i] ? beforeChange($event,i) : ''">
                <ngb-panel id="education{{i}}">
                  <ng-template ngbPanelHeader>
                    <div class="form" (click)="accordionEducation.toggle('education'+i);accordion.education[i] = !accordion.education[i]">
                      <div class="form-section">
                        Education #{{i+1}}
                        <i class="pull-right mt-1 pt-2"
                          [ngClass]="{'ft-chevron-right': accordion.education[i],'ft-chevron-down': !accordion.education[i]}"></i>
                        <a (click)="deleteEducation(i, $event)">
                          <i class="icon icon-trash pull-right m-2 p-1" data-toggle="tooltip" data-placement="bottom"
                            [title]="globals.labels.tooltips.global.delete"></i>
                        </a>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="card-body">
                      <div class="row card-block">
                        <div class="col-12">
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>
                                * University
                                <span *ngIf="!validation.education && !education.university"
                                  [ngClass]="{'primary-color': !education.university}">
                                  (Required field)
                                </span>
                              </label>
                            </div>
                            <div class="card-body-content">
                              <input type="text" class="form-control" placeholder="Insert university"
                                [(ngModel)]="education.university" [ngModelOptions]="{standalone: true}" appOnFocusOut
                                (focusout)="!validation.education ? validateEducation(): ''">
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>
                                * Degree
                                <span *ngIf="!validation.education && !education.degree"
                                  [ngClass]="{'primary-color': !education.degree}">
                                  (Required field)
                                </span>
                              </label>
                            </div>
                            <div class="card-body-content">
                              <input type="text" class="form-control" placeholder="Insert degree"
                                [(ngModel)]="education.degree" [ngModelOptions]="{standalone: true}" appOnFocusOut
                                (focusout)="!validation.education ? validateEducation(): ''">
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>
                                Major
                              </label>
                            </div>
                            <div class="card-body-content">
                              <input type="text" class="form-control" placeholder="Insert major"
                                [(ngModel)]="education.major" [ngModelOptions]="{standalone: true}">
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>
                                Start date
                              </label>
                            </div>
                            <div class="card-body-content">
                              <input type="date" [(ngModel)]="education.from" [ngModelOptions]="{standalone: true}"
                                class="form-control">
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>
                                End date
                              </label>
                            </div>
                            <div class="card-body-content">
                              <input type="date" [(ngModel)]="education.to" [ngModelOptions]="{standalone: true}"
                                class="form-control">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
          </div>
        </ng-template>
      </ng-container>
      <ng-container [ngbNavItem]="'ngb-tab-4'">
        <a ngbNavLink>Jobs
          <span class="badge m-0 badge-primary" *ngIf="!validation.job">
            <i class="fa fa-circle"></i>
          </span>
        </a>
        <ng-template ngbNavContent>
          <div class="card card-top scroll scroll-no-add">
            <ngb-accordion id="filter-accordion" [(activeIds)]="activeIds.jobs"  #accordionJobs="ngbAccordion" class="ng-accordion-append"
              (panelChange)="preventDefault.jobs ? beforeChange($event) : ''">
              <ngb-panel id="jobs" *ngIf="globals.checkIfExists('permission', 'action_candidates')">
                <ng-template ngbPanelHeader>
                  <div class="form" (click)="accordionJobs.toggle('jobs');accordion.jobs = !accordion.jobs">
                    <div class="form-section">
                      Add to job
                      <i class="pull-right mt-1 pt-2"
                        [ngClass]="{'ft-chevron-right': !accordion.jobs,'ft-chevron-down': accordion.jobs}"></i>
                    </div>
                    <div *ngIf="(job.stage && job.stage.value =='longlist') && inProcess">
                      <span class="badge-tags-inverse  align-self-center bg-warning-color">
                        This candidate is already <strong>in processs</strong> , please check availability before continuing
                      </span>
                    </div>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="card-body">
                    <div class="row card-block">
                      <div class="form-group col-6">
                        <div class="card-body-title-basic">
                          <label>
                            Jobs
                          </label>
                        </div>
                        <div class="card-body-content">
                          <ng-select appendTo=".ng-accordion-append" bindLabel="job_title" bindValue="uuid" dropdownPosition="bottom"
                            [placeholder]="globals.labels.placeholders.inputs.full_layout_page.omni_component.job.job"
                            [items]="resources.jobs" [(ngModel)]="job.job_uuid" [ngModelOptions]="{standalone: true}"
                            (open)="getJobsResources()" (clear)="job = {}"></ng-select>
                        </div>
                      </div>
                      <div class="form-group col-6" *ngIf="job.job_uuid">
                        <div class="card-body-title-basic">
                          <label>
                            * Stage
                            <span *ngIf="!validation.job && !job.stage"
                              [ngClass]="{'primary-color': !validation.job && !job.stage}">
                              (Required field)
                            </span>
                          </label>
                        </div>
                        <div class="card-body-content">
                          <ng-select appendTo=".ng-accordion-append" bindLabel="description" dropdownPosition="bottom" placeholder="Job stage"
                            [items]="resources.stages" [(ngModel)]="job.stage" [ngModelOptions]="{standalone: true}"
                            (open)="getApplicationStagesResources()" (clear)="job.substatus = null;job.comment = null;"
                            (change)="validateJob()">
                          </ng-select>
                        </div>
                      </div>
                      <div class="form-group col-6"
                        *ngIf="job.stage && job.stage.value == 'longlist'">
                        <div class="card-body-title-basic">
                          <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                            {{globals.labels.placeholders.full_layout_page.omni_component.job.sub_status}}
                            <span *ngIf="!validation.job && !job.substatus"
                              [ngClass]="{'primary-color': !validation.job && !job.substatus}">
                              (Required field)
                            </span>
                          </label>
                        </div>
                        <div class="card-body-content">
                          <ng-select appendTo=".ng-accordion-append" bindLabel="description" dropdownPosition="bottom"
                            [placeholder]="globals.labels.placeholders.inputs.full_layout_page.omni_component.job.sub_status"
                            [items]="resources.subStatuses" [(ngModel)]="job.substatus"
                            [ngModelOptions]="{standalone: true}" (open)="getResources('subStatuses')"
                            (clear)="job.comment = null" (change)="!validation.job ? validateJob(): ''">
                          </ng-select>
                        </div>
                      </div>
                      <div [ngClass]="ratingCSS(job)" *ngIf="job.job_uuid">
                        <div class="card-body-title-basic">
                          <label> Rating </label>
                        </div>
                        <div class="card-body-content">
                          <ng-select appendTo=".ng-accordion-append" bindLabel="description" dropdownPosition="bottom"
                            placeholder="Select Rating"
                            [items]="resources.candidate_application_rating" [(ngModel)]="job.candidate_rating"
                            [ngModelOptions]="{standalone: true}" (open)="getResourcesByType('candidate_application_rating')"
                            (clear)="job.comment = null" (change)="!validation.job ? validateJob(): ''">
                          </ng-select>
                        </div>
                      </div>
                      <div class="form-group col-12" *ngIf="job.stage">
                        <div class="card-body-title-basic">
                          <label>Comment section</label>
                        </div>
                        <div class="card-body-content">
                          <textarea [(ngModel)]="job.comment" class="form-control"
                            placeholder="Enter comment"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="openJobs" *ngIf="updateCandidate">
                <ng-template ngbPanelHeader>
                  <div class="form" (click)="accordionJobs.toggle('openJobs');accordion.openJobs = !accordion.openJobs">
                    <div class="form-section">
                      Open jobs
                      <i class="pull-right mt-1 pt-2"
                        [ngClass]="{'ft-chevron-right': !accordion.openJobs,'ft-chevron-down': accordion.openJobs}"></i>
                    </div>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div
                    *ngIf="candidate.job_history && candidate.job_history.open.length;then showOpenJobs else showEmptyOpenJobs">
                  </div>
                  <ng-template #showOpenJobs>
                    <div class="row card-block">
                      <div class="col-12">
                        <div *ngFor="let openJob of candidate.job_history.open">
                          <div class="card-body-title-basic">
                            <label>{{openJob.job}}</label>
                            <span *ngIf="openJob.rating" data-toggle="tooltip" data-placement="right" class="badge-tags-inverse ml-3 bg-success">{{openJob.rating.description}}</span>
                          </div>
                          <div class="card-body-content">
                            <div class="row"
                              *ngIf="openJob.stage.value || (openJob.substatus && openJob.substatus.description)">
                              <div class="col-6" *ngIf="openJob.stage.value">
                                <div class="form-group">
                                  Stage
                                  <ng-template #tooltipOpen>
                                    <div class="card card-preview">
                                      <div class="card-body">
                                        <div class="card-body-title-basic">
                                          Stage: {{openJob.stage.description}}
                                        </div>
                                        <div class="card-body-content">
                                          <div *ngIf="openJob.shortlisted_date">
                                            Shortlisted date:
                                            {{openJob.shortlisted_date | dateDiffTimestampFullNav:openJob.shortlisted_date}}
                                          </div>
                                          <div *ngIf="openJob.sent_date">
                                            Sent date:
                                            {{openJob.sent_date | dateDiffTimestampFullNav:openJob.sent_date}}
                                          </div>
                                          <div *ngIf="openJob.first_interview_date">
                                            First interview date:
                                            {{openJob.first_interview_date | dateDiffTimestampFullNav:openJob.first_interview_date}}
                                          </div>
                                          <div *ngIf="openJob.second_interview_date">
                                            Second interview date:
                                            {{openJob.second_interview_date | dateDiffTimestampFullNav:openJob.second_interview_date}}
                                          </div>
                                          <div *ngIf="openJob.offer_date">
                                            Offer date:
                                            {{openJob.offer_date | dateDiffTimestampFullNav:openJob.offer_date}}
                                          </div>
                                          <div *ngIf="openJob.placed_date">
                                            Placed date:
                                            {{openJob.placed_date | dateDiffTimestampFullNav:openJob.placed_date}}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </ng-template>
                                  <ng-template #t let-fill="fill" let-last="last" let-index="index">
                                    <span class="bullet" [class.full]="fill === 100">
                                      <span class="bullet"
                                        [ngClass]="fill === 100 && (openJob.substatus && openJob.substatus.success) ? 'success' : index == openJob.stage.current_stage-1 ? 'error' : 'success'"
                                        [style.width.%]="fill" [ngbTooltip]="tooltipOpen" placement="auto"
                                        [innerHTML]="globals.labels.placeholders.full_layout_page.omni_component.job.job_history.stage_rating"></span>
                                      <span
                                        [innerHTML]="globals.labels.placeholders.full_layout_page.omni_component.job.job_history.stage_rating"></span>
                                    </span>
                                  </ng-template>
                                  <ngb-rating [(rate)]="openJob.stage.current_stage" [starTemplate]="t"
                                    [readonly]="true" [max]="openJob.stage.max_stage">
                                  </ngb-rating>
                                </div>
                              </div>
                              <div class="col-6" *ngIf="openJob.substatus && openJob.substatus.description">
                                <div class="form-group">
                                  <span class="badge m-0" [ngStyle]="{'color': openJob.substatus.color_code}">
                                    <i class="fa fa-circle"></i>
                                  </span>
                                  <label>{{openJob.substatus.description}}</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template #showEmptyOpenJobs>
                    <div class="row card-block">
                      <div class="col-12">
                        This candidate is not shortlisted on any ongoing jobs
                      </div>
                    </div>
                  </ng-template>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="closedJobs" *ngIf="updateCandidate">
                <ng-template ngbPanelHeader>
                  <div class="form" (click)="accordionJobs.toggle('closedJobs');accordion.closedJobs = !accordion.closedJobs">
                    <div class="form-section">
                      Closed jobs
                      <i class="pull-right mt-1 pt-2"
                        [ngClass]="{'ft-chevron-right': !accordion.closedJobs,'ft-chevron-down': accordion.closedJobs}"></i>
                    </div>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div
                    *ngIf="candidate.job_history && candidate.job_history.closed.length;then showClosedJobs else showEmptyClosedJobs">
                  </div>
                  <ng-template #showClosedJobs>
                    <div class="row card-block">
                      <div class="col-12">
                        <div *ngFor="let closedJob of candidate.job_history.closed">
                          <div class="card-body-title-basic">
                            <label>{{closedJob.job}}</label>
                            <span *ngIf="closedJob.rating" data-toggle="tooltip" data-placement="right" class="badge-tags-inverse ml-3 bg-success">{{closedJob.rating.description}}</span>
                          </div>
                          <div class="card-body-content">
                            <div class="row"
                              *ngIf="closedJob.stage.value || (closedJob.substatus && closedJob.substatus.description)">
                              <div class="col-6" *ngIf="closedJob.stage.value">
                                <div class="form-group">
                                  Stage
                                  <ng-template #tooltipClose>
                                    <div class="card card-preview">
                                      <div class="card-body">
                                        <div class="card-body-title-basic">
                                          Stage: {{closedJob.stage.description}}
                                        </div>
                                        <div class="card-body-content">
                                          <div *ngIf="closedJob.shortlisted_date">
                                            Shortlisted date:
                                            {{closedJob.shortlisted_date | dateDiffTimestampFullNav:closedJob.shortlisted_date}}
                                          </div>
                                          <div *ngIf="closedJob.sent_date">
                                            Sent date:
                                            {{closedJob.sent_date | dateDiffTimestampFullNav:closedJob.sent_date}}
                                          </div>
                                          <div *ngIf="closedJob.first_interview_date">
                                            First interview date:
                                            {{closedJob.first_interview_date | dateDiffTimestampFullNav:closedJob.first_interview_date}}
                                          </div>
                                          <div *ngIf="closedJob.second_interview_date">
                                            Second interview date:
                                            {{closedJob.second_interview_date | dateDiffTimestampFullNav:closedJob.second_interview_date}}
                                          </div>
                                          <div *ngIf="closedJob.offer_date">
                                            Offer date:
                                            {{closedJob.offer_date | dateDiffTimestampFullNav:closedJob.offer_date}}
                                          </div>
                                          <div *ngIf="closedJob.placed_date">
                                            Placed date:
                                            {{closedJob.placed_date | dateDiffTimestampFullNav:closedJob.placed_date}}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </ng-template>
                                  <ng-template #t let-fill="fill" let-last="last" let-index="index">
                                    <span class="bullet" [class.full]="fill === 100">
                                      <span class="bullet"
                                        [ngClass]="fill === 100 && (closedJob.substatus && closedJob.substatus.success) ? 'success' : index == closedJob.stage.current_stage-1 ? 'error' : 'success'"
                                        [style.width.%]="fill" [ngbTooltip]="tooltipClose" placement="auto"
                                        [innerHTML]="globals.labels.placeholders.full_layout_page.omni_component.job.job_history.stage_rating"></span>
                                      <span
                                        [innerHTML]="globals.labels.placeholders.full_layout_page.omni_component.job.job_history.stage_rating"></span>
                                    </span>
                                  </ng-template>
                                  <ngb-rating [(rate)]="closedJob.stage.current_stage" [starTemplate]="t"
                                    [readonly]="true" [max]="closedJob.stage.max_stage">
                                  </ngb-rating>
                                </div>
                              </div>
                              <div class="col-6" *ngIf="closedJob.substatus && closedJob.substatus.description">
                                <div class="form-group">
                                  <span class="badge m-0" [ngStyle]="{'color': closedJob.substatus.color_code}">
                                    <i class="fa fa-circle"></i>
                                  </span>
                                  <label>{{closedJob.substatus.description}}</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template #showEmptyClosedJobs>
                    <div class="row card-block">
                      <div class="col-12">
                        No information available
                      </div>
                    </div>
                  </ng-template>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </ng-template>
      </ng-container>
    </nav>
    <div [ngbNavOutlet]="nav"></div>
    <div class="position-bottom full-width">
      <div class="pull-left ml-4 pl-3">
        <label><strong>Important: You can always drag and drop resume on the screen or
            <button type="button" class="btn bg-secondary-color ml-2 mr-2" (click)="fileInput.click()"> upload </button>
            here.
          </strong></label>
        <input class="hiddenInput m-0 p-0 position-absolute overflow-hidden" type="file" ng2FileSelect
          [uploader]="uploaderFile" #fileInput (change)="OnContentChange($event, 'uploaderFile')">
      </div>
      <button type="button" class="btn pull-right bg-secondary-color" [disabled]="uploadingData"
        (click)="createUpdateCandidate(candidate.exists)">{{candidate.exists ? globals.labels.placeholders.global.buttons.update : globals.labels.placeholders.global.buttons.create }}
      </button>
    </div>
  </div>
</div>
