import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpClient } from "@angular/common/http";
import { environment } from 'environments/environment';
import { Angulartics2 } from 'angulartics2';

@Injectable()
export class ViewContactService{

  constructor(private angulartics2: Angulartics2, private httpClient: HttpClient){}

  getContactActivities(contactUuid): Observable<any>{
    this.angulartics2.eventTrack.next({
      action: 'Loaded contact activities',
      properties: { contactUuid }
    });

    return this.httpClient.get(environment.platformBackendURL + '/activities?contact_id=' + contactUuid);
  }

  getContactData(contact): Observable<any>{
        return this.httpClient.get(environment.platformBackendURL + '/contacts/' + contact);
  }

  getCompany(company): Observable<any>{
    return this.httpClient.get(environment.platformBackendURL + '/companies?name=' + company);
  }

  addCompany(company): Observable<any>{
    this.angulartics2.eventTrack.next({
      action: 'Added job company',
      properties: { company }
    });

    const body = {
      "company_name": company
    };
    return this.httpClient.post(environment.platformBackendURL + '/companies', body);
  }

  updateContact(uuid, body){
    return this.httpClient.put(environment.platformBackendURL + '/contacts/' + uuid, body);
  }

  deleteContact(uuid){
    return this.httpClient.delete(environment.platformBackendURL + '/contacts/' + uuid);
  }
}
