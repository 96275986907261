import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GetnavisCandidateTriggerTypeModalService{

  constructor(private http: HttpClient){ }

  putCandidateTriggerType(campaign, candidate, response): Observable<any>{
    const body = {
      step: response
    };
    return this.http.put(environment.getNavisBackendURL + '/campaigns/' + campaign + '/candidates/' + candidate, body);
  }

  getTriggersResources(type): Observable<any>{
    return this.http.get(environment.getNavisBackendURL + '/resources?type=' + type);
  }

}
