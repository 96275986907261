import { Component, OnInit } from '@angular/core';
import { Globals } from 'app/globals';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-general-confirmation-modal',
  templateUrl: './general-confirmation-modal.component.html',
  styleUrls: ['./general-confirmation-modal.component.scss']
})
export class GeneralConfirmationModalComponent implements OnInit{

  confirmationMessage = '';

  confirmationTitle = "Confirmation popup";

  constructor(public globals: Globals, public activeModal: NgbActiveModal){ }

  ngOnInit(){
  }

}
