import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { CompanyActivityService } from './company-activity.service';
import { Globals } from 'app/globals';

@Component({
  selector: 'app-company-activity',
  templateUrl: './company-activity.component.html',
  styleUrls: ['./company-activity.component.scss']
})
export class CompanyActivityComponent implements OnInit, OnChanges{
  @Input() uuid: string;

  public activities: any = [];

  public loading = false;

  constructor(private companyActivityService: CompanyActivityService, public globals: Globals){

  }

  ngOnInit(){

  }

  ngOnChanges(){
    this.getCompanyActivities(this.uuid);
  }

  getCompanyActivities(uuid){
    this.loading = true;
    this.companyActivityService.getCompanyActivities(uuid)
      .subscribe((data) => {
        if (data){
          this.loading = false;
          this.activities = data;
        }
      });
  }
}
