<div *ngIf="loading" class="loading-view">
  <img [src]="globals.loading" class="loading-element" />
</div>
<div class="full-width">
  <div class="card mt-5">
    <div class="card-block">
      <div class="card-body">
        <div class="form">
          <div class="form-section">
            {{globals.labels.placeholders.global.activity}}
          </div>
        </div>
        <div *ngIf="activities.length>0; then showActivity else hideActivity;"></div>
        <ng-template #hideActivity>
          <div class="media pl-3 pt-4">
            <div class="media-body">
              <div class="media-body-title">
                {{globals.labels.placeholders.inputs.full_layout_page.company_component.no_activity_message}}
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template #showActivity>
          <div class="scroll scroll-activites">
            <div class="timeline-centered full-width">
              <article class="timeline-entry" *ngFor="let activity of activities">
                <div class="timeline-entry-inner" *ngIf="activity">
                  <div class="timeline-icon">
                    <i class="fa fa-circle primary-color"></i>
                  </div>
                  <div class="timeline-label">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="timeline-body mr-4" *ngIf="activity.title" [innerHTML]="activity.title"></div>
                        <span
                          class="timeline-time-ago">{{activity.created_date | dateDiffNav:activity.created_date }}</span>
                        <div class="timeline-body mr-4" *ngIf="activity.body">
                          <p class="timeline-body-activity p-2" [innerHTML]="activity.body"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
              <article class="timeline-entry begin">
                <div class="timeline-entry-inner">
                  <div class="timeline-icon" style="-webkit-transform: rotate(-90deg); -moz-transform: rotate(-90deg);">
                    <i class="fa fa-circle" style="color:#e5e5e5 !important;"></i>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>