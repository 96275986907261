import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable()
export class GetNavisCommunicationService{

  //components to navbar
  private onJobSelectedSource = new Subject<any>();

  onJobSelected$ = this.onJobSelectedSource.asObservable();

  //onChooseGetnavisConsultantDataDrawer
  private onChooseGetnavisConsultantDrawer = new Subject<any>();

  onChooseGetnavisConsultantDrawer$ = this.onChooseGetnavisConsultantDrawer.asObservable();

  //onChooseGetnavisConsultantCompaniesDrawer
  private onChooseGetnavisConsultantCompaniesDrawer = new Subject<any>();

  onChooseGetnavisConsultantCompaniesDrawer$ = this.onChooseGetnavisConsultantCompaniesDrawer.asObservable();

  //onChooseGetnavisHiringCompaniesDrawer
  private onChooseGetnavisHiringCompaniesDrawer = new Subject<any>();

  onChooseGetnavisHiringCompaniesDrawer$ = this.onChooseGetnavisHiringCompaniesDrawer.asObservable();

  //onChooseGetnavisJobDrawer
  private onChooseGetnavisJobDrawer = new Subject<any>();

  onChooseGetnavisJobDrawer$ = this.onChooseGetnavisJobDrawer.asObservable();

  //Sidebar to jobscomponents
  private onSetCandidatesComponentJob = new Subject<any>();

  onSetCandidatesComponentJob$ = this.onSetCandidatesComponentJob.asObservable();

  //onChooseGetnavisCandidateDrawer
  private onChooseGetnavisCandidateDrawer = new Subject<any>();

  onChooseGetnavisCandidateDrawer$ = this.onChooseGetnavisCandidateDrawer.asObservable();

  private onGetCandidatesComponentJob = new Subject<any>();

  onGetCandidatesComponentJob$ = this.onGetCandidatesComponentJob.asObservable();

  //filters components(jobs, candidates) to sidebar
  private toggleSidebar = new Subject<any>();

  toggleSidebar$ = this.toggleSidebar.asObservable();

  //components(jobs, candidates) to navbar

  private totalResults = new Subject<any>();

  totalResults$ = this.totalResults.asObservable();

  constructor(private router: Router){ }

  emitGetnavisJobSelected(jobTitle: any){
    this.onJobSelectedSource.next(jobTitle);
  }

  emitGetnavisConsultantDataDrawer(consultant_uuid: any, type: any){
    const data = {
      "consultant_uuid": consultant_uuid,
      "type": type
    };
    this.onChooseGetnavisConsultantDrawer.next(data);
  }

  emitGetnavisConsultantCompaniesDataDrawer(consultant_company_uuid: any, type: any){
    const data = {
      "consultant_company_uuid": consultant_company_uuid,
      "type": type
    };
    this.onChooseGetnavisConsultantCompaniesDrawer.next(data);
  }

  emitGetnavisHiringCompaniesDataDrawer(hiring_company_uuid: any, type: any){
    const data = {
      "hiring_company_uuid": hiring_company_uuid,
      "type": type
    };
    this.onChooseGetnavisHiringCompaniesDrawer.next(data);
  }

  emitGetnavisJobDataDrawer(job_uuid: any, type: any, origin:string){
    const data = {
      "job_uuid": job_uuid,
      "type": type,
      "origin": origin
    };
    this.onChooseGetnavisJobDrawer.next(data);
  }

  setJobEmitter(data: any){
    if (data){
      this.onSetCandidatesComponentJob.next(data);
    } else {
      this.router.navigate(['/overview']);
    }

  }

  emitGetnavisCandidateDataDrawer(candidate_uuid: any, type: any, origin:string){
    const data = {
      "candidate_uuid": candidate_uuid,
      "type": type,
      "origin": origin
    };
    this.onChooseGetnavisCandidateDrawer.next(data);
  }

  emitSidebarAppearance(data: boolean){
    this.toggleSidebar.next(data);
  }

  emitTotalResultsTables(data:any){
    this.totalResults.next(data);
  }
}
