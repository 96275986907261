import { Component, OnInit, Output, ViewChild, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from 'app/globals';
import { ToastrService } from 'ngx-toastr';
import { FileUploader } from 'ng2-file-upload';
import { GetnavisAddJobService } from './getnavis-add-job.service';
import { environment } from 'environments/environment';
import { distinctUntilChanged, debounceTime, tap, catchError, filter, switchMap } from 'rxjs/operators';
import { concat, Observable, of, Subject } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddHiringCompanyModalComponent } from 'app/shared/getnavis/getnavis-platform-modals/add-hiring-company-modal/add-hiring-company-modal.component';
import { AddConsultantModalComponent } from 'app/shared/getnavis/getnavis-platform-modals/add-consultant-modal/add-consultant-modal.component';
import { AddConsultantCompanyModalComponent } from 'app/shared/getnavis/getnavis-platform-modals/add-consultant-company-modal/add-consultant-company-modal.component';

@Component({
  selector: 'app-getnavis-add-job',
  templateUrl: './getnavis-add-job.component.html',
  styleUrls: ['./getnavis-add-job.component.scss']
})
export class GetnavisAddJobComponent implements OnInit{

  @Output() dismissDrawer: EventEmitter<boolean> = new EventEmitter<boolean>();

  readonly minLengthTerm = 3;

  loadComponent = false;

  job: any = {};

  disableButton = true;

  validForm = true;

  preventDefault = false;

  loading = false;

  tmpFiles: any = {};

  selectedCompany: any = null;

  locations$: Observable<any>;

  locationInput$ = new Subject<string>();

  locationLoading = false;

  companies$: Observable<any>;

  companyLoading = false;

  companyinput$ = new Subject<string>();

  public uploader: FileUploader;

  public csv_uploader: FileUploader;

  public blacklistCsvUploader: FileUploader;

  csv_loading = false;

  linkingResult: any = {};

  fileIndex: number;

  public hasBaseDropZoneOver;

  huntingGroundCompany : any;

  activeIds: string[] = ['archetypes', 'hunting_grounds', 'scorecards', 'blacklisted_candidates'];

  consultants: any = [];

  consultantCompany: any = [];

  hiringCompanies$: any = [];

  resources: any = {
    searchStages: [],
    currencies: [],
    jobFileTypes: [],
    internalUsers: [],
    settings: [],
    jobTypes: [],
    fe: [],
    sfe: [],
    seniority: []
  };

  blacklisted_candidate: any;

  private default_search_stage: any = {};

  private default_job_type: any = {};

  allowedValues: any = [];

  @ViewChild('blacklistSelect') blacklistNgSelect: NgSelectComponent;

  @ViewChild('usersSelect') usersSelect: NgSelectComponent;

  @ViewChild('huntingGroundSelect') huntingGroundSelect: NgSelectComponent;

  public addTagCompany: (name) => void;

  public addTagHGCompany: (name) => void;

  constructor(public globals: Globals, private getNavisAddJobService: GetnavisAddJobService, private toastr: ToastrService, private modalService: NgbModal,
    private cdr: ChangeDetectorRef){
    this.addTagCompany = (name) => this.addCompany(name);

    this.addTagHGCompany = (name) => this.huntingGroundCompany(name);
  }

  addCompany(name){
    this.companyLoading = false;
    const tempCompany = {
      name: name,
      images: {
        '32x32': this.globals.companyAvatar
        }
      };
    this.job.blacklisted_companies = [...this.job.blacklisted_companies, tempCompany];
    this.blacklistNgSelect.close();
  }

  saveIndex(index, dropdown: NgSelectComponent){
    this.huntingGroundCompany = (name) => {
      this.companyLoading = false;
      const tempCompany = {
        name: name,
        images: {
          '32x32': this.globals.companyAvatar
          }
        };
      this.job.hunting_grounds[index].companies = [...this.job.hunting_grounds[index].companies, tempCompany];
      dropdown.close();
    };
  }

  ngOnInit(){
    this.loadComponent = true;
    this.getSearchStage();
    this.initJob();
    this.loadHiringCompanies();
    this.loadLocations();
    this.loadCompany();
    this.getCurrencies();
    this.getJobTypes();
    this.createFileUploader();
    this.createCsvFileUploader();
    this.createBlacklistedCompaniesFileUploader();
    this.getSettings();
    this.getFunctionalSubfunctionalExpertises();
    this.getSeniorities();
    this.getConsultantCompanies();
  }

  initJob(){
    this.job = {
      title: null,
      consultant: [],
      company: null,
      location: null,
      job_type: this.default_job_type,
      deadline_to_client: null,
      search_stage: this.default_search_stage,
      additional_info: null,
      compensation: {
        annual_salary_amount: null,
        annual_salary_currency: null,
        signing_cash_bonus_amount: null,
        signing_cash_bonus_currency : null,
        equities: {
          percent : null,
          amount_estimation : null,
          amount_currency : null
        },
        other: null
      },
      users: [],
      archetypes: [],
      hunting_grounds: [],
      blacklisted_companies: [],
      blacklisted_candidates: [],
      scorecards: [],
      files: [],
      settings: this.resources.settings,
      include_company_blacklisted_companies: true,
      include_company_blacklisted_candidates: true,
      consultant_company: null
    };
  }

  loadCompany(){
    this.companies$ = concat(
      of([]),
      this.companyinput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.companyLoading = true),
        switchMap(company =>
          this.getNavisAddJobService.getDealroomCompanies(company).pipe(
              catchError(() => of([])),
              tap(() => {
                  this.companyLoading = false;
              })
            )
        )
      )
    );
  }

  loadLocations(){
    this.locations$ = concat(
      of([]), // default items
      this.locationInput$.pipe(
        filter(res => res !== null && res.length >= this.minLengthTerm),
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => this.locationLoading = true),
        switchMap(term => this.getNavisAddJobService.getLocations(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.locationLoading = false)
          ))
      )
    );
  }

  // Angular2 File Upload
  public fileOverBase(e: any){
      this.hasBaseDropZoneOver = e;
      this.uploader.uploadAll();
  }

  createFileUploader(){
    this.uploader = new FileUploader({
      url: environment.getNavisBackendURL + '/assets',
      itemAlias: 'file',
      authToken: 'Bearer ' + localStorage.getItem('access-token')
    });
    this.uploader.onCompleteItem = (item: any, response: any, status: any) => {
      const obj = JSON.parse(response);
      obj.type = this.tmpFiles.type;
      if (status === 200){
          this.job.files.push(obj);
      } else {
          this.toastr.error('Something went wrong');
      }
      this.hasBaseDropZoneOver = false;
    };
    this.uploader.uploadAll();
  }

  createBlacklistedCompaniesFileUploader(){
    this.blacklistCsvUploader = new FileUploader({
      url: environment.getNavisBackendURL + '/companies/linking/csv',
      itemAlias: 'csv',
      authToken: 'Bearer ' + localStorage.getItem('access-token')
    });

    this.blacklistCsvUploader.onCompleteItem = (item: any, response: any, status: any) => {
      if (status === 200){
        const obj = JSON.parse(response);
        if (obj instanceof Object && 'companies' in obj){
          this.job.blacklisted_companies = this.mergeAndDeduplicateBlacklistedCompanies(obj.companies);
          this.toastr.success('Blacklisted companies succesfully added');
          return;
        }
        this.toastr.error('Response is malformed or empty. Please check the csv file content');
        return;
      }
      this.toastr.error('Something went wrong');
      this.hasBaseDropZoneOver = false;
    };
  }

  mergeAndDeduplicateBlacklistedCompanies(companies){
    const tempCompaines = [...this.job.blacklisted_companies, ...companies];
    return tempCompaines.filter((value, index, self) =>
      index === self.findIndex((item) => (
        item.name === value.name
      ))
    );
  }

  createCsvFileUploader(){
    this.csv_uploader = new FileUploader({
      url: environment.getNavisBackendURL + '/companies/linking/csv',
      itemAlias: 'csv',
      authToken: 'Bearer ' + localStorage.getItem('access-token')
    });

    this.csv_uploader.onCompleteItem = (item: any, response: any, status: any) => {
      const obj = JSON.parse(response);
      if (status !== 200){
        this.csv_loading = false;
        this.toastr.error(obj.message);
        return;
      }
      this.linkingResult = JSON.parse(response);
      const currentCompanies = this.job.hunting_grounds[this.fileIndex].companies;
      this.linkingResult.companies.forEach(company => {

        const existingIndex = currentCompanies.findIndex(comp => comp.name == company.name);
        if (existingIndex != -1 && company.linked_to){
          currentCompanies[existingIndex].uuid = company.linked_to;
        } else {
          currentCompanies.push({
            name: company.name,
            uuid: company.linked_to,
            removed_by_consultant: false,
            id: company.id,
            path: company.path,
            dealroom_url: company.dealroom_url,
            linkedin_url: company.linkedin_url,
            website_url: company.website_url
          });
        }
      });
      this.job.hunting_grounds[this.fileIndex].companies = currentCompanies.slice();
      this.fileIndex = null;
      this.csv_loading = false;
    };
  }

  OnContentChangeCsv(event, hgIndex){
    if (event.target.value.substr(-3) === 'csv'){
      this.fileIndex = hgIndex;
      this.linkingResult = {};
      this.csv_uploader.uploadAll();
    }
    this.csv_loading = false;
  }

  onJobTypeChange(){
    this.checkCalibrationSetting();
  }

  onConsultantCompanyChange(){
    this.checkCalibrationSetting();
  }

/**
 * @description Check if the calibration setting needs to be enabled based on the job type
 * @returns void
 */
  checkCalibrationSetting(){
    const calibrationListSettingsIndex = this.job.settings.findIndex(setting => setting.value === 'calibration_list_enabled');
    if (calibrationListSettingsIndex != -1){
      if (this.job.job_type && this.job.job_type.value == 'test'){
        this.job.settings[calibrationListSettingsIndex].allowed_values = [true, false];
        return;
      }
      if (this.job.consultant_company && this.job.consultant_company.name == 'The Big Search'){
       this.job.settings[calibrationListSettingsIndex].allowed_values = [true, false];
        return;
      }
      this.job.settings[calibrationListSettingsIndex].allowed_values = [false, false];
    }
  }

  onBlacklistedCompanyInsertCSV(){
    this.blacklistCsvUploader.uploadAll();
    this.csv_loading = false;
  }

  onFileClick(event){
    event.target.value = '';
  }

  getConsultants(){
    this.getNavisAddJobService.getConsultants().subscribe((data) => {
      if (data){
        this.consultants = data;
      }
    },
    (err) => {
      this.displayErrorMessage(err);
    });
  }

  getConsultantCompanies(){
    this.getNavisAddJobService.getConsultantCompanies()
    .subscribe((data) => {
        this.consultantCompany = data;
    },
    (err) => {
      this.displayErrorMessage(err);
    });
  }

  getSearchStage(){
    this.getNavisAddJobService.getResources("search_stage")
      .subscribe((data) => {
        this.resources.searchStages = data;
        this.resources.searchStages.find(item => {
          if (item.default){
            this.default_search_stage = item;
            this.job.search_stage = item;
          }
        });
      },
      (err) => {
        this.displayErrorMessage(err);
      });
  }

  getCurrencies(){
    this.getNavisAddJobService.getResources("currencies")
      .subscribe((data) => {
        this.resources.currencies = data;
      },
      (err) => {
        this.displayErrorMessage(err);
      });
  }

  getFileTypes(){
    this.getNavisAddJobService.getResources("job_file_types")
    .subscribe((data) => {
      this.resources.jobFileTypes = data;
    },
    (err) => {
      this.displayErrorMessage(err);
    });
  }

  getJobTypes(){
    this.getNavisAddJobService.getResourcesByType("job_type")
    .subscribe((data) => {
      this.resources.jobTypes = data;
      this.default_job_type = data[0]; //default job type 'Default Search', ordered from backend 0
      this.job.job_type = data[0];
    },
    (err) => {
      this.displayErrorMessage(err);
    });
  }

  getSettings(){
    this.getNavisAddJobService.getResources("settings")
    .subscribe((data) => {
      this.resources.settings = data;
      //swapping the default order of the allowed values in 'research note visible to client' settings, so it will be 'false' by default
      const temp = this.resources.settings[0].allowed_values[0];
      this.resources.settings[0].allowed_values[0] = this.resources.settings[0].allowed_values[1];
      this.resources.settings[0].allowed_values[1] = temp;
      this.addSettingsToJob();
    },
    (err) => {
      this.displayErrorMessage(err);
    });
  }

  addSettingsToJob(){
    if (!this.resources.settings || !this.resources.settings.length) return;
    this.job.settings = JSON.parse(JSON.stringify(this.resources.settings));
  }

  getAllowedValues(value){
    for (let i = 0; i < this.resources.settings.length; i++){
      if (this.resources.settings[i].value == value){
        this.allowedValues = this.resources.settings[i].allowed_values;
        break;

      }
    }
  }

  getInternalUsers(){
    this.getNavisAddJobService.getUsers("internal_users")
    .subscribe((data) => {
      this.resources.internalUsers = data;
    },
    (err) => {
      this.displayErrorMessage(err);
    });
  }

  blacklistCompany(event){
    this.job.blacklisted_companies = [];
    if (this.job.company && this.job.blacklisted_companies.find(company => company.name === this.job.company.name)){
      this.job.blacklisted_companies.splice(
        this.job.blacklisted_companies.findIndex(i => i.name === this.job.company.name), 1
      );
    }
    if (event){
      this.job.blacklisted_companies.push(event);
    }
  }

  createJob(){
    this.checkRequiredFields();
    this.disableButton = true;

    this.getNavisAddJobService.createJob(this.job)
      .subscribe((data) => {
        this.toastr.success(data.message);
        this.initJob();
        this.disableButton = true;
        this.addSettingsToJob();
        this.dismissDrawer.emit(true);
      },
      (err) => {
        this.displayErrorMessage(err);
      });

    this.validForm = true;
  }

  checkRequiredFields(){
    if (!this.job.title){
      this.validForm = false;
      this.disableButton = true;
    }
  }

  loadHiringCompanies(){
    this.hiringCompanies$ = concat(
      of([]),
      this.companyinput$.pipe(
        filter(res => res !== null && res.length >= this.minLengthTerm),
        distinctUntilChanged(),
        debounceTime(200),
        tap(() => this.companyLoading = true),
        switchMap(company =>
          this.getNavisAddJobService.getHiringCompanies(company).pipe(
              catchError(() => of([])),
              tap(() => {
                  this.companyLoading = false;
              })
            )
        )
      )
    );
  }

  afterFileisDrop(){
      this.loading = true;
  }

  OnContentChange(event){
    if (event.target.value){
        this.loading = true;
        this.uploader.uploadAll();
    } else {
        this.loading = false;
    }
  }

  deleteCard(i, type){
    this.job[type].splice(i, 1);
  }

  setPersonData(data){
    if (data){
      if (this.job.users.length){
        for (const user of this.job.users){
          if (data.uuid === user.uuid){
            return;
          }
        }
      }
      data.project_lead = false;
      data.sourcing_lead = false;
      this.job.users.push(data);
    }
    this.usersSelect.handleClearClick();
  }

  public beforeChange($event: NgbPanelChangeEvent){
    $event.preventDefault();
    this.preventDefault = false;
  }

  addNewArchetype(){
    this.preventDefault = true;
    this.job.archetypes.push({
      name: null,
      description: null
    });
  }

  addNewHuntingGround(){
    this.preventDefault = true;
    this.job.hunting_grounds.push({
      name: null,
      description: null,
      companies: []
    });
  }

  addNewScorecard(){
    this.preventDefault = true;
    this.job.scorecards.push({
      id: this.job.scorecards.length,
      description: null
    });
  }

  deleteScorecard(i: any){
    this.job.scorecards.splice(i, 1);
  }

  addBlacklistedCandidate(){
    if (this.blacklisted_candidate && this.blacklisted_candidate.trim()){
      if (this.job.blacklisted_candidates.length){
        if (this.job.blacklisted_candidates.filter(x => x.linkedin_url === this.blacklisted_candidate.trim()).length == 0){
          const linkedin_url = { linkedin_url: this.blacklisted_candidate };
          this.job.blacklisted_candidates.push(linkedin_url);
          this.blacklisted_candidate = '';
        } else {
          this.blacklisted_candidate = '';
          this.toastr.error('This candidate already exist');
         }
      } else {
        const linkedin_url = { linkedin_url: this.blacklisted_candidate };
        this.job.blacklisted_candidates.push(linkedin_url);
        this.blacklisted_candidate = '';
      }
   }
  }

  deleteBlacklistedCandidate(index){
    this.job.blacklisted_candidates.splice(index, 1);
  }

  getFunctionalSubfunctionalExpertises(){
    this.getNavisAddJobService.getResourcesByType("functional_expertise")
    .subscribe((data) => {
      this.resources.fe = data;
    },
    (err) => {
      this.displayErrorMessage(err);
    });
  }

  getSeniorities(){
    this.getNavisAddJobService.getResourcesByType("role_seniority")
    .subscribe((data) => {
      this.resources.seniority = data;
    },
    (err) => {
      this.displayErrorMessage(err);
    });
  }

  onFEChange(){
    this.job.sfe = null;
    if (!this.job.fe){
      this.resources.sfe = [];
    } else {
      this.resources.sfe = this.job.fe.subfunctional_expertise;
    }
  }

  displayErrorMessage(error){
    if (error.error){
      for (const errProp in error.error){
        if (error.error.hasOwnProperty(errProp)){
          if (error.error[errProp][0]){
            this.toastr.error(error.error[errProp]);
          }
        }
      }
    } else {
      this.toastr.error('Something went wrong');
    }
  }

  checkClearLeadConsultant(consultant){
    if (!consultant || this.job.lead_consultant?.uuid == consultant?.value.uuid){
      this.job.lead_consultant = null;
    }
    if (!consultant){
      this.getConsultants();
    }
  }

  checkLeadConsultant(consultant){
    if (!consultant || this.job.lead_consultant?.uuid == consultant.uuid){
      this.job.lead_consultant = null;
    } else if (this.job.lead_consultant){
      const index = this.job.consultant.findIndex(consultant => consultant.uuid == this.job.lead_consultant.uuid);
      if (index != -1){
        this.job.consultant[index].is_lead = true;
      }
    }
  }

  setLeadConsultant(leadConsultant){
    let consultantIndex = -1;
    let consultantObj = null;

    if (leadConsultant){
      consultantIndex = this.job.consultant.findIndex(consultant => consultant.uuid == leadConsultant.uuid);
      consultantObj = this.consultants.find(consultant => consultant.uuid == leadConsultant.uuid);
    }

    if (consultantIndex != -1){
      this.job.consultant.forEach(consultant => consultant.is_lead = false);
      this.job.consultant[consultantIndex].is_lead = true;
    } else if (consultantIndex == -1 && leadConsultant){
      this.job.consultant.forEach(consultant => consultant.is_lead = false);
      consultantObj.is_lead = true;
      this.job.consultant = [...this.job.consultant, consultantObj];
    } else if (consultantIndex == -1 && !leadConsultant){
      const index = this.job.consultant.findIndex(consultant => consultant.is_lead == true);
      this.job.consultant[index].is_lead = false;
    }
  }

  addConsultant(){
    const modalRef = this.modalService.open(AddConsultantModalComponent, { size: 'md' as 'lg', centered: true, backdrop: 'static' });
    modalRef.componentInstance.uuid = null;
    this.getConsultants();
    modalRef.result.then((response) => {
      const consultant = [];
      this.job.consultant.forEach(element => {
        consultant.push(element);
      });
      consultant.push(response.data.details);
      this.job.consultant = consultant;
    }, () => {
    });
  }

  addHiringCompany(){
    const modalRef = this.modalService.open(AddHiringCompanyModalComponent, { size: 'md' as 'lg', centered: true, backdrop: 'static' });
    modalRef.componentInstance.uuid = null;
    modalRef.result.then((response) => {
      if (response.success){
        this.job.company = response.data;
      }
    }, () => {
    });
  }

  addConsultantCompany(){
    const modalRef = this.modalService.open(AddConsultantCompanyModalComponent, { size: 'md' as 'lg', centered: true, backdrop: 'static' });
    modalRef.componentInstance.uuid = null;
    modalRef.result.then((response) => {
      if (response.success){
        this.job.consultant_company = response.data;
      }
    }, () => {
    });
  }
}
