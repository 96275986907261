import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateDiffTimestampNav'
})
export class DateDiffTimestampNavPipe implements PipeTransform{

  transform(date: any): any{
    return moment.unix(date).format("MM/YYYY");
  }

}
