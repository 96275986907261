import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from 'environments/environment';

@Injectable()
export class MergeCandidatesModalService{
    constructor(private http: HttpClient){ }

    getCandidatesData(data): Observable<any>{
        return this.http.post(environment.platformBackendURL + '/candidates/compare', data);
    }

    mergeCandidates(data): Observable<any>{
        return this.http.post(environment.platformBackendURL + '/candidates/merge', data);
    }
}
