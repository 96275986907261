<span [ngbPopover]="popContent" container="body" placement="right auto" #p="ngbPopover" [autoClose]="'outside'"
(click)="getDealroomData()" class="big-search-primary-color cursor-pointer normal-font-weight widget-company-name"
[ngClass]="drawerCompany ? (drawerCompany.uuid ? 'text-success' : 'text-danger') : ''">
  {{companyName}}
</span>
<ng-template  #popContent>
    <div *ngIf="!showComponent" class="loading-view">
        <img [src]="globals.loading" class="loading-element" />
    </div>
    <div class="card m-0 scroll dealroom-popover" *ngIf="showComponent">
      <div class="min-height-100" *ngIf="company">
        <div class="card-header pt-4 pb-0">
          <div class="media display-inline-flex">
            <img class="media-object d-flex height-40 width-40 rounded-circle" [src]="company.images['32x32']">
            <div class="media-body">
              <div class="media-body-content">
                <div class="form-group">
                  <strong class="align-self-center"> <a target="_blank" [href]="company.url"> <u>{{company.name}}</u>
                    </a></strong>
                  <a *ngIf="company.linkedin_url" target="_blank" [href]="company.linkedin_url"><i
                      class="fa fa-linkedin-square fa-lg p-1"></i></a>
                  <a *ngIf="company.facebook_url" target="_blank" [href]="company.facebook_url"><i
                      class="fa fa-facebook-square fa-lg p-1"></i></a>
                  <a *ngIf="company.twitter_url" target="_blank" [href]="company.twitter_url"><i
                      class="fa fa-twitter-square fa-lg p-1"></i></a>
                  <a *ngIf="company.angellist_url" target="_blank" [href]="company.angellist_url"><i
                      class="fa fa-angellist fa-lg p-1"></i></a>
                  <a *ngIf="company.crunchbase_url" target="_blank" [href]="company.crunchbase_url"><i
                      class="icon-ttcb fa-lg p-1"></i></a>
                  <a *ngIf="company.appstore_app_id" target="_blank" [href]="company.appstore_app_id"><i
                      class="icon-tt001-app-store fa-lg p-1"></i></a>
                  <a *ngIf="company.playmarket_app_id" target="_blank" [href]="company.playmarket_app_id"><i
                      class="icon-tt004-playstore fa-lg p-1"></i></a>
                </div>
                <div class="form-group" *ngIf="company.website_url">
                  <a target="_blank" [href]="company.website_url">
                    <i class="fa fa-globe mr-1"></i>
                    <u>{{company.website_url}}</u>
                  </a>
                </div>
                <div class="form-group" *ngIf="company.hq_locations.length">
                  <i class="fa fa-map-marker p-1"></i>
                  <span *ngFor="let location of company.hq_locations;let last = last">
                    {{location.address}} {{!last ? ' (HQ), ' : ' (HQ)'}}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="card-block">
            <div class="form-group">
              <div *ngFor="let industry of company.industries" class="display-inline-flex">
                <div class="badge m-1 p-2 white bg-secondary-color">
                  Industry: <strong>{{industry.name}}</strong>
                </div>
              </div>
              <div *ngFor="let clientFocus of company.client_focus" class="display-inline-flex">
                <div class="badge m-1 p-2 white bg-secondary-color">
                  Client focus: <strong>{{clientFocus}}</strong>
                </div>
              </div>
              <div *ngFor="let revenue of company.revenues" class="display-inline-flex">
                <div class="badge m-1 p-2 white bg-secondary-color">
                  Revenue: <strong>{{revenue}}</strong>
                </div>
              </div>
              <div *ngFor="let subIndustry of company.sub_industries" class="display-inline-flex">
                <div class="badge m-1 p-2 white bg-secondary-color">
                  Sub industry: <strong>{{subIndustry}}</strong>
                </div>
              </div>
              <div class="badge m-1 p-2 white bg-secondary-color">
                Employees: <strong>{{company.employees}} (Latest: {{company.employees_latest}})</strong>
              </div>
            </div>
            <div class="form-group">
              <div *ngFor="let tag of company.tags" class="display-inline-flex">
                <div class="badge-tag m-1 p-2">
                  {{tag}}
                </div>
              </div>
            </div>
            <div class="form-group" *ngIf="company.valuation">
              Valuation: <strong> {{company.valuation.value}} {{company.valuation.date}}</strong>
            </div>
            <div class="form-group" *ngIf="company.total_funding">
              Total funding: <strong> {{company.total_funding}} M </strong>
            </div>
            <div class="form-group" *ngIf="company.fundings.total">
              <table class="table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Investors</th>
                    <th>Amount</th>
                    <th>Round</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let funding of company.fundings.items">
                    <td>
                      {{funding.month}}/{{funding.year}}
                    </td>
                    <td *ngIf="funding.investors.length">
                      <span *ngFor="let i of funding.investors;let last = last">
                        {{i.name}}{{last? '':', '}}
                      </span>
                    </td>
                    <td *ngIf="!funding.investors.length">
                      N/A
                    </td>
                    <td>
                      {{funding.amount ? funding.amount +'M '+ funding.currency : 'N/A'}}
                    </td>
                    <td>
                      {{funding.round}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card-footer">
          Powered by <a target="_blank" class="big-search-primary-color" href="https://dealroom.co">dealroom.co</a>
        </div>
      </div>
      <div *ngIf="!company" class="row card-block">
        <div class="col-12">
          No information available
        </div>
      </div>
    </div>
</ng-template>