<div class="modal-content">
  <div class="modal-bg">
    <div class="modal-header">
      <div class="modal-header-title">
        Blacklist candidate
      </div>
      <button type="button" class="close pull-right" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true" [innerHTML]="globals.labels.placeholders.global.buttons.close_x" data-toggle="tooltip"
          data-placement="bottom" [title]="globals.labels.tooltips.global.close"></span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <div class="modal-body-title">
          <label>
            Are you sure you want to blacklist the candidate?
          </label>
        </div>
      </div>
      <div class="form-group">
        <div class="modal-body-title">
          <label>
            * Reason
          </label>
        </div>
        <div class="modal-body-content">
          <quill-editor [(ngModel)]="data.reason" class="height-100" placeholder="Insert reason"
          class="editor-campaigns">
        </quill-editor>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" *ngIf="data.reason" class="col-md-3 btn btn-block bg-secondary-color"
        (click)="blacklistCandidate()">
        Blacklist</button>
      <button type="button" class="col-md-3 btn btn-block bg-primary-color"
        (click)="activeModal.dismiss()">{{globals.labels.placeholders.global.buttons.close}}</button>
    </div>
  </div>
</div>