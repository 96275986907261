import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class BlacklistCandidateModalService{

  constructor(private http: HttpClient){ }

  blacklistCandidate(data: any): Observable<any>{
    return this.http.post(environment.platformBackendURL + '/candidates/blacklistCandidate', data);
  }
}
