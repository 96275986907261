import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class AddCandidateActivityService{

    constructor(private httpClient: HttpClient){ }

    getResourcesByType(type){
        const url: string = environment.getNavisBackendURL + '/resources?type=' + type;
        return this.httpClient.get(url);
    }

    getJobs(candidate_uuid): Observable<any>{
        const url: string = environment.getNavisBackendURL + '/resources/jobs?candidate_uuid=' + candidate_uuid;
        return this.httpClient.get(url);
    }

    createCandidateInsight(candidate_uuid: string, note: any){
        const url: string = environment.getNavisBackendURL + '/candidates/' + candidate_uuid + '/candidateInsight';
        return this.httpClient.post<any>(url, note);
    }
}
