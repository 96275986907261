import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class GetNavisCloseJobModalService{

  constructor(private http: HttpClient){ }

  getResourcesByType(value): Observable<any>{
    return this.http.get(environment.getNavisBackendURL + '/resources?type=' + value);
  }

  getJobNpsData(jobUuid): Observable<any>{
    return this.http.get(environment.getNavisBackendURL + '/jobs/' + jobUuid + '/nps-report');
  }

  closeJob(status, job_uuid): Observable<any>{
    const body = {
      status: status
    };
    return this.http.post(environment.getNavisBackendURL + '/merger/jobs/' + job_uuid + '/close', body);
  }
}
