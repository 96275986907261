import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'app/globals';
import { environment } from 'environments/environment';
import { Angulartics2 } from 'angulartics2';

@Injectable()
export class AddJobService{

  constructor(private angulartics2: Angulartics2, private http: HttpClient, private globals: Globals){ }

  getResources(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources?type=job_files_type,job_type,role_seniority,currencies,bonus_type,fee_type,job_statuses,job_priorities,search_sources');
  }

  getFunctionalExpertise(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources/functionalExpertise');
  }

  getSubFunctionalExpertise(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources/subFunctionalExpertise');
  }

  getIndustries(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources/industries');
  }

  getInternalUsers(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources/users?group=internal');
  }

  getPods(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources/pods');
  }

  getCompany(company): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/companies?name=' + company);
  }

  addCompany(company): Observable<any>{
    this.angulartics2.eventTrack.next({
      action: 'Added job company',
      properties: { company }
    });

    const body = {
      "company_name": company
    };
    return this.http.post(environment.platformBackendURL + '/companies', body);
  }

  getContacts(company): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/contacts?company_id=' + company);
  }

  addContact(company, user, email): Observable<any>{
    this.angulartics2.eventTrack.next({
      action: 'Added job contact',
      properties: { company, user, email }
    });

    const body = {
      "company": {
        uuid: company
      },
      "name": user,
      "email": email
    };
    return this.http.post(environment.platformBackendURL + '/contacts', body);
  }

  getLocation(location): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources/city?name=' + location);
  }

  getRole(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources/userRoles');
  }

  createJob(body): Observable<any>{
    this.angulartics2.eventTrack.next({
      action: 'Created job',
      properties: { body }
    });

    return this.http.post(environment.platformBackendURL + '/jobs/insert', body);
  }

  getJob(uuid): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/jobs/' + uuid);
  }

  updateJob(uuid, body): Observable<any>{
    this.angulartics2.eventTrack.next({
      action: 'Updated job',
      properties: { uuid, body }
    });

    return this.http.put(environment.platformBackendURL + '/jobs/' + uuid, body);
  }

  deleteUser(job_uuid, user_uuid): Observable<any>{
    this.angulartics2.eventTrack.next({
      action: 'Deleted job user',
      properties: { job_uuid, user_uuid }
    });

    return this.http.delete(environment.platformBackendURL + '/jobs/' + job_uuid + '/user/' + user_uuid);
  }

  deleteFile(job_uuid, file_uuid): Observable<any>{
    this.angulartics2.eventTrack.next({
      action: 'Deleted job file',
      properties: { job_uuid, file_uuid }
    });

    return this.http.delete(environment.platformBackendURL + '/jobs/' + job_uuid + '/file/' + file_uuid);
  }

  getAffinityOpportunities(): Observable<any>{
    return this.http.get(environment.djangoURL + '/integrations/affinity/opportunities?field_id=99672&text_values=Pitch,Negotiation,Closed Won');
  }

  getLocations(parameter: string): Observable<any>{
    const body = {
      "input_text": parameter
    };
    return this.http.post(environment.djangoURL + '/geocoder/autocomplete/', body);
  }

}
