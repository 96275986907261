import { Component, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { Globals } from 'app/globals';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { AddConsultantModalService } from './add-consultant-modal.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-consultant-modal',
  templateUrl: './add-consultant-modal.component.html',
  styleUrls: ['./add-consultant-modal.component.scss']
})
export class AddConsultantModalComponent implements OnInit{

  @Output() dismissDrawer: EventEmitter<boolean> = new EventEmitter<boolean>();

  consultant: any = null;

  companies: Observable<any>;

  companyLoading = false;

  companyinput$ = new Subject<string>();

  resources: any = {
    jobs: []
  };

  show: any = {
    component: false
  };

  @ViewChild('companySelect') companySelect: NgSelectComponent;

  public addTagCompany: (name) => void;

  constructor(public globals: Globals, private addConsultantModalService: AddConsultantModalService, private toastr: ToastrService,
    public activeModal: NgbActiveModal){
    this.addTagCompany = (name) => this.addCompany(name);
   }

  addCompany(name){
  this.companyLoading = false;
  this.consultant.company = { name: name };
  this.companySelect.close();
}

  ngOnInit(){
    this.initComponent();
    this.loadCompany();
    this.show.component = true;
  }

  initComponent(){
    this.consultant = {
      first_name: null,
      last_name: null,
      email: null,
      title: null,
      company: null,
      phone: null,
      jobs: [],
      picture_file_url: null
    };
  }

  createConsultant(){
    this.addConsultantModalService.createConsultant(this.consultant)
    .subscribe((response) => {
      this.toastr.success(response.message);
      this.initComponent();
      this.activeModal.close(response);
      this.dismissDrawer.emit(true);
    },
      (err) => {
        if (err.error){
          for (const errProp in err.error){
            if (err.error.hasOwnProperty(errProp)){
              if (err.error[errProp][0]){
                this.toastr.error(err.error[errProp]);
              }
            }
          }
        } else {
          this.toastr.error(err.message);
        }
      });
  }

  getResources(value, search?){
    if (!this.resources[value].length){
      this.addConsultantModalService.getResources(value, search).subscribe((data) => {
        if (data.length){
          this.resources[value] = data;
        }
      },
      (error) => {
        this.toastr.error(error.message);
      });
    }
  }

  loadCompany(search?){
    this.addConsultantModalService.getConsultantCompanies(search).subscribe((data) => {
      if (data.length){
        this.companies = data;
      }
    },
      (error) => {
        this.toastr.error(error.message);
      });
  }

}
