import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

declare let mixpanel: any;

@Injectable()
export class Globals{
  data: any;

  avatar = 'assets/img/svg/user.svg';

  femaleAvatar = 'assets/img/svg/user-female.svg';

  companyAvatar = 'assets/img/svg/company_avatar.svg';

  teamAvatar = 'assets/img/svg/team_avatar.png';

  linkedin = '/assets/img/svg/linkedin.svg';

  loading = 'assets/img/oval.svg';

  labels: any;

  substatuses = 'connection_request,reach_out,reference_partner,re-engage,engaged,on_hold,not_our_candidate,personalized_message';

  env = 'navis'; //default value

  constructor(private http: HttpClient, private injector: Injector){
  }

  load(){
    if (localStorage.getItem('access-token')){
      this.reloadEnv();
      return new Promise((resolve) => {
        const url = this.env == 'getnavis' ? environment.getNavisBackendURL : environment.platformBackendURL;
        this.http.get(url + '/me').subscribe(
          (data) => {
            this.data = data;
            if (this.authenticateUserRole()){
              this.identifyMixpanelUser();
              resolve(true);
            }
            resolve(false);
          },
          () => {
            const route = this.injector.get(Router);
            route.navigate(['/login']);
            resolve(false);
          },
          () => {
            this.labels = this.data.labels;
          });
      });
    }
    return null;
  }

  authenticateUserRole(){
    if (!('role' in this.data)){
      return false;
    }
    if (this.data.role !== 'InternalUser'){
      return false;
    }
    return true;
  }

  getUserData(){
    return this.data;
  }

  reloadEnv(){
    this.env = localStorage.getItem('env');
  }

  countJobs(){
    if (this.data.jobs){
      return this.data.jobs.length;
    } else {
      return 0;
    }
  }

  getFirstJob(){
    if (this.data.jobs){
      return this.data.jobs[0].uuid;
    } else {
      return null;
    }
  }

  checkIfExists(array, groupValue): boolean{
    if (array == 'group'){
      return this.data.groups.some(function (el){
        return el.value === groupValue;
      });
    } else if (array == 'permission'){
      return this.data.permissions.some(function (el){
        return el.value === groupValue;
      });
    } else if (array == 'role'){
      return this.data.roles.some(function (el){
        return el.value === groupValue;
      });
    }
    return null;
  }

//TODO setup mixpanel for getnavis users
  identifyMixpanelUser(): void{
    if (this.env == 'getnavis'){
      const userId = this.data.uuid;
      const userEmail = this.data.email;
      const userName = this.data.name;
      const userRole = this.data.role;

      if (mixpanel){
        mixpanel.people.set({
          '$email': userEmail,
          '$name': userName,
          '$role': userRole
        });

        mixpanel.identify(userId);
      }
    }
  }
}
