import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AddActivityService } from "./add-activity.service";
import { ToastrService } from "ngx-toastr";
import { NgbAccordion } from "@ng-bootstrap/ng-bootstrap";
import { Globals } from 'app/globals';
import { catchError, concat, debounceTime, distinctUntilChanged, filter, Observable, of, Subject, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-add-activity',
  templateUrl: './add-activity.component.html',
  styleUrls: ['./add-activity.component.scss']
})
export class AddActivityComponent implements OnInit{
  readonly minLengthTerm = 3;

  type: any;

  activityTypes: any = [];

  nextSteps: any = [];

  functional: any = [];

  sub_functional: any = [];

  filtered_sub_functional: any = [];

  seniority: any = [];

  industries: any = [];

  qualitiesOfClient: any = [];

  tiers: any = [];

  currencies = [];

  bonus = [];

  fee = [];

  form_type: any;

  enter_job: any;

  company: any;

  selectedCompany: any = null;

  selectedContacts: any = [];

  choosenJob: any = null;

  selectedJob: any = [];

  selectedCandidate = [];

  choosenCandidate: any = null;

  companies$: Observable<any>;

  companyLoading = false;

  companyInput$ = new Subject<string>();

  contacts$: Observable<any>;

  contactLoading = false;

  contactInput$ = new Subject<string>();

  jobs$: Observable<any>;

  jobLoading = false;

  jobInput$ = new Subject<string>();

  companyJobs$: Observable<any>;

  companyJobLoading = false;

  companyJobInput$ = new Subject<string>();

  candidates$: Observable<any>;

  candidateLoading = false;

  candidateInput$ = new Subject<string>();

  locations$: Observable<any>;

  locationLoading = false;

  locationInput$ = new Subject<string>();

  interview: any;

  general_pitch: any;

  role_specific_pitch: any;

  catch_up: any;

  intake_call: any;

  checkRejectFlag = false;

  addTagCompany: (name) => void;

  addTagContact: (name) => void;

  email: string = null;

  validForm = true;

  basic_facts: any;

  the_role: any;

  process: any;

  @ViewChild('secondaryaccordion') accordion: NgbAccordion;

  @Output() dismissDrawer: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public globals: Globals, private addActivityService: AddActivityService, private toastr: ToastrService){
    this.initInterview();
    this.initRoleSpecificPitch();
    this.catch_up = { call_summary: null };
    this.general_pitch = { call_summary: null };
    this.intake_call = {
      summary: null
    };

    this.addTagCompany = (name) => this.addCompany(name);
    this.addTagContact = (name) => this.addContact(name);
  }

  initRoleSpecificPitch(){
    this.role_specific_pitch = {
      call_summary: null,
      role_name: null,
      fe: null,
      sfe: null,
      seniority: null,
      location: null,
      industry: null,
      quality_of_client: null,
      tier: null
    };
  }

  initInterview(){
    this.interview = {
      scorecards: [{ 'skill': null, 'description': null, 'order': null }],
      career_overview: null,
      company_info: { 'reports_to': null, 'team_size': null, 'revenue': null, 'growth': null, 'funding': null },
      relocation: null,
      compensation: { 'uuid': null, 'base_salary': null, 'bonus_salary': null, 'equity': null, 'bonus_type': null, 'currency': null },
      notice_period: null,
      next_step: null,
      introduce_to_client: false,
      rejected_reason: null,
      recommend: null,
      recycling_candidate: false
    };
  }

  ngOnInit(){
    this.getTypes();
    this.loadJobs();
    this.loadCompanyJobs();
    this.loadCandidates();
    this.loadCompanies();
    this.loadContacts();
    this.loadLocations();
    this.getFunctional();
    this.getIndustry();
  }

  getTypes(){
    if (this.activityTypes || this.nextSteps || this.qualitiesOfClient || this.seniority || this.tiers){
      this.addActivityService.getActivityTypes()
        .subscribe(
          (data) => {
            this.activityTypes = data.filter((el) => el.type == 'activities_type');
            this.nextSteps = data.filter((el) => el.type == 'interview_next_steps_type');
            this.qualitiesOfClient = data.filter((el) => el.type == 'pitch_client_quality');
            this.seniority = data.filter((el) => el.type == 'role_seniority');
            this.tiers = data.filter((el) => el.type == 'terms_and_pricing_type');
          },
          (err) => {
            this.toastr.error(err.message);
          });
    }
  }

  getFunctional(){
    if (this.functional){
      this.addActivityService.getFunctional()
        .subscribe((data) => {
          this.functional = data;
        },
          (err) => {
            this.toastr.error(err.message);
          });
    }
  }

  getSubFunctional(){
    if (this.sub_functional){
      this.addActivityService.getSubFunctional()
        .subscribe((data) => {
          this.sub_functional = data;
          this.filtered_sub_functional = this.sub_functional.filter((el) => el.fe_id == this.role_specific_pitch.fe.id);
        },
          (err) => {
            this.toastr.error(err.message);
          });
    }
  }

  getIndustry(){
    if (this.industries){
      this.addActivityService.getIndustries()
        .subscribe((data) => {
          this.industries = data;
        },
          (err) => {
            this.toastr.error(err.message);
          });
    }
  }

  loadJobs(){
    this.jobs$ = concat(
      of([]), // default items
      this.jobInput$.pipe(
        filter(res => res !== null && res.length >= this.minLengthTerm),
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => this.jobLoading = true),
        switchMap(term => this.addActivityService.getJobs(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.jobLoading = false)
          ))
      )
    );
  }

  loadCompanyJobs(){
    this.companyJobs$ = concat(
      of([]), // default items
      this.companyJobInput$.pipe(
        filter(res => res !== null && res.length >= this.minLengthTerm),
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => this.companyJobLoading = true),
        switchMap(term => this.addActivityService.getCompanyJobs(term, this.selectedCompany ? this.selectedCompany.uuid : null).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.companyJobLoading = false)
          ))
      )
    );
  }

  loadCandidates(){
    this.candidates$ = concat(
      of([]), // default items
      this.candidateInput$.pipe(
        filter(res => res !== null && res.length >= this.minLengthTerm),
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => this.candidateLoading = true),
        switchMap(term => this.addActivityService.getCandidates(term, this.choosenJob).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.candidateLoading = false)
          ))
      )
    );
  }

  filterCandidates(){
    this.candidates$ = concat(
      of([]), // default items
      this.candidateInput$.pipe(
        filter(res => res !== null && res.length >= this.minLengthTerm),
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => this.candidateLoading = true),
        switchMap(term => this.addActivityService.getCandidates(term, this.selectedJob).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.candidateLoading = false)
          ))
      )
    );
  }

  loadCompanies(){
    this.companies$ = concat(
      of([]), // default items
      this.companyInput$.pipe(
        filter(res => res !== null && res.length >= this.minLengthTerm),
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => this.companyLoading = true),
        switchMap(term => this.addActivityService.getCompanies(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.companyLoading = false)
          ))
      )
    );
  }

  loadContacts(){
    this.contacts$ = concat(
      of([]), // default items
      this.contactInput$.pipe(
        filter(res => res !== null && res.length >= this.minLengthTerm),
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => this.contactLoading = true),
        switchMap(term => this.addActivityService.getContacts(term, this.selectedCompany ? this.selectedCompany.uuid : null).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.contactLoading = false)
          ))
      )
    );
  }

  loadLocations(){
    this.locations$ = concat(
      of([]), // default items
      this.locationInput$.pipe(
        filter(res => res !== null && res.length >= this.minLengthTerm),
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => this.locationLoading = true),
        switchMap(term => this.addActivityService.getLocations(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.locationLoading = false)
          ))
      )
    );
  }

  onSelectJob(){
    if (this.choosenJob){
      this.addActivityService.getScorecards(this.choosenJob)
        .subscribe(
          (data) => {
            if (data){
              this.interview.scorecards = data;
              if (this.interview.scorecards.length > 0){
                this.interview.scorecards.forEach((el) => {
                  el.description = null;
                });
              } else {
                this.interview.scorecards = [];
              }
            }
          });
    }
  }

  onSelectCandidate(choosenCandidate){
    if (this.selectedCandidate){
      this.interview.compensation = choosenCandidate.compensation ? choosenCandidate.compensation : { 'uuid': null, 'base_salary': null, 'bonus_salary': null, 'equity': null, 'bonus_type': null, 'currency': null };
    }
  }

  addNewScorecard(){
    this.interview.scorecards.push({
      'skill': '',
      'description': ''
    });
  }

  deleteScorecard(i){
    this.interview.scorecards.splice(i, 1);
  }

  editScorecard(key){
    switch (key){
      case 'skill': {
        break;
      }
      case 'description': {
        break;
      }
      default: {
        break;
      }
    }
  }

  addCompany(company){
    return new Promise((resolve) => {
      this.addActivityService.addCompany(company)
        .subscribe((response) => {
          resolve({ uuid: response.uuid, name: company, valid: true });
          this.companyLoading = false;
        },
          (err) => {
            this.toastr.error(err.message);
          });
    });
  }

  addContact(name){
    return new Promise((resolve) => {
      this.addActivityService.addContact(this.selectedCompany.uuid, name, this.email)
        .subscribe((response) => {
          resolve({ uuid: response.uuid, name: name, valid: true });
        },
          (err) => {
            if (err.error){
              for (const errProp in err.error){
                if (err.error.hasOwnProperty(errProp)){
                  if (err.error[errProp][0]){

                    this.toastr.error(err.error[errProp]);
                  }
                }
              }
            }
          });
    });
  }

  prevent(event){
    event.stopPropagation();
  }

  clearNextSteps(){
    this.interview.rejected_reason = null;
    this.interview.introduce_to_client = false;
    this.interview.next_step = null;
    this.interview.recycling_candidate = false;
  }

  clearFunctional(){
    this.sub_functional = [];
    this.filtered_sub_functional = [];
    this.role_specific_pitch.sfe = null;
  }

  clearJob(){
    this.selectedCandidate = [];
    this.loadCandidates();
  }

  clearCompany(){
    if (this.type && this.type.value == ('general_pitch' || this.type) && (this.type.value == 'catch_up' || this.type) && this.type.value == 'intake_call'){
      this.selectedContacts = [];
      this.loadContacts();
      this.selectedJob = [];
      this.loadJobs();

    } else if (this.type && this.type.value == 'role_specific_pitch'){
      this.selectedCompany = null;
      this.selectedContacts = [];
      this.loadContacts();
    }
  }

  checkSelectedJob(){
    if (this.selectedJob.length > 0){ return true; } else { return false; }
  }

  clearInput(){
    this.email = null;
  }

  // clear all split on 5 funcions
  clearInterview(){
    this.choosenJob = null;
    this.selectedCandidate = [];
    this.initInterview();

  }

  clearGeneralPitch(){
    this.selectedCompany = null;
    this.selectedContacts = [];
    this.selectedJob = [];
    this.general_pitch = { call_summary: null };
  }

  clearRoleSpecificPitch(){
    this.selectedCompany = null;
    this.selectedContacts = [];
    this.role_specific_pitch = {
      call_summary: null,
      role_name: null,
      fe: null,
      sfe: null,
      seniority: null,
      location: null,
      industry: null,
      quality_of_client: null,
      tier: null
    };
  }

  clearCatch_up(){
    this.selectedCandidate = [];
    this.selectedCompany = null;
    this.selectedContacts = [];
    this.selectedJob = [];
    this.catch_up = { call_summary: null };
  }

  clearIntake_call(){
    this.selectedCompany = null;
    this.selectedContacts = [];
    this.selectedJob = [];
    this.intake_call = {
      amount: 0,
      type: null,
      currency: null,
      summary: null
    };
  }

  clearAll(){
    if (this.type){
      this.email = null;
      if (this.type.value == 'interview'){ this.clearInterview(); } else if (this.type.value == 'general_pitch'){ this.clearGeneralPitch(); } else if (this.type.value == 'role_specific_pitch'){ this.clearRoleSpecificPitch(); } else if (this.type.value == 'catch_up'){ this.clearCatch_up(); } else if (this.type.value == 'intake_call'){ this.clearIntake_call(); }
    }
  }

  createInterview(){
    this.form_type = null;
    this.enter_job = null;
    this.company = null;

    this.form_type = this.interview;
    this.enter_job = [this.choosenJob];

  }

  createGeneralPitch(){
    this.form_type = null;
    this.enter_job = null;
    this.company = null;

    this.form_type = this.general_pitch;
    this.enter_job = this.selectedJob;
    this.company = [this.selectedCompany.uuid];
  }

  createRoleSpecificPitch(){
    this.form_type = null;
    this.enter_job = null;
    this.company = null;

    this.form_type = this.role_specific_pitch;
    this.company = [this.selectedCompany.uuid];
  }

  createCatchUp(){
    this.form_type = null;
    this.enter_job = null;
    this.company = null;

    this.form_type = this.catch_up;
    this.enter_job = this.selectedJob;
    this.company = this.selectedCompany ? [this.selectedCompany.uuid] : null;
  }

  createIntakeCall(){
    this.form_type = null;
    this.enter_job = null;
    this.company = null;

    this.form_type = this.intake_call;
    this.enter_job = this.selectedJob;
    this.company = [this.selectedCompany.uuid];
  }

  checkRequiredFields(){
    if (this.type.value == 'interview'){
      return this.choosenJob && this.selectedCandidate.length && this.interview.next_step;
    } else if (this.type.value == 'general_pitch'){
      return this.selectedCompany && this.selectedContacts.length;
    } else if (this.type.value == 'role_specific_pitch'){
      return this.selectedCompany;
    } else if (this.type.value == 'catch_up'){
      return true;
    } else if (this.type.value == 'intake_call'){
      return this.selectedCompany && this.selectedContacts.length && this.selectedJob.length;
    }
    return null;

  }

  checkObjectValues(obj){
    let hasValue = false;
    for (const key in obj){
      if (obj[key] !== null && obj[key] != "")
        hasValue = true;
    }
    return hasValue;
  }

  onCreate(){
    if (this.checkRequiredFields()){

      if (this.type.value == 'interview'){
        if (this.interview.next_step){
          this.interview.next_step = {
            'value': this.interview.next_step.value,
            'description': this.interview.next_step.description
          };
        }
        if (this.interview.compensation){
          this.interview.compensation = {
            'base_salary': this.interview.compensation.base_salary,
            'bonus_salary': this.interview.compensation.bonus_salary,
            'bonus_type': this.interview.compensation.bonus_type ? {
              'value': this.interview.compensation.bonus_type.value,
              'description': this.interview.compensation.bonus_type.description

            } : null,
            'currency': this.interview.compensation.currency ? {
              'value': this.interview.compensation.currency.value,
              'description': this.interview.compensation.currency.description

            } : null,
            'equity': this.interview.compensation.equity
          };
        }
        this.interview.scorecards.forEach((element, index, array) => {
          if (!this.checkObjectValues(element)){
            array.splice(index, 1);
          }
        });
        this.createInterview();
      } else if (this.type.value == 'general_pitch'){
        this.createGeneralPitch();
      } else if (this.type.value == 'role_specific_pitch'){
        this.createRoleSpecificPitch();
      } else if (this.type.value == 'catch_up'){
        this.createCatchUp();
      } else if (this.type.value == 'intake_call'){
        this.createIntakeCall();
      }

      this.addActivityService.postActivity(this.type, this.form_type, this.company, this.selectedContacts, this.enter_job, this.selectedCandidate).subscribe(
        () => {
        },
        (err) => {
          if (err.error){
            for (const errProp in err.error){
              if (err.error.hasOwnProperty(errProp)){
                if (err.error[errProp][0]){
                  this.toastr.error(err.error[errProp]);
                }
              }
            }
          } else {
            this.toastr.error('Something went wrong');
          }
        },
        () => {
          this.dismissDrawer.emit(true);
          this.toastr.success('Successfully created ' + this.type.description);
          this.clearAll();
          this.type = null;
        }
      );
    } else this.validForm = false;
  }

  getResources(){
    if (!this.currencies.length && !this.bonus.length && !this.fee.length){

      this.addActivityService.getResources()
        .subscribe((data) => {
          if (!this.currencies.length){
            this.currencies = data.filter(function (item){
              return item.type === 'currencies';
            });
          }
          if (!this.bonus.length){
            this.bonus = data.filter(function (item){
              return item.type === 'bonus_type';
            });
          }
          if (!this.fee.length){
            this.fee = data.filter(function (item){
              return item.type === 'fee_type';
            });
          }
        });
    }
  }

  recreateJobObject(key, value){
    if (key.indexOf('.') > -1){
      const keyValue = key.substr(0, key.indexOf('.'));
      const obj = {};
      obj[keyValue] = {
        [key.split('.').pop()]: value
      };
      return obj;
    } else {
      return {
        [key]: value
      };
    }
  }

  checkReject(next_step){
    if (next_step){
      switch (next_step.value){
        case "reject_by_tt":
        case "reject_by_candidate": {
          this.interview.introduce_to_client = false;
          this.checkRejectFlag = true;
          break;
        }
        case "move_forward": {
          this.interview.recycling_candidate = false;
          this.checkRejectFlag = false;
          break;
        }
        case "on_hold": {
          this.interview.recycling_candidate = false;
          this.interview.introduce_to_client = false;
          this.checkRejectFlag = false;
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  preventNegativeNumbers(e: any){
    if (!((e.keyCode > 95 && e.keyCode < 106)
      || (e.keyCode > 47 && e.keyCode < 58)
      || e.keyCode == 8)){
      return false;
    }
    return true;
  }
}
