import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timestampToDatepicker'
})
export class TimestampToDatepickerPipe implements PipeTransform{

  transform(date: any): any{
    return moment.unix(date).format('YYYY-MM-DD');
  }

}
