import { Component, OnInit } from '@angular/core';
import { Globals } from 'app/globals';
import { Subject, of, concat, Observable } from 'rxjs';
import { distinctUntilChanged, debounceTime, switchMap, tap, catchError, filter } from 'rxjs/operators';
import { AddContactService } from './add-contact.service';
import { ToastrService } from 'ngx-toastr';
import { FileUploader } from 'ng2-file-upload';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss']
})
export class AddContactComponent implements OnInit{
  readonly minLengthTerm = 3;

  contact: any = {};

  validation = true;

  companies$: Observable<any>;

  companyLoading = false;

  companyInput$ = new Subject<string>();

  public addTagCompany: (name) => void;

  uploader: FileUploader;

  validEmailRegex = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;

  constructor(public globals: Globals, private addContactService: AddContactService, private toastr: ToastrService){
    this.addTagCompany = (name) => this.addCompany(name);
    const URL = environment.platformBackendURL + '/assets';

    this.uploader = new FileUploader({
      url: URL,
      isHTML5: true,
      method: 'POST',
      itemAlias: 'file',
      authTokenHeader: 'authorization',
      authToken: 'Bearer ' + localStorage.getItem('access-token')
    });

    this.uploader.onCompleteItem = (item: any, response: any, status: any) => {
      const obj = JSON.parse(response);
      if (status === 201){
        this.contact.photo = obj.file_url;
      } else {
        this.toastr.error(obj.message);
      }
    };
  }

  OnContentChange(event){
    if (event.target.value){
      this.uploader.uploadAll();
    }
  }

  ngOnInit(){
    this.loadCompanies();
  }

  loadCompanies(){
    this.companies$ = concat(
      of([]), // default items
      this.companyInput$.pipe(
        filter(res => res !== null && res.length >= this.minLengthTerm),
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => this.companyLoading = true),
        switchMap(term => this.addContactService.getCompany(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.companyLoading = false)
          ))
      )
    );
  }

  addCompany(company){
    return new Promise((resolve) => {
      this.addContactService.addCompany(company)
        .subscribe((response) => {
          resolve({ uuid: response.uuid, name: company, valid: true });
          this.companyLoading = false;
          this.toastr.success('Successfully added company');
        },
          (err) => {
            this.toastr.error(err.message);
          });
    });
  }

  validateFields(){
    if (this.contact.name && (this.contact.email && this.validEmailRegex.test(this.contact.email)) && (this.contact.company && this.contact.company.uuid)){
      this.validation = true;
      return true;
    }
    this.validation = false;
    return false;
  }

  createContact(){
    if (this.validateFields()){
      this.addContactService.createContact(this.contact)
      .subscribe(() => {
       this.toastr.success("Successfully created contact");
        this.initComponent();
      },
        (err) => {
          if (err.error){
            for (const errProp in err.error){
              if (err.error.hasOwnProperty(errProp)){
                if (err.error[errProp][0]){
                  this.toastr.error(err.error[errProp]);
                }
              }
            }
          } else {
            this.toastr.error(err.message);
          }
        });
    }
  }

  initComponent(){
    this.contact = {};
    this.validation = true;
  }

}
