import { Component, OnInit } from '@angular/core';
import { MergeCandidatesModalService } from './merge-candidates-modal.service';
import { ToastrService } from 'ngx-toastr';
import { Globals } from 'app/globals';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-merge-candidates-modal',
  templateUrl: './merge-candidates-modal.component.html',
  styleUrls: ['./merge-candidates-modal.component.scss']
})
export class MergeCandidatesModalComponent implements OnInit{
  data: any = {};

  objectKeys = Object.keys;

  accordion: any = {
    details: true,
    experiences: true,
    educations: true,
    languages: true,
    jobs: true,
    consent: true,
    compensation: true,
    location: true
  };

  loading = false;

  constructor(private mergeCandidatesModalService: MergeCandidatesModalService, private toastr: ToastrService, public globals: Globals, public activeModal: NgbActiveModal){ }

  ngOnInit(){
    this.getDataForMerging(this.data);
  }

  getDataForMerging(data){
    this.mergeCandidatesModalService.getCandidatesData(data).subscribe(
      (response) => {
        if (response){
          Object.assign(this.data, response);
          this.data.primary_candidate_uuid = response.new_data.uuid;
          this.data.secondary_candidate_uuid = response.old_data.uuid;
        }
      },
      (err) => {
        if (err.error){
          for (const errProp in err.error){
            if (err.error.hasOwnProperty(errProp) && err.error[errProp][0]){
              this.toastr.error(err.error[errProp]);
            }
          }
        } else {
          this.toastr.error('Something went wrong');
        }
      });
  }

  isObject(object){
    return (object && typeof object === 'object');
  }

  isArray(array){
    return (Array.isArray(array));
  }

  swapValues(key, newData, oldData, miscellaneous?){
    key == 'miscellaneous' ? this.data.new_data[key][miscellaneous] = oldData : this.data.new_data[key] = oldData;
    key == 'miscellaneous' ? this.data.old_data[key][miscellaneous] = newData : this.data.old_data[key] = newData;
  }

  addItem(item, i, key){
    this.data.new_data[key].push(item);
    this.data.old_data[key].splice(i, 1);
  }

  deleteItem(item, i, key){
    this.data.old_data[key].push(item);
    this.data.new_data[key].splice(i, 1);
  }

  mergeCandidates(){
    this.loading = true;
    this.mergeCandidatesModalService.mergeCandidates(this.data).subscribe(
      (response) => {
        if (response){
          this.loading = false;
          this.activeModal.close(response);
        }
      },
      (err) => {
        if (err.error){
          for (const errProp in err.error){
            if (err.error.hasOwnProperty(errProp) && err.error[errProp][0]){
              this.toastr.error(err.error[errProp]);
            }
          }
        } else {
          this.toastr.error('Something went wrong');
        }
      });
  }
}
