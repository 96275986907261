<div class="modal-content">
  <div class="modal-bg">
    <div class="modal-header">
      <div class="modal-header-title">
        {{confirmationTitle}}
      </div>
      <button type="button" class="close pull-right" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true" [innerHTML]="globals.labels.placeholders.global.buttons.close_x" data-toggle="tooltip"
          data-placement="bottom" [title]="globals.labels.tooltips.global.close"></span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12" [innerHTML]="confirmationMessage">
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn pull-right bg-secondary-color" (click)="activeModal.close()">
        Yes </button>
      <button type="button" class="btn pull-right bg-primary-color"
        (click)="activeModal.dismiss()">No</button>
    </div>
    </div>
  </div>