import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Globals } from '../../globals';

@Injectable()
export class PermissionsGuard implements CanActivate{

    constructor(private router: Router, private global: Globals){ }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean{

        if (this.global.env == 'getnavis'){
            if (!state.url.startsWith('/getnavis')){
                this.router.navigate(['/getnavis']);
                return false;
            }
            return true;
        }

        if (route.data.permission){
            const userPemissions = this.global.data.permissions;
            const foundPermission = userPemissions.find((permission) => permission.value === route.data.permission);
            if (foundPermission){
                return true;
            } else {
                this.router.navigate(['/overview']);
            }
        } else {
            return true;
        }
        return true;
    }
}
