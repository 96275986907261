<div class="modal-content" *ngIf="showComponent">
    <div class="modal-bg">
      <div class="modal-header pb-0">
        <h4>
          {{metricsData.description}}
        </h4>
        <button type="button" aria-label="Close" class="close pull-right" (click)="activeModal.dismiss()">
          <span aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Close">×</span>
      </button>
      </div>
      <div class="modal-body pt-0">
        <div class="row">
          <div class="col-12 col-lg-6 align-self-center">
            <div id="donut-dashboard-chart" class="height-155 donut">
              <x-chartist class="" [data]="chart.data" [type]="chart.type" [options]="chart.options"
                [responsiveOptions]="chart.responsiveOptions" [events]="chart.events">
              </x-chartist>
            </div>
          </div>
          <div class="col-12 col-lg-6 align-self-center">
            <div class="row" *ngFor="let info of metricsData.data.series">
              <div class="col-12 form-group">
                <div class="modal-body-title">{{info.value}}% {{info.name}}</div>
                <div class="progress height-5">
                  <div class="progress-bar" [className]="'bg-' + info.className" role="progressbar"
                    [style.width.%]="info.value" [attr.aria-valuenow]="info.value" aria-valuemin="0" aria-valuemax="100">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>