<div class="modal-content" *ngIf="hasCandidateData">
  <div class="modal-bg-cards">
    <div class="modal-header modal-header-bg white full-width">
      <div class="media">
        <img class="media-object d-flex height-70 width-70 rounded-circle" [src]="candidate.photo ? candidate.photo : (candidate.gender == 'FEMALE' ? globals.femaleAvatar : globals.avatar)"
          (error)="candidate.gender == 'FEMALE' ? candidate.photo = globals.femaleAvatar : candidate.photo = globals.avatar">
        <div class="media-body ml-3">
          <div class="media-body-title">
            {{candidate.first_name}} {{candidate.middle_name}} {{candidate.last_name}}
            <a *ngIf="candidate.linkedin" class="ml-2 fa fa-linkedin-square white tt-icon-lg" href="{{candidate.linkedin}}" target="_blank" aria-hidden="true"></a>
          </div>
          <div class="media-body-content">
            <div *ngIf="candidate.current_job_title || candidate.current_employer">
              {{candidate.current_job_title}}
              {{candidate.current_employer ? '@ ' + candidate.current_employer : ''}}
            </div>
            <div class="row">
              <div class="col-12">
                <div *ngIf="candidate.location">
                  <i class="fa fa-map-marker p-1"></i>
                  {{candidate.location.description}}
                </div>
                <div *ngIf="candidate.primary_email">
                  <i class="fa fa-envelope"></i>
                  {{candidate.primary_email}}
                </div>
              </div>
            </div>
            <div *ngIf="candidate.tags" class="row">
              <div class="col-12">
                <span *ngFor="let tag of candidate.tags" [ngStyle]="{'background-color':tag.background_color, 'color':tag.text_color}"
                  class="badge-tags-inverse nowrap align-self-center mt-2 mr-1 display-inline-flex" title="{{tag.hover_data}}">
                  {{tag.description}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button type="button" class="close white pull-right" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true" [innerHTML]="globals.labels.placeholders.global.buttons.close_x" data-toggle="tooltip" data-placement="bottom" [title]="globals.labels.tooltips.global.close"></span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <div *ngIf="inProcess">
            <i class="icon-ttcritical error-color"></i>
            <span>This candidate is in process in <strong> {{this.inProcessJobs}}</strong></span>
          </div>
          <div *ngIf="isBlacklisted">
            <i class="icon-ttcritical error-color"></i>
            <span>This candidate current company <strong> {{isBlacklisted.hover_data}} </strong></span>
          </div>
          <div *ngIf="isBlacklistedCandidate">
            <i class="icon-ttcritical error-color"></i>
            <span>This candidate is <strong> {{isBlacklistedCandidate.hover_data}} </strong></span>
          </div>
        </div>
        <div class="col-md-12 col-lg-12 col-xl-12">
          <div class="card">
            <div class="card-body">
              <div class="card-block modal-bg">
                <div class="scroll scroll-tabs">
                  <div class="form">
                    <div class="form-section">
                      Basic details
                    </div>
                  </div>
                  <div class="row card-block">
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                            Gender
                            <span *ngIf="!validForm && !candidate.gender " [ngClass]="{'primary-color': !candidate.gender }">
                              {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                            </span>
                          </label>
                        </div>
                        <div class="card-body-content">
                          <ng-select bindLabel="description" dropdownPosition="bottom" [placeholder]="globals.labels.placeholders.inputs.full_layout_page.omni_component.contact.gender"
                            [items]="resources.gender" (open)="getGender()" [(ngModel)]="candidate.gender" [ngModelOptions]="{standalone: true}"
                            (change)="changed && candidate.gender ? editCandidate('gender',candidate.gender, candidate.uuid):candidate.gender ? '' : toastr.warning('Gender is mandatory') "
                            (ngModelChange)="onChange()"></ng-select>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form">
                    <div class="form-section">
                      Experience
                    </div>
                  </div>

                  <div class="form-group" *ngFor="let experience of candidate.experiences;let i=index">

                    <div class="card-block">
                      <div class="row">
                        <div class="col-6">
                          <div class="form-group">
                            <div class="card-body-content">
                              <div class="custom-control custom-checkbox ml-0 ">
                                <input type="checkbox" [ngModelOptions]="{standalone: true}" [(ngModel)]="experience.is_investment" class="custom-control-input"
                                  (change)="experience.is_investment ? editExperience('is_investment', experience.is_investment, experience.uuid): removeInvestmentFromExperience(experience)"
                                  id="investment-{{i}}">
                                <label class="custom-control-label" for="investment-{{i}}">Is investment
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group">
                            <div class="card-body-content">
                              <div class="custom-control custom-checkbox ml-0">
                                <input type="checkbox" [ngModelOptions]="{standalone: true}" [(ngModel)]="experience.not_applicable" class="custom-control-input"
                                  (change)="editExperience('not_applicable', experience.not_applicable, experience.uuid)" id="notApplicable-{{i}}">
                                <label class="custom-control-label" for="notApplicable-{{i}}">Not applicable
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-12">
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                                Title
                                <span *ngIf="!validForm && !experience.not_applicable && !experience.job_title " [ngClass]="{'primary-color': !experience.job_title }">
                                  {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                                </span>
                              </label>
                            </div>
                            <div class="card-body-content">
                              <input type="text" class="form-control" placeholder="Insert job title" (change)="changed ? editExperience('job_title', experience.job_title, experience.uuid):''"
                                (ngModelChange)="onChange()" [(ngModel)]="experience.job_title" [ngModelOptions]="{standalone: true}">
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                                Company
                                <span *ngIf="!validForm && !experience.not_applicable && !experience.company_name " [ngClass]="{'primary-color': !experience.company_name }">
                                  {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                                </span>
                              </label>
                            </div>
                            <div class="card-body-content">
                              <input type="text" class="form-control" placeholder="Insert company name" (change)="changed ? editExperience('company_name', experience.company_name, experience.uuid):''"
                                (ngModelChange)="onChange()" [(ngModel)]="experience.company_name" [ngModelOptions]="{standalone: true}">
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                                Start date
                                <span *ngIf="!validForm && !experience.not_applicable &&  !experience.start_date " [ngClass]="{'primary-color': !experience.start_date }">
                                  {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                                </span>
                              </label>
                            </div>
                            <div class="card-body-content">
                              <input type="date" [ngModel]="experience.start_date ? (experience.start_date | timestampToDatepicker:experience.start_date) : null" [ngModelOptions]="{standalone: true}"
                                (change)="changed ? editExperience('start_date', $event.target.value, experience.uuid, i, candidate.experiences):toastr.warning('Start date is mandatory')"
                                (ngModelChange)="onChange()" class="form-control">
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>
                                End date
                              </label>
                            </div>
                            <div class="card-body-content">
                              <input type="date" [ngModel]="experience.end_date ? (experience.end_date | timestampToDatepicker:experience.end_date) : null" [ngModelOptions]="{standalone: true}"
                                (change)="changed ? editExperience('end_date', $event.target.value, experience.uuid, i, candidate.experiences):''" (ngModelChange)="onChange()" class="form-control">
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>Description

                              </label>
                            </div>
                            <div class="card-body-content">
                              <textarea class="form-control" [(ngModel)]="experience.summary" [ngModelOptions]="{standalone: true}" type="text"
                                (change)="changed ? editExperience('summary', experience.summary, experience.uuid):''" (ngModelChange)="onChange()" placeholder="Add description"></textarea>
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="custom-control custom-checkbox m-0">
                              <input type="checkbox" [ngModelOptions]="{standalone: true}" [(ngModel)]="experience.current_employer" class="custom-control-input"
                                (change)="editExperience('current_employer', experience.current_employer, experience.uuid)" id="current_employer-{{i}}">
                              <label class="custom-control-label" for="current_employer-{{i}}">Primary job
                              </label>
                            </div>
                          </div>
                          <div *ngIf="experience.is_investment">
                            <div class="form">
                              <div class="form-section">
                                <label class="secondary-color">VC fields</label>
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  Country focus
                                </label>
                              </div>
                              <div class="card-body-content">
                                <ng-select bindLabel="name" dropdownPosition="bottom" [items]="resources.countryFocus | async" [loading]="countryLoading" [typeahead]="countryinput"
                                  placeholder="Select country" [multiple]="true" [(ngModel)]="experience.investment_country_focus"
                                  (change)="changed ? editExperience('investment_country_focus',experience.investment_country_focus, experience.uuid):''" (ngModelChange)="onChange($event)">
                                </ng-select>
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  Stage focus
                                </label>
                              </div>
                              <div class="card-body-content">
                                <ng-select bindLabel="description" dropdownPosition="bottom" [items]="resources.stageFocus" (open)="getStageFocus()" [multiple]="true" placeholder="Select stage"
                                  [(ngModel)]="experience.investment_stage_focus" (change)="changed ? editExperience('investment_stage_focus',experience.investment_stage_focus, experience.uuid):''"
                                  (ngModelChange)="onChange($event)"></ng-select>
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                <label>
                                  Sector focus
                                </label>
                              </div>
                              <div class="card-body-content">
                                <ng-select bindLabel="value" bindValue="value" dropdownPosition="bottom" [items]="resources.sectorFocus | async" [loading]="sectorLoading" [addTag]="true"
                                  [typeahead]="sectorinput" placeholder="Select sector" [multiple]="true" [(ngModel)]="experience.investment_sector_focus"
                                  (change)="changed ? editExperience('investment_sector_focus',experience.investment_sector_focus, experience.uuid):''" (ngModelChange)="onChange($event)"></ng-select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-12">
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                                Seniority
                                <span *ngIf="!validForm && !experience.not_applicable && !experience.seniority" [ngClass]="{'primary-color': !experience.seniority}">
                                  {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                                </span>
                              </label>
                            </div>
                            <div class="card-body-content">
                              <ng-select [placeholder]="globals.labels.placeholders.inputs.full_layout_page.omni_component.experience_education.seniority" [items]="resources.seniority"
                                bindLabel="description" dropdownPosition="bottom" [(ngModel)]="experience.seniority " [ngModelOptions]="{standalone: true}"
                                (change)="changed ? editExperience( 'seniority' ,experience.seniority, experience.uuid):''" (ngModelChange)="onChange()" (open)="getSeniority()">
                              </ng-select>
                            </div>
                          </div>
                          <div class="form-group d-none">
                            <div class="card-body-title-basic">
                              <label>
                                Industry
                              </label>
                            </div>
                            <div class="card-body-content">
                              <ng-select [items]="resources.industry" (open)="getIndustry()" [multiple]="true"
                                [placeholder]="globals.labels.placeholders.inputs.full_layout_page.omni_component.experience_education.industry" bindLabel="description" dropdownPosition="bottom"
                                (change)="changed ? editExperience('industry',experience.industry, experience.uuid ):''" (ngModelChange)="onChange()" [(ngModel)]="experience.industry">
                              </ng-select>
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                                Functional expertise
                                <span *ngIf="!validForm && !experience.not_applicable &&  !experience.fe.length" [ngClass]="{'primary-color': !experience.fe.lenght}">
                                  {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                                </span>
                              </label>
                            </div>
                            <div class="card-body-content">
                              <ng-select [items]="resources.fe" (open)="getFe()" [multiple]="true" [(ngModel)]="experience.fe" [ngModelOptions]="{standalone: true}"
                                [placeholder]="globals.labels.placeholders.inputs.full_layout_page.omni_component.experience_education.functional" bindLabel="description"
                                (change)="changed ? editExperience('fe', experience.fe, experience.uuid):''" (ngModelChange)="onChange()" (remove)="onUnselectFunctional(experience, false)"
                                (clear)="experience.sfe = []">
                              </ng-select>
                            </div>
                          </div>
                          <div class="form-group d-none">
                            <div class="card-body-title-basic">
                              <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                                Sub Functional expertise
                                <span *ngIf="!validForm && !experience.not_applicable &&  !experience.sfe.length" [ngClass]="{'primary-color': !experience.sfe.length}">
                                  {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                                </span>
                              </label>
                            </div>
                            <div class="card-body-content">
                              <ng-select [items]="resources.filteredSfe" (open)="getFilteredSfe(experience.fe)"
                                [placeholder]="globals.labels.placeholders.inputs.full_layout_page.omni_component.experience_education.sub_functional" bindLabel="description" [multiple]="true"
                                [(ngModel)]="experience.sfe" (change)="changed ? editExperience('sfe', experience.sfe, experience.uuid):''" (ngModelChange)="onChange()"
                                [ngModelOptions]="{standalone: true}">
                              </ng-select>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 p-0">
        <button type="button" class="btn btn-header pull-left bg-skip-color mb-4" (click)="removeCandidate(index)">Remove
          Candidate</button>
        <button type="button" class="btn btn-header pull-right bg-secondary-color mb-4" *ngIf="index != candidatesDataForValidation.length -1" (click)="longlistCandidate(index)">Next
          Candidate</button>
        <button type="button" class="btn btn-header pull-right bg-primary-color mb-4" *ngIf="index == candidatesDataForValidation.length -1" (click)="longlistCandidate(index)">Finish</button>
      </div>
    </div>
  </div>
</div>