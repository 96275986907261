<div class="modal-content">
  <div class="modal-bg">
    <div class="modal-header">
      <div class="modal-header-title">
        Delete candidate
      </div>
      <button type="button" class="close pull-right" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true" [innerHTML]="globals.labels.placeholders.global.buttons.close_x" data-toggle="tooltip"
          data-placement="bottom" [title]="globals.labels.tooltips.global.close"></span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <div class="modal-body-title">
          <label>
            Are you sure you want to delete the candidate?
          </label>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="col-md-3 btn btn-block bg-secondary-color"
        (click)="activeModal.close(true)">
        Delete</button>
      <button type="button" class="col-md-3 btn btn-block bg-primary-color"
        (click)="activeModal.dismiss()">{{globals.labels.placeholders.global.buttons.close}}</button>
    </div>
  </div>
</div>