<div class="modal-content">
  <div class="modal-bg">
    <div class="modal-header">
      <div class="modal-header-title">
        Blacklist candidate
      </div>
      <button type="button" class="close pull-right" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="close">x</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <div class="modal-body-title">
          <label>
            Are you sure you want to blacklist the candidate?
          </label>
        </div>
      </div>
      <div class="form-group">
        <div class="modal-body-title">
          <label>
            * Job
          </label>
        </div>
        <div class="modal-body-content">
          <ng-select bindLabel="title" bindValue="uuid" dropdownPosition="bottom" placeholder="Select job"
            [items]="resources.jobs" [(ngModel)]="data.job" [ngModelOptions]="{standalone: true}">
          </ng-select>
        </div>
      </div>
      <div class="form-group">
        <div class="modal-body-title">
          <label>
            * Reason
          </label>
        </div>
        <div class="modal-body-content scroll">
          <quill-editor id="quill-editor-blacklist" [(ngModel)]="data.reason" placeholder="Insert reason"
          class="editor-campaigns">
        </quill-editor>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" *ngIf="data.reason && data.job" class="col-md-3 btn btn-block bg-secondary-color"
        (click)="blacklistCandidate()">
        Blacklist</button>
      <button type="button" class="col-md-3 btn btn-block bg-primary-color"
        (click)="activeModal.dismiss()">Close</button>
    </div>
  </div>
</div>