import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class GetnavisCandidatesFilterService{

    constructor(private http: HttpClient){ }

    getResources(type): Observable<any>{
        return this.http.get(environment.getNavisBackendURL + '/navis/resources?type=' + type);
    }

    getOpenJobs(){
        const url: string = environment.getNavisBackendURL + '/navis/jobs?type=open';
        return this.http.get(url);
    }

    getFiltersperCategory(value): Observable<any>{
        const URL = environment.getNavisBackendURL + '/navis/resources/dealroomFilters?category=' + value;
        return this.http.get(URL);
    }

    getFunctionalExpertise(): Observable<any>{
        return this.http.get(environment.getNavisBackendURL + '/navis/resources/functionalExpertise');
    }

    getSubFunctionalExpertise(): Observable<any>{
        return this.http.get(environment.getNavisBackendURL + '/navis/resources/subFunctionalExpertise');
    }

    getSeniority(): Observable<any>{
        return this.http.get(environment.getNavisBackendURL + '/navis/resources?type=role_seniority');
    }

    getLocation(location): Observable<any>{
        return this.http.get(environment.getNavisBackendURL + '/navis/resources/city?name=' + location);
    }

    getLanguages(language): Observable<any>{
        return this.http.get(environment.djangoURL + '/resources/languages?name=' + language);
    }

    getLanguagesLevel(): Observable<any>{
        return this.http.get(environment.djangoURL + '/resources/language_levels');
    }

    getHqLocation(location): Observable<any>{
        return this.http.get(environment.getNavisBackendURL + '/navis/resources/dealroomFilters?category=hq_locations&name=' + location);
    }

    getTags(tags): Observable<any>{
        return this.http.get(environment.getNavisBackendURL + '/navis/resources/dealroomFilters?category=tags&name=' + tags);
    }

    getSimilarJobs(body): Observable<any>{
        return this.http.post(environment.djangoURL + '/jobs/', body);
    }

    getLocations(parameter: string): Observable<any>{
      const body = {
        "input_text": parameter
      };
      return this.http.post(environment.djangoURL + '/geocoder/autocomplete/', body);
    }

}
