import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnFocusOut]'
})
export class OnFocusOutDirective{

    constructor(){ }

    @HostListener('focus', ['$event.target'])
      onFocus(){
      }

    @HostListener('focusout', ['$event.target'])
    onFocusout(){
    }
}
