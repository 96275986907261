import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class CommunicationService{

  //components to navbar
  private onJobSelectedSource = new Subject<any>();

  onJobSelected$ = this.onJobSelectedSource.asObservable();

  //from notification to navbar
  private onNoitificationSelected = new Subject<any>();

  onNotificationSelected$ = this.onNoitificationSelected.asObservable();

  //from navbar to notification
  private noitificationSelected = new Subject<any>();

  notificationSelected$ = this.noitificationSelected.asObservable();

  //navbar to drawer
  private onChooseDrawer = new Subject<any>();

  onChooseDrawer$ = this.onChooseDrawer.asObservable();

  //created Job to SideBar
  private onCreatedJob = new Subject<any>();

  onCreatedJob$ = this.onCreatedJob.asObservable();

  //view candidate profile -> mapped candidates
  private onCommentAdded = new Subject<any>();

  onCommentAdded$ = this.onCommentAdded.asObservable();

  //onChooseJobDrawer
  private onChooseJobDrawer = new Subject<any>();

  onChooseJobDrawer$ = this.onChooseJobDrawer.asObservable();

  //onChooseFilterDrawer
  private onChooseFilterDrawer = new Subject<any>();

  onChooseFilterDrawer$ = this.onChooseFilterDrawer.asObservable();

  //onChooseContactDrawer
  private onChooseContactDrawer = new Subject<any>();

  onChooseContactDrawer$ = this.onChooseContactDrawer.asObservable();

  //onChooseCompanyDrawer
  private onChooseCompanyDrawer = new Subject<any>();

  onChooseCompanyDrawer$ = this.onChooseCompanyDrawer.asObservable();

  //onChooseCandidateDrawer
  private onChooseCandidateDrawer = new Subject<any>();

  onChooseCandidateDrawer$ = this.onChooseCandidateDrawer.asObservable();

  //onChooseAddDataDrawer
  private onChooseAddDataDrawer = new Subject<any>();

  onChooseAddDataDrawer$ = this.onChooseAddDataDrawer.asObservable();

  //onChooseCampaignDrawer
  private onChooseCampaignDrawer = new Subject<any>();

  onChooseCampaignDrawer$ = this.onChooseCampaignDrawer.asObservable();

  //onChooseGetNavisCampaignDrawer
  private onChooseGetNavisCampaignDrawer = new Subject<any>();

  onChooseGetNavisCampaignDrawer$ = this.onChooseGetNavisCampaignDrawer.asObservable();

  //onChosenCandidateFilters
  private onChoosenCandidateFilters = new Subject<any>();

  onChoosenCandidateFilters$ = this.onChoosenCandidateFilters.asObservable();

  //onChosenCompaniesFilters
  private onChoosenCompaniesFilters = new Subject<any>();

  onChoosenCompaniesFilters$ = this.onChoosenCompaniesFilters.asObservable();

  //clearCandidateFilters
  private clearCandidateFilters = new Subject<any>();

  clearCandidateFilters$ = this.clearCandidateFilters.asObservable();

  //clearCompaniesFilters
  private changeCompaniesFilters = new Subject<any>();

  changeCompaniesFilters$ = this.changeCompaniesFilters.asObservable();

  //jobsFilters
  private openJobsDrawer = new Subject<any>();

  openJobsDrawer$ = this.openJobsDrawer.asObservable();

  //Jobs components to sidebar
  private onGetCandidatesComponentJob = new Subject<any>();

  onGetCandidatesComponentJob$ = this.onGetCandidatesComponentJob.asObservable();

  //editCampaign
  private putEditedCampaign = new Subject<any>();

  putEditedCampaign$ = this.putEditedCampaign.asObservable();

  //editCandidateToCampaign
  private addCandidateToCampaign = new Subject<any>();

  addCandidateToCampaign$ = this.addCandidateToCampaign.asObservable();

  //candidateAddedToCampaign
  private addedCandidateCampaign = new Subject<any>();

  addedCandidateCampaign$ = this.addedCandidateCampaign.asObservable();

    //editGetNavisCampaign
    private putEditedGetNavisCampaign = new Subject<any>();

    putEditedGetNavisCampaign$ = this.putEditedGetNavisCampaign.asObservable();

    //editCandidateToCampaign
    private addCandidateToCampaignGetNavis = new Subject<any>();

    addCandidateToCampaignGetNavis$ = this.addCandidateToCampaignGetNavis.asObservable();

    //candidateAddedToCampaign
    private addedCandidateCampaignGetNavis = new Subject<any>();

    addedCandidateCampaignGetNavis$ = this.addedCandidateCampaignGetNavis.asObservable();

  //Sidebar to jobscomponents
  private onSetCandidatesComponentJob = new Subject<any>();

  onSetCandidatesComponentJob$ = this.onSetCandidatesComponentJob.asObservable();

  constructor(private router: Router){ }

  emitJobSelected(jobTitle: any){
    this.onJobSelectedSource.next(jobTitle);
  }

  emitNotification(notification: boolean){
    this.onNoitificationSelected.next(notification);
  }

  emitToNotification(notification: any){
    this.noitificationSelected.next(notification);
  }

  emitDataDrawer(type: any, uuid: any){
    const data = {
      "type": type,
      "uuid": uuid
    };
    this.onChooseDrawer.next(data);
  }

  emitNewJob(job_uuid: any, title: any, fe: any){
    const data = {
      "uuid": job_uuid,
      "job_title": title,
      "fe": fe
    };
    this.onCreatedJob.next(data);
  }

  emitComment(comment, candidate_id){
    this.onCommentAdded.next({ 'comment': comment, 'candidate_id': candidate_id });
  }

  emitJobDataDrawer(job_uuid: string, type: string, origin: string){
    const data = {
      "job_uuid": job_uuid,
      "type": type,
      'origin': origin
    };
    this.onChooseJobDrawer.next(data);
  }

  emitFilterDataDrawer(filters: any, type: any){
    const data = {
      "filters": filters,
      "type": type
    };
    this.onChooseFilterDrawer.next(data);
  }

  emitContactDataDrawer(contact_uuid: any, type: any){
    const data = {
      "contact_uuid": contact_uuid,
      "type": type
    };
    this.onChooseContactDrawer.next(data);
  }

  emitCompanyDataDrawer(company_uuid: any, type: any){
    const data = {
      "company_uuid": company_uuid,
      "type": type
    };
    this.onChooseCompanyDrawer.next(data);
  }

  emitCandidatetDataDrawer(candidate_uuid: any, type: any, job_uuid: any, origin:string, parent_component?: string, candidate_index?: number, filter_type?: string, recommended_job?: string){
    const data = {
      candidate_uuid: candidate_uuid,
      type: type,
      job_uuid: job_uuid,
      parent_component: parent_component,
      candidate_index: candidate_index,
      filter_type: filter_type,
      recommended_job: recommended_job,
      origin: origin
    };
    this.onChooseCandidateDrawer.next(data);
  }

  emitAddDataDrawer(job_uuid: any, type: any){
    const data = {
      "job_uuid": job_uuid,
      "type": type
    };
    this.onChooseAddDataDrawer.next(data);
  }

  emitCampaignDataDrawer(campaign_uuid: any, type: any){
    const data = {
      "campaign_uuid": campaign_uuid,
      "type": type
    };
    this.onChooseCampaignDrawer.next(data);
  }

  emitGetNavisCampaignDataDrawer(campaign_uuid: any, type: any){
    const data = {
      "campaign_uuid": campaign_uuid,
      "type": type
    };
    this.onChooseGetNavisCampaignDrawer.next(data);
  }

  emitCandidateFilters(data: any){
    this.onChoosenCandidateFilters.next(data);
  }

  emitCompaniesFilters(data: any){
    this.onChoosenCompaniesFilters.next(data);
  }

  emitClearCandidatesFilters(data: any){
    this.clearCandidateFilters.next(data);
  }

  emitchangeCompaniesFilters(data: any){
    this.changeCompaniesFilters.next(data);
  }

  openJobDrawer(data: any){
    this.openJobsDrawer.next(data);
  }

  getJobEmitter(uuid){
    this.onGetCandidatesComponentJob.next(uuid);
  }

  emitEditedCampaign(data: any){
    this.putEditedCampaign.next(data);
  }

  emitCandidateToCampaign(data: any){

    this.addCandidateToCampaign.next(data);
  }

  emitCandidate(data: boolean){
    this.addedCandidateCampaign.next(data);
  }

  emitEditedGetNavisCampaign(data: any){
    this.putEditedGetNavisCampaign.next(data);
  }

  emitGetNavisCandidateToCampaign(data: any){

    this.addCandidateToCampaignGetNavis.next(data);
  }

  emitGetNavisCandidate(data: boolean){
    this.addedCandidateCampaignGetNavis.next(data);
  }

  setJobEmitter(data: any){
    if (data){
      this.onSetCandidatesComponentJob.next(data);
    } else {
      this.router.navigate(['/overview']);
    }

  }
}
