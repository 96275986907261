import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
@Component({
  selector: 'app-aggrid-dealroom-column',
  templateUrl: './aggrid-dealroom-column.component.html',
  styles: [``]
})
export class AgGridDealroomColumnComponent implements ICellRendererAngularComp{

  public companyName: string;

  constructor(){
  }

  agInit(params: any): void{
    this.returnCompanyNameFromParameters(params);
  }

  refresh(): boolean{
    return true;
  }

  returnCompanyNameFromParameters(params){
    switch (params.origin){
      case 'jobs': {
        this.companyName = params.data.company;
        break;
      }
      case 'mapped_candidates': {
        this.companyName = params.data.current_employer;
        break;
      }
      case 'filter_candidates': {
        this.companyName = params.data.current_employer;
        break;
      }
      case 'getnavis-consultant': {
        this.companyName = params.data.company?.name;
        break;
      }
      case 'getnavis-mapped-candidates': {
        this.companyName = params.data.company?.name;
        break;
      }
      default: {
        this.companyName = '';
      }
    }
  }
}
