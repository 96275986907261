<div class="modal-content" *ngIf="npsDataLoaded">
  <div class="modal-bg">
    <div class="modal-header">
      <div class="modal-header-title">
        Close job
      </div>
      <button type="button" class="close pull-right" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="close">x</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <div class="modal-body-title">
          <label>
            Outcome
          </label>
        </div>
        <div class="modal-body-content">
          <ng-select bindLabel="description" dropdownPosition="bottom" placeholder="Select search outcome"
            [items]="statuses" [(ngModel)]="closeJobStatus" [ngModelOptions]="{standalone: true}">
          </ng-select>
        </div>
      </div>
      <ul class="pl-4 mb-0" *ngIf="npsData">
        <li>NPS surveys sent: {{npsData.nps_sent_true_count}}</li>
        <li>NPS surveys not sent: {{npsData.nps_sent_false_count}}</li>
      </ul>
    </div>
    <div class="modal-footer">
      <button type="button"  class="col-md-3 btn btn-block bg-secondary-color"
        (click)="closeJob()">
        Close job</button>
      <button type="button" class="col-md-3 btn btn-block bg-primary-color"
        (click)="activeModal.dismiss()">Close</button>
    </div>
  </div>
</div>