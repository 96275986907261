import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Globals } from 'app/globals';
import { JobActvityService } from './job-activity.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-job-activity',
  templateUrl: './job-activity.component.html',
  styleUrls: ['./job-activity.component.scss']
})
export class JobActivityComponent implements OnInit, OnDestroy{
  @Input() uuid: string;

  data: any = [];

  activities: any = [];

  loading = false;

  subscription: any;

  constructor(private dataService: JobActvityService, public globals: Globals, private router: Router){ }

  ngOnInit(){
    let oldUUID = this.router.parseUrl(this.router.url).root.children.primary.segments[1].path;
    this.getActivity(this.uuid);
    this.subscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      if (this.router.parseUrl(this.router.url).root.children.primary.segments[1]){
        let newUUID = '';
        if (oldUUID != this.router.parseUrl(this.router.url).root.children.primary.segments[1].path){
          newUUID = this.router.parseUrl(this.router.url).root.children.primary.segments[1].path;
          oldUUID = newUUID;
        }
        if (newUUID){
          this.getActivity(newUUID);
        }
      }
    });
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  getActivity(uuid){
    if (this.data){
      this.dataService.getActivity(uuid)
        .subscribe((data) => {
          this.loading = false;
          this.activities = data;
        });
    }
  }
}

