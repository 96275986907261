import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class ViewCampaignService{

  constructor(private http: HttpClient){ }

  getCampaign(uuid): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/campaigns/' + uuid);
  }

  getJobsResources(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/campaigns/openJobs');
  }

  getTemplatesResources(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/campaigns/templates');
  }

  getContactsResources(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources/internalUsers');
  }

  getTriggersResources(type): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources?type=' + type);
  }

  getSubStatusesResources(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources/subStatuses');
  }

  addUpdateCampaign(body, uuid){
    if (uuid){
      return this.http.put(environment.platformBackendURL + '/campaigns/' + uuid, body);
    }
    return null;
  }

  getCandidates(campaign, step?, event?): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/campaigns/' + campaign + '/candidates' + (step ? '?stage=' + step : '') + (event ? (step ? '&event=' + event : '?event=' + event) : ''));
  }

  getStepsResources(uuid): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/campaigns/' + uuid + '/steps');
  }

  getEventsResources(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources/campaignEvents');
  }

  putCandidateStatus(event, campaign, candidate): Observable<any>{
    const body = {
      event: event
    };
    return this.http.put(environment.platformBackendURL + '/campaigns/' + campaign + '/candidates/' + candidate, body);
  }

  getNextStepEmail(campaign, candidate): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/campaigns/' + campaign + '/candidates/' + candidate + '/nextEmail');
  }

  putNextEmail(data): Observable<any>{
    return this.http.put(environment.platformBackendURL + '/campaigns/emails/' + data.uuid, data);
  }

}
