import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Globals } from 'app/globals';
import { ToastrService } from 'ngx-toastr';
import { catchError, concat, debounceTime, distinctUntilChanged, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { cloneDeep } from 'lodash';
import { GetnavisViewHiringCompanyService } from './getnavis-view-hiring-company.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-getnavis-view-hiring-company',
  templateUrl: './getnavis-view-hiring-company.component.html',
  styleUrls: ['./getnavis-view-hiring-company.component.scss']
})
export class GetnavisViewHiringCompanyComponent implements OnInit{

  @Input() uuid: string;

  changed = false;

  companies$: Observable<any>;

  companyLoading = false;

  companyinput$ = new Subject<string>();

  hiringCompany: any = null;

  private hiringCompanyCopy: any = null;

  edit = {
    basic: false
  };

  @ViewChild('companySelect') companySelect: NgSelectComponent;

  public addTagCompany: (name) => void;

  constructor(public globals: Globals, private getNavisViewHiringCompanyService: GetnavisViewHiringCompanyService, private toastr: ToastrService, private activatedRoute: ActivatedRoute){
    this.addTagCompany = (name) => this.addCompany(name);
    activatedRoute.queryParams.subscribe(params => {
      if (params.hiring_company_uuid){
        this.initEdit();
        this.initData();
        this.loadCompany();
        this.getHiringCompany(params.hiring_company_uuid);
        this.uuid = params.hiring_company_uuid;
      }
    });
  }

  ngOnInit(){
  }

  initEdit(){
    this.edit = {
      basic: false
    };
  }

  addCompany(name){
    this.companyLoading = false;
    this.hiringCompany.name = name;
    this.hiringCompany.dealroom_id = null;
    this.hiringCompany.dealroom_data = null;
    this.updateHiringCompany({
      name: name,
      dealroom_id: null,
      dealroom_data: null
    });
    this.companySelect.close();
  }

  getHiringCompany(uuid){
    this.getNavisViewHiringCompanyService.getHiringCompany(uuid).subscribe(
      (response) => {
        if (response){
          this.hiringCompany = response;
          this.hiringCompanyCopy = cloneDeep(this.hiringCompany);
        }
      },
      (error) => {
        this.toastr.error(error.message);
      });
  }

  updateHiringCompany(body){
    this.getNavisViewHiringCompanyService.updateHiringCompany(this.uuid, body)
      .subscribe((response) => {
        if (response.success){
          this.toastr.success(response.message);
          this.hiringCompanyCopy = cloneDeep(this.hiringCompany);
        }
      },
      (err) => {
        this.hiringCompany = cloneDeep(this.hiringCompanyCopy);
        if (err.error){
          for (const errProp in err.error){
            if (err.error.hasOwnProperty(errProp)){
              if (err.error[errProp][0]){
                this.toastr.error(err.error[errProp]);
              }
            }
          }
        } else {
          this.toastr.error('Something went wrong');
        }
      });
    this.changed = false;
  }

  editHiringCompany(key, value){
      const body = this.createHiringCompanyObjectForUpdate(key, value);
      this.updateHiringCompany(body);
  }

  createHiringCompanyObjectForUpdate(key, value){
    if (key.indexOf('.') > -1){
      const keyValue = key.substr(0, key.indexOf('.'));
      const obj = {};
      obj[keyValue] = {
        [key.split('.').pop()]: value
      };
      return obj;
    } else {
      return {
        [key]: value
      };
    }
  }

  editForm(section){
    this.edit[section] = !this.edit[section];
  }

  setDealroomCompanyData(data){
    if (data){
      this.hiringCompany.photo = data.images['100x100'];
      this.hiringCompany.name = data.name;
      this.hiringCompany.linkedin_url = data.linkedin_url;
      this.hiringCompany.dealroom_url = data.url;
      this.hiringCompany.website_url = data.website_url;
      this.hiringCompany.dealroom_id = data.id;
      this.hiringCompany.dealroom_data = data;
      this.updateHiringCompany(this.hiringCompany);
    }
  }

  loadCompany(){
    this.companies$ = concat(
      of([]),
      this.companyinput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.companyLoading = true),
        switchMap(company =>
          this.getNavisViewHiringCompanyService.getDealroomCompanies(company).pipe(
              catchError(() => of([])),
              tap(() => {
                  this.companyLoading = false;
              })
            )
        )
      )
    );
  }

  onChange(){
    this.changed = true;
  }

  initData(){
    this.hiringCompany = {
      name: null,
      photo: null,
      linkedin_url: null,
      dealroom_url: null,
      website_url: null,
      dealroom_id: null,
      dealroom_data: null,
      company_culture_description: null
    };
  }

  openUrl(url){
    window.open(this.generateValidUrl(url), '_blank');
  }

  generateValidUrl(url){
    let newUrl = window.decodeURIComponent(url);
    newUrl = newUrl.trim().replace(/\s/g, "");

    if ((/^(:\/\/)/).test(newUrl)){
        return `http${newUrl}`;
    }
    if (!(/^(f|ht)tps?:\/\//i).test(newUrl)){
        return `http://${newUrl}`;
    }

    return newUrl;
}
}