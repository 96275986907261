<div class="card card-top">
  <div class="card-block card-body scroll">
    <div class="row">
      <div class="col-4">
        <div class="form-group">
          <div class="media">
            <div class="hvrbox height-70 width-70">
              <a (click)="fileInput.click()" data-toggle="tooltip" data-placement="top"
                [title]="globals.labels.tooltips.global.choose_image">
                <img class="media-object d-flex height-70 width-70 rounded-circle"
                  [src]="contact.photo ? contact.photo : globals.avatar"
                  (error)="contact.photo = globals.avatar">
                <div class="hvrbox-layer_top">
                  <div class="hvrbox-text">
                    {{globals.labels.placeholders.full_layout_page.solve_component.image}}</div>
                </div>
              </a>
              <span
                style="visibility: hidden; position: absolute; overflow: hidden; width: 0; height:0;border:none; margin:0; padding:0">
                <input type="file" #fileInput ng2FileSelect [uploader]="uploader"
                  (change)="OnContentChange($event)" />
              </span>
            </div>
            <div class="media-body align-self-center">
              <div class="media-body-title">
                <span class="align-self-center">or</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-8">
        <div class="form-group">
          <div class="card-body-title-basic">
            <label>
              Photo URL
            </label>
          </div>
          <div class="card-body-content">
            <input type="text" class="form-control" placeholder="Insert photo url" [(ngModel)]="contact.photo"
              [ngModelOptions]="{standalone: true}">
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="form-group">
          <div class="card-body-title-basic">
            <label>
              * Name
              <span *ngIf="!validation && !contact.name" [ngClass]="{'primary-color': !contact.name}">
                (Required field)
              </span>
            </label>
          </div>
          <div class="card-body-content">
            <input type="text" class="form-control" placeholder="Insert name" [(ngModel)]="contact.name"
              [ngModelOptions]="{standalone: true}" appOnFocusOut (focusout)="!validation ? validateFields(): ''">
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="form-group">
          <div class="card-body-title-basic">
            <label>
              * Email
              <span *ngIf="!validation && !contact.email" [ngClass]="{'primary-color': !contact.email}">
                (Required field)
              </span>
              <span *ngIf="contact.email && !validEmailRegex.test(this.contact.email)"
                [ngClass]="{'primary-color': contact.email && !validEmailRegex.test(this.contact.email)}">
                (Invalid email)
              </span>
            </label>
          </div>
          <div class="card-body-content">
            <input type="email" class="form-control" name="email" placeholder="Insert email" [(ngModel)]="contact.email"
              [ngModelOptions]="{standalone: true}" appOnFocusOut (focusout)="!validation ? validateFields(): ''">
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="form-group">
          <div class="card-body-title-basic">
            <label>
              Title
            </label>
          </div>
          <div class="card-body-content">
            <input type="text" class="form-control" placeholder="Insert title" [(ngModel)]="contact.job_title"
              [ngModelOptions]="{standalone: true}">
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="form-group">
          <div class="card-body-title-basic">
            <label>
              * Company
              <span *ngIf="!validation && (!contact.company)" [ngClass]="{'primary-color': !contact.company}">
                (Required field)
              </span>
            </label>
          </div>
          <div class="card-body-content">
            <ng-select 
              [clearable]="false"
              [items]="companies$ | async"
              [hideSelected]="true"
              [addTag]="addTagCompany"
              bindLabel="name" 
              [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.company"
              dropdownPosition="bottom"
              [minTermLength]="minLengthTerm" 
              [loading]="companyLoading"
              typeToSearchText="Please enter {{minLengthTerm}} or more characters" 
              [typeahead]="companyInput$"
              [(ngModel)]="contact.company">
              <ng-template ng-option-tmp let-item="item" let-index="i">
                <div class="media mb-1">
                  <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                    [src]="item.photo ? item.photo : globals.companyAvatar"
                    (error)="item.photo = globals.companyAvatar" />
                  <div class="media-body">
                    <div class="media-body-title mt-1 mb-0">{{item.name}}</div>
                    <p><i *ngIf="item.location"
                        class="tt-icon-sm icon-ttrelocation"></i>{{item.location ? item.location : globals.labels.placeholders.shared_page.add_job.empty_location}}
                    </p>
                  </div>
                </div>
              </ng-template>
              <ng-template ng-tag-tmp let-search="searchTerm">
                <button
                  class="btn bg-secondary-color btn-position pull-right mb-0">{{globals.labels.placeholders.shared_page.add_job.add_company}}
                  {{search}}</button>
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-12">
        <div class="form-group">
          <div class="card-body-title-basic">
            <label>
              Linkedin
            </label>
          </div>
          <div class="card-body-content">
            <input type="text" class="form-control" placeholder="Insert Linkedin" [(ngModel)]="contact.linkedin"
              [ngModelOptions]="{standalone: true}">
          </div>
        </div>
      </div>
      <div class="col-md-4 col-12">
        <div class="form-group">
          <div class="card-body-title-basic">
            <label>
              Twitter
            </label>
          </div>
          <div class="card-body-content">
            <input type="text" class="form-control" placeholder="Insert twitter" [(ngModel)]="contact.twitter"
              [ngModelOptions]="{standalone: true}">
          </div>
        </div>
      </div>
      <div class="col-md-4 col-12">
        <div class="form-group">
          <div class="card-body-title-basic">
            <label>
              Facebook
            </label>
          </div>
          <div class="card-body-content">
            <input type="text" class="form-control" placeholder="Insert facebook" [(ngModel)]="contact.facebook"
              [ngModelOptions]="{standalone: true}">
          </div>
        </div>
      </div>
      <div class="col-md-4 col-12">
        <div class="form-group">
          <div class="card-body-title-basic">
            <label>
              Skype
            </label>
          </div>
          <div class="card-body-content">
            <input type="text" class="form-control" placeholder="Insert skype" [(ngModel)]="contact.skype"
              [ngModelOptions]="{standalone: true}">
          </div>
        </div>
      </div>
      <div class="col-md-4 col-12">
        <div class="form-group">
          <div class="card-body-title-basic">
            <label>
              Phone
            </label>
          </div>
          <div class="card-body-content">
            <input type="text" class="form-control" placeholder="Insert phone" [(ngModel)]="contact.phone"
              [ngModelOptions]="{standalone: true}">
          </div>
        </div>
      </div>
      <div class="col-md-4 col-12">
        <div class="form-group">
          <div class="card-body-title-basic">
            <label>
              Mobile
            </label>
          </div>
          <div class="card-body-content">
            <input type="text" class="form-control" placeholder="Insert mobile" [(ngModel)]="contact.mobile"
              [ngModelOptions]="{standalone: true}">
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <div class="card-body-title-basic">
            <label>
              Note
            </label>
          </div>
          <div class="card-body-content">
            <textarea class="form-control" placeholder="Insert note" [(ngModel)]="contact.note"
              [ngModelOptions]="{standalone: true}"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="position-bottom full-width">
  <button type="button" class="btn pull-left bg-primary-color" (click)="initComponent()">Clear data
  </button>
  <button type="button" class="btn pull-right bg-secondary-color mr-4" (click)="createContact()">Add Contact
  </button>
</div>