<div class="card-block" *ngIf="loadComponent">
  <nav ngbNav #nav="ngbNav" class="full-width nav-tabs"  id="tab-shadow">
    <ng-container [ngbNavItem]="'ngb-tab-0'">
      <a ngbNavLink>Details</a>
      <ng-template ngbNavContent>
        <div class="card card-top scroll">
          <div class="card-block">
            <div class="card-body">
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>* Job title
                    <span *ngIf="!validForm && !job.title" [ngClass]="{'primary-color': !job.title}">
                      (Please fill in this field)
                    </span>
                  </label>
                </div>
                <div class="card-body-content">
                  <input class="form-control" type="text" [(ngModel)]="job.title"
                    placeholder="Enter job title" />
                </div>
              </div>
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>* Hiring Company</label>
                </div>
                <div class="card-body-content">
                  <ng-select [items]="hiringCompanies$ | async" [hideSelected]="true" dropdownPosition="bottom"
                    bindLabel="name" [loading]="companyLoading" [typeahead]="companyinput$" [clearable]="false"
                    [(ngModel)]="job.company" placeholder="Select company"  [ngModelOptions]="{standalone: true}"
                    [minTermLength]="minLengthTerm" typeToSearchText="Please enter {{minLengthTerm}} or more characters"
                    (ngModelChange)="blacklistCompany($event)">
                    <ng-template ng-option-tmp let-item="item" let-index="i">
                      <div class="media mb-1">
                        <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                          [src]="item.photo"
                          (error)="item.photo = globals.companyAvatar" />
                        <div class="media-body">
                          <div class="media-body-title mt-1 mb-0"><b>{{item.name}}</b></div>
                        </div>
                      </div>
                    </ng-template>
                  </ng-select>
                  <div class="full-width mt-2">
                    <button type="button" class="btn bg-secondary-color"
                      (click)="addHiringCompany()">Add Hiring Company</button>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>* Job type</label>
                </div>
                <div class="card-body-content">
                  <div class="row">
                    <div class="col-12">
                      <ng-select [items]="resources.jobTypes" bindLabel="description" dropdownPosition="bottom" [clearable]="false"
                        placeholder="Select job type"  [(ngModel)]="job.job_type" (change)="onJobTypeChange()">
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>Consultants</label>
                </div>
                <div class="card-body-content">
                  <ng-select [items]="consultants" [clearable]="true"
                    placeholder="Select consultant" bindLabel="name" (open)="getConsultants()"
                    [multiple]="true" [(ngModel)]="job.consultant" (remove)="checkClearLeadConsultant($event)" (clear)="checkClearLeadConsultant()"
                    (change)="checkLeadConsultant($event)">
                    <ng-template ng-option-tmp let-item="item" let-index="i">
                      <div class="media mb-1">
                        <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                          [src]="item.picture_file_url"
                          (error)="item.picture_file_url = globals.avatar" />
                        <div class="media-body">
                          <div class="media-body-title mt-1 mb-0"><b>{{item.name}}</b></div>
                          <span>{{item.email}}</span>
                        </div>
                      </div>
                    </ng-template>
                  </ng-select>
                  <div class="full-width mt-2">
                    <button type="button" class="btn bg-secondary-color"
                      (click)="addConsultant()">Add Consultant</button>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>* Consultant Company</label>
                </div>
                <div class="card-body-content">
                  <ng-select [items]="consultantCompany" placeholder="Select consultant company" bindLabel="name"
                     [(ngModel)]="job.consultant_company" [clearable]="false" (change)="onConsultantCompanyChange()">
                  </ng-select>
                  <div class="full-width mt-2">
                    <button type="button" class="btn bg-secondary-color" (click)="addConsultantCompany()">Add Consultant Company</button>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>Lead Consultant</label>
                </div>
                <div class="card-body-content">
                  <ng-select [items]="consultants" [clearable]="true"
                    placeholder="Select consultant" bindLabel="name" (open)="getConsultants()" [(ngModel)]="job.lead_consultant"
                    (change)="setLeadConsultant($event)">
                    <ng-template ng-option-tmp let-item="item" let-index="i">
                      <div class="media mb-1">
                        <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                          [src]="item.picture_file_url"
                          (error)="item.picture_file_url = globals.avatar" />
                        <div class="media-body">
                          <div class="media-body-title mt-1 mb-0"><b>{{item.name}}</b></div>
                          <span>{{item.email}}</span>
                        </div>
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div class="form-group">
                  <div class="card-body-title-basic">
                  <label>Location</label>
                </div>
                <div class="card-body-content">
                  <div class="row">
                    <div class="col-12">
                      <ng-select bindLabel="description" dropdownPosition="bottom"
                        placeholder="Select location"
                        typeToSearchText="Type at least 3 characters to search"
                        [items]="locations$ | async" [(ngModel)]="job.location" [minTermLength]="minLengthTerm"
                        [ngModelOptions]="{standalone: true}" [loading]="locationLoading" [typeahead]="locationInput$">
                    </ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-6">
                    <div class="card-body-title-basic">
                      <label>
                        * Functional expertise
                      </label>
                    </div>
                    <ng-select [items]="resources.fe" bindLabel="description" dropdownPosition="bottom" [clearable]="true"
                      placeholder="Select functional expertise" [clearable]="false" [(ngModel)]="job.fe" (change)="onFEChange()">
                    </ng-select>
                  </div>
                  <div class="col-6">
                    <div class="card-body-title-basic">
                      <label>
                        Sub Functional expertise
                      </label>
                    </div>
                    <ng-select [items]="resources.sfe" bindLabel="description" dropdownPosition="bottom" [clearable]="true"
                      placeholder="Select sub functional expertise"  [(ngModel)]="job.sfe">
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>
                    * Seniority
                  </label>
                </div>
                <div class="card-body-content">
                  <div class="row">
                    <div class="col-12">
                      <ng-select [items]="resources.seniority" bindLabel="description" dropdownPosition="bottom" [clearable]="true"
                        placeholder="Select seniority" [clearable]="false" [(ngModel)]="job.seniority">
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>
                    Deadline to client
                  </label>
                </div>
                <div class="card-body-content">
                  <div class="row">
                    <div class="col-12">
                      <input type="date" [(ngModel)]="job.deadline_to_client" [ngModelOptions]="{standalone: true}"
                      class="form-control">
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>Search Stage</label>
                </div>
                <div class="card-body-content">
                  <div class="row">
                    <div class="col-12">
                      <ng-select [items]="resources.searchStages" bindLabel="description" dropdownPosition="bottom"
                        placeholder="Select search stage"  [(ngModel)]="job.search_stage" >
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>Additional info</label>
                </div>
                <div class="card-body-content">
                  <div class="row">
                    <div class="col-12">
                      <quill-editor id="quill-editor-job" [(ngModel)]="job.additional_info" placeholder="Add additional info" >
                      </quill-editor>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-container [ngbNavItem]="'team'">
      <a ngbNavLink>Team</a>
      <ng-template ngbNavContent>
        <div class="card card-top scroll">
          <div class="card-block">
            <div class="card-body">
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>Users</label>
                </div>
                <ng-select #usersSelect [items]="resources.internalUsers" [clearable]="true"
                  placeholder="Select an user" bindLabel="name" (open)="getInternalUsers()" (change)="setPersonData($event)">
                  <ng-template ng-option-tmp let-item="item" let-index="i">
                    <div class="media mb-1">
                      <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                        [src]="item.picture_file_url"
                        (error)="item.picture_file_url = globals.avatar" />
                      <div class="media-body">
                        <div class="media-body-title mt-1 mb-0"><b>{{item.name}}</b></div>
                        <span>{{item.email}}</span>
                      </div>
                    </div>
                  </ng-template>
                </ng-select>
              </div>
              <div class="scroll scroll-users" *ngIf='job.users.length'>
                <div class="card" *ngFor="let user of job.users; let i = index">
                  <div class="card-block">
                    <div class="pull-right">
                      <i class="tt-icon-lg fa fa-trash-o"
                        data-toggle="tooltip" data-placement="bottom"
                        (click)="deleteCard(i,'users')" title="delete user"></i>
                    </div>
                    <div class="media">
                      <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                        [src]="user.picture_file_url" (error)="globals.avatar" />
                      <div class="media-body align-self-center">
                        <div class="media-body-title mt-1 mb-0">{{user.name}}</div>
                        <p> {{user.title}} </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-container [ngbNavItem]="'compensation'">
      <a ngbNavLink>Compensation
        <span class="badge m-0 badge-primary" *ngIf="badgeFlagCompensation">
          <i class="fa fa-circle"></i>
        </span>
      </a>
      <ng-template ngbNavContent>
        <div class="card card-top scroll">
          <div class="card-block">
            <div class="card-body">
              <div class="form-group">
                <div class="row">
                  <div class="col-md-12 col-lg-6">
                    <div class="card-body-title-basic">
                      <label>Annual Salary</label>
                    </div>
                    <div class="card-body-content">
                      <input type="number" class="form-control" min="0" placeholder="Insert annual salary"
                      [(ngModel)]="job.compensation.annual_salary_amount" >
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-6">
                    <div class="card-body-title-basic">
                      <label>Annual salary currency</label>
                    </div>
                    <div class="card-body-content">
                      <ng-select  [(ngModel)]="job.compensation.annual_salary_currency" [ngModelOptions]="{standalone: true}" bindValue="value" bindLabel="description"  [items]="resources.currencies" dropdownPosition="bottom" placeholder = "Select currency">
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-md-12 col-lg-6">
                    <div class="card-body-title-basic">
                      <label>Signing cash bonus amount</label>
                    </div>
                    <div class="card-body-content">
                      <input type="number" class="form-control" min="0" placeholder="Insert signing cash bonus amount"
                      [(ngModel)]="job.compensation.signing_cash_bonus_amount">
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-6">
                    <div class="card-body-title-basic">
                      <label>Signing cash bonus currency</label>
                    </div>
                    <div class="card-body-content">
                      <ng-select  [(ngModel)]="job.compensation.signing_cash_bonus_currency" bindValue="value" bindLabel="description"  [items]="resources.currencies"
                        dropdownPosition="bottom" placeholder = "Select signing cash bonus currency">
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-md-12 col-lg-4">
                    <div class="card-body-title-basic">
                      <label>Equities percent</label>
                    </div>
                    <div class="card-body-content">
                      <input type="number" class="form-control" min="0" placeholder="Insert equities pecent"
                      [(ngModel)]="job.compensation.equities.percent">
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-4">
                    <div class="card-body-title-basic">
                      <label>Equities amount estimation</label>
                    </div>
                    <div class="card-body-content">
                      <input type="number" class="form-control" min="0" placeholder="Insert equities amount estimation"
                      [(ngModel)]="job.compensation.equities.amount_estimation">
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-4">
                    <div class="card-body-title-basic">
                      <label>Equities amount currency</label>
                    </div>
                    <div class="card-body-content">
                      <ng-select [(ngModel)]="job.compensation.equities.amount_currency" bindValue="value" bindLabel="description" [items]="resources.currencies"
                        dropdownPosition="bottom" placeholder = "Select equties currency">
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>Other</label>
                </div>
                <div class="card-body-content">
                  <div class="row">
                    <div class="col-12">
                      <textarea rows="2" class="form-control" type="text"
                        placeholder="Insert other information" [(ngModel)]="job.compensation.other">
                      </textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-container [ngbNavItem]="'search_scope'">
      <a ngbNavLink>Search Scope</a>
      <ng-template ngbNavContent>
        <div class="card card-top scroll">
          <div class="card-block">
            <div class="card-body">
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>Blacklisted Companies</label>
                </div>
                <div class="card-body-content">
                  <div class="row">
                    <div class="col-12">
                      <ng-select [items]="companies$ | async" #blacklistSelect [hideSelected]="true" dropdownPosition="bottom" [addTag]="addTagCompany"
                        bindLabel="name" [loading]="companyLoading" [multiple]="true" [typeahead]="companyinput$"
                        [(ngModel)]="job.blacklisted_companies" (remove)="loadCompany()" [clearable]="true" placeholder="Select company"  [ngModelOptions]="{standalone: true}">
                        <ng-template ng-option-tmp let-item="item" let-index="i">
                          <div class="media mb-1" [ngStyle]="{position: relative}">
                            <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                              [src]="item.images['32x32'] ? item.images['32x32'] : globals.companyAvatar"
                              (error)="item.images = globals.companyAvatar"/>
                            <div class="media-body">
                              <div class="media-body-title mt-1 mb-0">{{item.name}}, {{item.website_url}}</div>
                              <p *ngIf="item.hq_locations && item.hq_locations.length"><i
                                  class="tt-icon-sm icon-ttrelocation"></i>
                                <span *ngFor="let location of item.hq_locations;let last = last">
                                  {{location.address}} {{!last ? ' (HQ), ' : ' (HQ)'}}
                                </span>
                              </p>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template ng-tag-tmp let-search="searchTerm">
                          <button
                            class="btn bg-secondary-color btn-position pull-right mb-0" >Add company
                            {{search}}</button>
                        </ng-template>
                      </ng-select>
                    </div>
                    <div class="col-12">
                      <div class="custom-control custom-checkbox mt-2">
                        <input type="checkbox" [ngModelOptions]="{standalone: true}" id="include_company_blacklisted_companies"
                          [(ngModel)]="job.include_company_blacklisted_companies" class="custom-control-input">
                        <label class="custom-control-label cursor-pointer" for="include_company_blacklisted_companies">
                           Include consultant company blacklisted companies
                        </label>
                      </div>
                    </div>
                    <div class="col-12 d-flex align-items-center justify-content-center m-3 ">
                      <span class="text-color-700">Bulk import companies from .csv</span>
                      <button class="btn btn-sm bg-secondary-color btn-position mb-0 ml-2" (click)="blacklistedFileInput.click()">
                          choose .csv
                          <span *ngIf="csv_loading"
                              class="badge-icon-sm icon icon-loading icon-sm ml-1"></span>
                      </button>
                      <input type="file" ng2FileSelect [uploader]="blacklistCsvUploader" #blacklistedFileInput (click)="onFileClick($event)"
                          (change)="onBlacklistedCompanyInsertCSV()" class="d-none">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ngb-accordion id="filter-accordion" [(activeIds)]="activeIds" #acc="ngbAccordion"
            (panelChange)="preventDefault ? beforeChange($event) : ''">
            <ngb-panel id="blacklisted_candidates" class="mt-2">
              <ng-template ngbPanelHeader>
                <div class="form" (click)="acc.toggle('blacklisted_candidates')">
                  <div class="form-section">
                    Blacklisted candidates
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="row card-block">
                  <div class="col-12">
                    <div class="form-group">
                      <div class="card-body-content">
                        <div class="form-group">
                          <input class="form-control" type="text" [(ngModel)]="blacklisted_candidate"
                            [ngModelOptions]="{standalone: true}" placeholder="+ blacklisted candidates"
                            (keyup.enter)="addBlacklistedCandidate()"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row card-block" *ngIf="job.blacklisted_candidates.length">
                  <div class="col-12">
                    <div class="form-group">
                      <div class="card-body-content">
                        <ul class="media-list media-bordered pl-0">
                          <li class="media "*ngFor="let blacklisted_candidate of job.blacklisted_candidates; let i = index">
                            <div class="col-11">
                              <a [href]="blacklisted_candidate.linkedin_url" target="_blank">{{blacklisted_candidate.linkedin_url}}</a>
                            </div>
                            <div class="col-1">
                              <i class="tt-icon-sm icon-ttdelete pull-right m-2" (click)="deleteBlacklistedCandidate(i)"></i>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row card-block" *ngIf="!job.blacklisted_candidates.length">
                  <div class="col-12">
                    No candidates available
                  </div>
                </div>
                <div class="col-12">
                  <div class="custom-control custom-checkbox mt-2">
                    <input type="checkbox" [ngModelOptions]="{standalone: true}" id="include_company_blacklisted_candidates"
                      [(ngModel)]="job.include_company_blacklisted_candidates" class="custom-control-input">
                    <label class="custom-control-label cursor-pointer" for="include_company_blacklisted_candidates">
                       Include consultant company blacklisted candidates
                    </label>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
            <ngb-panel id="archetypes">
              <ng-template ngbPanelHeader>
                <div class="form" (click)="acc.toggle('archetypes')">
                  <div class="form-section">
                    Personas
                    <div class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2"
                    (click)="addNewArchetype() ">
                      Add new
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div *ngIf="job.archetypes.length; then showArchetypes; else showEmptyArchetypes">
                </div>
                <ng-template #showArchetypes>
                  <div class="form-group mb-5" *ngFor="let archetype of job.archetypes; let i = index">
                    <div class="card card-preview">
                      <div class="row">
                        <div class="col-11">
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>
                                Name
                              </label>
                            </div>
                            <div class="card-body-content">
                              <input type="text" class="form-control" placeholder="Insert persona name"
                                [(ngModel)]="archetype.name" [ngModelOptions]="{standalone: true}">
                            </div>
                          </div>
                        </div>
                        <div class="col-1">
                          <i class="tt-icon-lg fa fa-trash-o"
                            data-toggle="tooltip" data-placement="bottom"
                            (click)="deleteCard(i, 'archetypes')" title="delete persona"></i>
                        </div>
                        <div class="col-11">
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>
                                Description
                              </label>
                            </div>
                            <div class="card-body-content">
                              <input type="text" class="form-control" placeholder="Insert persona description"
                                [(ngModel)]="archetype.description" [ngModelOptions]="{standalone: true}">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template #showEmptyArchetypes>
                  <div class="row card-block">
                    <div class="col-12">
                      No personas available
                    </div>
                  </div>
                </ng-template>
              </ng-template>
            </ngb-panel>
            <ngb-panel id="hunting_grounds" class="mb-3">
              <ng-template ngbPanelHeader>
                <div class="form" (click)="acc.toggle('hunting_grounds')">
                  <div class="form-section">
                    Hunting Grounds
                    <div class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2"
                      (click)="addNewHuntingGround()">
                      Add new
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div
                *ngIf="job.hunting_grounds.length; then showHuntingGrounds; else showEmptyHuntingGrounds">
                </div>
                <ng-template #showHuntingGrounds>
                  <div class="form-group mb-5" *ngFor="let huntingGround of job.hunting_grounds; let i = index">
                    <div class="card card-preview">
                      <div class="row">
                        <div class="col-11">
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>
                                Name
                              </label>
                            </div>
                            <div class="card-body-content">
                              <input type="text" class="form-control" placeholder="Insert hunting ground name"
                                [(ngModel)]="huntingGround.name" [ngModelOptions]="{standalone: true}">
                            </div>
                          </div>
                        </div>
                        <div class="col-1">
                          <i class="tt-icon-lg fa fa-trash-o"
                            data-toggle="tooltip" data-placement="bottom"
                            (click)="deleteCard(i,'hunting_grounds')" title="delete hunting ground"></i>
                        </div>
                        <div class="col-11">
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>
                                Description
                              </label>
                            </div>
                            <div class="card-body-content">
                              <input type="text" class="form-control" placeholder="Insert hunting ground description"
                                [(ngModel)]="huntingGround.description" [ngModelOptions]="{standalone: true}">
                            </div>
                          </div>
                        </div>
                        <div class="col-11">
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>
                                Companies
                              </label>
                            </div>
                            <div class="card-body-content">
                              <ng-select [items]="companies$ | async" #huntingGroundSelect [hideSelected]="true"  [addTag]="addTagHGCompany" (remove)="loadCompany()"
                                bindLabel="name" [loading]="companyLoading" [multiple]="true" [typeahead]="companyinput$" (open)="saveIndex(i, huntingGroundSelect)"
                                [(ngModel)]="huntingGround.companies"  dropdownPosition="bottom" [clearable]="true" placeholder="Select company"
                                [ngModelOptions]="{standalone: true}">
                                <ng-template ng-option-tmp let-item="item" let-index="i">
                                  <div class="media mb-1">
                                    <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                                      [src]="item.images['32x32'] ? item.images['32x32'] : globals.companyAvatar"
                                      (error)="item.images = globals.companyAvatar" />
                                    <div class="media-body">
                                      <div class="media-body-title mt-1 mb-0">{{item.name}}, {{item.website_url}}</div>
                                      <p *ngIf="item.hq_locations.length"><i
                                          class="tt-icon-sm icon-ttrelocation"></i>
                                        <span *ngFor="let location of item.hq_locations;let last = last">
                                          {{location.address}} {{!last ? ' (HQ), ' : ' (HQ)'}}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </ng-template>
                                <ng-template ng-tag-tmp let-search="searchTerm">
                                  <button
                                    class="btn bg-secondary-color btn-position pull-right mb-0">Add company
                                    {{search}}</button>
                                </ng-template>
                              </ng-select>
                              <div class="col-12 d-flex align-items-center justify-content-center m-3 ">
                                <span class="text-color-700">Bulk import companies from .csv</span>
                                <button class="btn btn-sm bg-secondary-color btn-position mb-0 ml-2" (click)="fileInput.click()">
                                    choose .csv
                                    <span *ngIf="csv_loading && fileIndex === i"
                                        class="badge-icon-sm icon icon-loading icon-sm ml-1"></span>
                                </button>
                                <input type="file" ng2FileSelect [uploader]="csv_uploader" #fileInput (click)="onFileClick($event)"
                                    (change)="OnContentChangeCsv($event, i)" class="d-none">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template #showEmptyHuntingGrounds>
                  <div class="row card-block">
                    <div class="col-12">
                      No hunting grounds available
                    </div>
                  </div>
                </ng-template>
              </ng-template>
            </ngb-panel>
            <ngb-panel id="scorecards">
              <ng-template ngbPanelHeader>
                <div class="form" (click)="acc.toggle('scorecards')">
                  <div class="form-section">
                    Scorecards
                    <div class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2"
                    (click)="addNewScorecard()">
                    Add new
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div
                *ngIf="job.scorecards.length; then showScorecards; else showEmptyScorecards">
                </div>
                <ng-template #showScorecards>
                  <div class="card card-preview mb-5">
                    <div class="form-group" *ngFor="let scorecard of job.scorecards; let i = index">
                      <div class="row">
                        <div class="col-11">
                          <div class="card-body-content">
                            <input class="form-control" type="text" [(ngModel)]="scorecard.description"
                            placeholder="Insert scorecard name">
                          </div>
                        </div>
                        <div class="col-1">
                          <a (click)="deleteScorecard(i)">
                            <i class="tt-icon-sm icon-ttdelete pull-right m-2" data-toggle="tooltip"
                              data-placement="bottom"
                              title="delete scorecard"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template #showEmptyScorecards>
                  <div class="row card-block">
                    <div class="col-12">
                      No scorecards available
                    </div>
                  </div>
                </ng-template>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </ng-template>
    </ng-container>
    <ng-container   [ngbNavItem]="'files'">
      <a ngbNavLink>Files</a>
      <ng-template ngbNavContent>
        <div class="card card-top scroll">
          <div class="card-block">
            <div class="card-body">
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>File type</label>
                </div>
                <div class="card-body-content">
                  <ng-select [(ngModel)]="tmpFiles.type" bindLabel="description" (open)="getFileTypes()" [items]="resources.jobFileTypes"
                    dropdownPosition="bottom" placeholder="Select file type">
                  </ng-select>
                </div>
              </div>
              <div class="form-group" *ngIf="tmpFiles.type">
                <div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)"
                  [uploader]="uploader" (onFileDrop)="afterFileisDrop()" class="p-3 text-center grey my-drop-zone">
                  <span>Drag and drop resume here<br>or<br></span>
                  <button class="btn bg-primary-color col-md-3 col-lg-3 col-xl-3 btn-position"
                    (click)="fileInput.click()">
                    Upload file
                  </button>
                </div>
                <input class="hiddenInput m-0 p-0 position-absolute overflow-hidden" type="file" ng2FileSelect
                  [uploader]="uploader" #fileInput (change)="OnContentChange($event)">
              </div>
              <div class="form-group p-1" *ngIf="job.files && job.files.length">
                <div class="card text-center">
                  <div class="row">
                    <div class="col-md-4 col-sm-12" *ngFor="let file of job.files; let i = index">
                      <div class="card-body">
                        <div class="card-block">
                          <div [ngSwitch]="file.name.substr(file.name.lastIndexOf('.')+1).toLowerCase()">
                            <div *ngSwitchCase="'pdf'">
                              <i class="fa fa-file-pdf-o fa-5x primary-color m-2"
                                aria-hidden="true"></i>
                            </div>
                            <div *ngSwitchCase="'xlsx'">
                              <i class="fa fa-file-excel-o fa-5x text-success m-2"
                                aria-hidden="true"></i>
                            </div>
                            <div *ngSwitchCase="'msword'">
                              <i class="fa fa-file-word-o fa-5x big-search-primary-color m-2"
                                aria-hidden="true"></i>
                            </div>
                            <div *ngSwitchCase="'docx'">
                              <i class="fa fa-file-word-o fa-5x big-search-primary-color m-2"
                                aria-hidden="true"></i>
                            </div>
                            <div *ngSwitchCase="'doc'">
                              <i class="fa fa-file-word-o fa-5x big-search-primary-color m-2"
                                aria-hidden="true"></i>
                            </div>
                            <div *ngSwitchCase="'pptx'">
                              <i class="fa fa-file-powerpoint-o fa-5x warning-color m-2"
                                aria-hidden="true"></i>
                            </div>
                            <div *ngSwitchCase="'png'">
                              <i class="fa fa-file-image-o fa-5x warning-color m-2"
                                aria-hidden="true"></i>
                            </div>
                            <div *ngSwitchCase="'jpg'">
                              <i class="fa fa-file-image-o fa-5x warning-color m-2"
                                aria-hidden="true"></i>
                            </div>
                            <div *ngSwitchCase="'jpeg'">
                              <i class="fa fa-file-image-o fa-5x warning-color m-2"
                                aria-hidden="true"></i>
                            </div>
                            <div *ngSwitchDefault>
                              <i class="fa fa-file-o fa-5x m-2"
                                aria-hidden="true"></i>
                            </div>
                          </div>
                          <div class="card-body-title-basic">{{file.name}}</div>
                          <p class="card-text">
                            <a [href]="file.url" target="_blank">
                              <i class="tt-icon-lg icon-ttinprocess secondary-color rotate" data-toggle="tooltip"
                                data-placement="bottom"
                                title="Preview file"></i>
                            </a>
                            <a (click)="deleteCard(i,'files')">
                              <i class="tt-icon-lg icon-ttdelete primary-color" data-toggle="tooltip"
                                data-placement="bottom"
                                title="Delete file"></i>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-container   [ngbNavItem]="'settings'">
      <a ngbNavLink>Settings</a>
      <ng-template ngbNavContent>
        <div class="card card-top scroll">
          <div class="card-block">
            <div class="card-body">
              <div class="form-group" *ngFor="let item of job.settings;let first = first;let last = last; let i = index ">
                <div class="row">
                  <div class="col-12">
                    <div class="card-body-title-basic">
                      <label>{{item.description}}</label>
                    </div>
                  </div>
                  <div class="col-12">
                    <ng-select
                        bindLabel="description" dropdownPosition="bottom"
                        placeholder="Select setting value" [items]="allowedValues"
                        [(ngModel)]="job.settings[i].allowed_values[0]" [ngModelOptions]="{standalone: true}"
                        (open)="getAllowedValues(item.value)" [clearable]="false">
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </nav>
  <div [ngbNavOutlet]="nav"></div>
  <div class="position-bottom full-width">
    <button type="button" class="btn pull-right bg-secondary-color"
      (click)="disableButton ? createJob(): ''">Create Job</button>
  </div>
</div>
