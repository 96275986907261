import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from 'app/globals';
import { environment } from 'environments/environment';
import { FileUploader } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import { CandidateNextStepEmailModalService } from './candidate-next-step-email-modal.service';

@Component({
  selector: 'app-candidate-next-step-email-modal',
  templateUrl: './candidate-next-step-email-modal.component.html',
  styleUrls: ['./candidate-next-step-email-modal.component.scss']
})
export class CandidateNextStepEmailModalComponent implements OnInit{
  data: any = {};

  resources: any = {
    templates: [],
    contacts: []
  };

  show: any = {
    component: false,
    emailUpdate: false,
    loading: false,
    body: false
  };

  uploader: FileUploader;

  hasBaseDropZoneOver = false;

  @ViewChild('editor') editor;

  validForm = true;

  constructor(public activeModal: NgbActiveModal, public globals: Globals, private candidateNextStepModalService: CandidateNextStepEmailModalService, public toastr: ToastrService){
    const URL = environment.platformBackendURL + '/assets';
    this.uploader = new FileUploader({
      url: URL,
      isHTML5: true,
      method: 'POST',
      itemAlias: 'file',
      authTokenHeader: 'authorization',
      authToken: 'Bearer ' + localStorage.getItem('access-token')
    });

    this.uploader.onCompleteItem = (item: any, response: any, status: any) => {
      this.show.loading = false;
      const obj = JSON.parse(response);
      if (status === 201){
        this.data.assets.push(obj);
        this.show.emailUpdate = true;
      } else {
        this.toastr.error(obj.message);
      }
    };
  }

  fileOverBase(e: any): void{
    this.hasBaseDropZoneOver = e;
    this.uploader.uploadAll();
  }

  OnContentChange(event){
    if (event.target.value){
      this.show.loading = true;
      this.uploader.uploadAll();
    } else {
      this.show.loading = false;
    }
  }

  ngOnInit(){
    if (this.data){
      this.data.users.from.signature = this.data.body.substring(this.data.body.indexOf("<table"), this.data.body.length);
      this.data.body = this.data.body.slice(0, this.data.body.indexOf("<table"));
      this.show.emailUpdate = false;
      this.show.component = true;
    }
  }

  getTemplates(){
    if (!this.resources.templates.length){
      this.candidateNextStepModalService.getTemplatesResources().subscribe(
        (response) => {
          if (response){
            this.resources.templates = response;
          }
        },
        (err) => {
          if (err.error){
            for (const errProp in err.error){
              if (err.error.hasOwnProperty(errProp) && err.error[errProp].length){
                this.toastr.error(err.error[errProp]);
              }
            }
          } else {
            this.toastr.error('Something went wrong');
          }
        }
      );
    }
  }

  getContacts(){
    if (!this.resources.contacts.length){
      this.candidateNextStepModalService.getContactsResources().subscribe(
        (response) => {
          if (response){
            this.resources.contacts = response;
          }
        },
        (err) => {
          if (err.error){
            for (const errProp in err.error){
              if (err.error.hasOwnProperty(errProp) && err.error[errProp].length){
                this.toastr.error(err.error[errProp]);
              }
            }
          } else {
            this.toastr.error('Something went wrong');
          }
        }
      );
    }
  }

  removeFile(data, i){
    data.assets.splice(i, 1);
    this.show.emailUpdate = true;
  }

  bodyModelChange(){
    if (this.show.body){
      this.show.emailUpdate = true;
    }
    this.show.body = true;
  }

  checkRequiredFiels(){
    if (this.data.body && this.data.subject && this.data.users.from){
      this.validForm = true;
      return true;
    }
    this.validForm = false;
    return false;
  }

  editNextStepEmail(){
    if (this.data.body && !this.data.body.replace(/<[^>]*>/g, '')){
      this.data.body = null;
    }
    if (this.checkRequiredFiels()){
      const body = this.data.body + this.data.users.from.signature;
      this.data.body = body;
      this.activeModal.close(this.data);
    }
  }

  afterFileisDrop(){
    this.show.loading = true;
  }

}
