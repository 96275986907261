import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Globals } from 'app/globals';
import { AddToAnotherJobService } from './add-to-another-job.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-add-to-another-job',
    templateUrl: './add-to-another-job.component.html',
    styleUrls: ['./add-to-another-job.component.scss']
})

export class AddToAnotherJobComponent implements OnInit{
    candidates: any;

    enableResearchNoteCheckbox = false;

    includeResearchNote = false;

    openedFrom = '';

    addTojob: any = {
        job_uuid: null,
        status: null,
        persona_uuid: null
    };

    resources: any = {
        statuses: [],
        jobs: [],
        archetypes: [],
        tiers: []
    };

    closedJobs = false;

    constructor(public globals: Globals,
        private addToJobCandidatesService: AddToAnotherJobService,
        public toastr: ToastrService,
        public activeModal: NgbActiveModal){
    }

    ngOnInit(){
    }

    getJobs(){
        if (!this.resources.jobs.length){
            this.addToJobCandidatesService.getJobs(this.closedJobs).
                subscribe((data) => {
                    this.resources.jobs = data;
                });
        }
    }

    getStatuses(){
        if (!this.resources.statuses.length){
            this.addToJobCandidatesService.getStatuses(this.addTojob.job_uuid.uuid).subscribe(
                (data) => {
                    this.resources.statuses = data;
                });
        }
    }

    getArchetype(){
        if (!this.resources.archetypes.length){
            this.addToJobCandidatesService.getJobArchetypes(this.addTojob.job_uuid.uuid).subscribe(
                (data) => {
                    const archetypes = data.filter(s => s.type === 'persona');
                    this.resources.archetypes = archetypes;
                });
        }
    }
    // getTier(){
    //     if (!this.resources.tiers.length){
    //         this.addToJobCandidatesService.getResourcesByType('candidate_ranking').subscribe(
    //             (data) => {
    //                 this.resources.tiers = data;
    //             });
    //     }
    // }

/**
 * @description This method is run when 'Add to job' button is clicked on the modal. Checks the source from where the modal is opened. Depending on the source, adds all 'navis1' candidates to 'navis' first before the application update happens.
 */
    addToJob(){
        if (!this.addTojob['status']){
            return this.toastr.error('Status is mandatory');
        }
        const body = [];
        if (this.openedFrom === 'getnavis-candidates'){
            this.candidates.filter(candidate => candidate.source === 'navis')
                .forEach(candidate => body.push(this.populateBody(candidate)));
            const navis1Candidates = this.candidates.filter(candidate => candidate.source === 'navis1');
            return this.addNavis1CandidatesToNavis(navis1Candidates, body);
        }
        for (const candidate of this.candidates){
            if (this.openedFrom === 'view-candidate'){
                return this.addNavis1CandidatesToNavis([candidate], body);
            }
            body.push(this.populateBody(candidate));
        }
        return this.createApplication(body);
    }

/**
 * @description This method is used to add candidates from 'navis1' to 'navis'
 * @param candidates
 * @param body
 */
    addNavis1CandidatesToNavis(candidates, body){
        this.addToJobCandidatesService.addCandidateToNavis([...candidates]).subscribe(
            (response) => {
                response.forEach(uuid => body.push(this.populateBody(null, uuid)));
                this.createApplication(body);
            }, (err) => {
                this.displayErrorMessage(err);
            }
        );
    }

/**
 * if job was changed we clear up archetype and hunting ground if they were selected
 */
    changedJob(){
        this.addTojob.archetype = null;
        this.addTojob.hunting_ground = null;
        this.resources.huntingGrounds = [];
        this.resources.archetypes = [];
        this.includeResearchNote = false;
    }

    populateBody(candidate, uuid?){
        const body = {
            job_uuid: this.addTojob.job_uuid.uuid,
            candidate_uuid: uuid || (candidate.uuid || candidate.candidate_uuid),
            status: this.addTojob.status,
            persona_uuid: this.addTojob.persona_uuid
        };
        if (this.includeResearchNote){
            body['research_note'] = candidate.research_note;
        }
        return body;

    }

    createApplication(body){
        this.addToJobCandidatesService.createApplication(body).
            subscribe((data) => {
                this.responseMessages(data.message);
                this.activeModal.close(body);
            },
            (err) => {
                this.displayErrorMessage(err);
            });
    }

/**
 * @description This method displays the response messages for each successful or unsuccesful candidate applications. Useful when adding canddidates in bulk where different cases can occur.
 * @param data An object that holds "success" and "error" arrays consisting of response message for each candidate application.
 */
    responseMessages(data){
        if (data.success){
            data.success.forEach(candidateResponse => this.toastr.success(candidateResponse.message));
        }
        if (data.error){
            data.error.forEach(candidateResponse => this.toastr.error(candidateResponse.message));
        }
    }

    closePopup(){
        this.addTojob = {
            job_uuid: null,
            status: null,
            archetype: null,
            hunting_ground: null
            // candidate_ranking: null
        };
        this.activeModal.dismiss();
    }

    displayErrorMessage(error){
        error = error?.error?.errors;//flatten error message
        if (error){
          for (const errProp in error){
            for (const message in error[errProp]){
              this.toastr.error(error[errProp][message]);
            }
          }
        } else {
          this.toastr.error('Something went wrong');
        }
      }
}
