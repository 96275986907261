import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from 'environments/environment';

@Injectable()
export class AddRevenueService{

  constructor(private http: HttpClient){ }

  getResources(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources?type=revenue_type,currencies');
  }

  getCompany(company): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/companies?name=' + company);
  }

  getCandidates(candidate_name): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/candidates?name=' + candidate_name);
  }

  getPodLeads(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/pods/podUsers?type=lead');
  }

  getPodUsers(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/pods/podUsers');
  }

  getJobs(job_name): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/jobs?name=' + job_name + '&type=open');
  }

  validateInvoice(body): Observable<any>{
    return this.http.post(environment.djangoURL + '/invoicing/api/validate', body);
  }

  sendInvoice(body): Observable<any>{
    return this.http.post(environment.djangoURL + '/invoicing/api/deliver', body);
  }

  addRevenue(body): Observable<any>{
    return this.http.post(environment.platformBackendURL + '/revenue', body);
  }

  getBillingContacts(): Observable<any>{
    const body = {
      'tenant': 'navis'
    };

    return this.http.post(environment.djangoURL + '/invoicing/api/xero/contacts', body);
  }

}
