import { Component, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { Globals } from 'app/globals';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { GetnavisAddConsultantService } from './getnavis-add-consultant.service';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-getnavis-add-consultant',
  templateUrl: './getnavis-add-consultant.component.html',
  styleUrls: ['./getnavis-add-consultant.component.scss']
})
export class GetnavisAddConsultantComponent implements OnInit{

  @Output() dismissDrawer: EventEmitter<boolean> = new EventEmitter<boolean>();

  consultant: any = null;

  companies: any = [];

  companyLoading = false;

  companyinput$ = new Subject<string>();

  resources: any = {
    jobs: []
  };

  @ViewChild('companySelect') companySelect: NgSelectComponent;

  public addTagCompany: (name) => void;

  constructor(public globals: Globals, private getNavisAddConsultantService: GetnavisAddConsultantService, private toastr: ToastrService){
    this.addTagCompany = (name) => this.addCompany(name);
  }

  addCompany(name){
    this.companyLoading = false;
    this.consultant.company = { name: name };
    this.companySelect.close();
  }

  ngOnInit(){
    this.initComponent();
    this.loadCompany();
  }

  initComponent(){
    this.consultant = {
      first_name: null,
      last_name: null,
      email: null,
      title: null,
      company: null,
      phone: null,
      jobs: [],
      picture_file_url: null
    };
  }

  createConsultant(){
    this.getNavisAddConsultantService.createConsultant(this.consultant)
      .subscribe((response) => {
        this.toastr.success(response.message);
        this.initComponent();
        this.dismissDrawer.emit(true);
      },
        (err) => {
          if (err.error){
            for (const errProp in err.error){
              if (err.error.hasOwnProperty(errProp)){
                if (err.error[errProp][0]){
                  this.toastr.error(err.error[errProp]);
                }
              }
            }
          } else {
            this.toastr.error(err.message);
          }
        });
  }

  getResources(value, search?){
    if (!this.resources[value].length){
      this.getNavisAddConsultantService.getResources(value, search).subscribe((data) => {
        if (data.length){
          this.resources[value] = data;
        }
      },
        (error) => {
          this.toastr.error(error.message);
        });
    }
  }

  loadCompany(search?){
    this.getNavisAddConsultantService.getConsultantCompanies(search).subscribe((data) => {
      if (data.length){
        this.companies = data;
      }
    },
      (error) => {
        this.toastr.error(error.message);
      });
  }
}

