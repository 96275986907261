import { Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Globals } from 'app/globals';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-get-navis-aggrid-name-column',
  templateUrl: './getnavis-aggrid-name-column.component.html',
  styleUrls: ['./getnavis-aggrid-name-column.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GetNavisAgGridNameColumnComponent implements ICellRendererAngularComp{

  public data: any;

  public candidateData: any = null;

  private male;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, public globals: Globals){

  }

  agInit(params: any): void{
    this.male = this.globals.avatar;
    this.candidateData = this.transformNameObject(params.data);
  }

  transformNameObject(data){
    return {
      name: data.first_name && data.last_name ? data.first_name + " " + data.last_name : "",
      linkedin: data.linkedin_url ? data.linkedin_url : "",
      photo: data.picture_file_url ? data.picture_file_url : "",
      id: data.id ? data.id : data.candidate_uuid
    };
  }

  refresh(): boolean{
    return true;
  }

  setAvatar = () => {
    if (this.candidateData.photo){
      return this.candidateData.photo;
    } else {
      return this.male;
    }
  };

  tagStyleSetter(type){
      switch (type){
        default:
          return "#fff";
      }
  }

  nameClick(e: Event){
    e.preventDefault();
    if (this.candidateData.id){
        this.router.navigate([],
            {
              relativeTo: this.activatedRoute,
              queryParams: {
                'drawer_parent': 'getnavis-candidate-drawer',
                'type': 'view-candidate',
                'job_uuid': this.router.parseUrl(this.router.url).root.children.primary.segments[1] ? this.router.parseUrl(this.router.url).root.children.primary.segments[1].toString() : null,
                'candidate_uuid': this.candidateData.id
              }
            });
    }
  }
}
