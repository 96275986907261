import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class AddConsultantCompanyModalService{

  constructor(private httpClient: HttpClient){ }

  createConsultantCompany(consultantCompany): Observable<any>{
    return this.httpClient.post(environment.getNavisBackendURL + '/merger/consultant_companies', consultantCompany);
  }

  getResources(value, search?): Observable<any>{
    return this.httpClient.get(environment.getNavisBackendURL + '/resources/' + value + (search ? '?search=' + search : ''));
  }

  //return only consultants that are not linked to any consultant company
  getUsers(value): Observable<any>{
    return this.httpClient.get(environment.getNavisBackendURL + '/merger/users/' + value + ('?has_company=0'));
  }

  getDealroomCompanies(company: string): Observable<any>{
    const body = {
      'keyword': company,
      'keyword_type': 'default',
      'keyword_match_type': 'exact',
      'fields': 'id,name,path,website_url,url,linkedin_url,images,hq_locations',
      form_data: {
              must: {},
              must_not: {},
              should: {}
            },
      'sort': 'name',
      limit: 25,
      offset: 0
    };

    return this.httpClient.post(environment.getNavisBackendURL + '/dealroom/companies', body).pipe(map((rsp: any) => rsp.items));
  }
}
