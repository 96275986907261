import { Component, EventEmitter, OnInit, ViewChild, Output, ViewEncapsulation } from '@angular/core';
import { Globals } from 'app/globals';
import { ToastrService } from 'ngx-toastr';
import { GetnavisAddHiringCompanyService } from './getnavis-add-hiring-company.service';
import { concat, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime, switchMap, tap, catchError } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-getnavis-add-hiring-company',
  templateUrl: './getnavis-add-hiring-company.component.html',
  styleUrls: ['./getnavis-add-hiring-company.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GetnavisAddHiringCompanyComponent implements OnInit{

  @Output() dismissDrawer: EventEmitter<boolean> = new EventEmitter<boolean>();

  hiringCompany: any = null;

  companies: Observable<any>;

  companyLoading = false;

  companyinput$ = new Subject<string>();

  tempCompanyName = null;

  @ViewChild('companiesSelect') companiesNgSelect: NgSelectComponent;

  constructor(public globals: Globals, private getNavisAddHiringCompanyService: GetnavisAddHiringCompanyService, private toastr: ToastrService){
  }

  ngOnInit(){
    this.initData();
    this.loadCompany();
  }

  initData(){
    this.hiringCompany = {
      name: null,
      photo: null,
      linkedin_url: null,
      dealroom_url: null,
      website_url: null,
      company_culture_description: null
    };
  }

  loadCompany(){
    this.companies = concat(
      of([]),
      this.companyinput$.pipe(
          debounceTime(200),
          distinctUntilChanged(),
          tap(() => this.companyLoading = true),
          switchMap(company =>
            this.getNavisAddHiringCompanyService.getDealroomCompanies(company).pipe(
                  catchError(() => of([])),
                  tap(() => {
                      this.companyLoading = false;
                  })
              )
          )
      )
    );
  }

  addCompany(name){
    this.initData();
    this.companyLoading = false;
    this.hiringCompany.name = name;
    this.companiesNgSelect.close();
  }

  createHiringCompany(){
      this.getNavisAddHiringCompanyService.createHiringCompany(this.hiringCompany)
      .subscribe((response) => {
       this.toastr.success(response.message);
       this.initData();
       this.dismissDrawer.emit(true);
      },
      (err) => {
        if (err.error){
          for (const errProp in err.error){
            if (err.error.hasOwnProperty(errProp)){
              if (err.error[errProp][0]){
                this.toastr.error(err.error[errProp]);
              }
            }
          }
        } else {
          this.toastr.error(err.message);
        }
      });
  }

  setCompanyData(data){
    if (data){
      this.hiringCompany.photo = data.photo || data.images['100x100'];
      this.hiringCompany.linkedin_url = data.linkedin_url;
      this.hiringCompany.dealroom_url = data.url;
      this.hiringCompany.website_url = data.website_url;
      this.hiringCompany.company_culture_description = data.company_culture_description;
      this.hiringCompany.dealroom_id = data.id;
      this.hiringCompany.dealroom_data = data;
    }
  }
}
