import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberOnly'
})
export class NumberOnlyPipe implements PipeTransform{

  transform(): any{
    return null;
  }

}
