import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class AddToAnotherJobService{

    constructor(private httpClient: HttpClient){ }

    getJobs(closedJobs): Observable<any>{
        const url: string = closedJobs ? environment.getNavisBackendURL + '/resources/jobs' : environment.getNavisBackendURL + '/resources/jobs?type=open';
        return this.httpClient.get(url);
    }

    getStatuses(job_uuid){
        const url: string = environment.getNavisBackendURL + '/resources/application_statuses?job_uuid=' + job_uuid + '&type=longlist';
        return this.httpClient.get(url);
    }

    getResourcesByType(type){
        const url: string = environment.getNavisBackendURL + '/resources?type=' + type;
        return this.httpClient.get(url);
    }

    getJobArchetypes(job_uuid: string){
        const url: string = environment.getNavisBackendURL + '/resources/job_archetypes?job_uuid=' + job_uuid;
        return this.httpClient.get<any>(url);
    }

    createApplication(body){
        const url: string = environment.getNavisBackendURL + '/merger/candidates/applications';
        return this.httpClient.post<any>(url, body);
    }

    addCandidateToNavis(body){
        const url: string = environment.getNavisBackendURL + '/merger/candidates/navisCandidate';
        return this.httpClient.post<any>(url, body);
    }
}
