import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { environment } from "environments/environment";

@Injectable()
export class GetnavisViewCandidateService{

  constructor(private httpClient: HttpClient){}

  getCandidateProfile(uuid: string): Observable<any>{
    return this.httpClient.get(environment.getNavisBackendURL + '/merger/candidates/' + uuid);
  }

  getCandidateActivities(uuid: string, type: string, candidate_emails: Array<string>): Observable<any>{
    if (type == 'emails'){
      const body = {
        candidate_emails: candidate_emails,
        filters: {}
      };
      return this.httpClient.post(environment.graphAPIURL + '/mongodb/mails/', body);
    } else if (type == 'insights'){
      return this.httpClient.get(environment.getNavisBackendURL + '/candidates/' + uuid + '/candidateInsights');
    } else {
      const body = {
        type: type
      };
      return this.httpClient.post(environment.getNavisBackendURL + '/merger/candidates/activities/' + uuid, body);
    }
  }

  getLocations(parameter: string): Observable<any>{
    const body = {
      "input_text": parameter
    };
    return this.httpClient.post(environment.djangoURL + '/geocoder/autocomplete/', body);
  }

  getResources(value): Observable<any>{
    return this.httpClient.get(environment.getNavisBackendURL + '/resources/' + value);
  }

  getResourcesByType(type): Observable<any>{
    return this.httpClient.get(environment.getNavisBackendURL + '/resources?type=' + type);
  }

  updateCandidate(uuid, body): Observable<any>{
    return this.httpClient.put(environment.getNavisBackendURL + '/merger/candidates/' + uuid, body);
  }

  getJobArchetypes(job_uuid: string){
    const url: string = environment.getNavisBackendURL + '/resources/job_archetypes?job_uuid=' + job_uuid;
    return this.httpClient.get<any>(url);
}

  updateApplication(body: any): Observable<any>{
    return this.httpClient.put(environment.getNavisBackendURL + '/merger/candidates/applications', body);
  }

  getNavis1JobHistory(linkedin_url: string): Observable<any>{
    const body = {
      linkedin_url: linkedin_url
    };

    return this.httpClient.post(environment.getNavisBackendURL + '/navis/candidates/jobHistory', body);
  }

  postContactDetails(body, candidate_uuid){
    return this.httpClient.post(environment.getNavisBackendURL + '/candidates/' + candidate_uuid + '/contacts', body);
  }

  putContactDetails(data, candidate_uuid){
    const body = {
      contact_details: data
    };
    return this.httpClient.put(environment.getNavisBackendURL + '/candidates/' + candidate_uuid + '/contacts', body);
  }

  deleteContactDetails(contact_uuid){
    return this.httpClient.delete(environment.getNavisBackendURL + '/candidates/contacts/' + contact_uuid);
  }

  createExperiences(body, candidate_uuid): Observable<any>{
    return this.httpClient.post(environment.getNavisBackendURL + '/candidates/' + candidate_uuid + '/experiences', body);
  }

  updateExperiences(body): Observable<any>{
    return this.httpClient.put(environment.getNavisBackendURL + '/candidates/experiences', body);
  }

  deleteExperience(uuid): Observable<any>{
    return this.httpClient.delete(environment.getNavisBackendURL + '/candidates/experiences/' + uuid);
  }

  getDealroomCompanies(company: string): Observable<any>{
    const body = {
      'keyword': company,
      'keyword_type': 'default',
      'keyword_match_type': 'exact',
      'fields': 'id,name,path,website_url,url,linkedin_url,images,hq_locations',
      form_data: {
        must: {},
        must_not: {},
        should: {}
      },
      'sort': 'name',
      limit: 25,
      offset: 0
    };

    return this.httpClient.post(environment.getNavisBackendURL + '/dealroom/companies', body).pipe(map((rsp: any) => rsp.items));
  }

  checkContactDetails(candidateUUID: string){
    return this.httpClient.get(`${environment.getNavisBackendURL}/candidates/${candidateUUID}/checkContactDetails`);
  }

  getContactDetails(candidateUUID: string){
    return this.httpClient.get(`${environment.getNavisBackendURL}/candidates/${candidateUUID}/getContactDetails`);
  }
}
