import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from "@angular/router";
import { DataService } from './sidebar.service';
import { UserDataService } from '../service/userData.service';
import { Globals } from '../../globals';
import { CommunicationService } from '../service/communication.service';
import { ToastrService } from "ngx-toastr";
import { takeWhile } from 'rxjs/internal/operators/takeWhile';
declare let $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit, OnDestroy{
  componentAlive = true;

  allJobsCheckbox = false;

  dropdownTypeChanged = false;

  showJobsSubMenu = false;

  selectedJob: any = null;

  jobs: any = [];

  jobDropdownList: any = [];

  constructor(private route: Router, private dataService: DataService, private userData: UserDataService,
    public globals: Globals, private communicationService: CommunicationService, private toastr: ToastrService){
    this.communicationService.onCreatedJob$.pipe(takeWhile(() => this.componentAlive))
      .subscribe((data) => {
        if (data){
          this.selectedJob = data;
          this.emitJobSelected(data);
          this.jobs = [...this.jobs, data];
        }
      });

    this.communicationService.onGetCandidatesComponentJob$.pipe(takeWhile(() => this.componentAlive))
      .subscribe((uuid) => {
        this.getJobs(this.allJobsCheckbox, uuid, true);
      });
  }

  ngOnInit(){
    $.getScript('./assets/js/app-sidebar.js');
    if (this.route.parseUrl(this.route.url).root.children.primary.segments[0].toString() == 'job'){
      this.getJobs(this.allJobsCheckbox, this.route.parseUrl(this.route.url).root.children.primary.segments[1].toString(), false);
    }
    if (this.route.parseUrl(this.route.url).root.children.primary.segments[0].toString() == 'overview' && this.globals.countJobs() === 1 && (this.globals.checkIfExists('role', 'client_member') || this.globals.checkIfExists('role', 'client_basic'))){
      this.getJobs(this.allJobsCheckbox, null, false);
    }
  }

  navigateTo(value?){
    if (value == '/overview'){
      this.selectedJob = null;
      this.showJobsSubMenu = false;
      this.route.navigate([value]);
    } else {
      this.emitJobSelected(this.selectedJob);
      this.route.navigate(['/job/' + this.selectedJob.uuid + (value ? value : '')]);
    }
  }

  onChange(item: any){
    if (item){
      const urlSegments = this.route.parseUrl(this.route.url).root.children.primary.segments;
      let url = '/job/' + item.uuid;
      if (urlSegments[2]){
        url = url + '/' + urlSegments[2].toString();
      }
      this.route.navigate([url]);
      this.emitJobSelected(item);
    } else {
      this.selectedJob = null;
      this.showJobsSubMenu = false;
      this.route.navigate(['/overview']);
    }
  }

  emitJobSelected(job){
    if (job){
      this.showJobsSubMenu = true;
      this.communicationService.emitJobSelected(job);
    } else {
      this.route.navigate(['/overview']);
    }
  }

  getJobs(selectAllJobs, selectJobByUUID, emitToJobsComponents){
    if (!this.jobs.length){
      this.dataService.getJobs('all').subscribe(
        (data) => {
          if (data){
            this.jobs = data;
          }
        },
        (err) => {
          this.toastr.error(err);
        },
        () => {
          this.dropdownTypeChanged = true;
          this.prepareData(selectAllJobs, selectJobByUUID, emitToJobsComponents);
        });
    } else {
      this.prepareData(selectAllJobs, selectJobByUUID, emitToJobsComponents);
    }
  }

  prepareData(selectAllJobs, selectJobByUUID, emitToJobsComponents){
    this.changeDropdownJobType(selectAllJobs);
    if (selectJobByUUID){
      this.selectJobByUUID(selectJobByUUID, emitToJobsComponents);
    }
  }

  changeDropdownJobType(selectAllJobs){
    if (this.dropdownTypeChanged){
      selectAllJobs ? this.jobDropdownList = this.jobs : this.jobDropdownList = this.jobs.filter(item => item.open != selectAllJobs);
      if (this.globals.countJobs() === 1 && (this.globals.checkIfExists('role', 'client_member') || this.globals.checkIfExists('role', 'client_basic'))){
        this.selectedJob = this.jobDropdownList[0];
        this.showJobsSubMenu = true;
        this.emitJobSelected(this.selectedJob);
      }
    }
    this.dropdownTypeChanged = false;
  }

  selectJobByUUID(jobUUID, emitToJobsComponents){
    this.selectedJob = this.jobs.find(item => item.uuid == jobUUID);
    this.emitJobSelected(this.selectedJob);
    if (emitToJobsComponents){
      this.communicationService.setJobEmitter(this.selectedJob);
    }
  }

  populateJobsDropdown(){
    this.dropdownTypeChanged = true;
  }

  ngOnDestroy(){
    this.componentAlive = false;
  }
}
