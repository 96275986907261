import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from 'app/globals';
import { ToastrService } from 'ngx-toastr';
import { DeleteCandidateModalService } from './delete-candidate-modal.service';

@Component({
  selector: 'app-delete-candidate-modal',
  templateUrl: './delete-candidate-modal.component.html',
  styleUrls: ['./delete-candidate-modal.component.scss']
})
export class DeleteCandidateModalComponent implements OnInit{
  data: any = {};

  constructor(private deleteCandidateModalService: DeleteCandidateModalService, public globals: Globals, public activeModal: NgbActiveModal, private toastr: ToastrService){ }

  ngOnInit(){
  }

  deleteCandidate(){
    this.deleteCandidateModalService.deleteCandidate(this.data).subscribe(
      (response) => {
        if (response){
          //dynamic action toastr
          this.toastr[response.tag](response.value + (response.action ? '\n <a target="_blank" href="' + response.action + '">' + response.action + '<a/>' : ''));
          this.activeModal.close(true);
        }
      },
      (err) => {
        if (err.error){
          for (const errProp in err.error){
            if (err.error.hasOwnProperty(errProp) && err.error[errProp][0]){
              this.toastr.error(err.error[errProp]);
            }
          }
        } else {
          this.toastr.error('Something went wrong');
        }
      });

  }

}
