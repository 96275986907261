import { Component, OnInit } from '@angular/core';
import { Globals } from 'app/globals';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as Chartist from 'chartist';
import { ChartType, ChartEvent } from "ng-chartist";
import { CampaignsMetricsModalService } from './campaigns-metrics-modal.service';
import { ToastrService } from 'ngx-toastr';

export interface Chart
{
  type: ChartType;
  data: Chartist.IChartistData;
  options?: any;
  responsiveOptions?: any;
  events?: ChartEvent;
}

@Component({
  selector: 'app-campaigns-metrics-modal',
  templateUrl: './campaigns-metrics-modal.component.html',
  styleUrls: ['./campaigns-metrics-modal.component.scss']
})
export class CampaignsMetricsModalComponent implements OnInit{

  showComponent = false;

  uuid: string;

  metricsData: any = {};

  chart: Chart;

  constructor(public globals: Globals, public activeModal: NgbActiveModal, private campaignMetricsModalService: CampaignsMetricsModalService, private toastr: ToastrService){ }

  ngOnInit(){
    if (!this.uuid){
      this.drawMetrics();
    } else {
      this.getMetrics(this.uuid);
    }
  }

  drawMetrics(){
    if (this.metricsData.total){
      this.showComponent = true;
      const total = this.metricsData.total;
      this.chart = {
        type: 'Pie',
        data: this.metricsData.data,
        options: {
          donut: true,
          startAngle: 0,
          labelInterpolationFnc: function (){
            return total;
          }
        },
        events: {
          draw(data: any): void{
            if (data.type === 'label'){
              if (data.index === 0){
                data.element.attr({
                  dx: data.element.root().width() / 2,
                  dy: data.element.root().height() / 2
                });
              } else {
                data.element.remove();
              }
            }

          }
        }
      };
    }
  }

  getMetrics(jobUUID){
    this.campaignMetricsModalService.getJobMetrics(jobUUID).subscribe(
      (response) => {
        this.metricsData = response;
        this.drawMetrics();
      },
      (err) => {
        this.toastr.error(err);
      });
  }

}
