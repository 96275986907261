import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class AddContactService{

  constructor(private http: HttpClient, private angulartics2: Angulartics2){ }

  getCompany(company): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/companies?name=' + company);
  }

  addCompany(company): Observable<any>{
    this.angulartics2.eventTrack.next({
      action: 'Added job company',
      properties: { company }
    });

    const body = {
      "company_name": company
    };
    return this.http.post(environment.platformBackendURL + '/companies', body);
  }

  createContact(contact): Observable<any>{
    return this.http.post(environment.platformBackendURL + '/contacts', contact);
  }

}
