import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class GetnavisViewConsultantService{

  constructor(private httpClient: HttpClient){ }

  getResources(value, search?): Observable<any>{
    return this.httpClient.get(environment.getNavisBackendURL + '/resources/' + value + (search ? '?search=' + search : ''));
  }

  getConsultant(uuid: string): Observable<any>{
    return this.httpClient.get(environment.getNavisBackendURL + '/merger/consultant/' + uuid);
  }

  updateConsultant(uuid, body): Observable<any>{
    return this.httpClient.put(environment.getNavisBackendURL + '/merger/consultant/' + uuid, body);
  }

  getConsultantCompanies(company?): Observable<any>{

    return this.httpClient.get(environment.getNavisBackendURL + '/resources/consultant_companies' + (company ? '?search=' + company : ''));
  }

}
