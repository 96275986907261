import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class DataService{
    constructor(private http: HttpClient){ }

    getJobs(type: any): Observable<any>{
        const url: string = environment.platformBackendURL + '/jobs?type=' + type;
        return this.http.get(url);
    }

   getContactsPlatform(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/contacts/platform');
  }
}
