<nav class="navbar navbar-expand-lg bg-faded p-0">
  <div class="container-fluid">
    <div class="navbar-header full-width">
      <div class="row">
        <div class="col-12 p-0">
          <div class="input-group">
            <tag-input *ngIf="globals.checkIfExists('permission', 'global_search')" [(ngModel)]="searchTerm"
              (onSelect)="onSelect()" [editable]="true" (onTagEdited)="onTagEdited($event)" (onRemove)="close()"
              class="form-control position-relative ml-4 pl-4" (onAdd)="prepopulateDataForSearch()"
              [ngModelOptions]="{standalone: true}" zIndex='true' theme='minimal' (onRemove)="navbarEditable()"
              [animationDuration]="{enter: '0ms', leave: '0ms'}" secondaryPlaceholder="Start typing to search">
            </tag-input>
            <div *ngIf="loadingFlag" class="input-group-append">
              <div class="input-group-btn">
                <i class="fa fa-repeat tt-icon-lg m-4 p-1" [ngClass]="{'fa-spin':loadingFlag}"></i>
              </div>
            </div>
            <div id="navbar-fullscreen" class="navbar-title pl-4"
              [ngClass]="{'width-70-per': width < 993, 'm-4': width >= 992}"
              *ngIf="!globals.checkIfExists('permission', 'global_search')">
              <button type="button" class="navbar-toggle d-lg-none float-left m-2" data-toggle="collapse">
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
              </button>
              <div *ngIf="title" [ngClass]="{'m-4': width < 993}">
                {{title}}
                <a class="nav-item" ngbDropdown placement="bottom-right" id="settings"  display="dynamic"
                  *ngIf="(globals.checkIfExists('permission', 'delete_job')|| globals.checkIfExists('permission', 'read_activities') || globals.checkIfExists('permission', 'update_job')) && isJobRoute">
                  <a class="nav-link nav-link-menu position-relative" id="settings" ngbDropdownToggle style="display: inline-flex;">
                    <div data-toggle="tooltip" data-placement="bottom" title="Settings">
                    </div>
                  </a>

                </a>
                <span *ngIf="isJobRoute">
                  <i class="fa fa-angle-right" aria-hidden="true"></i>
                  {{jobRouteTitle}}
                </span>
              </div>
            </div>
            <div class="input-group-append"
              [ngClass]="{'position-absolute position-right-0': !globals.checkIfExists('permission', 'global_search'), 'pr-4': width < 993}">
              <span class="input-group-btn">
                <div class="navbar-container pt-4" [ngClass]="{'full-width': width < 993}">
                  <div id="navbarSupportedContent" [ngClass]="{'pull-right': width < 993}">
                    <ul class="navbar-nav">
                      <li class="nav-item mr-2" *ngIf="globals.checkIfExists('group', 'internal')">
                        <a href="https://tomatotalent.atlassian.net/servicedesk/customer/portal/3"
                          target="_blank" id="bug" class="nav-link nav-link-menu" data-toggle="tooltip" data-placement="bottom"
                          [title]="globals.labels.tooltips.shared_page.notion">
                          <i class="tt-icon-lg icon-ttreport"></i>
                        </a>
                      </li>
                      <li class="nav-item mr-2" *ngIf="width >= 993">
                        <a (click)="startTour()" id="tour" class="nav-link nav-link-menu" data-toggle="tooltip"
                          data-placement="bottom" [title]="globals.labels.tooltips.shared_page.tour">
                          <i class="icon-tttour tt-icon-lg"></i>
                        </a>
                      </li>
                      <li class="nav-item mr-4" *ngIf="width < 993">
                        <a class="nav-link nav-link-menu position-relative" id="dropdownBasic2" style="display: inline-flex;"
                          (click)="navigateTo('/notifications')" data-toggle="tooltip" data-placement="bottom"
                          [title]="globals.labels.tooltips.shared_page.notification">
                          <div id="notification">
                            <i class="icon-ttnotifications tt-icon-lg"></i>
                            <span *ngIf="unreadNotification" style="right: -20px"
                              class="notification badge-inverse badge-pill badge-big-search-primary-inverse">{{unreadNotification}}</span>
                          </div>
                        </a>
                      </li>
                      <li *ngIf="width >= 993" class="nav-item" ngbDropdown placement="bottom-right"  display="dynamic">
                        <a class="nav-link nav-link-menu position-relative" id="dropdownBasic2" ngbDropdownToggle
                          style="display: inline-flex;" (click)="getNotification()" data-toggle="tooltip"
                          data-placement="bottom" [title]="globals.labels.tooltips.shared_page.notification">
                          <div id="notification">
                            <i class="icon-ttnotifications tt-icon-lg"></i>
                            <span *ngIf="unreadNotification"
                              class="notification badge-inverse badge-pill badge-big-search-primary-inverse">{{unreadNotification}}</span>
                          </div>
                        </a>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic2" class="notification-dropdown card-shadow">
                          <div class="scroll" style="height: 47vh;">
                            <div *ngFor="let notification of notifications;let i = index">
                              <a class="dropdown-item noti-container py-3 border-bottom border-bottom-   border-bottom-lighten-4"
                                (click)="notificationAction(notification, i)"
                                [ngClass]="{'unread-notification': !notification.is_read}">
                                <span class="media" style="padding: 0.5rem 0rem 0rem 0rem">
                                  <span class="avatar avatar-md avatar-online">
                                    <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                                      [src]="notification.image_url ? notification.image_url : globals.avatar"
                                      (error)="notification.image_url = globals.avatar" />
                                  </span>
                                  <span class="noti-wrapper">
                                    <div *ngIf="notification.title" class="valign-child">
                                      <div class="text-bold-300" style="line-height: 1.5;"
                                        [innerHTML]=notification.title></div>
                                    </div>
                                    <span
                                      class="noti-text">{{notification.created_at | dateDiffNav: notification.created_at}}</span>
                                  </span>
                                </span>
                              </a>
                            </div>
                          </div>
                          <div class="row m-0">
                            <div class="col-6 p-0">
                              <a (click)="navigateTo('/notifications')"
                                class="dropdown-item py-1 secondary-color text-center">
                                {{globals.labels.placeholders.full_layout_page.notification_component.show_all}}
                              </a>
                            </div>
                            <div class="col-6 p-0">
                              <a (click)="readAllNotification()" class="dropdown-item py-1 secondary-color text-center">
                                {{globals.labels.placeholders.full_layout_page.notification_component.read_all}}
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="nav-item" ngbDropdown placement="bottom-right" display="dynamic"
                        *ngIf="globals.checkIfExists('permission', 'create_job') || globals.checkIfExists('permission', 'create_activities')">
                        <a class="nav-link nav-link-menu position-relative" id="dropdownBasic3" ngbDropdownToggle
                          style="display: inline-flex;">
                          <div id="user-icon" data-toggle="tooltip" data-placement="bottom"
                            [title]="globals.labels.tooltips.shared_page.settings">
                            <i class="icon icon-plus"></i>
                          </div>
                        </a>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic3" class="card-shadow notification-dropdown">
                          <a (click)="createCandidate()" class="dropdown-item py-1" href="javascript:;"
                            *ngIf="globals.checkIfExists('permission', 'add_candidates')">
                            <i class="ft-edit-3 mr-2"></i>
                            <span>Add candidate</span>
                          </a>
                          <a (click)="createJob()" class="dropdown-item py-1" href="javascript:;"
                            *ngIf="globals.checkIfExists('permission', 'create_job')">
                            <i class="ft-edit-3 mr-2"></i>
                            <span>Add job</span>
                          </a>
                          <a (click)="createActivity()" class="dropdown-item py-1" href="javascript:;"
                            *ngIf="globals.checkIfExists('permission', 'create_activities')">
                            <i class="ft-edit-3 mr-2"></i>
                            <span>Add activity</span>
                          </a>
                          <a (click)="createRevenue()" class="dropdown-item py-1" href="javascript:;"
                            *ngIf="globals.checkIfExists('permission', 'create_revenue')">
                            <i class="ft-edit-3 mr-2"></i>
                            <span>Add revenue</span>
                          </a>
                          <a (click)="createContact()" class="dropdown-item py-1" href="javascript:;"
                            *ngIf="globals.checkIfExists('permission', 'create_contacts')">
                            <i class="ft-edit-3 mr-2"></i>
                            <span>Add contact</span>
                          </a>
                        </div>
                      </li>
                      <li class="nav-item" ngbDropdown placement="bottom-right"  display="dynamic">
                        <a class="nav-link nav-link-menu position-relative" id="dropdownBasic3" ngbDropdownToggle
                          style="display: inline-flex;">
                          <div id="user-icon" data-toggle="tooltip" data-placement="bottom"
                            [title]="globals.labels.tooltips.shared_page.settings">
                            <img class="media-object align-self-center d-flex height-25 width-25 rounded-circle"
                              [src]="globals.data.contact.photo ? globals.data.contact.photo : globals.avatar"
                              (error)="globals.data.contact.photo = globals.avatar">
                          </div>
                        </a>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic3" class="card-shadow notification-dropdown">
                          <a (click)="navigateTo('/user-profile')" class="dropdown-item py-1" href="javascript:;">
                            <i class="ft-edit-3 mr-2"></i>
                            <span>{{globals.labels.placeholders.shared_page.user_profile_title}}</span>
                          </a>
                          <a (click)="navigateTo('/admin')" class="dropdown-item py-1" href="javascript:;"
                            *ngIf="globals.checkIfExists('permission', 'read_user')">
                            <i class="ft-settings mr-2"></i>
                            <span>Users</span>
                          </a>
                          <a (click)="navigateTo('/admin-resources')" class="dropdown-item py-1" href="javascript:;"
                            *ngIf="globals.checkIfExists('role', 'administrator') || globals.checkIfExists('permission', 'view_admin_resources')">
                            <i class="ft-settings mr-2"></i>
                            <span>Admin resouces</span>
                          </a>
                          <a (click)="navigateTo('/archived-candidates')" class="dropdown-item py-1" href="javascript:;"
                            *ngIf="globals.checkIfExists('permission', 'permanently_delete_candidates')">
                            <i class="ft-list mr-2"></i>
                            <span>Archived Candidates</span>
                          </a>
                          <a (click)="open(content, true)" class="dropdown-item py-1" href="javascript:;">
                            <i class="ft-file-text mr-2"></i>
                            <span>{{globals.labels.placeholders.shared_page.terms_condition_title}}</span>
                          </a>
                          <div class="dropdown-divider"></div>
                          <a (click)="logout()" class="dropdown-item" href="javascript:;">
                            <i class="ft-power mr-2"></i>
                            <span>{{globals.labels.placeholders.shared_page.logout_title}}</span>
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </span>
            </div>
          </div>
          <div *ngIf="!loadingFlag && ngTemplateForSeacrhFlag && globals.checkIfExists('permission', 'global_search')"
            class="row">
            <div [ngClass]="{'top-search-result col-6 ml-5': width >= 1366, 'col-11 ml-4': width < 1366}"
              class="tabset-bg">
              <div *ngIf="ngTemplateForSeacrhFlag">
                <div class="card-block">
                  <nav ngbNav #nav="ngbNav" class="nav-tabs" id="tab-shadow" (navChange)="dynamicType($event)">
                    <ng-container [ngbNavItem]="'ngb-tab-0'">
                      <a ngbNavLink>
                       Candidates<span
                          class="badge-inverse badge-pill badge-big-search-primary-inverse">{{totalCountData.total_candidates}}</span>
                      </a>
                      <ng-template ngbNavContent>
                        <div class="search-results"
                          infinite-scroll
                          [infiniteScrollDistance]="scrollDistance"
                          [infiniteScrollUpDistance]="scrollUpDistance"
                          [infiniteScrollThrottle]="throttle"
                          (scrolled)="onScrollDown()"
                          [scrollWindow]="false">
                          <div class="card-block" *ngFor="let candidate of globalSearchCandidatesNameData.data">
                            <a (click)="openCandidate(candidate.uuid)">
                              <div class="card m-5">
                                <div class="card-block">
                                  <div class="media mt-3">
                                    <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                                      (error)="candidate.photo = globals.avatar"
                                      [src]="candidate.photo ? candidate.photo : (candidate.gender =='FEMALE' ? globals.femaleAvatar : globals.avatar)" />
                                    <div class="media-body">
                                      <div class="row">
                                        <div class="col-12 col-md-4">
                                          <div class="media-body-title" *ngIf="candidate.name" data-toggle="tooltip"
                                            data-placement="bottom" [title]="candidate.name">
                                            <div class="pull-left mr-2">
                                              {{candidate.name}}
                                            </div>
                                            <div *ngIf="candidate.linkedin">
                                              <i target="_blank" (click)="prevent($event, candidate.linkedin)"
                                                class="fa fa-linkedin-square secondary-color tt-icon-lg"></i>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-12 col-md-4">
                                          <div *ngIf="candidate.location; else noLocation" class="media-body-content"
                                            [title]="candidate.location"><span class="icon-ttrelocation">
                                            </span>{{candidate.location && candidate.location.length >= 100 ? (candidate.location.slice(0,100) + '...') : candidate.location}}</div>
                                          <ng-template #noLocation class="media-body-content"><span
                                              class="icon-ttrelocation"> </span>No avalible location.</ng-template>
                                        </div>
                                        <div class="col-12 col-md-4">
                                          <div *ngIf="candidate.email; else noEmail" class="media-body-content"
                                            data-toggle="tooltip" data-placement="bottom" [title]="candidate.email"
                                            [ngClass]="{'half-width': width < 575}">
                                            <span class="icon-ttmail mr-1"> </span>{{candidate.email}}</div>
                                          <ng-template #noEmail class="media-body-content" data-toggle="tooltip"
                                            data-placement="bottom"> <span class="icon-ttmail"> </span>No email for this
                                            candidate. </ng-template>
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col-12 col-md-4">
                                          <div *ngIf="candidate.title; else noTitle" class="media-body-content"
                                            [title]="candidate.title">{{candidate.title}}</div>
                                          <ng-template #noTitle>No title for this candidate.</ng-template>
                                        </div>
                                        <div class="col-12 col-md-4">
                                          <div *ngIf="candidate.company; else noCandidateCompany"
                                            class="media-body-content" [title]="candidate.company"><span
                                              class="icon-ttmapped"> </span>{{candidate.company}}</div>
                                          <ng-template #noCandidateCompany><span class="icon-ttmapped"> </span>No
                                            company avalible.</ng-template>
                                        </div>
                                        <div class="col-12 col-md-4">
                                          <div *ngIf="candidate.phone; else noPhone" class="media-body-content"
                                            [title]="candidate.phone">{{candidate.phone}}</div>
                                          <ng-template #noPhone>No phone for this candidate.</ng-template>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                          <div *ngIf="infiniteScrollIndicatorFlag" class="text-center tabset-bg">
                            <img class="m-3" [src]="loadingURL">
                          </div>
                        </div>
                      </ng-template>
                    </ng-container>
                    <ng-container [ngbNavItem]="'ngb-tab-1'">
                      <a ngbNavLink> Contact <span
                          class="badge-inverse badge-pill badge-big-search-primary-inverse ">{{totalCountData.total_contacts}}</span>
                      </a>
                      <ng-template ngbNavContent>
                        <div class="search-results"
                          infinite-scroll
                          [infiniteScrollDistance]="scrollDistance"
                          [infiniteScrollUpDistance]="scrollUpDistance"
                          [infiniteScrollThrottle]="throttle"
                          (scrolled)="onScrollDown()"
                          [scrollWindow]="false">
                          <div *ngIf="!globalSearchContactData.total" class="text-center mt-5">
                            <div class="media-body-title">
                              No results found.
                            </div>
                          </div>
                          <div class="card-block" *ngFor="let contact of globalSearchContactData.data">
                            <a (click)="openContact(contact.uuid)">
                              <div class="card m-5">
                                <div class="card-block">
                                  <div class="media mt-3">
                                    <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                                      (error)="contact.photo = globals.avatar"
                                      [src]="contact.photo ? contact.photo : (contact.gender =='FEMALE' ? globals.femaleAvatar : globals.avatar)" />
                                    <div class="media-body ">
                                      <div class="row">
                                        <div *ngIf="contact.name" class="col-12 col-md-6 media-body-title"
                                          [title]="contact.name"> {{contact.name}}</div>
                                        <div *ngIf="!contact.name" class="col-12 col-md-6 media-body-content">No name
                                          for this candiadte. </div>

                                        <div class="col-12 col-md-6 media-body-content" *ngIf="contact.company"
                                          [title]=" contact.company"> <span
                                            class="icon-ttmapped mr-1"></span>{{contact.company}}</div>
                                        <div class="col-12 col-md-6 media-body-content" *ngIf="!contact.company"> <span
                                            class="icon-ttmapped"></span>No company for this candidate.</div>
                                      </div>
                                      <div class="row">
                                        <div class="col-12 col-md-6 media-body-content" *ngIf="contact.title"
                                          [title]="contact.title">{{ contact.title}}</div>
                                        <div class="col-12 col-md-6 media-body-content" *ngIf="!contact.title">No
                                          contact title</div>

                                        <div class="col-12 col-md-6 media-body-content" *ngIf="contact.email"
                                          [title]="contact.email"><span class="icon-ttmail mr-1">
                                          </span>{{contact.email}}</div>
                                        <div class="col-12 col-md-6 media-body-content" *ngIf="!contact.email"><span
                                            class="icon-ttmail mr-1"> </span>No email.</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                          <div *ngIf="infiniteScrollIndicatorFlag" class="text-center tabset-bg">
                            <img class="m-3" [src]="loadingURL">
                          </div>
                        </div>
                      </ng-template>
                    </ng-container>
                    <ng-container [ngbNavItem]="'ngb-tab-2'">
                      <a ngbNavLink>
                       Companies <span
                          class="badge-inverse badge-pill badge-big-search-primary-inverse ">{{totalCountData.total_companies}}</span>
                      </a>
                      <ng-template ngbNavContent>
                        <div class="search-results"
                          infinite-scroll
                          [infiniteScrollDistance]="scrollDistance"
                          [infiniteScrollUpDistance]="scrollUpDistance"
                          [infiniteScrollThrottle]="throttle"
                          (scrolled)="onScrollDown()"
                          [scrollWindow]="false">
                          <div *ngIf="!globalSearchCompaniesData.total" class="text-center mt-5">
                            <div class="media-body-title">
                              No results found.
                            </div>
                          </div>
                          <div *ngFor="let company of globalSearchCompaniesData.data">
                            <a (click)="openCompany(company.uuid)">
                              <div class="card m-5">
                                <div class="card-block">
                                  <div class="media mt-3">
                                    <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                                      [src]="company.photo ? company.photo : globals.companyAvatar" />
                                    <div class="media-body">
                                      <div class="row">
                                        <div class="col-md-4">
                                          <div class="media-body-content">
                                            {{company.name}}
                                          </div>
                                        </div>
                                        <div class="col-md-4">
                                          <div class="media-body-content">
                                              <span *ngIf="company.location" class="company-edge-case"  [title]="company.location">
                                              <span class="icon-ttmapped"></span>
                                              {{company.location && company.location.length >= 30 ? (company.location.slice(0,30) + '...') : company.location}}
                                            </span>
                                             <span *ngIf="!company.location">
                                              <span class="icon-ttmapped mr-1"></span>No avalible location.
                                            </span>
                                          </div>
                                        </div>
                                        <div class=" col-md-4">
                                          <div class="media-body-content">
                                            <span *ngIf="company.jobs_count != 0"
                                            [title]="company.jobs_count"><b>Jobs: </b> {{company.jobs_count}}</span>
                                          <span *ngIf="company.jobs_count == 0">
                                            No jobs for {{company.name}}</span>
                                          </div>
                                        </div>
                                        <div class=" col-md-4">
                                          <div class="media-body-content">
                                            <span *ngIf="company.website"
                                            [title]="company.website"><span
                                              class="icon-ttglobe"></span>{{company.website}}</span>
                                              <span *ngIf="!company.website"><span
                                                class="icon-ttglobe"></span>No avalible web site.</span>
                                          </div>
                                        </div>
                                        <div class=" col-md-4">
                                          <div class="media-body-content">
                                            <span *ngIf="company.phone"
                                            [title]="company.phone"><span class="icon-ttphone-1"></span>{{company.phone}}
                                          </span>
                                          <span *ngIf="!company.phone"><span
                                              class="icon-ttphone-1"></span> No avalible phone.</span>
                                          </div>
                                        </div>
                                        <div class=" col-md-4">
                                          <div class="media-body-content">
                                          <span *ngIf="company.no_of_contacts != 0"
                                           [title]="company.no_of_contacts">
                                          <b>Contact: </b>{{company.no_of_contacts}}</span>
                                          <span *ngIf="company.no_of_contacts == 0">No contacts for {{company.name}}
                                          </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                          <div *ngIf="infiniteScrollIndicatorFlag" class="text-center tabset-bg">
                            <img class="m-3" [src]="loadingURL">
                          </div>
                        </div>
                      </ng-template>
                    </ng-container>
                    <ng-container [ngbNavItem]="'ngb-tab-3'">
                      <a ngbNavLink>
                       Jobs <span
                          class="badge-inverse badge-pill badge-big-search-primary-inverse ">{{totalCountData.total_jobs}}</span>
                      </a>
                      <ng-template ngbNavContent>
                        <div class="search-results"
                          infinite-scroll
                          [infiniteScrollDistance]="scrollDistance"
                          [infiniteScrollUpDistance]="scrollUpDistance"
                          [infiniteScrollThrottle]="throttle"
                          (scrolled)="onScrollDown()"
                          [scrollWindow]="false">
                          <div *ngIf="!globalSearchJobsData.total" class="text-center mt-5">
                            <div class="media-body-title">
                              No results found.
                            </div>
                          </div>
                          <div *ngFor="let job of globalSearchJobsData.data">
                            <a (click)="openJob(job.uuid)">
                              <div class="card m-5">
                                <div class="card-block">
                                  <div class="media mt-3">
                                    <div class="media-body">
                                      <div class="row">
                                        <div class="col-12 col-md-4 media-body-title"><span
                                            class="tt-icon-sm icon-ttcareer mr-2"></span>{{job.title}}</div>
                                        <div *ngIf="job.status" class="col-12 col-md-4 media-body-content"
                                          [title]="job.status"><b>Status: </b><span
                                            [ngClass]="{'success-color': job.status == 'Active','error-color': job.status == 'On hold'}">{{job.status}}</span>
                                        </div>
                                        <div *ngIf="!job.status" class="col-12 col-md-4 media-body-content"
                                          [title]="job.status"><b>Status: </b><span
                                            [ngClass]="{'success-color': job.status == 'Active','error-color': job.status == 'On hold'}">no
                                            status.</span></div>
                                        <div *ngIf="job.contact" class="col-12 col-md-4 media-body-content"
                                          [title]="job.contact"><b>Contact: </b> {{job.contact}}</div>
                                        <div *ngIf="!job.contact" class="col-12 col-md-4 media-body-content">No contact.
                                        </div>
                                      </div>
                                      <br>
                                      <div class="row">
                                        <div *ngIf="job.pod" class="col-12 col-md-4 media-body-content"
                                          [title]="job.pod">
                                          <span class="the-icons span3 mr-2" title="Code: 0xe86a"><i
                                              class="demo-icon icon-ttpod"></i><span
                                              class="i-code"></span></span>{{job.pod}}</div>
                                        <div *ngIf="!job.pod" class="col-12 col-md-4 media-body-content"> <span
                                            class="the-icons span3 mr-2" title="Code: 0xe86a"><i
                                              class="demo-icon icon-ttpod"></i><span class="i-code"></span></span>No
                                          pod.</div>
                                        <div *ngIf="job.location" class="col-12 col-md-4 media-body-content"
                                          [title]="job.location"><span class="icon-ttmapped"></span>{{job.location && job.location.length >= 100 ? (job.location.slice(0,100) + '...') : job.location}}
                                        </div>
                                        <div *ngIf="!job.location" class="col-12 col-md-4 media-body-content"><span
                                            class="icon-ttmapped"></span> No avalible location.</div>

                                        <div *ngIf="job.company" class="col-12 col-md-4">Company: {{job.company}}</div>
                                        <div *ngIf="!job.company" class="col-12 col-md-4">No company for this job.</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                          <div *ngIf="infiniteScrollIndicatorFlag" class="text-center tabset-bg">
                            <img class="m-3" [src]="loadingURL">
                          </div>
                        </div>
                      </ng-template>
                    </ng-container>
                  </nav>
                  <div [ngbNavOutlet]="nav" class="mt-2"></div>
                </div>
              </div>
            </div>
            <div class="overlay-excluded-top-menu" (click)="close()">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</nav>
<nav class="navbar navbar-expand-lg bg-faded z-3" *ngIf="globals.checkIfExists('permission', 'global_search')">
  <div class="display-inline-flex">
    <button type="button" class="navbar-toggle d-lg-none float-left mt-3" data-toggle="collapse">
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </button>
    <div class="navbar-header">
      <p id="navbar-fullscreen" class="navbar-title mt-3 mb-0 " *ngIf="title" [ngClass]="{'ml-4 pl-3': width >= 992}">
        <span class="cursor-pointer mr-2" title="Click to open job." (click)="openJobDrawer()"
          *ngIf="isJobRoute"><u>{{title}}</u></span>
        <span *ngIf="!isJobRoute">{{title}}</span>
        <a class="nav-item" ngbDropdown placement="bottom-right" id="settings"  display="dynamic"
          *ngIf="(globals.checkIfExists('permission', 'delete_job')|| globals.checkIfExists('permission', 'read_activities') || globals.checkIfExists('permission', 'update_job')) && isJobRoute">
          <a class="nav-link nav-link-menu position-relative" id="settings" ngbDropdownToggle style="display: inline-flex;">
            <div data-toggle="tooltip" data-placement="bottom" title="Settings">
            </div>
          </a>
          <div ngbDropdownMenu aria-labelledby="settings" class="card-shadow">
            <a (click)="closeJob()" class="dropdown-item py-1" href="javascript:;"
              *ngIf="globals.checkIfExists('permission', 'delete_job')">
              <i class="ft-edit-3 mr-2"></i>
              <span>Close job</span>
            </a>
          </div>
        </a>
        <span *ngIf="isJobRoute">
          <i class="fa fa-angle-right" aria-hidden="true"></i>
          {{jobRouteTitle}}
        </span>
      </p>
    </div>
  </div>
</nav>
<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-content">
    <div class="modal-bg">
      <div class="modal-header">
        <div class="modal-header-title">
          {{globals.labels.placeholders.global.terms_and_conditions.title}}
        </div>
        <button type="button" class="close pull-right" aria-label="Close" (click)="d('Close click')">
          <span aria-hidden="true" [innerHTML]="globals.labels.placeholders.global.buttons.close_x"
            data-toggle="tooltip" data-placement="bottom" [title]="globals.labels.tooltips.global.close"></span>
        </button>
      </div>
      <div class="modal-body text-justify">
        <div class="col-12">
          <div [innerHTML]="globals.labels.placeholders.global.terms_and_conditions.description"></div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="col-md-3 btn btn-block btn-raised bg-secondary-color"
          (click)="c('Close click')">{{globals.labels.placeholders.global.buttons.close}}</button>
      </div>
    </div>
  </div>
</ng-template>
