<div class="modal-content">
    <div class="modal-bg">
      <div class="modal-header">
        <div class="modal-header-title">
          Update application
        </div>
        <button type="button" class="close pull-right" aria-label="Close" (click)="activeModal.dismiss()">
          <span aria-hidden="true" [innerHTML]="globals.labels.placeholders.global.buttons.close_x" data-toggle="tooltip"
            data-placement="bottom" [title]="globals.labels.tooltips.global.close"></span>
        </button>
      </div>


      <div class="modal-body">
        <div class="row mb-2">
         <div class="col-12"  *ngIf="inProcess && longlistValid">
          <span class="badge-tags-inverse  align-self-center bg-warning-color">
            This candidate is already <strong>in processs</strong> , please check availability before continuing
          </span>
          </div>
        </div>
        <div class="row">
          <div class="col-6" *ngIf="applicationData.stage">
            <div class="form-group">
              <div class="modal-body-title">
                <label>
                  Stage
                </label>
              </div>
              <div class="modal-body-content">
                <ng-select bindLabel="description" dropdownPosition="bottom" placeholder="Select job stage" [items]="stages" [clearable]="false"
                  [(ngModel)]="applicationData.stage" [ngModelOptions]="{standalone: true}" (open)="getStages()" (ngModelChange)="changeStage()">
                </ng-select>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <div class="modal-body-title">
                <label>
                    Substatus
                    <span *ngIf="!valid && !applicationData.substatus"
                      [ngClass]="{'primary-color': !applicationData.substatus }">
                      {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                    </span>
                </label>
              </div>
              <div class="modal-body-content">
                <ng-select bindLabel="description" dropdownPosition="bottom"
                  [placeholder]="globals.labels.placeholders.inputs.full_layout_page.omni_component.job.sub_status"[items]="subStatuses"
                  [(ngModel)]="applicationData.substatus" [ngModelOptions]="{standalone: true}" (ngModelChange)="changeSubstatus()">
                </ng-select>
              </div>
            </div>
          </div>

        </div>
        <div class="row" *ngIf="showRejectReason">
          <div class="col-6" >
            <div class="form-group">
              <div class="modal-body-title">
                <label>
                    Reject reason
                    <span *ngIf="!valid && !reject_reason.reason"
                      [ngClass]="{'primary-color': !reject_reason.reason }">
                      {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                    </span>
                </label>
              </div>
              <div class="modal-body-content">
                <ng-select bindLabel="description" dropdownPosition="bottom"
                  placeholder="Select reject reason" (open)="getRejectReasons()"
                  [items]="rejectReasons" [(ngModel)]="reject_reason.reason"
                  [ngModelOptions]="{standalone: true}" (ngModelChange)="changeRejectReason()">
                </ng-select>
              </div>
            </div>
          </div>
          <div class="col-6 mt-5" *ngIf="globals.checkIfExists('permission', 'edit_candidates')">
            <div class="custom-control custom-checkbox" *ngIf="globals.checkIfExists('permission', 'edit_candidates')">
              <input class="custom-control-input" id="recyclingCandidates" type="checkbox" [(ngModel)]="recycleCandidate" [ngModelOptions]="{standalone: true}">
              <label class="custom-control-label" for="recyclingCandidates">Recycle candidate</label>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="reject_reason.reason && reject_reason.reason.value == 'other'">
          <div class="col-12">
            <div class="form-group" >
              <div class="modal-body-title">
                <label>
                    Reason
                    <span *ngIf="!valid && !reject_reason.description"
                      [ngClass]="{'primary-color': !reject_reason.description }">
                      {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                    </span>
                </label>
              </div>
              <div class="modal-body-content scroll">
                <textarea type="text" placeholder="Enter reject reason" [(ngModel)]="reject_reason.description"
                [ngModelOptions]="{standalone: true}" class="form-control"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="candidate_rating !== undefined">
          <div class="col-12">
              <div class="modal-body-title">
                <label> Candidate rating </label>
              </div>
              <div class="modal-body-content">
                <ng-select bindLabel="description" dropdownPosition="bottom"
                  placeholder="Select candidate rating"
                  [items]="resources.candidate_application_rating" [(ngModel)]="candidate_rating"
                  [ngModelOptions]="{standalone: true}" (open)="getResourcesByType('candidate_application_rating')"
                  (clear)="candidate_rating = null">
                </ng-select>
              </div>
          </div>
        </div>
        <div class="row" *ngIf="applicationData.stage && applicationData.stage.value == 'longlist'">
          <div class="col-12">
            <div class="form-group">
              <div class="modal-body-title">
                <label>
                    Comment
                </label>
              </div>
              <div class="modal-body-content scroll">
                <textarea type="text" placeholder="Enter longlist comment" [(ngModel)]="comment"
                [ngModelOptions]="{standalone: true}" class="form-control"></textarea>
              </div>
            </div>
          </div>
        </div>
      <div class="modal-footer">
        <button type="button" class="btn pull-right bg-secondary-color" (click)="updateApplication()">
         Update application</button>
        <button type="button" class="btn pull-right bg-primary-color"
          (click)="activeModal.dismiss()">{{globals.labels.placeholders.global.buttons.close}}</button>
      </div>
    </div>
</div>