<div class="card mt-5 ">
  <div class="card-block">
    <div class="card-body">
      <div class="form">
        <div class="form-section">
          <label>{{globals.labels.placeholders.shared_page.add_activity.insert_activity}}</label>
        </div>
        <div class="form-group">
          <div class="card-body-title-basic">
            <label>{{globals.labels.placeholders.shared_page.add_activity.activity_type}}</label>
          </div>
          <div class="card-body-content">
            <ng-select bindLabel="description" dropdownPosition="bottom"
              [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.activity_type"
              [items]="activityTypes" [(ngModel)]="type" [ngModelOptions]="{standalone: true}" (clear)="clearAll()"
              (change)="clearAll()">
            </ng-select>
          </div>
        </div>
        <div class="form-group">
          <div class="scroll" *ngIf="type && type.value=='interview'">
            <div class="form-section">
              <label>{{globals.labels.placeholders.shared_page.add_activity.interview_details}}</label>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                  {{globals.labels.placeholders.shared_page.add_activity.job}}
                  <span *ngIf="!validForm && !choosenJob" [ngClass]="{'primary-color': !choosenJob}">
                    {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                  </span>
                </label>
              </div>
              <div class="card-body-content">
                <ng-select [clearable]="false" [items]="jobs$ | async" bindValue="uuid" bindLabel="job_title"
                  [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.select_job"
                  dropdownPosition="bottom" [minTermLength]="minLengthTerm" [loading]="jobLoading"
                  typeToSearchText="Please enter {{minLengthTerm}} or more characters" [typeahead]="jobInput$"
                  [(ngModel)]="choosenJob" [ngModelOptions]="{standalone: true}" (clear)="clearJob()"
                  (change)="onSelectJob()">
                </ng-select>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                  {{globals.labels.placeholders.shared_page.add_activity.candidate}}
                  <span *ngIf="!validForm && !selectedCandidate[0]"
                    [ngClass]="{'primary-color': !selectedCandidate[0]}">
                    {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                  </span>
                </label>
              </div>
              <div class="card-body-content">
                <ng-select [clearable]="false" [items]="candidates$ | async" bindValue="uuid" bindLabel="name"
                  [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.select_candidate"
                  dropdownPosition="bottom" [minTermLength]="minLengthTerm" [loading]="candidateLoading"
                  typeToSearchText="Please enter {{minLengthTerm}} or more characters" [typeahead]="candidateInput$"
                  [(ngModel)]="selectedCandidate[0]" [disabled]="!choosenJob" [ngModelOptions]="{standalone: true}"
                  (change)="onSelectCandidate($event)">
                  <ng-template ng-option-tmp let-item="item" let-index="i">
                    <div class="media mb-1">
                      <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                        [src]="item.photo ? item.photo : globals.avatar" (error)="item.photo = globals.avatar" />
                      <div class="media-body">
                        <div class="media-body-title mt-1 mb-0">{{item.name}}</div>
                        <p *ngIf="item.current_job_title">
                          <i class="tt-icon-sm icon-ttcareer"></i>
                          {{item.current_job_title || item.current_employer ? item.current_job_title + '@ ' +
                          item.current_employer : 'No job title available'}}
                        </p>
                      </div>
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-content">
                <a class="pull-right" (click)="addNewScorecard()">
                  <i class="icon icon-plus"></i>
                  {{globals.labels.placeholders.shared_page.add_activity.new_scorecard}}
                </a>
              </div>
              <div class="card-body-title-basic">
                <label>{{globals.labels.placeholders.shared_page.add_activity.scorecards}}</label>
              </div>
              <div class="card-body-content">
                <div class="form-group">
                  <div class="row pt-3" *ngFor="let scorecard of interview.scorecards; let i = index">
                    <div class="col-md-5">
                      <input class="form-control" type="text" value={{scorecard.skill}} [(ngModel)]='scorecard.skill'
                        [ngModelOptions]="{standalone: true}" (focusout)="editScorecard('skill', i)" appOnFocusOut
                        [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.scorecard_name">
                    </div>
                    <div class="col-md-5">
                      <input class="form-control text-color-secondary" type="text" value={{scorecard.description}}
                        maxlength="512" [(ngModel)]='scorecard.description' [ngModelOptions]="{standalone: true}"
                        (focusout)="editScorecard('description', i)" appOnFocusOut
                        [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.scorecard_description">
                    </div>
                    <div class="col-md-2">
                      <a (click)="deleteScorecard(i)">
                        <i class="tt-icon-sm icon-ttdelete pull-right m-2" data-toggle="tooltip" data-placement="bottom"
                          [title]="globals.labels.tooltips.global.delete">
                        </i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>{{globals.labels.placeholders.shared_page.add_activity.career_overview}}</label>
              </div>
              <div class="card-body-content">
                <quill-editor [(ngModel)]="interview.career_overview"
                  [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.career_overview">
                </quill-editor>
              </div>
            </div>
            <div class="form-group">
              <div class="form-section">
                <label>{{globals.labels.placeholders.shared_page.add_activity.company_info}}</label>
              </div>
              <div class="card-body">
                <div class="form-group">
                  <div class="card-body-title-basic">
                    <label>{{globals.labels.placeholders.shared_page.add_activity.reports_to}}</label>
                  </div>
                  <div class="card-body-content">
                    <textarea class="form-control text-color-secondary" appAutosize type="text" rows="2"
                      [(ngModel)]="interview.company_info.reports_to"
                      [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.reports_to">
                         </textarea>
                  </div>
                </div>
                <div class="form-group">
                  <div class="card-body-title-basic">
                    <label>{{globals.labels.placeholders.shared_page.add_activity.team_size}}</label>
                  </div>
                  <div class="card-body-content">
                    <textarea class="form-control text-color-secondary" appAutosize type="text" rows="2"
                      [(ngModel)]="interview.company_info.team_size" [ngModelOptions]="{standalone: true}"
                      [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.team_size">
                         </textarea>
                  </div>
                </div>
                <div class="form-group">
                  <div class="card-body-title-basic">
                    <label>{{globals.labels.placeholders.shared_page.add_activity.revenue}}</label>
                  </div>
                  <div class="card-body-content">
                    <textarea class="form-control text-color-secondary" appAutosize type="text" rows="2"
                      [(ngModel)]="interview.company_info.revenue" [ngModelOptions]="{standalone: true}"
                      [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.revenue">
                         </textarea>
                  </div>
                </div>
                <div class="form-group">
                  <div class="card-body-title-basic">
                    <label>{{globals.labels.placeholders.shared_page.add_activity.growth}}</label>
                  </div>
                  <div class="card-body-content">
                    <textarea class="form-control text-color-secondary" appAutosize type="text" rows="2"
                      [(ngModel)]="interview.company_info.growth" [ngModelOptions]="{standalone: true}"
                      [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.growth">
                         </textarea>
                  </div>
                </div>
                <div class="form-group">
                  <div class="card-body-title-basic">
                    <label>{{globals.labels.placeholders.shared_page.add_activity.funding}}</label>
                  </div>
                  <div class="card-body-content">
                    <textarea class="form-control text-color-secondary" appAutosize type="text" rows="2"
                      [(ngModel)]="interview.company_info.funding" [ngModelOptions]="{standalone: true}"
                      [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.funding">
                         </textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>{{globals.labels.placeholders.shared_page.add_activity.relocation}}</label>
              </div>
              <div class="card-body-content">
                <textarea class="form-control text-color-secondary" appAutosize type="text" rows="2"
                  [(ngModel)]="interview.relocation" [ngModelOptions]="{standalone: true}"
                  [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.relocation">
                     </textarea>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>{{globals.labels.placeholders.shared_page.add_activity.compensation}}</label>
              </div>
              <div class="row card-block">
                <div class="col-md-6 col-12">
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label>
                        Base
                      </label>
                    </div>
                    <div class="card-body-content">
                      <input type="text" class="form-control" placeholder="Insert base salary"
                        [(ngModel)]="interview.compensation.base_salary" [ngModelOptions]="{standalone: true}">
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label>
                        Currency
                      </label>
                    </div>
                    <div class="card-body-content">
                      <ng-select bindLabel="description" dropdownPosition="bottom"
                        [placeholder]="globals.labels.placeholders.inputs.full_layout_page.cit_component.next_steps.final_steps.currency"
                        [items]="currencies" (open)="currencies.length ? '': getResources()"
                        [(ngModel)]="interview.compensation.currency" [ngModelOptions]="{standalone: true}"></ng-select>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label>
                        Bonus
                      </label>
                    </div>
                    <div class="card-body-content">
                      <input type="text" class="form-control" placeholder="Insert bonus"
                        [(ngModel)]="interview.compensation.bonus_salary">
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label>
                        Bonus type
                      </label>
                    </div>
                    <div class="card-body-content">
                      <ng-select bindLabel="description" dropdownPosition="bottom"
                        [placeholder]="globals.labels.placeholders.inputs.full_layout_page.cit_component.next_steps.final_steps.currency"
                        [items]="bonus" (open)="bonus.length ? '': getResources()"
                        [(ngModel)]="interview.compensation.bonus_type" [ngModelOptions]="{standalone: true}">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label>
                        Equity
                      </label>
                    </div>
                    <div class="card-body-content">
                      <textarea appAutosize [(ngModel)]="interview.compensation.equity"
                        [ngModelOptions]="{standalone: true}" class="form-control" type="text"
                        [placeholder]="globals.labels.placeholders.inputs.full_layout_page.cit_component.next_steps.final_steps.equity"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>{{globals.labels.placeholders.shared_page.add_activity.notice_period}}</label>
              </div>
              <div class="card-body-content">
                <textarea class="form-control text-color-secondary" appAutosize type="text" rows="2"
                  [(ngModel)]="interview.notice_period" [ngModelOptions]="{standalone: true}"
                  [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.notice_period">
                     </textarea>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                  {{globals.labels.placeholders.shared_page.add_activity.next_step}}
                  <span *ngIf="interview.next_step">
                    <span *ngIf="interview.next_step.value!=='move_forward';else other_content"> (Substatus will be
                      changed to {{interview.next_step.description}})</span>
                    <ng-template #other_content>
                      (Stage will be changed to Second interview and substatus to
                      <span *ngIf="interview.introduce_to_client;else other_substatus"> Introduced to client</span>
                      <ng-template #other_substatus> Considering</ng-template> )
                    </ng-template>
                  </span>

                  <span *ngIf="!validForm && !interview.next_step" [ngClass]="{'primary-color': !interview.next_step}">
                    {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                  </span>
                </label>
              </div>
              <div class="card-body-content">
                <ng-select bindLabel="description" dropdownPosition="bottom"
                  [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.next_step"
                  [clearable]="false" [items]="nextSteps" [(ngModel)]="interview.next_step"
                  [ngModelOptions]="{standalone: true}" (clear)="clearNextSteps()"
                  (click)="checkReject(interview.next_step)">
                </ng-select>
                <div *ngIf=" globals.checkIfExists('permission', 'edit_candidates') && checkRejectFlag" class="mt-4">
                  <div class="custom-control custom-checkbox">
                    <input class="custom-control-input" id="recyclingCandidates" type="checkbox"
                      [(ngModel)]="interview.recycling_candidate" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" for="recyclingCandidates">Recycle candidate</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group" *ngIf="interview.next_step && interview.next_step.value == 'move_forward'">
              <div class="card-body-content">
                <div class="custom-control custom-checkbox m-0">
                  <input class="custom-control-input" id="introduce_to_client" type="checkbox"
                    [(ngModel)]="interview.introduce_to_client" [ngModelOptions]="{standalone: true}">
                  <label class="custom-control-label cursor-pointer"
                    for="introduce_to_client">{{globals.labels.placeholders.shared_page.add_activity.introduce_to_client}}</label>
                </div>
              </div>
            </div>
            <div class="form-group"
              *ngIf="interview.next_step && (interview.next_step.value == 'reject_by_tt' || interview.next_step.value == 'reject_by_candidate')">
              <div class="card-body-title-basic">
                <label>{{globals.labels.placeholders.shared_page.add_activity.rejected_reason}}</label>
              </div>
              <div class="card-body-content">
                <textarea class="form-control text-color-secondary" appAutosize type="text" rows="2"
                  [(ngModel)]="interview.rejected_reason" [ngModelOptions]="{standalone: true}"
                  [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.rejected_reason">
                     </textarea>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>{{globals.labels.placeholders.shared_page.add_activity.recommend}}</label>
              </div>
              <div class="card-body-content">
                <textarea class="form-control text-color-secondary" appAutosize type="text" rows="2"
                  [(ngModel)]="interview.recommend" [ngModelOptions]="{standalone: true}"
                  [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.recommend">
                     </textarea>
              </div>
            </div>
          </div>
          <div class="scroll" *ngIf="type && type.value=='general_pitch'">
            <div class="form-group">
              <div class="form-section">
                <label>{{globals.labels.placeholders.shared_page.add_activity.general_pitch}}</label>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                  {{globals.labels.placeholders.shared_page.add_activity.company}}
                  <span *ngIf="!validForm && (!selectedCompany ||  !selectedCompany.uuid)"
                    [ngClass]="{'primary-color': (!selectedCompany || !selectedCompany.uuid)}">
                    {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                  </span>
                </label>
              </div>
              <div class="card-body-content">
                <ng-select [clearable]="false" [items]="companies$ | async" bindLabel="name"
                  [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.select_company"
                  dropdownPosition="bottom" [minTermLength]="minLengthTerm" [loading]="companyLoading"
                  typeToSearchText="Please enter {{minLengthTerm}} or more characters" [typeahead]="companyInput$"
                  [(ngModel)]="selectedCompany" [ngModelOptions]="{standalone: true}" [hideSelected]="true"
                  [addTag]="addTagCompany" (clear)="clearCompany()">
                  <ng-template ng-option-tmp let-item="item" let-index="i">
                    <div class="media mb-1">
                      <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                        [src]="item.photo ? item.photo : globals.companyAvatar"
                        (error)="item.photo = globals.companyAvatar" />
                      <div class="media-body">
                        <div class="media-body-title mt-1 mb-0">{{item.name}}</div>
                        <p>
                          <i class="tt-icon-sm icon-ttrelocation"></i>{{item.location ? item.location : 'No location
                          available'}}
                        </p>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template ng-tag-tmp let-search="searchTerm">
                    <button class="btn bg-secondary-color btn-position pull-right mb-0">
                      {{globals.labels.placeholders.shared_page.add_activity.add_company}} {{search}}
                    </button>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                  {{globals.labels.placeholders.shared_page.add_activity.contacts}}
                  <span *ngIf="!validForm && !selectedContacts[0]" [ngClass]="{'primary-color': !selectedContacts[0]}">
                    {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                  </span>
                </label>
              </div>
              <div class="card-body-content">
                <ng-select [clearable]="false" [multiple]="true" [items]="contacts$ | async" bindValue="uuid"
                  bindLabel="name"
                  [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.select_contacts"
                  dropdownPosition="bottom" [minTermLength]="minLengthTerm" [loading]="contactLoading"
                  typeToSearchText="Please enter {{minLengthTerm}} or more characters" [typeahead]="contactInput$"
                  [(ngModel)]="selectedContacts" [ngModelOptions]="{standalone: true}" [addTag]="addTagContact"
                  [disabled]="!selectedCompany || !selectedCompany.uuid" (change)="clearInput()">
                  <ng-template ng-option-tmp let-item="item" let-index="i">
                    <div class="media mb-1">
                      <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                        [src]="item.photo ? item.photo : globals.avatar" (error)="item.photo = globals.avatar" />
                      <div class="media-body">
                        <div class="media-body-title mt-1 mb-0">{{item.name}}</div>
                        <p>
                          <i class="tt-icon-sm icon-ttmail email-icon mr-1"></i>{{item.email ? item.email : 'No email
                          available'}}<br>
                          <i class="tt-icon-sm icon-ttcareer"></i>{{item.job_title ? item.job_title : 'No job title
                          available'}}
                        </p>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template ng-tag-tmp let-search="searchTerm">
                    <div class="form-group">
                      <input class="form-control" type="text" placeholder="Insert email" (click)="prevent($event)"
                        [(ngModel)]="email" />
                    </div>
                    <button class="btn bg-secondary-color btn-position pull-right mb-0">
                      {{globals.labels.placeholders.shared_page.add_activity.add_contact}} {{search}}
                      {{globals.labels.placeholders.shared_page.add_activity.at}} {{selectedCompany.name}}
                    </button>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>{{globals.labels.placeholders.shared_page.add_activity.jobs}}</label>
              </div>
              <div class="card-body-content">
                <ng-select [multiple]="true" [items]="companyJobs$ | async" bindValue="uuid" bindLabel="job_title"
                  [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.select_jobs"
                  dropdownPosition="bottom" [minTermLength]="minLengthTerm" [loading]="companyJobLoading"
                  typeToSearchText="Please enter {{minLengthTerm}} or more characters" [typeahead]="companyJobInput$"
                  [(ngModel)]="selectedJob" [ngModelOptions]="{standalone: true}"
                  [disabled]="!selectedCompany || !selectedCompany.uuid">
                </ng-select>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>{{globals.labels.placeholders.shared_page.add_activity.general_pitch}}</label>
              </div>
              <div class="card-body-content">
                <quill-editor id="quill-editor2" [(ngModel)]="general_pitch.call_summary"
                  [ngModelOptions]="{standalone: true}"
                  [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.call_summary">
                </quill-editor>
              </div>
            </div>
          </div>
          <div class="scroll" *ngIf="type && type.value=='role_specific_pitch'">
            <div class="form-group">
              <div class="form-section">
                <label>{{globals.labels.placeholders.shared_page.add_activity.role_specific_pitch}}</label>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                  {{globals.labels.placeholders.shared_page.add_activity.company}}
                  <span *ngIf="!validForm && (!selectedCompany ||  !selectedCompany.uuid)"
                    [ngClass]="{'primary-color': (!selectedCompany || !selectedCompany.uuid)}">
                    {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                  </span>
                </label>
              </div>
              <div class="card-body-content">
                <ng-select [clearable]="false" [hideSelected]="true" [items]="companies$ | async" bindLabel="name"
                  [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.select_company"
                  dropdownPosition="bottom" [minTermLength]="minLengthTerm" [loading]="companyLoading"
                  typeToSearchText="Please enter {{minLengthTerm}} or more characters" [typeahead]="companyInput$"
                  [(ngModel)]="selectedCompany" [ngModelOptions]="{standalone: true}" (clear)="clearCompany()"
                  [addTag]="addTagCompany">
                  <ng-template ng-option-tmp let-item="item" let-index="i">
                    <div class="media mb-1">
                      <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                        [src]="item.photo ? item.photo : globals.companyAvatar"
                        (error)="item.photo = globals.companyAvatar" />
                      <div class="media-body">
                        <div class="media-body-title mt-1 mb-0">{{item.name}}</div>
                        <p>
                          <i class="tt-icon-sm icon-ttrelocation"></i>{{item.location ? item.location : 'No location
                          available'}}
                        </p>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template ng-tag-tmp let-search="searchTerm">
                    <button class="btn bg-secondary-color btn-position pull-right mb-0">
                      {{globals.labels.placeholders.shared_page.add_activity.add_company}} {{search}}
                    </button>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>
                  {{globals.labels.placeholders.shared_page.add_activity.contacts}}
                </label>
              </div>
              <div class="card-body-content">
                <ng-select [clearable]="false" [multiple]="true" [items]="contacts$ | async" bindValue="uuid"
                  bindLabel="name"
                  [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.select_contacts"
                  dropdownPosition="bottom" [minTermLength]="minLengthTerm" [loading]="contactLoading"
                  typeToSearchText="Please enter {{minLengthTerm}} or more characters" [typeahead]="contactInput$"
                  [(ngModel)]="selectedContacts" [ngModelOptions]="{standalone: true}" [addTag]="addTagContact"
                  (change)="clearInput()">
                  <ng-template ng-option-tmp let-item="item" let-index="i">
                    <div class="media mb-1">
                      <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                        [src]="item.photo ? item.photo : globals.avatar" (error)="item.photo = globals.avatar" />
                      <div class="media-body">
                        <div class="media-body-title mt-1 mb-0">{{item.name}}</div>
                        <p>
                          <i class="tt-icon-sm icon-ttmail email-icon mr-1"></i>{{item.email ? item.email : 'No email
                          available'}}<br>
                          <i class="tt-icon-sm icon-ttcareer"></i>{{item.job_title ? item.job_title : 'No job title
                          available'}}
                        </p>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template ng-tag-tmp let-search="searchTerm">
                    <div class="form-group">
                      <input class="form-control" type="text" placeholder="Insert email" (click)="prevent($event)"
                        [(ngModel)]="email" />
                    </div>
                    <button class="btn bg-secondary-color btn-position pull-right mb-0" *ngIf="selectedCompany">
                      {{globals.labels.placeholders.shared_page.add_activity.add_contact}} {{search}}
                      {{globals.labels.placeholders.shared_page.add_activity.at}} {{selectedCompany.name}}
                    </button>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>{{globals.labels.placeholders.shared_page.add_activity.call_summary}}</label>
              </div>
              <div class="card-body-content">
                <textarea class="form-control text-color-secondary" appAutosize type="text" rows="2"
                  [(ngModel)]="role_specific_pitch.call_summary" [ngModelOptions]="{standalone: true}"
                  [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.call_summary">
                     </textarea>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>{{globals.labels.placeholders.shared_page.add_activity.tier}}</label>
              </div>
              <div class="card-body-content">
                <ng-select dropdownPosition="bottom"
                  [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.select_tier"
                  bindLabel="description" [(ngModel)]="role_specific_pitch.tier" [ngModelOptions]="{standalone: true}"
                  [items]="tiers">
                  <ng-template ng-option-tmp let-item="item" let-index="i">
                    <div class="media mb-1">
                      <div class="media-body">
                        <p>
                          <i class="tt-icon-sm icon-tttour"></i>
                          {{item.description}} - {{item.hover}}
                        </p>
                      </div>
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>{{globals.labels.placeholders.shared_page.add_activity.quality_of_client}}</label>
              </div>
              <div class="card-body-content">
                <ng-select dropdownPosition="bottom"
                  [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.select_quality_of_client"
                  bindLabel="description" [(ngModel)]="role_specific_pitch.quality_of_client"
                  [ngModelOptions]="{standalone: true}" [items]="qualitiesOfClient">
                </ng-select>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-6">
                  <div class="card-body-title-basic">
                    <label>{{globals.labels.placeholders.shared_page.add_activity.role_name}}</label>
                  </div>
                  <div class="card-body-content">
                    <input class="form-control" [(ngModel)]="role_specific_pitch.role_name" name="role_name" type="text"
                      [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.role_name" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card-body-title-basic">
                    <label>{{globals.labels.placeholders.shared_page.add_activity.location}}</label>
                  </div>
                  <div class="card-body-content">
                    <ng-select [items]="locations$ | async" bindLabel="description" placeholder="Select location"
                      dropdownPosition="bottom" [minTermLength]="minLengthTerm" [loading]="locationLoading"
                      typeToSearchText="Please enter {{minLengthTerm}} or more characters" [typeahead]="locationInput$"
                      [(ngModel)]="role_specific_pitch.location" [ngModelOptions]="{standalone: true}">
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-6">
                  <div class="card-body-title-basic">
                    <label>{{globals.labels.placeholders.shared_page.add_activity.functional}}</label>
                  </div>
                  <div class="card-body-content">
                    <ng-select bindLabel="description" dropdownPosition="bottom"
                      [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.select_functional"
                      [items]="functional" [(ngModel)]="role_specific_pitch.fe" [ngModelOptions]="{standalone: true}"
                      (clear)="clearFunctional()">
                    </ng-select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card-body-title-basic">
                    <label>{{globals.labels.placeholders.shared_page.add_activity.subfunctional}}</label>
                  </div>
                  <div class="card-body-content">
                    <ng-select bindLabel="description" dropdownPosition="bottom"
                      [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.select_sub_functional"
                      [(ngModel)]="role_specific_pitch.sfe" [ngModelOptions]="{standalone: true}"
                      [items]="filtered_sub_functional" (open)="getSubFunctional()">
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-6">
                  <div class="card-body-title-basic">
                    <label>{{globals.labels.placeholders.shared_page.add_activity.seniority}}</label>
                  </div>
                  <div class="card-body-content">
                    <ng-select dropdownPosition="bottom"
                      [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.select_seniority"
                      bindLabel="description" [(ngModel)]="role_specific_pitch.seniority"
                      [ngModelOptions]="{standalone: true}" [items]="seniority">
                    </ng-select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card-body-title-basic">
                    <label>{{globals.labels.placeholders.shared_page.add_activity.industry}}</label>
                  </div>
                  <div class="card-body-content">
                    <ng-select bindLabel="description" dropdownPosition="bottom"
                      [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.select_industry"
                      [(ngModel)]="role_specific_pitch.industry" [ngModelOptions]="{standalone: true}"
                      [items]="industries">
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="scroll" *ngIf="type && type.value=='catch_up'">
            <div class="form-group">
              <div class="form-section">
                <label>{{globals.labels.placeholders.shared_page.add_activity.catch_up_details}}</label>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>{{globals.labels.placeholders.shared_page.add_activity.company}}</label>
              </div>
              <div class="card-body-content">
                <ng-select [items]="companies$ | async" bindLabel="name"
                  [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.select_company"
                  dropdownPosition="bottom" [minTermLength]="minLengthTerm" [loading]="companyLoading"
                  typeToSearchText="Please enter {{minLengthTerm}} or more characters" [typeahead]="companyInput$"
                  [(ngModel)]="selectedCompany" [ngModelOptions]="{standalone: true}" (clear)="clearCompany()"
                  [hideSelected]="true" [addTag]="addTagCompany">
                  <ng-template ng-option-tmp let-item="item" let-index="i">
                    <div class="media mb-1">
                      <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                        [src]="item.photo ? item.photo : globals.companyAvatar"
                        (error)="item.photo = globals.companyAvatar" />
                      <div class="media-body">
                        <div class="media-body-title mt-1 mb-0">{{item.name}}</div>
                        <p>
                          <i class="tt-icon-sm icon-ttrelocation"></i>{{item.location ? item.location : 'No location
                          available'}}
                        </p>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template ng-tag-tmp let-search="searchTerm">
                    <button
                      class="btn bg-secondary-color btn-position pull-right mb-0">{{globals.labels.placeholders.shared_page.add_activity.add_company}}
                      {{search}}</button>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>{{globals.labels.placeholders.shared_page.add_activity.contacts}}</label>
              </div>
              <div class="card-body-content">
                <ng-select [multiple]="true" [items]="contacts$ | async" bindValue="uuid" bindLabel="name"
                  [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.select_contacts"
                  dropdownPosition="bottom" [minTermLength]="minLengthTerm" [loading]="contactLoading"
                  typeToSearchText="Please enter {{minLengthTerm}} or more characters" [typeahead]="contactInput$"
                  [(ngModel)]="selectedContacts" [ngModelOptions]="{standalone: true}" [addTag]="addTagContact"
                  [disabled]="!selectedCompany || !selectedCompany.uuid" (change)="clearInput()">
                  <ng-template ng-option-tmp let-item="item" let-index="i">
                    <div class="media mb-1">
                      <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                        [src]="item.photo ? item.photo : globals.avatar" (error)="item.photo = globals.avatar" />
                      <div class="media-body">
                        <div class="media-body-title mt-1 mb-0">{{item.name}}</div>
                        <p>
                          <i class="tt-icon-sm icon-ttmail email-icon mr-1"></i>{{item.email ? item.email : 'No email
                          available'}}<br>
                          <i class="tt-icon-sm icon-ttcareer"></i>{{item.job_title ? item.job_title : 'No job title
                          available'}}
                        </p>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template ng-tag-tmp let-search="searchTerm">
                    <div class="form-group">
                      <input class="form-control" type="text" placeholder="Insert email" (click)="prevent($event)"
                        [(ngModel)]="email" />
                    </div>
                    <button class="btn bg-secondary-color btn-position pull-right mb-0">
                      {{globals.labels.placeholders.shared_page.add_activity.add_contact}} {{search}}
                      {{globals.labels.placeholders.shared_page.add_activity.at}} {{selectedCompany.name}}
                    </button>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>{{globals.labels.placeholders.shared_page.add_activity.jobs}}</label>
              </div>
              <div class="card-body-content">
                <ng-select [multiple]="true" [items]="companyJobs$ | async" bindValue="uuid" bindLabel="job_title"
                  [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.select_jobs"
                  dropdownPosition="bottom" [minTermLength]="minLengthTerm" [loading]="companyJobLoading"
                  typeToSearchText="Please enter {{minLengthTerm}} or more characters" [typeahead]="companyJobInput$"
                  [(ngModel)]="selectedJob" [ngModelOptions]="{standalone: true}" (change)="filterCandidates()"
                  (clear)="clearJob()">
                </ng-select>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>{{globals.labels.placeholders.shared_page.add_activity.candidates}}</label>
              </div>
              <div class="card-body-content">
                <ng-select [multiple]="true" [items]="candidates$ | async" bindValue="uuid" bindLabel="name"
                  [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.candidates"
                  dropdownPosition="bottom" [minTermLength]="minLengthTerm" [loading]="candidateLoading"
                  typeToSearchText="Please enter {{minLengthTerm}} or more characters" [typeahead]="candidateInput$"
                  [(ngModel)]="selectedCandidate" [ngModelOptions]="{standalone: true}">
                  <ng-template ng-option-tmp let-item="item" let-index="i">
                    <div class="media mb-1">
                      <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                        [src]="item.photo ? item.photo : globals.avatar" (error)="item.photo = globals.avatar" />
                      <div class="media-body">
                        <div class="media-body-title mt-1 mb-0">{{item.name}}</div>
                        <p>
                          <i class="tt-icon-sm icon-ttcareer"></i>
                          {{item.current_job_title || item.current_employer ? item.current_job_title + '@ ' +
                          item.current_employer : 'No job title available'}}
                        </p>
                      </div>
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="form-group">
              <div class="card-body-title-basic">
                <label>{{globals.labels.placeholders.shared_page.add_activity.catch_up}}</label>
              </div>
              <div class="card-body-content">
                <quill-editor id="quill-editor2" [(ngModel)]="catch_up.call_summary"
                  [ngModelOptions]="{standalone: true}"
                  [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.catch_up"></quill-editor>
              </div>
            </div>
          </div>
          <div class="scroll" *ngIf="type && type.value=='intake_call'">
            <div class="form-group">
              <div class="form-section">
                <label>{{globals.labels.placeholders.shared_page.add_activity.intake_call_details}}</label>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                        {{globals.labels.placeholders.shared_page.add_activity.company}}
                        <span *ngIf="!validForm && (!selectedCompany ||  !selectedCompany.uuid)"
                          [ngClass]="{'primary-color': (!selectedCompany || !selectedCompany.uuid)}">
                          {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                        </span>
                      </label>
                    </div>
                    <div class="card-body-content">
                      <ng-select [clearable]="false" [items]="companies$ | async" bindLabel="name"
                        [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.select_company"
                        dropdownPosition="bottom" [minTermLength]="minLengthTerm" [loading]="companyLoading"
                        typeToSearchText="Please enter {{minLengthTerm}} or more characters" [typeahead]="companyInput$"
                        [(ngModel)]="selectedCompany" [ngModelOptions]="{standalone: true}" [hideSelected]="true"
                        [addTag]="addTagCompany" (clear)="clearCompany()">
                        <ng-template ng-option-tmp let-item="item" let-index="i">
                          <div class="media mb-1">
                            <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                              [src]="item.photo ? item.photo : globals.companyAvatar"
                              (error)="item.photo = globals.companyAvatar" />
                            <div class="media-body">
                              <div class="media-body-title mt-1 mb-0">{{item.name}}</div>
                              <p>
                                <i class="tt-icon-sm icon-ttrelocation"></i>{{item.location ? item.location : 'No
                                location available'}}
                              </p>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template ng-tag-tmp let-search="searchTerm">
                          <button
                            class="btn bg-secondary-color btn-position pull-right mb-0">{{globals.labels.placeholders.shared_page.add_activity.add_company}}
                            {{search}}</button>
                        </ng-template>
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                        {{globals.labels.placeholders.shared_page.add_activity.contacts}}
                        <span *ngIf="!validForm && !selectedContacts[0]"
                          [ngClass]="{'primary-color': !selectedContacts[0]}">
                          {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                        </span>
                      </label>
                    </div>
                    <div class="card-body-content">
                      <ng-select [multiple]="true" [clearable]="false" [items]="contacts$ | async" bindValue="uuid"
                        bindLabel="name"
                        [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.select_contacts"
                        dropdownPosition="bottom" [minTermLength]="minLengthTerm" [loading]="contactLoading"
                        typeToSearchText="Please enter {{minLengthTerm}} or more characters" [typeahead]="contactInput$"
                        [(ngModel)]="selectedContacts" [ngModelOptions]="{standalone: true}" [addTag]="addTagContact"
                        [disabled]="!selectedCompany || !selectedCompany.uuid" (change)="clearInput()">
                        <ng-template ng-option-tmp let-item="item" let-index="i">
                          <div class="media mb-1">
                            <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                              [src]="item.photo ? item.photo : globals.avatar" (error)="item.photo = globals.avatar" />
                            <div class="media-body">
                              <div class="media-body-title mt-1 mb-0">{{item.name}}</div>
                              <p>
                                <i class="tt-icon-sm icon-ttmail email-icon mr-1"></i>{{item.email ? item.email : 'No
                                email available'}}<br>
                                <i class="tt-icon-sm icon-ttcareer"></i>{{item.job_title ? item.job_title : 'No job
                                title available'}}
                              </p>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template ng-tag-tmp let-search="searchTerm">
                          <div class="form-group">
                            <input class="form-control" type="text" placeholder="Insert email" (click)="prevent($event)"
                              [(ngModel)]="email" />
                          </div>
                          <button class="btn bg-secondary-color btn-position pull-right mb-0">
                            {{globals.labels.placeholders.shared_page.add_activity.add_contact}} {{search}}
                            {{globals.labels.placeholders.shared_page.add_activity.at}} {{selectedCompany.name}}
                          </button>
                        </ng-template>
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                        {{globals.labels.placeholders.shared_page.add_activity.jobs}}
                        <span *ngIf="!validForm && selectedJob.length < 1 " [ngClass]="{'primary-color': selectedJob}">
                          {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                        </span>
                      </label>
                    </div>
                    <div class="card-body-content">
                      <ng-select [clearable]="false" [multiple]="true" [items]="companyJobs$ | async" bindValue="uuid"
                        bindLabel="job_title"
                        [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.select_jobs"
                        dropdownPosition="bottom" [minTermLength]="minLengthTerm" [loading]="companyJobLoading"
                        typeToSearchText="Please enter {{minLengthTerm}} or more characters"
                        [typeahead]="companyJobInput$" [(ngModel)]="selectedJob" [ngModelOptions]="{standalone: true}"
                        [disabled]="!selectedCompany || !selectedCompany.uuid">
                      </ng-select>
                    </div>

                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 scroll scroll-intakeCall">
                  <div class="form-group">
                    <ngb-accordion [closeOthers]="true" id="secondary-accordion" #accBasic="ngbAccordion">
                      <ngb-panel id='basic'>
                        <ng-template ngbPanelHeader>
                          <h5 class="card-title p-4" (click)="accBasic.toggle('basic');basic_facts = !basic_facts">
                            {{globals.labels.placeholders.shared_page.add_activity.basic_facts}}
                            <i class="pull-right"
                              [ngClass]="{'ft-chevron-right': !basic_facts,'ft-chevron-down': basic_facts}"></i>
                          </h5>
                        </ng-template>
                        <ng-template ngbPanelContent>
                          <div class="card-body-content">
                            <ul>
                              <li>{{globals.labels.placeholders.shared_page.add_activity.basic_facts1}}</li>
                              <li>{{globals.labels.placeholders.shared_page.add_activity.basic_facts2}}</li>
                              <li>{{globals.labels.placeholders.shared_page.add_activity.basic_facts3}}</li>
                              <li>{{globals.labels.placeholders.shared_page.add_activity.basic_facts4}}</li>
                              <li>{{globals.labels.placeholders.shared_page.add_activity.basic_facts5}}</li>
                              <li>{{globals.labels.placeholders.shared_page.add_activity.basic_facts6}}</li>
                              <li>{{globals.labels.placeholders.shared_page.add_activity.basic_facts7}}</li>
                              <li>{{globals.labels.placeholders.shared_page.add_activity.basic_facts8}}</li>
                            </ul>
                          </div>
                        </ng-template>
                      </ngb-panel>
                    </ngb-accordion>
                  </div>
                  <div class="form-group">
                    <ngb-accordion [closeOthers]="true" id="secondary-accordion" #accRole="ngbAccordion">
                      <ngb-panel id="role">
                        <ng-template ngbPanelHeader>
                          <h5 class="card-title p-4" (click)="accRole.toggle('role');the_role= !the_role">
                            {{globals.labels.placeholders.shared_page.add_activity.the_role}}
                            <i class="pull-right"
                              [ngClass]="{'ft-chevron-right': !the_role,'ft-chevron-down': the_role}"></i>
                          </h5>
                        </ng-template>
                        <ng-template ngbPanelContent>
                          <div class="card-body-content">
                            <ul>
                              <li>{{globals.labels.placeholders.shared_page.add_activity.the_role1}}</li>
                              <li>{{globals.labels.placeholders.shared_page.add_activity.the_role2}}</li>
                              <li>{{globals.labels.placeholders.shared_page.add_activity.the_role3}}</li>
                              <li>{{globals.labels.placeholders.shared_page.add_activity.the_role4}}</li>
                              <li>{{globals.labels.placeholders.shared_page.add_activity.the_role5}}</li>
                              <li>{{globals.labels.placeholders.shared_page.add_activity.the_role6}}</li>
                              <li>{{globals.labels.placeholders.shared_page.add_activity.the_role7}}</li>
                              <li>{{globals.labels.placeholders.shared_page.add_activity.the_role8}}</li>
                              <li>{{globals.labels.placeholders.shared_page.add_activity.the_role9}}</li>
                            </ul>
                          </div>
                        </ng-template>
                      </ngb-panel>
                    </ngb-accordion>
                  </div>
                  <div class="form-group">
                    <ngb-accordion [closeOthers]="true" id="secondary-accordion" #accProcess="ngbAccordion">
                      <ngb-panel id="process">
                        <ng-template ngbPanelHeader>
                          <h5 class="card-title p-4" (click)="accProcess.toggle('process');process = !process">
                            {{globals.labels.placeholders.shared_page.add_activity.process}}
                            <i class="pull-right"
                              [ngClass]="{'ft-chevron-right': !process,'ft-chevron-down': process}"></i>
                          </h5>
                        </ng-template>
                        <ng-template ngbPanelContent>
                          <div class="card-body-content">
                            <ul>
                              <li>{{globals.labels.placeholders.shared_page.add_activity.process1}}</li>
                              <li>{{globals.labels.placeholders.shared_page.add_activity.process2}}</li>
                              <li>{{globals.labels.placeholders.shared_page.add_activity.process3}}</li>
                              <li>{{globals.labels.placeholders.shared_page.add_activity.process4}}</li>
                            </ul>
                          </div>
                        </ng-template>
                      </ngb-panel>
                    </ngb-accordion>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="card-body-content">
                      <quill-editor id="quill-editor"
                        [placeholder]="globals.labels.placeholders.inputs.shared_page.add_activity.intake_call_summary"
                        [(ngModel)]="intake_call.summary" [ngModelOptions]="{standalone: true}">
                      </quill-editor>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="position-bottom full-width" *ngIf="type">

  <button type="button" class="btn pull-right bg-secondary-color" (click)="onCreate()">{{ type.value == 'interview' ?
    globals.labels.placeholders.shared_page.add_activity.complete_interview :
    globals.labels.placeholders.global.buttons.create}}
    {{type.description}}

  </button>
</div>