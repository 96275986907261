<div class="full-width">
  <div class="card scroll mt-5">
    <div class="card-block">
      <div class="card-body">
        <div class="form">
          <div class="form-section">
            {{globals.labels.placeholders.shared_page.close_job.close_job_title}}
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-6">
                <div class="card-body-title-basic">
                  <label> {{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                    {{globals.labels.placeholders.shared_page.close_job.status}}
                    <span *ngIf="!validForm && !closingData.status_id"
                      [ngClass]="{'primary-color': !closingData.status_id}">
                      {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                    </span>
                  </label>
                </div>
                <div class="card-body-content">
                  <ng-select bindLabel="description" dropdownPosition="bottom" [items]="status"
                    [(ngModel)]="closingData.status_id" [ngModelOptions]="{standalone: true}"
                    (change)="this.closingData.reason_id = null;this.reason = [];"
                    [placeholder]="globals.labels.placeholders.inputs.shared_page.close_job.select_status"
                    (open)="getResources()">
                  </ng-select>
                </div>
              </div>
              <div class="col-6">
                <div class="card-body-title-basic">
                  <label> {{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                    {{globals.labels.placeholders.shared_page.close_job.reason}}
                    <span *ngIf="!validForm && !closingData.reason_id"
                      [ngClass]="{'primary-color': !closingData.reason_id}">
                      {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                    </span>
                  </label>
                </div>
                <ng-select bindLabel="description" dropdownPosition="bottom" [items]="reason"
                  (open)="getReason(closingData.status_id.id)" [disabled]="!closingData.status_id"
                  [(ngModel)]="closingData.reason_id" [ngModelOptions]="{standalone: true}"
                  [placeholder]="globals.labels.placeholders.inputs.shared_page.close_job.select_reason">
                </ng-select>
              </div>
            </div>
          </div>

          <!-- <div class="form-group" *ngIf="(closingData.status_id ? closingData.status_id.value == 'lose' : false) 
              && (closingData.reason_id ? closingData.reason_id.value == 'role-canceled-client':false)">
              <div class="card-body-content">
                <div class="custom-control custom-checkbox m-0">
                  <input class="custom-control-input" id="introduce_to_client" type="checkbox"
                    [(ngModel)]="closingData.include_cancellation_fee" [ngModelOptions]="{standalone: true}"
                    (change)="this.closingData.cancellation_fee = 0; this.closingData.fee_currency_id = null;">
                  <label class="custom-control-label cursor-pointer"
                    for="introduce_to_client">{{globals.labels.placeholders.shared_page.close_job.include_cancellation_fee}}</label>
                </div>
              </div>
            </div> -->


          <!-- 
            <div class="form-group" *ngIf="closingData.include_cancellation_fee && (closingData.status_id ? closingData.status_id.value == 'lose' : false) 
            && (closingData.reason_id ? closingData.reason_id.value == 'role-canceled-client':false)">
              <div class="row">
                <div class="col-md-6">
                  <div class="card-body-title-basic">
                    <label> {{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                      {{globals.labels.placeholders.shared_page.close_job.amount}}
                      <span
                        *ngIf="!validForm && (!this.closingData.cancellation_fee || this.closingData.cancellation_fee == 0)"
                        [ngClass]="{'primary-color': (!this.closingData.cancellation_fee || this.closingData.cancellation_fee == 0)}">
                        {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                      </span>
                    </label>
                  </div>
                  <div class="card-body-content">
                    <input class="form-control" type="number" [(ngModel)]="closingData.cancellation_fee"
                      [ngModelOptions]="{standalone: true}"
                      [placeholder]="globals.labels.placeholders.inputs.shared_page.close_job.insert_amount" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card-body-title-basic">
                    <label>{{globals.labels.placeholders.shared_page.close_job.currency}}</label>
                  </div>
                  <div class="card-body-content">
                    <ng-select bindLabel="description" bindValue="id" dropdownPosition="bottom"
                      [placeholder]="globals.labels.placeholders.inputs.shared_page.close_job.select_currency"
                      (open)="getResources()" [items]="currencies" [(ngModel)]="closingData.fee_currency_id"
                      [ngModelOptions]="{standalone: true}">
                    </ng-select>
                  </div>
                </div>
              </div>
            </div> -->
          <div class="form-group">
            <div class="card-body-title-basic">
              <label>{{globals.labels.placeholders.shared_page.close_job.additional_info}}</label>
            </div>
            <div class="card-body-content">
              <textarea rows="3" class="form-control" type="text" [(ngModel)]="closingData.additional_info"
                [placeholder]="globals.labels.placeholders.inputs.shared_page.close_job.insert_additioal_info">
              </textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="position-bottom full-width">
    <button type="button" class="btn pull-right bg-secondary-color"
      (click)="closeJob()">{{globals.labels.placeholders.shared_page.close_job.close_job}}</button>
  </div>
</div>