<div class="modal-content">
  <div class="modal-bg">
    <div class="modal-header">
      <div class="modal-header-title">
        Add to job
      </div>
      <button type="button" class="close pull-right" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true" [innerHTML]="globals.labels.placeholders.global.buttons.close_x" data-toggle="tooltip"
          data-placement="bottom" [title]="globals.labels.tooltips.global.close"></span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <div class="modal-body-title">
          <label> {{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
            Open jobs
            <span *ngIf="!validForm && !addTojob.job" [ngClass]="{'primary-color': !addTojob.job}">
              {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
            </span>
          </label>
        </div>
        <div class="modal-body-content">
          <ng-select bindLabel="job_title" dropdownPosition="bottom" (open)="getJobs('open', null)"
            [placeholder]="globals.labels.placeholders.inputs.shared_page.select_job" [items]="resources.jobs"
            [(ngModel)]="addTojob.job" [ngModelOptions]="{standalone: true}">
          </ng-select>
        </div>
      </div>
      <div class="form-group">
        <div class="modal-body-title">
          <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
            Stage
            <span *ngIf="!validForm && !addTojob.stage"
              [ngClass]="{'primary-color': !addTojob.stage}">
              {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
            </span></label>
        </div>
        <div class="modal-body-content">
          <ng-select bindLabel="description" dropdownPosition="bottom" placeholder="Select job stage" [items]="stage"
            [(ngModel)]="addTojob.stage" [ngModelOptions]="{standalone: true}" (open)="openStageDropdown()">
          </ng-select>
        </div>
      </div>
      <div class="form-group" *ngIf="addTojob.stage && 'longlist' == addTojob.stage.value">
        <div class="modal-body-title">
          <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
            {{globals.labels.placeholders.full_layout_page.omni_component.job.sub_status}}
            (the substatus will be added to all selected candidates)
            <span *ngIf="!validForm && !addTojob.substatus"
              [ngClass]="{'primary-color': !addTojob.substatus}">
              {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
            </span></label>
        </div>
        <div class="modal-body-content">
          <ng-select bindLabel="description" dropdownPosition="bottom"
            [placeholder]="globals.labels.placeholders.inputs.full_layout_page.omni_component.job.sub_status"
            [items]="resources.subStatuses" [(ngModel)]="addTojob.substatus" [ngModelOptions]="{standalone: true}"
            (open)="getInitialSubStatuses()">
          </ng-select>
        </div>
      </div>
      <div class="form-group">
        <div class="modal-body-title">
          <label> Candidate rating </label>
        </div>
        <div class="modal-body-content">
          <ng-select bindLabel="description" dropdownPosition="bottom"
            placeholder="Select candidate rating"
            [items]="resources.candidate_application_rating" [(ngModel)]="addTojob.candidate_rating"
            [ngModelOptions]="{standalone: true}" (open)="getResourcesByType('candidate_application_rating')"
            (clear)="addTojob.comment = null">
          </ng-select>
        </div>
      </div>
      <div class="form-group" *ngIf="addTojob.stage">
        <div class="modal-body-title">
          <label>Comments (the comment will be added to all selected candidates)</label>
        </div>
        <div class="modal-body-content">
          <textarea rows="2" class="form-control" type="text" [(ngModel)]="addTojob.comment"
            [ngModelOptions]="{standalone: true}" placeholder="Add Comment"></textarea>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn pull-right bg-secondary-color" (click)="checkRequiredFields()">
        Add to job</button>
      <button type="button" class="btn pull-right bg-primary-color"
        (click)="activeModal.dismiss()">{{globals.labels.placeholders.global.buttons.close}}</button>
    </div>
  </div>
