import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class DeleteCandidateModalService{

  constructor(private http: HttpClient){ }

  deleteCandidate(data: any): Observable<any>{
    const header: HttpHeaders = new HttpHeaders();
    const httpOptions = {
      headers: header,
      body: data
    };
    return this.http.delete(environment.platformBackendURL + '/candidates/' + data.uuid, httpOptions);
  }
}
