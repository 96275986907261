<div *ngIf="!loadComponent">
  <img [src]="globals.loading" class="loading-element" />
</div>
<div class="card-block" *ngIf="loadComponent">
  <nav ngbNav #nav="ngbNav" class="full-width nav-tabs" id="tab-shadow">
    <ng-container [ngbNavItem]="'ngb-tab-0'">
      <a ngbNavLink>Details
        <span class="badge m-0 badge-primary" *ngIf="badgeFlagDetails">
          <i class="fa fa-circle"></i>
        </span>
      </a>
      <ng-template ngbNavContent>
        <div class="card card-top scroll">
          <div class="card-block">
            <div class="card-body">
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                    {{globals.labels.placeholders.shared_page.add_job.job_title}}
                    <span *ngIf="!validForm && !job.job_title" [ngClass]="{'primary-color': !job.job_title}">
                      {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                    </span>
                  </label>
                </div>
                <div class="card-body-content">
                  <input class="form-control" type="text" [(ngModel)]="job.job_title"
                    [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.job_title" />
                </div>
              </div>
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                    {{globals.labels.placeholders.shared_page.add_job.company}}
                    <span *ngIf="!validForm && !job.company" [ngClass]="{'primary-color': !job.company}">
                      {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                    </span>
                  </label>
                </div>
                <div class="card-body-content">
                  <ng-select 
                    [items]="companies$ | async"
                    [hideSelected]="true"
                    [addTag]="addTagCompany"
                    bindLabel="name" 
                    [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.company"
                    dropdownPosition="bottom"
                    [minTermLength]="minLengthTerm" 
                    [loading]="companyLoading"
                    typeToSearchText="Please enter {{minLengthTerm}} or more characters" 
                    [typeahead]="companyInput$"
                    [(ngModel)]="job.company">
                    <ng-template ng-option-tmp let-item="item" let-index="i">
                      <div class="media mb-1">
                        <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                          [src]="item.photo ? item.photo : globals.companyAvatar"
                          (error)="item.photo = globals.companyAvatar" />
                        <div class="media-body">
                          <div class="media-body-title mt-1 mb-0">{{item.name}}</div>
                          <p><i *ngIf="item.location"
                              class="tt-icon-sm icon-ttrelocation"></i>{{item.location ? item.location : globals.labels.placeholders.shared_page.add_job.empty_location}}
                          </p>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template ng-tag-tmp let-search="searchTerm">
                      <button
                        class="btn bg-secondary-color btn-position pull-right mb-0">{{globals.labels.placeholders.shared_page.add_job.add_company}}
                        {{search}}</button>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                    {{globals.labels.placeholders.shared_page.add_job.contact}}
                    <span *ngIf="!validForm && !job.contact" [ngClass]="{'primary-color': !job.contact}">
                      {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                    </span>
                  </label>
                </div>
                <div class="card-body-content">
                  <ng-select bindLabel="name" dropdownPosition="bottom" [items]="filteredContact"
                    [addTag]="addTagContact" [(ngModel)]="job.contact" [clearable]="false"
                    (open)="filterContacts(job.company.uuid)" [disabled]="!job.company"
                    [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.contact">
                    <ng-template ng-option-tmp let-item="item" let-index="i">
                      <div class="media mb-1">
                        <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                          [src]="item.photo ? item.photo : globals.avatar" (error)="item.photo = globals.avatar" />
                        <div class="media-body">
                          <div class="media-body-title mt-1 mb-0">{{item.name}}</div>
                          <p><i
                              class="tt-icon-sm icon-ttmail email-icon mr-1"></i>{{item.email ? item.email : globals.labels.placeholders.shared_page.add_job.empty_email}}<br>
                            <i
                              class="tt-icon-sm icon-ttcareer"></i>{{item.job_title ? item.job_title : globals.labels.placeholders.shared_page.add_job.empty_title}}
                          </p>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template ng-tag-tmp let-search="searchTerm">
                      <div class="form-group">
                        <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                          {{globals.labels.placeholders.shared_page.add_job.contact_email}}
                          <span *ngIf="!validFormContact  && !email" [ngClass]="{'primary-color': !email}">
                            {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                          </span>
                        </label>
                        <input class="form-control" type="text" (click)="prevent($event)"
                          [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.contact_email"
                          [(ngModel)]="email" />
                      </div>
                      <button class="btn bg-secondary-color btn-position pull-right mb-0">
                        {{globals.labels.placeholders.shared_page.add_job.add_contact}} {{search}}
                        {{globals.labels.placeholders.global.at}} {{job.company.name}}
                      </button>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                    {{globals.labels.placeholders.shared_page.add_job.job_type}}
                    <span *ngIf="!validForm  && !job.type" [ngClass]="{'primary-color': !job.type}">
                      {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                    </span>
                  </label>
                </div>
                <div class="card-body-content">
                  <ng-select bindLabel="description" dropdownPosition="bottom" [items]="jobType" [(ngModel)]="job.type"
                    [ngModelOptions]="{standalone: true}" [clearable]="false"
                    [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.job_type">
                  </ng-select>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-md-12 col-lg-6">
                    <div class="card-body-title-basic">
                      <label> {{globals.labels.placeholders.shared_page.add_job.job_status}}</label>
                    </div>
                    <div class="card-body-content">
                      <ng-select bindLabel="description" dropdownPosition="bottom" [items]="status"
                        [(ngModel)]="job.status" [ngModelOptions]="{standalone: true}"
                        [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.job_status">
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-6">
                    <div class="card-body-title-basic">
                      <label>{{globals.labels.placeholders.shared_page.add_job.job_priority}}</label>
                    </div>
                    <div class="card-body-content">
                      <ng-select bindLabel="description" dropdownPosition="bottom" [items]="priority"
                        [(ngModel)]="job.priority" [ngModelOptions]="{standalone: true}"
                        [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.job_priority">
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>{{globals.labels.placeholders.shared_page.add_job.location}}</label>
                </div>
                <div class="card-body-content">
                  <div class="row">
                    <div class="col-12">
                      <ng-select 
                            [multiple]="true"
                            [items]="locations$ | async" 
                            bindLabel="description" 
                            placeholder="Select location"
                            dropdownPosition="bottom"
                            [minTermLength]="minLengthTerm" 
                            [loading]="locationLoading"
                            typeToSearchText="Please enter {{minLengthTerm}} or more characters" 
                            [typeahead]="locationInput$"
                            [(ngModel)]="job.location">
                          </ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-md-12 col-lg-6">
                    <div class="card-body-title-basic">
                      <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                        {{globals.labels.placeholders.shared_page.add_job.fe}}</label>
                      <span *ngIf="!validForm && !this.job.fe" [ngClass]="{'primary-color': !this.job.fe}">
                        {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                      </span>
                    </div>
                    <div class="card-body-content">
                      <ng-select bindLabel="description" dropdownPosition="bottom" [items]="fe" [(ngModel)]="job.fe"
                        [ngModelOptions]="{standalone: true}" (change)="
                          this.job.sfe = null;
                          filterSfe(job.fe);" (clear)="this.job.sfe = null"
                        [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.fe">
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-6">
                    <div class="card-body-title-basic">
                      <label>{{globals.labels.placeholders.shared_page.add_job.sfe}}</label>
                    </div>
                    <div class="card-body-content">
                      <ng-select bindLabel="description" dropdownPosition="bottom" [items]="filteredSfe"
                        [disabled]="!job.fe" [(ngModel)]="job.sfe" [ngModelOptions]="{standalone: true}"
                        [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.sfe">
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-md-12 col-lg-6">
                    <div class="card-body-title-basic">
                      <label>{{globals.labels.placeholders.shared_page.add_job.industry}}</label>
                    </div>
                    <div class="card-body-content">
                      <ng-select bindLabel="description" dropdownPosition="bottom" [items]="industry"
                        [(ngModel)]="job.industry" [ngModelOptions]="{standalone: true}"
                        [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.industry">
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-6">
                    <div class="card-body-title-basic">
                      <label>{{globals.labels.placeholders.shared_page.add_job.seniority}}</label>
                    </div>
                    <div class="card-body-content">
                      <ng-select bindLabel="description" dropdownPosition="bottom" [items]="seniority"
                        [(ngModel)]="job.seniority" [ngModelOptions]="{standalone: true}"
                        [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.seniority">
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-md-12 col-lg-6">
                    <div class="card-body-title-basic">
                      <label> {{globals.labels.placeholders.shared_page.add_job.length}}
                        <span *ngIf="!validForm && !job.projected_length"
                          [ngClass]="{'primary-color': !job.projected_length}">
                          Zero is not allowed
                        </span>
                      </label>
                    </div>
                    <div class="card-body-content">
                      <input class="form-control" type="number" pattern="[0-9]*" min="0" max="20"
                        [(ngModel)]="job.projected_length" (keydown)=preventNegativeNumbers($event)
                        [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.length" />
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-6">
                    <div class="card-body-title-basic">
                      <label> {{globals.labels.placeholders.shared_page.add_job.headcount}}
                        <span *ngIf="!validForm && !job.head_count" [ngClass]="{'primary-color': !job.head_count}">
                          Zero is not allowed
                        </span>
                      </label>
                    </div>
                    <div class="card-body-content">
                      <input class="form-control" type="number" pattern="[0-9]*" min="0" [(ngModel)]="job.head_count"
                        (keydown)=preventNegativeNumbers($event) max="20"
                        [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.headcount" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-md-12 col-lg-6">
                    <div class="card-body-title-basic">
                      <label> Search source</label>
                    </div>
                    <div class="card-body-content">
                      <ng-select bindLabel="description" dropdownPosition="bottom" [items]="searchSources"
                        [(ngModel)]="job.search_source" [ngModelOptions]="{standalone: true}"
                        placeholder="Select the search source"
                      >
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-6">
                    <div class="card-body-title-basic">
                      <label> Affinity opportunity</label>
                    </div>
                    <div class="card-body-content">
                      <ng-select bindLabel="name" bindValue="id" dropdownPosition="bottom" [items]="opportunities"
                        [(ngModel)]="job.affinity_opportunity_id" [ngModelOptions]="{standalone: true}"
                        placeholder="Select an opportunity from affinity"
                        >
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>{{globals.labels.placeholders.shared_page.add_job.description}} </label>
                </div>
                <div class="card-body-content">
                  <textarea rows="3" class="form-control" type="text" [(ngModel)]="job.description"
                    [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.description"> </textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-container [ngbNavItem]="'team'">
      <a ngbNavLink>Team</a>
      <ng-template ngbNavContent>
        <div class="card card-top scroll">
          <div class="card-block">
            <div class="card-body">
              <div class="form">
                <div class="form-section">
                  <label>Select Pod and Users with roles</label>
                </div>
                <div class="form-group">
                  <div class="card-body-title-basic">
                    <label>Pod</label>
                  </div>
                  <div class="card-body-content">
                    <ng-select bindLabel="name" dropdownPosition="bottom" [items]="pods" [(ngModel)]="job.pod"
                      (open)="getPod()" placeholder="Select pod">
                      <ng-template ng-option-tmp let-item="item" let-index="i">
                        <div class="media mb-1">
                          <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                            [src]="item.logo ? item.logo : globals.avatar" (error)="item.logo = globals.avatar" />
                          <div class="media-body">
                            <div class="media-body-title mt-3">{{item.name}}</div>
                          </div>
                        </div>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-md-12 col-lg-6">
                      <div class="card-body-title-basic">
                        <label>{{globals.labels.placeholders.shared_page.add_job.user}}</label>
                      </div>
                      <div class="card-body-content">
                        <ng-select bindLabel="name" dropdownPosition="bottom" [items]="users" [(ngModel)]="tmpUser.user"
                          [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.user">
                          <ng-template ng-option-tmp let-item="item" let-index="i">
                            {{item.name}} - {{item.email}}
                          </ng-template>
                        </ng-select>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-6">
                      <div class="card-body-title-basic">
                        <label>Role</label>
                      </div>
                      <div class="card-body-content row">
                        <div class="col-10">
                          <ng-select bindLabel="name" [closeOnSelect]="false" [multiple]="true"
                            dropdownPosition="bottom" [items]="role" [(ngModel)]="tmpUser.role"
                            [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.role">
                          </ng-select>
                        </div>
                        <div class="col-2 pl-0 pt-2">
                          <a (click)="addUser()">
                            <i class="icon icon-plus" data-toggle="tooltip" data-placement="bottom"
                              [title]="globals.labels.tooltips.shared_page.add_job.add_user"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group card p-1" *ngIf="job.users && job.users.length">
                  <div class="row" *ngFor="let user of job.users;let i = index">
                    <div class="col-md-12 col-lg-6">
                      <div class="card-body-title-basic">
                        <label class="pt-2">{{globals.labels.placeholders.shared_page.add_job.user}}</label>
                      </div>
                      <div class="card-body-content">
                        <label>{{user.name}}</label>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-6">
                      <div class="card-body-title-basic">
                        <label>{{globals.labels.placeholders.shared_page.add_job.assigned_role}}</label>
                      </div>
                      <div class="card-body-content row">
                        <div class="col-10">
                          <div *ngIf="user.roles && user.roles.length">
                            <div *ngFor="let role of user.roles" class="mr-3">
                              <span class="badge ml-0"
                                [ngClass]="{'badge-secondary' : role.value == 'recruit', 'badge-third' : role.value == 'administrator',
                                                 'badge-warning': role.value == 'research', 'badge-solve' : role.value == 'operations', 'badge-primary' : role.value == 'sales' || role.value == 'connect'}">
                                <i class="fa fa-circle"></i>
                              </span>
                              <label>
                                {{role.name}}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="col-2 pl-0 pt-2">
                          <a (click)="deleteUser(i, user.id)">
                            <i class="tt-icon-sm icon-ttdelete" data-toggle="tooltip" data-placement="bottom"
                              [title]="globals.labels.tooltips.shared_page.add_job.delete_user"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-container [ngbNavItem]="'compensation'">
      <a ngbNavLink>Compensation
        <span class="badge m-0 badge-primary" *ngIf="badgeFlagCompensation">
          <i class="fa fa-circle"></i>
        </span>
      </a>
      <ng-template ngbNavContent>
        <div class="card card-top scroll">
          <div class="card-block">
            <div class="card-body">
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                    {{globals.labels.placeholders.shared_page.add_job.currency}}</label>
                  <span
                    *ngIf="!validForm && !this.job.jobCompensation.currency"
                    [ngClass]="{'primary-color': !this.job.jobCompensation.currency}">
                    {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                  </span>
                </div>
                <div class="card-body-content">
                  <ng-select [clearable]="false" [(ngModel)]="job.jobCompensation.currency"
                    [ngModelOptions]="{standalone: true}" bindLabel="description" [items]="currency"
                    dropdownPosition="bottom"
                    [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.currency">
                  </ng-select>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-md-12 col-lg-6">
                    <div class="card-body-title-basic">
                      <label>
                        {{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                        {{globals.labels.placeholders.shared_page.add_job.salary_from}}</label>
                      <span
                        *ngIf="!validForm &&
                        !job.jobCompensation.salary_from &&
                        !job.jobCompensation.annual_salary &&
                        (job.jobCompensation.fee_type && job.jobCompensation.fee_type.value == 'percentage') &&
                        (job.type && (job.type.value== 'executive_search'  || job.type.value == 'non-executive_search'))"
                        [ngClass]="{'primary-color': !job.jobCompensation.salary_from}">
                        {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                      </span>
                    </div>
                    <div class="card-body-content">
                      <input [(ngModel)]="job.jobCompensation.salary_from" [ngModelOptions]="{standalone: true}"
                        class="form-control" type="number" min="0" [disabled]="job.jobCompensation.annual_salary"
                        [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.salary_from">
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-6">
                    <div class="card-body-title-basic">
                      <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                        {{globals.labels.placeholders.shared_page.add_job.salary_to}}</label>
                      <span
                        *ngIf="!validForm &&
                         !job.jobCompensation.salary_to &&
                         !job.jobCompensation.annual_salary &&
                         (job.jobCompensation.fee_type && job.jobCompensation.fee_type.value == 'percentage') &&
                         (job.type && (job.type.value == 'executive_search'  || job.type.value == 'non-executive_search'))"
                        [ngClass]="{'primary-color': !job.jobCompensation.salary_to}">
                        {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                      </span>
                    </div>
                    <div class="card-body-content">
                      <input [(ngModel)]="job.jobCompensation.salary_to" [ngModelOptions]="{standalone: true}"
                        class="form-control" type="number" min="0" [disabled]="job.jobCompensation.annual_salary"
                        [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.salary_to">
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>{{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                    {{globals.labels.placeholders.shared_page.add_job.annual_salary}}</label>
                  <span
                    *ngIf="!validForm &&
                    !job.jobCompensation.annual_salary &&
                     !job.jobCompensation.salary_to &&
                     !job.jobCompensation.salary_from &&
                     (job.jobCompensation.fee_type &&
                     job.jobCompensation.fee_type.value == 'percentage') && (job.type && (job.type.value == 'executive_search'  || job.type.value == 'non-executive_search'))"
                    [ngClass]="{'primary-color': !job.jobCompensation.annual_salary}">
                    {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                  </span>
                </div>
                <div class="card-body-content">
                  <input [(ngModel)]="job.jobCompensation.annual_salary" [ngModelOptions]="{standalone: true}"
                    class="form-control" type="number" min="0" (keydown)=preventNegativeNumbers($event)
                    [disabled]="job.jobCompensation.salary_from || job.jobCompensation.salary_to"
                    [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.annual_salary">
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-md-12 col-lg-8">
                    <div class="card-body-title-basic">
                      <label>{{globals.labels.placeholders.shared_page.add_job.bonus}}</label>
                    </div>
                    <div class="card-body-content">
                      <input [(ngModel)]="job.jobCompensation.bonus" [ngModelOptions]="{standalone: true}"
                        class="form-control" type="number" min="0" (keydown)=preventNegativeNumbers($event)
                        [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.bonus">
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-4">
                    <div class="card-body-title-basic">
                      <label>{{globals.labels.placeholders.shared_page.add_job.bonus_type}}</label>
                    </div>
                    <div class="card-body-content">
                      <ng-select [(ngModel)]="job.jobCompensation.bonus_type" [ngModelOptions]="{standalone: true}"
                        bindLabel="description" [items]="bonus"
                        [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.type">
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>{{globals.labels.placeholders.shared_page.add_job.equity}}</label>
                </div>
                <div class="card-body-content">
                  <textarea appAutosize [(ngModel)]="job.jobCompensation.equity" [ngModelOptions]="{standalone: true}"
                    class="form-control" type="text"
                    [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.equity">
                  </textarea>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-md-12 col-lg-8">
                    <div class="card-body-title-basic">
                      <label>
                        {{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                        {{globals.labels.placeholders.shared_page.add_job.fee}}</label>
                      <span
                        *ngIf="!validForm && (null == this.job.jobCompensation.fee || undefined == this.job.jobCompensation.fee) "
                        [ngClass]="{'primary-color': !job.jobCompensation.fee}">
                        {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                      </span>
                    </div>
                    <div class="card-body-content">
                      <input [(ngModel)]="job.jobCompensation.fee" [ngModelOptions]="{standalone: true}"
                        class="form-control" type="number" (keydown)=preventNegativeNumbers($event)
                        [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.fee">
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-4">
                    <div class="card-body-title-basic">
                      <label>
                        {{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
                        {{globals.labels.placeholders.shared_page.add_job.fee_type}}</label>
                      <span
                        *ngIf="!validForm && !this.job.jobCompensation.fee_type"
                        [ngClass]="{'primary-color': !this.job.jobCompensation.fee_type}">
                        {{globals.labels.placeholders.full_layout_page.cit_component.required_message}}
                      </span>
                    </div>
                    <div class="card-body-content">
                      <ng-select [(ngModel)]="job.jobCompensation.fee_type" [ngModelOptions]="{standalone: true}"
                        bindLabel="description" [items]="fee"
                        [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.type">
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-container [ngbNavItem]="'files'">
      <a ngbNavLink>Files</a>
      <ng-template ngbNavContent>
        <div class="card card-top scroll">
          <div class="card-block">
            <div class="card-body">
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>{{globals.labels.placeholders.shared_page.add_job.file_type}}</label>
                </div>
                <div class="card-body-content">
                  <ng-select [(ngModel)]="tmpFiles.type" [ngModelOptions]="{standalone: true}" bindLabel="description"
                    [items]="fileType" dropdownPosition="bottom"
                    [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.file_type">
                  </ng-select>
                </div>
              </div>
              <div class="form-group" *ngIf="tmpFiles.type">
                <div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)"
                  [uploader]="uploader" (onFileDrop)="afterFileisDrop()" class="p-3 text-center grey my-drop-zone">
                  <span [innerHTML]="globals.labels.placeholders.global.drag_and_drop"></span>
                  <button class="btn bg-primary-color col-md-3 col-lg-3 col-xl-3 btn-position"
                    (click)="fileInput.click()">
                    Upload file
                  </button>
                </div>
                <input class="hiddenInput m-0 p-0 position-absolute overflow-hidden" type="file" ng2FileSelect
                  [uploader]="uploader" #fileInput (change)="OnContentChange($event)">
              </div>
              <div class="form-group p-1" *ngIf="job.files && job.files.length">
                <div class="card text-center">
                  <div class="row">
                    <div class="col-md-4 col-sm-12" *ngFor="let file of job.files;let i = index">
                      <div class="card-body">
                        <div class="card-block">
                          <div [ngSwitch]="file.url.substr(file.url.lastIndexOf('.')+1).toLowerCase()">
                            <div *ngSwitchCase="'pdf'">
                              <i class="fa fa-file-pdf-o fa-5x primary-color m-2"
                                aria-hidden="true"></i>
                            </div>
                            <div *ngSwitchCase="'xlsx'">
                              <i class="fa fa-file-excel-o fa-5x text-success m-2"
                                aria-hidden="true"></i>
                            </div>
                            <div *ngSwitchCase="'msword'">
                              <i class="fa fa-file-word-o fa-5x big-search-primary-color m-2"
                                aria-hidden="true"></i>
                            </div>
                            <div *ngSwitchCase="'docx'">
                              <i class="fa fa-file-word-o fa-5x big-search-primary-color m-2"
                                aria-hidden="true"></i>
                            </div>
                            <div *ngSwitchCase="'pptx'">
                              <i class="fa fa-file-powerpoint-o fa-5x warning-color m-2"
                                aria-hidden="true"></i>
                            </div>
                            <div *ngSwitchCase="'png'">
                              <i class="fa fa-file-image-o fa-5x warning-color m-2"
                                aria-hidden="true"></i>
                            </div>
                            <div *ngSwitchCase="'jpg'">
                              <i class="fa fa-file-image-o fa-5x warning-color m-2"
                                aria-hidden="true"></i>
                            </div>
                            <div *ngSwitchCase="'jpeg'">
                              <i class="fa fa-file-image-o fa-5x warning-color m-2"
                                aria-hidden="true"></i>
                            </div>
                            <div *ngSwitchDefault>
                              <i class="fa fa-file-o fa-5x m-2"
                                aria-hidden="true"></i>
                            </div>
                          </div>
                          <div class="card-body-title-basic">{{file.file.original_name}}</div>
                          <p class="card-text">
                            <a [href]="file.url" target="_blank">
                              <i class="tt-icon-lg icon-ttinprocess secondary-color rotate" data-toggle="tooltip"
                                data-placement="bottom"
                                [title]="globals.labels.tooltips.shared_page.add_job.download_file"></i>
                            </a>
                            <a (click)="deleteFile(i, file.uuid)">
                              <i class="tt-icon-lg icon-ttdelete primary-color" data-toggle="tooltip"
                                data-placement="bottom"
                                [title]="globals.labels.tooltips.shared_page.add_job.delete_file"></i>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-container [ngbNavItem]="'scorecards'">
      <a ngbNavLink>Scorecards</a>
      <ng-template ngbNavContent>
        <div class="card card-top scroll">
          <div class="card-block">
            <div class="card-body">
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>{{globals.labels.placeholders.shared_page.add_job.scorecard}}</label>
                </div>
                <div class="card-body-content">
                  <div class="form-group" *ngFor="let scorecard of job.scorecards; let i = index">
                    <div class="row">
                      <div class="col-11">
                        <input class="form-control" type="text" [(ngModel)]="scorecard.skill"
                          [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.scorecard">
                      </div>
                      <div class="col-1">
                        <a (click)="deleteScorecard('scorecards',job.scorecards, i)">
                          <i class="tt-icon-sm icon-ttdelete pull-right m-2" data-toggle="tooltip"
                            data-placement="bottom"
                            [title]="globals.labels.tooltips.shared_page.add_job.delete_scorecard"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="card-body-content">
                    <a class="pull-right" (click)="addNewScorecard()">
                      <i class="icon icon-plus" data-toggle="tooltip" data-placement="bottom"
                        [title]="globals.labels.tooltips.shared_page.add_job.add_scorecard"></i>
                      {{globals.labels.placeholders.shared_page.add_job.new_scorecard}}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </nav>
  <div [ngbNavOutlet]="nav"></div>
  <div class="position-bottom full-width">
    <button type="button" class="btn pull-right bg-secondary-color"
      (click)="disableButton ? createJob(): ''">{{globals.labels.placeholders.global.buttons.create}}
      {{globals.labels.placeholders.shared_page.add_job.job}}
    </button>

  </div>
</div>
