<div class="card scroll mt-5">
  <div class="card-block">
    <div class="card-body">
      <div class="form">
        <div class="form-section">
          Basic details
          <div
          class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2"
          (click)="editForm('basic')">
            edit
          </div>
        </div>
        <div class="row card-block" *ngIf="edit.basic">
            <div class="col-md-12 col-12">
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>
                    * Name
                    <span >
                      (Required field)
                    </span>
                  </label>
                </div>
                <div class="card-body-content">
                  <ng-select [items]="companies$ | async" #companySelect [hideSelected]="true" dropdownPosition="bottom" [addTag]="addTagCompany"
                  bindLabel="name" [loading]="companyLoading" [typeahead]="companyinput$" 
                  (change)="setDealroomCompanyData($event)" 
                  [(ngModel)]="hiringCompany.name" (remove)="loadCompany()" [clearable]="false" placeholder="Select company"  [ngModelOptions]="{standalone: true}">
                      <ng-template ng-option-tmp let-item="item" let-index="i">
                        <div class="media mb-1">
                          <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                            [src]="item.images['32x32'] ? item.images['32x32'] : globals.companyAvatar"
                            (error)="item.images = globals.companyAvatar" />
                          <div class="media-body">
                            <div class="media-body-title mt-1 mb-0">{{item.name}}, {{item.website_url}}</div>
                            <p *ngIf="item.hq_locations.length"><i 
                                class="tt-icon-sm icon-ttrelocation"></i>
                                <span *ngFor="let location of item.hq_locations;let last = last">
                                  {{location.address}} {{!last ? ' (HQ), ' : ' (HQ)'}}
                                </span>
                            </p>
                          </div>
                        </div>
                      </ng-template>
                      <ng-template ng-tag-tmp let-search="searchTerm">
                        <button
                          class="btn bg-secondary-color btn-position pull-right mb-0" >Add company
                          {{search}}</button>
                      </ng-template>
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-12">
              <div class="card-body-title-basic">
                <label>
                  Logo URL
                </label>
              </div>
              <div class="media mb-3">
                <img class="align-self-center mr-3 height-40 width-40 rounded-circle" [src]="hiringCompany.photo || globals.companyAvatar">
                <div class="media-body">
                  <input type="text" class="form-control" placeholder="Insert logo url"
                      [(ngModel)]="hiringCompany.photo" [ngModelOptions]="{standalone: true}" onFocusOut
                      (focusout)="changed ? editHiringCompany('photo' ,hiringCompany.photo) : ''"
                      (ngModelChange)="onChange($event)">
                </div>
              </div>
            </div>
            <div class="col-md-12 col-12">
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>
                    Linkedin URL
                  </label>
                </div>
                <div class="card-body-content">
                  <input type="text" class="form-control" placeholder="Insert linkedin url"
                  [(ngModel)]="hiringCompany.linkedin_url" [ngModelOptions]="{standalone: true}" onFocusOut
                  (focusout)="changed ? editHiringCompany('linkedin_url' ,hiringCompany.linkedin_url) : ''"
                  (ngModelChange)="onChange($event)">
                </div>
              </div>
            </div>
            <div class="col-md-12 col-12">
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>
                    Dealroom URL
                  </label>
                </div>
                <div class="card-body-content">
                  <input type="text" class="form-control" placeholder="Insert dealroom url"
                  [(ngModel)]="hiringCompany.dealroom_url" [ngModelOptions]="{standalone: true}" onFocusOut
                  (focusout)="changed ? editHiringCompany('dealroom_url' ,hiringCompany.dealroom_url) : ''"
                  (ngModelChange)="onChange($event)">
                </div>
              </div>
            </div>
            <div class="col-md-12 col-12">
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>
                    Website URL
                  </label>
                </div>
                <div class="card-body-content">
                  <input type="text" class="form-control" placeholder="Insert website url"
                  [(ngModel)]="hiringCompany.website_url" [ngModelOptions]="{standalone: true}" onFocusOut
                  (focusout)="changed ? editHiringCompany('website_url' ,hiringCompany.website_url) : ''"
                  (ngModelChange)="onChange($event)">
                </div>
              </div>
            </div>
            <div class="col-md-12 col-12">
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>
                    Company culture description
                  </label>
                </div>
                <div class="card-body-content">
                  <textarea rows="2" class="form-control" type="text" placeholder="Add Comment" 
                    [(ngModel)]="hiringCompany.company_culture_description" [ngModelOptions]="{standalone: true}" onFocusOut
                    (focusout)="changed ? editHiringCompany('company_culture_description' ,hiringCompany.company_culture_description) : ''"
                    (ngModelChange)="onChange($event)">
                  </textarea>
                </div>
              </div>
            </div>
        </div> 
        <div *ngIf="!edit.basic">
          <div class="row card-block">
            <div class="col-12">
              <div class="form-group">
                <div class="media">
                  <img class="align-self-start mr-3 height-50 width-50 rounded-circle" [src]="hiringCompany.photo || globals.companyAvatar">
                  <div class="media-body">
                    <app-dealroom-widget style="text-decoration:underline; font-weight:bold!important" [companyName]="hiringCompany.name"></app-dealroom-widget>
                    <a *ngIf="hiringCompany.linkedin_url" (click)="openUrl(hiringCompany.linkedin_url)">
                      <i class="fa fa-linkedin-square fa-lg p-1"></i>
                    </a>
                    <div class="mt-1">
                      <div>
                        <a *ngIf="hiringCompany.website_url" (click)="openUrl(hiringCompany.website_url)">
                          <i class="fa fa-globe fa-lg p-1"></i>
                        <u>{{hiringCompany.website_url}}</u></a>
                      </div>
                      <div>
                        <a *ngIf="hiringCompany.dealroom_url" (click)="openUrl(hiringCompany.dealroom_url)">
                          <i class="fa fa-building fa-lg p-1"></i>
                          <u>{{hiringCompany.dealroom_url}}</u></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>
                    Company culture description
                  </label>
                </div>
                <div class="card-body-content">
                  {{hiringCompany.company_culture_description  || 'No information available'}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>