import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { GenerateFilterService } from './jobs-filter.service';
import { Subject, concat, of } from 'rxjs';
import { distinctUntilChanged, debounceTime, switchMap, tap, catchError } from 'rxjs/operators';
import { CommunicationService } from 'app/shared/service/communication.service';

@Component({
  selector: 'app-jobs-filter',
  templateUrl: './jobs-filter.component.html',
  styleUrls: ['./jobs-filter.component.scss']
})
export class JobsFilterDrawerComponent implements OnInit{
  @Output() dismissDrawer: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() data: any = {};

  componentAlive = true;

  titles: any = [];

  scordCards: any = [];

  tagInputScordCards: any = [];

  tagInputTitle: any = [];

  companys: any = [];

  companyinput = new Subject<string>();

  companyLoading = false;

  contacts: any = [];

  contact: any = [];

  type: any = [];

  types: any = [];

  statuses: any = [];

  status: any = [];

  priority: any = [];

  prioritys: any = [];

  seniorityes: any = [];

  seniority: any = [];

  location: any = [];

  locationLoading = false;

  locationinput = new Subject<string>();

  workLocations: any = [];

  fee: any;

  sfe: any;

  headCount: any;

  currencyes: any = [];

  bonusTypes: any = [];

  feeType: any = [];

  closedInfoStatus: any = [];

  closedInfoReason: any = [];

  industrys: any = [];

  pods: any = [];

  filters = {
       "title": [],
       "company": [],
       "contact": [],
       "type": [],
       "status": [],
       "priority": [],
       "seniority": [],
       "head_count": null,
       "description": null,
       "location": [],
       "fe": [],
       "sfe": [],
       "industry": [],
       "pod_name": [],
       "compensation": {
           "salary_from": null,
           "salary_to": null,
           "annual_salary": null,
           "bonus": null,
           "bonus_type": null,
           "fee": null,
           "fee_type": null,
           "currency": null,
           "equity": null
       },
       "scorecards": [],
       "closed_info": {
          "status" : null,
          "reason" : null
       },
       "search": [],
       "state": null,
       "sort" : []
      };

  constructor(private GenerateFilterService: GenerateFilterService, private communicationService: CommunicationService){ }

  ngOnInit(){
    this.loadCompany();
    this.loadLocation();
  }

  populateScordCards(val){
    this.tagInputScordCards.push(val);
    this.filters.scorecards = this.tagInputScordCards;
  }

  populateTitle(val){
    this.tagInputTitle.push(val);
    this.filters.title = this.tagInputTitle;
  }

  loadCompany(){
    this.companys = concat(
      of([]),
      this.companyinput.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.companyLoading = true),
        switchMap(companye =>
          this.GenerateFilterService.getCompany(companye).pipe(
            catchError(() => of([])),
            tap(() => {
              this.companyLoading = false;
            })
          )
        )
      )
    );
  }

  loadContacts(){
    this.GenerateFilterService.getContacts()
      .subscribe((val) => {
        this.contacts = val;
      });
  }

  getType(){
    this.GenerateFilterService.getType()
      .subscribe((val) => {
        this.types = val;
      });
  }

  getStatus(){
    this.GenerateFilterService.getStatus()
      .subscribe((val) => {
        this.statuses = val;
      });
  }

  getPriority(){
    this.GenerateFilterService.getPriority()
      .subscribe((val) => {
        this.prioritys = val;
      });
  }

  getSeniority(){
    this.GenerateFilterService.getSeniority()
      .subscribe((val) => {
        this.seniorityes = val;
      });
  }

  loadLocation(){
    this.location = concat(
      of([]),
      this.locationinput.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.locationLoading = true),
        switchMap(location =>
          this.GenerateFilterService.getLocation(location).pipe(
            catchError(() => of([])),
            tap(() => {
              this.locationLoading = false;
            })
          )
        )
      )
    );
  }

  getCurrency(){
    this.GenerateFilterService.getCurrency()
    .subscribe((val) => {
      this.currencyes = val;
    });
  }

  preventNegativeNumbers(e: any){
    if (!((e.keyCode > 95 && e.keyCode < 106)
    || (e.keyCode > 47 && e.keyCode < 58)
    || e.keyCode == 110
    || e.keyCode == 190
    || e.keyCode == 8)){
    return false;
  }
  return true;
}

  searchJobs(){
    this.filters.scorecards = this.tagInputScordCards;
    this.filters.title = this.tagInputTitle;
    this.communicationService.openJobDrawer(this.filters);
    this.dismissDrawer.emit(true);
    this.filters.title = [];
  }

  getbonusTypes(){
    this.GenerateFilterService.getbonusTypes()
      .subscribe((val) => {
        this.bonusTypes = val;
      });
  }

  getFeeType(){
    this.GenerateFilterService.getFeeType()
    .subscribe((val) => {
      this.feeType = val;
    });
  }

  getClosedInfoStatus(){
    this.GenerateFilterService.getClosedInfoStatus()
      .subscribe((val) => {
        this.closedInfoStatus = val;
        this.filters.closed_info.reason = null;
        this.getClosedInfoReason();
      });
  }

  getClosedInfoReason(){
    this.GenerateFilterService.getClosedInfoReason()
    .subscribe((val) => {
      if (this.closedInfoStatus){
        if (this.filters.closed_info.status == "win"){
          this.closedInfoReason = val[0].children;
        } else {
          this.closedInfoReason = val[1].children;
        }
      }
    });
  }

  getFee(){
    this.GenerateFilterService.getFunctionalExpertase()
    .subscribe((val) => {
      this.fee = val;
    });
  }

  getSfe(){
    this.GenerateFilterService.getSubFunctionalExpertase()
    .subscribe((val) => {
      this.sfe = val;
    });
  }

  getIndustry(){
    this.GenerateFilterService.getIndustry()
      .subscribe((val) => {
        this.industrys = val;
      });
  }

  getPods(){
    this.GenerateFilterService.getPods()
      .subscribe((val) => {
        this.pods = val;
      });
  }

  clear(){
    this.filters.closed_info.status = null;
    this.filters.closed_info.reason = null;
   }

   disabledCurrency(){
    if ((!this.filters.compensation.salary_from
     && !this.filters.compensation.salary_to)
     && !this.filters.compensation.annual_salary){
      return true;
     } else {
     return false;
   }
  }

  initializeBody(){
    const body =
    {
    "title": [],
    "company": [],
    "contact": [],
    "type": [],
    "status": [],
    "priority": [],
    "seniority": [],
    "head_count": null,
    "description": null,
    "location": [],
    "fe": [],
    "sfe": [],
    "industry": [],
    "pod_name": [],
    "compensation": {
        "salary_from": null,
        "salary_to": null,
        "annual_salary": null,
        "bonus": null,
        "bonus_type": null,
        "fee": null,
        "fee_type": null,
        "currency": null,
        "equity": null
    },
    "scorecards": [],
    "closed_info": {
        "status": null,
        "reason": null
    },
    "state": null,
    "search": [],
    "page" : 1,
    "sort" : []
  };
  return body;
  }
}
