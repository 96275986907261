import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ViewContactService } from './view-contact.service';
import { Globals } from 'app/globals';
import { Subject, of, concat } from 'rxjs';
import { FileUploader } from 'ng2-file-upload';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { distinctUntilChanged, debounceTime, switchMap, tap, catchError } from 'rxjs/operators';
import { DeleteContactModalComponent } from './delete-contact-modal/delete-contact-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbPanelChangeEvent, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-contact',
  templateUrl: './view-contact.component.html',
  styleUrls: ['./view-contact.component.scss']
})
export class ViewContactComponent implements OnInit, OnChanges{
  @Input() uuid: string;

  activities: any = [];

  loading = false;

  loadingActivity = false;

  contact: any = {};

  openJobs: any = [];

  closeJobs: any = [];

  company: any = [];

  companyLoading = false;

  companyinput = new Subject<string>();

  public addTagCompany: (name) => void;

  uploader: FileUploader;

  changed = false;

  activeIds: string[] = [];

  preventDefault = false;

  edit: any = {
    basic: false
  };

  accordion: any = {
    basic: true,
    jobs: true
  };

  constructor(private viewContactService: ViewContactService, public globals: Globals, private toastr: ToastrService, private router: Router, private modalService: NgbModal, private activatedRoute: ActivatedRoute){
    this.addTagCompany = (name) => this.addCompany(name);
    const URL = environment.platformBackendURL + '/assets';

    this.uploader = new FileUploader({
      url: URL,
      isHTML5: true,
      method: 'POST',
      itemAlias: 'file',
      authTokenHeader: 'authorization',
      authToken: 'Bearer ' + localStorage.getItem('access-token')
    });

    this.uploader.onCompleteItem = (item: any, response: any, status: any) => {
      const obj = JSON.parse(response);
      if (status === 201){
        this.contact.photo = obj.file_url;
        if (this.contact.uuid){
          this.editContact('photo', this.contact.photo, this.contact.uuid);
        }
      } else {
        this.toastr.error(obj.message);
      }
    };
  }

  initEdit(){
    this.edit = {
      basic: false
    };
  }

  initializeProfile(){
    this.activeIds = ['basic', 'jobs'];
  }

  OnContentChange(event){
    if (event.target.value){
      this.uploader.uploadAll();
    }
  }

  ngOnInit(){

  }

  ngOnChanges(){
    this.initEdit();
    this.loadCompany();
    this.getContactData(this.uuid);
    this.activities = [];
    this.getContactActivities(this.uuid);
    this.initializeProfile();
  }

  loadCompany(){
    this.company = concat(
      of([]),
      this.companyinput.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.companyLoading = true),
        switchMap(company =>
          this.viewContactService.getCompany(company).pipe(
            catchError(() => of([])),
            tap(() => {
              this.companyLoading = false;
            })
          )
        )
      )
    );
  }

  addCompany(company){
    return new Promise((resolve) => {
      this.viewContactService.addCompany(company)
        .subscribe((response) => {
          resolve({ uuid: response.uuid, name: company, valid: true });
          this.companyLoading = false;
          this.toastr.success('Successfully added company');
        },
          (err) => {
            this.toastr.error(err.message);
          });
    });
  }

  getContactData(uuid){
    this.loading = true;
    this.viewContactService.getContactData(uuid)
      .subscribe((data) => {
        if (data){
          this.loading = false;
          this.contact = data;
          this.sortJobs(data.jobs);
        }
      });
  }

  sortJobs(jobs){
    this.openJobs = [];
    this.closeJobs = [];
    for (let i = 0; i < jobs.length; i++){
      if (jobs[i].is_open){
        this.openJobs.push(jobs[i]);
      } else {
        this.closeJobs.push(jobs[i]);
      }
    }
  }

  deleteContact(){
    const modalRef = this.modalService.open(DeleteContactModalComponent, { size: 'sm', centered: true, backdrop: 'static' });
    modalRef.result.then((data) => {
      if (data){
        this.viewContactService.deleteContact(this.contact.uuid)
          .subscribe(() => {
            this.router.navigate([],
              {
                relativeTo: this.activatedRoute,
                queryParams: {}
              });
            const element = document.getElementById('contact-drawer');
            element.style.visibility = 'hidden';
          },
          (err) => {
            if (err && err.error){
              if (err.error.uuid){
                this.toastr.error(err.error.uuid[0]);
              } else if (err.error.message){
                this.toastr.error(err.error.message);
              }
            }

        });
      }

    }, () => {
    });
  }

  getContactActivities(uuid){
    this.loadingActivity = true;
    this.viewContactService.getContactActivities(uuid)
      .subscribe((data) => {
        if (data){
          this.loadingActivity = false;
          this.activities = data;
        }
      });
  }

  onChange(){
    this.changed = true;
  }

  editContact(key, value, uuid){
    const body = this.recreateJobObject(key, value);
    this.updateContact(body, uuid);
  }

  recreateJobObject(key, value){
    if (key.indexOf('.') > -1){
      const keyValue = key.substr(0, key.indexOf('.'));
      const obj = {};
      obj[keyValue] = {
        [key.split('.').pop()]: value
      };
      return obj;
    } else {
      return {
        [key]: value
      };
    }
  }

  updateContact(body, uuid){
    this.viewContactService.updateContact(uuid, body)
      .subscribe(() => {
        this.toastr.success('Successfully updated field');
      },
        (err) => {
          if (err.error){
            for (const errProp in err.error){
              if (err.error.hasOwnProperty(errProp)){
                if (err.error[errProp][0]){
                  this.toastr.error(err.error[errProp]);
                }
              }
            }
          } else {
            this.toastr.error('Something went wrong');
          }
        });
    this.changed = false;
  }

  editFields(type){
    this.edit[type] = !this.edit[type];
    this.preventDefault = true;
  }

  public beforeChange($event: NgbPanelChangeEvent){
    $event.preventDefault();
    this.preventDefault = false;
  }
}
