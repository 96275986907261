<nav class="navbar navbar-expand-lg bg-faded p-0">
  <div class="container-fluid">
    <div class="navbar-header full-width">
      <div class="row">
        <div class="col-12 p-0">
          <div class="input-group bg-white">
            <!-- <tag-input [(ngModel)]="searchTerm" (onSelect)="onSelect()" [editable]="true" (onTagEdited)="onTagEdited()"
              (onRemove)="close()" class="form-control position-relative ml-4 pl-4 border-0"
              (onAdd)="prepopulateDataForSearch()" [ngModelOptions]="{standalone: true}" zIndex='true' theme='minimal'
              (onRemove)="navbarEditable()" [animationDuration]="{enter: '0ms', leave: '0ms'}"
              secondaryPlaceholder="Start typing to search">
            </tag-input> -->
            <div class="input-group-append full-width" [ngClass]="{'pr-4': width < 993}">
              <span class="input-group-btn full-width">
                <div class="navbar-container pt-3 pl-0 pull-right" [ngClass]="{'full-width': width < 993}">
                  <div id="navbarSupportedContent" [ngClass]="{'pull-right': width < 993}">
                    <ul class="navbar-nav">
                      <li class="nav-item" ngbDropdown placement="bottom-right" display="dynamic">
                        <a class="nav-link nav-link-menu position-relative" id="dropdownBasic3" ngbDropdownToggle
                          style="display: inline-flex;">
                          <div id="user-icon" data-toggle="tooltip" data-placement="bottom" title="settings">
                            <i class="icon icon-plus"></i>
                          </div>
                        </a>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic3" class="card-shadow notification-dropdown">
                          <a (click)="createConsultant()" class="dropdown-item py-1" href="javascript:;">
                            <i class="ft-edit-3 mr-2"></i>
                            <span>Add consultant</span>
                          </a>
                          <a (click)="createConsultantCompany()" class="dropdown-item py-1" href="javascript:;">
                            <i class="ft-edit-3 mr-2"></i>
                            <span>Add consultant company</span>
                          </a>
                          <a (click)="createHiringCompany()" class="dropdown-item py-1" href="javascript:;">
                            <i class="ft-edit-3 mr-2"></i>
                            <span>Add hiring company</span>
                          </a>
                          <a (click)="createJob()" class="dropdown-item py-1" href="javascript:;">
                            <i class="ft-edit-3 mr-2"></i>
                            <span>Add job</span>
                          </a>
                        </div>
                      </li>
                      <li class="nav-item" ngbDropdown placement="bottom-right" display="dynamic">
                        <a class="nav-link position-relative" id="dropdownBasic3" ngbDropdownToggle
                          style="display: inline-flex;">
                          <div id="user-icon" data-toggle="tooltip" data-placement="bottom" title="settings">
                            <img class="media-object align-self-center d-flex height-25 width-25 rounded-circle"
                              [src]="globals.avatar">
                          </div>
                        </a>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic3" class="card-shadow notification-dropdown">
                          <a (click)="navigateTo('/getnavis/user-profile')" class="dropdown-item py-1" href="javascript:;">
                            <i class="ft-edit-3 mr-2"></i>
                            <span>User profile</span>
                          </a>
                          <a (click)="navigateTo('/getnavis/admin-resources')" class="dropdown-item py-1" href="javascript:;">
                            <i class="ft-settings mr-2"></i>
                            <span>Admin resouces</span>
                          </a>
                          <a (click)="logout()" class="dropdown-item" href="javascript:;">
                            <i class="ft-power mr-2"></i>
                            <span>Logout</span>
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
<nav class="navbar navbar-expand-lg bg-faded z-3 d-inline-block">
  <div class="display-inline-flex">
    <div class="navbar-header">
      <p id="navbar-fullscreen" class="navbar-title mt-3 mb-0 " *ngIf="title" [ngClass]="{'ml-4 pl-3': width >= 992}">
        <span class="cursor-pointer mr-2" title="Click to open job." (click)="openJobDrawer()"
          *ngIf="isJobRoute"><u>{{title}}</u></span>
        <span *ngIf="companyName">
          - {{companyName}}
        </span>
        <span *ngIf="isJobRoute">
          <i class="fa fa-angle-right" aria-hidden="true"></i>
          {{jobRouteTitle}}
        </span>
        <span *ngIf="!isJobRoute" [ngClass]="{'margin-filters-applied' : checkIfFiltersApplied()}">{{title}} {{jobRouteTitleAdditionalInfo ? '(' + jobRouteTitleAdditionalInfo + ')' : ''}}</span>
      </p>
    </div>
  </div>
</nav>
