import { mergeMap, map, filter, takeWhile } from 'rxjs/operators';
import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgbModal, NgbDropdown, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { UserDataService } from '../service/userData.service';
import { Globals } from '../../globals';
import { CommunicationService } from '../service/communication.service';
import toolTipData from './navbar.tooltip.json';
import { NavbarService } from './navbar.service';
import { ToastrService } from 'ngx-toastr';

declare let hopscotch: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnDestroy, OnInit{

  throttle = 300;

  scrollDistance = 4;

  scrollUpDistance = 4;

  componentAlive = true;

  closeResult: string;

  view_as = false;

  read_user = false;

  title: string;

  jobTitle: string;

  isJobRoute = false;

  jobRouteTitle = '';

  currentJobTitle = '';

  response: any = [];

  originalTemplates: any = [];

  globalSearchCandidatesNameData: any = [];

  globalSearchContactData: any = [];

  globalSearchGLobalData: any = [];

  globalSearchCompaniesData: any = [];

  globalSearchJobsData: any = [];

  globalSearchTotalJobs: any = 0;

  globalSearchTotalCompanyes: any = 0;

  globalSearchTotalContacts: any = [];

  globalSearchCounterForInfinityScroll = 0;

  infiniteScrollIndicatorFlag = false;

  // search templates
  prevSearchValue = null;

  @ViewChild(NgbDropdown) myDrop1;

  notifications: any = [];

  unreadNotification: number;

  width: number;

  ngTemplateForSeacrhFlag = false;

  loading = false;

  searchTerm: any = [];

  loadingFlag = false;

  loadingURL = 'assets/img/oval.svg';

  oldSearchTerm: string;

  type = 'candidates';

  totalCountData: any = [];

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private userData: UserDataService,
    public globals: Globals,
    private communicationService: CommunicationService,
    private toastr: ToastrService,
    private navbarService: NavbarService,
    private cdr: ChangeDetectorRef
  ){
    this.communicationService.onJobSelected$.pipe(
      takeWhile(() => this.componentAlive))
      .subscribe((job) => {
        this.currentJobTitle = job.job_title;
        this.isJobRoute = true;
        this.jobRouteTitle = this.jobRouteTitle === '' ? this.title : this.jobRouteTitle;
        this.title = job.job_title;
        this.close();
      });

    this.communicationService.onNotificationSelected$.pipe(takeWhile(() => this.componentAlive))
      .subscribe((notificationCounter) => {
        notificationCounter ? this.unreadNotification = 0 : this.unreadNotification -= 1;
      });
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => activatedRoute),
      map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data))
      .subscribe((event) => {
        this.close();
        const routeSegments = this.router.parseUrl(this.router.url).root.children.primary.segments[0].toString();
        hopscotch ? hopscotch.endTour(true) : '';
        if (routeSegments === 'job' && this.router.parseUrl(this.router.url).root.children.primary.segments[2]){
          this.isJobRoute = true;
          this.title = this.currentJobTitle;
          this.jobRouteTitle = event.title;
        } else {
          this.isJobRoute = false;
          this.title = event.title;
        }
        const aside = document.getElementsByTagName('aside');
        if (document.getElementsByTagName('aside')){
          for (let i = 0; i < aside.length; i++){
            if (aside[i].getAttribute('id') == 'filter-drawer'){
              aside[i].style.visibility = "hidden";
            }
          }
        }
      });
  }

  ngOnInit(){
    this.width = document.body.clientWidth;
    if (this.width >= 993){
      if (localStorage.getItem('first-login') == 'true'){
        setTimeout(() => {
          hopscotch.startTour(toolTipData.overview);
        }, 1000);
        localStorage.setItem('first-login', 'false');
      }
    }
    this.getNotification();

  }

  // navigation through components
  navigateTo(value){
    this.router.navigate([value]);
  }

  readAllNotification(){
    this.navbarService.readAllNotification()
      .subscribe(
        () => {
          this.emitToNotification('read_all');
          this.unreadNotification = 0;
        }
      );
  }

  // log out from platform
  logout(){
    this.authService.logout();
  }

  ngOnDestroy(){
    this.componentAlive = false;
  }

  startTour(){
    const routeSegments = this.router.parseUrl(this.router.url).root.children.primary.segments[0].toString();
    switch (routeSegments){
      case 'overview': {
        hopscotch.startTour(toolTipData.overview);
        break;
      }
      case 'user-profile': {
        hopscotch.startTour(toolTipData.users);
        break;
      }
      case 'admin': {
        hopscotch.startTour(toolTipData.admin);
        break;
      }
      default: {}
    }
    if (routeSegments === 'job' && this.router.parseUrl(this.router.url).root.children.primary.segments[2]){
      switch (this.router.parseUrl(this.router.url).root.children.primary.segments[2].toString()){
        case 'overview': {
          hopscotch.startTour(toolTipData['job-overview']);
          break;
        }
        case 'calibration-list': {
          hopscotch.startTour(toolTipData.calibration);
          break;
        }
        case 'cit': {
          hopscotch.startTour(toolTipData.cit);
          break;
        }
        case 'candidates': {
          hopscotch.startTour(toolTipData.candidates);
          break;
        }
        default: {}
      }
    }

  }

  notificationAction(notification, i){
    if (!this.notifications[i].is_read){
      this.unreadNotification -= 1;
      this.emitToNotification(notification.uuid);
    }
    this.notifications[i].is_read = 1;
    this.navbarService.markAsRead(notification.uuid).subscribe();
    if (notification.navigation_url){
      if (notification.is_route){
        window.open(notification.navigation_url);
      } else {
        const params = JSON.parse('{"' + decodeURI(notification.navigation_url.replace(/\?/g, '')).replace(/"/g, '\\"')
.replace(/&/g, '","')
.replace(/=/g, '":"') + '"}');
        this.router.navigate([],
          {
            relativeTo: this.activatedRoute,
            queryParams: params
          });
      }
    }
  }

  getNotification(){
    this.navbarService.getNotifications()
      .subscribe(
        (data) => {
          this.notifications = data.events;
          this.unreadNotification = data.eventsCount;
        }
      );
  }

  emitToNotification(notification: any){
    this.communicationService.emitToNotification(notification);
  }

  // Open default modal
  open(content, state){
    if (state){
      const ngbModalOptions: NgbModalOptions = {
        centered: true,
        size: 'md' as 'lg'
      };
      this.modalService.open(content, ngbModalOptions);
    }
  }

  closeJob(){
    this.router.navigate([],
      {
        relativeTo: this.activatedRoute,
        queryParams: { 'drawer_parent': 'job-drawer', 'type': 'close-job', 'job_uuid': this.router.parseUrl(this.router.url).root.children.primary.segments[1].toString() }
      });
  }

  createCandidate(){
    this.addQueryParamsRoute('add-data-drawer', 'add-candidate');
  }

  createJob(){
    this.addQueryParamsRoute('add-data-drawer', 'add-job');
  }

  createActivity(){
    this.addQueryParamsRoute('add-data-drawer', 'add-activity');
  }

  createRevenue(){
    this.addQueryParamsRoute('add-data-drawer', 'add-revenue');
  }

  createContact(){
    this.addQueryParamsRoute('add-data-drawer', 'add-contact');
  }

  addQueryParamsRoute(drawer_parent, type){
    this.router.navigate([],
      {
        relativeTo: this.activatedRoute,
        queryParams: { 'drawer_parent': drawer_parent, 'type': type }
      });
  }

  openCandidate(uuid){

    this.router.navigate([],
      {
        relativeTo: this.activatedRoute,
        queryParams: {
          'drawer_parent': 'candidate-drawer',
'type': 'view-candidate',
          'job_uuid': null,
          'candidate_uuid': uuid
        }

      });
  }

  prevent(event, url){
    window.open(url, '_blank');
    event.stopPropagation();
  }

  openContact(uuid){
    this.router.navigate([],
      {
        relativeTo: this.activatedRoute,
        queryParams: {
          'drawer_parent': 'contact-drawer',
'type': 'view-contact',
          'contact_uuid': uuid
        }
      });
  }

  openCompany(uuid){
    this.router.navigate([],
      {
        relativeTo: this.activatedRoute,
        queryParams: {
          'drawer_parent': 'company-drawer',
'type': 'company-activity',
          'company_uuid': uuid
        }
      });
  }

  openJob(uuid){
    this.router.navigate([],
      {
        relativeTo: this.activatedRoute,
        queryParams: { 'drawer_parent': 'job-drawer', 'type': 'view-job', 'job_uuid': uuid }
      });
  }

  openJobDrawer(){
    const routeSegments = this.router.parseUrl(this.router.url).root.children.primary.segments[1].toString();
    this.router.navigate([],
      {
        relativeTo: this.activatedRoute,
        queryParams: { 'drawer_parent': 'job-drawer', 'type': 'view-job', 'job_uuid': routeSegments }
      });
  }

  checkNgTemplateForSeacrhFlag(){
    if (this.searchTerm.length == 0){
      return false;
    } else {
      return true;
    }
  }

  onSelect(){
    this.ngTemplateForSeacrhFlag = true;
    this.loadingFlag = false;
  }

  clear(){

  }

  onTagEdited(item){
    this.searchTerm = [];
    this.searchTerm.push(item);
    this.prepopulateDataForSearch();
  }

  navbarEditable(){
    const container = document.getElementsByClassName('ng2-tag-input__text-input') as HTMLCollectionOf<HTMLElement>;
    if (container.length != 0){
      container[0].style.display = 'block';
    }
  }

  close(){
    this.ngTemplateForSeacrhFlag = false;
    this.clear();
    this.type = 'candidates';
  }

  prepopulateDataForSearch(){
    if (this.searchTerm.length == 1){
      const container = document.getElementsByClassName('ng2-tag-input__text-input') as HTMLCollectionOf<HTMLElement>;
      if (container.length != 0){
        container[0].style.display = 'none';
      }
    }

    this.clear();
    this.loadingFlag = true;
    this.navbarService.getDataForGlobalSearch(this.searchTerm[0].value, this.type, 1)
      .subscribe((data) => {
        this.globalSearchCandidatesNameData = [];
        this.globalSearchContactData = [];
        this.globalSearchCompaniesData = [];
        this.globalSearchJobsData = [];
        this.ngTemplateForSeacrhFlag = true;
        if (data){ this.loadingFlag = false; }
        this.globalSearchCandidatesNameData = data;
      });
    this.navbarService.getCountEverything(this.searchTerm[0].value)
      .subscribe((data) => {
        this.totalCountData = [];
        this.totalCountData = data;
      });
  }

  dynamicType(event){
    event.activeId = event.nextId;
    if (event.activeId == "ngb-tab-0") this.type = 'candidates';
    else if (event.nextId == "ngb-tab-1") this.type = 'contacts';
    else if (event.nextId == "ngb-tab-2") this.type = 'companies';
    else if (event.nextId == "ngb-tab-3") this.type = 'jobs';
    this.globalSearchCandidatesNameData.current_page = 1;

    this.navbarService.getDataForGlobalSearch(this.searchTerm[0].value, this.type, this.globalSearchCandidatesNameData.current_page)
      .subscribe((data) => {
        this.ngTemplateForSeacrhFlag = true;
        if (data){
          this.loadingFlag = false;
          switch (event.nextId){
            case "ngb-tab-0": {
              this.globalSearchCandidatesNameData = data;
              break;
            }
            case "ngb-tab-1": {
              this.globalSearchContactData = data;
              break;
            }
            case "ngb-tab-2": {
              this.globalSearchCompaniesData = data;
              break;
            }
            case "ngb-tab-3": {
              this.globalSearchJobsData = data;
              break;
            }
            default: {}
          }
        }
      },
        () => {
          this.loadingFlag = true;
        });
  }

  onScrollDown(){
    this.infiniteScrollIndicatorFlag = true;
    let next_page = 0;
    switch (this.type){
      case 'companies': {
        this.globalSearchCompaniesData.current_page < this.globalSearchCompaniesData.last_page ? next_page = ++this.globalSearchCompaniesData.current_page : next_page = 0;
        break;
      }
      case 'jobs': {
        next_page = this.globalSearchJobsData.current_page < this.globalSearchJobsData.last_page ? next_page = ++this.globalSearchJobsData.current_page : next_page = 0;
        break;
      }
      case 'contacts': {
        next_page = this.globalSearchContactData.current_page < this.globalSearchContactData.last_page ? next_page = ++this.globalSearchContactData.current_page : next_page = 0;
        break;
      }
      case 'candidates': {
        next_page = this.globalSearchCandidatesNameData.current_page < this.globalSearchCandidatesNameData.last_page ? next_page = ++this.globalSearchCandidatesNameData.current_page : next_page = 0;
        break;
      }
      default: {
        break;
      }
    }
    if (next_page){
      this.navbarService.getDataForGlobalSearch(this.searchTerm[0].value, this.type, next_page)
      .subscribe((data) => {
        this.infiniteScrollIndicatorFlag = false;
        switch (this.type){
          case 'companies': {
            this.insertNewDataIntoArray(data, this.globalSearchCompaniesData);
            break;
          }
          case 'jobs': {
            this.insertNewDataIntoArray(data, this.globalSearchJobsData);
            break;
          }
          case 'contacts': {
            this.insertNewDataIntoArray(data, this.globalSearchContactData);
            break;
          }
          case 'candidates': {
            this.insertNewDataIntoArray(data, this.globalSearchCandidatesNameData);
            break;
          }
          default:
            break;
        }
        () => {
          this.infiniteScrollIndicatorFlag = false;
        };
      });
    } else {
      this.infiniteScrollIndicatorFlag = false;
    }
  }

  insertNewDataIntoArray(newData, oldData){
    oldData.current_page = newData.current_page;
    oldData.first_page_url = newData.first_page_url;
    oldData.from = newData.from;
    oldData.last_page = newData.last_page;
    oldData.last_page_url = newData.last_page_url;
    oldData.next_page_url = newData.next_page_url;
    oldData.path = newData.path;
    oldData.per_page = newData.per_page;
    oldData.prev_page_url = newData.prev_page_url;
    oldData.to = newData.to;
    oldData.total = newData.total;
    Array.prototype.push.apply(oldData.data, newData.data);
  }

}
