import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class GetNavisBlacklistCandidateModalService{

  constructor(private http: HttpClient){ }

  blacklistCandidate(data: any): Observable<any>{
    const url = environment.getNavisBackendURL + '/merger/candidates/blacklist';
    return this.http.post(url, data);
  }

  getJobs(): Observable<any>{
    const url = environment.getNavisBackendURL + '/resources/jobs?type=open';
    return this.http.get(url);
}
}
