<div class="modal-content">
    <div class="modal-bg">
      <div class="modal-header">
        <div class="modal-header-title">
          Change step trigger type
        </div>
        <button type="button" aria-label="Close" class="close pull-right" (click)="activeModal.dismiss()">
            <span aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Close">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="form-group">
              <div class="modal-body-title">
                *
                Trigger type
                <span *ngIf="!validForm && !step.trigger_type" [ngClass]="{'primary-color': !step.trigger_type}">
                  (Please fill in this field)
                </span>
              </div>
              <div class="modal-body-content">
                <ng-select bindLabel="description" dropdownPosition="bottom"
                  (ngModelChange)="this.show.stepUpdate = true"
                  (open)="getTriggers(triggers.triggerType);getTriggers(triggers.triggerSubType)"
                  placeholder="Select trigger type"
                  (ngModelChange)="step.trigger_type = null;step.time = {};step.trigger_time=null"
                  [items]="resources.triggerType" [(ngModel)]="step.trigger_type"
                  (clear)="step.trigger_type = null;step.time = {};step.trigger_time=null"
                  [ngModelOptions]="{standalone: true}">
                </ng-select>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6" *ngIf="step.trigger_type && 'daily_at' == step.trigger_type.value">
            <div class="form-group">
              <div class="modal-body-content timepicker-position">
                <ngb-timepicker [(ngModel)]="step.time" (ngModelChange)="transformTime(step)">
                </ngb-timepicker>
                <span *ngIf="!validForm && !step.trigger_time" [ngClass]="{'primary-color': !step.trigger_time}">
                  <strong>(Please fill in this field)</strong>
                </span>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6" *ngIf="step.trigger_type && 'after_days' == step.trigger_type.value">
            <div class="modal-body-title">
              *
              After days
              <span *ngIf="!validForm && !step.trigger_time" [ngClass]="{'primary-color': !step.trigger_time}">
                (Please fill in this field)
              </span>
              <span *ngIf="!validForm && (step.trigger_time && step.trigger_time<1)"
                [ngClass]="{'primary-color': step.trigger_time && step.trigger_time<1}">
                (Days cannot be lower than 1)
              </span>
            </div>
            <div class="form-group">
              <div class="modal-body-content">
                <input type="number" class="form-control" placeholder="Insert after days"
                  (ngModelChange)="this.show.stepUpdate = true" min=1 [(ngModel)]="step.trigger_time"
                  [ngModelOptions]="{standalone: true}">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" *ngIf="show.stepUpdate" class="col-md-3 btn btn-block bg-secondary-color"
          (click)="changeTriggerType()">
          Change</button>
        <button type="button" class="col-md-3 btn btn-block bg-primary-color"
          (click)="activeModal.dismiss()">Close</button>
      </div>
    </div>
  </div>