import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Angulartics2 } from 'angulartics2';

@Injectable()
export class CloseJobService{

  constructor(private angulartics2: Angulartics2, private http: HttpClient){ }

  getResources(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources?type=closing_role_type,currencies');
  }

  closeJob(uuid, body): Observable<any>{
    this.angulartics2.eventTrack.next({
      action: 'Closed job',
      properties: { uuid, body }
    });

    return this.http.post(environment.platformBackendURL + '/jobs/close/' + uuid, body);
  }
}
