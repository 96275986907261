import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html'
})
export class ConfirmationModalComponent{

  modalHeader = '';

  modalDescription = '';

  cancelButton = 'Cancel';

  confirmButton = 'Confirm';

  constructor(public activeModal: NgbActiveModal){}
}