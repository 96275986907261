<div class="full-width scroll scroll-filter mt-5">
  <div class="card-block">
    <ngb-accordion id="filter-accordion" #acc="ngbAccordion" [(activeIds)]="activeId"
      (panelChange)="saveAccordionsState($event)">
      <ngb-panel id="company" >
        <ng-template ngbPanelHeader>
          <div class="form" (click)="acc.toggle('company')">
            <div class="form-section">
              Company level
              <i class="ft-filter tt-icon-sm"></i>
            </div>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="row card-block">
            <div class="col-md-4 col-12 append-div-companies" *ngFor="let category of categories; let i = index">
              <div class="form-group">
                <div class="card-body-title-basic">
                  <label>
                    {{category.value | removeUndersoreCapitalizeFirst }}
                  </label>
                </div>
                <div class="card-body-content" *ngIf="category.value != 'tags' && category.value != 'hq_locations'">
                  <ng-select appendTo=".append-div-companies" [multiple]="true" [items]="category.data"
                    bindLabel="name" (open)="getFiltersperCategory(category.value,i)" placeholder="Select"
                    [(ngModel)]="filters.company_filters[category.value]"></ng-select>
                </div>
                <div class="card-body-content" *ngIf="category.value == 'tags'">
                  <ng-select appendTo=".append-div-companies" [items]="tags" bindLabel="name" [hideSelected]="true"
                    [loading]="tagsLoading" [typeahead]="tagsinput"
                    [(ngModel)]="filters.company_filters[category.value]" [multiple]="true" placeholder="Select">
                  </ng-select>
                </div>
                <div class="card-body-content" *ngIf="category.value == 'hq_locations'">
                  <ng-select appendTo=".append-div-companies" [items]="hqLocation" bindLabel="name"
                    [hideSelected]="true" [loading]="hqLocationLoading" [typeahead]="hqlocationinput" [multiple]="true"
                    [(ngModel)]="filters.company_filters[category.value]" placeholder="Select">
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="candidate">
        <ng-template ngbPanelHeader>
          <div class="form" (click)="acc.toggle('candidate')">
            <div class="form-section">
              Candidate level
              <i class="ft-filter tt-icon-sm"></i>
            </div>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="row">
            <div class="col-12 p-0">
              <ngb-accordion id="demo-accordion" #subAcc="ngbAccordion" [(activeIds)]="activeIds"
                #accLevel="ngbAccordion" (panelChange)="saveSubAccordionsState($event)">
                <ngb-panel id="gender">
                  <ng-template ngbPanelHeader>
                    <div class="secondary-color mb-2"
                      (click)="accLevel.toggle('gender');filters.accordion.gender = !filters.accordion.gender">
                      Gender
                      <i class="pull-right p-1"
                        [ngClass]="{'ft-chevron-right': !filters.accordion.gender,'ft-chevron-down': filters.accordion.gender}"></i>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="row card-block">
                      <div class="col-md-4 col-12 append-div-gender">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Gender:
                            </label>
                          </div>
                          <div class="card-body-content">
                            <ng-select appendTo=".append-div-gender" bindLabel="description"
                              dropdownPosition="bottom" placeholder="Select gender" [items]="gender"
                              (clear)="clearGender()" [(ngModel)]="filters.candidates_filters.gender"
                              [ngModelOptions]="{standalone: true}">
                            </ng-select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="vetted">
                  <ng-template ngbPanelHeader>
                    <div class="secondary-color mb-2"
                      (click)="accLevel.toggle('vetted');filters.accordion.vetted = !filters.accordion.vetted">
                      Vetted
                      <i class="pull-right p-1"
                        [ngClass]="{'ft-chevron-right': !filters.accordion.vetted,'ft-chevron-down': filters.accordion.vetted}"></i>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="row card-block">
                      <div class="col-md-4 col-12">
                        <div class="form-group">
                          <div class="card-body-content display-inline-flex">
                            <div class="custom-control custom-radio custom-control-inline">
                              <input type="radio" [(ngModel)]="filters.candidates_filters.is_vetted" name="vetted"
                                id="vetted_all" [value]=null class="custom-control-input">
                              <label class="custom-control-label" for="vetted_all">All</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                              <input type="radio" [(ngModel)]="filters.candidates_filters.is_vetted" name="vetted"
                                id="vetted_yes" [value]=true class="custom-control-input">
                              <label class="custom-control-label" for="vetted_yes">Yes</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                              <input type="radio" [(ngModel)]="filters.candidates_filters.is_vetted" name="vetted"
                                id="vetted_no" [value]=false class="custom-control-input">
                              <label class="custom-control-label" for="vetted_no">No</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="in_process">
                  <ng-template ngbPanelHeader>
                    <div class="secondary-color mb-2"
                      (click)="accLevel.toggle('in_process');filters.accordion.in_process = !filters.accordion.in_process">
                      In process
                      <i class="pull-right p-1"
                        [ngClass]="{'ft-chevron-right': !filters.accordion.in_process,'ft-chevron-down': filters.accordion.in_process}"></i>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="row card-block">
                      <div class="col-md-4 col-12">
                        <div class="form-group">
                          <div class="card-body-content display-inline-flex">
                            <div class="custom-control custom-radio custom-control-inline">
                              <input type="radio" [(ngModel)]="filters.candidates_filters.in_other_process"
                                name="in_process" id="in_process_all" [value]=null class="custom-control-input">
                              <label class="custom-control-label" for="in_process_all">All</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                              <input type="radio" [(ngModel)]="filters.candidates_filters.in_other_process"
                                name="in_process" id="in_process_yes" [value]=true class="custom-control-input">
                              <label class="custom-control-label" for="in_process_yes">Yes</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                              <input type="radio" [(ngModel)]="filters.candidates_filters.in_other_process"
                                name="in_process" id="in_process_no" [value]=false class="custom-control-input">
                              <label class="custom-control-label" for="in_process_no">No</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="location">
                  <ng-template ngbPanelHeader>
                    <div class="secondary-color mb-2"
                      (click)="accLevel.toggle('location');filters.accordion.location = !filters.accordion.location">
                      Candidate location
                      <i class="pull-right p-1"
                        [ngClass]="{'ft-chevron-right': !filters.accordion.location,'ft-chevron-down': filters.accordion.location}"></i>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="row card-block">
                      <div class="col-md-8 col-12 append-div-location">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Location
                            </label>
                          </div>
                          <div class="card-body-content">
                            <ng-select [multiple]="true" appendTo=".append-div-location" [items]="locations$ | async"
                              bindLabel="description" placeholder="Select location" dropdownPosition="bottom"
                              [minTermLength]="minLengthTerm" [loading]="locationLoading"
                              typeToSearchText="Please enter {{minLengthTerm}} or more characters"
                              [typeahead]="locationInput$" [(ngModel)]="filters.candidates_filters.candidate_location"
                              [ngModelOptions]="{standalone: true}">
                            </ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              International exposure
                            </label>
                          </div>
                          <div class="card-body-content">
                            <div class="row card-block">
                              <div class="col-12">
                                <div class="form-group">
                                  <div class="card-body-content display-inline-flex">
                                    <div class="custom-control custom-radio custom-control-inline">
                                      <input type="radio"
                                        [(ngModel)]="filters.candidates_filters.international_exposure"
                                        name="international_exposure" id="international_exposure_all" [value]=null
                                        class="custom-control-input">
                                      <label class="custom-control-label" for="international_exposure_all">All</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                      <input type="radio"
                                        [(ngModel)]="filters.candidates_filters.international_exposure"
                                        name="international_exposure" id="international_exposure_yes" [value]=true
                                        class="custom-control-input">
                                      <label class="custom-control-label" for="international_exposure_yes">Yes</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                      <input type="radio"
                                        [(ngModel)]="filters.candidates_filters.international_exposure"
                                        name="international_exposure" id="international_exposure_no" [value]=false
                                        class="custom-control-input">
                                      <label class="custom-control-label" for="international_exposure_no">No</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="experience">
                  <ng-template ngbPanelHeader>
                    <div class="secondary-color mb-2"
                      (click)="accLevel.toggle('experience');filters.accordion.experience = !filters.accordion.experience">
                      Experience
                      <i class="pull-right p-1"
                        [ngClass]="{'ft-chevron-right': !filters.accordion.experience,'ft-chevron-down': filters.accordion.experience}"></i>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="card-block">
                      <div class="row">
                        <div class="col-md-4 col-12 append-div-experiences">
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>Experience state
                              </label>
                            </div>
                            <div class="card-body-content">
                              <ng-select appendTo=".append-div-experiences" bindLabel="description"
                                dropdownPosition="bottom" (open)="getState()" placeholder="Select state"
                                [items]="dropdownResources.state" [(ngModel)]="experience.state"
                                [ngModelOptions]="{standalone: true}">
                              </ng-select>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div class="row">
                        <div class="col-md-4 col-12">
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>{{globals.labels.placeholders.shared_page.add_job.fe}}
                              </label>
                            </div>
                            <div class="card-body-content">
                              <ng-select appendTo=".append-div-experiences" bindLabel="description"
                                dropdownPosition="bottom"
                                [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.fe"
                                [items]="dropdownResources.fe" [(ngModel)]="experience.fe"
                                [ngModelOptions]="{standalone: true}" (open)="getFe()"
                                (clear)="experience.fe=null;experience.sfe=[]">
                              </ng-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 col-12">
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>{{globals.labels.placeholders.shared_page.add_job.sfe}}
                              </label>
                            </div>
                            <div class="card-body-content">
                              <ng-select appendTo=".append-div-experiences" bindLabel="description"
                                dropdownPosition="bottom" [disabled]="!experience.fe"
                                [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.sfe"
                                [items]="dropdownResources.filteredSfe" [(ngModel)]="experience.sfe" [multiple]="true"
                                [ngModelOptions]="{standalone: true}" (open)="filterSfe(experience.fe.id)">
                              </ng-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 col-12">
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>{{globals.labels.placeholders.shared_page.add_job.seniority}}
                              </label>
                            </div>
                            <div class="card-body-content">
                              <ng-select appendTo=".append-div-experiences" bindLabel="description"
                                dropdownPosition="bottom" (open)="getSeniority()"
                                [placeholder]="globals.labels.placeholders.inputs.shared_page.add_job.seniority"
                                [items]="dropdownResources.seniority" [(ngModel)]="experience.seniority"
                                [ngModelOptions]="{standalone: true}">
                              </ng-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-8">
                          <div class="form-group">
                            <div class="row">
                              <div class="col-12">
                                <div class="form-group">
                                  <div class="card-body-title-basic">
                                    <label>
                                      Location
                                    </label>
                                  </div>
                                  <div class="card-body-content">
                                    <ng-select [multiple]="true" appendTo=".append-div-experiences"
                                      [items]="locations$ | async" bindLabel="description" placeholder="Select location"
                                      dropdownPosition="bottom" [minTermLength]="minLengthTerm"
                                      [loading]="locationLoading"
                                      typeToSearchText="Please enter {{minLengthTerm}} or more characters"
                                      [typeahead]="locationInput$" [(ngModel)]="expirienceLocation"
                                      [ngModelOptions]="{standalone: true}"
                                      (change)="populateExpirienceLocation($event)">
                                    </ng-select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 col-12">
                          <div class="form-group mt-4 pt-3">
                            <div class="card-body-content">
                              <a class="card-body-title-basic secondary-color" (click)="this.addExperience(experience)">
                                <i class=" icon icon-plus"></i>
                                add desired experience
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4 col-12" *ngIf="filters.candidates_filters.experiences.current.length">
                          <div class="form">
                            <div class="form-section">
                              Current experience
                            </div>
                          </div>
                          <div class="form-group"
                            *ngFor="let exp of filters.candidates_filters.experiences.current;let i = index">
                            <div class="card card-preview">
                              <a (click)="deleteExperience('current', i)">
                                <i class="tt-icon-sm icon-ttdelete pull-right" data-toggle="tooltip"
                                  data-placement="bottom" title="Delete experience"></i>
                              </a>
                              <div class="form-group" *ngIf="exp.fe">
                                <strong> Fe: </strong> {{exp.fe.description}}
                              </div>
                              <div class="form-group" *ngIf="exp.sfe.length">
                                <strong> Sfe: </strong>
                                <span *ngFor="let sfe of exp.sfe;let last = last">
                                  {{sfe.description}}{{!last? ', ': ' '}}
                                </span>
                              </div>
                              <div class="form-group" *ngIf="exp.seniority">
                                <strong> Seniority: </strong> {{exp.seniority.description}}
                              </div>
                              <div class="form-group" *ngIf="exp.location.length">
                                <strong> Work location: </strong>
                                <span *ngFor="let location of exp.location;let last = last">
                                  {{location.description}}{{!last? '; ': ' '}}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 col-12" *ngIf="filters.candidates_filters.experiences.past.length">
                          <div class="form">
                            <div class="form-section">
                              Past experience
                            </div>
                          </div>
                          <div class="form-group"
                            *ngFor="let exp of filters.candidates_filters.experiences.past;let i = index">
                            <div class="card card-preview">
                              <a (click)="deleteExperience('past', i)">
                                <i class="tt-icon-sm icon-ttdelete pull-right" data-toggle="tooltip"
                                  data-placement="bottom" title="Delete experience"></i>
                              </a>
                              <div class="form-group" *ngIf="exp.fe">
                                <strong> Fe: </strong> {{exp.fe.description}}
                              </div>
                              <div class="form-group" *ngIf="exp.sfe.length">
                                <strong> Sfe: </strong>
                                <span *ngFor="let sfe of exp.sfe;let last = last">
                                  {{sfe.description}}{{!last? ', ': ' '}}
                                </span>
                              </div>
                              <div class="form-group" *ngIf="exp.seniority">
                                <strong> Seniority: </strong> {{exp.seniority.description}}
                              </div>
                              <div class="form-group" *ngIf="exp.location.length">
                                <strong> Work location: </strong>
                                <span *ngFor="let location of exp.location;let last = last">
                                  {{location.description}}{{!last? '; ': ' '}}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 col-12" *ngIf="filters.candidates_filters.experiences.all.length">
                          <div class="form">
                            <div class="form-section">
                              Current/Past experience
                            </div>
                          </div>
                          <div class="form-group"
                            *ngFor="let exp of filters.candidates_filters.experiences.all;let i = index">
                            <div class="card card-preview">
                              <a (click)="deleteExperience('all', i)">
                                <i class="tt-icon-sm icon-ttdelete pull-right" data-toggle="tooltip"
                                  data-placement="bottom" title="Delete experience"></i>
                              </a>
                              <div class="form-group" *ngIf="exp.fe">
                                <strong> Fe: </strong> {{exp.fe.description}}
                              </div>
                              <div class="form-group" *ngIf="exp.sfe.length">
                                <strong> Sfe: </strong>
                                <span *ngFor="let sfe of exp.sfe;let last = last">
                                  {{sfe.description}}{{!last? ', ': ' '}}
                                </span>
                              </div>
                              <div class="form-group" *ngIf="exp.seniority">
                                <strong> Seniority: </strong> {{exp.seniority.description}}
                              </div>
                              <div class="form-group" *ngIf="exp.location.length">
                                <strong> Work location: </strong>
                                <span *ngFor="let location of exp.location;let last = last">
                                  {{location.description}}{{!last? '; ': ' '}}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="yearsExp">
                  <ng-template ngbPanelHeader>
                    <div class="secondary-color mb-2"
                      (click)="accLevel.toggle('yearsExp');filters.accordion.yearsExperience = !filters.accordion.yearsExperience">

                      Years of experience (range)
                      <i class="pull-right p-1"
                        [ngClass]="{'ft-chevron-right': !filters.accordion.yearsExperience,'ft-chevron-down': filters.accordion.yearsExperience}"></i>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="row card-block">
                      <div class="col-md-4 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                          </div>
                          <div class="card-body-content">
                            <input type="text" pattern="[0-9]*" (keydown)=preventNegativeNumbers($event)
                              class="form-control" placeholder="Insert from year(s)"
                              [(ngModel)]="filters.candidates_filters.years_of_experience.from" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                          </div>
                          <div class="card-body-content">
                            <input type="text" pattern="[0-9]*" (keydown)=preventNegativeNumbers($event)
                              class="form-control" placeholder="Insert to year(s)"
                              [(ngModel)]="filters.candidates_filters.years_of_experience.to" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="language">
                  <ng-template ngbPanelHeader>
                    <div class="secondary-color mb-2"
                      (click)="accLevel.toggle('language');filters.accordion.language = !filters.accordion.language">
                      Languages
                      <i class="pull-right p-1"
                        [ngClass]="{'ft-chevron-right': !filters.accordion.language,'ft-chevron-down': filters.accordion.language}"></i>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="row card-block">
                      <div class="col-md-4 col-12 append-div-langugages">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Language:
                            </label>
                          </div>
                          <div class="card-body-content">
                            <ng-select [hideSelected]="true" appendTo=".append-div-langugages"
                              [items]="languages$ | async" bindLabel="description" placeholder="Select language"
                              dropdownPosition="bottom" [minTermLength]="minLengthTerm" [loading]="languageLoading"
                              typeToSearchText="Please enter {{minLengthTerm}} or more characters"
                              [typeahead]="languageInput$" [(ngModel)]="language.language">
                            </ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Level:
                            </label>
                          </div>
                          <div class="card-body-content">
                            <ng-select appendTo=".append-div-langugages" bindLabel="description"
                              dropdownPosition="bottom" (open)="getLanguagesLevel()" placeholder="Select level"
                              [items]="dropdownResources.languagesLevel" [(ngModel)]="language.level"
                              [ngModelOptions]="{standalone: true}">
                            </ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-12">
                        <div class="form-group mt-4 pt-3">
                          <div class="card-body-content">
                            <a class="card-body-title-basic secondary-color"
                              (click)="addLanguage(language.language, language.level)">
                              <i class="icon icon-plus"></i>
                              add desired language
                            </a>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="filters.candidates_filters.languages.length" class="col-12">
                        <div class="form">
                          <div class="form-section">
                            Choosen languages
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="card card-preview">
                            <div class="row">
                              <div class="col-md-4 col-12">
                                <div class="card-body-title-basic">
                                  <label>
                                    Language:
                                  </label>
                                </div>
                              </div>
                              <div class="col-md-8 col-12">
                                <div class="card-body-title-basic">
                                  <label>
                                    Level:
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="row"
                                *ngFor="let language of filters.candidates_filters.languages;let i = index">
                                <div class="col-md-4 col-12">
                                  <div class="card-body-content">
                                    {{language.language.description}}
                                  </div>
                                </div>
                                <div class="col-md-4 col-12">
                                  <div class="card-body-content">
                                    {{language.level.description}}
                                  </div>
                                </div>
                                <div class="col-md-4 col-12">
                                  <div class="card-body-content">
                                    <a (click)="deleteLanguage(i)">
                                      <i class="tt-icon-sm icon-ttdelete pull-right" data-toggle="tooltip"
                                        data-placement="bottom" title="Delete language"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="education">
                  <ng-template ngbPanelHeader>
                    <div class="secondary-color mb-2"
                      (click)="accLevel.toggle('education');filters.accordion.education = !filters.accordion.education">
                      Education
                      <i class="pull-right p-1"
                        [ngClass]="{'ft-chevron-right': !filters.accordion.education,'ft-chevron-down': filters.accordion.education}"></i>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="row card-block">
                      <div class="col-md-4 col-12 append-div-education">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Minimum degree type:
                            </label>
                          </div>
                          <div class="card-body-content">
                            <ng-select appendTo=".append-div-education" bindLabel="description"
                              dropdownPosition="bottom" (open)="getDegree()" placeholder="Select degree"
                              (change)="addEducation()" [items]="dropdownResources.degree"
                              [(ngModel)]="education.degree" [ngModelOptions]="{standalone: true}">
                            </ng-select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="keywords">
                  <ng-template ngbPanelHeader>
                    <div class="secondary-color mb-2"
                      (click)="accLevel.toggle('keywords');filters.accordion.keyword = !filters.accordion.keyword">
                      Rank by keywords
                      <i class="pull-right p-1"
                        [ngClass]="{'ft-chevron-right': !filters.accordion.keyword,'ft-chevron-down': filters.accordion.keyword}"></i>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="row card-block">
                      <div class="col-md-4 col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Ranking (press enter to insert word)
                            </label>
                          </div>
                          <div class="card-body-content">
                            <input class="form-control" [(ngModel)]="keywords" [ngModelOptions]="{standalone: true}"
                              type="text" placeholder="+ key word"
                              (keyup.enter)="populateKeywords(keywords);keywords = ''" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-8 col-12" *ngIf="filters.candidates_filters.keywords.length">
                        <div class="card-body-title-basic">
                          <label>
                            Results (click on X to delete key word)
                          </label>
                        </div>
                        <div class="card-body-content">
                          <tag-input id="expertise-input" [(ngModel)]="filters.candidates_filters.keywords"
                            [ngModelOptions]="{standalone: true}" [identifyBy]="'value'" [displayBy]="'value'">
                          </tag-input>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="open_to_roles">
                  <ng-template ngbPanelHeader>
                    <div class="secondary-color mb-2"
                      (click)="accLevel.toggle('open_to_roles');filters.accordion.open_to_roles = !filters.accordion.open_to_roles">
                      Open to roles
                      <i class="pull-right p-1"
                        [ngClass]="{'ft-chevron-right': !filters.accordion.open_to_roles,'ft-chevron-down': filters.accordion.open_to_roles}"></i>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="row card-block">
                      <div class="col-md-4 col-12">
                        <div class="card-body-title-basic">
                          <label>Recycled</label>
                        </div>
                        <div class="card-body-content">
                          <div class="row card-block">
                            <div class="col-12">
                              <div class="form-group">
                                <div class="card-body-content display-inline-flex">
                                  <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" [(ngModel)]="filters.candidates_filters.recycled.value"
                                      name="recycled_value" id="recycled_value_all" [value]=null
                                      class="custom-control-input">
                                    <label class="custom-control-label" for="recycled_value_all">All</label>
                                  </div>
                                  <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" [(ngModel)]="filters.candidates_filters.recycled.value"
                                      name="recycled_value" id="recycled_value_yes" [value]=true
                                      class="custom-control-input">
                                    <label class="custom-control-label" for="recycled_value_yes">Yes</label>
                                  </div>
                                  <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" [(ngModel)]="filters.candidates_filters.recycled.value"
                                      name="recycled_value" id="recycled_value_no" [value]=false
                                      class="custom-control-input">
                                    <label class="custom-control-label" for="recycled_value_no">No</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-12" *ngIf="filters.candidates_filters.recycled.value">
                        <div class="card-body-title-basic">
                          <label>Recycled in the last days</label>
                        </div>
                        <div class="card-body-content">
                          <input [(ngModel)]="filters.candidates_filters.recycled.days"
                            [ngModelOptions]="{standalone: true}" class="form-control" type="number" pattern="[0-9]*"
                            (keydown)=preventNegativeNumbers($event) placeholder="Insert day(s)">
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="jobs">
        <ng-template ngbPanelHeader>
          <div class="form" (click)="acc.toggle('jobs')">
            <div class="form-section">
              Similar jobs
              <i class="ft-filter tt-icon-sm"></i>
            </div>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="row card-block pb-0">
            <div class="col-12 append-div-similar-jobs">
              <div class="form-group">
                <div class="card-body-content">
                  <ng-select appendTo=".append-div-similar-jobs" bindLabel="job_title" dropdownPosition="bottom"
                    (open)="getSimilarJobs(false)" placeholder="Choose similar jobs"
                    [items]="dropdownResources.similarJobs" [closeOnSelect]="false" [(ngModel)]="filters.similar_jobs"
                    [ngModelOptions]="{standalone: true}" [multiple]="true">
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="filters.similar_jobs.length" class="col-12 pl-4 pr-4">
            <div class="form">
              <div class="form-section">
                Choosen jobs
              </div>
            </div>
            <div class="form-group">
              <div class="card card-preview">
                <div class="row">
                  <div class="col-12" *ngFor="let job of filters.similar_jobs;let i = index">
                    <div class="form-group">
                      {{job.job_title}}
                      <a (click)="deleteJob(i)">
                        <i class="tt-icon-sm icon-ttdelete pull-right" data-toggle="tooltip" data-placement="bottom"
                          title="Delete job"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="recommended_candidates">
        <ng-template ngbPanelHeader>
          <div class="form" (click)="acc.toggle('recommended_candidates')">
            <div class="form-section">
              Recommend candidates BETA
              <i class="ft-filter tt-icon-sm"></i>
            </div>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="row card-block pb-0">
            <div class="col-12">
              <div class="form-group">
                <div class="card-body-content">
                  <div class="alert alert-light" role="alert" [innerHtml]="recommended_description"></div>
                  <ng-select bindLabel="job_title" dropdownPosition="auto"
                    (open)="getJobs()" [items]="dropdownResources.openJobs" placeholder="Select role"
                    [(ngModel)]="filters.recommended_job" [ngModelOptions]="{standalone: true}">
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
  <div class="position-bottom full-width">
    <button type="button" class="btn pull-right bg-secondary-color" (click)="searchCandidates()">
      Search candidates
    </button>
  </div>
</div>
