import { Component, EventEmitter, OnInit, ViewChild, Output, ViewEncapsulation } from '@angular/core';
import { concat, of, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime, switchMap, tap, catchError } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Globals } from 'app/globals';
import { ToastrService } from 'ngx-toastr';
import { AddConsultantCompanyModalService } from './add-consultant-company-modal.service';
import { FileUploader } from 'ng2-file-upload';
import { environment } from 'environments/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-consultant-company-modal',
  templateUrl: './add-consultant-company-modal.component.html',
  styleUrls: ['./add-consultant-company-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddConsultantCompanyModalComponent implements OnInit{

  @Output() dismissDrawer: EventEmitter<boolean> = new EventEmitter<boolean>();

  loadComponent = false;

  consultantCompany: any = null;

  companies: Observable<any>;

  companyLoading = false;

  companyinput$ = new Subject<string>();

  tempCompanyName = null;

  consultants: any[] = [];

  blacklisted_candidate: any;

  public blacklistCsvUploader: FileUploader;

  csv_loading = false;

  public hasBaseDropZoneOver;

  public addTagCompany: (name) => void;

  @ViewChild('blacklistSelect') blacklistNgSelect: NgSelectComponent;

  @ViewChild('companiesSelect') companiesNgSelect: NgSelectComponent;

  show: any = {
    component: false
  };

  constructor(public globals: Globals, private getNavisAddConsultantCompanyService: AddConsultantCompanyModalService, private toastr: ToastrService,
    public activeModal: NgbActiveModal){
    this.addTagCompany = (name) => this.addCompanyBlacklist(name);
  }

  ngOnInit(){
    this.loadComponent = true;
    this.initData();
    this.loadCompany();
    this.createBlacklistedCompaniesFileUploader();
    this.show.component = true;
  }

  initData(){
    this.consultantCompany = {
      name: null,
      photo: null,
      linkedin_url: null,
      dealroom_url: null,
      website_url: null,
      contact: {},
      consultants: [],
      blacklisted_companies: [],
      blacklisted_candidates: []
    };
  }

  initCompanyData(){
      this.consultantCompany.name = null,
      this.consultantCompany.photo = null,
      this.consultantCompany.linkedin_url = null,
      this.consultantCompany.dealroom_url = null,
      this.consultantCompany.website_url = null;
  }

  loadCompany(){
    this.companies = concat(
      of([]),
      this.companyinput$.pipe(
          debounceTime(200),
          distinctUntilChanged(),
          tap(() => this.companyLoading = true),
          switchMap(company =>
            this.getNavisAddConsultantCompanyService.getDealroomCompanies(company).pipe(
                  catchError(() => of([])),
                  tap(() => {
                      this.companyLoading = false;
                  })
              )
          )
      )
    );
  }

  addCompany(name){
    this.initData();
    this.companyLoading = false;
    this.consultantCompany.name = name;
    this.companiesNgSelect.close();
  }

  createConsultantCompany(){
    this.checkCompanyContact();
    this.getNavisAddConsultantCompanyService.createConsultantCompany(this.consultantCompany)
    .subscribe((response) => {
      this.toastr.success(response.message);
      this.initData();
      this.activeModal.close(response);
      this.dismissDrawer.emit(true);
    },
    (err) => {
      if (err.error){
        for (const errProp in err.error){
          if (err.error.hasOwnProperty(errProp)){
            if (err.error[errProp][0]){
              this.toastr.error(err.error[errProp]);
            }
          }
        }
      } else {
        this.toastr.error(err.message);
      }
    });
  }

  checkCompanyContact(){
    if (!this.consultantCompany.contact.name && !this.consultantCompany.contact.email && !this.consultantCompany.contact.phone && !this.consultantCompany.contact.photo){
      this.consultantCompany.contact = {};
    }
  }

  setCompanyData(data){
    if (data){
       this.consultantCompany.photo = data.photo || data.images['100x100'];
       this.consultantCompany.linkedin_url = data.linkedin_url;
       this.consultantCompany.website_url = data.website_url;
       this.consultantCompany.dealroom_id = data.id;
       this.consultantCompany.dealroom_data = data;
    }
  }

  //only consultants without consultant company
  getConsultants(){
    this.getNavisAddConsultantCompanyService.getUsers('consultants').subscribe((data) => {
      if (data.length){
        this.consultants = data;
      }
    },
    (err) => {
      this.displayErrorMessage(err);
    });
  }

  displayErrorMessage(error){
    if (error.error){
      for (const errProp in error.error){
        if (error.error.hasOwnProperty(errProp)){
          if (error.error[errProp][0]){
            this.toastr.error(error.error[errProp]);
          }
        }
      }
    } else {
      this.toastr.error('Something went wrong');
    }
  }

  addBlacklistedCandidate(){
    if (this.blacklisted_candidate && this.blacklisted_candidate.trim()){
      if (this.consultantCompany.blacklisted_candidates.length){
        if (this.consultantCompany.blacklisted_candidates.filter(x => x.linkedin_url === this.blacklisted_candidate.trim()).length == 0){
          const linkedin_url = { linkedin_url: this.blacklisted_candidate };
          this.consultantCompany.blacklisted_candidates.push(linkedin_url);
          this.blacklisted_candidate = '';
        } else {
          this.blacklisted_candidate = '';
          this.toastr.error('This candidate already exist');
         }
      } else {
        const linkedin_url = { linkedin_url: this.blacklisted_candidate };
        this.consultantCompany.blacklisted_candidates.push(linkedin_url);
        this.blacklisted_candidate = '';
      }
   }
  }

  deleteBlacklistedCandidate(index){
    this.consultantCompany.blacklisted_candidates.splice(index, 1);
  }

  onBlacklistedCompanyInsertCSV(){
    this.blacklistCsvUploader.uploadAll();
    this.csv_loading = false;
  }

  onFileClick(event){
    event.target.value = '';
  }

  createBlacklistedCompaniesFileUploader(){
    this.blacklistCsvUploader = new FileUploader({
      url: environment.getNavisBackendURL + '/companies/linking/csv',
      itemAlias: 'csv',
      authToken: 'Bearer ' + localStorage.getItem('access-token')
    });

    this.blacklistCsvUploader.onCompleteItem = (item: any, response: any, status: any) => {
      if (status === 200){
        const obj = JSON.parse(response);
        if (obj instanceof Object && 'companies' in obj){
          this.consultantCompany.blacklisted_companies = this.mergeAndDeduplicateBlacklistedCompanies(obj.companies);
          this.toastr.success('Blacklisted companies succesfully added');
          return;
        }
        this.toastr.error('Response is malformed or empty. Please check the csv file content');
        return;
      }
      this.toastr.error('Something went wrong');
      this.hasBaseDropZoneOver = false;
    };
  }

  mergeAndDeduplicateBlacklistedCompanies(companies){
    const tempCompaines = [...this.consultantCompany.blacklisted_companies, ...companies];
    return tempCompaines.filter((value, index, self) =>
      index === self.findIndex((item) => (
        item.name === value.name
      ))
    );
  }

  blacklistCompany(event){
    this.consultantCompany.blacklisted_companies = [];
    if (this.consultantCompany && this.consultantCompany.blacklisted_companies.find(company => company.name === this.consultantCompany.name)){
      this.consultantCompany.blacklisted_companies.splice(
        this.consultantCompany.blacklisted_companies.findIndex(i => i.name === this.consultantCompany.name), 1
      );
    }
    if (event){
      this.consultantCompany.blacklisted_companies.push(event);
    }
  }

  addCompanyBlacklist(name){
    this.companyLoading = false;
    const tempCompany = {
      name: name,
      images: {
        '32x32': this.globals.companyAvatar
        }
      };
    this.consultantCompany.blacklisted_companies = [...this.consultantCompany.blacklisted_companies, tempCompany];
    this.blacklistNgSelect.close();
  }

}
