export const environment = {
  production: false,
  sentryURL: 'https://1b57cfa3255f4055874ef276ae5334d0:73d4cf0e985d4b5790488ad95449c8ce@o377710.ingest.sentry.io/5440674',
  platformBackendURL: 'https://navis-api-staging.thebigsearch.com',
  getNavisBackendURL: 'https://backend-preprod.getnavis.com/api',
  djangoURL: 'https://search-api-staging.thebigsearch.com',
  dealroomURL: 'https://api.dealroom.co/api/v1',
  graphAPIURL: 'https://email-api-staging.thebigsearch.com',
  djangoKey: 'zDr80lAwFJpV3kJvWEEa7z3z1atrbM',
  graphAPIKey: 'Ft9d7mnB62hWgf6kYYv3siw8LOfGdu'
};
