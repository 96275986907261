<div class="modal-content">
  <div class="modal-bg">
    <div class="modal-header">
      <div class="modal-header-title">
        Merge candidates
      </div>
      <button type="button" class="close pull-right" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true" [innerHTML]="globals.labels.placeholders.global.buttons.close_x" data-toggle="tooltip"
          data-placement="bottom" [title]="globals.labels.tooltips.global.close"></span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <div class="modal-body-title">
          <label> {{globals.labels.placeholders.full_layout_page.cit_component.required_star}}
            Choose candidate
          </label>
        </div>
        <div class="modal-body-content">
          <ng-select bindLabel="name" bindValue="uuid" dropdownPosition="bottom"
            placeholder="Start typing to search candidate"
            [items]="candidates | async" [(ngModel)]="data.secondary_candidate_uuid" [ngModelOptions]="{standalone: true}" [loading]="candidateLoading" [typeahead]="candidateInput">
            <ng-template ng-option-tmp let-item="item" let-index="i">
              <div class="media mb-1">
                <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                  [src]="item.photo ? item.photo : globals.avatar" (error)="item.photo = globals.avatar" />
                <div class="media-body">
                  <div class="media-body-title mt-1 mb-0">{{item.name}}</div>
                  <p class="title-white-space">
                    <i class="tt-icon-sm icon-ttcareer"></i>
                    {{item.current_job_title || item.current_employer ? item.current_job_title + '@ ' + item.current_employer : 'No job title available'}}
                  </p>
                </div>
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" *ngIf="data.secondary_candidate_uuid" class="col-md-3 btn btn-block bg-secondary-color"
        (click)="activeModal.close(data)">
        Next</button>
      <button type="button" class="col-md-3 btn btn-block bg-primary-color"
        (click)="activeModal.dismiss()">{{globals.labels.placeholders.global.buttons.close}}</button>
    </div>
  </div>
</div>