import { Component, OnInit } from '@angular/core';
import { AddRevenueService } from "./add-revenue.service";
import { ToastrService } from "ngx-toastr";
import { distinctUntilChanged, debounceTime, switchMap, tap, catchError, filter } from 'rxjs/operators';
import { Subject, Observable, of, concat } from 'rxjs';
import { Globals } from 'app/globals';
import * as moment from 'moment';

@Component({
  selector: 'app-add-revenue',
  templateUrl: './add-revenue.component.html',
  styleUrls: ['./add-revenue.component.scss']
})
export class AddRevenueComponent implements OnInit{
  private readonly minLengthTerm = 3;

  validForm = true;

  revenueData: any = {};

  invoiceData: any = {};

  //dropdowns
  podLeads: any = [];

  podUsers: any = [];

  billingContacts: any = [];

  revenueTypes: any = [];

  currencies: any = [];

  vatTypes: any = [
    {
      'description': 'VAT (21%)',
      'value': 'vat_21'
    },
    {
      'description': 'VAT reverse charge (0%)',
      'value': 'vat_reverse_charge'
    }
  ];

  candidates$: Observable<any>;

  candidateLoading = false;

  candidateInput$ = new Subject<string>();

  jobs$: Observable<any>;

  jobLoading = false;

  jobInput$ = new Subject<string>();

  invoiceValidationId: string = null;

  invoiceValidationIssues: any = [];

  //ne znajme so e ova da go vidime na krajo
  edit: any = {
    editingReference: true,
    editingDescription: true,
    editingDueDate: true
  };

  constructor(public globals: Globals, private addRevenueService: AddRevenueService, private toastr: ToastrService){
  }

  ngOnInit(){
    this.initRevenueForm();
    this.loadCandidates();
    this.loadJobs();
  }

  initRevenueForm(){
    this.revenueData = {
      type: null,
      pod_lead: null,
      pod_users: [],
      amount: null,
      currency: null,
      parent_id: {
        candidate: null,
        job: null
      }
    };

    this.invoiceData = {
      date: this.generateDefaultInvoiceDueDate(),
      description: null,
      notification_email: this.globals.data.email,
      reference: null,
      vat_type: null,
      pod_lead: null
    };
  }

  /**
   * zosto e ova da se prasat
   */
  clearTypeFileds(){
    this.revenueData.amount = 0;
    this.revenueData.currency = null;
    this.updateInvoiceRefAndDesc();
  }

  getResources(){
    if (!this.revenueTypes.length || !this.currencies.length){
      this.addRevenueService.getResources()
        .subscribe((data) => {
          this.revenueTypes = data.filter((el) => el.type == 'revenue_type');
          this.currencies = data.filter((el) => el.type == 'currencies');
        },
          (err) => {
            this.toastr.error(err.message);
          });
    }
  }

  getPodLeads(){
    if (!this.podLeads.length){
      this.addRevenueService.getPodLeads()
        .subscribe((data) => {
          if (data){
            this.podLeads = data;
          }
        },
          (err) => {
            this.toastr.error(err.message);
          });
    }
  }

  getPodUsers(){
    if (!this.podUsers.length){
      this.addRevenueService.getPodUsers()
        .subscribe((data) => {
          if (data){
            this.podUsers = data;
          }
        },
          (err) => {
            this.toastr.error(err.message);
          });
    }
  }

  getBillingContacts(){
    if (!this.billingContacts.length){
      this.addRevenueService.getBillingContacts()
        .subscribe((data) => {
          if (data){
            this.billingContacts = data.contacts;
          }
        },
          (err) => {
            this.toastr.error(err.message);
          });
    }
  }

  loadCandidates(){
    this.candidates$ = concat(
      of([]), // default items
      this.candidateInput$.pipe(
        filter(res => res !== null && res.length >= this.minLengthTerm),
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => this.candidateLoading = true),
        switchMap(term => this.addRevenueService.getCandidates(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.candidateLoading = false)
          ))
      )
    );
  }

  loadJobs(){
    this.jobs$ = concat(
      of([]), // default items
      this.jobInput$.pipe(
        filter(res => res !== null && res.length >= this.minLengthTerm),
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => this.jobLoading = true),
        switchMap(term => this.addRevenueService.getJobs(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.jobLoading = false)
          ))
      )
    );
  }

  updateInvoiceRefAndDesc(){
    this.invoiceData.reference = this.generateInvoiceReference();
    this.invoiceData.description = this.generateInvoiceDescription();
  }

  isFormValid(){
    this.validForm = this.getFormValidity();
    return this.validForm;
  }

  getFormValidity(){
    if (!this.revenueData.type || !this.revenueData.amount || !this.revenueData.currency || !this.revenueData.pod_lead ||
      !this.invoiceData.contact_id || !this.invoiceData.reference || !this.invoiceData.description || !this.invoiceData.vat_type){
      this.validForm = false;
      return false;
    }

    if (this.revenueData.type.value == 'placement' && !this.revenueData.parent_id.candidate){
      this.validForm = false;
      return false;
    }

    return true;
  }

  addRevenue(skipValidation){
    this.isFormValid();
    if (this.validForm){
      this.invoiceData.amount = this.revenueData.amount;
      this.invoiceData.currency = this.revenueData.currency.value;
      this.invoiceData.tenant = 'navis';

      if (skipValidation){
        this.invoiceValidationIssues = [];
        return this.sendInvoiceAndAddRevenue(skipValidation);
      }

      this.addRevenueService.validateInvoice({ invoice: this.invoiceData }).subscribe(
        (data) => {
          this.invoiceValidationId = data.validation_id;
          if (data.passed){
            this.sendInvoiceAndAddRevenue(false);
            this.toastr.success('Successfully sent invoice');
          } else {
            this.invoiceValidationIssues = data.details;
            this.toastr.error('Invoice failed to submit, you have errors');
          }
        },
        (err) => {
          this.toastr.error(err);
        }
      );
    }
    return null;
  }

  sendInvoiceAndAddRevenue(override_validation){
    this.invoiceData.pod_lead = this.revenueData.pod_lead.name;
    const invoiceBody = {
      invoice: this.invoiceData,
      validation_id: this.invoiceValidationId,
      override_validation: override_validation,
      tenant: 'navis'
    };

    this.addRevenueService.sendInvoice(invoiceBody).subscribe(
      (data) => {
        if (!data.success){
          return this.toastr.error(data.error);
        }

        this.addRevenueService.addRevenue(this.revenueData)
          .subscribe(
            () => {
              this.initRevenueForm();
              this.toastr.success('Successfully created revenue');
            },
            (err) => {
              this.toastr.error(err.error);
            }
          );
        return null;
      },
      (err) => {
        this.toastr.error(err);
      }
    );
  }

  generateInvoiceDescription(){
    if (!this.getUniqueElementInRevenue()){
      return '';
    }

    if (this.revenueData.type.value == 'placement'){
      if (!this.revenueData.parent_id.job){
        return 'Placement fee for ' + this.revenueData.parent_id.candidate.name;
      }
      return 'Placement fee for ' + this.revenueData.parent_id.candidate.name + ' - ' + this.revenueData.parent_id.job.job_title;
    }

    if (this.revenueData.type.value == 'project'){
      const currentMonth = moment().format('MMMM');
      return 'Monthly base fee - ' + currentMonth;
    }

    if (!this.revenueData.parent_id.job){
      return this.revenueData.type.description + ' fee';
    }

    return this.revenueData.type.description + ' fee for the Search of ' + this.revenueData.parent_id.job.job_title;

  }

  generateInvoiceReference(){
    const uniqueElement = this.getUniqueElementInRevenue();

    if (!uniqueElement){
      return '';
    }
    const uniqueId = uniqueElement.uuid;
    const invoiceDate = new Date();
    return this.revenueData.type.id + '-' + uniqueId + '-' + invoiceDate.getDate() + '-' + invoiceDate.getMonth() + '-' + invoiceDate.getFullYear();
  }

  generateDefaultInvoiceDueDate(){
    return moment().add(30, 'days')
.format('YYYY-MM-DD');
  }

  getUniqueElementInRevenue(){
    if (this.revenueData.type.value == 'placement'){
      return this.revenueData.parent_id.candidate;
    }

    if (this.revenueData.type.value == 'project'){
      return { 'uuid': 'rpo' };
    }

    return this.revenueData.parent_id.job;
  }
}
