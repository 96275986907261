import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Globals } from 'app/globals';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { GetnavisViewConsultantService } from './getnavis-view-consultant.service';
import { cloneDeep } from 'lodash';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-getnavis-view-consultant',
  templateUrl: './getnavis-view-consultant.component.html',
  styleUrls: ['./getnavis-view-consultant.component.scss']
})
export class GetnavisViewConsultantComponent implements OnInit{
  @Input() uuid: string;

  readonly minLengthTerm = 3;

  loading = false;

  changed = false;

  companies: any = [];

  companyLoading = false;

  companyinput$ = new Subject<string>();

  consultant: any = {
    details: {
      company: {}
    },
    jobs: []
  };

  private consultantCopy: any = null;

  edit: any = {
    basic: false,
    jobs: false
  };

  resources: any = {
    jobs: []
  };

  @ViewChild('companySelect') companySelect: NgSelectComponent;

  public addTagCompany: (name) => void;

  constructor(public globals: Globals, private getNavisViewConsultantService: GetnavisViewConsultantService, private toastr: ToastrService, private activatedRoute: ActivatedRoute){
    this.addTagCompany = (name) => this.addCompany(name);
    activatedRoute.queryParams.subscribe(params => {
      if (params.consultants_uuid){
        this.initEdit();
        this.loadCompany();
        this.getConsultant(params.consultants_uuid);
        this.uuid = params.consultants_uuid;
      }
    });
  }

  initEdit(){
    this.edit = {
      basic: false,
      jobs: false
    };
  }

  ngOnInit(){

  }

  addCompany(name){
    this.companyLoading = false;
    this.consultant.details.company = { name: name };
    this.updateConsultant({ company: { name: name } });
    this.companySelect.close();
  }

  getConsultant(uuid){
    this.loading = true;
    this.getNavisViewConsultantService.getConsultant(uuid).subscribe(
      (response) => {
        if (response){
          this.loading = false;
          this.consultant = response;
          this.consultantCopy = cloneDeep(this.consultant);
        }
      },
      (error) => {
        this.toastr.error(error.message);
      });
  }

  updateConsultant(body){
    this.getNavisViewConsultantService.updateConsultant(this.uuid, body)
      .subscribe((response) => {
        if (response.success){
          this.toastr.success(response.message);
          this.consultantCopy = cloneDeep(this.consultant);
        }
      },
        (err) => {
          this.consultant = cloneDeep(this.consultantCopy);
          if (err.error){
            for (const errProp in err.error){
              if (err.error.hasOwnProperty(errProp)){
                if (err.error[errProp][0]){
                  this.toastr.error(err.error[errProp]);
                }
              }
            }
          } else {
            this.toastr.error('Something went wrong');
          }
        });
    this.changed = false;
  }

  editConsultant(key, value){
    const body = this.createConsultantObjectForUpdate(key, value);
    this.updateConsultant(body);
  }

  createConsultantObjectForUpdate(key, value){
    if (key.indexOf('.') > -1){
      const keyValue = key.substr(0, key.indexOf('.'));
      const obj = {};
      obj[keyValue] = {
        [key.split('.').pop()]: value
      };
      return obj;
    } else {
      return {
        [key]: value
      };
    }
  }

  editForm(section){
    this.edit[section] = !this.edit[section];
  }

  getResources(value, search?){
    if (!this.resources[value].length){
      this.getNavisViewConsultantService.getResources(value, search).subscribe((data) => {
        if (data.length){
          this.resources[value] = data;
        }
      },
        (error) => {
          this.toastr.error(error.message);
        });
    }
  }

  loadCompany(search?){
    this.getNavisViewConsultantService.getConsultantCompanies(search).subscribe((data) => {
      if (data.length){
        this.companies = data;
      }
    },
      (error) => {
        this.toastr.error(error.message);
      });
  }

  onChange(){
    this.changed = true;
  }
}
