import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from 'app/globals';
import { CommunicationService } from 'app/shared/service/communication.service';
import { ToastrService } from 'ngx-toastr';
import { GetnavisCandidateEmailModalService } from './getnavis-candidate-email-modal.service';

@Component({
  selector: 'app-getnavis-candidate-email-modal',
  templateUrl: './getnavis-candidate-email-modal.component.html',
  styleUrls: ['./getnavis-candidate-email-modal.component.scss']
})
export class GetnavisCandidateEmailModalComponent implements OnInit{

  data: any;

  changedEmail = false;

  constructor(public globals: Globals, public activeModal: NgbActiveModal, private candidateEmailModalService: GetnavisCandidateEmailModalService, public toastr: ToastrService,
    private communicationService: CommunicationService){
  }

  ngOnInit(){
  }

  updateEmail(email){
    const body = { 'primary_email': email };
    this.updateCandidate(this.data.uuid, body);
  }

  updateCandidate(uuid, body){

    this.candidateEmailModalService.updateCandidate(uuid, body).subscribe(
      () => {
        this.toastr.success('Successfully updated email');
        this.addCandidateToCampaign();
      },
      (err) => {
        if (err.error){
          for (const errProp in err.error){
            if (err.error.hasOwnProperty(errProp)){
              if (err.error[errProp].length){
                this.toastr.error(err.error[errProp]);
              }
            }
          }
        } else {
          this.toastr.error('Something went wrong');
        }
      });
  }

  addCandidateToCampaign(){
    const addCandidateToCampaign = {
      campaign: { uuid: this.data.campaign },
      candidates: [this.data.uuid],
      job_uuid: this.data.job
    };
    this.communicationService.emitGetNavisCandidateToCampaign(addCandidateToCampaign);
    this.activeModal.close(this.data.primary_email);
  }

}
