<div *ngIf="jobDataIsDownloaded">
  <div class="media m-4">
    <div class="media-body">
      <div class="media-body-title form-group display-inline-flex">
        <span class="align-self-center">{{job.title}}</span>
      </div>
      <div class="media-body-content">
        <div class="row">
          <div class="col-4">
            <div class="form-group">
              <div *ngIf="job.location">
                <div>
                  <i class="fa fa-map-marker pr-1 pl-1"></i>
                  {{job.location.description}}
                </div>
              </div>
              <div *ngIf="!job.location">
                <i class="fa fa-map-marker pr-1 pl-1"></i>No location available
              </div>
            </div>
            <div class="form-group">
              <strong>Hiring Company:</strong>
              {{job.company ? job.company.name : 'No company information'}}
            </div>
            <div class="form-group">
              <strong>Job type:
              </strong>{{job.job_type ? job.job_type.description : 'No job type information'}}
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <strong>Created </strong> <span *ngIf="job.creator && job.creator.first_name"><strong> by
                </strong>{{job.creator.first_name + ' '+ job.creator.last_name}}</span><span
                *ngIf="job.created_at"><strong> @
                </strong>{{job.created_at}}</span>
            </div>
            <div class="form-group">
              <strong>Search Stage:
              </strong>{{job.search_stage ? job.search_stage.description : 'No search stage information'}}
            </div>
            <div class="form-group">
              <strong>Research Support:
              </strong>{{job.research_support ? job.research_support.description : 'No research support information'}}
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <div class="row">
                <div class="mt-2" [ngClass]="job.users.length ? 'col-2' : 'col'">
                  <strong>Team: </strong><span *ngIf="!job.users.length">No team information</span>
                </div>
                <div class="col-6 position-left" *ngIf="job.users.length">
                  <div *ngFor="let user of job.users; let i = index" class="position-absolute podPicture">
                    <img class="d-flex height-30 width-30 rounded-circle cursor-pointer"
                      [ngClass]="{'lead-border': user.project_lead || user.sourcing_lead}"
                      [src]="user.picture_file_url ? user.picture_file_url : globals.avatar"
                      (error)="user.picture_file_url = globals.avatar" data-toggle="tooltip" data-placement="top"
                      [title]="user.first_name ? (user.first_name + ' ' + user.last_name) : user.name" />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="mt-2" [ngClass]="job.consultants.length ? 'col-2' : 'col-12'">
                  <strong>Consultants: </strong><span *ngIf="!job.consultants.length">No consultants information</span>
                </div>
                <div class="col-6 position-left" *ngIf="job.consultants.length">
                  <div *ngFor="let consultant of job.consultants; let i = index"
                    class="position-absolute consultantPicture">
                    <img class="d-flex height-30 width-30 rounded-circle cursor-pointer"
                      [src]="consultant.picture_file_url ? consultant.picture_file_url : globals.avatar"
                      (error)="consultant.picture_file_url = globals.avatar" data-toggle="tooltip" data-placement="top"
                      [title]="consultant.name" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <nav ngbNav id="tab-shadow" class="nav-tabs" #acc="ngbNav">
    <ng-container [ngbNavItem]="'ngb-tab-0'">
      <a ngbNavLink>General</a>
      <ng-template ngbNavContent>
        <div class="card card-top scroll scroll-tabs">
          <ngb-accordion id="filter-accordion" [(activeIds)]="activeId" #acc="ngbAccordion" class="ng-accordion-append"
            (panelChange)="preventDefault ? beforeChange($event) : ''">
            <ngb-panel id="details" class="mt-2">
              <ng-template ngbPanelHeader>
                <div class="form" (click)="acc.toggle('details')">
                  <div class="form-section">
                    Details
                    <div
                      class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2"
                      (click)="editForm('details')">
                      edit
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent *ngIf="!edit.details">
                <div class="card-block">
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Job Title
                        </label>
                        <div class="card-body-content">{{job.title}}</div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Hiring Company
                        </label>
                        <div class="card-body-content">{{job.company.name}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Job type
                        </label>
                        <div class="row" *ngIf="job.job_type">
                          <div class="col-12">
                            {{job.job_type.description}}
                          </div>
                        </div>
                        <div class="row" *ngIf="!job.job_type">
                          <div class="col-12">
                            No type available
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Search Stage
                        </label>
                        <div class="card-body-content">{{job.search_stage ? job.search_stage.description : 'No
                          Information Available.'}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Consultant
                        </label>
                        <div class="row" *ngIf="job.consultants.length">
                          <div class="col-12">
                            <div *ngFor="let consultant of job.consultants">
                              {{consultant.name}}
                            </div>
                          </div>
                        </div>
                        <div class="row" *ngIf="!job.consultants.length">
                          <div class="col-12">
                            No consultants info
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Consultant Company
                        </label>
                        <div class="row" *ngIf="job.consultant_company">
                          <div class="col-12">
                            {{job.consultant_company.name}}
                          </div>
                        </div>
                        <div class="row" *ngIf="!job.consultant_company">
                          <div class="col-12">
                            No consultant company info
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Lead Consultant
                        </label>
                        <div class="row" *ngIf="job.lead_consultant">
                          <div class="col-12">
                            <div>
                              {{job.lead_consultant.name}}
                            </div>
                          </div>
                        </div>
                        <div class="row" *ngIf="!job.lead_consultant">
                          <div class="col-12">
                            No lead consultant info
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Location
                        </label>
                        <div class="row" *ngIf="job.location">
                          <div class="col-12">
                            {{job.location.description}}
                          </div>
                        </div>
                        <div class="row" *ngIf="!job.location">
                          <div class="col-12">
                            No location available
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Functional Expertise
                        </label>
                        <div class="card-body-content">{{job.fe ? job.fe.description : 'No Informaton Available.'}}</div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Sub Functional Expertise
                        </label>
                        <div class="card-body-content">{{job.sfe ? job.sfe.description : 'No Informaton Available.'}}</div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Seniority
                        </label>
                        <div class="card-body-content">{{job.seniority ? job.seniority.description : 'No Informaton Available.'}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <label class="card-body-title-basic">
                          Deadline to Client
                        </label>
                        <div class="card-body-content">{{job.deadline_to_client || 'No Informaton Available.'}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          Additional Info
                        </div>
                        <div class="card-body-content" [innerHTML]="job.additional_info || 'No Information Available'">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent *ngIf="edit.details">
                <div class="card-block">
                  <div class="form-group">
                    <label class="card-body-title-basic">* Job title</label>
                    <div class="card-body-content">
                      <input class="form-control" type="text" [(ngModel)]="job.title" placeholder="Enter job title"
                        (focusout)="changed ? editJob('title' ,job.title) : ''" (ngModelChange)="onChange($event)" />
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label>* Hiring Company</label>
                    </div>
                    <div class="card-body-content">
                      <ng-select [items]="hiringCompanies$ | async" [hideSelected]="true" dropdownPosition="bottom"
                        bindLabel="name" [loading]="companyLoading" [typeahead]="companyinput$"
                        [(ngModel)]="job.company" [clearable]="false" placeholder="Select company"
                        [ngModelOptions]="{standalone: true}" [minTermLength]="minLengthTerm"
                        typeToSearchText="Please enter {{minLengthTerm}} or more characters"
                        (change)="editJob('company' ,job.company)" (ngModelChange)="blacklistCompany($event)">
                        <ng-template ng-option-tmp let-item="item" let-index="i">
                          <div class="media mb-1">
                            <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                              [src]="item.photo" (error)="item.photo = globals.companyAvatar" />
                            <div class="media-body">
                              <div class="media-body-title mt-1 mb-0"><b>{{item.name}}</b></div>
                            </div>
                          </div>
                        </ng-template>
                      </ng-select>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label>* Job type</label>
                    </div>
                    <div class="card-body-content">
                      <div class="row">
                        <div class="col-12">
                          <ng-select [items]="resources.jobTypes" bindLabel="description" dropdownPosition="bottom"
                            placeholder="Select job type" [(ngModel)]="job.job_type" [clearable]="false"
                            (change)="changeAndUpdateJobType()">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label>Consultants</label>
                    </div>
                    <div class="card-body-content">
                      <ng-select [items]="consultants" [clearable]="true" placeholder="Select consultant"
                        bindLabel="name" (open)="getConsultants()" [multiple]="true" [(ngModel)]="job.consultants"
                        (remove)="checkClearLeadConsultant($event)" (clear)="checkClearLeadConsultant()" (change)="editJob('consultants' ,job.consultants)">
                        <ng-template ng-option-tmp let-item="item" let-index="i">
                          <div class="media mb-1">
                            <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                              [src]="item.picture_file_url" (error)="item.picture_file_url = globals.avatar" />
                            <div class="media-body">
                              <div class="media-body-title mt-1 mb-0"><b>{{item.name}}</b></div>
                              <span>{{item.email}}</span>
                            </div>
                          </div>
                        </ng-template>
                      </ng-select>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label>* Consultant Company</label>
                    </div>
                    <div class="card-body-content">
                      <ng-select [items]="consultantCompanies" placeholder="Select consultant company" bindLabel="name"
                         [(ngModel)]="job.consultant_company" (open)="getConsultantCompanies()" [clearable]="false"
                         (change)="changeAndUpdateConsultantCompany()">
                         <ng-template ng-option-tmp let-item="item" let-index="i">
                          <div class="media mb-1">
                            <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                              [src]="item.photo" (error)="item.photo = globals.companyAvatar" />
                            <div class="media-body">
                              <div class="media-body-title mt-1 mb-0"><b>{{item.name}}</b></div>
                            </div>
                          </div>
                        </ng-template>
                      </ng-select>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label>Lead Consultant</label>
                    </div>
                    <div class="card-body-content">
                      <ng-select [items]="consultants" [clearable]="true"
                        placeholder="Select consultant" bindLabel="name" (open)="getConsultants()"
                        (change)="setLeadConsultant($event)" [(ngModel)]="job.lead_consultant">
                        <ng-template ng-option-tmp let-item="item" let-index="i">
                          <div class="media mb-1">
                            <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                              [src]="item.picture_file_url"
                              (error)="item.picture_file_url = globals.avatar" />
                            <div class="media-body">
                              <div class="media-body-title mt-1 mb-0"><b>{{item.name}}</b></div>
                              <span>{{item.email}}</span>
                            </div>
                          </div>
                        </ng-template>
                      </ng-select>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label>Locations</label>
                    </div>
                    <div class="card-body-content">
                      <div class="row">
                        <div class="col-12">
                          <ng-select [items]="locations$ | async" bindLabel="description" placeholder="Select location"
                            dropdownPosition="bottom" typeToSearchText="Type at least 3 characters to search"
                            [(ngModel)]="job.location" [minTermLength]="minLengthTerm"
                            [ngModelOptions]="{standalone: true}" [loading]="locationLoading"
                            [typeahead]="locationInput$" (change)="editJob('location' ,job.location)">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-6">
                        <div class="card-body-title-basic">
                          <label>
                            * Functional expertise
                          </label>
                        </div>
                        <ng-select [items]="resources.fe" bindLabel="description" dropdownPosition="bottom" [clearable]="true"
                          placeholder="Select functional expertise"  [(ngModel)]="job.fe" [clearable]="false" (change)="onFEChange(); editJob('fe', job.fe)">
                        </ng-select>
                      </div>
                      <div class="col-6">
                        <div class="card-body-title-basic">
                          <label>
                            Sub Functional expertise
                          </label>
                        </div>
                        <ng-select [items]="resources.sfe" bindLabel="description" dropdownPosition="bottom" [clearable]="true"
                          placeholder="Select sub functional expertise"  [(ngModel)]="job.sfe" (change)="editJob('sfe', job.sfe)">
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label>
                        * Seniority
                      </label>
                    </div>
                    <div class="card-body-content">
                      <div class="row">
                        <div class="col-12">
                          <ng-select [items]="resources.seniority" bindLabel="description" dropdownPosition="bottom" [clearable]="true"
                            placeholder="Select seniority"  [(ngModel)]="job.seniority" [clearable]="false" (change)="editJob('seniority', job.seniority)">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label>
                        Deadline to client
                      </label>
                    </div>
                    <div class="card-body-content">
                      <div class="row">
                        <div class="col-12">
                          <input type="date" class="form-control" data-date-format="YYYY MMMM DD"
                            [(ngModel)]="job.deadline_to_client" [ngModelOptions]="{standalone: true}"
                            (focusout)="changed ? editJob('deadline_to_client' ,job.deadline_to_client) : ''"
                            (ngModelChange)="onChange($event)">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label>Search Stage</label>
                    </div>
                    <div class="card-body-content">
                      <div class="row">
                        <div class="col-12">
                          <ng-select [items]="resources.searchStages" bindLabel="description" dropdownPosition="bottom"
                            placeholder="Select search stage" [(ngModel)]="job.search_stage"
                            (change)="editJob('search_stage' ,job.search_stage)">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label>Additional info</label>
                    </div>
                    <div class="card-body-content">
                      <div class="row">
                        <div class="col-12">
                          <quill-editor id="quill-editor-job" [(ngModel)]="job.additional_info"
                            (focusout)="changed ? editJob('additional_info' ,job.additional_info) : ''"
                            (ngModelChange)="onChange($event)" placeholder="Add additional info">
                          </quill-editor>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
            <ngb-panel id="team" class="mt-2">
              <ng-template ngbPanelHeader>
                <div class="form" (click)="acc.toggle('team')">
                  <div class="form-section">
                    Team
                    <div
                      class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2 ml-2"
                      (click)="editForm('team')">
                      edit
                    </div>
                    <div
                      class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2"
                      (click)="addUser()">
                      add
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="card-block" *ngIf="add.team">
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label>Users</label>
                    </div>
                    <ng-select #usersSelect [items]="resources.internalUsers" [clearable]="true"
                      placeholder="Select an user" bindLabel="name" (open)="getInternalUsers()"
                      (change)="setPersonData($event)">
                      <ng-template ng-option-tmp let-item="item" let-index="i">
                        <div class="media mb-1">
                          <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                            [src]="item.picture_file_url" (error)="item.picture_file_url = globals.avatar" />
                          <div class="media-body">
                            <div class="media-body-title mt-1 mb-0"><b>{{item.name}}</b></div>
                            <span>{{item.email}}</span>
                          </div>
                        </div>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
                <div class="card-block">
                  <div class="row" *ngIf="job.users.length > 0; else showEmptyTeam">
                    <div class="col-5">
                      <label class="card-body-title-basic">
                        <strong>Users</strong>
                      </label>
                    </div>
                    <div class="col-6">
                      <label class="card-body-title-basic">
                        <strong>Email</strong>
                      </label>
                    </div>
                  </div>
                  <div class="row" *ngFor="let user of job.users; let i = index">
                    <div class="col-5">
                      <div class="form-group card-body-content">
                        <p class="m-0">{{user.name || user.first_name + " " +user.last_name}} <strong
                            *ngIf="!edit.team"> {{job.project_leads.indexOf(user.uuid) != -1 ? (job.sourcing_leads.indexOf(user.uuid) != -1 ? ' (Iteration Project & Sourcing Lead)' : ' (Iteration Project Lead)') : (job.sourcing_leads.indexOf(user.uuid) != -1 ? ' (Iteration Sourcing Lead)' : '')}}</strong></p>
                      </div>
                    </div>
                    <div class="col-6">
                      <label class="card-body-content">
                        {{user.email}}
                      </label>
                    </div>
                    <div class="col-1 pull-right">
                      <i class="tt-icon-lg fa fa-trash-o" data-toggle="tooltip" data-placement="bottom"
                        (click)="deleteCard(i,'users')" title="delete user" *ngIf="edit.team"></i>
                    </div>
                  </div>
                  <ng-template #showEmptyTeam>
                    No team information available.
                  </ng-template>
                </div>
              </ng-template>
            </ngb-panel>
            <ngb-panel id="compensation" class="mt-2">
              <ng-template ngbPanelHeader>
                <div class="form" (click)="acc.toggle('compensation')">
                  <div class="form-section">
                    Compensation
                    <div
                      class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2"
                      (click)="editForm('compensation')">
                      edit
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent *ngIf="!edit.compensation">
                <div class="card-block">
                  <div class="row">
                    <div class="col-4">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>Annual Salary</label>
                        </div>
                        <div class="card-body-content">
                          {{(!isNaN(job.compensation.annual_salary_amount) && job.compensation.annual_salary_amount !== null) ? job.compensation.annual_salary_amount : 'No information available.'}}
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>Annual salary currency</label>
                        </div>
                        <div class="card-body-content">
                          {{job.compensation.annual_salary_currency ? job.compensation.annual_salary_currency : 'No
                          information available.'}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>Signing cash bonus amount</label>
                        </div>
                        <div class="card-body-content">
                          {{(!isNaN(job.compensation.signing_cash_bonus_amount) && job.compensation.signing_cash_bonus_amount !== null)  ? job.compensation.signing_cash_bonus_amount : 'No information available.'}}
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>Signing cash bonus currency</label>
                        </div>
                        <div class="card-body-content">
                          {{job.compensation.signing_cash_bonus_currency ? job.compensation.signing_cash_bonus_currency
                          : 'No information available.'}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>Equities percent</label>
                        </div>
                        <div class="card-body-content">
                          {{(!isNaN(job.compensation.equities.percent)  && job.compensation.equities.percent !== null) ? job.compensation.equities.percent : 'No information available.'}}
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>Equities amount estimation</label>
                        </div>
                        <div class="card-body-content">
                          {{(!isNaN(job.compensation.equities.amount_estimation) && job.compensation.equities.amount_estimation !== null) ? job.compensation.equities.amount_estimation : 'No information available.'}}
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>Equities amount currency</label>
                        </div>
                        <div class="card-body-content">
                          {{job.compensation.equities.amount_currency || 'No information available.'}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="form-group">
                        <div class="card-body-title-basic">
                          <label>Other</label>
                        </div>
                        <div class="card-body-content">
                          {{job.compensation.other || 'No information available.'}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent *ngIf="edit.compensation">
                <div class="card-block">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12 col-lg-6">
                        <div class="card-body-title-basic">
                          <label>Annual Salary</label>
                        </div>
                        <div class="card-body-content">
                          <input type="number" class="form-control" min="0" placeholder="Insert annual salary"
                            [(ngModel)]="job.compensation.annual_salary_amount"
                            (focusout)="changed ? editJob('compensation', job.compensation): ''"
                            (ngModelChange)="onChange($event)">
                        </div>
                      </div>
                      <div class="col-md-12 col-lg-6">
                        <div class="card-body-title-basic">
                          <label>Annual salary currency</label>
                        </div>
                        <div class="card-body-content">
                          <ng-select [items]="resources.currencies" bindValue="value" bindLabel="description"
                            dropdownPosition="bottom" [(ngModel)]="job.compensation.annual_salary_currency"
                            [ngModelOptions]="{standalone: true}" placeholder="Select currency"
                            (change)="editJob('compensation', job.compensation)">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12 col-lg-6">
                        <div class="card-body-title-basic">
                          <label>Signing cash bonus amount</label>
                        </div>
                        <div class="card-body-content">
                          <input type="number" class="form-control" min="0"
                            placeholder="Insert signing cash bonus amount"
                            [(ngModel)]="job.compensation.signing_cash_bonus_amount"
                            (focusout)="changed ? editJob('compensation', job.compensation) : ''"
                            (ngModelChange)="onChange($event)">
                        </div>
                      </div>
                      <div class="col-md-12 col-lg-6">
                        <div class="card-body-title-basic">
                          <label>Signing cash bonus currency</label>
                        </div>
                        <div class="card-body-content">
                          <ng-select [(ngModel)]="job.compensation.signing_cash_bonus_currency" bindValue="value"
                            bindLabel="description" [items]="resources.currencies" dropdownPosition="bottom"
                            placeholder="Select signing cash bonus currency"
                            (change)="editJob('compensation', job.compensation)">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-12">
                        <div class="card-body-title-basic">
                          <label>Equities percent</label>
                        </div>
                        <div class="card-body-content">
                          <input type="number" class="form-control" min="0" placeholder="Insert equities pecent"
                            [(ngModel)]="job.compensation.equities.percent"
                            (focusout)="changed ? editJob('compensation', job.compensation) : ''"
                            (ngModelChange)="onChange($event)">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-12">
                        <div class="card-body-title-basic">
                          <label>Equities amount estimation</label>
                        </div>
                        <div class="card-body-content">
                          <input type="number" class="form-control" min="0"
                            placeholder="Insert equities amount estimation"
                            [(ngModel)]="job.compensation.equities.amount_estimation"
                            (focusout)="changed ? editJob('compensation', job.compensation) : ''"
                            (ngModelChange)="onChange($event)">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-12">
                        <div class="card-body-title-basic">
                          <label>Equities amount currency</label>
                        </div>
                        <div class="card-body-content">
                          <ng-select [(ngModel)]="job.compensation.equities.amount_currency" bindValue="value"
                            bindLabel="description" [items]="resources.currencies" dropdownPosition="bottom"
                            placeholder="Select equties currency" (change)="editJob('compensation', job.compensation)">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="card-body-title-basic">
                      <label>Other</label>
                    </div>
                    <div class="card-body-content">
                      <div class="row">
                        <div class="col-12">
                          <textarea rows="2" class="form-control" type="text" placeholder="Insert other information"
                            [(ngModel)]="job.compensation.other"
                            (focusout)="changed ? editJob('compensation', job.compensation) : ''"
                            (ngModelChange)="onChange($event)">
                          </textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </ng-template>
    </ng-container>
    <ng-container [ngbNavItem]="'ngb-tab-1'">
      <a ngbNavLink>
        <div>Search Strategy</div>
      </a>
      <ng-template ngbNavContent>
        <div class="card card-top scroll scroll-tabs">
          <ngb-accordion id="filter-accordion" [(activeIds)]="activeId" #acc="ngbAccordion" class="ng-accordion-append"
            (panelChange)="preventDefault ? beforeChange($event) : ''">
            <ngb-panel id="blacklisted_companies" class="mt-2">
              <ng-template ngbPanelHeader>
                <div class="form" (click)="acc.toggle('blacklisted_companies')">
                  <div class="form-section">
                    Blacklisted companies
                    <div
                      class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2"
                      (click)="editForm('blacklisted_companies')">
                      edit
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="row card-block" *ngIf="edit.blacklisted_companies">
                  <div class="col-12">
                    <div class="form-group">
                      <div class="card-body-content">
                        <ng-select #blacklistSelect [items]="companies$ | async" bindLabel="name" [hideSelected]="true"
                          placeholder="Select company" typeToSearchText="Type at least 3 characters to search"
                          [multiple]="true" [loading]="companyLoading" [typeahead]="companyinput$"
                          [minTermLength]="minLengthTerm" [(ngModel)]="job.blacklisted_companies"
                          dropdownPosition="bottom" [clearable]="true" [addTag]="addTagCompany"
                          [ngModelOptions]="{standalone: true}"
                          (change)="editJob('blacklisted_companies', job.blacklisted_companies)">
                          <ng-template ng-option-tmp let-item="item" let-index="i">
                            <div class="media mb-1">
                              <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                                [src]="item.images['32x32'] ? item.images['32x32'] : globals.companyAvatar"
                                (error)="item.images = globals.companyAvatar" />
                              <div class="media-body">
                                <div class="media-body-title mt-1 mb-0">{{item.name}}, {{item.website_url}}</div>
                                <p *ngIf="item.hq_locations.length"><i class="tt-icon-sm icon-ttrelocation"></i>
                                  <span *ngFor="let location of item.hq_locations;let last = last">
                                    {{location.address}} {{!last ? ' (HQ), ' : ' (HQ)'}}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </ng-template>
                          <ng-template ng-tag-tmp let-search="searchTerm">
                            <button class="btn bg-secondary-color btn-position pull-right mb-0">Add company
                              {{search}}</button>
                          </ng-template>
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="custom-control custom-checkbox mt-2">
                      <input type="checkbox" [ngModelOptions]="{standalone: true}" id="include_company_blacklisted_companies"
                        [(ngModel)]="job.include_company_blacklisted_companies" class="custom-control-input"
                        (ngModelChange)="editJob('include_company_blacklisted_companies', job.include_company_blacklisted_companies)">
                      <label class="custom-control-label cursor-pointer" for="include_company_blacklisted_companies">
                        Include consultant company blacklisted companies
                      </label>
                    </div>
                  </div>
                  <div class="col-12 d-flex align-items-center justify-content-center m-3 ">
                    <span class="text-color-700">Bulk import companies from .csv</span>
                    <button class="btn btn-sm bg-secondary-color btn-position mb-0 ml-2"
                      (click)="blacklistedFileInput.click()">
                      choose .csv
                      <span *ngIf="csv_loading" class="badge-icon-sm icon icon-loading icon-sm ml-1"></span>
                    </button>
                    <input type="file" ng2FileSelect [uploader]="blacklistCsvUploader" #blacklistedFileInput
                      (click)="onFileClick($event)" (change)="onBlacklistedCompanyInsertCSV()" class="d-none">
                  </div>
                </div>
                <div *ngIf="job.blacklisted_companies.length; then showBlacklisted; else showEmptyBlacklisted"></div>
                <ng-template #showBlacklisted>
                  <div class="row card-block" *ngIf="!edit.blacklisted_companies">
                    <div class="col-12" *ngFor="let company of job.blacklisted_companies">
                      <div class="media mb-1">
                        <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                          [src]="(company.images && company.images['32x32']) || (company.dealroom_data?.images?.['32x32']) || globals.companyAvatar"
                          (error)="company.images = globals.companyAvatar" />
                        <div class="media-body">
                          <div class="media-body-title mt-1 mb-0">
                            <app-dealroom-widget [companyName]="company.name"></app-dealroom-widget>
                          </div>
                          <p
                            *ngIf="(company.hq_locations && company.hq_locations.length) ||
                            (company.dealroom_data ? company.dealroom_data.hq_locations && company.dealroom_data.hq_locations.length : '');else showEmptyLocation">
                            <i class="tt-icon-sm icon-ttrelocation"></i>
                            <span
                              *ngFor="let location of (company.hq_locations || company.dealroom_data.hq_locations);let last = last">
                              {{location.address}} {{!last ? ' (HQ), ' : ' (HQ)'}}
                            </span>
                          </p>
                          <ng-template #showEmptyLocation>
                            <p>No location available</p>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template #showEmptyBlacklisted>
                  <div *ngIf="!edit.blacklisted_companies" class="row card-block">
                    <div class="col-12">
                      No companies available
                    </div>
                  </div>
                </ng-template>
              </ng-template>
            </ngb-panel>
            <ngb-panel id="blacklisted_candidates" class="mt-2">
              <ng-template ngbPanelHeader>
                <div class="form" (click)="acc.toggle('blacklisted_candidates')">
                  <div class="form-section">
                    Blacklisted candidates
                    <div
                      class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2"
                      (click)="editForm('blacklisted_candidates')">
                      edit
                    </div>
                    <div class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2 mr-2"
                      (click)="addForm('blacklisted_candidates')">
                      add
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="row card-block" *ngIf="add.blacklisted_candidates">
                  <div class="col-12">
                    <div class="form-group">
                      <div class="card-body-content">
                        <div class="form-group">
                          <input class="form-control" type="text" [(ngModel)]="blacklisted_candidate"
                            [ngModelOptions]="{standalone: true}" placeholder="+ blacklisted candidates"
                            (keyup.enter)="addBlacklistedCandidate()" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row card-block" *ngIf="edit.blacklisted_candidates">
                  <div class="col-12">
                    <div class="form-group">
                      <div class="card-body-content">
                        <ul class="media-list media-bordered pl-0">
                          <li class="media "*ngFor="let blacklisted_candidate of job.blacklisted_candidates; let i = index">
                            <div class="col-11">
                              <a [href]="blacklisted_candidate" target="_blank">{{blacklisted_candidate}}</a>
                            </div>
                            <div class="col-1">
                              <i class="tt-icon-sm icon-ttdelete pull-right m-2" (click)="deleteBlacklistedCandidate(i)"></i>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="custom-control custom-checkbox mt-2">
                      <input type="checkbox" [ngModelOptions]="{standalone: true}" id="include_company_blacklisted_candidates"
                        [(ngModel)]="job.include_company_blacklisted_candidates" class="custom-control-input"
                        (ngModelChange)="editJob('include_company_blacklisted_candidates', job.include_company_blacklisted_candidates)">
                      <label class="custom-control-label cursor-pointer" for="include_company_blacklisted_candidates">
                        Include consultant company blacklisted candidates
                      </label>
                    </div>
                  </div>
                </div>
                <div *ngIf="job.blacklisted_candidates.length; then showBlacklistedCandidates; else showEmptyBlacklistedCandidates"></div>
                <ng-template #showBlacklistedCandidates>
                  <div class="row card-block" *ngIf="!edit.blacklisted_candidates">
                    <div class="col-12" *ngFor="let candidate of job.blacklisted_candidates">
                      <a [href]="candidate" target="_blank">{{candidate}}</a>
                    </div>
                  </div>
                </ng-template>
                <ng-template #showEmptyBlacklistedCandidates>
                  <div *ngIf="!edit.blacklisted_candidates" class="row card-block">
                    <div class="col-12">
                      No candidates available
                    </div>
                  </div>
                </ng-template>
              </ng-template>
            </ngb-panel>
            <ngb-panel id="archetypes" class="mt-2 mb-5">
              <ng-template ngbPanelHeader>
                <div class="form" (click)="acc.toggle('archetypes')">
                  <div class="form-section">
                    Personas
                    <div
                      class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2 ml-2"
                      (click)="editForm('archetypes')">
                      edit
                    </div>
                    <div
                      class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2"
                      (click)="addArchetypeForm()" *ngIf="!add.archetype">
                      add
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="form-group" *ngIf="add.archetype">
                  <div class="card card-preview">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Name
                            </label>
                          </div>
                          <div class="card-body-content">
                            <input type="text" class="form-control" placeholder="Insert persona name"
                              [(ngModel)]="archetype.name" [ngModelOptions]="{standalone: true}">
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Description
                            </label>
                          </div>
                          <div class="card-body-content">
                            <input type="text" class="form-control" placeholder="Insert persona description"
                              [(ngModel)]="archetype.description" [ngModelOptions]="{standalone: true}">
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div
                          class="btn btn-sm d-flex align-items-center justify-content-center bg-primary-color pull-right mt-2  ml-2"
                          (click)="cancelAdd('archetype')">
                          Close
                        </div>
                        <div
                          class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2"
                          (click)="addNewArchetype()">
                          Add new persona
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="job.archetypes.length; then showArchetypes; else showEmptyArchetypes">
                </div>
                <ng-template #showArchetypes>
                  <div class="form-group" *ngFor="let archetype of job.archetypes; let i = index">
                    <div class="card card-preview">
                      <div class="row">
                        <div class="col-11">
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>
                                Name
                              </label>
                            </div>
                            <div class="card-body-content" *ngIf="!edit.archetypes">
                              {{archetype.name}}
                            </div>
                            <div class="card-body-content" *ngIf="edit.archetypes">
                              <input type="text" class="form-control" placeholder="Insert persona name"
                                [(ngModel)]="archetype.name" [ngModelOptions]="{standalone: true}"
                                (focusout)="changed ? updateArchetype('archetypes', i) : ''"
                                (ngModelChange)="onChange($event)">
                            </div>
                          </div>
                        </div>
                        <div class="col-1">
                          <i class="tt-icon-lg fa fa-trash-o" *ngIf="edit.archetypes" data-toggle="tooltip"
                            data-placement="bottom" (click)="deleteArchetype(i,'archetypes')"
                            title="delete persona"></i>
                        </div>
                        <div class="col-11">
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>
                                Description
                              </label>
                            </div>
                            <div class="card-body-content" *ngIf="!edit.archetypes">
                              {{archetype.description}}
                            </div>
                            <div class="card-body-content" *ngIf="edit.archetypes">
                              <input type="text" class="form-control" placeholder="Insert persona name"
                                [(ngModel)]="archetype.description" [ngModelOptions]="{standalone: true}"
                                (focusout)="changed ? updateArchetype('archetypes', i) : ''"
                                (ngModelChange)="onChange($event)">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template #showEmptyArchetypes>
                  <div class="row card-block">
                    <div class="col-12">
                      No personas available
                    </div>
                  </div>
                </ng-template>
              </ng-template>
            </ngb-panel>
            <ngb-panel id="hunting_grounds" class="mt-2">
              <ng-template ngbPanelHeader>
                <div class="form" (click)="acc.toggle('hunting_grounds')">
                  <div class="form-section">
                    Hunting Grounds
                    <div
                      class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2 ml-2"
                      (click)="editForm('hunting_grounds')">
                      edit
                    </div>
                    <div
                      class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2"
                      (click)="addHuntingGroundsForm()" *ngIf="!add.hunting_ground">
                      add
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="form-group" *ngIf="add.hunting_ground">
                  <div class="card card-preview">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Name
                            </label>
                          </div>
                          <div class="card-body-content">
                            <input type="text" class="form-control" placeholder="Insert hunting ground name"
                              [(ngModel)]="archetype.name" [ngModelOptions]="{standalone: true}">
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Description
                            </label>
                          </div>
                          <div class="card-body-content">
                            <input type="text" class="form-control" placeholder="Insert hunting ground description"
                              [(ngModel)]="archetype.description" [ngModelOptions]="{standalone: true}">
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Companies
                            </label>
                          </div>
                          <div class="card-body-content">
                            <ng-select #blacklistAddFormNgSelect [items]="companies$ | async" bindLabel="name"
                              [hideSelected]="true" placeholder="Select company"
                              typeToSearchText="Type at least 3 characters to search" [multiple]="true"
                              [loading]="companyLoading" [typeahead]="companyinput$" [minTermLength]="minLengthTerm"
                              [(ngModel)]="archetype.companies" dropdownPosition="bottom" [clearable]="true"
                              [addTag]="addCustomCompanyForNewHg.bind(this)" [ngModelOptions]="{standalone: true}">
                              <ng-template ng-option-tmp let-item="item" let-index="i">
                                <div class="media mb-1">
                                  <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                                    [src]="item.images['32x32'] ? item.images['32x32'] : globals.companyAvatar"
                                    (error)="item.images = globals.companyAvatar" />
                                  <div class="media-body">
                                    <div class="media-body-title mt-1 mb-0">{{item.name}}, {{item.website_url}}</div>
                                    <p *ngIf="item.hq_locations.length"><i class="tt-icon-sm icon-ttrelocation"></i>
                                      <span *ngFor="let location of item.hq_locations;let last = last">
                                        {{location.address}} {{!last ? ' (HQ), ' : ' (HQ)'}}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </ng-template>
                              <ng-template ng-tag-tmp let-search="searchTerm">
                                <button class="btn bg-secondary-color btn-position pull-right mb-0">Add company
                                  {{search}}</button>
                              </ng-template>
                            </ng-select>
                            <div class="col-12 d-flex align-items-center justify-content-center m-3 drag-drop-zone">
                              <span class="text-color-700">Bulk import companies from .csv</span>
                              <button class="btn btn-sm bg-secondary-color btn-position mb-0 ml-2"
                                (click)="fileInput.click()">
                                choose .csv
                                <span *ngIf="csv_loading && fileIndex === i"
                                  class="badge-icon-sm icon icon-loading icon-sm ml-1"></span>
                              </button>
                              <input type="file" ng2FileSelect [uploader]="csv_uploader" #fileInput
                                (click)="onFileClick($event)" (change)="OnContentChangeCsv($event, i)" class="d-none">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div
                          class="btn btn-sm d-flex align-items-center justify-content-center bg-primary-color pull-right mt-2  ml-2"
                          (click)="cancelAdd('hunting_ground')">
                          Close
                        </div>
                        <div
                          class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2"
                          (click)="addNewArchetype()">
                          Add new hunting ground
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="job.hunting_grounds.length; then showHuntingGrounds; else showEmptyHuntingGrounds"></div>
                <ng-template #showHuntingGrounds>
                  <div class="form-group" *ngFor="let huntingGround of job.hunting_grounds; let i = index">
                    <div class="card card-preview">
                      <div class="row">
                        <div class="col-11">
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>
                                Name
                              </label>
                            </div>
                            <div class="card-body-content" *ngIf="!edit.hunting_grounds">
                              {{huntingGround.name}}
                            </div>
                            <div class="card-body-content" *ngIf="edit.hunting_grounds">
                              <input type="text" class="form-control" placeholder="Insert hunting ground name"
                                [(ngModel)]="huntingGround.name" [ngModelOptions]="{standalone: true}"
                                (focusout)="changed ? updateArchetype('hunting_grounds', i) : ''"
                                (ngModelChange)="onChange($event)">
                            </div>
                          </div>
                        </div>
                        <div class="col-1">
                          <i class="tt-icon-lg fa fa-trash-o" data-toggle="tooltip" data-placement="bottom"
                            *ngIf="edit.hunting_grounds" (click)="deleteArchetype(i,'hunting_grounds')"
                            title="delete hunting ground"></i>
                        </div>
                        <div class="col-11">
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>
                                Description
                              </label>
                            </div>
                            <div class="card-body-content" *ngIf="!edit.hunting_grounds">
                              {{huntingGround.description}}
                            </div>
                            <div class="card-body-content" *ngIf="edit.hunting_grounds">
                              <input type="text" class="form-control" placeholder="Insert hunting ground description"
                                [(ngModel)]="huntingGround.description" [ngModelOptions]="{standalone: true}"
                                (focusout)="changed ? updateArchetype('hunting_grounds', i) : ''"
                                (ngModelChange)="onChange($event)">
                            </div>
                          </div>
                        </div>
                        <div class="col-11">
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>
                                Companies
                              </label>
                            </div>
                            <div class="card-body-content" *ngIf="!edit.hunting_grounds">
                              <div *ngFor="let company of huntingGround.companies">
                                <div class="media mb-1">
                                  <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                                    [src]="company.images ? company.images['32x32'] : ((company.dealroom_data && company.dealroom_data.images) ? company.dealroom_data.images['32x32'] : globals.companyAvatar) "
                                    (error)="company.images = globals.companyAvatar" />
                                  <div class="media-body">
                                    <div class="media-body-title mt-1 mb-0">
                                      <app-dealroom-widget
                                        [title]="company.uuid ? 'Linked successfully' : 'Not linked to company entity'"
                                        [companyName]="company.name" [drawerCompany]="company"></app-dealroom-widget>
                                    </div>
                                    <p
                                      *ngIf="(company.hq_locations && company.hq_locations.length) ||
                                      (company.dealroom_data ? company.dealroom_data.hq_locations && company.dealroom_data.hq_locations.length : '');else showEmptyLocation">
                                      <i class="tt-icon-sm icon-ttrelocation"></i>
                                      <span
                                        *ngFor="let location of (company.hq_locations || company.dealroom_data.hq_locations);let last = last">
                                        {{location.address}} {{!last ? ' (HQ), ' : ' (HQ)'}}
                                      </span>
                                    </p>
                                    <ng-template #showEmptyLocation>
                                      <p>No location available</p>
                                    </ng-template>
                                  </div>
                                </div>
                              </div>
                              <div *ngIf="!huntingGround.companies.length">
                                No companies information available
                              </div>
                            </div>
                            <div class="card-body-content" *ngIf="edit.hunting_grounds">
                              <ng-select #editHuntingGroundSelect [items]="companies$ | async"
                                bindLabel="name" [hideSelected]="true" placeholder="Select company"
                                typeToSearchText="Type at least 3 characters to search" [multiple]="true"
                                [loading]="companyLoading" [typeahead]="companyinput$" [minTermLength]="minLengthTerm"
                                [(ngModel)]="huntingGround.companies" dropdownPosition="bottom" [clearable]="true"
                                [addTag]="addCustomHuntingGroundCompany" [ngModelOptions]="{standalone: true}"
                                (open)="saveHuntingGroundUuid(huntingGround.uuid)"
                                (add)="addHuntingGroundCompany($event)"
                                (remove)="removeHuntingGroundCompany($event)">
                                <ng-template ng-option-tmp let-item="item" let-index="i">
                                  <div class="media mb-1">
                                    <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                                      [src]="item.images['32x32'] ? item.images['32x32'] : globals.companyAvatar"
                                      (error)="item.images = globals.companyAvatar" />
                                    <div class="media-body">
                                      <div class="media-body-title mt-1 mb-0">{{item.name}}, {{item.website_url}}</div>
                                      <p *ngIf="item.hq_locations.length"><i class="tt-icon-sm icon-ttrelocation"></i>
                                        <span *ngFor="let location of item.hq_locations;let last = last">
                                          {{location.address}} {{!last ? ' (HQ), ' : ' (HQ)'}}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </ng-template>
                                <ng-template ng-tag-tmp let-search="searchTerm">
                                  <button class="btn bg-secondary-color btn-position pull-right mb-0">Add company
                                    {{search}}</button>
                                </ng-template>
                              </ng-select>
                              <div class="col-12 d-flex align-items-center justify-content-center m-3 ">
                                <span class="text-color-700">Bulk import companies from .csv</span>
                                <button class="btn btn-sm bg-secondary-color btn-position mb-0 ml-2"
                                  (click)="fileInput.click()">
                                  choose .csv
                                  <span *ngIf="csv_loading && fileIndex === i"
                                    class="badge-icon-sm icon icon-loading icon-sm ml-1"></span>
                                </button>
                                <input type="file" ng2FileSelect [uploader]="csv_uploader" #fileInput
                                  (click)="onFileClick($event)" (change)="OnContentChangeCsv($event, i)" class="d-none">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template #showEmptyHuntingGrounds>
                  <div class="row card-block">
                    <div class="col-12">
                      No hunting grounds available
                    </div>
                  </div>
                </ng-template>
              </ng-template>
            </ngb-panel>
            <ngb-panel id="scorecards" class="mt-2">
              <ng-template ngbPanelHeader>
                <div class="form" (click)="acc.toggle('scorecards')">
                  <div class="form-section">
                    Scorecards
                    <div
                      class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2 ml-2"
                      (click)="editForm('scorecards')">
                      edit
                    </div>
                    <div
                      class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2"
                      (click)="addScorecardForm()" *ngIf="!add.scorecards">
                      add
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="form-group" *ngIf="add.scorecard">
                  <div class="card card-preview">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="card-body-title-basic">
                            <label>
                              Description
                            </label>
                          </div>
                          <div class="card-body-content">
                            <input class="form-control" placeholder="Insert scorecard description" type="text"
                              [(ngModel)]="addScorecard" onFocusOut>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div
                          class="btn btn-sm d-flex align-items-center justify-content-center bg-primary-color pull-right mt-2  ml-2"
                          (click)="cancelAdd('scorecard')">
                          Close
                        </div>
                        <div
                          class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2"
                          (click)="addNewScorecard()">
                          Add new scorecard
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="job.scorecards.length; then showScorecards; else showEmptyScorecards"></div>
                <ng-template #showScorecards>
                  <div class="card-block mt-2" *ngIf="edit.scorecards">
                    <div class="form-group" *ngFor="let scorecard of job.scorecards; let i = index">
                      <div class="row">
                        <div class="col-11">
                          <input class="form-control" type="text" [(ngModel)]="scorecard.description" appOnFocusOut
                            (focusout)="changed ? editJob('scorecards' ,job.scorecards) : ''"
                            (ngModelChange)="onChange($event)">
                        </div>
                        <div class="col-1">
                          <a (click)="deleteCard(i,'scorecards')">
                            <i class="tt-icon-sm icon-ttdelete pull-right m-2" data-toggle="tooltip"
                              data-placement="bottom"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-block mt-2" *ngIf="!edit.scorecards">
                    <div class="form-group" *ngFor="let scorecard of job.scorecards; let i = index">
                      <p>{{scorecard.description}}</p>
                    </div>
                  </div>
                </ng-template>
                <ng-template #showEmptyScorecards>
                  <div class="row card-block">
                    <div class="col-12">
                      No scorecards information available.
                    </div>
                  </div>
                </ng-template>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </ng-template>
    </ng-container>
    <ng-container [ngbNavItem]="'ngb-tab-2'">
      <a ngbNavLink>Files</a>
      <ng-template ngbNavContent>
        <div class="card card-top scroll scroll-tabs">
          <ngb-accordion [(activeIds)]="filesActiveId" id="filter-accordion" #accFiles="ngbAccordion"
            class="ng-accordion-append" (panelChange)="preventDefault ? beforeChange($event) : ''">
            <ngb-panel id="files">
              <ng-template ngbPanelHeader>
                <div class="form" (click)="accFiles.toggle('files')">
                  <div class="form-section">
                    Files
                    <div
                      class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2 ml-2"
                      (click)="editForm('files')">
                      edit
                    </div>
                    <div
                      class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2"
                      (click)="toogleAddFileFlag()">
                      add
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div *ngIf="addFileFlag">
                  <div class="form-group">
                    <div class="card card-preview">
                      <div class="row">
                        <div class="col-12">
                          <div class="form-group">
                            <div class="card-body-title-basic">
                              <label>
                                File Type
                              </label>
                            </div>
                            <div class="card-body-content">
                              <ng-select class="ng-append" appendTo=".ng-accordion-append" [(ngModel)]="add.file.type"
                                [ngModelOptions]="{standalone: true}" bindLabel="description"
                                [items]="resources.jobFileTypes" dropdownPosition="bottom"
                                placeholder="Select File Type" (open)="getFileTypes()" [clearable]="false">
                              </ng-select>
                            </div>
                          </div>
                          <div class="form-group" *ngIf="add.file.type.value">
                            <div class="card-body-content">
                              <div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
                                (fileOver)="fileOverBase($event)" [uploader]="uploader"
                                class="p-3 text-center grey my-drop-zone">
                                <span>Drag and drop CV here<br>or<br></span>
                                <button class="btn bg-primary-color col-md-3 col-lg-3 col-xl-3 btn-position"
                                  (click)="fileInputFile.click()">
                                  Upload file
                                </button>
                              </div>
                              <input style="display: none;"
                                class="hiddenInput m-0 p-0 position-absolute overflow-hidden" type="file" ng2FileSelect
                                [uploader]="uploader" #fileInputFile (change)="OnContentChangeFile()">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="job.files.length; then showFiles else showEmptyFiles"></div>
                <ng-template #showFiles>
                  <div class="form-group" *ngFor="let file of job.files;let i = index">
                    <div *ngIf="edit.files">
                      <div class="card card-preview">
                        <div class="card-block">
                          <div class="col-12">
                            <a class="pull-right" (click)="deleteCard(i,'files')">
                              <a><i class="tt-icon-lg icon-ttdelete secondary-color" data-toggle="tooltip"
                                  data-placement="bottom" title="delete"></i></a>
                            </a>
                          </div>
                          <div class="col-10">
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                File type
                              </div>
                              <div class="card-body-content">
                                <ng-select class="ng-append" appendTo=".ng-accordion-append" [(ngModel)]="file.type"
                                  [ngModelOptions]="{standalone: true}" bindLabel="description"
                                  [items]="resources.jobFileTypes" dropdownPosition="auto"
                                  placeholder="Insert file type" (open)="getFileTypes()"
                                  (change)="file.type ? editJob('files', job.files) : ''" [clearable]="false">
                                </ng-select>
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="card-body-title-basic">
                                File name
                              </div>
                              <div class="card-body-content">
                                <div class="card-body-content full-width display-inline-flex">
                                  <input type="text" class="form-control col-10" placeholder="Insert file name"
                                    [ngModel]="file.name.substr(0, file.name.indexOf('.'))"
                                    [ngModelOptions]="{standalone: true}" appOnFocusOut
                                    (change)="file.name ? removeSpacesFileName(file, i): ''"
                                    (ngModelChange)="onChange(file.tempName = $event)">

                                  <div class="col-2 align-self-center text-center">
                                    {{file.name.substr(file.name.lastIndexOf('.'))}}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group" *ngIf="'cv/resume' == file.type.value">
                              <div class="card-body-content">
                                <div class="custom-control custom-checkbox m-0">
                                  <input type="checkbox" [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="file.is_primary" class="custom-control-input" id="primary-CV+{{i}}">
                                  <label class="custom-control-label" for="primary-CV+{{i}}">Primary CV
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="!edit.files">
                      <div class="form-group">
                        <div class="card-block">
                          <div class="row">
                            <div class="col-1">
                              <a [href]="file.url" target="_blank">
                                <div *ngIf="file.name">
                                  <div [ngSwitch]="file.name.substr(file.name.lastIndexOf('.')+1).toLowerCase()">
                                    <div *ngSwitchCase="'pdf'">
                                      <i class="fa fa-file-pdf-o tt-icon-xl primary-color m-2" aria-hidden="true"></i>
                                    </div>
                                    <div *ngSwitchCase="'xlsx'">
                                      <i class="fa fa-file-excel-o tt-icon-xl text-success m-2" aria-hidden="true"></i>
                                    </div>
                                    <div *ngSwitchCase="'doc'">
                                      <i class="fa fa-file-word-o tt-icon-xl big-search-primary-color m-2"
                                        aria-hidden="true"></i>
                                    </div>
                                    <div *ngSwitchCase="'docx'">
                                      <i class="fa fa-file-word-o tt-icon-xl big-search-primary-color m-2"
                                        aria-hidden="true"></i>
                                    </div>
                                    <div *ngSwitchCase="'pptx'">
                                      <i class="fa fa-file-powerpoint-o tt-icon-xl warning-color m-2"
                                        aria-hidden="true"></i>
                                    </div>
                                    <div *ngSwitchCase="'png'">
                                      <i class="fa fa-file-image-o tt-icon-xl warning-color m-2" aria-hidden="true"></i>
                                    </div>
                                    <div *ngSwitchCase="'jpg'">
                                      <i class="fa fa-file-image-o tt-icon-xl warning-color m-2" aria-hidden="true"></i>
                                    </div>
                                    <div *ngSwitchCase="'jpeg'">
                                      <i class="fa fa-file-image-o tt-icon-xl warning-color m-2" aria-hidden="true"></i>
                                    </div>
                                    <div *ngSwitchDefault>
                                      <i class="fa fa-file-o tt-icon-xl m-2" aria-hidden="true"></i>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                            <div class="col-10">
                              <div class="form-group">
                                <div class="card-body-title-basic" *ngIf="file.type.description">
                                  {{file.type.description}}
                                  <span *ngIf="file.is_primary && 'cv/resume' == file.type.value"
                                    class="tag secondary-color ml-5">
                                    <em>Primary CV/Resume</em>
                                  </span>
                                </div>
                              </div>
                              <div class="form-group" *ngIf="file.name">
                                <div class="card-body-content">
                                  <a [href]="file.url" target="_blank" [ngClass]="{'secondary-color':file.url}">
                                    <u>{{file.name}}</u>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template #showEmptyFiles>
                  <div>
                    No information available.
                  </div>
                </ng-template>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </ng-template>
    </ng-container>
    <ng-container [ngbNavItem]="'ngb-tab-3'">
      <a ngbNavLink>Iterations</a>
      <ng-template ngbNavContent>
        <div class="card card-top scroll scroll-tabs">
          <ngb-accordion [(activeIds)]="activeId" id="filter-accordion" #accIterations="ngbAccordion"
            class="ng-accordion-append" (panelChange)="preventDefault ? beforeChange($event) : ''">
            <ngb-panel id="iterations">
              <ng-template ngbPanelHeader>
                <div class="form" (click)="accIterations.toggle('iterations')">
                  <div class="form-section">
                    Iterations
                    <div
                      class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2 ml-2"
                      (click)="editForm('iterations')">
                      edit
                    </div>
                    <div *ngIf="!add.iterations"
                      class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2"
                      (click)="addForm('iterations')">
                      add
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="row card-block" *ngIf="add.iterations">
                  <label class="card-body-title-basic">Iteration {{job.iterations.length + 1}}</label>
                  <div class="card-body-content col-12">
                    <div class="row">
                      <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                          <label class="card-body-title-basic">Iteration start date</label>
                          <input type="date" class="form-control" data-date-format="YYYY MMMM DD"
                            [(ngModel)]="addIteration.start_date" [ngModelOptions]="{standalone: true}"
                            (ngModelChange)="onChange($event)">
                          <label>*The default timezone is CEST</label>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                          <label class="card-body-title-basic">Iteration end date</label>
                          <input type="date" class="form-control" data-date-format="YYYY MMMM DD"
                            [(ngModel)]="addIteration.end_date" [ngModelOptions]="{standalone: true}"
                            (ngModelChange)="onChange($event)">
                          <label>*The default timezone is CEST</label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label class="card-body-title-basic">Project lead</label>
                          <ng-select #usersSelect [items]="resources.internalUsers" [clearable]="true"
                            placeholder="Select an user" bindLabel="name" (open)="getInternalUsers()"
                            [(ngModel)]="addIteration.project_lead">
                            <ng-template ng-option-tmp let-item="item" let-index="i">
                              <div class="media mb-1">
                                <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                                  [src]="item.picture_file_url" (error)="item.picture_file_url = globals.avatar" />
                                <div class="media-body">
                                  <div class="media-body-title mt-1 mb-0"><b>{{item.name}}</b></div>
                                  <span>{{item.email}}</span>
                                </div>
                              </div>
                            </ng-template>
                          </ng-select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label class="card-body-title-basic">Sourcing lead</label>
                          <ng-select #usersSelect [items]="resources.internalUsers" [clearable]="true"
                            placeholder="Select an user" bindLabel="name" (open)="getInternalUsers()"
                            [(ngModel)]="addIteration.sourcing_lead">
                            <ng-template ng-option-tmp let-item="item" let-index="i">
                              <div class="media mb-1">
                                <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                                  [src]="item.picture_file_url" (error)="item.picture_file_url = globals.avatar" />
                                <div class="media-body">
                                  <div class="media-body-title mt-1 mb-0"><b>{{item.name}}</b></div>
                                  <span>{{item.email}}</span>
                                </div>
                              </div>
                            </ng-template>
                          </ng-select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div
                          class="btn btn-sm d-flex align-items-center justify-content-center bg-primary-color pull-right mt-2  ml-2"
                          (click)="cancelAdd('iterations')">
                          Close
                        </div>
                        <div
                          class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2"
                          (click)="addNewIteration()">
                          Add new iteration
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row card-block" *ngIf="edit.iterations">
                  <div class="col-12">
                    <div class="form-group">
                      <div class="card-body-content">
                        <ul class="media-list media-bordered pl-0">
                          <li class="media "*ngFor="let iteration of job.iterations; let i = index">
                            <div class="col-11">
                              <label class="card-body-title-basic">Iteration {{iteration.iteration_number}}</label>
                              <div class="row">
                                <div class="col-lg-6 col-md-12">
                                  <label class="card-body-title-basic">Iteration start date</label>
                                  <input type="date" class="form-control" data-date-format="YYYY MMMM DD"
                                    [(ngModel)]="iteration.start_date" [ngModelOptions]="{standalone: true}"
                                    (change)="editIteration(i)">
                                  <label>*The default timezone is CEST</label>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                  <label class="card-body-title-basic">Iteration end date</label>
                                  <input type="date" class="form-control" data-date-format="YYYY MMMM DD"
                                    [(ngModel)]="iteration.end_date" [ngModelOptions]="{standalone: true}"
                                    (change)="editIteration(i)">
                                  <label>*The default timezone is CEST</label>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-12">
                                  <div class="form-group">
                                  <label class="card-body-title-basic">Project lead</label>
                                  <ng-select #usersSelect [items]="resources.internalUsers" [clearable]="true"
                                    placeholder="Select an user" bindLabel="name" (open)="getInternalUsers()"
                                    [(ngModel)]="iteration.project_lead" (change)="editIteration(i)">
                                    <ng-template ng-option-tmp let-item="item" let-index="i">
                                      <div class="media mb-1">
                                        <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                                          [src]="item.picture_file_url" (error)="item.picture_file_url = globals.avatar" />
                                        <div class="media-body">
                                          <div class="media-body-title mt-1 mb-0"><b>{{item.name}}</b></div>
                                          <span>{{item.email}}</span>
                                        </div>
                                      </div>
                                    </ng-template>
                                  </ng-select>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-12">
                                  <div class="form-group">
                                  <label class="card-body-title-basic">Sourcing lead</label>
                                  <ng-select #usersSelect [items]="resources.internalUsers" [clearable]="true"
                                    placeholder="Select an user" bindLabel="name" (open)="getInternalUsers()"
                                    [(ngModel)]="iteration.sourcing_lead" (change)="editIteration(i)">
                                    <ng-template ng-option-tmp let-item="item" let-index="i">
                                      <div class="media mb-1">
                                        <img class="media-object d-flex mr-3 height-40 width-40 rounded-circle"
                                          [src]="item.picture_file_url" (error)="item.picture_file_url = globals.avatar" />
                                        <div class="media-body">
                                          <div class="media-body-title mt-1 mb-0"><b>{{item.name}}</b></div>
                                          <span>{{item.email}}</span>
                                        </div>
                                      </div>
                                    </ng-template>
                                  </ng-select>
                                </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-1">
                              <i class="tt-icon-sm icon-ttdelete pull-right m-2" (click)="deleteIteration(i)"></i>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="job.iterations.length; then showJobIterations; else showEmptyJobIterations"></div>
                <ng-template #showJobIterations>
                  <div class="row card-block" *ngIf="!edit.iterations">
                    <div class="col-12" *ngFor="let iteration of job.iterations">
                      <div class="form-group">
                        <label class="card-body-title-basic">Iteration {{iteration.iteration_number}}</label>
                        <div class="card-body-content">
                          <label *ngIf="iteration.start_date || iteration.end_date">{{iteration.start_date ? 'from ' + iteration.start_date : ''}}{{iteration.end_date ? ' to ' + iteration.end_date : ''}}</label>
                          <div class="row" *ngIf="iteration.project_lead">
                            <div class="col-6">
                              <div class="form-group">
                                {{iteration.project_lead.name}} <strong>(Project Lead)</strong>
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="form-group">
                                {{iteration.project_lead.email}}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row" *ngIf="iteration.sourcing_lead">
                          <div class="col-6">
                            <div class="form-group">
                              {{iteration.sourcing_lead.name}} <strong>(Sourcing Lead)</strong>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              {{iteration.sourcing_lead.email}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template #showEmptyJobIterations>
                  <div *ngIf="!edit.iterations" class="row">
                    <div class="col-12">
                      No information available
                    </div>
                  </div>
                </ng-template>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </ng-template>
    </ng-container>
    <ng-container [ngbNavItem]="'ngb-tab-4'">
      <a ngbNavLink>Settings</a>
      <ng-template ngbNavContent>
        <div class="card card-top scroll scroll-tabs">
          <ngb-accordion [(activeIds)]="activeId" id="filter-accordion" #acc="ngbAccordion" class="ng-accordion-append"
            (panelChange)="preventDefault ? beforeChange($event) : ''">
            <ngb-panel id="settings">
              <ng-template ngbPanelHeader>
                <div class="form" (click)="acc.toggle('settings')">
                  <div class="form-section">
                    Settings
                    <div
                      class="btn btn-sm d-flex align-items-center justify-content-center bg-secondary-color pull-right mt-2 ml-2"
                      (click)="editForm('settings')">
                      edit
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="card-block">
                  <div class="form-group" *ngFor="let item of job.settings;let i = index ">
                    <div class="row">
                      <div class="col-12">
                        <div *ngIf='item.value != "notes_visible_to_clients" && item.value != "calibration_list_enabled" && item.value != "target_companies_enabled"' class="card-body-title-basic">
                          <label>{{item.description}}
                            <span *ngIf="!edit.settings">{{item.allowed_values[0].description}}</span>
                          </label>
                        </div>
                        <div *ngIf='item.value == "notes_visible_to_clients"' class="card-body-title-basic">
                          <label *ngIf="item.allowed_values[0]">
                            {{item.description}}
                          </label>
                          <label *ngIf="!item.allowed_values[0]">
                            research notes not visbile to clients
                          </label>
                        </div>
                        <div *ngIf='item.value == "calibration_list_enabled"' class="card-body-title-basic">
                          <label *ngIf="item.allowed_values[0]">
                            {{item.description}}
                          </label>
                          <label *ngIf="!item.allowed_values[0]">
                            calibration list disabled
                          </label>
                        </div>
                        <div *ngIf='item.value == "target_companies_enabled"' class="card-body-title-basic">
                          <label *ngIf="item.allowed_values[0]">
                            {{item.description}}
                          </label>
                          <label *ngIf="!item.allowed_values[0]">
                            target companies disabled
                          </label>
                        </div>
                      </div>
                      <div class="col-12">
                        <ng-select *ngIf="edit.settings" bindLabel="description" dropdownPosition="bottom"
                          placeholder="Select setting value" [items]="allowedValues"
                          [(ngModel)]="job.settings[i].allowed_values[0]" [ngModelOptions]="{standalone: true}"
                          (open)="getAllowedValues(item.value)" [clearable]="false"
                          (change)="editJob('settings' ,job.settings)">
                        </ng-select>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </ng-template>
    </ng-container>
  </nav>
  <div [ngbNavOutlet]="acc"></div>
  <div class="mt-2">
    <label><strong>Important:</strong> Data is saved automatically when you navigate out of a field or
      select an
      item from the dropdown list</label>
  </div>
</div>
