<!-- Sidebar Header starts -->
<div class="sidebar-header">
    <div class="logo clearfix">
        <a (click)="navigateTo('/overview')" class="logo-text float-left header">
            <div class="logo-img">
                <img src="assets/img/logo-text.svg" />
            </div>
            <span class="text align-middle"> <img src="assets/img/logo.svg" style="height: 14px;" /></span>
        </a>
        <a class="nav-toggle d-none d-sm-none d-md-none d-lg-block header" id="sidebarToggle" href="javascript:;"
            data-toggle="tooltip" data-placement="bottom" [title]="globals.labels.tooltips.shared_page.sidebar">
            <i class="ft-toggle-right toggle-icon" data-toggle="expanded"></i>
        </a>
        <a class="nav-close d-block d-md-block d-lg-none d-xl-none header" id="sidebarClose" href="javascript:;">
            <i class="ft-x"></i>
        </a>
    </div>
</div>
<!-- Sidebar Header Ends -->
<!-- Sidebar Content starts -->
<div class="sidebar-content">
    <div class="col-md-12 checkbox-box"></div>
    <div class="">
        <ul *ngIf="this.globals.countJobs() !== 1 && !(this.globals.checkIfExists('role', 'client_member') || this.globals.checkIfExists('role', 'client_basic'))"
            class="navigation">
            <li id="overview">
                <a (click)="navigateTo('/overview')">
                    <i class="icon-ttoverview"></i>
                    <span class="menu-title">{{globals.labels.placeholders.shared_page.overview_title}}</span>
                </a>
            </li>
        </ul>
        <div class="dropdown-jobs" id="select-job">
            <ng-select id="ng-select" bindLabel="job_title" dropdownPosition="bottom"
                [placeholder]="globals.labels.placeholders.inputs.shared_page.select_job" [items]="jobDropdownList" (open)="getJobs(allJobsCheckbox, null, false)"
                [(ngModel)]="selectedJob" [ngModelOptions]="{standalone: true}" (change)="onChange(selectedJob)">
            </ng-select>
        </div>
        <div class="checkbox-jobs" *ngIf="globals.checkIfExists('group', 'internal')">
            <div class="input-group checkbox-display">
                <input id="checkbox" type="checkbox" [(ngModel)]="allJobsCheckbox" (change)="populateJobsDropdown()">
                <label for="checkbox"
                    class="checkbox-menu cursor-pointer">{{globals.labels.placeholders.shared_page.closed_jobs}}</label>
            </div>
        </div>
        <ul class="navigation pl-1 pb-3" *ngIf="showJobsSubMenu">
            <li class="">
                <a (click)="navigateTo()">
                    <i class="icon-ttjoboverview"></i>
                    <span class="menu-title">{{globals.labels.placeholders.shared_page.job_overview_title}}</span>
                </a>
            </li>
            <li class="">
                <a (click)="navigateTo('/cit')">
                    <i class="icon-ttintroducedcandidates"></i>
                    <span class="menu-title">{{globals.labels.placeholders.shared_page.cit_title}}</span>
                </a>
            </li>
            <li class="" *ngIf="globals.checkIfExists('permission', 'read_candidates')">
                <a (click)="navigateTo('/candidates')">
                    <i class="icon-ttcandidates"></i>
                    <span class="menu-title">{{globals.labels.placeholders.shared_page.candidates_title}}</span>
                </a>
            </li>
            <li class="">
                <a (click)="navigateTo('/calibration-list')">
                    <i class="icon-ttcalibarationlist"></i>
                    <span class="menu-title">{{globals.labels.placeholders.shared_page.cl_title}}</span>
                </a>
            </li>
        </ul>
        <ul class="navigation position-bottom full-width">
            <li class="" *ngIf="globals.checkIfExists('permission', 'read_companies')">
                <a [routerLink]="['/companies']">
                    <i class="icon-ttcompany"></i>
                    <span class="menu-title">{{globals.labels.placeholders.shared_page.companies_title}}</span>
                </a>
            </li>
            <li class="" *ngIf="globals.checkIfExists('permission', 'update_job')">
                <a [routerLink]="['/jobs']">
                    <i class="icon-ttcareer"></i>
                    <span class="menu-title">Jobs</span>
                </a>
            </li>
            <li class="" *ngIf="globals.checkIfExists('permission', 'read_contacts')">
                <a [routerLink]="['/contacts']">
                    <i class="icon-ttcontacts"></i>
                    <span class="menu-title">{{globals.labels.placeholders.shared_page.contacts_title}}</span>
                </a>
            </li>
            <li class="" *ngIf="globals.checkIfExists('permission', 'filter_candidates')">
                <a [routerLink]="['/candidates']">
                    <i class="icon-ttcandidates"></i>
                    <span class="menu-title">Filter candidates</span>
                </a>
            </li>
            <li class="" *ngIf="globals.checkIfExists('permission', 'view_campaigns')">
                <a [routerLink]="['/campaigns']">
                    <i class="icon-ttmail"></i>
                    <span class="menu-title">Campaigns</span>
                </a>
            </li>
            <li id="solve" class="" *ngIf="globals.checkIfExists('permission', 'read_solve')">
                <a [routerLink]="['/solve']">
                    <i class="icon-ttsolve"></i>
                    <span class="menu-title">{{globals.labels.placeholders.shared_page.solve_title}}</span>
                </a>
            </li>
            <li class="" id="faq">
                <a [routerLink]="['/faq']">
                    <i class="icon-ttfaq"></i>
                    <span class="menu-title">{{globals.labels.placeholders.shared_page.faq_title}}</span>
                </a>
            </li>
        </ul>
    </div>
</div>