<aside id="add-data-drawer" class="add-data-drawer d-sm-block m-0 overflow-hidden" [ngStyle]="{'width': width >= 1200 ? '80vh' : '100%'}">
  <a class="add-data-drawer-close" (click)="dismiss()">
    <i class="ft-x font-medium-3"></i>
  </a>
  <div class="side-nav add-data-drawer-content" [ngSwitch]="data.type">
    <app-add-job *ngSwitchCase="'add-job'" [uuid]="data.job_uuid" (dismissDrawer)="dismiss()"></app-add-job>
    <app-add-activity *ngSwitchCase="'add-activity'" (dismissDrawer)="dismiss()"></app-add-activity>
    <app-add-revenue *ngSwitchCase="'add-revenue'" (dismissDrawer)="dismiss()"></app-add-revenue>
    <app-add-candidate *ngSwitchCase="'add-candidate'" (dismissDrawer)="dismiss()"></app-add-candidate>
    <app-add-contact *ngSwitchCase="'add-contact'" (dismissDrawer)="dismiss()"></app-add-contact>
  </div>
</aside>