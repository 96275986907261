import { Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { RemoveCandidateModalService } from "./remove-candidate-modal.service";

@Component({
  selector: 'app-remove-candidate-modal',
  templateUrl: './remove-candidate-modal.component.html'
})
export class RemoveCandidateModalComponent{

  candidates: any[] = [];

  jobUUID = '';

  campaignCandidate = false;

  constructor(public activeModal: NgbActiveModal, private removeCandidateService:RemoveCandidateModalService, private toastr: ToastrService){}

  removeCandidates(): void{
    const applications = this.candidates.map(candidate => ({ "application_uuid" : candidate.application_uuid }));
    this.removeCandidateService.removeCandidates(applications).subscribe(
      (response) => {
        this.activeModal.close(response);
      }, (err) => {
        if (err.error){
          for (const errProp in err.error){
            if (err.error.hasOwnProperty(errProp)){
              if (err.error[errProp].length){
                this.toastr.error(err.error[errProp]);
              }
            }
          }
        } else {
          this.toastr.error('Something went wrong');
        }
      });
  }

}