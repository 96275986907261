import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class GetnavisAddJobService{
  getConsultantCompanies(){
    return this.httpClient.get(environment.getNavisBackendURL + '/resources/consultant_companies');
  }

  constructor(private httpClient: HttpClient){ }

  createJob(job): Observable<any>{
    return this.httpClient.post(environment.getNavisBackendURL + '/merger/jobs', job);
  }

  getHiringCompanies(company?: string): Observable<any>{
    return this.httpClient.get(environment.getNavisBackendURL + '/resources/hiring_companies' + (company ? '?search=' + company : ''));
  }

  getLocations(parameter: string): Observable<any>{
    const body = {
      "input_text": parameter
    };
    return this.httpClient.post(environment.djangoURL + '/geocoder/autocomplete/', body);
  }

  getUsers(value): Observable<any>{
    return this.httpClient.get(environment.getNavisBackendURL + '/merger/users/' + value);
  }

  getResources(value): Observable<any>{
    return this.httpClient.get(environment.getNavisBackendURL + '/resources/' + value);
  }

  getResourcesByType(value): Observable<any>{
    return this.httpClient.get(environment.getNavisBackendURL + '/resources?type=' + value);
  }

  getDealroomCompanies(company: string): Observable<any>{
    const body = {
      'keyword': company,
      'keyword_type': 'default',
      'keyword_match_type': 'exact',
      'fields': 'id,name,path,website_url,url,linkedin_url,images,hq_locations',
      form_data: {
              must: {},
              must_not: {},
              should: {}
            },
      'sort': 'name',
      limit: 25,
      offset: 0
    };
    return this.httpClient.post(environment.getNavisBackendURL + '/dealroom/companies', body).pipe(map((rsp: any) => rsp.items));
  }

  getConsultants(){
      return this.httpClient.get(environment.getNavisBackendURL + '/merger/users/consultants');
  }

}