import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { NgbModal, NgbPanelChangeEvent } from "@ng-bootstrap/ng-bootstrap";
import { Globals } from "app/globals";
import { FileUploader } from "ng2-file-upload";
import { ToastrService } from "ngx-toastr";
import { GetnavisViewCandidateService } from "./getnavis-view-candidate.service";
import { environment } from "environments/environment";
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { distinctUntilChanged, debounceTime, tap, catchError, filter, switchMap, finalize, share, shareReplay } from 'rxjs/operators';
import { concat, Observable, of, Subject } from 'rxjs';
import { AddToAnotherJobComponent } from "app/shared/getnavis/getnavis-platform-modals/add-to-another-job/add-to-another-job.component";
import { AddCandidateActivityComponent } from "app/shared/getnavis/getnavis-platform-modals/add-candidate-activity/add-candidate-activity.component";
import { GetNavisBlacklistCandidateModalComponent } from "./blacklist-candidate-modal/blacklist-candidate-modal.component";
import { ActivatedRoute } from "@angular/router";
import { NgSelectComponent } from "@ng-select/ng-select";
import { ConfirmationModalComponent } from "app/shared/getnavis/getnavis-platform-modals/confirmation-modal/confirmation-modal.component";

@Component({
  selector: 'app-getnavis-view-candidate',
  templateUrl: './getnavis-view-candidate.component.html',
  styleUrls: ['./getnavis-view-candidate.component.scss']
})
export class GetnavisViewCandidateComponent implements OnInit, OnChanges{
  @Input() data: any;

  candidate_uuid = null;

  job_uuid = null;

  readonly minLengthTerm = 3;

  activeTab = 'ngb-tab-0';

  candidate: any = {};

  candidateActivities: any = [];

  candidateNavis1JobHistory: any = [];

  candidateDataIsDownloaded = false;

  changed = false;

  loadingActivity = false;

  activeFilter = null;

  innerWidth: number;

  locations$: Observable<any>;

  locationInput$ = new Subject<string>();

  locationLoading = false;

  languageLoading = false;

  loading = false;

  tooltipText = '';

  primaryExperience: any = null;

  primaryExperiences: any = [];

  companies: Observable<any>;

  companyLoading = false;

  companyinput$ = new Subject<string>();

  public uploader: FileUploader;

  private candidateCopy: any = null;

  enableImport = false;

  objectKeys = Object.keys;

  activeIds = ['basic', 'contact', 'miscellaneous', 'consent', 'experience', 'education', 'language', 'search', 'filters', 'navisSearches', 'navis1Searches', 'openJobs', 'closedJobs'];

  preventDefault = false;

  dateFormat = 'DD/MM/YYYY';

  add: any = {
    experienceForm: false,
    experiences: {
      title: null,
      company: {
        name: null
      },
      to: null,
      from: null,
      description: null,
      is_primary: false
    },
    educationForm: false,
    education: {
      school: {
        name: null
      },
      name: null,
      from: null,
      to: null,
      duration_months: null
    },
    languageForm: false,
    languages: {
      language: null,
      level: null
    },
    contactForm: false,
    contact: {}
  };

  edit: any = {
    basic: false,
    contact: false,
    miscellaneous: false,
    experience: false,
    education: false,
    language: false,
    jobs: [],
    consent: false
  };

  resources: any = {
    gender: [],
    languages: [],
    levels: [],
    tiers: [],
    archetypes: [],
    obtained_type: [],
    consent_type: [],
    activity_filters: [{
      value: 'insights',
      description: 'Insights'
    }, {
      value: 'roleActivities',
      description: 'Role activities'
    }, {
      value: 'emails',
      description: 'Emails'
    }],
    contact_source: [],
    contact_shared_with: [],
    contact_type: []
  };

  @ViewChild('companiesSelect') companiesNgSelect: NgSelectComponent;

  constructor(private getnavisViewCandidateService: GetnavisViewCandidateService, private toastr: ToastrService, public globals: Globals, private modalService: NgbModal, activatedRoute: ActivatedRoute){
    this.innerWidth = window.innerWidth;
    const URL = environment.getNavisBackendURL + '/assets';
    this.uploader = new FileUploader({
      url: URL,
      isHTML5: true,
      method: 'POST',
      itemAlias: 'file',
      authTokenHeader: 'authorization',
      authToken: 'Bearer ' + localStorage.getItem('access-token')
    });

    this.uploader.onCompleteItem = (item: any, response: any, status: any) => {
      const obj = JSON.parse(response);
      if (status == 200){
        this.candidate.picture_file_url = obj.url;
        this.editCandidate('picture_file_url', this.candidate.picture_file_url);
      } else {
        this.toastr.error(obj.message);
      }
    };

    activatedRoute.queryParams.subscribe(params => {
      this.job_uuid = params.job_uuid;
    });
  }

  ngOnInit(){
    this.candidate_uuid = this.data.candidate_uuid;
    this.getCandidateProfile();
    this.loadLocations();
    this.getLanguages();
    this.getLanguageLevel();
    this.getTier();
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes.data.previousValue && changes.data.currentValue.origin == 'navis' && (changes.data.currentValue.candidate_uuid != changes.data.previousValue.candidate_uuid)){
      this.candidateActivities = [];
      this.candidateNavis1JobHistory = [];
      this.candidate_uuid = changes.data.currentValue.candidate_uuid;
      this.getCandidateProfile();
    }
  }

  getCandidateProfile(){
    this.loading = true;
    this.getnavisViewCandidateService.getCandidateProfile(this.candidate_uuid)
      .subscribe((data) => {
        this.candidate = data;
        this.candidateCopy = cloneDeep(this.candidate);
        this.candidateDataIsDownloaded = true;
        this.loading = false;
        this.checkContactDetails();
        data.experiences ? this.primaryExperience = data.experiences.find(experience => experience.is_primary === true) : '';
        data.jobs ? data.jobs.forEach(() => this.edit.jobs.push(false)) : '';
        if (this.activeTab === 'ngb-tab-5'){
          this.getCandidateActivities();
        }
        if (this.activeTab === 'ngb-tab-4'){
          this.getNavis1JobHistory();
        }
      }, (err) => {
        this.displayErrorMessage(err);
      });
  }

  getCandidateActivities(){
    if (!this.activeFilter){
      this.activeFilter = this.resources.activity_filters[0].value;
    }
    this.candidateActivities = [];
    this.loadingActivity = true;
    const candidate_emails = this.candidate.contact_details.filter(contact => contact.type.value === 'email').map(item => item.value);
    this.getnavisViewCandidateService.getCandidateActivities(this.candidate_uuid, this.activeFilter, candidate_emails)
      .subscribe((data) => {
        this.candidateActivities = data;
        this.loadingActivity = false;
      }, (err) => {
        this.displayErrorMessage(err);
        this.loadingActivity = false;
      });
  }

  fetchActiveTab(event){
    this.activeTab = event.nextId;
    if (event.nextId === 'ngb-tab-5'){
      this.getCandidateActivities();
    }
    if (event.nextId === 'ngb-tab-4'){
      this.getNavis1JobHistory();
    }
  }

  getNavis1JobHistory(){
    if (!this.candidateNavis1JobHistory.length){
      this.getnavisViewCandidateService.getNavis1JobHistory(this.candidate.linkedin_url)
        .subscribe((data) => {
          this.candidateNavis1JobHistory = data;
        }, (err) => {
          this.displayErrorMessage(err);
        });
    }
  }

  setPrimaryExperiences(){
    this.primaryExperiences = this.candidate.experiences.filter(experience => !experience.to);
  }

  getGender(){
    if (!this.resources.gender.length){
      this.getnavisViewCandidateService.getResources('gender')
        .subscribe((data) => {
          this.resources.gender = data;
        });
    }
  }

  getLanguages(){
    this.getnavisViewCandidateService.getResources('languages')
      .subscribe((data) => {
        this.resources.languages = data;
      }, (error) => {
        this.toastr.error(error.message);
      });
  }

  getLanguageLevel(){
    this.getnavisViewCandidateService.getResources('language_levels')
      .subscribe((data) => {
        this.resources.levels = data;
      }, (error) => {
        this.toastr.error(error.message);
      });
  }

  loadLocations(){
    this.locations$ = concat(
      of([]),
      this.locationInput$.pipe(
        filter(res => res !== null && res.length >= this.minLengthTerm),
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => this.locationLoading = true),
        switchMap(term => this.getnavisViewCandidateService.getLocations(term).pipe(
          catchError(() => of([])),
          tap(() => this.locationLoading = false)
        ))
      )
    );
  }

  getTier(){
    if (!this.resources.tiers.length){
      this.getnavisViewCandidateService.getResources('candidate_ranking').subscribe(
        (data) => {
          this.resources.tiers = data;
        });
    }
  }

  getArchetype(job){
    this.resources.archetypes = [];
    this.getnavisViewCandidateService.getJobArchetypes(job.job_uuid).subscribe(
      (data) => {
        const archetypes = data.filter(archetype => archetype.type === 'persona');
        this.resources.archetypes = archetypes;
      });
  }

  setPrimaryExperience(event){
    if (!event){
      this.primaryExperience = null;
      this.candidate.experiences.forEach(experience => {
        experience.is_primary = 0;
      });
    } else {
      this.primaryExperience = event;
      this.candidate.experiences.forEach(experience => {
        if (experience.from === event.from && experience.title === event.title){
          experience.is_primary = 1;
        } else {
          experience.is_primary = 0;
        }
      });
    }
    this.updateExperience(this.candidate.experiences);
  }

  public beforeChange($event: NgbPanelChangeEvent){
    $event.preventDefault();
    this.preventDefault = false;
  }

  createName(){
    return this.candidate.first_name + ' ' + (this.candidate.middle_name ? (this.candidate.middle_name + ' ') : '') + this.candidate.last_name;
  }

  editCandidate(key, value, index = null){
    if (key === 'experiences'){
      if (index !== null){
        this.updateExperience(value[index]);
      }
      return;
    }
    const body = this.recreateJobObject(key, value);
    this.updateCandidate(body);
  }

  recreateJobObject(key, value){
    if (key.indexOf('.') > -1){
      const keyValue = key.substr(0, key.indexOf('.'));
      const obj = {};
      obj[keyValue] = {
        [key.split('.').pop()]: value
      };
      return obj;
    } else {
      return {
        [key]: value
      };
    }
  }

  updateCandidate(body){
    this.getnavisViewCandidateService.updateCandidate(this.candidate_uuid, body)
      .subscribe((response) => {
        this.toastr.success(response.message);
        this.candidateCopy = cloneDeep(this.candidate);
      },
        (err) => {
          this.candidate = cloneDeep(this.candidateCopy);
          this.displayErrorMessage(err);
        });
    this.changed = false;
  }

  editApplication(key, value, application_uuid){
    const body = this.recreateJobObject(key, value);
    this.updateApplication(application_uuid, body);
  }

  editArchetype(type, application){
    const body :any = {};

    switch (type){
      case 'persona':
        body.persona_uuid = application.archetype ? application.archetype.uuid : null;
        break;

      case 'comment':
        body.research_note = application.research_note;
        break;

      default:
        break;
    }

    this.updateApplication(application.application_uuid, body);
  }

  updateApplication(applicaton_uuid, body){
    body.application_uuid = applicaton_uuid;
    this.getnavisViewCandidateService.updateApplication(Array(body))
      .subscribe((response) => {
        this.toastr.success(response.message.success[0].message);
        this.candidateCopy.jobs = cloneDeep(this.candidate.jobs);
      },
        (err) => {
          this.candidate.jobs = cloneDeep(this.candidateCopy.jobs);
          this.displayApplicationErrorMessage(err);
        });
    this.changed = false;
  }

  displayErrorMessage(error){
    if (error.error){
      for (const errProp in error.error){
        if (error.error.hasOwnProperty(errProp)){
          if (error.error[errProp][0]){
            this.toastr.error(error.error[errProp]);
          }
        }
      }
    } else {
      this.toastr.error('Something went wrong');
    }
  }

  displayApplicationErrorMessage(error){
    error = error?.error?.errors;//flatten error message
    if (error){
      for (const errProp in error){
        for (const message in error[errProp]){
          this.toastr.error(error[errProp][message]);
        }
      }
    } else {
      this.toastr.error('Something went wrong');
    }
  }

  deleteCard(i: number, typeFE: string, typeBE = typeFE){
    const removedObj = this.candidate[typeFE].splice(i, 1)[0];
    if (typeBE == 'experiences'){
      this.deleteExperience(removedObj);
      return;
    }
    this.editCandidate(typeBE, this.candidate[typeFE]);
  }

  addToJobModal(){
    const modalRef = this.modalService.open(AddToAnotherJobComponent, { size: 'md' as 'lg', centered: true, backdrop: 'static' });
    modalRef.componentInstance.candidates = [this.candidate];
    modalRef.result.then(() => {
    }, () => {
    });
  }

  addCandidateActivity(activity_type){
    const modalRef = this.modalService.open(AddCandidateActivityComponent, { size: 'md' as 'lg', centered: true, backdrop: 'static' });
    modalRef.componentInstance.candidate_uuid = this.candidate_uuid;
    modalRef.componentInstance.job_uuid = this.job_uuid;
    modalRef.componentInstance.create_activity_type = activity_type;
    modalRef.result.then((response) => {
      if (this.activeTab === 'ngb-tab-5' && this.activeFilter === 'insights'){
        this.candidateActivities.unshift(response);
      }
    }, () => {
    });
  }

  blacklistCandidateModal(){
    const modalRef = this.modalService.open(GetNavisBlacklistCandidateModalComponent, { size: 'md' as 'lg', centered: true, backdrop: 'static' });
    modalRef.componentInstance.candidate_uuid = this.candidate_uuid;
    modalRef.componentInstance.job_uuid = this.job_uuid;
    modalRef.result.then(() => {
    }, () => {
    });
  }

  createExperience(experience, candidate_uuid){
    this.getnavisViewCandidateService.createExperiences([experience], candidate_uuid)
    .subscribe((response) => {
        this.toastr.success(response.message);
        this.candidate['experiences'].push(cloneDeep(response.data[0]));
        this.candidateCopy = cloneDeep(this.candidate);
        this.initAdd(this.add.experiences);
        this.initCompanyData();
      },
        (err) => {
          this.candidate = cloneDeep(this.candidateCopy);
          this.displayErrorMessage(err);
        });
  }

  updateExperience(experience){
    if (!(experience instanceof Array)){
      experience = [experience];
    }
    this.getnavisViewCandidateService.updateExperiences(experience)
    .subscribe((response) => {
        this.toastr.success(response.message);
        this.candidateCopy = cloneDeep(this.candidate);
      },
        (err) => {
          this.candidate = cloneDeep(this.candidateCopy);
          this.displayErrorMessage(err);
        });
  }

  deleteExperience(experience){
    this.getnavisViewCandidateService.deleteExperience(experience['uuid'])
    .subscribe((response) => {
        this.toastr.success(response.message);
        this.candidateCopy = cloneDeep(this.candidate);
      },
        (err) => {
          this.candidate = cloneDeep(this.candidateCopy);
          this.displayErrorMessage(err);
        });
  }

  addNew(addedObj, typeFE: string, typeBE = typeFE){
    if (typeBE === 'experiences'){
      this.createExperience(this.add.experiences, this.candidate_uuid);
      return;
    }
    this.candidate[typeFE].push(cloneDeep(addedObj));
    this.editCandidate(typeBE, this.candidate[typeFE]);
    if (typeBE === 'languages'){
      this.add.languages = {
        language: null,
        level: null
      };
    } else {
      this.initAdd(addedObj);
    }
  }

  initAdd(obj){
    Object.keys(obj).forEach(key => {
      if (typeof obj[key] === 'object' && obj[key] !== null){
        this.initAdd(obj[key]);
      } else {
        obj[key] = null;
      }
    });
  }

  editFields(type, index){
    if (!isNaN(index)){
      this.edit[type][index] = !this.edit[type][index];
    } else {
      this.edit[type] = !this.edit[type];
    }
    this.preventDefault = true;

    if (type === 'experience'){
      this.loadCompany();
    }
  }

  addForm(type){
    this.add[type] = !this.add[type];
    this.preventDefault = true;

    if (type === 'experienceForm'){
      this.loadCompany();
    }
  }

  OnContentChange(){
    this.uploader.uploadAll();
  }

  onChange(){
    this.changed = true;
  }

  getConsentType(){
    if (!this.resources.consent_type.length){
      this.getnavisViewCandidateService.getResourcesByType('candidate_consent_type')
        .subscribe((data) => {
          this.resources.consent_type = data;
        });
    }
  }

  getObtainedType(){
    if (!this.resources.obtained_type.length){
      this.getnavisViewCandidateService.getResourcesByType('candidate_consent_obtained_through')
        .subscribe((data) => {
          this.resources.obtained_type = data;
        });
    }
  }

  getContrastYIQ(hexcolor){
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 128) ? 'black' : 'white';
  }

  isObject(object){
    return (object && typeof object === 'object');
  }

  isArray(array){
    return (Array.isArray(array));
  }

  checkObjectValues(obj){
    let hasValue = false;
    for (const key in obj){
      if (obj[key] !== null && obj[key] != "")
        hasValue = true;
    }
    return hasValue;
  }

  transform(date: any): any{
    return moment.unix(date).format(this.dateFormat);
  }

  checkArrayObjectValues(array){
    let hasValue = false;
    array.forEach(element => {
      if (element.skill){
        hasValue = true;
      }
    });
    return hasValue;
  }

  getResource(value){
    if (!this.resources[value].length){
      this.getnavisViewCandidateService.getResourcesByType(value)
      .subscribe((data) => {
        this.resources[value] = data;
      }, (error) => {
        this.toastr.error(error.message);
      });
    }
  }

  addContactDetails(contactObject, type){
    this.getnavisViewCandidateService.postContactDetails(contactObject, this.candidate_uuid)
    .subscribe((response:any) => {
      this.candidate.contact_details.push(response);
      this.candidate.contact_details = [...this.candidate.contact_details];
      this.addForm(type);
      this.add.contact = {};
      this.toastr.success('Contact detail has been successfully created!');
    }, (error) => {
      this.toastr.error(error.message);
    });
  }

  editContactDetails(){
    this.getnavisViewCandidateService.putContactDetails(this.candidate.contact_details, this.candidate_uuid)
    .subscribe((response:any) => {
      for (let i = 0; i < this.candidate.contact_details?.length; i++){
        if (!this.candidate.contact_details[i].uuid){
          this.candidate.contact_details[i] = response.find(responseData => responseData.value === this.candidate.contact_details[i].value);
        }
      }
      this.toastr.success('Contact detail has been successfully updated!');
    }, (error) => {
      this.toastr.error(error.message);
    });
    this.changed = false;
  }

  deleteContactDetails(contact, i){
    this.getnavisViewCandidateService.deleteContactDetails(contact.uuid)
    .subscribe((response:any) => {
      this.candidate.contact_details.splice(i, 1);
      this.candidate.contact_details = [...this.candidate.contact_details];
      this.toastr.success(response.message);
    }, (error) => {
      this.toastr.error(error.message);
    });
  }

  checkIfExistInContactDetails(array, type){
    return array.filter(item => item.type.value == type).length;
  }

  initCompanyData(){
    this.add.experiences.company.name = null,
    this.add.experiences.company.photo = null,
    this.add.experiences.company.linkedin_url = null,
    this.add.experiences.company.dealroom_url = null,
    this.add.experiences.company.website_url = null;
}

  setCompanyData(data){
    if (data){
       this.add.experiences.company.photo = data.photo || data.images['100x100'];
       this.add.experiences.company.linkedin_url = data.linkedin_url;
       this.add.experiences.company.website_url = data.website_url;
       this.add.experiences.company.dealroom_id = data.id;
       this.add.experiences.company.dealroom_data = data;
       this.add.experiences.company.dealroom_url = data.url;
    }
  }

  loadCompany(){
    this.companies = concat(
      of([]),
      this.companyinput$.pipe(
          filter(res => res !== null && res.length >= this.minLengthTerm),
          debounceTime(200),
          distinctUntilChanged(),
          tap(() => this.companyLoading = true),
          switchMap(company =>
            this.getnavisViewCandidateService.getDealroomCompanies(company).pipe(
                  catchError(() => of([])),
                  tap(() => {
                      this.companyLoading = false;
                  })
              )
          ),
          shareReplay(1)
      )
    );
  }

  addCompany(name, index = null){
    if (index || index === 0){
      this.candidate.experiences[index].company = {name: name}
      this.editCandidate('experiences', this.candidate.experiences, index)
    } else {
      this.add.experiences.company.name = name;
    }
    this.companyLoading = false;
  }

  extractHuntingGroundNames(hunting_grounds){
    return hunting_grounds.map(hunting_ground => hunting_ground.name).join(', ');
  }

  checkContactDetails(): void{
    this.enableImport = false;
    this.getnavisViewCandidateService.checkContactDetails(this.candidate_uuid)
      .subscribe((response: any) => {
        for (const key in response){
          if (response.hasOwnProperty(key) && response[key]?.found){
            this.enableImport = true;
            this.getResource('contact_source');
            this.getResource('contact_type');
            this.getResource('contact_shared_with');
            break;
          }
        }
      }, () => { this.enableImport = false; });
  }

  onImportClick(){
    this.preventDefault = true;
    const hasContactDetails = this.candidate.contact_details.find(contact => contact.type?.value === 'email' || contact.type?.value === 'phone');
    if (hasContactDetails){
      const modalRef = this.modalService.open(ConfirmationModalComponent, { size: 'sm', centered: true, backdrop: 'static' });
      modalRef.componentInstance.modalHeader = 'Confirm contact details import';
      modalRef.componentInstance.modalDescription = 'Contact information is already available for this candidate. Would you like to proceed with the import?';
      modalRef.result.then(
        (result) => {
          if (result){
            this.importContactDetails();
          }
        },
        (reason) => {}
      );
    } else {
      this.importContactDetails();
    }
  }

  importContactDetails(): void{
    this.loading = true;
    this.getnavisViewCandidateService.getContactDetails(this.candidate.uuid).pipe(
      finalize(() => {
        this.loading = false;
        this.editContactDetails();
      })
    )
    .subscribe((response: any) => {
      if (response.emails?.length){
        response.emails.forEach((email) => {
         this.addImportedContactDetail(email, 'email');
        });
      }
      if (response.phones?.length){
        response.phones.forEach((phone) => {
         this.addImportedContactDetail(phone, 'phone');
        });
      }
    }, () => {
    });
  }

  addImportedContactDetail(value, type): void{
    if (this.candidate.contact_details.find(contactDetail => contactDetail.value == value)) return;
    this.candidate.contact_details.push(
      {
        value: value,
        type: type ? this.resources.contact_type.find(item => item.value == type) : null,
        is_primary: false,
        source: this.resources.contact_source.find(item => item.value == 'contact_out'),
        shared_with: this.resources.contact_shared_with.find(item => item.value == 'all_consultants')
      });
  }

}
