import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Angulartics2 } from 'angulartics2';

@Injectable()
export class CompaniesFilterService{

  constructor(private angulartics2: Angulartics2, private http: HttpClient){ }

  getDealroomFiltersByCategories(categories): Observable<any>{
    this.angulartics2.eventTrack.next({
      action: 'Loaded company dealroom filters by categories',
      properties: { categories }
    });

    return this.http.get(environment.platformBackendURL + '/resources/dealroomFilters?category=' + categories).pipe(
      map((res: any) => {
        const array = [];
        for (const key of Object.keys(res)){
          array.push({ key: key, items: res[key] });
        }
        array.push({ key: 'tags', items: [] });
        array.push({ key: 'hq_locations', items: [] });
        return array;
      }));
  }

  getDealroomFilters(term, category?): Observable<any>{
    this.angulartics2.eventTrack.next({
      action: 'Searched company dealroom filters',
      properties: { term, category }
    });

    const url = category ? environment.platformBackendURL + '/resources/dealroomFilters?name=' + term + '&category=' + category : environment.platformBackendURL + '/resources/dealroomFilters?name=' + term;
    return this.http.get(url).pipe(map(
      (res: any) => {
        const filtersArray = [];
        Object.keys(res).forEach(key => {
          if (res[key] && res[key].length > 0){
            res[key].forEach(element => {
              element.category = key;
              filtersArray.push(element);
            });
          }
        });
        return filtersArray;
      }));
  }
}
