import { Pipe, PipeTransform } from '@angular/core';
/*
 * Replace all underscores with empty string from given string and capitalize first letter
 * Takes a string as a value.
 * Usage:
 *  value | removeUndersoreCapitalizeFirst
 * Example:
 *  // value.name = first_name
 *  {{ value.name | removeUndersoreCapitalizeFirst  }}
 *  fromats to: First Name
*/
@Pipe({
    name: 'removeUndersoreCapitalizeFirst'
})
export class RemoveUnderscoreCapitalizeFirstPipe implements PipeTransform{
    transform(value: string, args?: any): string{
        const replacment = args ? args : ' ';
        if (value === null){ return 'Not assigned'; }
        if (typeof value === 'string'){
            const result = value.charAt(0).toUpperCase() + value.slice(1);
            return result.replace(/_/g, replacment);
        }
        return value;
    }
}
