import { Component, ViewEncapsulation } from '@angular/core';
import { Globals } from 'app/globals';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-getnavis-aggrid-substatus-column',
  templateUrl: './getnavis-aggrid-substatus-column.component.html',
  styleUrls: ['./getnavis-aggrid-substatus-column.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class GetnavisAgGridSubstatusColumnComponent implements ICellRendererAngularComp{

  constructor(public globals: Globals){ }

  params: any;

  selectedSubstatus:any = null;

  agInit(params: any): void{
    this.params = params;
    this.selectedSubstatus = this.params.data.substatus;
  }

  refresh(): boolean{
    return true;
  }

  getValue(): any{
    return this.selectedSubstatus;
  }

  tagStyleSetter(type){
    switch (type){
      case "color": {
        return this.params.data.contacted_by_consultant_substatus.text_color;
      }
      case "backgroundColor": {
        return this.params.data.contacted_by_consultant_substatus.background_color;
      }
      default:
        return "#fff";
    }
  }
}
