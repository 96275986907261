import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { NgbModal, NgbPanelChangeEvent } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from 'ngx-toastr';
import { GetnavisViewJobService } from "./getnavis-view-job.service";
import { Globals } from 'app/globals';
import { distinctUntilChanged, debounceTime, tap, catchError, filter, switchMap } from 'rxjs/operators';
import { concat, Observable, of, Subject } from 'rxjs';
import { NgSelectComponent } from "@ng-select/ng-select";
import { environment } from 'environments/environment';
import { FileUploader } from "ng2-file-upload";
import { cloneDeep } from 'lodash';
import { GetNavisCloseJobModalComponent } from "./close-job-modal/close-job-modal.component";

@Component({
  selector: 'app-getnavis-view-job',
  templateUrl: './getnavis-view-job.component.html',
  styleUrls: ['./getnavis-view-job.component.scss']
})
export class GetnavisViewJobComponent implements OnInit, OnChanges{

  @Input() data: any;

  isNaN: Function = Number.isNaN;

  uuid = '';

  jobDataIsDownloaded = false;

  job: any;

  loading = false;

  csv_loading = false;

  locations$: Observable<any>;

  locationInput$ = new Subject<string>();

  locationLoading = false;

  companies$: Observable<any>;

  companyLoading = false;

  companyinput$ = new Subject<string>();

  readonly minLengthTerm = 3;

  activeId: string[] = ['details', 'team', 'compensation', 'archetypes', 'hunting_grounds', 'blacklisted_companies', 'blacklisted_candidates', 'scorecards', 'files', 'settings', 'iterations'];

  filesActiveId: string[] = ['files'];

  preventDefault = false;

  companyAvatarImg: any = this.globals.companyAvatar;

  consultants: any[] = [];

  hiringCompanies$: any = [];

  tmpFiles: any = {};

  addBlacklistedCompany: any;

  addScorecard: any;

  public uploader: FileUploader;

  public csv_uploader: FileUploader;

  blacklistCsvUploader: FileUploader;

  linkingResult: any = {};

  fileIndex: number;

  public hasBaseDropZoneOver;

  addFileFlag = false;

  changed = false;

  private jobCopy: any = null;

  fileSource: string;

  allowedValues: any = [];

  latestHuntingGroundUuid: string;//we keep track of the latest hunting ground uuid so we can use it to create a custom company

  edit = {
    details: false,
    team: false,
    compensation: false,
    archetypes: false,
    hunting_grounds: false,
    blacklisted_companies: false,
    blacklisted_candidates: false,
    scorecards: false,
    files: false,
    settings: false,
    iterations: false
  };

  add = {
    team: false,
    archetype: false,
    hunting_ground: false,
    scorecard: false,
    blacklisted_candidates: false,
    file: {
      uuid: null,
      url: null,
      original_name: '',
      type: []
    },
    iterations: false
  };

  resources: any = {
    searchStages: [],
    currencies: [],
    jobFileTypes: [],
    internalUsers: [],
    archetypes: [
      {
        value: 'hunting_ground',
        description: 'hunting ground'
      },
      {
        value: 'persona',
        description: 'persona'
      }
    ],
    settings: [],
    job_types: [],
    fe: [],
    sfe: [],
    seniority: []
  };

  archetype: any = {
    name: '',
    description: '',
    type: '',
    uuid: '',
    companies: []
  };

  addIteration: any = {
    start_date: '',
    end_date: '',
    project_lead: null,
    sourcing_lead: null
  };

  blacklisted_candidate: any;

  @ViewChild('blacklistSelect') blacklistNgSelect: NgSelectComponent;

  public addTagCompany: (name) => void;

  consultantCompanies: any = [];

  constructor(private globals: Globals, private getnavisViewJobService: GetnavisViewJobService, private toastr: ToastrService, private modalService: NgbModal){
    this.addTagCompany = (name) => this.addCompany(name);
  }

  ngOnInit(): void{
    this.uuid = this.data.job_uuid;
    this.takeDataForJob(this.uuid);
    this.loadCompany();
    this.getCurrencies();
    this.getSearchStage();
    this.loadLocations();
    this.loadHiringCompanies();
    this.createFileUploader();
    this.createCsvFileUploader();
    this.createBlacklistedCompaniesFileUploader();
    this.getSettings();
    this.getJobTypes();
    this.getSeniorities();
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes.data.previousValue && changes.data.currentValue.origin == 'navis' && (changes.data.currentValue.job_uuid != changes.data.previousValue.job_uuid)){
      this.uuid = changes.data.currentValue.job_uuid;
      this.takeDataForJob(this.uuid);
    }
  }

  addCompany(name){
    this.companyLoading = false;
    const tempCompany = {
      name: name,
      images: {
        '32x32': this.globals.avatar
      }
    };
    this.job.blacklisted_companies = [...this.job.blacklisted_companies, tempCompany];
    this.editJob('blacklisted_companies', this.job.blacklisted_companies);
    this.blacklistNgSelect.close();
  }

  addCustomHuntingGroundCompany(name){
    return {
      name: name,
      customAddedCompany: true
    };
  }

  addCustomCompanyForNewHg(name){
    this.companyLoading = false;
    const tempCompany = {
      name: name
    };
    this.archetype.companies = [...this.archetype.companies, tempCompany];
    return tempCompany;
  }

  saveHuntingGroundUuid(hunting_ground_uuid){
    this.latestHuntingGroundUuid = hunting_ground_uuid;
  }

  editJob(key, value, object?){
    const body = this.recreateJobObject(key, value);

    if (this.job.lead_consultant && key == 'consultants'){
      const index = body['consultants'].findIndex(consultant => consultant.uuid == this.job.lead_consultant.uuid);
      if (index != -1){
        body['consultants'][index].is_lead = true;
      }
    }

    if (key == 'search_stage' && value?.value == 'closed'){
      this.closeJobModal(body);
      return;
    }
    if (key == 'blacklisted_candidates'){
      body['blacklisted_candidates'] = [];
      this.job.blacklisted_candidates.forEach(element => {
        const linkedin_url = { linkedin_url: element };
        body['blacklisted_candidates'].push(linkedin_url);
      });
    }
    this.updateJob(body, object);
  }

  closeJobModal(body){
    const modalRef = this.modalService.open(GetNavisCloseJobModalComponent, { size: 'sm', centered: true, backdrop: 'static' });
    modalRef.componentInstance.job_uuid = this.uuid;
    modalRef.result.then(() => {
      this.updateJob(body);
    }, () => {
      this.job.search_stage = this.jobCopy.search_stage; //reset job search stage to previous value when modal is closed
    });
  }

  changeAndUpdateConsultantCompany(){
    this.updateDependantFieldsAfterConsultantCompanyChange();
    let body = this.recreateJobObject('consultant_company', this.job.consultant_company);
    body = { ...body, ...this.recreateJobObject('settings', this.job.settings) };
    this.updateJob(body);
  }

  updateDependantFieldsAfterConsultantCompanyChange(){
    this.checkCalibrationSetting();
  }

  changeAndUpdateJobType(){
    this.updateDependantFieldsAfterJobTypeChange();
    let body = this.recreateJobObject('job_type', this.job.job_type);
    body = { ...body, ...this.recreateJobObject('settings', this.job.settings) };
    this.updateJob(body);
  }

  updateDependantFieldsAfterJobTypeChange(){
    this.checkCalibrationSetting();
  }

  checkCalibrationSetting(){
    const calibrationListSettingsIndex = this.job.settings.findIndex(setting => setting.value === 'calibration_list_enabled');
    if (calibrationListSettingsIndex != -1){
      if (this.job.job_type && this.job.job_type.value == 'test'){
        this.job.settings[calibrationListSettingsIndex].allowed_values = [true, false];
        return;
      }
      if (this.job.consultant_company && this.job.consultant_company.name == 'The Big Search'){
       this.job.settings[calibrationListSettingsIndex].allowed_values = [true, false];
        return;
      }
      this.job.settings[calibrationListSettingsIndex].allowed_values = [false, false];
    }
  }

  recreateJobObject(key, value){
    if (key.indexOf('.') > -1){
      const keyValue = key.substr(0, key.indexOf('.'));
      const obj = {};
      obj[keyValue] = {
        [key.split('.').pop()]: value
      };
      return obj;
    } else {
      return {
        [key]: value
      };
    }
  }

  updateJob(body, object?){
    this.getnavisViewJobService.updateJob(this.uuid, body)
      .subscribe((response) => {
        this.toastr.success(response.message);
        this.jobCopy = cloneDeep(this.job);
      },
        (err) => {
          if (object){
            this.job.iterations.forEach((iteration, index) => {
              if (iteration == object){
                this.job.iterations.splice(index, 1);
              }
            });
          }
          if (body.hasOwnProperty('job_type') && body.job_type.value !== 'test'){
            this.job = cloneDeep(this.jobCopy);
          } else {
            this.job.job_type_pipeline = this.jobCopy.job_type_pipeline;
          }
          this.displayErrorMessage(err);
        },
        () => {
          this.changed = false;
        });
  }

  takeDataForJob(uuid){
    this.getnavisViewJobService.getJob(uuid)
      .subscribe((data) => {
        this.jobDataIsDownloaded = true;
        this.job = data;
        this.getFunctionalSubfunctionalExpertises();
        this.job.lead_consultant = data.consultants.find(consultant => consultant.pivot.lead_type?.value === 'lead_consultant');
        this.job.project_leads = [];
        this.job.sourcing_leads = [];
        data.iterations.filter(iteration => iteration.project_lead !== null).forEach(iteration => {
          this.job.project_leads.push(iteration.project_lead.uuid);
        });
        data.iterations.filter(iteration => iteration.sourcing_lead !== null).forEach(iteration => {
          this.job.sourcing_leads.push(iteration.sourcing_lead.uuid);
        });
        if (!this.job.compensation){
          this.job.compensation = {
            annual_salary_amount: null,
            annual_salary_currency: null,
            signing_cash_bonus_amount: null,
            signing_cash_bonus_currency: null,
            equities: {
              percent: null,
              amount_estimation: null,
              amount_currency: null
            },
            other: null
          };
        }
        this.jobCopy = cloneDeep(this.job);
      }, (err) => {
        this.toastr.error(err.message);
      });
  }

  getCurrencies(){
    this.getnavisViewJobService.getResources("currencies")
      .subscribe((data) => {
        this.resources.currencies = data;
      },
        (err) => {
          this.toastr.error(err.message);
        });
  }

  getJobTypes(){
    this.getnavisViewJobService.getResourcesByType("job_type")
      .subscribe((data) => {
        this.resources.jobTypes = data;
      },
        (err) => {
          this.toastr.error(err.message);
        });
  }

  getConsultants(){
    this.getnavisViewJobService.getUsers('consultants').subscribe((data) => {
      if (data.length){
        this.consultants = data;
      }
    },
      (error) => {
        this.toastr.error(error.message);
      });
  }

  loadHiringCompanies(){
    this.hiringCompanies$ = concat(
      of([]),
      this.companyinput$.pipe(
        filter(res => res !== null && res.length >= this.minLengthTerm),
        distinctUntilChanged(),
        debounceTime(200),
        tap(() => this.companyLoading = true),
        switchMap(company =>
          this.getnavisViewJobService.getHiringCompanies(company).pipe(
            catchError(() => of([])),
            tap(() => {
              this.companyLoading = false;
            })
          )
        )
      )
    );
  }

  getFileTypes(){
    this.getnavisViewJobService.getResources("job_file_types")
      .subscribe((data) => {
        this.resources.jobFileTypes = data;
      },
        (err) => {
          this.toastr.error(err.message);
        });
  }

  loadLocations(){
    this.locations$ = concat(
      of([]), // default items
      this.locationInput$.pipe(
        filter(res => res !== null && res.length >= this.minLengthTerm),
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => this.locationLoading = true),
        switchMap(term => this.getnavisViewJobService.getLocations(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.locationLoading = false)
        ))
      )
    );
  }

  loadCompany(){
    this.companies$ = concat(
      of([]), // default items
      this.companyinput$.pipe(
        filter(res => res !== null && res.length >= this.minLengthTerm),
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => this.companyLoading = true),
        switchMap(term => this.getnavisViewJobService.getDealroomCompanies(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.companyLoading = false)
        ))
      )
    );
  }

  getSearchStage(){
    this.getnavisViewJobService.getResources("search_stage")
      .subscribe((data) => {
        this.resources.searchStages = data;
      },
        (err) => {
          this.toastr.error(err.message);
        });
  }

  getInternalUsers(){
    this.getnavisViewJobService.getUsers("internal_users")
      .subscribe((data) => {
        this.resources.internalUsers = data;
      },
        (err) => {
          this.toastr.error(err.message);
        });
  }

  getSettings(){
    this.getnavisViewJobService.getResources("settings")
      .subscribe((data) => {
        this.resources.settings = data;
      },
        (err) => {
          this.toastr.error(err.message);
        });
  }

  getAllowedValues(value){
    for (let i = 0; i < this.resources.settings.length; i++){
      if (this.resources.settings[i].value == value){
        this.allowedValues = this.resources.settings[i].allowed_values;
        break;

      }
    }
  }

  public beforeChange($event: NgbPanelChangeEvent){
    $event.preventDefault();
    this.preventDefault = false;
  }

  blacklistCompany(event){
    if (this.job.company && this.job.blacklisted_companies.find(company => company.name === this.job.company.name)){
      this.job.blacklisted_companies.splice(
        this.job.blacklisted_companies.findIndex(i => i.name === this.job.company.name), 1
      );
    }
    if (event){
      this.job.blacklisted_companies.push(event);
    }
    this.editJob('blacklisted_companies', this.job.blacklisted_companies);
  }

  setPersonData(data){
    if (data){
      if (this.job.users.length){
        if (this.job.users.some(item => item.uuid == data.uuid)){
          return;
        }
      }
      this.job.users.push(data);
    }
    this.editJob('users', this.job.users);
  }

  editForm(section: string){
    this.preventDefault = true;
    this.edit[section] = !this.edit[section];
  }

  addForm(section: string){
    this.preventDefault = true;
    this.add[section] = !this.add[section];
  }

  addUser(){
    this.preventDefault = true;
    this.add.team = !this.add.team;
  }

  addArchetypeForm(){
    this.preventDefault = true;
    this.add.archetype = !this.add.archetype;
    this.archetype.type = 'persona';
  }

  addHuntingGroundsForm(){
    this.preventDefault = true;
    this.add.hunting_ground = !this.add.hunting_ground;
    this.archetype.type = 'hunting_ground';
  }

  addScorecardForm(){
    this.preventDefault = true;
    this.add.scorecard = !this.add.scorecard;
  }

  addNewScorecard(){
    if (!this.addScorecard){
      this.toastr.warning('Can not add empty scorecard');
    } else {
      this.job.scorecards.push({
        id: this.job.scorecards.length,
        description: this.addScorecard
      });
      this.editJob("scorecards", this.job.scorecards);
      this.addScorecard = null;
      this.add.scorecard = !this.add.scorecard;
    }
  }

  addNewArchetype(){
    this.preventDefault = true;
    this.createArchetypes();
  }

  addNewIteration(){
    if (!this.job.iterations){
      this.job.iterations = [];
    }
    this.addIteration.iteration_number = this.job.iterations.length + 1;
    this.job.iterations.push(this.addIteration);
    this.editJob("iterations", this.job.iterations, this.addIteration);

    ['project_lead', 'sourcing_lead'].forEach(type => {
      if (this.addIteration[type]){
        if (this.job.users.length){
          if (this.job.users.some(item => item.uuid == this.addIteration[type].uuid)){
            return;
          }
        }
        this.job.users.push(this.addIteration[type]);
      }
    });

    this.addIteration = {
      start_date: '',
      end_date: '',
      project_lead: null,
      sourcing_lead: null
    };
    this.add.iterations = !this.add.iterations;
  }

  editIteration(index){
    this.editJob("iterations", this.job.iterations);

    const iteration = this.job.iterations[index];
    ['project_lead', 'sourcing_lead'].forEach(type => {
      if (iteration && iteration[type]){
        if (this.job.users.length){
          if (this.job.users.some(item => item.uuid == iteration[type].uuid)){
            return;
          }
        }
        this.job.users.push(iteration[type]);
      }
    });
  }

  deleteIteration(index){
    const deleteIteration = this.job.iterations[index];
    this.getnavisViewJobService.deleteJobIteration(this.job.uuid, deleteIteration.iteration_number).subscribe(
      (response) => {
        this.toastr.success(response.message);
        this.job.iterations.splice(index, 1);
        let iter_num = 1;
        this.job.iterations.forEach(iteration => {
          iteration.iteration_number = iter_num++;
        });
      },
      (error) => {
        this.toastr.error(error.message);
      });
  }

  cancelAdd(type){
    this.add[type] = !this.add[type];
  }

  createArchetypes(){
    const body = {
      job_uuid: this.job.uuid,
      data: this.archetype
    };
    this.getnavisViewJobService.createArchetype(body).subscribe(
      (response) => {
        this.toastr.success(response.message);
        this.archetype.uuid = response.data.uuid;
        if (this.archetype.type === 'persona'){
          this.job.archetypes.push(cloneDeep(this.archetype));
          this.jobCopy.archetypes = cloneDeep(this.job.archetypes);
          this.add.archetype = false;
        } else {
          this.job.hunting_grounds.push(cloneDeep(this.archetype));
          this.jobCopy.hunting_grounds = cloneDeep(this.job.hunting_grounds);
          this.add.hunting_ground = false;
        }
        this.archetype = {
          name: '',
          description: '',
          type: '',
          uuid: '',
          companies: []
        };
      },
      (err) => {
        this.displayErrorMessage(err);
      });
  }

  updateArchetype(type, index){
    this.getnavisViewJobService.updateArchetype(this.job[type][index].uuid, this.job[type][index]).subscribe(
      (response) => {
        this.toastr.success(response.message);
        this.jobCopy = cloneDeep(this.job);
      },
      (err) => {
        this.job.archetypes = cloneDeep(this.jobCopy.archetypes);
        this.job.hunting_grounds = cloneDeep(this.jobCopy.hunting_grounds);
        this.displayErrorMessage(err);
      },
      () => {
        this.changed = false;
      });
  }

  deleteArchetype(index, type){
    const deleteArchetype = this.job[type][index];
    this.getnavisViewJobService.deleteArchetype(deleteArchetype.uuid, deleteArchetype).subscribe(
      (response) => {
        this.toastr.success(response.message);
        this.job[type].splice(index, 1);
        this.jobCopy = cloneDeep(this.job);
      },
      (error) => {
        this.toastr.error(error.message);
      });
  }

  isProjectLead(item, i){
    if (item.target.checked){
      this.job.users.forEach((user, index) => {
        if (index !== i){
          user.project_lead = false;
        }
      });
    }
    this.editJob('users', this.job.users);
  }

  isSourcingLead(item, i){
    if (item.target.checked){
      this.job.users.forEach((user, index) => {
        if (index !== i){
          user.sourcing_lead = false;
        }
      });
    }
    this.editJob('users', this.job.users);
  }

  deleteCard(i: number, type: string){
    this.job[type].splice(i, 1);
    if (type == 'scorecards'){
      this.job.scorecards.forEach((element, index) => {
        element.id = index;
      });
    }
    this.editJob(type, this.job[type]);
  }

  // Angular2 File Upload
  public fileOverBase(e: any){
    this.hasBaseDropZoneOver = e;
    this.uploader.uploadAll();
  }

  createFileUploader(){
    this.uploader = new FileUploader({
      url: environment.getNavisBackendURL + '/assets',
      itemAlias: 'file',
      authToken: 'Bearer ' + localStorage.getItem('access-token')
    });
    this.uploader.onCompleteItem = (item: any, response: any, status: any) => {
      const obj = JSON.parse(response);
      obj.type = this.add.file.type;
      if (status === 200){
        this.job.files.push(obj);
        this.editJob('files', this.job.files);
        this.jobCopy.files = cloneDeep(this.job.files);
      } else {
        this.toastr.error(obj.message);
      }
      this.hasBaseDropZoneOver = false;
    };
  }

  createBlacklistedCompaniesFileUploader(){
    this.blacklistCsvUploader = new FileUploader({
      url: environment.getNavisBackendURL + '/companies/linking/csv',
      itemAlias: 'csv',
      authToken: 'Bearer ' + localStorage.getItem('access-token')
    });

    this.blacklistCsvUploader.onCompleteItem = (item: any, response: any, status: any) => {
      if (status === 200){
        const obj = JSON.parse(response);
        if (obj instanceof Object && 'companies' in obj){
          this.job.blacklisted_companies = this.mergeAndDeduplicateBlacklistedCompanies(obj.companies);
          this.editJob('blacklisted_companies', this.job.blacklisted_companies);
          return;
        }
        this.toastr.error('Response is malformed or empty. Please check the csv file content');
        return;
      }
      this.toastr.error('Something went wrong');
      this.hasBaseDropZoneOver = false;
    };
  }

  mergeAndDeduplicateBlacklistedCompanies(companies){
    const tempCompaines = [...this.job.blacklisted_companies, ...companies];
    return tempCompaines.filter((value, index, self) =>
      index === self.findIndex((item) => (
        item.name === value.name
      ))
    );
  }

  createCsvFileUploader(){
    this.csv_uploader = new FileUploader({
      url: environment.getNavisBackendURL + '/companies/linking/csv',
      itemAlias: 'csv',
      authToken: 'Bearer ' + localStorage.getItem('access-token')
    });

    this.csv_uploader.onCompleteItem = (item: any, response: any, status: any) => {
      if (status !== 200){
        this.csv_loading = false;
        this.toastr.error(response.message);
        return;
      }

      this.linkingResult = JSON.parse(response);

      if (this.add.hunting_ground){
        const currentCompanies = this.archetype.companies;
        this.linkingResult.companies.forEach(company => {

          const existingIndex = currentCompanies.findIndex(comp => comp.name == company.name);
          if (existingIndex != -1 && company.linked_to){
            currentCompanies[existingIndex].uuid = company.linked_to;
          } else {
            currentCompanies.push({
              name: company.name,
              uuid: company.linked_to,
              id: company.id,
              path: company.path,
              dealroom_url: company.dealroom_url,
              linkedin_url: company.linkedin_url,
              website_url: company.website_url
            });
          }
        });
        // The slice is needed to force ng-select to detect the changes
        // See https://github.com/ng-select/ng-select#change-detection
        this.archetype.companies = currentCompanies.slice();
      } else {
        const currentCompanies = this.job.hunting_grounds[this.fileIndex].companies;
        this.linkingResult.companies.forEach(company => {
          const existingIndex = currentCompanies.findIndex(comp => comp.name == company.name);
          if (existingIndex != -1 && company.linked_to){
            currentCompanies[existingIndex].uuid = company.linked_to;
          } else {
            currentCompanies.push({
              name: company.name,
              uuid: company.linked_to,
              id: company.id,
              path: company.path,
              dealroom_url: company.dealroom_url,
              linkedin_url: company.linkedin_url,
              website_url: company.website_url
            });
          }
        });
        this.job.hunting_grounds[this.fileIndex].companies = currentCompanies.slice();
        this.updateArchetype('hunting_grounds', this.fileIndex);
      }
      this.csv_loading = false;
      this.csv_uploader.clearQueue();
    };

  }

  onFileClick(event){
    event.target.value = '';
  }

  OnContentChangeCsv(event, hgIndex){
    if (event.target.value){
      this.fileIndex = hgIndex;
      this.linkingResult = {};
      this.csv_loading = true;
      this.csv_uploader.uploadAll();

    } else {
      this.loading = false;

    }
  }

  onBlacklistedCompanyInsertCSV(){
    this.blacklistCsvUploader.uploadAll();
    this.csv_loading = false;
  }

  afterFileisDrop(){
    this.loading = true;
  }

  toogleAddFileFlag(){
    this.addFileFlag = !this.addFileFlag;
    this.preventDefault = true;
  }

  removeSpacesFileName(file, index){
    file.name = (file.tempName + file.name.substr(file.name.lastIndexOf('.'))).replace(/ /g, '');
    if (file.name == '.pptx' || file.name == '.doc' || file.name == '.pdf'){
      this.toastr.error('Name of file can not be empty');
      this.job.files = cloneDeep(this.jobCopy.files);
    } else {
      this.job.files[index].name = file.name;
      this.editJob('files', this.job.files);
    }
  }

  OnContentChangeFile(){
    this.uploader.uploadAll();
  }

  onChange(){
    this.changed = true;
  }

  displayErrorMessage(error){
    if (error.error){
      for (const errProp in error.error){
        if (error.error.hasOwnProperty(errProp)){
          if (error.error[errProp][0]){
            this.toastr.error(error.error[errProp]);
          }
        }
      }
    } else {
      this.toastr.error('Something went wrong');
    }
  }

  deleteBlacklistedCandidate(index){
    this.job.blacklisted_candidates.splice(index, 1);
    this.editJob('blacklisted_candidates', this.job.blacklisted_candidates);
  }

  addBlacklistedCandidate(){
    if (this.blacklisted_candidate && this.blacklisted_candidate.trim() &&
      this.job.blacklisted_candidates.indexOf(this.blacklisted_candidate.trim()) == -1){
      this.job.blacklisted_candidates.push(this.blacklisted_candidate);
      this.editJob('blacklisted_candidates', this.job.blacklisted_candidates);
      this.blacklisted_candidate = '';
    } else {
      this.blacklisted_candidate = '';
      this.toastr.error('This candidate already exist');
    }
  }

  getFunctionalSubfunctionalExpertises(){
    this.getnavisViewJobService.getResourcesByType("functional_expertise")
      .subscribe((data) => {
        this.resources.fe = data;

        if (this.job.fe){
          this.resources.sfe = this.resources.fe.find(obj => obj.description === this.job.fe.description).subfunctional_expertise;
        }
      },
        (err) => {
          this.toastr.error(err.message);
        });
  }

  getSeniorities(){
    this.getnavisViewJobService.getResourcesByType("role_seniority")
      .subscribe((data) => {
        this.resources.seniority = data;
      },
        (err) => {
          this.toastr.error(err.message);
        });
  }

  onFEChange(){
    this.job.sfe = null;
    this.editJob('sfe', this.job.sfe);
    if (!this.job.fe){
      this.resources.sfe = [];
    } else {
      this.resources.sfe = this.job.fe.subfunctional_expertise;
    }
  }

  checkClearLeadConsultant(consultant){
    if (!consultant || this.job.lead_consultant?.uuid == consultant?.value.uuid){
      this.job.lead_consultant = null;
    }
  }

  setLeadConsultant(leadConsultant){
    let consultantIndex = -1;
    let consultantObj = null;

    if (leadConsultant){
      consultantIndex = this.job.consultants.findIndex(consultant => consultant.uuid == leadConsultant.uuid);
      consultantObj = this.consultants.find(consultant => consultant.uuid == leadConsultant.uuid);
    }

    if (consultantIndex != -1){
      this.job.consultants.forEach(consultant => consultant.is_lead = false);
      this.job.consultants[consultantIndex].is_lead = true;
    } else if (consultantIndex == -1 && leadConsultant){
      this.job.consultants.forEach(consultant => consultant.is_lead = false);
      consultantObj.is_lead = true;
      this.job.consultants = [...this.job.consultants, consultantObj];
    } else if (consultantIndex == -1 && !leadConsultant){
      const index = this.job.consultants.findIndex(consultant => consultant.is_lead == true);
      this.job.consultants[index].is_lead = false;
    }
    this.editJob('consultants', this.job.consultants);
  }

  getConsultantCompanies(){
    this.getnavisViewJobService.getConsultantCompanies()
    .subscribe((data) => {
        this.consultantCompanies = data;
    },
    (err) => {
      this.displayErrorMessage(err);
    });
  }

  addHuntingGroundCompany(event){
    this.getnavisViewJobService.addHuntingGroundCompany(this.uuid, this.latestHuntingGroundUuid, event).subscribe(
      (response) => {
        this.addHuntingGroundCompanyInJobObject(this.latestHuntingGroundUuid, response);
        this.toastr.success('Company added successfully');
      },
      (err) => {
        this.toastr.error('Something went wrong');
      },
      () => {
        this.changed = false;
      });
  }

  removeHuntingGroundCompany(event){
    this.getnavisViewJobService.removeHuntingGroundCompany(event.value.pivot?.uuid).subscribe(
      (response) => {
        this.toastr.success('Company removed successfully');
      },
      (err) => {
        this.toastr.error('Something went wrong');
      },
      () => {
        this.changed = false;
      });
  }

  addHuntingGroundCompanyInJobObject(uuid, company){
    const index = this.job.hunting_grounds.findIndex(hg => hg.uuid == uuid);
    if (index != -1){
      this.removeCustomAddedCompanies(index, company);
      this.job.hunting_grounds[index].companies.push(company);
    }
  }

  removeCustomAddedCompanies(index: number, responseCompany): void{
    this.job.hunting_grounds[index].companies = this.job.hunting_grounds[index].companies.filter(
      (company) =>
        !(company.name === responseCompany.name && company.customAddedCompany) &&
        !('id' in company && company.url === responseCompany.dealroom_url)
    );
  }
}
