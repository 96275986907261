import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GetNavisCloseJobModalService } from './close-job-modal.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-close-job-modal',
  templateUrl: './close-job-modal.component.html',
  styleUrls: ['./close-job-modal.component.scss']
})
export class GetNavisCloseJobModalComponent implements OnInit{

  job_uuid: string;

  closeJobStatus: any = null;

  statuses: any = [];

  npsData:any = null;

  npsDataLoaded = false;

  constructor(public activeModal: NgbActiveModal, public service: GetNavisCloseJobModalService, private toastr: ToastrService){
  }

  ngOnInit(){
    this.getStatusResources();
    this.getJobNpsData();
  }

  getStatusResources(){
    this.service.getResourcesByType("closed_job_statuses")
    .subscribe((data) => {
      this.statuses = data;
    },
      (err) => {
        this.displayErrorMessage(err);
      });
  }

  getJobNpsData(){
    this.service.getJobNpsData(this.job_uuid).pipe(
      finalize(() => {
        this.npsDataLoaded = true;
      })
    )
.subscribe((response) => {
        this.npsData = response;
      },
      (error) => {
        this.displayErrorMessage(error);
      }
    );
  }

  closeJob(){
    this.service.closeJob(this.closeJobStatus, this.job_uuid)
    .subscribe(() => {
      this.toastr.success('Job closed succesfully');
      this.activeModal.close();
    },
      (err) => {
        this.displayErrorMessage(err);
      });
  }

/**
 * @description Displays error message
 * @param error
 */
  displayErrorMessage(error){
    if (error.error){
      for (const errProp in error.error){
        if (error.error.hasOwnProperty(errProp)){
          if (error.error[errProp][0]){
            this.toastr.error(error.error[errProp]);
          }
        }
      }
    } else {
      this.toastr.error('Something went wrong');
    }
  }
}
