import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Globals } from 'app/globals';

@Injectable()
export class AddCandidateService{
  constructor(private httpClient: HttpClient, private globals: Globals){ }

  getResources(value, name): Observable<any>{
    return this.httpClient.get(environment.platformBackendURL + '/resources/' + (value == 'subStatuses' ? value + '?value=' + this.globals.substatuses : value) + (name ? '?name=' + name : ''));
  }

  getResourcesByType(type): Observable<any>{
    return this.httpClient.get(environment.platformBackendURL + '/resources?type=' + type);
  }

  getJobsResources(): Observable<any>{
    return this.httpClient.get(environment.platformBackendURL + '/jobs?type=open');
  }

  getApplicationStagesResources(): Observable<any>{
    return this.httpClient.get(environment.platformBackendURL + '/resources/applicationStages?current_stage=null');
  }

  createCandidate(candidate): Observable<any>{
    return this.httpClient.post(environment.platformBackendURL + '/candidates', candidate);
  }

  createCandidateApplication(job): Observable<any>{
    return this.httpClient.post(environment.platformBackendURL + '/candidates/applications', job);
  }

  checkCandidateData(parameters: string): Observable<any>{
    return this.httpClient.get(environment.platformBackendURL + '/candidates?' + parameters);
  }

  getLocations(parameter: string): Observable<any>{
    const body = {
      "input_text": parameter
    };
    return this.httpClient.post(environment.djangoURL + '/geocoder/autocomplete/', body);
  }
}
