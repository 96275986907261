<div #wrapper id="wrapper">
    <div class="app-sidebar" data-active-color="white" data-background-color="black">
        <app-sidebar *ngIf="this.globals.env == 'navis'"></app-sidebar>
        <app-getnavis-sidebar *ngIf="this.globals.env == 'getnavis'"></app-getnavis-sidebar>
        <div class="sidebar-background"></div>
    </div>
    <app-navbar *ngIf="this.globals.env == 'navis'"></app-navbar>
    <app-getnavis-navbar *ngIf="this.globals.env == 'getnavis'"></app-getnavis-navbar>
    <div class="main-panel">
        <div class="main-content">
            <div class="content-wrapper">
                <div class="container-fluid">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
        <app-add-data-drawer></app-add-data-drawer>
        <app-candidate-drawer></app-candidate-drawer>
        <app-contact-drawer></app-contact-drawer>
        <app-company-drawer></app-company-drawer>
        <app-filter-drawer></app-filter-drawer>
        <app-job-drawer></app-job-drawer>
        <app-campaign-drawer></app-campaign-drawer>
        <app-getnavis-consultant-drawer></app-getnavis-consultant-drawer>
        <app-getnavis-consultant-company-drawer></app-getnavis-consultant-company-drawer>
        <app-getnavis-hiring-company-drawer></app-getnavis-hiring-company-drawer>
        <app-getnavis-job-drawer></app-getnavis-job-drawer>
        <app-getnavis-candidate-drawer></app-getnavis-candidate-drawer>
        <app-getnavis-campaign-drawer></app-getnavis-campaign-drawer>
    </div>
</div>