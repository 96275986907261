import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Angulartics2 } from 'angulartics2';

@Injectable()
export class GenerateFilterService{

  constructor(private angulartics2: Angulartics2, private http: HttpClient){ }

  getCompany(company): Observable<any>{
    this.angulartics2.eventTrack.next({
      action: 'Loaded company from job filter',
      properties: { company }
    });

    return this.http.get(environment.platformBackendURL + '/companies?name=' + company);
  }

  getContacts(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/contacts?');
  }

  getType(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources?type=job_type');
  }

  getStatus(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources?type=job_statuses');
  }

  getPriority(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources?type=job_priorities');
  }

  getSeniority(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources?type=role_seniority');
  }

  getLocation(location): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources/city?name=' + location);
  }

  getCurrency(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources?type=currencies');
  }

  getbonusTypes(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources?type=bonus_type');
  }

  getFeeType(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources?type=fee_type');
  }

  getClosedInfoStatus(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources?type=closing_role_type');
  }

  getClosedInfoReason(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources?type=closing_role_type');
  }

  getFunctionalExpertase(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources/functionalExpertise');
  }

  getSubFunctionalExpertase(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources/subFunctionalExpertise');
  }

  getIndustry(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources/industries');
  }

  getPods(): Observable<any>{
    return this.http.get(environment.platformBackendURL + '/resources/pods');
  }
}
