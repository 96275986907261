import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class GetNavisSidebarService{
  constructor(private http: HttpClient){ }

  getJobs(closedJobs): Observable<any>{
      const url: string = closedJobs ? environment.getNavisBackendURL + '/resources/jobs' : environment.getNavisBackendURL + '/resources/jobs?type=open';
      return this.http.get(url);
  }
}