import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Globals } from 'app/globals';
import { AddCandidateActivityService } from './add-candidate-activity.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Angulartics2 } from 'angulartics2';

@Component({
    selector: 'app-add-candidate-activity',
    templateUrl: './add-candidate-activity.component.html',
    styleUrls: ['./add-candidate-activity.component.scss']
})

export class AddCandidateActivityComponent implements OnInit{
    candidate_uuid: any;

    create_activity_type: any;

    job_uuid: string;

    activity = null;

    resources = {
        jobs: null
    };

    constructor(public globals: Globals,
        private addCandidateActivityService: AddCandidateActivityService,
        public toastr: ToastrService,
        public activeModal: NgbActiveModal,
        private angulartics2: Angulartics2){
    }

    ngOnInit(){
        this.initializeActivityObject();
        if (this.create_activity_type == 'inmail_reply'){
            this.getJobs();
            this.activity.position_uuid = this.job_uuid;
        }
    }

    getJobs(){
        this.addCandidateActivityService.getJobs(this.candidate_uuid).subscribe(
            (data) => {
                if (data){
                    this.resources.jobs = data;
                }
            },
            (err) => {
                this.displayErrorMessage(err);
            });
    }

    createCandidateInsight(){
        this.addCandidateActivityService.createCandidateInsight(this.candidate_uuid, this.activity).subscribe(
            (response) => {
                this.angulartics2.eventTrack.next({
                    action: 'backoffice_candidate_add_note_click_app',
                    properties: { }
                  });
                this.toastr.success('Global note created successfully');
                this.activeModal.close(response);
            },
            (err) => {
                this.displayErrorMessage(err);
        });
    }

    initializeActivityObject(){
        if (this.create_activity_type == 'global_note'){
            this.activity = {
                access: {
                    private: true,
                    share_with: {
                        value: "my_team",
                        description: "my team",
                        type: "activity_privacy"
                    }
                },
                type: 'note',
                value: null
            };
        } else {
            this.activity = {
                position_uuid: null,
                access: {
                    private: false,
                    share_with: {
                        value: "with_consultant",
                        description: "with consultant",
                        type: "activity_privacy"
                    }
                },
                type: 'interaction',
                value: null
            };
        }
    }

    displayErrorMessage(error){
        if (error.error){
            for (const errProp in error.error){
                if (error.error.hasOwnProperty(errProp)){
                    if (error.error[errProp][0]){
                        this.toastr.error(error.error[errProp]);
                    }
                }
            }
        } else {
            this.toastr.error('Something went wrong');
        }
    }

    closePopup(){
        this.initializeActivityObject();
        this.activeModal.dismiss();
    }
}
