<div *ngIf="loading" class="loading-view">
  <img [src]="globals.loading" class="loading-element" />
</div>
<div class="modal-content" *ngIf="data.new_data">
  <div class="modal-bg-cards">
    <div class="modal-header modal-header-bg">
      <div class="row full-width" *ngIf="data.header_data && data.header_data.new_data && data.header_data.old_data">
        <div class="col-6 media">
          <img class="media-object d-flex height-60 width-60 rounded-circle"
            [src]="data.header_data.new_data.photo ? data.header_data.new_data.photo : globals.avatar"
            (error)="data.header_data.new_data.photo = globals.avatar">
          <div class="modal-header-body ml-3 full-width white">
            <div *ngIf="data.header_data.new_data.first_name || data.header_data.new_data.last_name"
              class="modal-header-title">
              {{data.header_data.new_data.first_name}} {{data.header_data.new_data.last_name}}
              <a href="{{data.header_data.new_data.linkedin}}" target="_blank" class="white">
                <i class="fa fa-linkedin-square"></i>
              </a>
              <span class="badge-tags-inverse ml-3 bg-success-color cursor-pointer text-color-primary">
                primary
              </span>
            </div>
            <div *ngIf="data.header_data.new_data.current_job_title || data.header_data.new_data.current_employer">
              {{data.header_data.new_data.current_job_title }} {{data.header_data.new_data.current_employer ?
              globals.labels.placeholders.global.at + ' ' +
              data.header_data.new_data.current_employer : ''}}
            </div>
            <div *ngIf="data.header_data.new_data.location">
              <i class="tt-icon-sm fa fa-map-marker"></i>
              {{data.header_data.new_data.location}}
            </div>
            <div *ngIf="data.header_data.new_data.primary_email">
              <i class="tt-icon-sm fa fa-envelope-o"></i>
              {{data.header_data.new_data.primary_email}}
            </div>
          </div>
        </div>
        <div class="col-6 media">
          <img class="media-object d-flex height-60 width-60 rounded-circle"
            [src]="data.header_data.old_data.photo ? data.header_data.old_data.photo : globals.avatar"
            (error)="data.header_data.old_data.photo = globals.avatar">
          <div class="modal-header-body white ml-3 full-width">
            <div *ngIf="data.header_data.old_data.first_name || data.header_data.old_data.last_name"
              class="modal-header-title">
              {{data.header_data.old_data.first_name}} {{data.header_data.old_data.last_name}}
              <a href="{{data.header_data.old_data.linkedin}}" target="_blank" class="white">
                <i class="fa fa-linkedin-square"></i>
              </a>
              <span class="badge-tags-inverse ml-3 bg-error-color cursor-pointer">
                deleted
              </span>
            </div>
            <div *ngIf="data.header_data.old_data.current_job_title || data.header_data.old_data.current_employer">
              {{data.header_data.old_data.current_job_title }} {{data.header_data.old_data.current_employer ?
              globals.labels.placeholders.global.at + ' ' +
              data.header_data.old_data.current_employer : ''}}
            </div>
            <div *ngIf="data.header_data.old_data.location">
              <i class="tt-icon-sm fa fa-map-marker"></i>
              {{data.header_data.old_data.location}}
            </div>
            <div *ngIf="data.header_data.old_data.primary_email">
              <i class="tt-icon-sm fa fa-envelope-o"></i>
              {{data.header_data.old_data.primary_email}}
            </div>
          </div>
        </div>
      </div>
      <button type="button" class="close pull-right" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true" [innerHTML]="globals.labels.placeholders.global.buttons.close_x" class="white"
          data-toggle="tooltip" data-placement="bottom" [title]="globals.labels.tooltips.global.close"></span>
      </button>
    </div>
    <div class="modal-body scroll">
      <div class="form-group">
        <div class="modal-body-content">
          <div class="form" (click)="accordion.details = !accordion.details">
            <div class="form-section">
              Details
              <i class="pull-right mt-1 pt-2"
                [ngClass]="{'ft-chevron-right': !accordion.details,'ft-chevron-down': accordion.details}"></i>
            </div>
          </div>
          <div *ngFor="let key of objectKeys(data.old_data)">
            <div class="form-group" *ngIf="!isArray(data.new_data[key]) && data.old_data[key] && accordion.details">
              <div *ngIf="!isObject(data.new_data[key]) && 'uuid' != key">
                <div class="modal-body-title">
                  <label>
                    {{key | removeUndersoreCapitalizeFirst}}
                  </label>
                </div>
                <div class="modal-body-content">
                  <div class="row">
                    <div class="col-6">
                      <div class="card m-0">
                        <div *ngIf="'linkedin' == key || 'photo' == key; then linkNew else textNew"></div>
                        <ng-template #linkNew>
                          <a class="p-2 secondary-color" [href]="data.new_data[key]" target="_blank">
                            <u>{{data.new_data[key]}}</u>
                            <i class="tt-icon-sm fa fa-check pull-right p-1 success"></i>
                          </a>
                        </ng-template>
                        <ng-template #textNew>
                          <span class="p-2">
                            {{data.new_data[key]}}
                            <i class="tt-icon-sm fa fa-check pull-right p-1 success"></i>
                          </span>
                        </ng-template>
                      </div>
                    </div>
                    <div class="col-1">
                      <a (click)="swapValues(key, data.new_data[key], data.old_data[key])">
                        <i class="tt-icon-lg fa fa-arrows-h pt-2" data-toggle="tooltip" data-placement="bottom"
                          title="Change values"></i>
                      </a>
                    </div>
                    <div class="col-5 pl-0">
                      <div class="card m-0">
                        <div *ngIf="'linkedin' == key || 'photo' == key; then linkOld else textOld"></div>
                        <ng-template #linkOld>
                          <a class="p-2 secondary-color" [href]="data.old_data[key]" target="_blank">
                            <u>{{data.old_data[key]}}</u>
                            <i class="tt-icon-sm fa fa-times pull-right p-1 error-color"></i>
                          </a>
                        </ng-template>
                        <ng-template #textOld>
                          <span class="p-2">
                            {{data.old_data[key]}}
                            <i class="tt-icon-sm fa fa-times pull-right p-1 error-color"></i>
                          </span>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="isObject(data.new_data[key])">
                <div [ngSwitch]="key">
                  <ng-template [ngSwitchCase]="'location'">
                    <div class="form-group">
                      <div class="modal-body-title">
                        <div class="form" (click)="accordion[key] = !accordion[key]">
                          <div class="form-section">
                            {{key | removeUndersoreCapitalizeFirst}}
                            <i class="pull-right mt-1 pt-2"
                              [ngClass]="{'ft-chevron-right': !accordion[key],'ft-chevron-down': accordion[key]}"></i>
                          </div>
                        </div>
                      </div>
                      <div class="modal-body-content" *ngIf="accordion[key]">
                        <div class="row">
                          <div class="col-6">
                            <div class="card m-0 p-2 mb-3">
                              <div class="row">
                                <div class="col-12">
                                  <div class="card-body-content">
                                    {{data.new_data.location.description}}
                                    <i class="tt-icon-sm fa fa-check pull-right p-1 success"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-1">
                            <a (click)="swapValues(key, data.new_data.location, data.old_data.location)">
                              <i class="tt-icon-lg fa fa-arrows-h pt-2" data-toggle="tooltip" data-placement="bottom"
                                title="Change values"></i>
                            </a>
                          </div>
                          <div class="col-5 pl-0">
                            <div class="card m-0 p-2 mb-3">
                              <div class="row">
                                <div class="col-12">
                                  <div class="card-body-content">
                                    {{data.old_data.location.description}}
                                    <i class="tt-icon-sm fa fa-times pull-right p-1 error-color"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template [ngSwitchCase]="'miscellaneous'">
                    <div *ngFor="let item of objectKeys(data.old_data[key])">
                      <div *ngIf="data.old_data[key][item] && data.old_data[key][item].length">
                        <div class="form-group">
                          <div class="modal-body-title">
                            <label>
                              {{item | removeUndersoreCapitalizeFirst}}
                            </label>
                          </div>
                          <div class="modal-body-content">
                            <div class="row">
                              <div class="col-6">
                                <div class="card m-0">
                                  <span class="p-2">
                                    {{data.new_data[key][item]}}
                                    <i class="tt-icon-sm fa fa-check pull-right p-1 success"></i>
                                  </span>
                                </div>
                              </div>
                              <div class="col-1">
                                <a (click)="swapValues(key, data.new_data[key][item], data.old_data[key][item], item)">
                                  <i class="tt-icon-lg fa fa-arrows-h pt-2" data-toggle="tooltip"
                                    data-placement="bottom" title="Change values"></i>
                                </a>
                              </div>
                              <div class="col-5 pl-0">
                                <div class="card m-0">
                                  <span class="p-2">
                                    {{data.old_data[key][item]}}
                                    <i class="tt-icon-sm fa fa-times pull-right p-1 error-color"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template [ngSwitchCase]="'compensation'">
                    <div class="form-group">
                      <div class="modal-body-title">
                        <div class="form" (click)="accordion[key] = !accordion[key]">
                          <div class="form-section">
                            {{key | removeUndersoreCapitalizeFirst}}
                            <i class="pull-right mt-1 pt-2"
                              [ngClass]="{'ft-chevron-right': !accordion[key],'ft-chevron-down': accordion[key]}"></i>
                          </div>
                        </div>
                      </div>
                      <div class="modal-body-content" *ngIf="accordion[key]">
                        <div class="row">
                          <div class="col-6">
                            <div class="card m-0 p-2 mb-3">
                              <div class="row">
                                <div class="col-12">
                                  <div class="card-body-content">
                                    <strong> Base: </strong>
                                    {{data.new_data.compensation && data.new_data.compensation.base_salary ?
                                    data.new_data.compensation.base_salary : 'No information available' }}
                                    <i class="tt-icon-sm fa fa-check pull-right p-1 success"></i>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="card-body-content">
                                    <strong>Currency: </strong>
                                    {{data.new_data.compensation && data.new_data.compensation.currency ?
                                    data.new_data.compensation.currency.description : 'No information available'}}
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="card-body-content">
                                    <strong>Bonus: </strong>
                                    {{data.new_data.compensation && data.new_data.compensation.bonus_salary ?
                                    data.new_data.compensation.bonus_salary: 'No information available' }}
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="card-body-content">
                                    <strong>Type: </strong>
                                    {{data.new_data.compensation && data.new_data.compensation.bonus_type ?
                                    data.new_data.compensation.bonus_type.description : 'No information available'}}
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <div class="card-body-title-basic">
                                    </div>
                                    <div class="card-body-content">
                                      <strong>Equity: </strong>
                                      {{data.new_data.compensation && data.new_data.compensation.equity ?
                                      data.new_data.compensation.equity : 'No information available'}}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-1">
                            <a (click)="swapValues(key, data.new_data.compensation, data.old_data.compensation)">
                              <i class="tt-icon-lg fa fa-arrows-h pt-2" data-toggle="tooltip" data-placement="bottom"
                                title="Change values"></i>
                            </a>
                          </div>
                          <div class="col-5 pl-0">
                            <div class="card m-0 p-2 mb-3">
                              <div class="row">
                                <div class="col-12">
                                  <div class="card-body-content">
                                    <strong> Base: </strong>
                                    {{data.old_data.compensation && data.old_data.compensation.base_salary ?
                                    data.old_data.compensation.base_salary : 'No information available' }}
                                    <i class="tt-icon-sm fa fa-times pull-right p-1 error-color"></i>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="card-body-content">
                                    <strong>Currency: </strong>
                                    {{data.old_data.compensation && data.old_data.compensation.currency ?
                                    data.old_data.compensation.currency.description : 'No information available'}}
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="card-body-content">
                                    <strong>Bonus: </strong>
                                    {{data.old_data.compensation && data.old_data.compensation.bonus_salary ?
                                    data.old_data.compensation.bonus_salary: 'No information available' }}
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="card-body-content">
                                    <strong>Type: </strong>
                                    {{data.old_data.compensation && data.old_data.compensation.bonus_type ?
                                    data.old_data.compensation.bonus_type.description : 'No information available'}}
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <div class="card-body-title-basic">
                                    </div>
                                    <div class="card-body-content">
                                      <strong>Equity: </strong>
                                      {{data.old_data.compensation && data.old_data.compensation.equity ?
                                      data.old_data.compensation.equity : 'No information available'}}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template [ngSwitchCase]="'consent'">
                    <div class="form-group">
                      <div class="modal-body-title">
                        <div class="form" (click)="accordion[key] = !accordion[key]">
                          <div class="form-section">
                            {{key | removeUndersoreCapitalizeFirst}}
                            <i class="pull-right mt-1 pt-2"
                              [ngClass]="{'ft-chevron-right': !accordion[key],'ft-chevron-down': accordion[key]}"></i>
                          </div>
                        </div>
                      </div>
                      <div class="modal-body-content" *ngIf="accordion[key]">
                        <div class="row">
                          <div class="col-6">
                            <div class="card m-0 p-2 mb-3">
                              <div class="row">
                                <div class="col-12">
                                  <div class="card-body-content">
                                    <strong>Obtained at: </strong>
                                    {{data.new_data.consent && data.new_data.consent.obtained_at ?
                                    data.new_data.consent.obtained_at : 'No information available'}}
                                    <i class="tt-icon-sm fa fa-check pull-right p-1 success"></i>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="card-body-content">
                                    <strong>Obtained through type: </strong>
                                    {{data.new_data.consent && data.new_data.consent.obtained_through_type ?
                                    data.new_data.consent.obtained_through_type.description: 'No information
                                    available'}}
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="card-body-content">
                                    <strong>Type: </strong>
                                    {{data.new_data.consent && data.new_data.consent.type ?
                                    data.new_data.consent.type.description : 'No information available'}}
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <div class="card-body-title-basic">
                                      <strong>
                                        Note:
                                      </strong>
                                    </div>
                                    <div class="card-body-content"
                                      [innerHTML]="data.new_data.consent && data.new_data.consent.note ? data.new_data.consent.note : 'No information available' ">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-1">
                            <a (click)="swapValues(key, data.new_data.consent, data.old_data.consent)">
                              <i class="tt-icon-lg fa fa-arrows-h pt-2" data-toggle="tooltip" data-placement="bottom"
                                title="Change values"></i>
                            </a>
                          </div>
                          <div class="col-5 pl-0">
                            <div class="card m-0 p-2 mb-3">
                              <div class="row">
                                <div class="col-12">
                                  <div class="card-body-content">
                                    <strong>Obtained at: </strong>
                                    {{data.old_data.consent && data.old_data.consent.obtained_at ?
                                    data.old_data.consent.obtained_at : 'No information available'}}
                                    <i class="tt-icon-sm fa fa-times pull-right p-1 error-color"></i>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="card-body-content">
                                    <strong>Obtained through type: </strong>
                                    {{data.old_data.consent && data.old_data.consent.obtained_through_type ?
                                    data.old_data.consent.obtained_through_type.description: 'No information
                                    available'
                                    }}
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="card-body-content">
                                    <strong>Type: </strong>
                                    {{data.old_data.consent && data.old_data.consent.type ?
                                    data.old_data.consent.type.description : 'No information available'}}
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <div class="card-body-title-basic">
                                      <strong>
                                        Note:
                                      </strong>
                                    </div>
                                    <div class="card-body-content"
                                      [innerHTML]="data.old_data.consent && data.old_data.consent.note ? data.old_data.consent.note : 'No information available' ">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template *ngSwitchDefault>
                    <div *ngFor="let item of objectKeys(data.old_data[key]) | slice:('gender' == key ? 1 : 2)">
                      <div *ngIf="data.old_data[key][item]">
                        <div class="modal-body-title">
                          <label>
                            {{key | removeUndersoreCapitalizeFirst}}
                          </label>
                        </div>
                        <div class="modal-body-content">
                          <div class="row">
                            <div class="col-6">
                              <div class="card m-0">
                                <span class="p-2">
                                  {{data.new_data[key][item]}}
                                  <i class="tt-icon-sm fa fa-check pull-right p-1 success"></i>
                                </span>
                              </div>
                            </div>
                            <div class="col-1">
                              <a (click)="swapValues(key, data.new_data[key], data.old_data[key])">
                                <i class="tt-icon-lg fa fa-arrows-h pt-2" data-toggle="tooltip" data-placement="bottom"
                                  title="Change values"></i>
                              </a>
                            </div>
                            <div class="col-5 pl-0">
                              <div class="card m-0">
                                <span class="p-2">
                                  {{data.old_data[key][item]}}
                                  <i class="tt-icon-sm fa fa-times pull-right p-1 error-color"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
            <div class="form-group"
              *ngIf="isArray(data.new_data[key]) && isArray(data.old_data[key]) && (data.old_data[key].length || data.new_data[key].length || 'jobs' == key)">
              <div class="modal-body-title">
                <div class="form" (click)="accordion[key] = !accordion[key]">
                  <div class="form-section">
                    {{key | removeUndersoreCapitalizeFirst}}
                    {{'jobs' == key ? '(All jobs will be saved)' : '(Please add or remove the items)'}}
                    <i class="pull-right mt-1 pt-2"
                      [ngClass]="{'ft-chevron-right': !accordion[key],'ft-chevron-down': accordion[key]}"></i>
                  </div>
                </div>
              </div>
              <div class="modal-body-content" *ngIf="'jobs' == key && accordion[key]">
                <div class="row">
                  <div *ngFor="let job of data.new_data[key]" class="col-6">
                    <div class="card m-0 p-2 mb-3">
                      <div class="card-body-title-basic">
                        <div class="form-group display-inline-flex">
                          <a><u>{{job.job}}</u></a>
                          <span class="badge-tags-inverse ml-3 bg-success-color cursor-pointer" *ngIf="job.is_vetted">
                            Vetted
                          </span>
                        </div>
                      </div>
                      <div class="card-body-content">
                        <div class="row"
                          *ngIf="(job.stage && job.stage.value) || (job.substatus && job.substatus.description)">
                          <div class="col-6" *ngIf="job.stage && job.stage.value">
                            <div class="form-group">
                              Stage
                              <ng-template #tooltipOpen>
                                <div class="card card-preview">
                                  <div class="card-body">
                                    <div class="card-body-title-basic">
                                      Stage: {{job.stage.description}}
                                    </div>
                                    <div class="card-body-content">
                                      <div *ngIf="job.shortlisted_date">
                                        Shortlisted date:
                                        {{job.shortlisted_date | dateDiffTimestampFullNav:job.shortlisted_date}}
                                      </div>
                                      <div *ngIf="job.sent_date">
                                        Sent date: {{job.sent_date | dateDiffTimestampFullNav:job.sent_date}}
                                      </div>
                                      <div *ngIf="job.first_interview_date">
                                        First interview date:
                                        {{job.first_interview_date | dateDiffTimestampFullNav:job.first_interview_date}}
                                      </div>
                                      <div *ngIf="job.second_interview_date">
                                        Second interview date:
                                        {{job.second_interview_date |
                                        dateDiffTimestampFullNav:job.second_interview_date}}
                                      </div>
                                      <div *ngIf="job.offer_date">
                                        Offer date:
                                        {{job.offer_date | dateDiffTimestampFullNav:job.offer_date}}
                                      </div>
                                      <div *ngIf="job.placed_date">
                                        Placed date:
                                        {{job.placed_date | dateDiffTimestampFullNav:job.placed_date}}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-template>
                              <ng-template #t let-fill="fill" let-last="last" let-index="index">
                                <span class="bullet" [class.full]="fill === 100">
                                  <span class="bullet"
                                    [ngClass]="fill === 100 && (job.substatus && job.substatus.success) ? 'success' : index == job.stage.current_stage-1 ? 'error' : 'success'"
                                    [style.width.%]="fill" [ngbTooltip]="tooltipOpen" placement="auto"
                                    [innerHTML]="globals.labels.placeholders.full_layout_page.omni_component.job.job_history.stage_rating"></span>
                                  <span
                                    [innerHTML]="globals.labels.placeholders.full_layout_page.omni_component.job.job_history.stage_rating"></span>
                                </span>
                              </ng-template>
                              <ngb-rating [(rate)]="job.stage.current_stage" [starTemplate]="t" [readonly]="true"
                                [max]="job.stage.max_stage">
                              </ngb-rating>
                            </div>
                          </div>
                          <div class="col-6" *ngIf="job.substatus && job.substatus.description">
                            <div class="form-group">
                              <span class="badge m-0" [ngStyle]="{'color': job.substatus.color}">
                                <i class="fa fa-circle"></i>
                              </span>
                              <label>{{job.substatus.description}}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-body-content" *ngIf="'jobs' != key && accordion[key]">
                <div class="row">
                  <div class="col-6">
                    <div *ngFor="let item of data.new_data[key];let i = index">
                      <div [ngSwitch]="key">
                        <div *ngSwitchCase="'experiences'">
                          <div class="card m-0 mb-3 p-2">
                            <div class="row">
                              <div class="col-12">
                                <div class="form-group">
                                  <div class="card-body-title-basic display-inline-flex" *ngIf="item.job_title">
                                    <strong> {{item.job_title}}</strong>
                                    <div *ngIf="item.current_employer" class="tag secondary-color ml-3">
                                      <em>{{globals.labels.placeholders.full_layout_page.cit_component.candidate_profile.primary_job}}</em>
                                    </div>
                                    <div *ngIf="item.is_investment" class="tag primary-color ml-3">
                                      <em>Investment</em>
                                    </div>
                                    <span class="badge-tags-inverse ml-3 bg-error-color cursor-pointer right white"
                                      (click)="deleteItem(item, i, key)">
                                      Delete
                                    </span>
                                  </div>
                                  <div class="card-body-content" *ngIf="item.company_name">
                                    <strong> {{item.company_name}}</strong>
                                  </div>
                                  <div class="card-body-content" *ngIf="(item.start_date || item.end_date)">
                                    {{(item.start_date) ? (item.start_date | dateDiffTimestampNav:item.start_date) + ' –
                                    ' : ''}}
                                    {{(item.end_date) ? (item.end_date | dateDiffTimestampNav:item.end_date) :
                                    globals.labels.placeholders.full_layout_page.cit_component.candidate_profile.present}}
                                  </div>
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="form-group">
                                  <div *ngIf="item.seniority && item.seniority.description">
                                    <strong>Seniority: </strong> {{item.seniority.description}}
                                  </div>
                                  <div *ngIf="item.industry.length">
                                    <strong>Industry: </strong>
                                    <span *ngFor="let i of item.industry;let last = last">
                                      {{i.description}}{{last? '':', '}}</span>
                                  </div>
                                  <div *ngIf="item.fe.length">
                                    <strong> Functional expertise: </strong>
                                    <span *ngFor="let i of item.fe;let last = last">
                                      {{i.description}}{{last? '':', '}}</span>
                                  </div>
                                  <div *ngIf="item.sfe.length">
                                    <strong>Sub Functional expertise: </strong>
                                    <span *ngFor="let i of item.sfe;let last = last">
                                      {{i.description}}{{last? '':', '}}</span>
                                  </div>
                                  <div *ngIf="item.expertise.length">
                                    <strong>Expertise: </strong>
                                    <span *ngFor=" let i of item.expertise;let last=last">
                                      {{i.description}}{{last? '':', '}}</span>
                                  </div>
                                </div>
                                <div class="form-group"
                                  *ngIf="item.is_investment && (item.investment_country_focus.length || item.investment_stage_focus.length || item.investment_sector_focus.length)">
                                  <strong>VC fields</strong>
                                  <div *ngIf="item.investment_country_focus.length">
                                    <strong>Country focus: </strong>
                                    <span *ngFor="let i of item.investment_country_focus;let last = last">
                                      {{i.name}}{{last? '':', '}}</span>
                                  </div>
                                  <div *ngIf="item.investment_stage_focus.length">
                                    <strong>Stage focus: </strong>
                                    <span *ngFor="let i of item.investment_stage_focus;let last = last">
                                      {{i.description}}{{last? '':', '}}</span>
                                  </div>
                                  <div *ngIf="item.investment_sector_focus.length">
                                    <strong>Sector focus: </strong>
                                    <span *ngFor="let i of item.investment_sector_focus;let last = last">
                                      {{i}}{{last? '':', '}}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngSwitchCase="'educations'">
                          <div class="card m-0 p-2 mb-3">
                            <div class="row">
                              <div class="col-12">
                                <div class="card-body-title-basic">
                                  {{item.degree_name}}
                                  <span class="badge-tags-inverse ml-3 bg-error-color cursor-pointer right white"
                                    (click)="deleteItem(item, i, key)">
                                    Delete
                                  </span>
                                </div>
                                <div class="card-body-content">
                                  {{item.major}} {{item.university ? '@ '+ item.university : ''}}
                                </div>
                                <div class="card-body-content">
                                  <div *ngIf="item.start_date || item.end_date">
                                    {{(item.start_date) ? (item.start_date | dateDiffTimestampNav:item.start_date) + ' –
                                    ' : ''}}
                                    {{(item.end_date) ? (item.end_date | dateDiffTimestampNav:item.end_date) :
                                    globals.labels.placeholders.full_layout_page.cit_component.candidate_profile.present}}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngSwitchCase="'languages'">
                          <div class="card m-0 p-2 mb-3">
                            <div class="row">
                              <div class="col-12">
                                <strong> {{item.language.description}} </strong>
                                {{item.level && 'NO_VALUE' != item.level.value ? '- ' + item.level.description : ''}}
                                <span class="badge-tags-inverse ml-3 bg-error-color cursor-pointer right white"
                                  (click)="deleteItem(item, i, key)">
                                  Delete
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div *ngFor="let item of data.old_data[key];let i = index">
                      <div [ngSwitch]="key">
                        <div *ngSwitchCase="'experiences'">
                          <div class="card m-0 mb-3 p-2">
                            <div class="row">
                              <div class="col-12">
                                <div class="form-group">
                                  <div class="card-body-title-basic  display-inline-flex" *ngIf="item.job_title">
                                    <strong> {{item.job_title}}</strong>
                                    <div *ngIf="item.current_employer" class="tag secondary-color ml-3">
                                      <em>{{globals.labels.placeholders.full_layout_page.cit_component.candidate_profile.primary_job}}</em>
                                    </div>
                                    <div *ngIf="item.is_investment" class="tag primary-color ml-3">
                                      <em>Investment</em>
                                    </div>
                                    <span class="badge-tags-inverse ml-3 bg-success-color cursor-pointer right"
                                      (click)="addItem(item, i, key)">
                                      Add
                                    </span>
                                  </div>
                                  <div class="card-body-content" *ngIf="item.company_name">
                                    <strong> {{item.company_name}}</strong>
                                  </div>
                                  <div class="card-body-content" *ngIf="(item.start_date || item.end_date)">
                                    {{(item.start_date) ? (item.start_date | dateDiffTimestampNav:item.start_date) + ' –
                                    ' : ''}}
                                    {{(item.end_date) ? (item.end_date | dateDiffTimestampNav:item.end_date) :
                                    globals.labels.placeholders.full_layout_page.cit_component.candidate_profile.present}}
                                  </div>
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="form-group">
                                  <div *ngIf="item.seniority && item.seniority.description">
                                    <strong>Seniority: </strong> {{item.seniority.description}}
                                  </div>
                                  <div *ngIf="item.industry.length">
                                    <strong>Industry: </strong>
                                    <span *ngFor="let i of item.industry;let last = last">
                                      {{i.description}}{{last? '':', '}}</span>
                                  </div>
                                  <div *ngIf="item.fe.length">
                                    <strong> Functional expertise: </strong>
                                    <span *ngFor="let i of item.fe;let last = last">
                                      {{i.description}}{{last? '':', '}}</span>
                                  </div>
                                  <div *ngIf="item.sfe.length">
                                    <strong>Sub Functional expertise: </strong>
                                    <span *ngFor="let i of item.sfe;let last = last">
                                      {{i.description}}{{last? '':', '}}</span>
                                  </div>
                                  <div *ngIf="item.expertise.length">
                                    <strong>Expertise: </strong>
                                    <span *ngFor=" let i of item.expertise;let last=last">
                                      {{i.description}}{{last? '':', '}}</span>
                                  </div>
                                </div>
                                <div class="form-group"
                                  *ngIf="item.is_investment && (item.investment_country_focus.length || item.investment_stage_focus.length || item.investment_sector_focus.length)">
                                  <strong>VC fields</strong>
                                  <div *ngIf="item.investment_country_focus.length">
                                    <strong>Country focus: </strong>
                                    <span *ngFor="let i of item.investment_country_focus;let last = last">
                                      {{i.name}}{{last? '':', '}}</span>
                                  </div>
                                  <div *ngIf="item.investment_stage_focus.length">
                                    <strong>Stage focus: </strong>
                                    <span *ngFor="let i of item.investment_stage_focus;let last = last">
                                      {{i.description}}{{last? '':', '}}</span>
                                  </div>
                                  <div *ngIf="item.investment_sector_focus.length">
                                    <strong>Sector focus: </strong>
                                    <span *ngFor="let i of item.investment_sector_focus;let last = last">
                                      {{i}}{{last? '':', '}}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngSwitchCase="'educations'">
                          <div class="card m-0 p-2 mb-3">
                            <div class="row">
                              <div class="col-12">
                                <div class="card-body-title-basic">
                                  {{item.degree_name}}
                                  <span class="badge-tags-inverse ml-3 bg-success-color cursor-pointer right"
                                    (click)="addItem(item, i, key)">
                                    Add
                                  </span>
                                </div>
                                <div class="card-body-content">
                                  {{item.major}} {{item.university ? '@ '+ item.university : ''}}
                                </div>
                                <div class="card-body-content">
                                  <div *ngIf="item.start_date || item.end_date">
                                    {{(item.start_date) ? (item.start_date | dateDiffTimestampNav:item.start_date) + ' –
                                    ' : ''}}
                                    {{(item.end_date) ? (item.end_date | dateDiffTimestampNav:item.end_date) :
                                    globals.labels.placeholders.full_layout_page.cit_component.candidate_profile.present}}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngSwitchCase="'languages'">
                          <div class="card m-0 p-2 mb-3">
                            <div class="row">
                              <div class="col-12">
                                <strong> {{item.language.description}} </strong>
                                {{item.level && 'NO_VALUE' != item.level.value ? '- ' + item.level.description : ''}}
                                <span class="badge-tags-inverse ml-3 bg-success-color cursor-pointer right"
                                  (click)="addItem(item, i, key)">
                                  Add
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="col-md-3 btn btn-block bg-secondary-color" (click)="mergeCandidates()">
        Merge</button>
      <button type="button" class="col-md-3 btn btn-block bg-primary-color"
        (click)="activeModal.dismiss()">{{globals.labels.placeholders.global.buttons.close}}</button>
    </div>
  </div>
</div>