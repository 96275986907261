<div *ngIf="selectedSubstatus">
    <span *ngIf="selectedSubstatus.additional_comment" class="ft-info p-2 cursor-pointer" [ngbTooltip]="tipContent" placement="auto" container="body">
        <ng-template #tipContent><span [innerHTML]="selectedSubstatus.additional_comment"></span></ng-template>
      </span>
    <span class="blacklist-toggle" *ngIf="selectedSubstatus.type === 'contacted_substatuses_by_consultant';else nonConsultantDescription">
        <span class="badge-tags-inverse nowrap align-self-center d-inline"
        [ngStyle]="{
            color: tagStyleSetter('color'),
            backgroundColor: tagStyleSetter('backgroundColor')}"
        [appStickyPopover]="popContent" container="body" placement="right">{{params.data.contacted_by_consultant_substatus.description}}</span>
    </span>
    <ng-template #nonConsultantDescription>
        <ng-container>{{selectedSubstatus.description}}</ng-container>
    </ng-template>
</div>