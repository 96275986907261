import {
  NgModule,
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA
} from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { NgbModule, NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";

import { HttpClientModule } from "@angular/common/http";

import { AppComponent } from "./app.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

import { AuthService } from "./shared/auth/auth.service";
import { AuthGuard } from "./shared/auth/auth-guard.service";

import { UserDataService } from "./shared/service/userData.service";
//global variables
import { Globals } from "./globals";
//bearer token
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { TokenInterceptor } from "./shared/auth/token.interceptor";

import { CommunicationService } from "./shared/service/communication.service";
import { PermissionsGuard } from "./shared/guards/permissions-guard.service";

import { QuillModule } from "ngx-quill";
import { FormsModule } from "@angular/forms";
import { ClipboardModule } from "ngx-clipboard";
import { ToastrModule } from "ngx-toastr";
import { VersionCheckService } from "./version-check.service";
import { FileUploadModule } from "ng2-file-upload";
import { Angulartics2Module } from "angulartics2";

import { GetNavisCommunicationService } from './shared/getnavis/getnavis-service/get-navis-communication.service';
import { TimestampToDatepickerJobPipe } from "./shared/pipes/timestamp-to-datepicker-job.pipe";

export function globalProviderFactory(provider: Globals){
  return () => provider.load();
}

@NgModule({
  declarations: [AppComponent, FullLayoutComponent, ContentLayoutComponent, TimestampToDatepickerJobPipe],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    NgbModule,
    ToastrModule.forRoot({
      positionClass: "toast-bottom-right",
      closeButton: true,
      enableHtml: true
    }),
    QuillModule.forRoot(),
    FormsModule,
    ClipboardModule,
    FileUploadModule,
    Angulartics2Module.forRoot()
  ],
  providers: [
    Globals,
    {
      provide: APP_INITIALIZER,
      useFactory: globalProviderFactory,
      deps: [Globals],
      multi: true
    },
    AuthService,
    AuthGuard,
    PermissionsGuard,
    HttpClientModule,
    UserDataService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    CommunicationService,
    //CandidatesHelperService,
    VersionCheckService,
    NgbPopoverConfig,
    GetNavisCommunicationService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule{}
