import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Angulartics2 } from 'angulartics2';

@Injectable()
export class JobActvityService{

  constructor(private angulartics2: Angulartics2, private http: HttpClient){ }

  getActivity(jobUUID): Observable<any>{
    this.angulartics2.eventTrack.next({
      action: 'Loaded job activities',
      properties: { jobUUID }
    });

    return this.http.get(environment.platformBackendURL + '/activities?job_id=' + jobUUID);
  }

}
