import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Globals } from 'app/globals';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LonglistCandidateModalService } from './longlist-candidate-modal.service';
import { Subject, of, concat } from 'rxjs';
import { distinctUntilChanged, debounceTime, switchMap, tap, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-longlist-candidate-modal',
  templateUrl: './longlist-candidate-modal.component.html',
  styleUrls: ['./longlist-candidate-modal.component.scss']
})
export class LonglistCandidateModalComponent implements OnInit{
  selectedCandidatesUuids: any = [];

  succesfulCandidatesUuids: any = [];

  candidatesDataForValidation: any = [];

  candidate_rating: any;

  candidate: any = {};

  subStatus: string = null;

  comment: string = null;

  job_uuid: string = null;

  validForm = false;

  changed = false;

  hasCandidateData = false;

  index = 0;

  languageLoading = false;

  languageinput = new Subject<string>();

  nationalityLoading = false;

  nationalityinput = new Subject<string>();

  sectorLoading = false;

  sectorinput = new Subject<string>();

  stageLoading = false;

  stageinput = new Subject<string>();

  countryLoading = false;

  countryinput = new Subject<string>();

  inProcess = null;

  isBlacklisted = null;

  inProcessJobs = '';

  resources: any = {
    gender: [],
    fe: [],
    sfe: [],
    industry: [],
    seniority: [],
    source: [],
    filteredSfe: [],
    language: [],
    level: [],
    fileType: [],
    subStatuses: [],
    jobs: {
      type: null,
      data: []
    },
    stageFocus: [],
    countryFocus: [],
    sectorFocus: [],
    nationality: [],
    contacts: [],
    campaigns: [],
    clients: [],
    activity: [],
    filters: []

  };

  isBlacklistedCandidate: any;

  constructor(public globals: Globals, private modalService: NgbModal, private longlistCandidateService: LonglistCandidateModalService, private cdr: ChangeDetectorRef,
    public activeModal: NgbActiveModal, public toastr: ToastrService){ }

  ngOnInit(){
    this.selectedCandidatesUuids = this.selectedCandidatesUuids.map(function (item){
      return {
         uuid: item.candidate_id ? item.candidate_id : item,
         application_uuid: item.application_uuid,
         in_process: item.in_process
        };
    });
    this.getCandidatesDataForValidation();
  }

  getCandidatesDataForValidation(){

    this.longlistCandidateService.getCandidatesDataForValidation(this.selectedCandidatesUuids).subscribe(
      (response) => {
        this.candidatesDataForValidation = response;
        if (this.candidatesDataForValidation.length > 0){
          this.loadCountry();
          this.loadSector();
          this.getFe();
          this.getSfe();
        }
        this.longlistCandidate(-1);
      },
      (err) => {
        this.toastr.error(err);
      }
    );
  }

  onChange(){
    this.changed = true;
  }

  getGender(){
    if (!this.resources.gender.length){
      this.longlistCandidateService.getResources('gender_type')
        .subscribe((data) => {
          this.resources.gender = data;
        });
    }
  }

  getIndustry(){
    if (!this.resources.industry.length){
      this.longlistCandidateService.getIndustryResources()
        .subscribe((data) => {
          this.resources.industry = data;
        });
    }
  }

  getSeniority(){
    if (!this.resources.seniority.length){
      this.longlistCandidateService.getResources('role_seniority')
        .subscribe((data) => {
          this.resources.seniority = data;
        });
    }
  }

  getFe(){
    if (!this.resources.fe.length){
      this.longlistCandidateService.getFeResources()
        .subscribe((data) => {
          this.resources.fe = data;
        });
    }
  }

  onUnselectFunctional(experience){
    if (experience.fe.length){
      this.getFilteredSfe(experience.fe);
      experience.fe.forEach(fe => {
        experience.sfe = experience.sfe.filter((element) => fe.value == element.fe_value);
      });
    } else {
      experience.sfe = [];
    }

  }

  getFilteredSfe(fe){
    this.resources.filteredSfe = [];
    if (fe.length){
      fe.forEach((element) => {
        this.resources.sfe.forEach(obj => {
          if (obj.fe_value === element.value || obj.fe_value === null){
            this.resources.filteredSfe.push(obj);
          }

        });
      });
    }
    //removes duplicates from array
    this.resources.filteredSfe = this.resources.filteredSfe.filter(
      (item, i, arr) => arr.findIndex(distinctItem => distinctItem.value && distinctItem.value === item.value) === i
    );

  }

  getSfe(){
    this.longlistCandidateService.getSfeResources()
      .subscribe((data) => {
        this.resources.sfe = data;
      });
  }

  getStageFocus(){
    if (!this.resources.stageFocus.length){
      this.longlistCandidateService.getStageFocusResources()
        .subscribe((data) => {
          this.resources.stageFocus = data;
        });
    }
  }

  loadSector(){
    this.resources.sectorFocus = concat(
      of([]),
      this.sectorinput.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.sectorLoading = true),
        switchMap(data =>
          this.longlistCandidateService.getSectorFocusResources(data).pipe(
            catchError(() => of([])),
            tap(() => {
              this.sectorLoading = false;
            })
          ))
      )
    );
  }

  loadCountry(){
    this.resources.countryFocus = concat(
      of([]),
      this.countryinput.pipe(

        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.countryLoading = true),
        switchMap(data =>
          this.longlistCandidateService.getCountryFocusResources(data).pipe(
            catchError(() => of([])),
            tap(() => {
              this.countryLoading = false;
            })
          ))
      )
    );
  }

  removeInvestmentFromExperience(experience){
    const body = {
      is_investment: false,
      investment_country_focus: [],
      investment_sector_focus: [],
      investment_stage_focus: []
    };
    this.updateExperience(body, experience.uuid);
  }

  editExperience(key, value, uuid, i?, array?){
    if (key == 'start_date' || key == 'end_date'){
      value ? array[i][key] = new Date(value).getTime() / 1000 : array[i][key] = null;
    }
    const body = this.recreateJobObject(key, value);
    this.updateExperience(body, uuid);
  }

  updateExperience(body: any, uuid){
    this.longlistCandidateService.updateExperience(uuid, body)
      .subscribe(() => {
        this.toastr.success('Successfully updated experience');
        this.changed = false;
      },
        (err) => {
          if (err.error){
            for (const errProp in err.error){
              if (err.error.hasOwnProperty(errProp)){
                if (err.error[errProp][0]){
                  this.toastr.error(err.error[errProp]);
                }
              }
            }
          } else {
            this.toastr.error('Something went wrong');
          }
        });
  }

  editCandidate(key, value, uuid){
    const body = this.recreateJobObject(key, value);
    this.longlistCandidateService.updateCandidate(uuid, body)
      .subscribe(() => {
        this.toastr.success('Successfully updated field');
      },
        (err) => {
          if (err.error){
            for (const errProp in err.error){
              if (err.error.hasOwnProperty(errProp) && err.error[errProp][0]){

                  this.toastr.error(err.error[errProp]);

              }
            }
          } else {
            this.toastr.error('Something went wrong');
          }
        });
    this.changed = false;
  }

  recreateJobObject(key, value){
    if (key.indexOf('.') > -1){
      const keyValue = key.substr(0, key.indexOf('.'));
      const obj = {};
      obj[keyValue] = {
        [key.split('.').pop()]: value
      };
      return obj;
    }

    return {
        [key]: value
      };

  }

  checkRequiredFields(){

    if (!this.candidate.gender){
      this.validForm = false;
      this.toastr.error("Gender is required");
      return false;

    }
    for (let i = 0; i < this.candidate.experiences.length; i++){
      let valid = true;
      if (!this.candidate.experiences[i].not_applicable){
        let message = "Experience with title " + this.candidate.experiences[i].job_title + " is missing ";

        if (!this.candidate.experiences[i].job_title){
          message = String(message);
          valid = false;
        }
        if (!this.candidate.experiences[i].company_name){
          message += "company name ";
          valid = false;
        }
        if (!this.candidate.experiences[i].start_date){
          message += "start date ";
          valid = false;
        }
        if (!this.candidate.experiences[i].seniority){
          message += "seniority ";
          valid = false;
        }
        if (!this.candidate.experiences[i].fe.length){
          message += "functional expertise ";
          valid = false;
        }
        if (!valid){
          this.toastr.error(message);
          this.validForm = false;
          return false;
        }
      }
    }
    return true;
  }

  removeCandidate(index){
    this.selectedCandidatesUuids = this.selectedCandidatesUuids.filter(obj => obj.uuid != this.candidate.uuid);

    if (this.candidatesDataForValidation[index + 1]){
      this.candidate = this.candidatesDataForValidation[index + 1];
      this.index += 1;
    } else {
      if (this.selectedCandidatesUuids.length > 0){
        this.selectedCandidatesUuids.forEach(element => {
          this.createLonglistApplication(element.uuid);
        });
      } else {
        this.activeModal.close({
          application_data: {
            candidates: this.succesfulCandidatesUuids,
            substatus: this.subStatus,
            comment: this.comment,
            candidate_rating: this.candidate_rating,
            stage: {
              description: 'Longlist'
            }
          }
        });
      }
    }
    this.checkCandidateTags();
  }

  longlistCandidate(index){
    if (this.candidatesDataForValidation.length){
      if (index !== -1){
        if (this.checkRequiredFields()){
          this.createLonglistApplication(this.candidate.uuid);
          if (index != this.candidatesDataForValidation.length - 1){
            this.candidate = this.candidatesDataForValidation[index + 1];
            this.index += 1;
          } else {
            this.selectedCandidatesUuids.forEach(element => {
              if (index < this.candidatesDataForValidation.length - 1){
                this.createLonglistApplication(element.uuid);
              }
            });
          }
        }
      } else {
        this.candidate = this.candidatesDataForValidation[index + 1];
        this.hasCandidateData = true;
      }
    } else {
      this.selectedCandidatesUuids.forEach(element => {
        this.createLonglistApplication(element.uuid);
      });
    }
    this.checkCandidateTags();
  }

  createLonglistApplication(candidate_uuid){
    const application_uuid = this.selectedCandidatesUuids.find(item => item.uuid == candidate_uuid).application_uuid;
    const body = {
      candidate_uuid: candidate_uuid,
      job_uuid: this.job_uuid,
      substatus: this.subStatus,
      comment: this.comment,
      stage: {
        value: "longlist",
        description: 'Longlist'
      }
    };
    if (this.candidate_rating){
      body['candidate_rating'] = this.candidate_rating;
    }

    this.sendApplicationDataToServer(candidate_uuid, body, application_uuid);

  }

  sendApplicationDataToServer(candidate_uuid, body, application_uuid){
    this.longlistCandidateService.createLonglistApplication(body, application_uuid)
    .subscribe((response) => {
      this.succesfulCandidatesUuids.push(candidate_uuid);
      if (response.success){
        response.success.forEach(element => {
          if (element.hasOwnProperty('message')){
            this.toastr.success(element.message);
          }
        });
      }
    },
      (err) => {
        if (err.error){
          for (const errProp in err.error){
            if (err.error.hasOwnProperty(errProp)){
              if (err.error[errProp][0]){
                this.toastr.error(err.error[errProp]);
              }
            }
          }
        } else {
          this.toastr.error('Something went wrong');
        }
      })
.add(() => {
        this.selectedCandidatesUuids = this.selectedCandidatesUuids.filter(obj => obj.uuid != candidate_uuid);
        if (this.selectedCandidatesUuids.length == 0){
          this.activeModal.close({
            application_data: {
              candidates: this.succesfulCandidatesUuids,
              substatus: this.subStatus,
              comment: this.comment,
              candidate_rating: this.candidate_rating,
              stage: {
                description: 'Longlist'
              }
            }
          });
        }
      });
  }

  checkCandidateTags(){
    this.inProcessJobs = '';
    this.inProcess = null;
    this.isBlacklisted = null;
    this.isBlacklistedCandidate = null;
    for (const item of this.candidate.tags){
      if (item.description === "In Process"){
        this.inProcess = item;
        const tempJobs = [];
        item.data.forEach(element => {
          if (element.uuid !== this.job_uuid){
            tempJobs.push(element.job_title);
          }
        });
        this.inProcessJobs = tempJobs.join(', ');
      } else if (item.description === "Blacklisted company"){
        this.isBlacklisted = item;
      } else if (item.description === "Blacklisted candidate"){
        this.isBlacklistedCandidate = item;
      }
    }
  }
}
