import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from 'app/globals';

@Component({
  selector: 'app-currently-unemployed',
  templateUrl: './currently-unemployed-modal.component.html'
})
export class CurrentlyUnemployedModalComponent implements OnInit{

  constructor(public activeModal: NgbActiveModal, public globals: Globals){ }

  ngOnInit(){
  }

}
