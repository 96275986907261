<div *ngIf="data">
    <div class="d-flex align-items-center">
        <div class="mr-3">
            <img [src]="setAvatar()" class="height-35 width-35 rounded-circle margin-table-pic" [ngStyle]="{border: borderSetter()}">
        </div>
        <div class="line-height-20-px full-width">
            <div class="d-flex">
                <a *ngIf="data.linkedin" class="mr-2" [href]="data.linkedin" target="_blank">
                    <img [src]="globals.linkedin">
                </a>
                <div *ngIf="data.name" [ngClass]="setNameClass()" class="cursor-pointer" style="text-decoration: underline;" (click)="nameClick($event)">
                    {{data.name}}
                </div>
                <app-hubspot [data]="data.hubspot" [clickable]="true" class="full-width"></app-hubspot>
            </div>
            <div class="blacklist-toggle" *ngIf="(data.blacklisted_company || data.blacklisted_candidate)">
                <span class="badge-tags-inverse nowrap align-self-center d-inline"
                [ngStyle]="{
                    color: tagStyleSetter('color'),
                    backgroundColor: tagStyleSetter('backgroundColor')}"
                [appStickyPopover]="popContent" container="body" placement="right">Blacklisted</span>
            </div>
           
        </div>
    </div>
    <ng-template #popContent>
        <div class="card scroll aggird-popover">
            <ul class="list-group list-group-flush">
            <li *ngIf="data.blacklisted_company" class="list-group-item ">
                <h5 class="card-title">Blacklisted company</h5>
                <p class="card-text" [innerHTML]="data.blacklisted_company.hover_data"></p>
            </li>
            <li *ngIf="data.blacklisted_candidate" class="list-group-item">
                <h5 class="card-title">Blacklisted candidate</h5>
                <p class="card-text" [innerHTML]="data.blacklisted_candidate.hover_data"></p>
                </li>
            </ul>
        </div>
    </ng-template>
</div>