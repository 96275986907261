import { Component, EventEmitter, OnInit, ViewChild, Output, ViewEncapsulation } from '@angular/core';
import { Globals } from 'app/globals';
import { ToastrService } from 'ngx-toastr';
import { AddHiringCompanyModalService } from './add-hiring-company-modal.service';
import { concat, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime, switchMap, tap, catchError } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-hiring-company-modal',
  templateUrl: './add-hiring-company-modal.component.html',
  styleUrls: ['./add-hiring-company-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddHiringCompanyModalComponent implements OnInit{

  @Output() dismissDrawer: EventEmitter<boolean> = new EventEmitter<boolean>();

  hiringCompany: any = null;

  show: any = {
    component: false
  };

  companies: Observable<any>;

  companyLoading = false;

  companyinput$ = new Subject<string>();

  tempCompanyName = null;

  @ViewChild('companiesSelect') companiesNgSelect: NgSelectComponent;

  constructor(public globals: Globals, private addHiringCompanyModalService: AddHiringCompanyModalService, private toastr: ToastrService, public activeModal: NgbActiveModal){
  }

  ngOnInit(){
    this.initData();
    this.loadCompany();
    this.show.component = true;
  }

  initData(){
    this.hiringCompany = {
      name: null,
      photo: null,
      linkedin_url: null,
      dealroom_url: null,
      website_url: null,
      company_culture_description: null
    };
  }

  loadCompany(){
    this.companies = concat(
      of([]),
      this.companyinput$.pipe(
          debounceTime(200),
          distinctUntilChanged(),
          tap(() => this.companyLoading = true),
          switchMap(company =>
            this.addHiringCompanyModalService.getDealroomCompanies(company).pipe(
                  catchError(() => of([])),
                  tap(() => {
                      this.companyLoading = false;
                  })
              )
          )
      )
    );
  }

  addCompany(name){
    this.initData();
    this.companyLoading = false;
    this.hiringCompany.name = name;
    this.companiesNgSelect.close();
  }

  createHiringCompany(){
    this.addHiringCompanyModalService.createHiringCompany(this.hiringCompany)
    .subscribe((response) => {
     this.toastr.success(response.message);
     this.initData();
     this.activeModal.close(response);
     this.dismissDrawer.emit(true);
    },
    (err) => {
      if (err.error){
        for (const errProp in err.error){
          if (err.error.hasOwnProperty(errProp)){
            if (err.error[errProp][0]){
              this.toastr.error(err.error[errProp]);
            }
          }
        }
      } else {
        this.toastr.error(err.message);
      }
    });
}

  setCompanyData(data){
    if (data){
      this.hiringCompany.photo = data.photo || data.images['100x100'];
      this.hiringCompany.linkedin_url = data.linkedin_url;
      this.hiringCompany.dealroom_url = data.url;
      this.hiringCompany.website_url = data.website_url;
      this.hiringCompany.company_culture_description = data.company_culture_description;
      this.hiringCompany.dealroom_id = data.id;
      this.hiringCompany.dealroom_data = data;
    }
  }
}
