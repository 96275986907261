import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpClient } from "@angular/common/http";
import { environment } from 'environments/environment';
import { Angulartics2 } from 'angulartics2';

@Injectable()
export class CompanyActivityService{

  constructor(private angulartics2: Angulartics2, private httpClient: HttpClient){ }

  getCompanyActivities(companyUuid): Observable<any>{
    this.angulartics2.eventTrack.next({
      action: 'Loaded company activities',
      properties: { companyUuid }
    });

    return this.httpClient.get(environment.platformBackendURL + '/activities?company_id=' + companyUuid);
  }
}
