import { Component, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CommunicationService } from 'app/shared/service/communication.service';
import { Globals } from 'app/globals';
import { filter, Subscription, takeWhile } from 'rxjs';
import { GetNavisCommunicationService } from 'app/shared/getnavis/getnavis-service/get-navis-communication.service';

@Component({
    selector: 'app-full-layout',
    templateUrl: './full-layout.component.html',
    styleUrls: ['./full-layout.component.scss']
})

export class FullLayoutComponent implements AfterViewInit, OnDestroy{
    paramsSubscription: Subscription;

    componentAlive = true;

    filterApplied = false;

    @ViewChild('wrapper') public wrapper: ElementRef;

    constructor(private activatedRoute: ActivatedRoute, private communicationService: CommunicationService, public globals: Globals, private getNavisCommunicationService: GetNavisCommunicationService, private router: Router){
        this.getNavisCommunicationService.toggleSidebar$.pipe(
            takeWhile(() => this.componentAlive))
            .subscribe((data) => {
                this.filterApplied = data;
                this.setMenuAppearance();
            });

            this.checkComponentsFilters();
    }

    checkComponentsFilters(){
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            const routerSegment = this.router.parseUrl(this.router.url).root.children.primary.segments[1]?.path;
            if (routerSegment == 'candidates' && sessionStorage.getItem('filter-active-candidates') == 'true'){
                this.filterApplied = true;
            } else if (routerSegment == 'jobs' && sessionStorage.getItem('filter-active-jobs') == 'true'){
                this.filterApplied = true;
            } else {
                this.filterApplied = false;
            }
            this.setMenuAppearance();
        });
    }

    ngAfterViewInit(){
        this.paramsSubscription = this.activatedRoute.queryParams.subscribe(params => {
            if (!params.filter && !params.feedback){
                this.openPlatformDrawer(params);
            }
        });
        this.setMenuAppearance();
    }

    setMenuAppearance(){
        this.wrapper?.nativeElement.classList.add('wrapper');
        if (this.filterApplied){
            this.wrapper?.nativeElement.classList.add('menu-collapsed');
            this.wrapper?.nativeElement.classList.add('nav-collapsed');
        } else {
            this.wrapper?.nativeElement.classList.remove('menu-collapsed');
            this.wrapper?.nativeElement.classList.remove('nav-collapsed');
        }
    }

    openPlatformDrawer(queryParams: any){
        switch (queryParams.drawer_parent){
            case 'add-data-drawer':
                {
                    if (queryParams.type == "add-job"){
                        if (this.globals.checkIfExists('permission', 'create_job') || (queryParams.job_uuid && this.globals.checkIfExists('permission', 'update_job'))){
                            this.communicationService.emitAddDataDrawer(queryParams.job_uuid ? queryParams.job_uuid : null, queryParams.type);
                        }
                    }
                    if (queryParams.type == "add-activity"){
                        if (this.globals.checkIfExists('permission', 'create_activities')){
                            this.communicationService.emitAddDataDrawer(queryParams.job_uuid ? queryParams.job_uuid : null, queryParams.type);
                        }
                    }
                    if (queryParams.type == "add-revenue"){
                        if (this.globals.checkIfExists('permission', 'create_revenue')){
                            this.communicationService.emitAddDataDrawer(queryParams.job_uuid ? queryParams.job_uuid : null, queryParams.type);
                        }
                    }
                    if (queryParams.type == "add-candidate"){
                        if (this.globals.checkIfExists('permission', 'add_candidates')){
                            this.communicationService.emitAddDataDrawer(null, queryParams.type);
                        }
                    }

                    if (queryParams.type == "add-contact"){
                        if (this.globals.checkIfExists('permission', 'create_contacts')){
                            this.communicationService.emitAddDataDrawer(null, queryParams.type);
                        }
                    }
                    break;
                }

            case 'getnavis-consultant-drawer': {
                if (queryParams.type == 'view-consultant' || queryParams.type == 'add-consultant'){
                    this.getNavisCommunicationService.emitGetnavisConsultantDataDrawer(queryParams.uuid || null, queryParams.type);
                }
                break;
            }

            case 'getnavis-consultant-company-drawer': {
                if (queryParams.type == 'view-consultant-company' || queryParams.type == 'add-consultant-company'){
                    this.getNavisCommunicationService.emitGetnavisConsultantCompaniesDataDrawer(queryParams.consultant_company_uuid || null, queryParams.type);
                }
                break;
            }

            case 'getnavis-hiring-company-drawer': {
                if (queryParams.type == 'view-hiring-company' || queryParams.type == 'add-hiring-company'){
                    this.getNavisCommunicationService.emitGetnavisHiringCompaniesDataDrawer(null, queryParams.type);
                }
                break;
            }

            case 'getnavis-job-drawer': {
                if (queryParams.type == 'view-job' || queryParams.type == 'add-job'){
                    this.getNavisCommunicationService.emitGetnavisJobDataDrawer(queryParams.job_uuid, queryParams.type, queryParams.origin);
                }
                break;
            }

            case 'getnavis-candidate-drawer': {
                if (queryParams.type == 'view-candidate' || queryParams.type == 'add-candidate'){
                    this.getNavisCommunicationService.emitGetnavisCandidateDataDrawer(queryParams.candidate_uuid, queryParams.type, queryParams.origin);
                }
                break;
            }

            case 'candidate-drawer':
                {
                    if (queryParams.type == "view-candidate"){
                        this.communicationService.emitCandidatetDataDrawer(
                            queryParams.candidate_uuid,
                            queryParams.type,
                            queryParams.job_uuid ? queryParams.job_uuid : null,
                            queryParams.origin ? queryParams.origin : null,
                            queryParams.parent_component ? queryParams.parent_component : null,
                            queryParams.candidate_index ? queryParams.candidate_index : null,
                            queryParams.filter_type ? queryParams.filter_type : null,
                            queryParams.recommended_job ? queryParams.recommended_job : null
                        );
                    }
                    break;
                }
            case 'company-drawer':
                {
                    if (queryParams.type == "company-activity" || queryParams.type == "company-report"){
                        if (this.globals.checkIfExists('permission', 'read_companies')){
                            this.communicationService.emitCompanyDataDrawer(queryParams.company_uuid, queryParams.type);
                        }
                    }
                    break;
                }
            case 'contact-drawer':
                {
                    if (queryParams.type == "view-contact"){
                        if (this.globals.checkIfExists('permission', 'read_contacts')){
                            this.communicationService.emitContactDataDrawer(queryParams.contact_uuid, queryParams.type);
                        }
                    }
                    break;
                }
            case 'job-drawer':
                {
                    if (queryParams.type == "close-job"){
                        if (this.globals.checkIfExists('permission', 'delete_job')){
                            this.communicationService.emitJobDataDrawer(queryParams.job_uuid, queryParams.type, queryParams.origin);
                        }
                    }
                    if (queryParams.type == "job-activity"){
                        if (this.globals.checkIfExists('permission', 'read_activities')){
                            this.communicationService.emitJobDataDrawer(queryParams.job_uuid, queryParams.type, queryParams.origin);
                        }
                    }
                    if (queryParams.type == "view-job"){
                        if (this.globals.env == 'getnavis' || this.globals.checkIfExists('permission', 'create_job')){
                            this.communicationService.emitJobDataDrawer(queryParams.job_uuid, queryParams.type, queryParams.origin);
                        }
                    }
                    break;
                }
            case 'campaign-drawer':
                {
                    if (queryParams.type == "view-campaign"){
                        if (this.globals.checkIfExists('permission', 'view_campaigns')){
                            this.communicationService.emitCampaignDataDrawer(queryParams.campaign_uuid, queryParams.type);
                        }
                    }
                    break;
                }
            case 'getnavis-campaign-drawer': {
                if (queryParams.type == "view-campaign"){
                    this.communicationService.emitGetNavisCampaignDataDrawer(queryParams.campaign_uuid, queryParams.type);

                }
                break;
            }

            default: {
                break;
            }
        }
        this.closeOtherDrawers(queryParams.drawer_parent);
    }

    closeOtherDrawers(parent){
        const aside = document.getElementsByTagName('aside');
        if (document.getElementsByTagName('aside')){
            for (let i = 0; i < aside.length; i++){
                if (parent){
                    if (aside[i].getAttribute('id') != parent){
                        aside[i].style.visibility = "hidden";
                    }
                } else {
                    aside[i].style.visibility = "hidden";
                }
            }
        }
    }

    ngOnDestroy(){
        this.paramsSubscription.unsubscribe();
    }
}
