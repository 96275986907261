import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Globals } from 'app/globals';
import { Angulartics2 } from 'angulartics2';

@Injectable()
export class ViewCandidateService{
  constructor(private angulartics2: Angulartics2, private httpClient: HttpClient, private globals: Globals){ }

  getCandidateProfile(uuid: any): Observable<any>{
    return this.httpClient.get(environment.getNavisBackendURL + '/navis/candidates/' + uuid);
  }

  getComments(candidate_uuid, job_uuid): Observable<any>{
    if (job_uuid){
      return this.httpClient.get(environment.getNavisBackendURL + '/navis/candidates/comments/' + candidate_uuid + '?job_uuid=' + job_uuid);
    } else {
      return this.httpClient.get(environment.getNavisBackendURL + '/navis/candidates/comments/' + candidate_uuid);
    }
  }

  getActivity(candidate_uuid, filters, type, candidate_emails): Observable<any>{
    const body = {
      "candidate_emails": candidate_emails,
      "candidate_uuid": candidate_uuid,
      "filters": filters
    };

    this.angulartics2.eventTrack.next({
      action: 'Loaded candidate activity',
      properties: { candidate_uuid, filters, type, candidate_emails }
    });

    switch (type){
      case 'insights': {
        return this.httpClient.post(environment.getNavisBackendURL + '/navis/activities/insights', body);
      }
      case 'email': {
        return this.httpClient.post(environment.graphAPIURL + '/mongodb/mails/', body);
      }
      case 'role_activity': {
        return this.httpClient.post(environment.getNavisBackendURL + '/navis/activities/roleActivities', body);
      }
      default: {}
    }
    return null;
  }

  getInitialSubStatuses(): Observable<any>{
    return this.httpClient.get(environment.getNavisBackendURL + '/navis/resources/subStatuses?value=' + this.globals.substatuses);
  }

  getStageStatus(): Observable<any>{
    return this.httpClient.get(environment.getNavisBackendURL + '/navis/resources/applicationStages?current_stage=null');
  }

  getResources(type): Observable<any>{
    return this.httpClient.get(environment.getNavisBackendURL + '/navis/resources?type=' + type);
  }

  getActivityFilterResources(): Observable<any>{
    return this.httpClient.get(environment.getNavisBackendURL + '/navis/resources?type=candidate_activity');
  }

  getCampaignsResources(): Observable<any>{
    return this.httpClient.get(environment.getNavisBackendURL + '/navis/resources/campaigns');
  }

  getUsers(params): Observable<any>{
    return this.httpClient.get(environment.getNavisBackendURL + '/navis/resources/users' + params);
  }

  getCandidateJobsResources(type, candidate_uuid): Observable<any>{
    return this.httpClient.get(environment.getNavisBackendURL + '/navis/resources/jobs' + (candidate_uuid ? '?candidate_uuid=' + candidate_uuid : '') + (type ? '?type=' + type : ''));
  }
}
