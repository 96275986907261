import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';

@Injectable()
export class GetnavisViewJobService{

    constructor(private httpClient: HttpClient){ }

    getJob(uuid): Observable<any>{
        return this.httpClient.get(environment.getNavisBackendURL + '/merger/jobs/' + uuid);
    }

    getResources(value): Observable<any>{
        return this.httpClient.get(environment.getNavisBackendURL + '/resources/' + value);
    }

    getResourcesByType(value): Observable<any>{
        return this.httpClient.get(environment.getNavisBackendURL + '/resources?type=' + value);
      }

    getUsers(value): Observable<any>{
        return this.httpClient.get(environment.getNavisBackendURL + '/merger/users/' + value);
    }

    getHiringCompanies(company?: string): Observable<any>{
        return this.httpClient.get(environment.getNavisBackendURL + '/resources/hiring_companies' + (company ? '?search=' + company : ''));
    }

    getLocations(parameter: string): Observable<any>{
        const body = {
          "input_text": parameter
        };
        return this.httpClient.post(environment.djangoURL + '/geocoder/autocomplete/', body);
    }

    getDealroomCompanies(company: string): Observable<any>{
        const body = {
          'keyword': company,
          'keyword_type': 'default',
          'keyword_match_type': 'exact',
          'fields': 'id,name,path,website_url,url,linkedin_url,images,hq_locations',
          form_data: {
                  must: {},
                  must_not: {},
                  should: {}
                },
          'sort': 'name',
          limit: 25,
          offset: 0
        };
        return this.httpClient.post(environment.getNavisBackendURL + '/dealroom/companies', body).pipe(map((rsp: any) => rsp.items));
    }

    updateJob(uuid, body): Observable<any>{
        return this.httpClient.put(environment.getNavisBackendURL + '/merger/jobs/' + uuid, body);
    }

    createArchetype(body): Observable<any>{
        return this.httpClient.post(environment.getNavisBackendURL + '/merger/jobs/archetypes', body);
    }

    updateArchetype(uuid, body): Observable<any>{
        return this.httpClient.put(environment.getNavisBackendURL + '/merger/jobs/archetypes/' + uuid, body);
    }

    deleteArchetype(uuid, body): Observable<any>{
        return this.httpClient.delete(environment.getNavisBackendURL + '/jobs/archetypes/' + uuid, body);
    }

    getConsultantCompanies(){
        return this.httpClient.get(environment.getNavisBackendURL + '/resources/consultant_companies');
    }

    deleteJobIteration(uuid, iteration_number): Observable<any>{
      return this.httpClient.delete(environment.getNavisBackendURL + '/merger/jobs/' + uuid + '/' + iteration_number);
    }

    addHuntingGroundCompany(job_uuid, hunting_ground_uuid, body): Observable<any>{
      return this.httpClient.post(environment.getNavisBackendURL + '/merger/jobs/' + job_uuid + '/targetCompanies/' + hunting_ground_uuid, body);
    }

    removeHuntingGroundCompany(company_uuid): Observable<any>{
      return this.httpClient.delete(environment.getNavisBackendURL + '/merger/jobs/targetCompanies/' + company_uuid);
    }
}
