import {
    ElementRef,
    Directive, Input, TemplateRef,
    Renderer2,
    Injector,
    ComponentFactoryResolver,
    ViewContainerRef,
    NgZone,
    OnInit,
    OnDestroy,
    Inject,
    ChangeDetectorRef,
    ApplicationRef
  } from '@angular/core';

  import { DOCUMENT } from '@angular/common';
  import { NgbPopover, NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
  @Directive({
    selector: '[appStickyPopover]',
    exportAs: 'appStickyPopover'
  })
  export class StickyPopoverDirective extends NgbPopover implements OnInit, OnDestroy{
    @Input() appStickyPopover: TemplateRef<any>;

    popoverTitle: string;

    placement: 'auto' | 'top' | 'bottom' | 'left' | 'right' | 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' | 'left-top' | 'left-bottom' | 'right-top' | 'right-bottom' | ('auto' | 'top' | 'bottom' | 'left' | 'right' | 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' | 'left-top' | 'left-bottom' | 'right-top' | 'right-bottom')[];

    triggers: string;

    container: string;

    ngpPopover: TemplateRef<any>;

    canClosePopover: boolean;

    toggle(): void{
      super.toggle();
    }

    isOpen(): boolean{
      return super.isOpen();
    }

    constructor(
      private elRef: ElementRef,
      private render: Renderer2,
      injector: Injector,
      componentFactoryResolver: ComponentFactoryResolver,
      private viewContainerRef: ViewContainerRef,
      config: NgbPopoverConfig,
      ngZone: NgZone,
      changeDetector: ChangeDetectorRef,
      applicationRef: ApplicationRef,
      @Inject(DOCUMENT) _document: any){
      super(elRef, render, injector, componentFactoryResolver, viewContainerRef, config, ngZone, _document, changeDetector, applicationRef);
      this.triggers = 'manual';
      this.popoverTitle = 'Permissions';
      this.container = 'body';
  }

    ngOnInit(): void{
      super.ngOnInit();
      this.ngbPopover = this.appStickyPopover;

      this.render.listen(this.elRef.nativeElement, 'mouseenter', () => {
        this.canClosePopover = true;
        this.open();
      });

      this.render.listen(this.elRef.nativeElement, 'mouseleave', () => {
        setTimeout(() => { if (this.canClosePopover){ this.close(); } }, 100);

      });

      this.render.listen(this.elRef.nativeElement, 'click', () => {
        this.close();
      });
    }

    ngOnDestroy(): void{
      super.ngOnDestroy();
    }

    open(){
      super.open();
      setTimeout(() => {
        const popover = window.document.querySelector('.popover');
        this.render.listen(popover, 'mouseover', () => {
          this.canClosePopover = false;
        });

        this.render.listen(popover, 'mouseout', () => {
          this.canClosePopover = true;
          setTimeout(() => { if (this.canClosePopover){ this.close(); } }, 0);
        });
      }, 0);
    }

    close(){
      super.close();
    }
  }
