import { Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Globals } from 'app/globals';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-aggrid-name-column',
  templateUrl: './aggrid-name-column.component.html',
  styleUrls: ['./aggrid-name-column.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgGridNameColumnComponent implements ICellRendererAngularComp{

  public data: any;

  private type;

  private female;

  private male;

  private origin = null;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, public globals: Globals){

  }

  agInit(params: any): void{
    this.type = params.type;
    this.origin = params.origin;
    this.female = this.globals.femaleAvatar;
    this.male = this.globals.avatar;
    if (params.data){
      this.data = this.generateObjectFromData(params.data);
    }
  }

  generateObjectFromData(data){
    switch (this.type){
      case 'getnavis-consultant': {
        return this.transformGetNavisConsultantData(data);
      }
      case 'getnavis-candidate': {
        return this.transformGetNavisCandidateData(data);
      }
      case 'campaigning-candidate': {
        return this.transformCampaigningCandidateData(data);
      }
      case 'getnavis-hiring-company': {
        return this.transformGetNavisHiringCompany(data);
      }
      case 'getnavis-consultant-company': {
        return this.transformGetNavisConsultantCompany(data);
      }
      case 'getnavis-campaigning-candidate':{
        return this.transformGetNavisCampaignsCandidateData(data);
      }
      case 'getnavis-targeted-company': {
        return this.transformGetNavisTargetedCompany(data);
      }
      default: {
        return this.transformDefaultCandidateData(data);
      }
    }
  }

  transformGetNavisHiringCompany(data: any){
    return {
      blacklisted_company: null,
      blacklisted_candidate: null,
      name: data.name,
      linkedin: data.linkedin_url,
      photo: data.photo || data.dealroom_data?.images?.['32x32'] || this.globals.companyAvatar,
      id: data.uuid,
      comments: null,
      gender: null
    };
  }

  transformGetNavisConsultantCompany(data: any){
    return {
      id: data.uuid,
      name: data.name,
      photo: data.photo || data.dealroom_data?.images?.['32x32'] || this.globals.companyAvatar,
      linkedin_url: data.linkedin_url,
      website: data.website_url
    };
  }

  transformGetNavisTargetedCompany(data: any){
    return {
      blacklisted_company: null,
      blacklisted_candidate: null,
      name: data.name,
      linkedin: null,
      linkedin_url: data.linkedin,
      photo: data.photo || data.dealroom_data?.images?.['32x32'] || this.globals.companyAvatar,
      id: data.uuid,
      comments: null,
      gender: null
    };
  }

  transformDefaultCandidateData(data: any){
    return {
      blacklisted_company: data.blacklisted_company,
      blacklisted_candidate: data.blacklisted_candidate,
      name: data.name.name,
      linkedin: data.name.linkedin,
      photo: data.name.photo,
      id: data.id,
      comments: data.comments,
      gender: data.gender,
      hubspot: data.hubspot
    };
  }

  transformCampaigningCandidateData(data: any){
    return {
      blacklisted_company: data.blacklisted_company,
      blacklisted_candidate: data.blacklisted_candidate,
      name: data.name,
      linkedin: data.linkedin,
      photo: data.photo,
      id: data.candidate_uuid,
      comments: data.comments,
      gender: data.gender,
      hubspot: data.hubspot
    };
  }

  transformGetNavisCandidateData(data){
    return {
      blacklisted_company: data.blacklisted_company,
      blacklisted_candidate: data.blacklisted_candidate,
      name: data.first_name + " " + data.last_name,
      linkedin: data.linkedin_url,
      photo: data.picture_file_url,
      id: data.candidate_uuid,
      comments: null,
      gender: null, //needs to be added later
      hubspot: data.hubspot
    };
  }

  transformGetNavisConsultantData(data){
    return {
      blacklisted_company: null,
      blacklisted_candidate: null,
      name: data.name,
      linkedin: null,
      photo: data.picture_file_url,
      id: data.uuid,
      comments: null,
      gender: null,
      hubspot: data.hubspot
    };
  }

  transformGetNavisCampaignsCandidateData(data){
      return {
        name: data.name,
        linkedin: data.linkedin,
        photo: data.photo,
        id: data.candidate_uuid,
        gender: data.gender,
        blacklisted_company: data.blacklisted_company,
        blacklisted_candidate: data.blacklisted_candidate,
        hubspot: data.hubspot
      };
  }

  refresh(): boolean{
    return true;
  }

  setAvatar = () => {
    if (this.data.photo){
      return this.data.photo;
    } else if (this.data.gender === 'Female' || this.data.gender == 'female'){
      return this.female;
    } else {
      return this.male;
    }
  };

  getLastCommentTag(comments){
      for (let i = comments.length - 1; i >= 0; i--){
          if (comments[i].market_mapping_tag){
              return comments[i].market_mapping_tag;
          }
      }
      return null;
  }

  borderSetter = () => {
    if (this.type == 'market_mapping' && this.globals.checkIfExists('permission', 'view_market_mapping_candidates')){
      const comments = [...this.data.comments];
      const distinctTag = this.getLastCommentTag(comments);
      return distinctTag ? `3px solid ${distinctTag.color_code} ` : `none`;
    }
    return null;
  };

  tagStyleSetter(type){
      switch (type){
        case "color": {
          if (this.data.blacklisted_company && "text_color" in this.data.blacklisted_company){
            return this.data.blacklisted_company.text_color;
          }
          if (this.data.blacklisted_candidate && "text_color" in this.data.blacklisted_candidate){
            return this.data.blacklisted_candidate.text_color;
          }
          return null;
        }
        case "backgroundColor": {
          if (this.data.blacklisted_company && "background_color" in this.data.blacklisted_company){
            return this.data.blacklisted_company.background_color;
          }
          if (this.data.blacklisted_candidate && "background_color" in this.data.blacklisted_candidate){
            return this.data.blacklisted_candidate.background_color;
          }
          return null;
        }
        default:
          return "#fff";
      }
  }

  nameClick(e: Event){
    e.preventDefault();
    if (this.data.id){
      switch (this.type){
        case 'getnavis-consultant': {
          this.openDrawer({
            'drawer_parent': 'getnavis-consultant-drawer',
            'type': 'view-consultant',
            'consultants_uuid': this.data.id
          });
          break;
        }
        case 'getnavis-candidate': {
          this.openDrawer({
            'drawer_parent': 'getnavis-candidate-drawer',
            'type': 'view-candidate',
            'candidate_uuid': this.data.id,
            'job_uuid': this.router.parseUrl(this.router.url).root.children.primary.segments[2]?.toString(),
            'origin': this.origin
          });
          break;
        }
        case 'getnavis-hiring-company': {
          this.openDrawer({
            'drawer_parent': 'getnavis-hiring-company-drawer',
            'type': 'view-hiring-company',
            'hiring_company_uuid': this.data.id
          });
          break;
        }
        case 'getnavis-consultant-company': {
          this.openDrawer({
            'drawer_parent': 'getnavis-consultant-company-drawer',
            'type': 'view-consultant-company',
            'consultant_company_uuid': this.data.id
          });
          break;
        }
        case 'getnavis-campaigning-candidate': {
          this.openDrawer({
            'drawer_parent': 'getnavis-candidate-drawer',
            'type': 'view-candidate',
            'candidate_uuid': this.data.id,
            'origin': this.origin
          });
          break;
        }
        case 'getnavis-targeted-company': {
          if (this.data.linkedin_url){
            window.open(this.data.linkedin_url, '_blank');
          }
          break;
        }
        default: {
          this.openDrawer({
            'drawer_parent': 'candidate-drawer',
            'type': 'view-candidate',
            'job_uuid': this.router.parseUrl(this.router.url).root.children.primary.segments[1]?.toString(),
            'candidate_uuid': this.data.id,
            'origin': this.origin
          });
        }
      }
    }
  }

  openDrawer(queryParams){
    this.router.navigate([],
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams
      }
    );
  }

  setNameClass(){
    if (this.type === 'getnavis-targeted-company'){
      if (this.data.id){
        return 'text-success';
      }
      return 'text-danger';
    }
    return 'big-search-primary-color';
  }
}
